<template>
  <div id="formCriarFornecedoresDadosMestres">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
        <h3 class="titleHeader mb-3">Fluxo de Criar Fornecedores</h3>
      <v-row>
          <v-col>
            <h5>Atenção</h5>
            <p class="caption">Os campos com
              <span class="count-warning">*</span>
              são obrigatórios.</p>
          </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="empresa"
            label="Empresa"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="razaoFornecedor"
            label="Razão social do fornecedor"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4" v-if="cnpj || !editar">
          <v-text-field
            v-model="cnpj"
            label="CNPJ"
            outlined
            dense
            :disabled="editar || hasCpfOrExteriorIndentificator"
          ></v-text-field>
        </v-col>
        <span style="margin-top: 20px;" v-if="!editar">OU</span>
        <v-col cols="4" v-if="cpf || !editar">
          <v-text-field
            v-model="cpf"
            label="CPF"
            outlined
            dense
            :disabled="editar || hasCNPJOrExteriorIndentificator"
          ></v-text-field>
        </v-col>
        <span style="margin-top: 20px;" v-if="!editar">OU</span>
        <v-col cols="3" v-if="indentificacaoExterior || !editar">
          <v-text-field
            v-model="indentificacaoExterior"
            label="Identificação Exterior"
            outlined
            dense
            :disabled="editar || hasCNPJOrCPF"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="endereco"
            label="Endereço"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>

        <v-col cols="4" v-if="cep || !editar">
          <v-text-field
            v-mask="'#####-###'"
            v-model="cep"
            label="CEP"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>

        <v-col cols="3" v-if="zipCode || !editar">
          <v-text-field
            v-mask="'#####-###'"
            v-model="zipCode"
            label="ZIPCODE"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="cidade"
            label="Cidade"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field
            v-model="estado"
            label="Estado"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="pais"
            label="País"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-mask="'(##)#####-####'"
            v-model="telefone1"
            label="Telefone Obrigatório"
            outlined
            dense
            :disabled="editar"
          >
          </v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>

        <v-col cols="4" v-if=" telefone2 || !editar">
          <v-text-field
            v-mask="'(##)#####-####'"
            v-model="telefone2"
            label="Telefone opcional"
            outlined
            dense
            :disabled="editar"
          >
          </v-text-field>
        </v-col>

        <v-col cols="3" v-if=" telefone3 || !editar">
          <v-text-field
            v-mask="'(##)#####-####'"
            v-model="telefone3"
            label="Telefone opcional"
            outlined
            dense
            :disabled="editar"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="emailGeral"
            label="E-mail geral"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>

        <v-col cols="5" v-if="emailPedido || !editar">
          <v-text-field
            v-model="emailPedido"
            label="E-mail para envio de pedido"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-select
            v-model="formaPagamento"
            :items="formasPagamento"
            label="Forma de pagamento"
            outlined
            dense
            :disabled="editar"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-if="permissaoMax.id_permissao !== usuarioPerm"
            v-model="numeroAtendimento"
            label="Informação dados SAP"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn
        :class="{ 'btn-primary-disabled ml-3': isStatusEncerrado,
                   'btn-primary ml-3': !isStatusEncerrado }"
        @click="toggleEdit"
        :disabled="isStatusEncerrado">
        <span v-if="editar">
          <v-icon left>mdi-pencil</v-icon>Editar
        </span>
        <span v-else>
          <v-icon left>mdi-close</v-icon>Cancelar
        </span>
      </v-btn>

      <v-btn
        v-if="!editar"
        class="btn-primary ml-2"
        @click="salvarFluxoEditado"
      >
        <v-icon left>mdi-content-save</v-icon>Salvar
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import moment from 'moment';
import { mask } from 'vue-the-mask';
import VueTypes from 'vue-types';
import { getMaxPermission } from '../../utils/utilities';
import Snackbar from '../Snackbar';

export default {
  name: 'FormCriarFornecedoresDadosMestres.vue',
  components: { Snackbar },
  directives: { mask },
  props: {
    camposFluxo: VueTypes.array,
    fluxoId: VueTypes.integer,
    atendimentoId: VueTypes.integer,
    statusAtendimento: VueTypes.string,
  },
  data() {
    return {
      permissaoMax: getMaxPermission(this.$store),
      usuarioPerm: this.$store.getters.listaPermissoes.colaborador,
      empresa: '',
      razaoFornecedor: '',
      cnpj: '',
      cpf: '',
      indentificacaoExterior: '',
      endereco: '',
      cep: '',
      zipCode: '',
      cidade: '',
      numeroAtendimento: '',
      estado: '',
      pais: '',
      telefone1: '',
      editar: true,
      telefone2: '',
      telefone3: '',
      emailGeral: '',
      emailPedido: '',
      formaPagamento: '',
      formasPagamento: ['Boleto', 'Crédito em conta'],
      dadosFluxo: {},
      newDadosFluxo: {},
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },
  created() {
    moment.locale('pt-br');
  },

  mounted() {
    this.loadInfoCamposFluxo();
  },

  computed: {
    hasCpfOrExteriorIndentificator() {
      return !!this.cpf || !!this.indentificacaoExterior;
    },

    hasCNPJOrExteriorIndentificator() {
      return !!this.cnpj || !!this.indentificacaoExterior;
    },

    hasCNPJOrCPF() {
      return !!this.cnpj || !!this.cpf;
    },

    isStatusEncerrado() {
      return this.statusAtendimento === 'Encerrado';
    },

    verificaCampos() {
      return (!!this.empresa
      && !!this.razaoFornecedor
      && (!!this.cnpj || !!this.cpf || !!this.indentificacaoExterior)
      && !!this.endereco
      && (!!this.cep || !!this.zipCode)
      && !!this.cidade
      && !!this.estado
      && !!this.pais
      && !!this.telefone1
      && !!this.emailGeral
      && this.chechIfneedEmaiPedido()
      && !!this.formaPagamento);
    },
  },
  methods: {
    chechIfneedEmaiPedido() {
      return this.zipCode ? true : !!this.emailPedido;
    },

    loadInfoCamposFluxo() {
      this.empresa = this.camposFluxo[0].resposta;
      this.razaoFornecedor = this.camposFluxo[1].resposta;
      this.cnpj = this.camposFluxo[2].resposta;
      this.cpf = this.camposFluxo[3].resposta;
      this.indentificacaoExterior = this.camposFluxo[4].resposta;
      this.endereco = this.camposFluxo[5].resposta;
      this.cep = this.camposFluxo[6].resposta;
      this.zipCode = this.camposFluxo[7].resposta;
      this.cidade = this.camposFluxo[8].resposta;
      this.estado = this.camposFluxo[9].resposta;
      this.pais = this.camposFluxo[10].resposta;
      this.telefone1 = this.camposFluxo[11].resposta;
      this.telefone2 = this.camposFluxo[12].resposta;
      this.telefone3 = this.camposFluxo[13].resposta;
      this.emailGeral = this.camposFluxo[14].resposta;
      this.emailPedido = this.camposFluxo[15].resposta;
      this.formaPagamento = this.camposFluxo[16].resposta;
      this.numeroAtendimento = this.camposFluxo[17].resposta;
    },

    toggleEdit() {
      this.editar = !this.editar;
      this.disabled = !this.disabled;
    },

    async salvarFluxoEditado() {
      if (!this.verificaCampos) {
        this.dadosFluxo = { validation: false };
        this.alerta = 'error';
        this.msg = 'Todos os campos com * são obrigatórios.';
        this.snackBarAlertaCampos = true;
      } else {
        this.toggleEdit();

        this.alerta = 'success';
        this.msg = 'Formulário editado com sucesso!';
        this.snackBarAlertaCampos = true;

        const body = {
          id: this.atendimentoId,
          campos_fluxo: {
            column_campo_1: this.empresa,
            column_campo_2: this.razaoFornecedor,
            column_campo_3: this.cnpj,
            column_campo_4: this.cpf,
            column_campo_5: this.indentificacaoExterior,
            column_campo_6: this.endereco,
            column_campo_7: this.cep,
            column_campo_8: this.zipCode,
            column_campo_9: this.cidade,
            column_campo_10: this.estado,
            column_campo_11: this.pais,
            column_campo_12: this.telefone1,
            column_campo_13: this.telefone2,
            column_campo_14: this.telefone3,
            column_campo_15: this.emailPedido,
            column_campo_16: this.emailGeral,
            column_campo_17: this.formaPagamento,
            column_campo_18: this.numeroAtendimento,
          },
          responsavel: this.$store.getters.isUsuario.id,
          fluxo_id: this.fluxoId,
        };

        await this.$http.put('/atendimentos/editar/dados-fluxo', body);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

.btn-primary {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border: 1px solid #0056a8 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}
#formCriarFornecedoresDadosMestres {
  margin-left: 12px;
}

.btn-primary-disabled {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.count-warning {
  background-color: transparent;
  color: red;
}
</style>
