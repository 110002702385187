<template>
  <div>
    <div @click="launchFilePicker()">
      <slot name="activator"></slot>
    </div>
    <input
      type="file"
      ref="file"
      :name="uploadFieldName"
      @change="onFileChange(
          $event.target.name, $event.target.files)"
      onclick="this.value=null;"
      style="display: none;"
    >
    <Snackbar
      :label="errorText"
      v-model="snackbarAvatar"
      absolute
      top
      right
      :tipo="snackbarTipo"
    />
  </div>
</template>

<script>
import Snackbar from './Snackbar.vue';

export default {
  name: 'InputImagem',
  components: {
    Snackbar,
  },
  data() {
    return {
      errorText: '',
      uploadFieldName: 'file',
      snackbarAvatar: false,
      snackbarTipo: 'error',
      loader: false,
    };
  },
  props: {
    // Use "value" to enable using v-model
    value: Object,
    maxSize: {
      type: Number,
      default: 2.048,
    },
    loadAvatar: Boolean,
  },
  watch: {
    loadAvatar(newValue) {
      if (newValue) {
        this.loader = true;
      } else {
        this.loader = false;
        this.snackbarTipo = 'success';
        this.errorText = 'Avatar alterado com sucesso.';
        this.snackbarAvatar = true;
      }
    },
  },

  methods: {
    launchFilePicker() {
      this.$refs.file.click();
    },
    onFileChange(fieldName, file) {
      const { maxSize } = this;
      if (file.length > 0) {
        const imageFile = file[0];
        const size = imageFile.size / 1024 / 1024;
        if (!imageFile.type.match('image/jpeg|image/png')) {
          // check whether the upload is an image
          this.snackbarTipo = 'error';
          this.errorText = 'Por favor selecione um arquivo jpeg, jpg ou png.';
          this.snackbarAvatar = true;
        } else if (size > maxSize) {
          // check whether the size is greater than the size limit
          this.snackbarTipo = 'error';
          this.errorText = 'Seu arquivo é muito grande! Por favor selecione uma imagem menor que 5MB.';
          this.snackbarAvatar = true;
        } else {
          // Append file into FormData and turn file into image URL
          const formData = new FormData();
          const imageURL = URL.createObjectURL(imageFile);
          formData.append('avatar', imageFile);
          formData.append('id', this.$store.getters.isUsuario.id);
          // Emit the FormData and image URL to the parent component
          this.$emit('input', { formData, imageURL, imageFile });
        }
      }
    },
  },
};
</script>
