<template>
  <v-row justify="center">
    <v-dialog
      v-model="modal"
      persistent
      max-width="700"
    >
      <v-card>
        <v-card-title class="headline">
          <v-icon
            class="mr-2"
            color="primary"
            size="32"
          >mdi-alert-box</v-icon>
          <span>Avalie antes de continuar</span>
        </v-card-title>
        <v-card-text class="my-2">
          <span
            v-if="pendentes.length > 1"
            class="subtitle-2 font-weight-bold"
          >Para criar um novo atendimento será necessário
            que você avalie seus atendimentos anteriores que estão pendentes.
          </span>
          <span
            v-else
            class="subtitle-2 font-weight-bold"
          >Para criar um novo atendimento será necessário
            que você avalie o atendimento anterior que está pendente.
          </span>
          <v-simple-table class="my-2">
            <template v-slot:default>
              <thead>
                <tr>
                  <th scope="col" class="text-left">Titulo</th>
                  <th scope="col" class="text-left">Encerrado em</th>
                  <th scope="col" class="text-left">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in pendentes"
                  :key="index"
                >
                  <td>{{ item.titulo }}</td>
                  <td>{{ item.data | formatDate }}</td>
                  <td>
                    <v-btn
                      :href="item.url"
                      target="_blank"
                      class="botao-avaliar"
                    >
                      <v-icon
                        size="20"
                        class="mr-1"
                        color="primary"
                      >mdi-message-text</v-icon>
                      <span>Avaliar</span>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="pb-2">
          <v-spacer></v-spacer>
          <v-btn
            class="botao-avaliar"
            @click="redirecionaAtendimentos"
          >Meus atendimentos</v-btn>
          <v-btn
            class="botao-atualizar"
            @click="$emit('atualiza-pendentes')"
          >
            <v-icon class="mr-1">mdi-refresh</v-icon>
            <span>Atualizar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'ModalAvaliacaoPendente',
  props: {
    pendentes: VueTypes.array.isRequired,
  },
  data() {
    return {
      modal: true,
    };
  },
  methods: {
    redirecionaAtendimentos() {
      this.$router.push('/lista');
    },
  },


};
</script>

<style lang="scss" scoped>
.botao-avaliar {
  text-transform: inherit !important;
  background: #fff;
  color: #122870;
  border-radius: 5px;
  border: 1px solid #bfbfbf;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.botao-atualizar {
  text-transform: inherit !important;
  background: #122870 !important;
  border-color: 122870 !important;
  color: #fff !important;
  border-radius: 4px;
  font-size: 16px !important;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}
</style>
