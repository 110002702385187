<template>
  <div id="FormComprasFixas">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="empresa"
            label="Empresa"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>

        <v-col cols="11">
          <v-text-field
            v-model="unidade"
            label="Centro / Unidade"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="11">
          <v-text-field
            v-model="fornecedorSelecionado"
            label="Fornecedor"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
      </v-row>

      <v-row>
        <v-col cols="11">
          <v-text-field
            v-model="servico"
            label="Material/ Serviço"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
      </v-row>

      <v-row>
        <v-col cols="7">
          <v-text-field
            v-model="centroCustoSelecionado0"
            label="Centro de custo"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
        <v-col cols="4">
          <v-text-field
            v-money="money"
            v-model="valorTotalCentroCusto0"
            label="Valor total anual"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
      </v-row>

      <v-row v-if="centroCustoSelecionado1 || !editar">
        <v-col cols="7">
          <v-text-field
            v-model="centroCustoSelecionado1"
            label="Centro de custo"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-money="money"
            v-model="valorTotalCentroCusto1"
            label="Valor total anual"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="centroCustoSelecionado2 || !editar">
        <v-col cols="7">
          <v-text-field
            v-model="centroCustoSelecionado2"
            label="Centro de custo"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-money="money"
            v-model="valorTotalCentroCusto2"
            label="Valor total anual"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="centroCustoSelecionado3 || !editar">
        <v-col cols="7">
          <v-text-field
            v-model="centroCustoSelecionado3"
            label="Centro de 11custo"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-money="money"
            v-model="valorTotalCentroCusto3"
            label="Valor total anual"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="centroCustoSelecionado4 || !editar">
        <v-col cols="7">
          <v-text-field
            v-model="centroCustoSelecionado4"
            label="Centro de custo"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-money="money"
            v-model="valorTotalCentroCusto4"
            label="Valor total anual"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn
        :class="{ 'btn-primary-disabled ml-0 mt-2': isStatusEncerrado,
                   'btn-primary ml-0 mt-2': !isStatusEncerrado }"
        @click="toggleEdit"
        :disabled="isStatusEncerrado">
        <span v-if="editar">
          <v-icon left>mdi-pencil</v-icon>Editar
        </span>
        <span v-else>
          <v-icon left>mdi-close</v-icon>Cancelar
        </span>
      </v-btn>

      <v-btn
        v-if="!editar"
        class="btn-primary ml-2 mt-2"
        @click="salvarFluxoEditado"
      >
        <v-icon left>mdi-content-save</v-icon>Salvar
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { mask } from 'vue-the-mask';
import { VMoney } from 'v-money';
import Snackbar from '../Snackbar';

export default {
  name: 'FormComprasFixas',
  components: { Snackbar },
  directives: { mask, money: VMoney },

  props: {
    camposFluxo: VueTypes.array,
    fluxoId: VueTypes.integer,
    atendimentoId: VueTypes.integer,
    statusAtendimento: VueTypes.string,
  },

  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
      empresa: '',

      unidade: '',

      fornecedorSelecionado: '',

      servico: '',

      centroCustoSelecionado0: '',
      centroCustoSelecionado1: '',
      centroCustoSelecionado2: '',
      centroCustoSelecionado3: '',
      centroCustoSelecionado4: '',

      valorTotalCentroCusto0: '',
      valorTotalCentroCusto1: '',
      valorTotalCentroCusto2: '',
      valorTotalCentroCusto3: '',
      valorTotalCentroCusto4: '',

      editar: true,
      disabled: true,
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },
  created() {
    this.loadInfoCamposFluxo();
  },

  computed: {
    isStatusEncerrado() {
      return this.statusAtendimento === 'Encerrado';
    },

    verificaCampos() {
      return (this.empresa
      && !!this.unidade
      && !!this.fornecedorSelecionado
      && !!this.servico
      && !!this.centroCustoSelecionado0);
    },
  },
  methods: {
    loadInfoCamposFluxo() {
      this.empresa = this.camposFluxo[0].resposta;
      this.unidade = this.camposFluxo[1].resposta;
      this.fornecedorSelecionado = this.camposFluxo[2].resposta;
      this.servico = this.camposFluxo[3].resposta;
      this.centroCustoSelecionado0 = this.camposFluxo[4].resposta;
      this.valorTotalCentroCusto0 = this.camposFluxo[5].resposta;
      this.centroCustoSelecionado1 = this.camposFluxo[6].resposta;
      this.valorTotalCentroCusto1 = this.camposFluxo[7].resposta;
      this.centroCustoSelecionado2 = this.camposFluxo[8].resposta;
      this.valorTotalCentroCusto2 = this.camposFluxo[9].resposta;
      this.centroCustoSelecionado3 = this.camposFluxo[10].resposta;
      this.valorTotalCentroCusto3 = this.camposFluxo[11].resposta;
      this.centroCustoSelecionado4 = this.camposFluxo[12].resposta;
      this.valorTotalCentroCusto4 = this.camposFluxo[13].resposta;
    },

    toggleEdit() {
      this.editar = !this.editar;
      this.disabled = !this.disabled;
    },

    async salvarFluxoEditado() {
      if (!this.verificaCampos) {
        this.alerta = 'error';
        this.msg = 'Existem campos obrigatórios que não foram preenchidos';
        this.snackBarAlertaCampos = true;
      }
      if (this.verificaCampos) {
        this.toggleEdit();

        this.alerta = 'success';
        this.msg = 'Formulário editado com sucesso!';
        this.snackBarAlertaCampos = true;

        const body = {
          id: this.atendimentoId,
          campos_fluxo: {
            column_campo_1: this.empresa,
            column_campo_2: this.unidade,
            column_campo_3: this.fornecedorSelecionado,
            column_campo_4: this.servico,
            column_campo_5: this.centroCustoSelecionado0,
            column_campo_6: this.valorTotalCentroCusto0,
            column_campo_7: this.centroCustoSelecionado1,
            column_campo_8: this.valorTotalCentroCusto1,
            column_campo_9: this.centroCustoSelecionado2,
            column_campo_10: this.valorTotalCentroCusto2,
            column_campo_11: this.centroCustoSelecionado3,
            column_campo_12: this.valorTotalCentroCusto3,
            column_campo_13: this.centroCustoSelecionado4,
            column_campo_14: this.valorTotalCentroCusto4,
          },
          responsavel: this.$store.getters.isUsuario.id,
          fluxo_id: this.fluxoId,
        };

        await this.$http.put('/atendimentos/editar/dados-fluxo', body);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

#Form {
  margin-left: 15px;
}

.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.btn-primary {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border: 1px solid #0056a8 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.btn-primary-disabled {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.count-warning {
  background-color: transparent;
  color: red;
}
</style>
