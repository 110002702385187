<template>
  <div id="FormModificarFornecedor">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="empresa"
            label="Empresa"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="8">
          <v-text-field
            v-model="codigoFornecedor"
            label="Código Fornecedor"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="endereco"
            label="Endereço"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="4" v-if="cep || !editar">
          <v-text-field
            v-mask="'#####-###'"
            v-model="cep"
            label="CEP"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="3" v-if="zipCode || !editar">
          <v-text-field
            v-model="zipCode"
            label="ZIPCODE"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="cidade"
            label="Cidade"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field
            v-model="estado"
            label="Estado"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="pais"
            label="País"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="telefone1"
            label="Telefone Principal"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="4" v-if="telefone2 || !editar">
          <v-text-field
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="telefone2"
            label="Telefone Opcional"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="3" v-if="telefone3 || !editar">
          <v-text-field
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="telefone3"
            label="Telefone Opcional"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="emailPedido"
            label="Email para envio pedido"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field
            v-model="emailGeral"
            label="Email Geral"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-select
            v-model="formaPagamento"
            :items="formasPagamento"
            label="Forma de pagamento"
            class="mb-2"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-select>
        </v-col>
      </v-row>

      <v-btn
        :class="{ 'btn-primary-disabled ml-0': isStatusEncerrado,
                   'btn-primary ml-0': !isStatusEncerrado }"
        @click="toggleEdit"
        :disabled="isStatusEncerrado">
        <span v-if="editar">
          <v-icon left>mdi-pencil</v-icon>Editar
        </span>
        <span v-else>
          <v-icon left>mdi-close</v-icon>Cancelar
        </span>
      </v-btn>

      <v-btn
        v-if="!editar"
        class="btn-primary ml-2"
        @click="salvarFluxoEditado"
      >
        <v-icon left>mdi-content-save</v-icon>Salvar
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { mask } from 'vue-the-mask';
import Snackbar from '../Snackbar';

export default {
  name: 'FormModificarFornecedor',
  components: { Snackbar },
  directives: { mask },

  props: {
    camposFluxo: VueTypes.array,
    fluxoId: VueTypes.integer,
    atendimentoId: VueTypes.integer,
    statusAtendimento: VueTypes.string,
  },

  data() {
    return {
      empresa: '',

      codigoFornecedor: '',

      endereco: '',

      cep: '',

      zipCode: '',

      cidade: '',

      estado: '',

      pais: '',

      telefone1: '',
      telefone2: '',
      telefone3: '',

      emailPedido: '',

      emailGeral: '',

      formaPagamento: '',
      formasPagamento: ['Boleto', 'Crédito em conta', 'Cartão de Crédito'],

      editar: true,
      disabled: true,
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },

  created() {
    this.loadInfoCamposFluxo();
  },

  computed: {
    isStatusEncerrado() {
      return this.statusAtendimento === 'Encerrado';
    },

    verificaCampos() {
      return (!!this.empresa
      && !!this.codigoFornecedor);
    },
  },
  methods: {
    loadInfoCamposFluxo() {
      this.empresa = this.camposFluxo[0].resposta;
      this.codigoFornecedor = this.camposFluxo[1].resposta;
      this.endereco = this.camposFluxo[2].resposta;
      this.cep = this.camposFluxo[3].resposta;
      this.zipCode = this.camposFluxo[4].resposta;
      this.cidade = this.camposFluxo[5].resposta;
      this.estado = this.camposFluxo[6].resposta;
      this.pais = this.camposFluxo[7].resposta;
      this.telefone1 = this.camposFluxo[8].resposta;
      this.telefone2 = this.camposFluxo[9].resposta;
      this.telefone3 = this.camposFluxo[10].resposta;
      this.emailGeral = this.camposFluxo[11].resposta;
      this.emailPedido = this.camposFluxo[12].resposta;
      this.formaPagamento = this.camposFluxo[13].resposta;
    },

    toggleEdit() {
      this.editar = !this.editar;
      this.disabled = !this.disabled;
    },

    async salvarFluxoEditado() {
      if (!this.verificaCampos) {
        this.dadosFluxo = { validation: false };
        this.alerta = 'error';
        this.msg = 'Todos os campos com * são obrigatórios.';
        this.snackBarAlertaCampos = true;
      } else {
        this.toggleEdit();

        this.alerta = 'success';
        this.msg = 'Formulário editado com sucesso!';
        this.snackBarAlertaCampos = true;

        const body = {
          id: this.atendimentoId,
          campos_fluxo: {
            column_campo_1: this.empresa,
            column_campo_2: this.codigoFornecedor,
            column_campo_3: this.endereco,
            column_campo_4: this.cep,
            column_campo_5: this.zipCode,
            column_campo_6: this.cidade,
            column_campo_7: this.estado,
            column_campo_8: this.pais,
            column_campo_9: this.telefone1,
            column_campo_10: this.telefone2,
            column_campo_11: this.telefone3,
            column_campo_12: this.emailGeral,
            column_campo_13: this.emailPedido,
            column_campo_14: this.formaPagamento,
          },
          responsavel: this.$store.getters.isUsuario.id,
          fluxo_id: this.fluxoId,
        };

        await this.$http.put('/atendimentos/editar/dados-fluxo', body);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

#FormModificarFornecedor {
  margin-left: 12px;
}

.btn-primary {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border: 1px solid #0056a8 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

</style>
