var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"solicitarCoffeeBreak"}},[_c('Snackbar',{attrs:{"label":_vm.msg,"top":"","right":"","timeout":2500,"tipo":_vm.alerta},model:{value:(_vm.snackBarAlertaCampos),callback:function ($$v) {_vm.snackBarAlertaCampos=$$v},expression:"snackBarAlertaCampos"}}),_c('v-form',[_c('h3',{staticClass:"titleHeader mb-3"},[_vm._v("Fluxo de Solicitar CoffeBreak")]),_c('v-row',[_c('v-col',[_c('h5',[_vm._v("Atenção")]),_c('p',{staticClass:"caption"},[_vm._v("Os campos com "),_c('span',{staticClass:"count-warning"},[_vm._v("*")]),_vm._v(" são obrigatórios.")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","readonly":"","label":"Data do evento","append-icon":"event"},model:{value:(_vm.dataEventoFormatted),callback:function ($$v) {_vm.dataEventoFormatted=$$v},expression:"dataEventoFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDataEvento),callback:function ($$v) {_vm.menuDataEvento=$$v},expression:"menuDataEvento"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"pt-br"},on:{"input":function($event){_vm.menuDataEvento = false}},model:{value:(_vm.periodo),callback:function ($$v) {_vm.periodo=$$v},expression:"periodo"}})],1)],1),_c('div',{staticClass:"caption count-warning"},[_vm._v("*")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Local do evento","outlined":"","dense":""},model:{value:(_vm.local),callback:function ($$v) {_vm.local=$$v},expression:"local"}})],1),_c('div',{staticClass:"caption count-warning"},[_vm._v("*")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Número de pessoas","type":"number","outlined":"","dense":"","clearable":"","auto-grow":""},model:{value:(_vm.numeroPessoas),callback:function ($$v) {_vm.numeroPessoas=$$v},expression:"numeroPessoas"}})],1),_c('div',{staticClass:"caption count-warning"},[_vm._v("*")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},_vm._l((_vm.horarios),function(item,index){return _c('v-text-field',{key:index,attrs:{"label":"Horário","type":"time","outlined":"","dense":"","clearable":"","auto-grow":""},model:{value:(item.hora),callback:function ($$v) {_vm.$set(item, "hora", $$v)},expression:"item.hora"}})}),1),_c('div',{staticClass:"caption count-warning"},[_vm._v("*")]),_c('v-col',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","color":"white","x-small":"","dark":"","left":""},on:{"click":function($event){$event.stopPropagation();return _vm.addNewHour()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Adicione mais um horário.")])]),(_vm.horarios.length > 1)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","color":"white","x-small":"","dark":"","left":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeOldHour()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-minus")])],1)]}}],null,false,17008432)},[_c('span',[_vm._v("Remova um horário.")])]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }