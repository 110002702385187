<template>
  <div class="principal">
    <Botao
    type="button"
    label="Relatório"
    tipo="primary"
    :callback="getRelatorioSolicitacoes"
  />
  </div>
</template>

<script>
import moment from 'moment';
import VueTypes from 'vue-types';
import XLSX from 'xlsx';
import Botao from './Botao.vue';
import { getMaxPermission } from '../utils/utilities';
import { setores } from '../Constants.js';

export default {
  name: 'RelatorioSolicitacoes',
  components: {
    Botao,
  },
  props: {
    dataCheckbox: VueTypes.boolean,
    statusCheckbox: VueTypes.boolean,
    statusSelected: VueTypes,
    dataInitialSelected: VueTypes,
    dataFinalSelected: VueTypes,
  },
  data() {
    return {
      relatorioSolicitacoes: [],
      dialog: false,
      menu1: false,
      menu2: false,
      dataHoje: new Date().toISOString().slice(0, 10),
      permissaoGestor: getMaxPermission(this.$store),
      dp: setores.departamentoDePessoal,
      gestor: this.$store.getters.listaPermissoes.gestor,
      gestorGeral: this.$store.getters.listaPermissoes.gestorgeral,
    };
  },

  methods: {
    closeModal() {
      this.setor = '';
      this.dialog = false;
      this.dialogDelete = true;
    },

    async getRelatorioSolicitacoes() {
      const { data: response } = await this.$http.get(
        '/segunda-aprovacao-all/',
      );

      this.relatorioSolicitacoes = response.map(element => ({
        Numero_Atendimento: element.id_atendimento,
        Status_Aprovação: element.status_aprovacao,
        Data_Alteração: this.dataFormatted(element.updated_at),
        Gestor: element.dados_fluxo.manager,
        Profissional: element.dados_fluxo.employee,
        Razão: element.dados_fluxo.reason,
        Motivo: element.dados_fluxo.motivo ? element.dados_fluxo.motivo
          : '',
        Mês_Competencia: element.dados_fluxo.selectedMonth,
        Regime_Contratação: element.dados_fluxo.roundCheckBoxType,
        Ocupação_De: element.dados_fluxo.occupationPositionFrom,
        Ocupação_Para: element.dados_fluxo.occupationPositionTo,
        Remuneração_De: element.dados_fluxo.paymentFrom,
        Remuneração_Para: element.dados_fluxo.paymentTo,
        Remuneração_CCT: element.dados_fluxo.paymentCalc,
        Senioridade_De: element.dados_fluxo.experienceFrom,
        Senioridade_Para: element.dados_fluxo.experienceTo,
        Descrição: element.dados_fluxo.description,
        Terceira_Aprovacao: element.terceira_aprovacao ? 'Sim' : 'Não',
        Comentário: element.comentario,
        Programa_Evoluir_De: element.dados_fluxo.checkTheBoxProgram
          ? element.dados_fluxo.programFrom : '',
        Programa_Evoluir_Para: element.dados_fluxo.checkTheBoxProgram
          ? element.dados_fluxo.programTo : '',
        Ajuda_Custo_De: element.dados_fluxo.checkTheBoxAllowance
          ? element.dados_fluxo.allowanceFrom : '',
        Ajuda_Custo_Para: element.dados_fluxo.checkTheBoxAllowance
          ? element.dados_fluxo.allowanceTo : '',
        Vale_Alimentacao_De: element.dados_fluxo.checkTheBoxMealTicket
          ? element.dados_fluxo.mealTicketFrom : '',
        Vale_Alimentacao_Para: element.dados_fluxo.checkTheBoxMealTicket
          ? element.dados_fluxo.mealTicketTo : '',
      }));

      const data = XLSX.utils.json_to_sheet(this.relatorioSolicitacoes);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'data');
      const nameFile = 'Relatório de Solicitações.xlsx';
      XLSX.writeFile(wb, nameFile);
    },

    dataFormatted(data) {
      return data ? moment(data).format('DD/MM/YYYY') : '';
    },
    dataMoment(data) {
      return data ? moment(data, 'DD/MM/YYYY') : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.principal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .btn-2 {
    text-transform: inherit !important;
  background: #0056a8 !important;
  color: #fff;
  border-radius: 5px;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    &:hover {
      color: #bfbfbf;
    }
  }
  .container-row {
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  align-items: flex-start;
  flex-direction: row;
  }
}
</style>
