<template>
  <div class="breadcrumb">
    <ul>
      <li
        v-for="(item, index) in breadcrumbs"
        :key="index"
        class="breadcrumb-path"
      >
        <router-link
          :to="item.route"
          v-if="index !== breadcrumbs.length - 1"
          class="breadcrumb-path-before"
        >{{ item.label }} ></router-link>
        <span
          v-else
          class="breadcrumb-path-atual"
        >{{ item.label }}</span>
      </li>
    </ul>
    <div v-if="indicadores" class="indicador">
      <p>Total de atendimentos abertos hoje<strong>/</strong>Total atendimentos no Meta Conecta:
        <strong>{{ atendimentosHojeAbertos }}<strong>/</strong>{{ totalAtendimentos }}</strong></p>
      <p>Total de encerrados hoje:
        <strong>{{ atendimentosHojeFechados }}</strong></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    breadcrumbs: {
      required: true,
    },
    indicadores: {
      required: false,
    },
  },
  data() {
    return {
      totalAtendimentos: '',
      atendimentosHojeAbertos: '',
      atendimentosHojeFechados: '',
    };
  },

  mounted() {
    if (this.indicadores) this.indicadorAbertosEncerradosPorDia();
  },

  methods: {
    async indicadorAbertosEncerradosPorDia() {
      try {
        const { data: response } = await this.$http.get('/indicadores/abertos-hoje');

        this.totalAtendimentos = new Intl.NumberFormat('pt-br').format(response.totalAtendimentos);
        this.atendimentosHojeAbertos = response.totalAbertos;
        this.atendimentosHojeFechados = response.totalFechados;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  margin: 0 !important;
  padding: 0 !important;
}
.indicador p {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0px;
  color: #122870;
  width: 100%;
  height: 20px;
  strong {
  font-weight: bold;
  margin-left: 3px;
  margin-right: 3px;
  }
}
.breadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
  padding: 0 !important;
  background: #fff;
}
.breadcrumb-path a {
  font-weight: normal;
  color: #303030 !important;
}
.breadcrumb-path-before {
  margin-right: 3px;
}
.breadcrumb-path-atual {
  color: #122870;
  width: 100%;
  height: 20px;
  font-weight: bold !important;
}
.breadcrumb ul li {
  list-style: none;
  display: inline-flex;
}
</style>
