<template>
  <div v-click-outside="toggleLeaveBell">

    <div :class="{ shake: activeToggleBell }">
      <a @click="toggleBell">
        <span
          class="notificacao-numero"
          :class="cQuantNotificacoes"
        >{{ quantNotificacoes }}</span>
        <v-icon
          class="bell"
          color="#122870"
          large
        >mdi-bell</v-icon>
      </a>
    </div>

    <transition name="fade">
      <div
        class="toggle-bell"
        v-if="activeToggleBell"
      >
        <div class="bell-header">
          <h5>Notificações</h5>
        </div>
        <div
          v-if="notificacoes.length > 0"
          class="bell-body"
        >
          <div
            v-for="(item, index) in notificacoes"
            :key="index"
          >
            <div
              @click="chamaAtendimento(item.id_atendimento)"
              class="notificacao"
            >
              <div
                class="circular-status-wrapper"
                @click.stop="marcarLida(item.id, index)"
              >
                <CircularStatus
                  v-if="!item.notificacaoLida"
                  status="#027cee"
                  size="small"
                />
                <div
                  class="msg-lida"
                  v-if="item.notificacaoLida"
                />
              </div>
              <v-icon
                class="v-icon-tipo"
                color="black"
              >{{ mdiIconArray[item.id_tipo] }} </v-icon>
              <div>
                <span><strong>{{ primeiroNome(item.nome) }} </strong>
                  {{ item.status }} {{ item.id_atendimento }}</span>
                <span>{{ item.data | formatAdonisDate }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="empty-state-content"
        >
          <img
            class="empty-state-img"
            src="../assets/img/empty-states/empty-state-2.svg"
            alt="Não há notificações"
          />
          <h6 class="empty-state-text">
            Você não possui notificações.
          </h6>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import CircularStatus from './CircularStatus.vue';
import Comment from '../assets/icons/comment.svg';
import primeiroNome from '../mixins/primeiroNome.js';

export default {
  name: 'Notificacao',
  components: {
    CircularStatus,
  },
  mixins: [primeiroNome],
  props: {
    notificacoes: VueTypes.array.isRequired,
  },
  data() {
    return {
      Comment,
      currentLocation: window.location,
      activeToggleBell: false,
      quantNotificacoes: 0,
      mdiIconArray: [
        '',
        'mdi-message-text',
        'mdi-at',
        'mdi-reload',
        'mdi-reload',
        'mdi-reload',
        'mdi-reload',
      ],
    };
  },
  watch: {
    notificacoes() {
      this.countNotificacoesNaoLidas();
    },
  },
  computed: {
    switchIcon(id) {
      return this.mdiIconArray[id];
    },
    cQuantNotificacoes() {
      if (this.quantNotificacoes === 0) {
        return 'n0';
      }
      if (this.quantNotificacoes < 10) {
        return 'n9';
      }
      if (this.quantNotificacoes < 100) {
        return 'n99';
      }
      return 'n999';
    },
  },
  methods: {
    countNotificacoesNaoLidas() {
      try {
        if (this.notificacoes) {
          this.quantNotificacoes = 0;
          this.notificacoes.map((item) => {
            if (!item.notificacaoLida) {
              this.quantNotificacoes = this.quantNotificacoes + 1;
              return true;
            }
            return false;
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    toggleBell() {
      this.activeToggleBell = !this.activeToggleBell;
    },
    toggleLeaveBell() {
      this.activeToggleBell = false;
    },
    async marcarLida(id, index) {
      try {
        await this.$http.put(`/ler-notificacao/${id}`);
        this.notificacoes[index].notificacaoLida = true;
        this.countNotificacoesNaoLidas();
      } catch (error) {
        console.error(error);
      }
    },
    chamaAtendimento(idAtendimento) {
      const path = `/atendimento/${idAtendimento}`;
      this.$router.push(path);
      if (this.currentLocation.href.includes('atendimento')) {
        this.$router.go();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shake {
  animation: shake 1s;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity .5s;
}
.fade-leave-active {
  transition: opacity 1s;
}

a {
  text-decoration: none !important;
  color: #000;
}
.notificacao {
  display: flex;
  width: 100%;
  padding: 9px 3px 9px 0;
  justify-content: flex-start;
  box-shadow: 0 0.1px 0.4px 0.1px rgba(0, 0, 0, 0.15);
  &:hover {
    background-color: rgba(86, 197, 201, 0.2);
  }
  & img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin: 5px;
    padding: 7px;
    box-shadow: 0 0 2.6px 0 rgba(0, 0, 0, 0.25);
  }
  & div {
    display: flex;
    flex-direction: column;
    padding: 0 5px;
    & span {
      font-size: 14px;
      color: #000;
      &:nth-child(2) {
        color: #9d9d9d;
      }
    }
  }
}
.toggle-bell {
  display: block;
  z-index: 40;
  position: fixed;
  top: 25px;
  right: 335px;
  width: 355px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  background: #ffffff;
  border-radius: 4px;
}
.bell-header {
  z-index: 40;
  height: 32px;
  width: 100%;
  background-color: #122870;
  padding: 7px 10px 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #ffffff;
  & h5 {
    font-weight: 500;
  }
}
.v-icon-tipo {
  font-size: 18px !important;
  border-radius: 50%;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.3);
  max-height: 36px;
  min-height: 36px;
  max-width: 36px;
  min-width: 36px;
  align-self: center;
  background-color: #fff;
}
.bell-body {
  display: flex;
  flex-direction: column;
  max-height: 301px;
  overflow-y: scroll
}
.circular-status-wrapper {
  display: flex;
  align-self: center;
  max-width: 22px;
}
.msg-lida {
  display: flex;
  align-self: center;
  width: 6px;
  height: 6px;
  margin: 3px;
}
.bell {
  margin-top: 10px;
  margin-right: 60px;
}
.notificacao-numero {
  position: relative;
  top: -10px;
  right: -35px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  padding: 3px 6.5px;
  background-color: #ee0202;
  border-radius: 50%;
  z-index: 31;
}
.notificacao-numero.n0 {
  display: none;
}
.notificacao-numero.n9 {
  padding: 3px 6.5px;
}
.notificacao-numero.n99 {
  padding: 3.5px 4px;
}
.notificacao-numero.n999 {
  padding: 6px 3px;
  font-size: 10px;
}
.empty-state-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .empty-state-img {
    width: 250px;
    margin: 25px;
  }
  .empty-state-text {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 30px;
  }
}
</style>
