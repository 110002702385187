<template>
  <Layout>
    <Breadcrumb :breadcrumbs="breadcrumbs" />
    <h4 class="font-weight-black text-center ma-3 pb-4">Como podemos ajudar?</h4>
    <div class="flex-filter-container mt-3 mb-5 pb-6">
      <FiltroAtendimento
        tipoFiltro="searchFAQ"
        :quantidadeTags="2"
        class="search-filter px-2"
        @changeFiltro="showResults($event)"
      />
    </div>
    <div
      class="flex-container"
      v-if="exibeListaFAQs"
    >
          <div class="flex-item" v-for="(item, index) in this.listaSetores" :key=index>
            <h6 class="setor-style" v-if="filtraCategorias(categorias, item.id).length" >
              {{ item.nome }}</h6>
            <div class="flex-item"
            v-for="(item, index) in filtraCategorias(categorias, item.id)"
            :key="index">
              <FAQList
                :categoria="item.nome"
                :subcategorias="item.subcategorias"
              />
            </div>
          </div>
    </div>
    <div
      class="text-align-center"
      v-if="!exibeListaFAQs "
    >
      <div class="flex-item" v-if="isSetor">
        <h6 class="setor-style"> {{this.nomeSetor}} </h6>
        <div class="flex-item" v-for="(item, index) in this.resultadosFAQ" :key=index>
          <FAQList
            :categoria="item.nome"
            :subcategorias="item.subcategoria"
          />
          </div>
        </div>
        <div class="flex-item" v-if="!isSetor">
          <h6 class="setor-style" v-if="!this.resultadosFAQ.nome">
            Nenhum FAQ cadastrado </h6>
          <FAQList
            :categoria="resultadosFAQ.nome"
            :subcategorias="resultadosFAQ.subcategorias"
          />
        </div>
        <div v-else>
        </div>
      </div>
  </Layout>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import Layout from '../layouts/Layout.vue';
import Breadcrumb from '../components/Breadcrumb.vue';
import FAQList from '../components/FAQList.vue';
import FiltroAtendimento from '../components/FiltroAtendimento.vue';


export default {
  name: 'faq',
  components: {
    Layout,
    Breadcrumb,
    FAQList,
    FiltroAtendimento,
  },

  data() {
    return {
      breadcrumbs: [],
      isSetor: false,
      nomeSetor: '',
      exibeListaFAQs: false,
      resultadosFAQ: {},
      listaSetores: '',
      setores: [],
      categorias: [],
    };
  },

  created() {
    try {
      this.getCategorias();
      this.getSetores();
    } catch (error) {
      console.error(error);
    }
  },

  mounted() {
    this.breadcrumbs = [
      {
        route: {
          name: 'lista',
        },
        label: 'Início',
      },
      {
        route: {
          name: 'faq',
        },
        label: 'FAQ',
      },
    ];
  },

  methods: {
    filtraCategorias(categorias, setorId) {
      const categoriasSetor = categorias.filter(
        categoria => categoria.setorId === setorId,
      );
      return categoriasSetor;
    },

    async getSetores() {
      try {
        const { data: response } = await this.$http.get('/setores');
        this.listaSetores = response.map(item => ({
          id: item.id,
          nome: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },

    async getCategorias() {
      try {
        const { data: response } = await this.$http.get('/listar-categorias-faq');
        this.adjustColumns(response);
      } catch (error) {
        console.error(error);
      }
    },

    showResults(result) {
      let categorias;
      if (!isEmpty(result)) {
        this.exibeListaFAQs = false;
        if (result[0].categoria && !result[0].desativado) {
          this.isSetor = true;
          this.nomeSetor = result[0].nome;
          categorias = [];
          result[0].categoria.map((categoria) => {
            const subcategoriaBase = categoria.subcategoria;
            // eslint-disable-next-line no-param-reassign
            categoria.subcategoria = [];
            subcategoriaBase.map((subcategoria) => {
              if (subcategoria.mensagensFaqDuvida
                && subcategoria.mensagensFaqDuvida.url_socialwork) {
                categorias.push(categoria);
                categorias[categorias.length - 1].subcategoria.push(subcategoria);
              }
              return subcategoria;
            });
            return categoria;
          });
          if (!categorias.length) this.nomeSetor = 'Nenhum FAQ cadastrado';
          this.resultadosFAQ = categorias;
        } else {
          this.isSetor = false;
          let nome = '';
          const subcategorias = [];
          result.forEach((categoria) => {
            categoria.subcategoria.forEach((subcat) => {
              if (subcat.mensagensFaqDuvida && subcat.mensagensFaqDuvida.url_socialwork) {
                // eslint-disable-next-line prefer-destructuring
                nome = categoria.nome;
                subcategorias.push(subcat);
              }
            });
          });
          this.resultadosFAQ = {
            nome,
            subcategorias,
          };
        }
      } else {
        this.categorias = [];
        this.getCategorias();
      }
    },

    adjustColumns(response) {
      try {
        response.forEach((categoria) => {
          let obj = {};
          const array = [];
          categoria.subcategoria.forEach((element) => {
            if (element.mensagensFaqDuvida && element.mensagensFaqDuvida.url_socialwork) {
              array.push(element);
              obj = {
                setorId: categoria.setor_id,
                nome: categoria.nome,
                subcategorias: array,
              };
            }
          });

          this.categorias.push(obj);
        });
        this.exibeListaFAQs = true;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex-column {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
}

.flex-item {
  height: auto;
}

.flex-filter-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.search-filter {
  display: flex;
  width: 50%;
}

.setor-style{
  color: rgb(0, 0, 0);
  font-size: 27px;
  text-align: center;
  font-weight: bolder;
}
</style>
