<template>
  <span>
    <md-button
      :type="type"
      class="btn"
      :style="styles"
      :[tipo]="true"
      @click="callback"
    >
      {{ label }}
    </md-button>
  </span>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'Botao',
  props: {
    label: VueTypes.string.isRequired.def('Ok'),
    tipo: VueTypes.oneOf([
      'primary',
      'secondary',
      'disabled',
      'atendimento',
      'azulMeta',
    ]).def('primary'),
    styles: VueTypes.object.def({}),
    type: VueTypes.oneOf(['submit', 'button', 'reset']).def('submit'),
    callback: VueTypes.func.def(() => { }),
  },
};
</script>

<style lang="scss" scoped>
.btn[primary] {
  text-transform: inherit !important;
  background: #0056a8 !important;
  color: #fff;
  border-radius: 5px;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}
.btn[secondary] {
  text-transform: inherit !important;
  background: #fff;
  color: #122870;
  border-radius: 5px;
  border: 1px solid #bfbfbf;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}
.btn[disabled] {
  text-transform: inherit !important;
  background: #fff;
  color: black;
  border-radius: 5px;
  font-size: 16px !important;
  border: 1px solid #bfbfbf;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  opacity: 0.4;
  &:hover {
    color: #bfbfbf;
    opacity: 0.4;
  }
}
.btn[atendimento] {
  text-transform: inherit !important;
  background: #0056a8;
  color: white;
  border-radius: 5px;
  font-size: 16px !important;
  border: 1px solid #0056a8;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}
.btn[azulMeta] {
  text-transform: inherit !important;
  background: #122870;
  color: white;
  border-radius: 5px;
  font-size: 16px !important;
  border: 1px solid #122870;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}
</style>
