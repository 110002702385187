var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Snackbar',{attrs:{"label":_vm.msg,"top":"","right":"","timeout":2500,"tipo":_vm.alerta},model:{value:(_vm.snackbarPutPermissao),callback:function ($$v) {_vm.snackbarPutPermissao=$$v},expression:"snackbarPutPermissao"}}),_c('v-text-field',{attrs:{"label":"Pesquise um usuário","outlined":"","clearable":""},model:{value:(_vm.searchUser),callback:function ($$v) {_vm.searchUser=$$v},expression:"searchUser"}},[(!_vm.searchUser)?_c('v-icon',{attrs:{"slot":"append"},slot:"append"},[_vm._v("mdi-account-search")]):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"v-btn_add"},on),[_vm._v(" Adicionar novo ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"px-2",staticStyle:{"min-height":"270px"}},[(_vm.permissaoGestor.id_permissao
      === _vm.gestorGeral)?_c('v-card-title',{staticClass:"headline"},[_vm._v("Adicionar Permissão")]):_vm._e(),(_vm.permissaoGestor.id_permissao
      === _vm.gestor)?_c('v-card-title',{staticClass:"headline"},[_vm._v("Adicionar Permissão de responsável")]):_vm._e(),_c('v-card-subtitle',{staticClass:"mt-1"},[_c('FiltroAtendimento',{staticClass:"search-filter px-4",attrs:{"tipoFiltro":"searchUsuarios","quantidadeTags":1},on:{"changeFiltro":function($event){return _vm.showResults($event)}}})],1),(_vm.usuario
       && _vm.permissaoGestor.id_permissao === _vm.gestorGeral)?_c('v-card-subtitle',{staticClass:"mt-1"},[(_vm.usuario)?_c('v-select',{attrs:{"label":"Setor","items":_vm.setores,"outlined":"","dense":""},model:{value:(_vm.setor),callback:function ($$v) {_vm.setor=$$v},expression:"setor"}}):_vm._e()],1):_vm._e(),((_vm.permissaoGestor.id_permissao === _vm.gestor && _vm.usuario)
      || (_vm.permissaoGestor.id_permissao === _vm.gestorGeral && _vm.setor))?_c('v-card-subtitle',{staticClass:"mt-1"},[(_vm.usuario)?_c('v-select',{attrs:{"label":"Permissão","items":_vm.permissoes,"outlined":"","dense":""},model:{value:(_vm.permissao),callback:function ($$v) {_vm.permissao=$$v},expression:"permissao"}}):_vm._e()],1):_vm._e(),((_vm.permissao && _vm.permissaoGestor.id_permissao === _vm.gestorGeral)
      || (_vm.permissao && _vm.permissaoGestor.id_permissao === _vm.gestor))?_c('v-card-subtitle',[_c('v-card-actions',{staticClass:"pb-4"},[_c('v-spacer'),_c('Botao',{staticClass:"mr-4",attrs:{"type":"button","label":"Cancelar","tipo":"secondary","callback":_vm.closeModal}}),_c('Botao',{attrs:{"type":"submit","label":"Salvar","callback":_vm.salvaPermissao}})],1)],1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"scrollable":"","max-width":"500"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',{staticClass:"px-2"},[_c('v-card-title',{staticClass:"headline"},[_vm._v("Remover Permissão")]),_c('v-card-subtitle',{staticClass:"mt-1"},[_vm._v(" Deseja realmente remover a permissão? ")]),_c('v-card-actions',{staticClass:"pb-4"},[_c('v-spacer'),_c('Botao',{staticClass:"mr-4",attrs:{"type":"button","label":"Cancelar","tipo":"secondary","callback":_vm.closeModal}}),_c('Botao',{attrs:{"type":"button","label":"Confirmar","callback":_vm.deletePermissao}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.usuarios,"search":_vm.searchUser,"sort-by":'permissao_nome',"items-per-page":5,"no-results-text":'Usuário não encontrado.',"footer-props":{
      'items-per-page-text': 'Resultados por página',
      'items-per-page-all-text': 'Todos',
      'page-text': '{0}-{1} de {2}'
    }},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
    var item = ref.item;
return [(item.avatar)?_c('img',{attrs:{"src":(_vm.url + "/" + (item.avatar)),"alt":"Avatar","height":"20","width":"20"}}):_c('img',{attrs:{"src":require("../assets/img/user-placeholder.png"),"alt":"Avatar","height":"20","width":"20"}})]}},{key:"item.sede_nome",fn:function(ref){
    var item = ref.item;
return [(item.sede_nome)?_c('span',[_vm._v(_vm._s(item.sede_nome)+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.setor_nome",fn:function(ref){
    var item = ref.item;
return [(item.setor_nome)?_c('span',[_vm._v(_vm._s(item.setor_nome)+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.permissao_id",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',_vm._g({attrs:{"small":"","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteModal(item)}}},_vm.on),[_vm._v(" mdi-delete ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }