<template>
  <div>
    <v-card
      :max-height="600"
      :width="800"
      :min-width="200"
      :elevation="4"
      class="card-indicador mt-2 px-3 pt-4 pb-0"
    >
      <v-overlay
        :value="loaderAtivo"
        :absolute="true"
        :opacity="0.30"
      >
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <div ref="capture">
        <div class="div-cabecalho-reabertos">
          <span class="span-indicador">
            Satisfação de Atendimentos REABERTO(S)
          </span>
          <button
            class="botao-download"
            @click="screenShot()"
          ><span class="
              material-icons">
              get_app
            </span>
          </button>
        </div>
        <div class="pt-6">
          <div>
            Quantidade primeira avaliação:<strong> {{quantidade.primeiro}} </strong>
          </div>
          <div>
            Quantidade segunda avaliação:<strong> {{quantidade.reaberto}} </strong>
          </div>
        </div>
        <apexchart
          type="bar"
          :options="chartOptions"
          :series="series"
          width="330"
          height="300"
        ></apexchart>
      </div>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import VueTypes from 'vue-types';
import screenShot from '../../mixins/screenShot.js';


export default {
  name: 'IndicadorDeSatisfacaoDeAtendimentosReabertos',
  mixins: [screenShot],
  components: {
    apexchart: VueApexCharts,
  },

  props: {
    valores: VueTypes.object.isRequired,
    categorias: VueTypes.array.isRequired,
    quantidade: VueTypes.object.isRequired,
    loader: VueTypes.bool.isRequired,
  },

  data() {
    return {
      loaderAtivo: this.loader,
      series: [
        {
          name: this.categorias[0],
          data: [this.valores.primeiro[0], this.valores.reaberto[0]],
        },
        {
          name: this.categorias[1],
          data: [this.valores.primeiro[1], this.valores.reaberto[1]],
        },
        {
          name: this.categorias[2],
          data: [this.valores.primeiro[2], this.valores.reaberto[2]],
        },
        {
          name: this.categorias[3],
          data: [this.valores.primeiro[3], this.valores.reaberto[3]],
        },
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 320,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: 20,
            left: 20,
            bottom: 30,
          },
        },
        xaxis: {
          type: 'category',
          categories: ['1ªAvaliação', '2ªAvaliação'],
          labels: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        legend: {
          position: 'right',
          offsetX: 0,
          offsetY: 100,
        },
        colors: ['#ef1602', '#EF7F3C', '#97C93D', '#0038FF'],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.div-cabecalho-reabertos {
  display: flex;
  justify-content: space-between;

  .span-indicador {
    font-weight: bold;
    color: #333;
    font-size: 15px;
  }

  .botao-download {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    border-radius: 5px;
    border: 2px solid #eeeeee;

    &:hover {
      background-color: #e2dede;
    }

    .material-icons {
      color: #0056a8;
      font-size: 25px;
    }
  }
}
.subtitulo-numero {
  font-weight: bold;
}
.card-indicador {
  border-radius: 20px !important;
  padding-bottom:  20px !important;
}
</style>
<style lang="scss">
.card-indicador {
  border-radius: 20px !important;
  padding-bottom:  20px !important;
}
</style>
