<template>
  <div>
    <v-dialog
      v-model="modalOpened"
      max-width="500"
      persistent
    >
      <v-card class="master">
        <div class="principal">
          <v-card-title class="headline">Prioridade!</v-card-title>
          <img
          src="../assets/img/prioridade-image.png"
          alt="Modal Prioridade"/>
        </div>
        <hr>
        <h5 class="textoPrincipal">Selecione uma das opções para prosseguir</h5>
        <hr>
        <div class="botoes">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="btn-dialog mr-2 mb-2 botao-urgente"
              text
              @click="retornaPrioridade(1)"
            >
              URGENTE
            </v-btn>
            <v-btn
              class="btn-dialog mr-2 mb-2 botao-prioritario"
              text
              @click="retornaPrioridade(2)"
            >
              PRIORITÁRIO
            </v-btn>
            <v-btn
              class="btn-dialog mr-2 mb-2 botao-normal"
              text
              @click="retornaPrioridade(3)"
            >
              NORMAL
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'ModalPrioridade',
  props: {
    modalOpen: VueTypes.boolean,
  },

  data: () => ({
    modalOpened: '',
  }),

  created() {
    this.modalOpened = this.modalOpen;
  },

  methods: {
    retornaPrioridade(valor) {
      this.$emit('prioridadeChanged', valor);
      this.modalOpened = false;
    },
  },
};

</script>
<style scoped lang="scss">
.principal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.botoes{
  display: flex;
  justify-content: center;
}
.textoPrincipal{
  display: flex;
  justify-content: center;
}
.botao-urgente {
  background-color: #CF1717 !important;
  border: none !important;
}
.botao-prioritario {
  background-color: #DAC81C !important;
  border: none !important;
}
.botao-normal {
  background-color: #2BAE3A !important;
  border: none !important;
}
.master {
  padding: 10px;
}
</style>
