import { validaPermissao } from '../../utils/utilities';

export default function relatorio({ next, store }) {
  if (validaPermissao(store.getters.isUsuario.permissao,
    store.getters.listaPermissoes, store.getters.listaPermissoes.relatorio)
    || validaPermissao(store.getters.isUsuario.permissao,
      store.getters.listaPermissoes, store.getters.listaPermissoes.gestor)) {
    return next();
  }
  const pagina = window.location.pathname;
  return next({
    name: 'login',
    params: { fromPagina: pagina },
  });
}
