<template>
  <header @mouseleave="toggleLeave">
    <img
      src="../assets/img/Meta-Conecta-preferencial.png"
      alt="logo"
      class="logo"
      @click="chamaRotaHome"
    />
    <nav :class="menuDown ? 'active' : ''">
      <div
        class="mudar-visao">
        <v-btn
          v-if="showButton"
          class="primary"
          @click="validateVision"
          >
          {{ validateButton }}
        </v-btn>
      </div>
      <Notificacao :notificacoes="notificacoes" />
      <div class="nav-component">
        <span class="dados-usuario">
          <span class="nome">{{ splitNome(this.usuario.nome) }}</span>
          <span
            class="sede"
            :class="usuario.sede_id ? '' : 'active'"
          >
            {{ usuario.sede_id ? usuario.sede_nome : 'Informe sua sede' }}
            <v-icon
              dark
              @click="toggleSede"
            >mdi-pencil</v-icon>
          </span>
        </span>
        <a
          @mouseenter="toggleCamActive"
          @mouseleave="toggleCamActive"
        >
          <InputImagem
            v-model="avatar"
            :loadAvatar="loadAvatar"
          >
            <div slot="activator">
              <v-progress-circular
                v-if="loadAvatar"
                indeterminate
                :size="64"
                :color="'primary'"
              ></v-progress-circular>
             <v-avatar
                v-else
                size="64px"
                v-ripple
                :class="!avatar ? 'avatar-iniciais' : 'avatar'"
              >
                <span v-if="!avatarActive">{{ nomeIniciais(this.usuario.nome) }}</span>
                <img
                  v-else
                  :src="avatarActive"
                  alt="avatar"
                >
                <img
                  class="avatar-cam-avatar"
                  v-if="camIsActive"
                  :src="Cam"
                  alt="avatar"
                >
              </v-avatar>

            </div>
          </InputImagem>
        </a>
        <a @click="toggle">
          <img
            class="setinha"
            src="../assets/icons/setinha.svg"
            alt="setinha"
          >
        </a>
      </div>

      <transition name="slide-fade">
        <div
          class="toggle"
          v-if="activeToggle"
          :class="activeToggle ? 'active-toggle' : ''"
        >
          <a
            class="link"
            @click="deslogar"
          >
            <span>
              <img
                class="icons"
                src="../assets/icons/exit.svg"
                alt="icon sair"
              /> Sair
            </span>
          </a>
        </div>
      </transition>

      <div
        class="toggle-sede"
        :class="activeToggleSede ? 'active-toggle-sede' : ''"
      >
        <ul
         class="sedes-list-menu"
        >
          <li
            @click="putSedeUsuario(item.id)"
            v-for="(item, index) in sedes"
            :key="index"
          >
            {{ item.nome }}
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import Cam from '../assets/icons/cam.svg';
import InputImagem from './InputImagem.vue';
import nomeIniciais from '../mixins/nomeIniciais.js';
import splitNome from '../mixins/splitNome.js';
import Notificacao from './Notificacao';
import { getMaxPermission, getPermissionsList, orderByAlphabetical } from '../utils/utilities';

export default {
  name: 'Navegacao',
  components: {
    InputImagem,
    Notificacao,
  },
  mixins: [nomeIniciais, splitNome],
  data() {
    return {
      logado: this.$store.getters.logado,
      Cam,
      activeToggle: false,
      activeToggleSede: false,
      camIsActive: false,
      menuDown: false,
      sedes: {},
      usuario: {},
      avatar: null,
      avatarActive: '',
      notificacoes: [],
      loadAvatar: false,
    };
  },
  created() {
    this.getSedes();
    this.usuario = this.$store.getters.isUsuario;
    this.$store.dispatch('inscricaoNotificacao', this.$store.getters.isUsuario.id);
    this.carregaNotificacoes();
    this.avatarActive = this.$store.getters.usuarioAvatar;
  },
  mounted() {
    this.$ws.$on(`notify:${this.$store.getters.isUsuario.id}|comentario`, this.handleNotificaoEvent);
    this.$ws.$on(`notify:${this.$store.getters.isUsuario.id}|status`, this.handleNotificaoEvent);
    this.$ws.$on(`notify:${this.$store.getters.isUsuario.id}|encerramento`, this.handleNotificaoEvent);
    this.$ws.$on(`notify:${this.$store.getters.isUsuario.id}|atribuicao`, this.handleNotificaoEvent);
    this.$ws.$on(`notify:${this.$store.getters.isUsuario.id}|mencao`, this.handleNotificaoEvent);
    this.$ws.$on(`notify:${this.$store.getters.isUsuario.id}|resposta`, this.handleNotificaoEvent);
  },
  beforeDestroy() {
    this.$ws.$off(`notify:${this.usuario.id}|comentario`, this.handleNotificaoEvent);
    this.$ws.$off(`notify:${this.usuario.id}|status`, this.handleNotificaoEvent);
    this.$ws.$off(`notify:${this.usuario.id}|encerramento`, this.handleNotificaoEvent);
    this.$ws.$off(`notify:${this.usuario.id}|atribuicao`, this.handleNotificaoEvent);
    this.$ws.$off(`notify:${this.usuario.id}|mencao`, this.handleNotificaoEvent);
    this.$ws.$off(`notify:${this.usuario.id}|resposta`, this.handleNotificaoEvent);
  },
  watch: {
    avatar(newVal) {
      if (newVal) {
        this.handleClick();
      }
    },
  },
  computed: {
    showButton() {
      const permissaoListaUsuario = this.usuario.permissao;

      const validation = permissaoListaUsuario.filter(element => element.id_permissao
        === this.$store.getters.listaPermissoes.gestorgeral
        || element.id_permissao === this.$store.getters.listaPermissoes.gestor
        || element.id_permissao === this.$store.getters.listaPermissoes.atendente).length;

      const validationButton = !!validation || this.usuario.permissao[0].visaoBotao;

      return validationButton;
    },
    validateButton() {
      if (this.usuario.permissao.length === 1
      && this.usuario.permissao[0].id_permissao
        === this.$store.getters.listaPermissoes.colaborador) {
        return 'Visão atendente';
      }
      return 'Visão usuário';
    },
    sede() {
      return this.$store.getters.isUsuario.sede_nome;
    },
    usuarioRotaLogo() {
      return getMaxPermission(this.$store).id_permissao === this.$store.getters.listaPermissoes.atendente ? '/lista-usuario' : '/lista';
    },
  },
  methods: {
    async validateVision() {
      if (this.usuario.permissao.length === 1
        && this.usuario.permissao[0].id_permissao
          === this.$store.getters.listaPermissoes.colaborador) {
        await this.toChangeViewAtendente();
      } else {
        await this.toChangeViewUSer();
      }
    },

    async toChangeViewAtendente() {
      const { data: response } = await this.$http.get(
        `/usuariohaspermissoes/${this.$store.getters.isUsuario.id}`,
      );
      this.usuario.permissao = getPermissionsList(response);

      this.$store.commit('UPDATE_USUARIO', this.usuario);
      this.$router.push('/lista');
    },

    async toChangeViewUSer() {
      this.usuario.permissao = [{
        id_permissao: this.$store.getters.listaPermissoes.colaborador,
        visaoBotao: true,
      }];
      this.$store.commit('UPDATE_USUARIO', this.usuario);
      this.$router.push('/lista-usuario');
    },

    async handleClick() {
      try {
        this.loadAvatar = true;
        const file = this.avatar;

        const id = this.$store.getters.isUsuario.avatar_id;
        if (!id) {
          await this.$http.post('/avatar', file.formData);
        } else {
          await this.$http.put(`/avatar/${id}`, file.formData);
        }

        this.snackbarAvatarSuccess = true;
        await this.getUsuario();
        await this.buscaAvatarBanco();
        this.avatarActive = this.$store.getters.usuarioAvatar;
        this.loadAvatar = false;
      } catch (error) {
        console.error(error);
      }
    },
    toggleCamActive() {
      this.camIsActive = !this.camIsActive;
    },
    toggleSede() {
      this.activeToggleSede = !this.activeToggleSede;
      this.activeToggle = false;
    },
    toggle() {
      this.activeToggle = !this.activeToggle;
      this.activeToggleSede = false;
    },
    toggleLeave() {
      this.activeToggle = false;
    },
    deslogar() {
      try {
        this.$store.commit('UPDATE_USUARIO', null);
        this.$store.commit('UPDATE_LOGIN', false);
        this.$store.commit('UPDATE_INSCRICAO', null);
        this.$store.commit('UPDATE_USUARIO_AVATAR', null);
        this.$store.commit('UPDATE_MENULATERAL', false);
        this.$router.push('/login');
        localStorage.removeItem('usuario');
      } catch (error) {
        console.error(error);
      }
    },
    async putSedeUsuario(sede) {
      try {
        this.activeToggleSede = false;
        const body = {
          sede_id: sede,
        };
        const { id } = this.$store.getters.isUsuario;
        await this.$http.put(`/usuarios/${id}`, body);
        await this.getUsuario();
        this.$emit('sedeChanged');
      } catch (error) {
        console.error(error);
      }
    },
    async getUsuario() {
      try {
        const { data: response } = await this.$http.get(`/usuarios/${this.$store.getters.isUsuario.id}`);
        this.usuario = response;
        response.permissao = getMaxPermission(this.$store).id_permissao;
        this.$store.commit('UPDATE_USUARIO', response);
      } catch (error) {
        console.error(error);
      }
    },
    async getSedes() {
      try {
        const { data: response } = await this.$http.get('/sedes/');
        this.sedes = orderByAlphabetical(response, 'sedes');
      } catch (error) {
        console.error(error);
      }
    },
    handleNotificaoEvent(data) {
      const notif = {
        status: data.mensagem,
        nome: data.nome,
        id: data.idNotificacao,
        id_tipo: data.tipo,
        id_atendimento: data.idAtendimento,
        data: data.dataCriacao,
        link: data.urlAtendimento,
        notificacaoLida: data.visualizada,
      };
      if (!this.notificacoes.find(e => e.id === notif)) {
        this.notificacoes.unshift(notif);
        if (this.notificacoes.length > 5) {
          this.notificacoes.pop();
        }
      }
    },
    async carregaNotificacoes() {
      const { data: response } = await this.$http.get(`/notificacao/${this.$store.getters.isUsuario.id}`);

      this.notificacoes = response;
    },
    chamaRotaHome() {
      this.$router.push('/');
    },
    async buscaAvatarBanco() {
      try {
        const { data: response } = await this.$http.get(`/busca-avatar/${this.$store.getters.isUsuario.avatar_id}`);
        this.$store.commit('UPDATE_USUARIO_AVATAR', response);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
header {
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  z-index: 30;
  height: 92px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  & nav {
    display: flex;
  }
  & .nav-component {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.sedes-list-menu {
  height:300px;
  width:100%;
  overflow:hidden;
  overflow-y:scroll;
}
.logo {
  cursor: pointer;
  width: 220px;
  margin-left: 50px;
}
.dados-usuario {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10px;
}
.icons {
  width: 32px;
}
.setinha {
  cursor: pointer;
  margin-right: 10px;
  &:hover {
    opacity: 0.5;
  }
}
.nome {
  font-size: 16px;
  font-weight: 500;
  color: #303030;
  text-transform: capitalize;
}
.sede.active {
  color: #c1c1c1;
}
.sede {
  font-size: 16px;
  color: #303030;
  & img {
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
}
.toggle-sede.active-toggle-sede {
  display: flex;
}
.toggle-sede {
  display: none;
  z-index: 31;
  margin-top: 65px;
  width: 181px;
  right: 108px;
  padding: 0;
  justify-content: flex-start;
  position: fixed;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  & ul {
    margin: 0;
    padding: 0;
  }
  & ul li {
    font-weight: 500;
    width: 100%;
    list-style: none;
    font-size: 18px;
    padding: 10px 12px;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    cursor: pointer;
    &:last-child {
      border: none;
    }
    &:hover {
      background-color: rgba(158, 231, 255, 0.5);
    }
  }
}
.toggle.active-toggle {
  display: flex;
}
.toggle {
  display: none;
  z-index: 31;
  flex-direction: column;
  justify-content: center;
  margin-top: 77px;
  position: fixed;
  right: 15px;
  padding: 8px 0;
  background-color: #122870;
  border-radius: 4px;
  & a:hover {
    text-decoration: none;
  }
  & a span {
    padding: 2px 10px 0 2.5px;
    display: flex;
    color: white !important;
    width: 100%;
    font-size: 16px;
    &:hover {
      text-decoration: none;
      background-color: rgba(158, 231, 255, 0.5);
    }
  }
}
.link {
  width: 141px;
}
@media screen and (max-width: 860px) {
  header {
    .menu-down {
      display: block;
      transition: all 0.3s;
    }
    & nav.active {
      display: flex;
      transition: all 0.3s;
    }
    & nav {
      display: none;
      position: fixed;
      right: 0px;
      top: 110px;
      justify-content: flex-end;
      background: white;
      width: 100%;
      padding: 0 20px 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      transition: all 0.3s;
    }
  }
}
.avatar {
  width: 64px;
  transition: all 0.3s;
}
.avatar-iniciais.active {
  z-index: 15;
  opacity: 0.3;
}
.mudar-visao {
  margin: 15px 50px 20px 0;
}

.avatar-iniciais {
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.12);
  color: white;
  font-size: 22px;
  font-weight: 500;
  background: #0056a8;
  z-index: 31;
  top: 0px;
  transition: all 0.3s;
}
.avatar-cam-avatar {
  transition: all 0.3s;
  display: flex;
  top: 15px;
  background: #30303064;
  position: fixed;
  padding: 18px;
  width: 64px;
  border-radius: 50%;
  z-index: 31;
}
</style>
