<template>
  <div id="opcoesInativas">
    <Snackbar
      :label="msg"
      v-model="snackbarOpen"
      top
      right
      :tipo="'success'"
      :timeout="2500"
    />
    <v-data-table
      item-key="descricao"
      :headers="headers"
      :items="inativos"
      :search="searchUser"
      :sort-by="['categoria_nome', 'subcategoria_nome']"
      :items-per-page="5"
      :no-data-text="'Não há setores/categorias/subcategorias inativos.'"
      :no-results-text="'Setor/categoria/subcategoria não encontrada.'"
      :footer-props="{
        'items-per-page-text': 'Resultados por página',
        'items-per-page-all-text': 'Todos',
        'page-text': '{0}-{1} de {2}',
    }"
    >
      <template v-slot:top>
        <v-text-field
          v-model="searchUser"
          label="Pesquise um setor/categoria/subcategoria"
          outlined
          clearable
        >
          <v-icon
            v-if="!searchUser"
            slot="append"
          >mdi-account-search</v-icon>
        </v-text-field>
        <v-toolbar
          flat
          color="white"
        >
          <v-dialog
            v-model="dialogReativar"
            scrollable
            max-width="500"
          >
            <v-card class="px-2">
              <v-card-title class="headline">
                Reativar {{tipoSelecionado}}?
              </v-card-title>
              <!-- <v-card-subtitle class="mt-1">
                adicionar aqui texto no corpo do modal se necessário
              </v-card-subtitle> -->
              <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <Botao
                  class="mr-4"
                  type="button"
                  label="Cancelar"
                  tipo="secondary"
                  :callback="closeModal"
                />
                <Botao
                  type="button"
                  label="Confirmar"
                  :callback="reativarTipo"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.setor_nome="{item}">
        {{ item.setor_nome }}
      </template>
      <template v-slot:item.categoria_nome="{item}">
        {{ item.categoria_nome }}
      </template>
      <template v-slot:item.subcategoria_nome="{item}">
        {{ item.subcategoria_nome }}
      </template>
      <template v-slot:item.acoes="{item}">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              dark
              v-on="on"
              @click.stop="reativarItem(item)"
            >
              mdi-recycle
            </v-icon>
          </template>
          <span>Reativar</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Botao from './Botao.vue';
import Snackbar from './Snackbar.vue';
import EventBus from '../plugins/EventBus';
import { getMaxPermission } from '../utils/utilities';

export default {
  name: 'OpcoesInativas',
  components: {
    Botao,
    Snackbar,
  },
  data() {
    return {
      snackbarOpen: false,
      tipoSnackbar: 'info',
      msg: '',
      dialogReativar: false,
      searchUser: '',
      inativos: [],
      setores: [],
      categorias: [],
      tipoSelecionado: '',
      idSelecionado: 0,
      headers: [
        { text: 'Setor', value: 'setor_nome' },
        { text: 'Categoria', value: 'categoria_nome' },
        { text: 'Subcategoria', value: 'subcategoria_nome' },
        { text: 'Ações', value: 'acoes', sortable: false },
      ],
      gestor: this.$store.getters.listaPermissoes.gestor,
      gestorGeral: this.$store.getters.listaPermissoes.gestorgeral,
      permissaoGestor: getMaxPermission(this.$store),
    };
  },
  watch: {
    dialog(val) {
      return val || this.closeModal();
    },
  },
  async mounted() {
    try {
      await this.getInativos();
    } catch (error) {
      console.error(error);
    }
  },
  created() {
    EventBus.$on('BackofficeServiceDisabled', () => {
      this.getInativos();
    });
  },
  methods: {
    closeModal() {
      this.dialogReativar = false;
      this.tipoSelecionado = '';
      this.idSelecionado = 0;
    },
    async getInativos() {
      this.inativos = [];
      await this.getSetores();
      await this.getCategorias();
      await this.getSubcategorias();
    },
    async getSetores() {
      try {
        const { data: response } = await this.$http.get('/setores-inativos');
        this.setores.push(...response);
        response.forEach((item) => {
          this.inativos.push({
            setor_nome: item.nome,
            categoria_nome: '-',
            subcategoria_nome: '-',
            tipo: 'setor',
            id: item.id,
            setor_id: item.setor_id,
          });
          if (this.permissaoGestor.id_permissao === this.gestor) {
            this.inativos = this.inativos.filter(value => value.setor_id
            === this.permissaoGestor.id_setor);
          }
        });
        const { data: responseAtivos } = await this.$http.get('/setores');
        this.setores.push(...responseAtivos);
      } catch (error) {
        console.error(error);
      }
    },
    async getCategorias() {
      try {
        const { data: response } = await this.$http.get('/categorias-inativas');
        this.categorias = response;
        response.forEach((item) => {
          const setor = this.setores.find(element => element.id === item.setor_id);
          this.inativos.push({
            setor_nome: setor.nome,
            categoria_nome: item.nome,
            subcategoria_nome: '-',
            tipo: 'categoria',
            id: item.id,
            setor_id: item.setor_id,
          });
        });
        if (this.permissaoGestor.id_permissao === this.gestor) {
          this.inativos = this.inativos.filter(value => value.setor_id
            === this.permissaoGestor.id_setor);
        }
        const { data: responseAtivos } = await this.$http.get('/categorias');
        this.categorias.push(...responseAtivos);
      } catch (error) {
        console.error(error);
      }
    },
    async getSubcategorias() {
      try {
        const { data: response } = await this.$http.get('/subcategorias-inativas');
        response.forEach((item) => {
          if (item.desativado) {
            const categoria = this.categorias.find(element => element.id === item.categoria_id);
            const setor = this.setores.find(element => element.id === categoria.setor_id);
            this.inativos.push({
              setor_nome: setor.nome,
              setor_id: setor.id,
              categoria_nome: categoria.nome,
              subcategoria_nome: item.nome,
              tipo: 'subcategoria',
              id: item.id,
            });
            if (this.permissaoGestor.id_permissao === this.gestor) {
              this.inativos = this.inativos.filter(value => value.setor_id
            === this.permissaoGestor.id_setor);
            }
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    reativarItem(item) {
      this.idSelecionado = item.id;
      this.tipoSelecionado = item.tipo;
      this.dialogReativar = true;
    },
    async reativarTipo() {
      try {
        let rota = '';
        if (this.tipoSelecionado === 'setor') rota = 'setores';
        else if (this.tipoSelecionado === 'categoria') rota = 'categorias';
        else if (this.tipoSelecionado === 'subcategoria') rota = 'subcategorias';
        await this.$http.put(`/${rota}/${this.idSelecionado}`, { desativado: false });
        // eslint-disable-next-line
        this.msg = (this.tipoSelecionado + ' reativado(a) com sucesso!');
        this.snackbarOpen = true;
        this.closeModal();
        this.getInativos();
        EventBus.$emit('BackofficeServiceEnabled');
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.v-data-table tbody tr.v-data-table__expanded__content {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.descricao {
  strong {
    font-weight: bold;
  }

  p {
    margin: 0px !important;
  }
}
</style>
