var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('p',{staticClass:"totalAttendance"},[_vm._v("Atendimentos em aberto: "+_vm._s(_vm.getTotalAttendance()))]),_c('hr'),_c('div',{staticClass:"topbar"},[_c('FiltroAtendimento',{staticClass:"input-filtro",attrs:{"tipoFiltro":"searchAtendimentos","quantidadeTags":2,"permissao":this.permissaoMax},on:{"changeFiltro":function($event){return _vm.filtrar($event)}}}),_c('div',{staticClass:"input-select"},[_c('InputSelect',{attrs:{"options":_vm.opcoes},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}})],1)],1),_c('div',{staticClass:"lista-atendente"},[_c('div',[_c('ColunaStatus',{attrs:{"titulo":"Pendente","ordenar":_vm.pendente,"total":_vm.pendente.length}},[_c('draggable',_vm._b({staticClass:"draggable-wrapper",attrs:{"name":"pendente","move":_vm.onMove},on:{"start":function($event){_vm.isDragging=true},"end":function($event){_vm.isDragging=false},"change":_vm.onDrop},model:{value:(_vm.pendente),callback:function ($$v) {_vm.pendente=$$v},expression:"pendente"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.pendente),function(item,index){return _c('div',{key:index},[(_vm.filtro === _vm.todosAtend ||
                  (_vm.filtro === _vm.usuarioAtend && item.id_atendente === _vm.usuario) ||
                  (_vm.filtro === _vm.atend && item.id_usuario === _vm.usuario))?_c('CardAtendimento',{key:index,attrs:{"link":("/atendimento/" + (item.id_atendimento)),"nomeUsuario":item.nome,"setorOrigem":item.setor_origem,"subcategoria":item.subcategoria,"descricao":item.descricao,"status":"#ff6b00","statusLabel":item.status,"tipo":item.categoria,"data":_vm._f("formatDate")(item.data_criacao),"avatar":_vm.getAvatar(item),"alt":"avatar","atendenteAtribuido":!!item.id_atendente,"nome":item.atendente_nome,"id":item.id_atendimento,"id_fluxo":item.id_fluxo ? item.id_fluxo : 0,"funcionario":item.dados_fluxo ?
              item.dados_fluxo.funcionario : 'Sem funcionario',"unidade":item.dados_fluxo ?
              item.dados_fluxo.unidade : 'Sem unidade',"data_desligamento":item.dados_fluxo ?
              item.dados_fluxo.dataDesligamento : 'Sem data de desligamento',"dataAvisoPrevio":item.dados_fluxo ?
              item.dados_fluxo.dataAvisoPrevio : 'Sem data de aviso prévio',"idPrioridade":item.id_prioridade}}):_vm._e()],1)}),0)],1)],1),_c('div',[_c('ColunaStatus',{attrs:{"titulo":"Em análise","ordenar":_vm.analise,"total":_vm.analise.length}},[_c('draggable',_vm._b({staticClass:"draggable-wrapper",attrs:{"name":"analise","move":_vm.onMove},on:{"start":function($event){_vm.isDragging=true},"end":function($event){_vm.isDragging=false},"change":_vm.onDrop},model:{value:(_vm.analise),callback:function ($$v) {_vm.analise=$$v},expression:"analise"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.analise),function(item,index){return _c('div',{key:index},[(_vm.filtro === _vm.todosAtend ||
                  (_vm.filtro === _vm.usuarioAtend && item.id_atendente === _vm.usuario) ||
                  (_vm.filtro === _vm.atend && item.id_usuario === _vm.usuario))?_c('CardAtendimento',{key:index,attrs:{"link":("/atendimento/" + (item.id_atendimento)),"nomeUsuario":item.nome,"setorOrigem":item.setor_origem,"subcategoria":item.subcategoria,"descricao":item.descricao,"status":"#8E24AA","statusLabel":item.status,"tipo":item.categoria,"data":_vm._f("formatDate")(item.data_criacao),"avatar":_vm.getAvatar(item),"alt":"avatar","atendenteAtribuido":!!item.id_atendente,"nome":item.atendente_nome,"id":item.id_atendimento,"id_fluxo":item.id_fluxo ? item.id_fluxo : 0,"funcionario":item.dados_fluxo ?
              item.dados_fluxo.funcionario : 'Sem funcionario',"unidade":item.dados_fluxo ?
              item.dados_fluxo.unidade : 'Sem unidade',"data_desligamento":item.dados_fluxo ?
              item.dados_fluxo.dataDesligamento : 'Sem data de desligamento',"dataAvisoPrevio":item.dados_fluxo ?
              item.dados_fluxo.dataAvisoPrevio : 'Sem data de aviso prévio',"idPrioridade":item.id_prioridade}}):_vm._e()],1)}),0)],1)],1),_c('div',[_c('ColunaStatus',{attrs:{"titulo":"Aguardando responsável","ordenar":_vm.aguardandoAtendente,"total":_vm.aguardandoAtendente.length}},[_c('draggable',_vm._b({staticClass:"draggable-wrapper",attrs:{"name":"aguardandoAtendente","move":_vm.onMove},on:{"start":function($event){_vm.isDragging=true},"end":function($event){_vm.isDragging=false},"change":_vm.onDrop},model:{value:(_vm.aguardandoAtendente),callback:function ($$v) {_vm.aguardandoAtendente=$$v},expression:"aguardandoAtendente"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.aguardandoAtendente),function(item,index){return _c('div',{key:index},[(_vm.filtro === _vm.todosAtend ||
                  (_vm.filtro === _vm.usuarioAtend && item.id_atendente === _vm.usuario) ||
                  (_vm.filtro === _vm.atend && item.id_usuario === _vm.usuario))?_c('CardAtendimento',{key:index,attrs:{"link":("/atendimento/" + (item.id_atendimento)),"nomeUsuario":item.nome,"setorOrigem":item.setor_origem,"subcategoria":item.subcategoria,"descricao":item.descricao,"status":"#027cee","statusLabel":item.status,"tipo":item.categoria,"data":_vm._f("formatDate")(item.data_criacao),"avatar":_vm.getAvatar(item),"alt":"avatar","atendenteAtribuido":!!item.id_atendente,"nome":item.atendente_nome,"id":item.id_atendimento,"id_fluxo":item.id_fluxo ? item.id_fluxo : 0,"funcionario":item.dados_fluxo ?
              item.dados_fluxo.funcionario : 'Sem funcionario',"unidade":item.dados_fluxo ?
              item.dados_fluxo.unidade : 'Sem unidade',"data_desligamento":item.dados_fluxo ?
              item.dados_fluxo.dataDesligamento : 'Sem data de desligamento',"dataAvisoPrevio":item.dados_fluxo ?
              item.dados_fluxo.dataAvisoPrevio : 'Sem data de aviso prévio',"idPrioridade":item.id_prioridade}}):_vm._e()],1)}),0)],1)],1),_c('div',[_c('ColunaStatus',{attrs:{"titulo":"Em atendimento","ordenar":_vm.andamento,"total":_vm.andamento.length}},[_c('draggable',_vm._b({staticClass:"draggable-wrapper",attrs:{"name":"andamento","move":_vm.onMove},on:{"start":function($event){_vm.isDragging=true},"end":function($event){_vm.isDragging=false},"change":_vm.onDrop},model:{value:(_vm.andamento),callback:function ($$v) {_vm.andamento=$$v},expression:"andamento"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.andamento),function(item,index){return _c('div',{key:index},[(_vm.filtro === _vm.todosAtend ||
                  (_vm.filtro === _vm.usuarioAtend && item.id_atendente === _vm.usuario) ||
                  (_vm.filtro === _vm.atend && item.id_usuario === _vm.usuario))?_c('CardAtendimento',{key:index,attrs:{"link":("/atendimento/" + (item.id_atendimento)),"nomeUsuario":item.nome,"setorOrigem":item.setor_origem,"subcategoria":item.subcategoria,"descricao":item.descricao,"status":"#97c93d","statusLabel":item.status,"tipo":item.categoria,"data":_vm._f("formatDate")(item.data_criacao),"avatar":_vm.getAvatar(item),"alt":"avatar","atendenteAtribuido":!!item.id_atendente,"nome":item.atendente_nome,"id":item.id_atendimento,"id_fluxo":item.id_fluxo ? item.id_fluxo : 0,"funcionario":item.dados_fluxo ?
              item.dados_fluxo.funcionario : 'Sem funcionario',"unidade":item.dados_fluxo ?
              item.dados_fluxo.unidade : 'Sem unidade',"data_desligamento":item.dados_fluxo ?
              item.dados_fluxo.dataDesligamento : 'Sem data de desligamento',"dataAvisoPrevio":item.dados_fluxo ?
              item.dados_fluxo.dataAvisoPrevio : 'Sem data de aviso prévio',"idPrioridade":item.id_prioridade}}):_vm._e()],1)}),0)],1)],1),_c('div',[_c('ColunaStatus',{attrs:{"titulo":"Aguardando usuário/gestor","ordenar":_vm.aguardando,"total":_vm.aguardando.length}},[_c('draggable',_vm._b({staticClass:"draggable-wrapper",attrs:{"name":"aguardando","move":_vm.onMove},on:{"start":function($event){_vm.isDragging=true},"end":function($event){_vm.isDragging=false},"change":_vm.onDrop},model:{value:(_vm.aguardando),callback:function ($$v) {_vm.aguardando=$$v},expression:"aguardando"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.aguardando),function(item,index){return _c('div',{key:index},[(_vm.filtro === _vm.todosAtend ||
                  (_vm.filtro === _vm.usuarioAtend && item.id_atendente === _vm.usuario) ||
                  (_vm.filtro === _vm.atend && item.id_usuario === _vm.usuario))?_c('CardAtendimento',{key:index,attrs:{"link":("/atendimento/" + (item.id_atendimento)),"nomeUsuario":item.nome,"setorOrigem":item.setor_origem,"subcategoria":item.subcategoria,"descricao":item.descricao,"status":"#ffe500","statusLabel":item.status,"tipo":item.categoria,"data":_vm._f("formatDate")(item.data_criacao),"avatar":_vm.getAvatar(item),"alt":"avatar","atendenteAtribuido":!!item.id_atendente,"nome":item.atendente_nome,"id":item.id_atendimento,"id_fluxo":item.id_fluxo ? item.id_fluxo : 0,"funcionario":item.dados_fluxo ?
              item.dados_fluxo.funcionario : 'Sem funcionario',"unidade":item.dados_fluxo ?
              item.dados_fluxo.unidade : 'Sem unidade',"data_desligamento":item.dados_fluxo ?
              item.dados_fluxo.dataDesligamento : 'Sem data de desligamento',"dataAvisoPrevio":item.dados_fluxo ?
              item.dados_fluxo.dataAvisoPrevio : 'Sem data de aviso prévio',"idPrioridade":item.id_prioridade}}):_vm._e()],1)}),0)],1)],1),_c('div',[_c('ColunaStatus',{attrs:{"titulo":"Encerrado","ordenar":_vm.encerrado,"total":_vm.encerrado.length,"decrescente":""}},[_c('draggable',_vm._b({staticClass:"draggable-wrapper",attrs:{"name":"encerrado","move":_vm.onMove},on:{"start":function($event){_vm.isDragging=true},"end":function($event){_vm.isDragging=false},"change":_vm.onDrop},model:{value:(_vm.encerrado),callback:function ($$v) {_vm.encerrado=$$v},expression:"encerrado"}},'draggable',_vm.dragOptions,false),[_c('div',{staticClass:"botao-encerrados-container"},[(_vm.encerrado.length === 0)?_c('Botao',{staticClass:"ml-3",attrs:{"type":"button","label":'Carregar encerrados',"tipo":"primary","callback":_vm.chamaEncerrados}}):_vm._e()],1),_vm._l((_vm.encerrado),function(item,index){return _c('div',{key:index},[(_vm.filtro === _vm.todosAtend ||
                  (_vm.filtro === _vm.usuarioAtend && item.id_atendente === _vm.usuario) ||
                  (_vm.filtro === _vm.atend && item.id_usuario === _vm.usuario))?_c('CardAtendimento',{key:index,attrs:{"link":("/atendimento/" + (item.id_atendimento)),"nomeUsuario":item.nome,"setorOrigem":item.setor_origem,"subcategoria":item.subcategoria,"descricao":item.descricao,"status":"#ee0202","statusLabel":item.status,"tipo":item.categoria,"data":_vm._f("formatDate")(item.data_criacao),"avatar":_vm.getAvatar(item),"alt":"avatar","atendenteAtribuido":!!item.id_atendente,"nome":item.atendente_nome,"id":item.id_atendimento,"id_fluxo":item.id_fluxo ? item.id_fluxo : 0,"funcionario":item.dados_fluxo ?
              item.dados_fluxo.funcionario : 'Sem funcionario',"unidade":item.dados_fluxo ?
              item.dados_fluxo.unidade : 'Sem unidade',"data_desligamento":item.dados_fluxo ?
              item.dados_fluxo.dataDesligamento : 'Sem data de desligamento',"dataAvisoPrevio":item.dados_fluxo ?
              item.dados_fluxo.dataAvisoPrevio : 'Sem data de aviso prévio',"idPrioridade":item.id_prioridade}}):_vm._e()],1)})],2)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }