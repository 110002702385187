<template>
  <div id="solicitarCoffeeBreak">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
        <h3 class="titleHeader mb-3">Fluxo de Solicitar CoffeBreak</h3>
      <v-row>
          <v-col>
            <h5>Atenção</h5>
            <p class="caption">Os campos com
              <span class="count-warning">*</span>
              são obrigatórios.</p>
          </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-menu
            offset-y
            :close-on-content-click="false"
            :nudge-right="40"
            v-model="menuDataEvento"
            transition="scale-transition"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                readonly
                v-model="dataEventoFormatted"
                label="Data do evento"
                append-icon="event"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              v-model="periodo"
              @input="menuDataEvento = false"
              locale="pt-br"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>
      <!--  -->
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="local"
            label="Local do evento"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="numeroPessoas"
            label="Número de pessoas"
            type="number"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-text-field
            v-for="(item, index) in horarios"
            :key="index"
            v-model="item.hora"
            label="Horário"
            type="time"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
        <v-col>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                color="white"
                x-small
                dark
                left
                v-bind="attrs"
                v-on="on"
                @click.stop="addNewHour()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Adicione mais um horário.</span>
          </v-tooltip>
          <v-tooltip v-if="horarios.length > 1" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                color="white"
                x-small
                dark
                left
                v-bind="attrs"
                v-on="on"
                @click.stop="removeOldHour()"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </template>
            <span>Remova um horário.</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import moment from 'moment';
import Snackbar from '../Snackbar';

export default {
  name: 'SolicitarCoffeeBreak',
  components: { Snackbar },

  data() {
    return {
      menuDataEvento: false,
      periodo: new Date().toISOString().substr(0, 10),
      menuHora: false,
      local: '',
      numeroPessoas: '',
      horarios: [{ hora: '' }],
      dadosFluxo: {},
      newDadosFluxo: {},
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
      horarioTotal: '',
    };
  },
  created() {
    moment.locale('pt-br');
  },
  computed: {
    dataEventoFormatted() {
      return this.periodo ? moment(this.periodo).format('L') : '';
    },
    verificaCampos() {
      return (!!this.periodo
      && !!this.local
      && !!this.numeroPessoas
      && !!this.horarios[0].hora);
    },
  },
  methods: {
    addNewHour() {
      if (this.horarios.length < 5) {
        this.horarios.push({
          hora: '',
        });
      } else {
        this.alerta = 'error';
        this.msg = 'Limite de 5 Horários por Atendimento';
        this.snackBarAlertaCampos = true;
      }
    },

    removeOldHour() {
      this.horarios.pop();
    },

    trataResposta(dadosFluxo) {
      const respostasModel = {};

      if (dadosFluxo.periodoEvent) respostasModel.column_campo_1 = dadosFluxo.periodoEvent;
      if (dadosFluxo.local) respostasModel.column_campo_2 = dadosFluxo.local;
      if (dadosFluxo.numeroPessoas) respostasModel.column_campo_3 = dadosFluxo.numeroPessoas;
      if (dadosFluxo.horarios[0]) respostasModel.column_campo_4 = dadosFluxo.horarios[0].hora;
      if (dadosFluxo.horarios[1]) respostasModel.column_campo_5 = dadosFluxo.horarios[1].hora;
      if (dadosFluxo.horarios[2]) respostasModel.column_campo_6 = dadosFluxo.horarios[2].hora;
      if (dadosFluxo.horarios[3]) respostasModel.column_campo_7 = dadosFluxo.horarios[3].hora;
      if (dadosFluxo.horarios[4]) respostasModel.column_campo_8 = dadosFluxo.horarios[4].hora;

      respostasModel.validation = true;
      this.newDadosFluxo = respostasModel;
    },

    enviaJSON() {
      for (let i = 0; i < this.horarios.length; i += 1) {
        this.horarioTotal += `${this.horarios[i].hora}, `;
      }
      if (!this.verificaCampos) {
        this.dadosFluxo = { validation: false };
        this.alerta = 'error';
        this.msg = 'Todos os campos são obrigatórios.';
        this.snackBarAlertaCampos = true;
      } else if (this.verificaCampos) {
        this.dadosFluxo = {
          validation: true,
          periodoEvent: this.dataEventoFormatted,
          local: this.local,
          numeroPessoas: this.numeroPessoas,
          horarios: this.horarios,
          horarioTotal: this.horarioTotal,
        };
        this.trataResposta(this.dadosFluxo);
        this.$emit('dadosFluxo', this.newDadosFluxo);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.count-warning {
  background-color: transparent;
  color: red;
}
</style>
