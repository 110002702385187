<template>
  <layout>
    <Snackbar
      :label="msg"
      v-model="snackbarFeedback"
      top
      right
      :tipo="tipoSnackbar"
    />
    <Breadcrumb :breadcrumbs="breadcrumbs" />
    <hr />
    <v-row class="top justify-content-center">
      <v-col cols="10">
        <h3 class="tituloFeedback">Feedback</h3>
        <p>Deixe aqui seu comentário e/ou sugestões de melhoria para a ferramenta.</p>
        <v-form @submit.prevent="checkForm">
          <v-text-field
            label="Nome completo"
            outlined
            disabled
            v-model="usuario_nome"
          ></v-text-field>
          <br>
          <v-select
            label="Selecione um setor"
            v-model="setor"
            :items="setores"
            outlined
            dense
            @change="getAtendimentos"
          ></v-select>
          <br>
        <h5 v-if="setor">Setor {{setorNome}}</h5>
          <v-textarea
            label="Comentário/sugestão"
            clearable
            outlined
            auto-grow
            v-model="comentario"
          ></v-textarea>
          <div class="buttons">
            <Botao
              label="Enviar"
              tipo="primary"
              type="submit"
            />
          </div>
        </v-form>
        <hr />
      </v-col>
    </v-row>
  </layout>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import layout from '../layouts/Layout.vue';
import Botao from '../components/Botao';
import Snackbar from '../components/Snackbar';
import { setores } from '../Constants.js';
import { validaAtendente, getMaxPermission } from '../utils/utilities';

export default {
  name: 'Feedback',
  components: {
    layout,
    Botao,
    Breadcrumb,
    Snackbar,
  },
  data() {
    return {
      permissaoGestor: this.$store.getters.listaPermissoes.gestor,
      permissaoGestorGeral: this.$store.getters.listaPermissoes.gestorgeral,
      permissaoAtendente: this.$store.getters.listaPermissoes.atendente,
      atendenteTipo: getMaxPermission(this.$store),
      setores: [],
      setor: '',
      setorDev: setores.desenvolvimento,
      setorNome: '',
      usuario_id: this.$store.getters.isUsuario.id,
      usuario_nome: this.$store.getters.isUsuario.nome,
      aberto: 1,
      comentario: '',
      msg: '',
      breadcrumbs: [],
      snackbarFeedback: false,
      tipoSnackbar: 'info',
    };
  },
  methods: {
    isAtendente() {
      return validaAtendente(this.$store);
    },
    checkForm(e) {
      if (this.comentario && this.setores) {
        this.enviarFeedback();
      }

      if (!this.comentario) {
        this.msg = 'O campo comentário é obrigatório!';
        this.tipoSnackbar = 'error';
        this.snackbarFeedback = true;
      }
      e.preventDefault();
    },
    async enviarFeedback() {
      try {
        const body = {
          usuario_id: this.usuario_id,
          status_id: this.aberto,
          texto: this.comentario,
          setor_id: this.setor,
        };
        await this.$http.post('/novo-feedback', body);
        this.comentario = '';
        this.msg = 'Feedback enviado com sucesso!';
        this.tipoSnackbar = 'success';
        this.snackbarFeedback = true;
      } catch (error) {
        console.error(error);
      }
    },
    async listaSetores() {
      try {
        const { data: response } = await this.$http.get('/setores');
        if (this.atendenteTipo.id_permissao === this.permissaoGestor
            || this.atendenteTipo.id_permissao === this.permissaoGestorGeral
            || (this.atendenteTipo.id_permissao === this.permissaoAtendente
            && this.atendenteTipo.setor_nome === this.setorDev)) {
          this.setores = response.map(item => ({
            value: item.id,
            text: item.nome,
          }));
        } else {
          const responseTratado = response.map(item => ({
            value: item.id,
            text: item.nome,
          }));
          this.setores = responseTratado.filter(item => item.text !== this.setorDev);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    this.breadcrumbs = [
      {
        route: {
          name: 'feedback',
        },
        label: 'Feedback',
      },
    ];
    await this.listaSetores();
  },
};
</script>

<style lang="scss" scoped>
.tituloFeedback {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.top {
  margin-top: 15px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}
</style>
