<template>
  <div id="CriarServicoCliente">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
        <h3 class="titleHeader mb-3">Fluxo Criar Serviço Cliente</h3>
      <v-row>
          <v-col>
            <h5>Atenção</h5>
            <p class="caption">Os campos com
              <span class="count-warning">*</span>
              são obrigatórios.</p>
          </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-autocomplete
            v-model="unidade"
            :items="unidades"
            label="Centro / Unidade"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="servico"
            label="Nome do Serviço"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col>
          <v-icon>mdi-alert</v-icon>
          <p class="caption"><strong>Atenção: </strong>Informações adicionais,
          utilizar o campo de <strong>mensagem.</strong></p>
        </v-col>
      </v-row>

    </v-form>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import { VMoney } from 'v-money';
import Snackbar from '../Snackbar';

export default {
  name: 'CriarServicoCliente',
  components: { Snackbar },
  directives: { mask, money: VMoney },
  data() {
    return {
      servico: '',
      unidade: '',
      unidades: [],
      dadosFluxo: {},
      newDadosFluxo: {},
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },
  created() {
    this.getCentrosUnidade();
  },

  computed: {
    verificaCampos() {
      return (!!this.unidade
      && !!this.servico);
    },
  },
  methods: {
    async getCentrosUnidade() {
      try {
        const { data: response } = await this.$http.get('/data-from-sap/centro-filiais');
        this.unidades = response.map(item => item.descricaoItens);
      } catch (error) {
        console.error(error);
      }
    },

    trataResposta(dadosFluxo) {
      const respostasModel = {};

      if (dadosFluxo.unidade) respostasModel.column_campo_1 = dadosFluxo.unidade;
      if (dadosFluxo.servico) respostasModel.column_campo_2 = dadosFluxo.servico;

      respostasModel.validation = true;
      this.newDadosFluxo = respostasModel;
    },

    enviaJSON() {
      if (!this.verificaCampos) {
        this.dadosFluxo = { validation: false };
        this.alerta = 'error';
        this.msg = 'Todos os campos com * são obrigatórios.';
        this.snackBarAlertaCampos = true;
      } else {
        this.dadosFluxo = {
          validation: true,
          servico: this.servico,
          unidade: this.unidade,
        };
        this.trataResposta(this.dadosFluxo);
        this.$emit('dadosFluxo', this.newDadosFluxo);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.count-warning {
  background-color: transparent;
  color: red;
}
</style>
