import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import Home from '../views/Home.vue';
import Atendimento from '../views/Atendimento.vue';
import ListaAtendimento from '../views/ListaAtendimento.vue';
import ListaAtendimentoUsuario from '../views/ListaAtendimentoUsuario';
import VisualizarAtendimento from '../views/VisualizarAtendimento.vue';
import Login from '../views/Login.vue';
import Faq from '../views/Faq.vue';
import Atualizacoes from '../views/Atualizacoes.vue';
import Feedback from '../views/Feedback.vue';
import SolicitacaoAprovacao from '../views/SolicitacaoAprovacao.vue';
import Admin from '../views/Admin.vue';
import IndicadoresDiretoria from '../views/IndicadoresDiretoria.vue';
import IndicadoresAtendente from '../views/IndicadoresAtendente.vue';
import Aprovacao from '../components/Aprovacao.vue';
import IndicadoresGestores from '../views/IndicadoresGestores.vue';
import PageNotFoundService from '../views/PageNotFoundService.vue';
import PageNotFound from '../views/PageNotFound.vue';
import UnsupportedBrowser from '../views/UnsupportedBrowser.vue';
import Relatorios from '../views/DownloadRelatorioXLSX.vue';
import auth from './middleware/auth';
import guest from './middleware/guest';
import gestao from './middleware/gestao';
import aprovador from './middleware/aprovador';
import diretoria from './middleware/diretoria';
import relatorio from './middleware/relatorio';
import store from '../store/index';
import { getMaxPermission } from '../utils/utilities';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/novo',
    name: 'novo',
    component: Atendimento,
    meta: {
      middleware: [auth],
    },
  },

  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/lista',
    name: 'lista',
    component: ListaAtendimento,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/lista-usuario',
    name: 'lista-usuario',
    component: ListaAtendimentoUsuario,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/atendimento/:id',
    name: 'atendimento',
    component: VisualizarAtendimento,
    beforeEnter: async (to, from, next) => {
      try {
        const { data: response } = await axios.get(`/atendimentos/${to.params.id}`);
        const { data: setorConvidado } = await axios.get(`/convidados/${to.params.id}`);

        const permisao = getMaxPermission(store);
        const setor = setorConvidado
          .filter(item => item.setor_id === store.getters.isUsuario.setor_id).length;

        if (permisao.id_permissao === store.getters.listaPermissoes.colaborador
          && store.getters.isUsuario.id !== response.usuario_id) {
          next({
            name: 'lista-usuario',
            params: {
              toastAtendimentoOutroUsuario: true,
            },
          });
        }
        const erro = setor ? null : 'Você não possui convite para este Atendimento.';

        if ((response.setor_id === store.getters.isUsuario.setor_id
          && store.getters.isUsuario.id_permissao > store.getters.listaPermissoes.colaborador)
          || setor
          || permisao.id_permissao === store.getters.listaPermissoes.gestorgeral
          || response.usuario_id === store.getters.isUsuario.id) next();
        else {
          next({
            name: 'pagina-atendimento-nao-encontrada',
            params: {
              error: erro,
            },
          });
        }
      } catch {
        next('/pagina-nao-encontrada');
      }
    },
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/perguntas-frequentes',
    name: 'faq',
    component: Faq,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/email-aprovacao/:hash/:status',
    name: 'email-aprovacao',
    component: SolicitacaoAprovacao,
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: Feedback,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/pagina-atendimento-nao-encontrada',
    name: 'pagina-atendimento-nao-encontrada',
    component: PageNotFoundService,

  },
  {
    path: '*',
    name: 'pagina-nao-encontrada',
    component: PageNotFound,

  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    meta: {
      middleware: [gestao],
    },
  },
  {
    path: '/dashboard/atendente',
    name: 'dashboardatendente',
    component: IndicadoresAtendente,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/aprovacao',
    name: 'aprovacao',
    component: Aprovacao,
    meta: {
      middleware: [aprovador],
    },
  },
  {
    path: '/dashboard/gestor',
    name: 'dashboardgestor',
    component: IndicadoresGestores,
    meta: {
      middleware: [gestao],
    },
  },
  {
    path: '/dashboard/diretoria',
    name: 'dashboarddiretoria',
    component: IndicadoresDiretoria,
    meta: {
      middleware: [diretoria],
    },
  },
  {
    path: '/atualizacoes',
    name: 'atualizacoes',
    component: Atualizacoes,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/relatorios',
    name: 'relatorios',
    component: Relatorios,
    meta: {
      middleware: [relatorio],
    },
  },
  {
    path: '/unsupportedbrowser',
    name: 'unsupportedbrowser',
    component: UnsupportedBrowser,
  },
];
const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }

  const context = {
    to,
    from,
    router,
    store,
    next,
  };

  return to.meta.middleware[0]({
    ...context,
  });
});

export default router;
