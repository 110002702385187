<template>
  <layout>
    <div>
      <v-card class="card-filtro mt-0 px-3 py-3">
        <v-expansion-panels
        accordion
        multiple
        flat
        >
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h6 class="font-weight-bold body-0">Filtro: Status</h6>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="container-sedes">
                <v-checkbox
                  dense
                  v-for="(item, index) in status"
                  :key="index"
                  class="flex-item my-0 mr-4 pa-0"
                  v-model="selectedStatus"
                  :label="item.tipo"
                  :value="item.id"
                />
              </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
          <h6 class="font-weight-bold body-0">Filtro: Sedes</h6>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="container-column sedes-column">
              <v-checkbox
                dense
                v-for="(item, index) in sedes"
                :key="index"
                class="my-0 pa-0"
                v-model="selectedSedes"
                :label="item.nome"
                :value="item.id"
              />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
            <v-expansion-panel
              v-if="permissaoGestor.id_permissao === gestorGeral"
            >
              <v-expansion-panel-header>
                <h6 class="font-weight-bold body-0">Filtro: Setores</h6>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="container-column sedes-column">
                  <v-checkbox
                    dense
                    v-for="(item, index) in setores"
                    :key="index"
                    class="flex-item my-0 mr-4 pa-0"
                    v-model="selectedSetores"
                    :label="item.nome"
                    :value="item.id"
                  />
                </div>
              </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-row>
          <h6 class="my-6 ml-3 font-weight-bold body-0">Filtro: Intervalo de datas</h6>
        </v-row>
        <div class="container-row">
          <v-menu
            offset-x
            offset-y
            :close-on-content-click="false"
            ref="menu1"
            v-model="menu1"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                readonly
                class="input-date mx-1"
                v-model="dataInicialFormat"
                label="Data inicial"
                append-icon="event"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              :max="dataHoje"
              v-model="dataInicial"
              @input="menu1 = false"
              locale="pt-br"
            ></v-date-picker>
          </v-menu>

          <v-menu
            offset-x
            offset-y
            :close-on-content-click="false"
            ref="menu2"
            v-model="menu2"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                readonly
                class="input-date ml-4"
                v-model="dataFinalFormat"
                label="Data final"
                append-icon="event"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              :max="dataHoje"
              v-model="dataFinal"
              @input="menu2 = false"
              locale="pt-br"
            ></v-date-picker>
          </v-menu>
          <div class="display-button">
            <Botao
              class="botao-filtro"
              type="button"
              label="Filtrar"
              :tipo="showFilterButton"
              :callback="filtrarIndicadores"
            />
          </div>
        </div>
        <v-icon>mdi-alert</v-icon>
        <p class="caption"><strong>Atenção: </strong>As datas estão em formato
        americano (Ano - Mês - Dia)</p>
      </v-card>
      <br>
      <!-- Inicio Indicadores Modal-->
      <v-tabs>
        <v-row>
          <v-tab>
            <v-icon left>mdi-chart-bar</v-icon>
            Sumário Executivo da Área
          </v-tab>
          <v-tab>
            <v-icon left>mdi-chart-bar</v-icon>
            Volumetria da Área
          </v-tab>
          <v-tab>
            <v-icon left>mdi-chart-bar</v-icon>
            Performance Atendentes da Área
          </v-tab>
        </v-row>
        <v-tab-item  class="indicadores">
          <v-card flat>
            <v-card-text >
              <IndicadorSumarioExecutivo
                :dadosFiltro="dadosFiltro"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item  class="indicadores">
          <v-card flat>
            <v-card-text>
              <IndicadorVolumetria
              :dadosFiltro="dadosFiltro"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item  class="indicadores">
          <v-card flat>
            <v-card-text>
              <IndicadorPerformanceAtendentes
                :dadosFiltro="dadosFiltro"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    <!-- Final Indicadores Modal -->
    </div>
  </layout>
</template>

<script>
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import layout from '../layouts/Layout.vue';
import Botao from '../components/Botao.vue';
import IndicadorSumarioExecutivo from '../components/indicadores/IndicadorSumarioExecutivo';
import IndicadorVolumetria from '../components/indicadores/IndicadorVolumetria';
import IndicadorPerformanceAtendentes from '../components/indicadores/IndicadorPerformanceAtendentes';
import { setores } from '../Constants.js';
import { getMaxPermission, orderByAlphabetical } from '../utils/utilities';

export default {
  name: 'IndicadoresDiretoria',
  components: {
    Botao,
    layout,
    IndicadorSumarioExecutivo,
    IndicadorVolumetria,
    IndicadorPerformanceAtendentes,
  },

  data() {
    return {
      setores: [],
      setorDev: setores.desenvolvimento,
      status: [],
      sedes: [],
      selectedSetores: [],
      selectedStatus: [],
      selectedSedes: [],
      dataHoje: new Date().toISOString().slice(0, 10),
      dataInicial: '',
      dataFinal: '',
      menu1: false,
      menu2: false,
      dadosFiltro: '',

      gestorGeral: this.$store.getters.listaPermissoes.gestorgeral,
      permissaoGestor: getMaxPermission(this.$store),
    };
  },
  computed: {
    dataInicialFormat() {
      return this.dataInicial ? moment(this.dataInicial).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD');
    },
    dataFinalFormat() {
      return this.dataFinal ? moment(this.dataFinal).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    },
    showFilterButton() {
      return (!isEmpty(this.selectedSedes) && this.dataInicial && this.dataFinal) ? 'primary' : 'disabled';
    },
  },

  async created() {
    await this.getSetores();
    await this.getSedes();
    await this.getStatus();
    this.filtrarIndicadores();
  },

  methods: {
    listCreator(listaKeyValue) {
      const listaKey = [];
      const listaValue = [];

      listaKeyValue.forEach((item) => {
        listaKey.push(item.nome);
        listaValue.push(item.count);
      });

      return [listaKey, listaValue];
    },

    loadingPage(status) {
      this.$store.commit('UPDATE_LOADING', status);
    },

    async getSetores() {
      try {
        await this.loadingPage(true);

        const { data: response } = await this.$http.get('/setores');
        this.setores = orderByAlphabetical(response, 'setores');
        this.setores = this.setores.filter(item => item.nome !== this.setorDev);
        if (this.permissaoGestor.id_permissao < this.gestorGeral) {
          this.selectedSetores.push(this.permissaoGestor.id_setor);
        } else {
          this.setores.forEach((element) => {
            this.selectedSetores.push(element.id);
          });
        }

        await this.loadingPage(false);
      } catch (error) {
        console.error(error);
      }
    },

    async getSedes() {
      try {
        await this.loadingPage(true);
        const { data: response } = await this.$http.get('/sedes');
        this.sedes = orderByAlphabetical(response, 'sedes');
        this.sedes.forEach((sede) => {
          this.selectedSedes.push(sede.id);
        });
        await this.loadingPage(false);
      } catch (error) {
        console.error(error);
      }
    },

    async getStatus() {
      try {
        await this.loadingPage(true);
        const { data: response } = await this.$http.get('/status');
        this.status = orderByAlphabetical(response, 'status');
        this.status.forEach((status) => {
          this.selectedStatus.push(status.id);
        });
        await this.loadingPage(false);
      } catch (error) {
        console.error(error);
      }
    },

    async filtrarIndicadores() {
      const body = {
        dataInicio: this.dataInicial ? this.dataInicial : moment().startOf('month').format('YYYY-MM-DD'),
        dataFim: this.dataFinal ? this.dataFinal : moment().format('YYYY-MM-DD'),
        setores: this.selectedSetores,
        status: this.selectedStatus,
        sedes: this.selectedSedes,
      };
      this.dadosFiltro = body;
    },
  },
};
</script>

<style lang="scss" scoped>
.indicadores {
  display: flex;
  justify-content: center;
}
.titulos-pag {
  display: flex;
  justify-content: center;
  background-color:rgb(209, 26, 26);
}
.container-column {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  max-width: 250px;
}
.status-column {
  max-height: 95px;
}
.sedes-column {
  max-height: 145px;
}
.container-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}
.display-button {
  display: flex;
  margin-left: 10px;
  margin-top: 3px;
  // justify-content: flex-end;
  width: 100%;
}
.card-filtro {
  border: 2px solid black;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;

  .titulo-marcar-desmarcar {
    display: flex;

    .div-marcar-desmarcar {
      display: flex;
      align-items: flex-start;
      padding-left: 20px;
    }
  }
}
.input-date {
  max-width: 250px;
}

.v-input--selection-controls {
  margin-top: -2px;
  padding-top: 0;
}
.container-sedes {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  max-height: 95px;
  max-width: 336px;
}
</style>
