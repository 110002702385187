<template>
  <div v-if="file.length > 0">
    <v-container>
      <v-row>
        <v-col
          v-for="(f, index) in file"
          :key="index"
          cols="12"
          sm="6"
        >
          <v-card
            outlined
            @mouseover="hover = index"
            @mouseleave="hover = false"
          >
            <v-list-item>
              <v-icon
                v-if="f.type === 'pdf'"
                class="icon-spacing"
              >mdi-pdf-box</v-icon>
              <v-icon
                v-if="f.type === 'jpg' || f.type === 'jpeg'"
                class="icon-spacing"
              >mdi-image</v-icon>
              <v-icon
                v-if="f.type === 'png'"
                class="icon-spacing"
              >mdi-image</v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ f.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  Tamanho: {{ f.size | formatFileSize() }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-card-actions v-if="hover === index">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <button
                      text
                      icon
                      color="blue"
                      v-on="on"
                      @click="downloadFile(f.id, f.name)"
                    >
                      <v-icon>mdi-download</v-icon>
                    </button>
                  </template>
                  <span>Download</span>
                </v-tooltip>
              </v-card-actions>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'CardArquivo',
  props: {
    atendimento_id: VueTypes.number.isRequired,
    comentario_id: VueTypes.number,
  },
  data() {
    return {
      hover: false,
      file: [],
    };
  },
  mounted() {
    this.getFiles();
  },
  methods: {
    async downloadFile(id, name) {
      try {
        await this.loadingPage(true);
        this.$http({
          url: `/arquivos/${id}/download/${name.split('.')[0]}`,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', name);
          document.body.appendChild(fileLink);

          fileLink.click();
        });
        this.loadingPage(false);
      } catch (error) {
        console.error(error);
      }
    },
    async getFiles() {
      try {
        const { data: response } = await this.$http.get(`/arquivos/${this.atendimento_id}/${this.comentario_id || ''}`);
        this.file = response.map(fileItem => ({
          id: fileItem.id,
          name: fileItem.path.split('uploads/').slice(-1)[0],
          type: fileItem.path.split('.').slice(-1)[0],
          size: fileItem.size,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    loadingPage(status) {
      this.$store.commit('UPDATE_LOADING', status);
    },
  },
  filters: {
    formatFileSize(value) {
      if (!value) {
        return '1 MB';
      }
      const units = ['B', 'kB', 'MB', 'GB'];
      let result = value;
      let i;
      for (i = 0; parseInt(result / 1000, 10) >= 1 && i < 4; i += 1) {
        result /= 1000;
      }
      return `${parseFloat(result).toFixed(2)} ${units[i]}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-card.v-card--outlined {
  border: 1px solid rgba(86, 197, 201, 0.35);
}

.v-list-item {
  padding: 10px;
}

.v-card__actions {
  padding: 0px !important;
}

.btn-download {
  background-color: blueviolet;
  color: whitesmoke;
}

.icon-spacing {
  padding-right: 10px;
}
</style>
