<template>
  <Layout>
    <div class="home">
      <h1>Essa é a Home!</h1>
    </div>
  </Layout>
</template>

<script>
import Layout from '../layouts/Layout.vue';
import { getMaxPermission } from '../utils/utilities';

export default {
  name: 'home',
  components: {
    Layout,
  },
  beforeCreate() {
    this.$router.push(getMaxPermission(this.$store).id_permissao === this.$store.getters.listaPermissoes.atendente ? '/lista' : '/lista-usuario');
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  margin-top: 100px;
  justify-content: center;
}
</style>
