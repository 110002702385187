import { render, staticRenderFns } from "./IndicadorAtendimentoPorAtendente.vue?vue&type=template&id=45039cd3&scoped=true&"
import script from "./IndicadorAtendimentoPorAtendente.vue?vue&type=script&lang=js&"
export * from "./IndicadorAtendimentoPorAtendente.vue?vue&type=script&lang=js&"
import style0 from "./IndicadorAtendimentoPorAtendente.vue?vue&type=style&index=0&id=45039cd3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45039cd3",
  null
  
)

export default component.exports