<template>
  <div>
    <v-card
      :max-height="500"
      :width="1230"
      :min-width="700"
      :elevation="4"
      class="card-indicador mt-2 px-3 pt-3 pb-0"
    >
      <v-overlay
        :value="loaderSatisfacaoArea"
        :absolute="true"
        :opacity="0.30"
      >
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <div ref="capture">
        <div class="div-titulo-botao">
          <span class="span-titulo">
            Média(%) de <strong>SATISFAÇÃO por Área durante o ano</strong>
          </span>
          <button
            class="botao-download"
            @click="screenShot()"
          >
            <span class="material-icons">
              get_app
            </span>
          </button>
        </div>
        <apexchart
        type="line"
        height="350"
        :options="chartOptions"
        :series="series"
        ></apexchart>
      </div>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import VueTypes from 'vue-types';
import screenShot from '../../mixins/screenShot.js';


export default {
  name: 'IndicadorSatisfacaoAreaPorAno',
  components: {
    apexchart: VueApexCharts,
  },
  mixins: [screenShot],
  props: {
    mesesTratados: VueTypes.array.isRequired,
    valores: VueTypes.array.isRequired,
    loaderSatisfacaoArea: VueTypes.bool.isRequired,
  },

  data() {
    return {

      series: this.valores,
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth',
        },
        title: {
          text: '⠀',
          align: 'right',
        },
        markers: {
          size: 1,
        },
        yaxis: {
          min: 0,
          max: 10,
        },
        xaxis: {
          categories: this.mesesTratados,
          title: {
            text: 'Meses',
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: false,
          offsetY: -35,
          offsetX: -5,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.card-indicador {
  border-radius: 20px !important;
}
.div-titulo-botao {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .span-titulo {
    padding-left: 10px;
    color: #333;
  }

  .botao-download {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;
    border-radius: 5px;
    border: 2px solid #eeeeee;

    &:hover {
      background-color: #e2dede;
    }
    .material-icons {
      color: #0056a8;
      font-size: 25px;
    }
  }
}
</style>
