<template>
  <Layout>
    <div class="centralizar">
      <div>
        <h1 class="my-5 ml-6 font-weight-bold body-0">Atualizações do Sistema</h1>
        <div>
          <h3 class="font-weight-bold">07/07/2021</h3>
          <ul class="descricao">
            <li>Testes e correções de dados de alguns indicadores;</li>
            <li>Criado validação para fluxos que usam arquivos próprios que foram desativados;</li>
            <li>Implementação de contador de Atendimentos do Conecta, para usuários;</li>
            <li>Efetuado melhoria no Criador de fluxos dinâmico, adicionado Snackbar;</li>
            <li>Validado e corrigido bug dos direcionamentos no banco de dados;</li>
            <li>Dividido relátorio de Desligamento DP/ Desligamento Jurídico;</li>
            <li>Corrigido bug do script relacionado aos fluxos de desligamento antigos;</li>
          </ul>
        </div>
        <v-divider></v-divider>
        <div>
          <h3 class="font-weight-bold">16/06/2021</h3>
          <ul class="descricao">
            <li>Aperfeiçoado regras de Direcionamento do Sistema;</li>
            <li>Corrigido BUG Notificação de Setores Convidados;</li>
            <li>Adicionada requisição para trazer endereços no Fluxo de Motoboy;</li>
            <li>Adicionada criação de Fluxo Dinamicamente;</li>
            <li>Corrigido BUG Atendimento com mais de um Convite;</li>
            <li>Refatorado criação de Atendimentos com Fluxo;</li>
            <li>Refatorado visualização de Atendimentos com Fluxo;</li>
            <li>Realizado tratamento de dados de Fluxos Antigos para o Modelo Novo;</li>
            <li>Desatribuição de Atendente após resposta do Setor Convidado;</li>
          </ul>
        </div>
        <v-divider></v-divider>
        <div>
          <h3 class="font-weight-bold">12/05/2021</h3>
          <ul class="descricao">
            <li>Adicionado filtro pelo ID do Atendimento e por descrição;</li>
            <li>Finalizado o fluxo de reajuste de salário;</li>
            <li>Adicionado aviso para quando o solicitante for colaborador
              (Formulário de rescisão);</li>
            <li>Modificado aviso da entrada do Sistema
              quando o usuário não consegue realizar login;</li>
            <li>Melhorias nas regras de direcionamentos;</li>
          </ul>
        </div>
        <v-divider></v-divider>
        <div>
          <h3 class="font-weight-bold">05/05/2021</h3>
          <ul class="descricao">
            <li>Adicionado permissão de visualização de relatório;</li>
            <li>Modificado botão relatórios para o menu à esquerda, facilitando o acesso;</li>
            <li>Modificado o select para autocomplete,
               atribuição de Atendentes, modificação de Status, etc;</li>
            <li>Corrigido filtro Atendimentos;</li>
            <li>Atualizado relatório de Desligamento com os novos campos;</li>
            <li>Realizado melhoria na rota Meta People;</li>
            <li>Realizado melhoria no retorno da API;</li>
          </ul>
        </div>
        <v-divider></v-divider>
        <div>
          <h3 class="font-weight-bold">28/04/2021</h3>
          <ul class="descricao">
            <li>Adicionado permissão para usuários visualizarem novo setor Automation Center;</li>
            <li>Adicionado avisos para subcategorias;</li>
            <li>Adicionado permissão "atribuidor", quem receber essa permissão pode
              encaminhar atendimentos para outros Atendentes;</li>
            <li>Adicionado histórico e notificações na solicitação de reajuste de salário;</li>
            <li>Adicionado atualização de token automática;</li>
            <li>Adicionado ordenação de Setores,
              Categorias, Subcategorias e Sedes por ordem alfabética;</li>
            <li>Refatorado e modificado fluxo de rescisão do DP;</li>
          </ul>
        </div>
        <v-divider></v-divider>
        <div>
          <h3 class="font-weight-bold">21/04/2021</h3>
          <ul class="descricao">
            <li>Atualizado relatório de Atendimentos trazendo dados fluxos;</li>
            <li>Atualizado somente pessoas envolvidas ao Atendimento podem visualizar;</li>
            <li>Revisado Indicador Card Atendentes;</li>
            <li>Finalizado teste de storys que já estão em produção;</li>
            <li>Modificado visualização do campo de CCT;</li>
            <li>Refatorado permissionamento do sistema para dinâmico;</li>
            <li>Adicionado permissões de Business Partner e Automation Center;</li>
            <li>Adicionado novos campos no formulário de reajuste;</li>
            <li>Adicionado filtro por datas na tabela desligamento;</li>
            <li>Adicionado ordenação por Total na tabela desligamento;</li>
            <li>Adicionado envio de e-mail solicitação reajuste;</li>
            <li>Removido estágio Efetivado dos Indicadores de Desligamento;</li>
          </ul>
        </div>
        <v-divider></v-divider>
        <div>
          <h3 class="font-weight-bold">14/04/2021</h3>
          <ul class="descricao">
            <li>Desenvolvido Solicitação Aprovação e regra de negócio;</li>
            <li>Adicionado média nos Indicadores de satisfação;</li>
            <li>Revisado Indicador de tempo médio;</li>
            <li>Desenvolvido todos calculos dos Indicadores do Sumário Executivo Diretoria;</li>
          </ul>
        </div>
        <v-divider></v-divider>
        <div>
          <h3 class="font-weight-bold">07/04/2021</h3>
          <ul class="descricao">
            <li>Desenvolvido Indicadores Sumário Executivo Diretoria;</li>
            <li>Desenvolvido a separação dos Indicadores por áreas na parte Diretoria;</li>
            <li>Desenvolvido Botão visão Atendente/Usuário;</li>
            <li>Inserido um campo de Motivo na Avaliação do Atendimento;</li>
            <li>Inserido periodo de reabertura
               de atendimento de no máximo 5 dias após encerrado;</li>
            <li>Validação de dados de alguns Indicadores;</li>
            <li>Corrigido BUGs - Botão Visão Atendente/Usuário aparecendo para todos usuários;</li>
          </ul>
        </div>
        <v-divider></v-divider>
        <div>
          <h3 class="font-weight-bold">31/03/2021</h3>
          <ul class="descricao">
            <li>Desenvolvido Indicadores para atendentes;</li>
            <li>Desenvolvido a separação dos Indicadores por áreas na parte Gestor;</li>
            <li>Desabilitado Ag. Usuário da contagem dos Indicadores de tempo médio;</li>
            <li>E-mail ajustado de conectadp@meta.com.br para conecta@meta.com.br;</li>
            <li>Inserido relatório de desligamento em Analytics Diretoria;</li>
            <li>Inserido filtro de datas e status nos relatórios;</li>
            <li>Inserido tabela nos Indicadores de Desligamento;</li>
            <li>Inserido contagem de Atendimentos por Raia;</li>
            <li>Inserido novo campo CC no
              formulário de Solicitar rescisão (Jurídico e Adminstrativo);</li>
            <li>Nomes dos atendentes ajustado encima dos Indicadores;</li>
            <li>Alterado aviso em Solicitar Rescisão;</li>
            <li>Inserido novo Setor Desenvolvimento Conecta;</li>
            <li>Corrigido BUG - Atendente abre um atendimento e é direcionado para ele mesmo;</li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../layouts/Layout.vue';

export default {
  name: 'atualizacoes',
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
.centralizar {
  display: flex;
  justify-content: center;
}
.descricao {
  margin-left: 20px;
  font-size: 22px;
}
</style>
