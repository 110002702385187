<template>
  <div class="my-3">
    <div class="card p-3">
      <div
        class="nome-usuario"
        v-if="nomeUsuario"
      >
        <p class="mb-1"> {{ splitNome(nomeUsuario) }}</p>
      </div>
      <div class="header-card">
        <router-link :to="link">
          <h5 class="pl-1">[{{ id }}] - {{ subcategoria }}</h5>
        </router-link>
      </div>
      <div class="text-wrap descricao-card">
        <h6>{{ miniDescricao }}</h6>
      </div>
      <div class="content-card">
        <TagLabel :tipo="tipo" :idPrioridade="idPrioridade"/>
        <div>
          <CircularStatus
            :status="status"
            :size="size"
          />
          {{ statusLabel === "Aguardando atendente" ? "Aguardando responsável" : statusLabel }}
        </div>
      </div>
      <div class="footer-card">
        <p>{{ data }}</p>
      </div>
      <v-tooltip
        color="black"
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-avatar
            v-if="setorOrigem"
            size="32px"
            v-ripple
            class="avatar-iniciais-posicao-origem"
          >
             <img
              class="tag-avatar"
              v-on="on"
              alt="Avatar Setor"
            />
            <span
             v-on="on"
            >{{ nomeIniciais(setorOrigem) }}</span>
          </v-avatar>
        </template>
        <span>
          <h6>Atribuído</h6>
          <h6>
            {{ this.setorOrigem }}
            <span class="label-usuario">@{{ splitNomeUsuario(this.nome) }}</span>
          </h6>
        </span>
      </v-tooltip>
      <v-tooltip
        color="black"
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-avatar
            v-if="avatar"
            size="32px"
            v-ripple
            class="avatar-iniciais-posicao"
          >
             <img
              v-if="atendenteAtribuido && (avatar.length > 3)"
              class="tag-avatar"
              :src="avatar"
              :alt="alt"
              v-on="on"
            />
            <span
              v-else
              v-on="on"
            >{{ avatar }}</span>

          </v-avatar>
        </template>
        <span>
          <h6>Atribuído</h6>
          <h6>
            {{ splitNome(this.nome) }}
            <span class="label-usuario">@{{ splitNomeUsuario(this.nome) }}</span>
          </h6>
        </span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import TagLabel from './TagLabel.vue';
import CircularStatus from './CircularStatus.vue';
import splitNome from '../mixins/splitNome.js';
import nomeIniciais from '../mixins/nomeIniciais.js';

export default {
  name: 'CardAtendimento',
  components: {
    TagLabel,
    CircularStatus,
  },
  mixins: [nomeIniciais, splitNome],
  props: {
    id: VueTypes.number,
    nome: VueTypes.string.def('Seu nome'),
    nomeUsuario: VueTypes.string,
    setorOrigem: VueTypes.string,
    link: VueTypes.string.isRequired,
    subcategoria: VueTypes.string.isRequired,
    data: VueTypes.string.def('XX/XX/XXXX às XX:XX'),
    status: VueTypes.string.isRequired,
    statusLabel: VueTypes.string.isRequired,
    descricao: VueTypes.string.isRequired,
    tipo: VueTypes.string.isRequired,
    size: VueTypes.oneOf(['small', 'normal', 'big', 'biggest']).def('normal'),
    avatar: VueTypes.string.def(''),
    alt: VueTypes.string.def(''),
    atendenteAtribuido: VueTypes.bool.def(false),
    id_fluxo: VueTypes.number,
    unidade: VueTypes.string,
    funcionario: VueTypes.string,
    data_desligamento: VueTypes.string,
    dataAvisoPrevio: VueTypes.string,
    idPrioridade: VueTypes,
  },

  watch: {
    idPrioridade(value) {
      this.idPrioridade = value;
    },
  },

  computed: {
    miniDescricao() {
      if (this.descricao.length > 100) {
        return `${this.descricao.substring(0, 100)}...`;
      }
      return this.descricao;
    },
  },
};
</script>

<style scoped lang="scss">
li {
  list-style-type: none;
  margin-left: -8%;
}
a {
  color: #303030 !important;
  &:hover {
    color: #303030 !important;
  }
}
.card {
  border-radius: 5px;
  max-width: 100%;
  margin-top: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  padding: 10px;
  text-decoration: none;
  flex: 1;
}
.nome-usuario p {
  color: rgba(48, 48, 48, 0.5);
  font-size: 12px;
  text-align: start;
  margin: 0;
}
.descricao-card h6 {
  text-align: start;
  font-size: 14px !important;
  color: #303030;
  margin-bottom: 5px !important;
}
.header-card {
  margin: 0 0 0 -3px !important;
  background-color: #fff;
  padding: 0 !important;
}
.header-card h5 {
  font-size: 14px !important;
  font-weight: bold !important;
  color: #303030;
  margin-bottom: 5px !important;
}
.content-card {
  padding: 0 5px 5px 0;
  font-size: 14px !important;
  font-weight: normal;
  text-align: start;
}
.footer-card {
  color: rgba(48, 48, 48, 0.5);
  font-size: 12px;
  text-align: start;
  & p {
    margin: 0;
  }
}
.tag-avatar {
  position: absolute;
  width: 32px !important;
  height: 32px;
}
.avatar-iniciais-posicao {
  position: absolute;
  border-radius: 100%;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.12);
  bottom: 8px;
  right: 8px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  background: #0056a8;
  z-index: 15;
  transition: all 0.3s;
}
.avatar-iniciais-posicao-origem {
  position: absolute;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.12);
  bottom: 144px;
  right: 8px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  background: rgb(223, 0, 0);
  transition: all 0.3s;
}
h6 {
  text-align: center;
  &:nth-child(1) {
    padding-top: 5px;
    font-size: 13px;
    font-weight: 400;
  }
  &:nth-child(2) {
    font-weight: 600;
    font-size: 14px;
  }
}
.label-usuario {
  color: #909090;
  font-weight: 600;
  font-size: 14px;
}
</style>
