<template>
  <v-timeline
    :align-top="true"
    :dense="true"
    :noLine="true"
  >
    <v-timeline-item :right="true">
      <template v-slot:icon>
        <v-avatar>
          <img
            v-if="avatar"
            :src="avatar"
            alt="placeholder"
          />
          <span
            v-else
            class="avatar-iniciais"
          >
            {{ nomeIniciais(usuarioNome) }}
          </span>
        </v-avatar>
      </template>
      <form
        class="col-12"
        enctype="multipart/form-data"
      >
        <EditorTexto
          :tipoComentario="comentarioPublico"
          :setorConvidadoAtendimento="atendimento"
        />
        <InputFile
          :enviar="enviarFiles"
          @hasError="updateFileError"
          ref="inputfile"
        />
        <div :class="isAtendente ? 'txt-footer' : ''">
          <div
            v-if="isAtendente"
            class="switch-margin"
          >
            <span class="switch-text switch-text-privado v-label theme--light">Privado</span>
            <v-switch
              v-if="isAtendente"
              v-model="comentarioPublico"
            ></v-switch>
            <span class="switch-text switch-text-publico v-label theme--light">Público</span>
          </div>
          <div class="btn-stilo">
            <Botao
              label="Cancelar"
              tipo="secondary"
              class="ma-2"
              :callback="apagaComentario"
              type="reset"
            >
            </Botao>
            <v-btn
              class="mt-2"
              :class="verificaComentario() ? 'primary' : 'disabled'"
              @click="enviarFormulario"
            >
              Responder
            </v-btn>
          </div>
        </div>
      </form>
    </v-timeline-item>
  </v-timeline>
</template>
<script>
import VueTypes from 'vue-types';
import InputFile from './InputFile.vue';
import Botao from './Botao.vue';
import Notify from '../mixins/Notify';
import nomeIniciais from '../mixins/nomeIniciais.js';
import { fluxos, status } from '../Constants.js';
import EditorTexto from './EditorTexto.vue';
import EventBus from '../plugins/EventBus';
import { getMaxPermission, validaAtendente } from '../utils/utilities';

export default {
  name: 'FormTimeline',
  components: {
    InputFile,
    Botao,
    EditorTexto,
  },
  mixins: [Notify, nomeIniciais],
  data() {
    return {
      avatar: '',
      comentarioUsuario: '',
      comentarioPublico: true,
      teste: false,
      enviarFiles: false,
      fileError: null,
      tag: [],
      usuarioNome: this.$store.getters.isUsuario.nome,
      atendentesMencionadas: [],
      setorConvidadoAtendimento: getMaxPermission(this.$store),
    };
  },
  props: {
    atendimento: VueTypes.object.isRequired,
    idAtendimento: VueTypes.number.isRequired,
  },
  computed: {
    isAtendente() {
      return validaAtendente(this.$store);
    },
    isUsuario() {
      return getMaxPermission(this.$store).id_permissao
        === this.$store.getters.listaPermissoes.colaborador;
    },
  },
  created() {
    this.avatar = this.$store.getters.usuarioAvatar;
  },
  methods: {
    verificaComentario() {
      const texto = this.$store.getters.comentario;
      const paragrafoVazio = '<p></p>';
      const tabelaVazia = '<table><tbody><tr><td><p></p></td><td><p></p></td><td><p></p></td></tr><tr><td><p></p></td><td><p></p></td><td><p></p></td></tr><tr><td><p></p></td><td><p></p></td><td><p></p></td></tr></tbody></table>';
      if ((texto && texto !== paragrafoVazio) && (texto !== tabelaVazia)) {
        this.comentarioUsuario = texto;
        return true;
      }
      return false;
    },
    getFiles(files) {
      this.files = files;
    },
    getMembers(members) {
      this.tag = members;
    },
    updateFileError(value) {
      this.fileError = value;
    },

    async putComentario(files) {
      try {
        if (this.isAtendente) {
          this.boldUserNames();
        }
        const { data: usuario } = await this.$http.get(`/usuarios/${this.$store.getters.isUsuario.id}`);

        let body;
        if (this.atendimento.id_fluxo) {
          body = {
            id_usuario: this.$store.getters.isUsuario.id,
            id_atendimento: this.$route.params.id,
            comentario: this.comentarioUsuario.trim(),
            publico: this.isUsuario || this.comentarioPublico,
            usuarios_citados: this.tag,
            nomeUsuario: usuario.nome,
            id_fluxo: this.atendimento.id_fluxo,
            rescisao: this.atendimento.id_fluxo === fluxos.recisao,
            contratacaoRPA: this.atendimento.id_fluxo === fluxos.contratacaoRPA,
          };
        } else {
          body = {
            id_usuario: this.$store.getters.isUsuario.id,
            id_atendimento: this.$route.params.id,
            comentario: this.comentarioUsuario.trim(),
            publico: this.isUsuario || this.comentarioPublico,
            usuarios_citados: this.tag,
          };
        }
        const response = await this.$http.post('/comentarios/', body);
        this.notificaMencoes();
        this.notificaComentario(this.decideUsuarioNotificar());
        if (files.length) {
          await this.sendFiles(response.data.id, files);
        }
        if (this.atendimento.id_status === status.aguardandoUsuario
          && this.atendimento.id_usuario === this.$store.getters.isUsuario.id) {
          await this.updateAtendStatus();
          this.$emit('updateStatus');
        }
        this.$emit('refreshTimeline');
      } catch (error) {
        console.error(error);
      }
    },
    async updateAtendStatus() {
      const body = {
        status_id: status.aguardandoAtendente,
        id_usuario: this.atendimento.id_usuario,
        id_atendimento: parseInt(this.$route.params.id, 10),
        tipo_alteracao: 'STATUS',
        valor_antigo: status.aguardandoUsuario,
        valor_novo: status.aguardandoAtendente,
      };
      await this.$http.put(`/atendimentos/${this.atendimento.id_atendimento}`, body);
    },

    montaAtendentesMencionadas() {
      this.atendentesMencionadas = [];
      const comentarioToHTML = new DOMParser().parseFromString(this.comentarioUsuario, 'text/html');
      const mencao = comentarioToHTML.querySelectorAll('[data-mention-id]');
      if (mencao) {
        mencao.forEach((element) => {
          const obj = {
            id_usuario: element.dataset.mentionId,
            nome: element.innerText,
          };
          this.atendentesMencionadas.push(obj);
        });
      }
    },

    async notificaMencoes() {
      if (!this.isAtendente) {
        return;
      }
      await this.montaAtendentesMencionadas();

      const dados = {
        id_atendimento: this.$route.params.id,
        id_usuario_responsavel: this.$store.getters.isUsuario.id,
      };

      this.atendentesMencionadas.forEach((element) => {
        if (element.id_usuario !== this.$store.getters.isUsuario.id) {
          dados.id_usuario = element.id_usuario;
          this.emiteNotificacao('mencao', dados);
        }
      });
    },
    notificaComentario(idUsuario) {
      const dados = {
        id_usuario: idUsuario,
        id_usuario_responsavel: this.$store.getters.isUsuario.id,
        id_atendimento: this.$route.params.id,
      };
      if (this.isUsuario || this.comentarioPublico) {
        this.emiteNotificacao('comentario', dados);
      }
    },
    decideUsuarioNotificar() {
      const usuarioLogado = this.$store.getters.isUsuario.id;
      const usuarioDoAtendimento = this.atendimento.id_usuario;
      if (usuarioLogado === usuarioDoAtendimento) {
        return this.atendimento.id_atendente;
      }
      return usuarioDoAtendimento;
    },
    async sendFiles(idComentario, files) {
      try {
        const formData = new FormData();
        for (let index = 0; index < files.length; index += 1) {
          formData.append('envios', files[index]);
        }
        formData.append('atendimento_id', this.atendimento.id_atendimento);
        formData.append('comentario_id', idComentario);
        await this.$http.post('/arquivos', formData);
      } catch (error) {
        console.error(error);
      }
    },
    apagaComentario() {
      this.comentarioUsuario = '';
      EventBus.$emit('limparComentarioBus', true);
      this.$refs.inputfile.removeFiles();
    },
    async enviarFormulario() {
      await this.putComentario(this.$refs.inputfile.getFiles());
      await this.apagaComentario();
      await this.encerraConvite();
    },
    async encerraConvite() {
      const { data: response } = await this.$http.get(
        `/convidados/${this.idAtendimento}`,
      );
      if (response) {
        response.forEach((item) => {
          if (this.$store.getters.isUsuario.setor_id === item.setor_id) {
            const body = {
              usuario_atendente_id: item.id_atendente_convite,
              id_usuario: this.atendimento.id_usuario,
              id_atendimento: this.idAtendimento,
              tipo_alteracao: 'ATRIBUICAO',
              valor_antigo: this.atendimento.id_atendente,
              valor_novo: item.id_atendente_convite,
            };
            this.$http.put(`/atendimentos/${this.idAtendimento}`, body);
            this.$emit('getNotificaNovoAtendente');
            this.$http.delete(`/convidados/${item.id}`);
          }
        });
      }
    },
    boldUserNames() {
      const atendNames = this.namesAtend();
      atendNames.forEach((atend) => {
        const regex = new RegExp(`@${atend}`, 'g');
        this.comentarioUsuario = this.comentarioUsuario.replace(regex, `<strong>@${atend}</strong>`);
      });
    },
    namesAtend() {
      const atendNames = [];
      this.tag.forEach((e) => {
        atendNames.push(e.name);
      });
      return atendNames;
    },
  },
};
</script>
<style lang="scss" scoped>
form.col-12 {
  padding: 0;
  margin-top: 10px;
}
div {
  padding: 0;
  margin: 0;
}
.btn-stilo {
  display: flex;
  justify-content: flex-end;
}
.btn-local {
  margin: 10px 0px 0 10px;
}
.txt-footer {
  display: flex;
  justify-content: space-between;
}
.v-timeline[noLine]:before {
  width: 0px;
}
.avatar-iniciais {
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.12);
  color: white;
  font-size: 22px;
  font-weight: 500;
  background: #0056a8;
  z-index: 31;
  top: 0px;
  border-radius: 50%;
  transition: all 0.3s;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.switch-margin {
  display: flex;
  padding-top: 16px;
}
.switch-text {
  padding-top: 4px;
  cursor: default;
}
.switch-text-privado {
  padding-right: 9px;
}
.switch-text-publico {
  padding-left: 4px;
}

.primary {
  text-transform: inherit !important;
  background: #0056a8 !important;
  color: #fff;
  border-radius: 5px;
  font-size: 16px !important;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.disabled {
  text-transform: inherit !important;
  background: #fff;
  color: black;
  border-radius: 5px;
  font-size: 16px !important;
  border: 1px solid #bfbfbf;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  opacity: 0.4;
  &:hover {
    color: #bfbfbf;
    opacity: 0.4;
  }
}
</style>

<style lang="scss">
html .v-input--selection-controls.v-input .v-label {
  margin: 0;
}
</style>
