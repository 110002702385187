import { validaAprovador } from '../../utils/utilities';
import { setores } from '../../Constants.js';

export default function aprovador({ next, store }) {
  if (validaAprovador(store, setores)) {
    return next();
  }
  const pagina = window.location.pathname;
  return next({
    name: 'login',
    params: { fromPagina: pagina },
  });
}
