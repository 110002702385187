<template>
  <div id="direcionamentos">
    <Snackbar
      :label="msg"
      v-model="snackbarOpen"
      top
      right
      :tipo="tipoSnackbar"
      :timeout="2500"
    />
      <div class="assunto container-show-setor">
        <div
          class="buttons-setor-view"
          v-for="(sector, index) in sectors"
          :key="index"
        >
          <v-btn
            rounded
            class="v-btn_cat mr-2"
            :class="{ 'active': index === activeSetView,
            'inactive': inactiveSetView && index !== activeSetView }"
            @click.prevent="showCategoryOfSectorTable(index, sector.id, sector.nome)"
          >
            {{ sector.nome }}
            <v-icon
              right
              small
              v-if="activeSetView === index"
            >
              mdi-close
            </v-icon>
          </v-btn>
          <div
            v-if="selectedSetView === sector.id"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  dark
                  class="mr-2"
                  v-on="on"
                  @click.stop="editItem(sector)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  dark
                  v-on="on"
                  @click.stop="setDisableItem(sector)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>Remover</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <div
        class="button-dialog"
      >
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                class="v-btn_add"
                v-on="on"
              >
                Adicionar novo
              </v-btn>
            </template>
            <v-card class="px-2">
              <v-card-title class="headline title-type-dialog">
                {{ formTitle }}
              </v-card-title>
              <v-card-subtitle class="subtitle-1">Selecione o tipo:
                <v-radio-group
                  :disabled="editedIndex !== -1"
                  v-model="type"
                  row
                  @change="resetTipo(type)"
                >
                  <v-radio v-if="permissaoGestor.id_permissao === gestorGeral"
                    label="Setor"
                    value="sector"
                  ></v-radio>
                  <v-radio
                    label="Categoria"
                    value="category"
                  ></v-radio>
                  <v-radio
                    label="Subcategoria"
                    value="subcategory"
                  ></v-radio>
                </v-radio-group>
              </v-card-subtitle>
              <v-card-subtitle
                v-if="type === 'sector'"
                class="subtitle-1"
              >
                <v-text-field
                  v-model="newNameSector"
                  label="Nome do setor"
                  outlined
                  dense
                  clearable
                ></v-text-field>
              </v-card-subtitle>
              <v-card-subtitle
                v-if="((type === 'category' || type === 'subcategory')
                  && type !== 'sector') && editedIndex === -1"
                class="subtitle-1"
              >
                <p class="mb-2">{{ typeTitle }}</p>
                <v-btn
                  rounded
                  class="v-btn_cat mr-2 mb-2"
                  :class="{'active': index === activeSet,
                  'inactive': inactiveSet && index !== activeSet}"
                  v-for="(sector, index) in sectors"
                  :key="index"
                  @click.prevent="setSector(index, sector.id)"
                >
                  {{ sector.nome }}
                  <v-icon
                    right
                    small
                    v-if="activeSet === index"
                  >
                    mdi-close
                  </v-icon>
                </v-btn>
                <v-text-field
                  class="textfield-edit"
                  v-if="selectedSet && type === 'category'"
                  v-model="newNameCategory"
                  label="Nome da categoria"
                  outlined
                  dense
                  clearable
                ></v-text-field>
                <div v-if="selectedSet !== '' && type === 'subcategory'">
                  <v-btn
                    rounded
                    class="v-btn_cat mr-2 mb-2"
                    :class="{ 'active': index === activeCat,
                'inactive': inactiveCat && index !== activeCat }"
                    v-for="(cat, index) in filterCategoriesModal"
                    @click.prevent="setCategory(index, cat.id)"
                    :key="index"
                  >
                    {{ cat.nome }}
                    <v-icon
                      right
                      small
                      v-if="activeCat === index"
                    >
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
                <div v-if="activeCat !== null && type === 'subcategory'">
                  <v-text-field
                    class="textfield-edit"
                    v-if="selectedCat && type === 'subcategory'"
                    v-model="newNameSubcategory"
                    label="Nome da subcategoria"
                    outlined
                    dense
                    clearable
                  ></v-text-field>
                </div>
              </v-card-subtitle>
              <v-card-subtitle
                v-if="editedIndex !== -1"
                class="subtitle-1"
              >
                <div v-if="selectedSet !== '' && (type === 'category' || type === 'subcategory')">
                  <p class="mb-2">Setor atual:</p>
                  <v-btn
                    rounded
                    class="v-btn_cat mr-2 mb-2 active"
                  >
                    {{ editedSetor }}
                  </v-btn>
                </div>
                <v-text-field
                  v-if="selectedSet && type === 'category'"
                  v-model="newNameCategory"
                  label="Nome da categoria"
                  outlined
                  dense
                  clearable
                ></v-text-field>
                <div v-if="selectedSet !== '' && type === 'subcategory'">
                  <p class="mb-2">Categoria atual:</p>
                  <v-btn
                    rounded
                    class="v-btn_cat mr-2 mb-2 active"
                  >
                    {{ currentCategoryModal.nome }}
                  </v-btn>
                </div>
                <div v-if="activeCat !== null && type === 'subcategory'">
                  <v-text-field
                    v-if="selectedCat && type === 'subcategory'"
                    v-model="newNameSubcategory"
                    label="Nome da subcategoria"
                    outlined
                    dense
                    clearable
                  ></v-text-field>
                </div>
              </v-card-subtitle>
              <v-card-subtitle
                v-if="type === 'category'"
                class="subtitle-1"
              >
              <v-dialog
                v-model="dialogFluxo"
                max-width="350"
              >
                <v-card>
                  <v-card-title class="headline">Atenção!</v-card-title>
                  <v-card-text>
                    Novas categorias só ficarão disponíveis para abertura
                     de atendimentos quando forem criadas <strong>subcategorias</strong> para ela.
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="btn-dialog mr-2 mb-2"
                      text
                      @click="dialogFluxo = false"
                    >
                      Estou ciente
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <Botao
                  class="mr-4"
                  type="button"
                  label="Cancelar"
                  tipo="secondary"
                  :callback="closeModal"
                />
                <Botao
                  type="button"
                  label="Salvar"
                  :tipo="showSaveButton()"
                  :callback="saveNewServiceBackoffice"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>
      </div>
      <div
        v-if="selectedSetView === ''"
        class="show-activeSet-empty"
      >
        <p>Selecione um setor.</p>
      </div>
    <v-data-table
      show-expand
      v-if="activeSetView !== null"
      :single-expand="true"
      :headers="headers"
      :expanded.sync="expanded"
      :items="selectedSetView ? filterCategoriesTable : categories"
      :sort-by="['categoria_nome', 'subcategoria_nome']"
      :items-per-page="5"
      :no-data-text="'Não há categorias ou subcategorias.'"
      :footer-props="{
        'items-per-page-text': 'Resultados por página',
        'items-per-page-all-text': 'Todos',
        'page-text': '{0}-{1} de {2}',
    }"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white"
        >
          <v-dialog
            v-model="dialogDisable"
            scrollable
            max-width="500"
          >
            <v-card class="px-2">
              <v-card-title class="headline">{{ disableTitle }}</v-card-title>
              <v-card-subtitle class="mt-1">
                {{ deleteText }}
              </v-card-subtitle>
              <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <Botao
                  class="mr-4"
                  type="button"
                  label="Cancelar"
                  tipo="secondary"
                  :callback="closeModal"
                />
                <Botao
                  type="button"
                  label="Confirmar"
                  :callback="disableServiceBackoffice"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          class="pa-2"
          :colspan="headers.length"
        >
          <v-simple-table>
            <thead>
              <tr>
                <th scope="col">Subcategoria(s)</th>
                <th scope="col">Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(subcategory, index) in subcategories"
                :key="index"
              >
                  <td v-if="item.id === subcategory.categoria_id">
                      {{ subcategory.nome }}
                  </td>
                  <td v-if="item.id === subcategory.categoria_id">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          small
                          dark
                          class="mr-2"
                          v-on="on"
                          @click.stop="editItem(subcategory)"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          small
                          dark
                          v-on="on"
                          @click.stop="setDisableItem(subcategory)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Remover</span>
                    </v-tooltip>
                  </td>
              </tr>
            </tbody>
          </v-simple-table>
        </td>
      </template>
      <template v-slot:item.ativo="{item}">
        {{ item.ativo ? 'Não' : 'Sim' }}
      </template>
      <template v-slot:item.categoria_nome="{item}">
        <span v-if="item.categoria_nome">{{ item.categoria_nome }} </span>
        <span v-else>Não informado</span>
      </template>
      <template v-slot:item.acoes="{item}">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              dark
              class="mr-2"
              v-on="on"
              @click.stop="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              dark
              v-on="on"
              @click.stop="setDisableItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Remover</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Botao from './Botao.vue';
import Snackbar from './Snackbar.vue';
import EventBus from '../plugins/EventBus';
import { getMaxPermission } from '../utils/utilities';

export default {
  name: 'Direcionamentos',
  components: {
    Botao,
    Snackbar,
  },
  data() {
    return {
      dialogFluxo: true,
      dialog: false,
      dialogDisable: false,
      editedIndex: -1,
      newNameSector: '',
      editedSetor: '',
      currentCategoryModal: {},
      newNameCategory: '',
      newNameSubcategory: '',
      activeSet: null,
      activeCat: null,
      activeSub: null,
      activeSetView: null,
      disableSector: false,
      disableCategory: false,
      disableSubcategory: false,
      inactiveCat: false,
      inactiveSet: false,
      inactiveSub: false,
      inactiveSetView: false,
      selectedCat: '',
      selectedSub: '',
      selectedSet: '',
      selectedSetView: '',
      type: null,
      msg: '',
      snackbarOpen: false,
      tipoSnackbar: 'info',
      sectors: [],
      categories: [],
      filterCategoriesModal: [],
      filterCategoriesTable: [],
      subcategories: [],
      headers: [
        { text: 'Categoria(s)', value: 'nome' },
        { text: 'Ações', value: 'acoes', sortable: false }],
      gestor: this.$store.getters.listaPermissoes.gestor,
      gestorGeral: this.$store.getters.listaPermissoes.gestorgeral,
      permissaoGestor: getMaxPermission(this.$store),
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Criar novo' : 'Editar Backoffice';
    },
    disableTitle() {
      let textTitle = '';
      if (this.disableCategory) {
        textTitle = 'Desabilitar categoria';
      }
      if (this.disableSubcategory) {
        textTitle = 'Desabilitar subcategoria';
      }
      if (this.disableSector) {
        textTitle = 'Desabilitar setor';
      }
      return textTitle;
    },
    typeTitle() {
      return this.type === 'category' ? 'Selecione o setor:' : 'Selecione o setor e a categoria:';
    },
    isSubcategory() {
      return (this.newNameSubcategory && this.type === 'subcategory' && this.selectedCat);
    },
    isCategory() {
      return (this.newNameCategory && this.type === 'category' && this.selectedSet);
    },
    isSector() {
      return (this.type === 'sector' && this.newNameSector);
    },
  },
  watch: {
    dialog(val) {
      return val || this.closeModal();
    },
  },
  created() {
    this.getSectorsList();
    EventBus.$on('BackofficeServiceEnabled', () => {
      this.cleanSelectSectorView('setores');
    });
  },
  methods: {
    verifyHeadersPermission() {
      if (this.permissaoGestor.id_permissao === this.gestor) {
        this.headers = [
          { text: 'Categoria', value: 'categoria_nome' },
          { text: 'Subcategoria', value: 'subcategoria_nome' },
          { text: 'Descrição', value: 'descricao' },
          { text: '', value: 'data-table-expand' },
          { text: 'Categoria(s)', value: 'nome' },
          { text: 'Ações', value: 'acoes', sortable: false },
        ];
      } else if (this.permissaoGestor.id_permissao === this.gestorGeral) {
        this.headers = [
          { text: 'Setor', value: 'setor_nome' },
          { text: 'Categoria', value: 'categoria_nome' },
          { text: 'Subcategoria', value: 'subcategoria_nome' },
          { text: 'Descrição', value: 'descricao' },
          { text: '', value: 'data-table-expand' },
          { text: 'Categoria(s)', value: 'nome' },
          { text: 'Ações', value: 'acoes', sortable: false },
        ];
      }
    },
    setCategory(index, id) {
      if (index === this.activeCat) {
        this.activeCat = null;
        this.selectedCat = '';
        this.selectedSub = '';
        this.inactiveCat = false;
        this.inactiveSub = false;
      } else {
        this.activeCat = index;
        this.selectedCat = id;
        this.inactiveCat = true;
        this.selectedSub = '';
      }
    },
    setSector(index, id) {
      if (index === this.activeSet) {
        this.activeSet = null;
        this.selectedSet = '';
        this.selectedCat = '';
        this.selectedSub = '';
        this.inactiveCat = false;
        this.inactiveSub = false;
        this.inactiveSet = false;
      } else {
        this.activeSet = index;
        this.selectedSet = id;
        this.inactiveSet = true;
        this.selectedSub = '';
        this.getCategoriesList(false);
      }
    },
    setDisableItem(item) {
      if (item.setor_id) {
        this.type = 'category';
        this.selectedCat = item.id;
        this.disableCategory = true;
      }

      if (item.categoria_id) {
        this.type = 'subcategory';
        this.selectedSub = item.id;
        this.disableSubcategory = true;
      }

      if (item.id === this.selectedSetView) {
        this.type = 'sector';
        this.selectedSet = item.id;
        this.disableSector = true;
      }
      this.dialogDisable = true;
    },
    editItem(item) {
      this.editedIndex += 1;
      if (item.setor_id) {
        this.type = 'category';
        this.newNameCategory = item.nome;
        this.selectedSet = item.setor_id;
        this.selectedCat = item.id;
        this.activeSet = item.setor_id;
        this.activeCat = item.id;
      }

      if (item.categoria_id) {
        this.type = 'subcategory';
        this.selectedSet = this.selectedSetView;
        this.activeSet = this.selectedSetView;
        this.selectedCat = item.categoria_id;
        this.activeCat = item.categoria_id;
        this.newNameSubcategory = item.nome;
        this.filterCategoryForId(item.categoria_id);
        this.selectedSub = item.id;
      }

      if (item.id === this.selectedSetView) {
        this.type = 'sector';
        this.selectedSet = item.id;
        this.newNameSector = item.nome;
      }
      this.dialog = true;
    },
    resetTipo(value) {
      if (value === 'category') {
        this.selectedCat = '';
        this.selectedSet = '';
        this.selectedSub = '';
        this.activeCat = null;
        this.activeSet = null;
        this.activeSub = null;
        this.inactiveCat = false;
        this.inactiveSet = false;
        this.inactiveSub = false;
      } else if (value === 'subcategory') {
        this.selectedSet = '';
        this.activeSet = null;
        this.inactiveSet = false;
      } else {
        this.selectedSet = '';
        this.selectedCat = '';
        this.activeSet = null;
        this.selectedSub = '';
        this.activeCat = null;
        this.activeSub = null;
        this.inactiveCat = false;
        this.inactiveSet = false;
        this.inactiveSub = false;
      }
    },
    closeModal() {
      this.type = null;
      this.newNameSector = '';
      this.newNameCategory = '';
      this.currentCategoryModal = {};
      this.newNameSubcategory = '';
      this.activeCat = null;
      this.activeSet = null;
      this.activeSub = null;
      this.inactiveCat = false;
      this.inactiveSet = false;
      this.inactiveSub = false;
      this.selectedSet = '';
      this.selectedCat = '';
      this.selectedSub = '';
      this.editedIndex = -1;
      this.dialog = false;
      this.dialogDisable = false;
      this.disableSector = false;
      this.disableCategory = false;
      this.disableSubcategory = false;
    },
    showCategoryOfSectorTable(index, id, nome) {
      if (index === this.activeSetView) {
        this.activeSetView = null;
        this.selectedSetView = '';
        this.inactiveSetView = false;
        this.editedSetor = '';
      } else {
        this.activeSetView = index;
        this.selectedSetView = id;
        this.inactiveSetView = true;
        this.editedSetor = nome;
        this.getCategoriesList(true);
        this.getSubcategories(this.selectedCat, true);
      }
    },
    showSaveButton() {
      if (this.type) {
        if (this.isSubcategory || this.isCategory || this.isSector) {
          return 'primary';
        }
      }
      return 'disabled';
    },
    getDataSaveBody() {
      if (this.isSubcategory) {
        return {
          nome: this.newNameSubcategory,
          categoria_id: this.selectedCat,
          setor_id: this.selectedSet,
        };
      }

      if (this.isCategory && !this.isSubcategory) {
        return {
          nome: this.newNameCategory,
          setor_id: this.selectedSet,
        };
      }

      if (this.isSector) {
        return {
          nome: this.newNameSector,
        };
      }

      if (this.disableSubcategory) {
        return {
          desativado: true,
        };
      }
      return {};
    },
    getDataSaveUrl() {
      if (this.isSubcategory) {
        return 'subcategorias';
      }

      if (this.isCategory && !this.isSubcategory) {
        return 'categorias';
      }

      if (this.isSector) {
        return 'setores';
      }
      return {};
    },
    getDataSaveParamsEdit() {
      if (this.isSubcategory) {
        return this.selectedSub;
      }

      if (this.isCategory && !this.isSubcategory) {
        return this.selectedCat;
      }

      if (this.isSector) {
        return this.selectedSetView;
      }
      return {};
    },
    async saveNewServiceBackoffice() {
      if (this.editedIndex > -1) {
        try {
          const body = this.getDataSaveBody();
          const url = this.getDataSaveUrl();
          const paramsEdit = this.getDataSaveParamsEdit();

          await this.$http.put(`/${url}/${paramsEdit}`, body);
          this.closeModal();
          this.msg = 'Operação realizada com sucesso!';
          this.snackbarType('success');
          this.showCategoryOfSectorTable(this.activeSetView, 0, '');
          this.getSectorsList();
        } catch (error) {
          this.msg = 'Favor preencher todos os campos!';
          this.snackbarType('error');
          console.error(error);
        }
      } else {
        try {
          const body = this.getDataSaveBody();
          const url = this.getDataSaveUrl();

          await this.$http.post(`/${url}`, body);
          this.closeModal();
          this.msg = 'Operação realizada com sucesso!';
          this.snackbarType('success');
          this.showCategoryOfSectorTable(this.activeSetView, 0, '');
          this.getSectorsList();
        } catch (error) {
          this.msg = 'Favor preencher todos os campos!';
          this.snackbarType('error');
          console.error(error);
        }
      }
    },
    getDataDisableServiceBackoffice() {
      if (this.disableSubcategory) {
        return {
          body: {
            desativado: true,
          },
          url: 'subcategorias',
          paramsDisable: this.selectedSub,
        };
      }

      if (this.disableCategory) {
        return {
          body: {
            desativado: true,
          },
          url: 'categorias',
          paramsDisable: this.selectedCat,
        };
      }

      if (this.disableSector) {
        return {
          body: {
            desativado: true,
          },
          url: 'setores',
          paramsDisable: this.selectedSet,
        };
      }
      return {};
    },
    cleanSelectSectorView(url) {
      if (url === 'setores') {
        this.selectedSetView = '';
        this.activeSetView = null;
        this.inactiveSetView = false;
      }
    },
    async disableServiceBackoffice() {
      try {
        const { body, url, paramsDisable } = this.getDataDisableServiceBackoffice();

        await this.$http.put(`/${url}/${paramsDisable}`, body);
        this.closeModal();
        this.msg = 'Operação realizada com sucesso!';
        this.snackbarType('success');
        this.getSectorsList();
        this.getCategoriesList(true);
        this.getSubcategories(this.selectedCat);
        this.cleanSelectSectorView(url);
        EventBus.$emit('BackofficeServiceDisabled');
      } catch (error) {
        this.msg = 'Ocorreu um erro.';
        this.snackbarType('error');
        console.error(error);
      }
    },
    snackbarType(type) {
      this.tipoSnackbar = type;
      this.snackbarOpen = true;
    },
    async getSectorsList() {
      try {
        const { data: response } = await this.$http.get('/setores');
        this.sectors = response.map(item => ({
          id: item.id,
          nome: item.nome,
        }));
        if (this.permissaoGestor.id_permissao === this.gestor) {
          this.sectors = this.sectors.filter(item => item.id === this.permissaoGestor.id_setor);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getCategoriesList(view) {
      try {
        const { data: response } = await this.$http.get('/categorias');
        if (view) {
          this.filterCategoriesTable = this.filterCategories(response, this.selectedSetView);
        } else {
          this.filterCategoriesModal = this.filterCategoryModal(response, this.selectedSet);
        }
      } catch (error) {
        console.error(error);
      }
    },
    filterCategoryModal(categories, sector) {
      return categories.filter(
        category => category.setor_id === sector && category.desativado === false && category.nome !== 'Outros',
      );
    },
    filterCategories(categories, sector) {
      return categories.filter(
        category => category.setor_id === sector && category.desativado === false,
      );
    },
    filterCategoryForId(categoryId) {
      const selectCategory = this.filterCategoriesTable.filter(
        cat => cat.id === categoryId,
      );
      this.currentCategoryModal = {
        nome: selectCategory[0].nome,
      };
    },
    async getSubcategories(category) {
      try {
        const { data: response } = await this.$http.get(`/subcategorias/${category}`);
        this.subcategories = response;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn_add {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #ffffff !important;
  border-radius: 5px;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.v-btn_cat {
  color: #303030;
  letter-spacing: 0;
  text-transform: none;
  border: 1px solid rgba(86, 197, 201, 0.5);
  box-shadow: none;
  transition: all 0.5s ease;
  margin-top: 5px;
  &:hover {
    color: #ffffff !important;
    background-color: #0056a8 !important;
    border-color: #0056a8;
  }
  &.active {
    color: #fff !important;
    background-color: #0056a8 !important;
    border-color: #0056a8 !important;
  }
  &.inactive {
    display: none;
  }
}

.v-icon {
  margin-top: 2px;
}
.v-input--selection-controls {
  margin-top: 4px;
  padding-top: 4px;
  height: 30px;
}

.v-dialog > .v-card > .v-card__title {
  padding: 16px 24px 0px;
}

.show-activeSet-empty {
  font-size: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
}
</style>
<style lang="scss">
.v-data-table tbody tr.v-data-table__expanded__content {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  width: 100%;
}

.button-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
}

.container-show-setor {
  display: flex;
  flex-wrap: wrap;
}

.textfield-edit {
  margin-top: 10px;
}

.buttons-setor-view {
  display: flex;
  align-items: center;
}

.title-type-dialog {
  margin-bottom: 15px;
}

.descricao {
  strong {
    font-weight: bold;
  }

  p {
    margin: 0px !important;
  }
}

.btn-dialog {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border: 1px solid #0056a8 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

</style>
