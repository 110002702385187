<template>
  <div>
    <h3
      class="titulo"
      :style="styleTitulo"
    >{{ label }}</h3>
    <hr
      class="linha"
      :style="styleLinha"
    >
  </div>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'TituloCategoriaLista',
  props: {
    label: VueTypes.string.def(''),
    corTitulo: VueTypes.string.def('#303030'),
    corLinha: VueTypes.string.def('#c1c1c1'),
  },
  computed: {
    styleTitulo() {
      return `color: ${this.corTitulo};`;
    },
    styleLinha() {
      return `background-color: ${this.corLinha};`;
    },
  },
};
</script>

<style lang="scss" scoped>
.titulo {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}
.linha {
  margin-top: 3.9px;
  display: block;
  width: 100%;
  height: 1px;
  background-color: #c1c1c1;
}
</style>
