<template>
  <div>
      <v-row class="indicadores">
        <h1 class="my-5 ml-6 font-weight-bold body-0">Sumário Executivo das Áreas</h1>
        <!-- Total de atendimentos ABERTO(S), ENCERRADO(S) e REABERTO(S) por Area -->
        <v-col lg="12">
          <IndicadorAtendimentosPorAreaDiretoria
            v-if="exibeIndicadorAtendimentosPorArea"
            :nomeAreas="nomeAreas"
            :valores="abertosEncerradosReabertos"
            :key="updateAtendimentosPorArea"
            :loaderAtendimentosPorArea="loaderAtendimentosPorArea"
          />
        </v-col>
        <!-- Média(%) de SATISFAÇÃO por Área durante o ano -->
        <v-col lg="12">
          <IndicadorSatisfacaoAreaPorAno
            v-if="exibeIndicadorSatisfacaoArea"
            :mesesTratados="mesesTratados"
            :valores="mediaSatisfacaoArea"
            :key="updateMediaSatisfacaoArea"
            :loaderSatisfacaoArea="loaderSatisfacaoArea"
          />
        </v-col>
        <!-- Atendimentos por Status -->
        <v-col lg="12">
          <IndicadorAtendimentosPorArea
            v-if="exibeIndicadorTotalAtendimentoPorArea"
            :valores="TotalAtendimentosPorArea"
            :areas="areas"
            :totalAbertos="totalAbertos"
            :totalEncerrados="totalEncerrados"
            :key="updateTotalAtendimentosPorArea"
            :loaderTotalAtendimentosPorArea="loaderTotalAtendimentosPorArea"
          />
        </v-col>
        <!-- Anexos por Area-->
        <v-col lg="12">
          <IndicadorAnexosPorArea
            v-if="exibeIndicadorTotalAnexosPorArea"
            :valores="TotalAnexosPorArea"
            :areas="areasAnexos"
            :key="updateTotalAnexosPorArea"
            :loaderTotalAtendimentosPorArea="loaderTotalAnexosPorArea"
          />
        </v-col>
        <!-- Cards dos atendentes -->
        <v-row>
          <v-col lg="12">
            <IndicadorCardAreas
              v-if="exibeIndicadorCardAreas"
              :valores="atendimentosPorArea"
              :key="updateCardAreas"
              :loader="loaderPorAreas"
            />
          </v-col>
        </v-row>
      </v-row>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { setores } from '../../Constants';
import IndicadorAtendimentosPorAreaDiretoria from './IndicadorAtendimentosPorAreaDiretoria';
import IndicadorSatisfacaoAreaPorAno from './IndicadorSatisfacaoAreaPorAno';
import IndicadorAtendimentosPorArea from './IndicadorAtendimentosPorArea';
import IndicadorAnexosPorArea from './IndicadorAnexosPorArea.vue';
import IndicadorCardAreas from './IndicadorCardAreas';
import { orderByAlphabetical } from '../../utils/utilities';

export default {
  name: 'IndicadorSumarioExecutivoDiretoria',
  components: {
    IndicadorAtendimentosPorAreaDiretoria,
    IndicadorSatisfacaoAreaPorAno,
    IndicadorAtendimentosPorArea,
    IndicadorAnexosPorArea,
    IndicadorCardAreas,
  },

  props: {
    dadosFiltro: VueTypes.object.isRequired,
  },
  watch: {
    dadosFiltro(val) {
      this.populaDados(val);
    },
  },
  created() {
    this.getAtendentes();
    this.getSetores();
  },

  mounted() {
    this.populaDados(this.dadosFiltro);
  },

  data() {
    return {
      setorDev: setores.desenvolvimento,

      // Total de atendimentos ABERTO(S), ENCERRADO(S) e REABERTO(S) por Area
      exibeIndicadorAtendimentosPorArea: false,
      nomeAreas: [],
      abertosEncerradosReabertos: [],
      updateAtendimentosPorArea: 0,
      loaderAtendimentosPorArea: false,

      // Média(%) de SATISFAÇÃO por Área durante o ano
      exibeIndicadorSatisfacaoArea: false,
      mesesTratados: [],
      mediaSatisfacaoArea: [],
      updateMediaSatisfacaoArea: 0,
      loaderSatisfacaoArea: false,

      // Atendimentos por Status
      exibeIndicadorTotalAtendimentoPorArea: false,
      TotalAtendimentosPorArea: [],
      areas: [],
      totalAbertos: 0,
      totalEncerrados: 0,
      updateTotalAtendimentosPorArea: 0,
      loaderTotalAtendimentosPorArea: false,

      // Atendimentos por Status
      exibeIndicadorTotalAnexosPorArea: false,
      TotalAnexosPorArea: [],
      areasAnexos: [],
      updateTotalAnexosPorArea: 0,
      loaderTotalAnexosPorArea: false,

      // Cards dos atendentes
      atendentes: [],
      selectedSetores: [],
      exibeIndicadorCardAreas: false,
      atendimentosPorArea: [],
      updateCardAreas: 0,
      loaderPorAreas: false,
    };
  },

  methods: {
    populaDados(dados) {
      this.indicadorAtendimentosPorArea(dados);
      this.indicadorSatisfacaoAreaPorAno(dados);
      this.indicadorTotalAtendimentosPorArea(dados);
      this.indicadorTotalAnexosPorArea(dados);
      this.indicadorCardAreas(dados);
    },

    listCreator(listaKeyValue) {
      const listaKey = [];
      const listaValue = [];

      listaKeyValue.forEach((item) => {
        listaKey.push(item.nome);
        listaValue.push(item.count);
      });

      return [listaKey, listaValue];
    },

    async getSetores() {
      try {
        const { data: response } = await this.$http.get('/setores');
        this.setores = orderByAlphabetical(response, 'setores');
        this.selectedSetores = this.setores.filter(item => item.nome !== this.setorDev);
      } catch (error) {
        console.error(error);
      }
    },

    async getAtendentes() {
      try {
        const { data: response } = await this.$http.get('/atendentes');
        this.atendentes = response;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorAtendimentosPorArea(dadosFiltro) {
      try {
        this.loaderAtendimentosPorArea = true;
        const { data: response } = await this.$http.post('/indicadores/setores-aberto-reaberto-fechado', dadosFiltro);

        this.nomeAreas = response.nomes;

        this.abertosEncerradosReabertos = [response.abertos,
          response.encerrados, response.reabertos];

        this.exibeIndicadorAtendimentosPorArea = true;
        this.updateAtendimentosPorArea += 1;
        this.loaderAtendimentosPorArea = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorSatisfacaoAreaPorAno(dadosFiltro) {
      try {
        this.loaderSatisfacaoArea = true;
        const { data: response } = await this.$http.post('/indicadores/satisfacao-setores', dadosFiltro);

        this.mesesTratados = response.mesesTratados;
        this.mediaSatisfacaoArea = response.valores;

        this.exibeIndicadorSatisfacaoArea = true;
        this.updateMediaSatisfacaoArea += 1;
        this.loaderSatisfacaoArea = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorTotalAtendimentosPorArea(dadosFiltro) {
      try {
        this.loaderTotalAtendimentosPorArea = true;

        const { data: response } = await this.$http.post('/indicadores/atendimentos-setores-status', dadosFiltro);
        const [nome, count] = this.listCreator(response.atendimentos);

        this.TotalAtendimentosPorArea = count;
        this.areas = nome;
        this.totalAbertos = response.totalAbertos;
        this.totalEncerrados = response.totalEncerrados;

        this.exibeIndicadorTotalAtendimentoPorArea = true;
        this.updateTotalAtendimentosPorArea += 1;
        this.loaderTotalAtendimentosPorArea = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorTotalAnexosPorArea(dadosFiltro) {
      try {
        this.loaderTotalAnexosPorArea = true;

        const { data: response } = await this.$http.post('/indicadores/anexos-por-areas', dadosFiltro);
        const [nome, count] = this.listCreator(response.arquivos);

        const countFormatado = this.formataTamanhoAnexos(count);

        this.TotalAnexosPorArea = countFormatado;
        this.areasAnexos = nome;

        this.exibeIndicadorTotalAnexosPorArea = true;
        this.updateTotalAnexosPorArea += 1;
        this.loaderTotalAnexosPorArea = false;
      } catch (error) {
        console.error(error);
      }
    },

    formataTamanhoAnexos(count) {
      const retorno = [];

      count.forEach((item) => {
        const kb = item / 1000;
        retorno.push((kb / 1000).toFixed(2));
      });

      return retorno;
    },

    async indicadorCardAreas(dadosFiltro) {
      this.loaderPorAreas = true;
      const { data: response } = await this.$http.post('/indicadores/cards-por-areas', dadosFiltro);

      const retorno = [];
      this.selectedSetores.forEach(async (setor) => {
        const dias = response.media.tempoMedia.filter(
          item => item.setor_id === setor.id,
        );
        const avaliacoes = response.avaliacoes.filter(
          item => item.setor_id === setor.id,
        );
        let encerrados = response.encerrados.filter(
          item => item.setor_id === setor.id,
        );
        let reabertos = response.reabertos.filter(
          item => item.setor_id === setor.id,
        );
        let pendentes = response.pendentes.filter(
          item => item.setor_id === setor.id,
        );
        if (encerrados.length) {
          encerrados = encerrados[0].count;
        } else {
          encerrados = '0';
        }
        if (reabertos.length) {
          reabertos = reabertos[0].count;
        } else {
          reabertos = '0';
        }
        if (pendentes.length) {
          pendentes = pendentes[0].count;
        } else {
          pendentes = '0';
        }
        const avatar = null;
        retorno.push({
          nome: setor.nome,
          avatar,
          dias: dias.length ? dias[0].valor : 0,
          avaliacao: avaliacoes.length ? [avaliacoes[0].insatisfeito,
            avaliacoes[0].neutro, avaliacoes[0].satisfeito, avaliacoes[0].naoAvaliar] : ['0', '0', '0', '0'],
          atendimento: [encerrados, reabertos],
          pendente: pendentes,
        });
      });
      this.atendimentosPorArea = retorno;

      this.exibeIndicadorCardAreas = true;
      this.updateCardAreas += 1;
      this.loaderPorAreas = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.indicadores {
  display: flex;
  justify-content: center;
  max-width: 1250px;
}
</style>
