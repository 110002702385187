<template>
  <span
    class="circular-status"
    :[size]="true"
    :style="cor"
    :square="square"
  ></span>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'CircularStatus',
  props: {
    status: VueTypes.string.isRequired,
    size: VueTypes.oneOf(['small', 'normal', 'big', 'biggest']).def('normal'),
    square: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cor() {
      return `backgroundColor: ${this.status};`;
    },
  },
};
</script>
<style scoped lang="scss">
.circular-status {
  display: inline-block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
.circular-status[square] {
  border-radius: 2px;
}
.circular-status[small] {
  width: 6px;
  height: 6px;
  margin: 3px;
}
.circular-status[normal] {
  margin: 1.5px;
  width: 8px;
  height: 8px;
}
.circular-status[big] {
  width: 10px;
  height: 10px;
}
.circular-status[biggest] {
  width: 12px;
  height: 12px;
}
</style>
