import { render, staticRenderFns } from "./IndicadorAtendimentosPorAreaDiretoria.vue?vue&type=template&id=44c5922c&scoped=true&"
import script from "./IndicadorAtendimentosPorAreaDiretoria.vue?vue&type=script&lang=js&"
export * from "./IndicadorAtendimentosPorAreaDiretoria.vue?vue&type=script&lang=js&"
import style0 from "./IndicadorAtendimentosPorAreaDiretoria.vue?vue&type=style&index=0&id=44c5922c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44c5922c",
  null
  
)

export default component.exports