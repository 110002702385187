<template>
  <Layout>
    <div class="solicitacao">
      <h1> Obrigado! </h1>
      <hr>
        <p>
          Status da solicitação: <strong>{{status}}</strong>
        </p>
    </div>
  </Layout>
</template>

<script>
import Layout from '../layouts/Layout.vue';

export default {
  name: 'SolicitacaoAprovacao',

  components: {
    Layout,
  },

  data() {
    return {
      hash: this.$route.params.hash,
      status: this.$route.params.status,
    };
  },

  async mounted() {
    await this.enviaStatusAprovacao();
  },

  methods: {
    async enviaStatusAprovacao() {
      try {
        const body = {
          statusAprovacao: this.status,
        };

        await this.$http.put(`/solicitacao-aprovacao/${this.hash}`, body);
      } catch (error) {
        const { response: { data: { message } } } = error;
        this.status = message;
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.solicitacao {
  margin-top: 200px;
  justify-content: center;
  text-align: center;
  align-items: center;
}
</style>
