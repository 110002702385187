import { render, staticRenderFns } from "./IndicadoresAtendente.vue?vue&type=template&id=4c510068&scoped=true&"
import script from "./IndicadoresAtendente.vue?vue&type=script&lang=js&"
export * from "./IndicadoresAtendente.vue?vue&type=script&lang=js&"
import style0 from "./IndicadoresAtendente.vue?vue&type=style&index=0&id=4c510068&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c510068",
  null
  
)

export default component.exports