<template>
  <layout>
    <Breadcrumb :breadcrumbs="breadcrumbs" />
    <hr />
    <div class="header">
      <h1>Solicitação de Aprovação</h1>
    </div>
    <div>
      <Snackbar
      :label="msg"
      v-model="snackbarOpen"
      top
      right
      :tipo="tipoSnackbar"
      :timeout="2500"
    />
    </div>
    <div class="ApproveAll">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            rounded
            class="btn-dialog"
            @click.prevent="dialogFluxoVisualizarAlterados = true"
          >
            Visualizar todas Aprovações
          </v-btn>
        </template>
        <span>Visualizar Todas</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }"
        v-if="validaAprovadorFunc">
          <v-btn
            v-if="validaAprovadorFunc"
            rounded
            class="btn-dialog"
            @click.prevent="dialogFluxoAprovarTodos = true"
          >
            Aprovar Todas
          </v-btn>
        </template>
        <span>Aprovar t</span>
      </v-tooltip>
      </div>
      <v-dialog
        v-model="dialogFluxoVisualizarAlterados"
        max-width="450"
      >
        <v-card>
          <v-card-title class="headline">Atenção!</v-card-title>
          <v-card-text>
            <strong>Essas são as solicitações aprovadas e recusadas:</strong>
          </v-card-text>
          <RelatorioSolicitacoes/>
          <v-data-table
            :headers="headersAlterados"
            :items="fluxosAlterados"
            :items-per-page="5"
            :no-data-text="'Não há solicitações para serem aceitas'"
            :footer-props="{
              'items-per-page-text': 'Resultados por página',
              'items-per-page-all-text': 'Todos',
              'page-text': '{0}-{1} de {2}',
            }"
          >
            <template v-slot:item.acoes="{item}">
              <v-tooltip top>
              <template v-slot:activator="{ on }">
                <a :href="`/atendimento/${item.id_atendimento}`" target="_blank" rel="noopener">
                  <v-icon
                    small
                    dark
                    class="mr-2"
                    v-on="on"
                  >
                    remove_red_eye
                  </v-icon>
                </a>
              </template>
              <span>Ver fluxo completo</span>
            </v-tooltip>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="btn-dialog-cancel mr-2 mb-2"
              text
              @click="dialogFluxoVisualizarAlterados = false"
            >
              Sair
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogFluxoAprovarTodos"
        max-width="350"
      >
        <v-card>
          <v-card-title class="headline">Atenção!</v-card-title>
          <v-card-text>
            <strong>Você tem certeza que deseja aprovar todas as solicitações?</strong>.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="btn-dialog mr-2 mb-2"
              text
              @click="aprovarTodos()"
            >
              Aprovar Todas
            </v-btn>
            <v-btn
              class="btn-dialog-cancel mr-2 mb-2"
              text
              @click="dialogFluxoAprovarTodos = false"
            >
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-data-table
      :headers="headers"
      :items="fluxos"
      :items-per-page="5"
      :no-data-text="'Não há solicitações para serem aceitas'"
      :footer-props="{
        'items-per-page-text': 'Resultados por página',
        'items-per-page-all-text': 'Todos',
        'page-text': '{0}-{1} de {2}',
    }"
    >
      <template v-slot:item.roundCheckBoxType="{item}">
        {{ item.roundCheckBoxType }}
      </template>
      <template v-slot:item.selectedMonth="{item}">
        {{ item.selectedMonth }}
      </template>
      <template v-slot:item.employee="{item}">
        <span>{{ item.employee }} </span>
      </template>
      <template v-slot:item.manager="{item}">
        <span>{{ item.manager }} </span>
      </template>
      <template v-slot:item.reason="{item}">
        <span>{{ item.reason }} </span>
      </template>
      <template
      v-if="validaAprovadorFunc
      || validaAprovadorCeoFunc"
        v-slot:item.acoes="{item}">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              dark
              class="mr-2"
              v-on="on"
              @click.stop="dialogFluxoAprovar = true,
              comentario = ''"
            >
              check_circle
            </v-icon>
          </template>
          <span>Aprovar</span>
        </v-tooltip>
        <v-dialog
          v-model="dialogFluxoAprovar"
          max-width="auto"
        >
          <v-card>
            <v-card-title class="headline">Atenção!</v-card-title>
            <v-card-text>
              <strong>Você tem certeza que deseja aprovar essa solicitação?</strong><br>
              <strong
              v-if="!validaAprovadorCeoFunc()">
              Caso seja necessário uma aprovação de um superior clique
                em "Solicitar Aprovação".</strong>
            </v-card-text>
            <v-card-title class="title-atendimento text-center">Motivo para
            aprovar:</v-card-title>
          <v-card-text>
            <v-col cols="auto">
              <v-textarea
                v-model="comentario"
                label="Insira o motivo para aprovar essa solicitação"
                outlined
                dense
                clearable
                auto-grow
              ></v-textarea>
            </v-col>
          </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="!validaAprovadorCeoFunc()"
                class="btn-dialog-other mr-2 mb-2"
                text
                @click="enviaTerceiroAprovador(item)"
              >
                Solicitar aprovação
              </v-btn>
              <v-btn
                v-if="comentario"
                class="btn-dialog mr-2 mb-2"
                text
                @click="aprovarItem(item)"
              >
                Aprovar
              </v-btn>
              <v-btn
                class="btn-dialog-cancel mr-2 mb-2"
                text
                @click="dialogFluxoAprovar = false"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              dark
              class="mr-2"
              v-on="on"
              @click.stop="dialogFluxoRecusar = true,
              comentario = ''"
            >
              disabled_by_default
            </v-icon>
          </template>
          <span>Reprovar</span>
        </v-tooltip>
        <v-dialog
          v-model="dialogFluxoRecusar"
          max-width="500"
        >
          <v-card>
            <v-card-title class="headline">Atenção!</v-card-title>
            <v-card-text>
              <strong>Você tem certeza que deseja recusar essa solicitação?</strong>.
            </v-card-text>
          <v-card-title class="title-atendimento text-center">Motivo para
            recusar:</v-card-title>
          <v-card-text>
            <v-col cols="auto">
              <v-textarea
                v-model="comentario"
                label="Insira o motivo para recusar essa solicitação"
                outlined
                dense
                clearable
                auto-grow
              ></v-textarea>
            </v-col>
          </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="comentario"
                class="btn-dialog mr-2 mb-2"
                text
                @click="reprovarItem(item)"
              >
                Recusar
              </v-btn>
              <v-btn
                class="btn-dialog-cancel mr-2 mb-2"
                text
                @click="dialogFluxoRecusar = false"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <a :href="`/atendimento/${item.id_atendimento}`" target="_blank" rel="noopener">
              <v-icon
                small
                dark
                class="mr-2"
                v-on="on"
              >
                remove_red_eye
              </v-icon>
            </a>
          </template>
          <span>Ver fluxo completo</span>
        </v-tooltip>
      </template>
      </v-data-table>
  </layout>
</template>

<script>
import moment from 'moment';
import layout from '../layouts/Layout.vue';
import Breadcrumb from './Breadcrumb';
import RelatorioSolicitacoes from './RelatorioSolicitacoes.vue';
import Snackbar from './Snackbar.vue';
import {
  getMaxPermission,
  validaAprovador,
  validaAprovadorCeo,
} from '../utils/utilities';
import { setores } from '../Constants';
import Notify from '../mixins/Notify';

export default {
  name: 'Aprovacao',
  components: {
    Breadcrumb,
    layout,
    Snackbar,
    RelatorioSolicitacoes,
  },
  mixins: [Notify],
  data() {
    return {
      dialogFluxoAprovar: false,
      dialogFluxoRecusar: false,
      dialogFluxoAprovarTodos: false,
      dialogFluxoVisualizarAlterados: false,
      snackbarOpen: false,
      tipoSnackbar: 'info',
      comentario: '',
      msg: '',
      atendente: this.$store.getters.listaPermissoes.atendente,
      gestor: this.$store.getters.listaPermissoes.gestor,
      gestorGeral: this.$store.getters.listaPermissoes.gestorgeral,
      aprovador: this.$store.getters.listaPermissoes.aprovador,
      aprovadorCeo: this.$store.getters.listaPermissoes.aprovadorceo,
      permissaoGestor: getMaxPermission(this.$store),
      fluxos: [],
      fluxosAlterados: [],
      headers: [],
      headersAlterados: [],
    };
  },

  created() {
    moment.locale('pt-br');
  },
  async mounted() {
    await this.populaFluxos();
    this.verifyHeaders();
    this.populaFluxosAlterados();
    this.verifyHeadersAlterados();
  },

  methods: {
    validaAprovadorFunc() {
      return validaAprovador(this.$store, setores);
    },

    validaAprovadorCeoFunc() {
      return validaAprovadorCeo(this.$store,
        this.$store.getters.listaPermissoes.aprovadorceo);
    },

    async enviaTerceiroAprovador(item) {
      try {
        const body = {
          statusAprovacao: 'Pendente',
          segundaAprovacao: true,
          id_aprovador: this.$store.getters.isUsuario.id,
        };
        await this.$http.put(`/email-aprovacao/${item.id_solicitacao}`, body);
        this.snackbarOpen = true;
        this.tipoSnackbar = 'sucess';
        this.msg = 'A solicitação foi enviada ao terceiro aprovador!';
        this.dialogFluxoAprovar = false;
        this.populaFluxos();
        this.populaFluxosAlterados();
        // notificação ao email do responsavel
        this.notificaSolicitante('Enviado ao CEO', item);
      } catch (e) {
        console.error(e);
      }
    },

    notificaSolicitante(valor, item) {
      const dados = {
        id_usuario: item.id_atendente,
        id_usuario_responsavel: this.$store.getters.isUsuario.id,
        id_atendimento: item.id_atendimento,
        resposta: valor,
      };
      this.emiteNotificacao('solicitacaoAprovacao', dados);
    },

    async reprovarItem(item) {
      try {
        let body = {};
        if (this.validaAprovadorCeoFunc()) {
          body = {
            statusAprovacao: 'Recusada',
            terceiraAprovacao: true,
            comentario: this.comentario,
            id_aprovador: this.$store.getters.isUsuario.id,
          };
        } else if (this.validaAprovadorFunc()) {
          body = {
            statusAprovacao: 'Recusada',
            segundaAprovacao: true,
            comentario: this.comentario,
            id_aprovador: this.$store.getters.isUsuario.id,
          };
        }
        await this.$http.put(`/email-aprovacao/${item.id_solicitacao}`, body);
        this.snackbarOpen = true;
        this.tipoSnackbar = 'sucess';
        this.msg = 'A solicitação foi recusada!';
        this.dialogFluxoRecusar = false;
        this.populaFluxos();
        this.populaFluxosAlterados();
        this.notificaSolicitante('Recusada', item);
      } catch (e) {
        console.error(e);
      }
    },

    async aprovarItem(item) {
      try {
        let body = {};
        if (this.validaAprovadorCeoFunc()) {
          body = {
            statusAprovacao: 'Aceita',
            terceiraAprovacao: true,
            comentario: this.comentario,
            id_aprovador: this.$store.getters.isUsuario.id,
          };
        } else if (this.validaAprovadorFunc()) {
          body = {
            statusAprovacao: 'Aceita',
            segundaAprovacao: true,
            comentario: this.comentario,
            id_aprovador: this.$store.getters.isUsuario.id,
          };
        }
        await this.$http.put(`/email-aprovacao/${item.id_solicitacao}`, body);
        this.snackbarOpen = true;
        this.tipoSnackbar = 'sucess';
        this.msg = 'A solicitação foi aprovada!';
        this.dialogFluxoAprovar = false;
        this.populaFluxos();
        this.populaFluxosAlterados();
        this.notificaSolicitante('Aceita', item);
      } catch (e) {
        console.error(e);
      }
    },

    async aprovarTodos() {
      try {
        await this.$http.post('/segunda-aprovacao-aprovar-todas/');
        this.snackbarOpen = true;
        this.tipoSnackbar = 'sucess';
        this.msg = 'Todas solicitações em pendente foram aprovadas!';
        let listaAprovados = {};
        listaAprovados = this.fluxos.map(item => ({
          id_atendimento: item.id_atendimento,
          id_atendente: item.id_atendente,
        }));
        listaAprovados.forEach((item) => {
          this.notificaSolicitante('Aceita', item);
        });
        this.dialogFluxoAprovarTodos = false;
        this.populaFluxos();
        this.populaFluxosAlterados();
      } catch (e) {
        console.error(e);
      }
    },

    async populaFluxosAlterados() {
      const { data: response } = await this.$http.get('/segunda-aprovacao-all/');
      this.fluxosAlterados = response.map(item => ({
        id_atendimento: item.id_atendimento,
        status_aprovacao: item.status_aprovacao,
        data: this.dataFormatted(item.updated_at),
        comentario: item.comentario,
      }));
    },

    async populaFluxos() {
      let lista = {};
      if (this.validaAprovadorCeoFunc()) {
        const { data: response } = await this.$http.get('/terceira-aprovacao-pendente/');
        lista = response;
      } else if (this.validaAprovadorFunc()) {
        const { data: response } = await this.$http.get('/segunda-aprovacao-pendente/');
        lista = response;
      }
      this.fluxos = lista.map(item => ({
        roundCheckBoxType: item.dados_fluxo.roundCheckBoxType,
        selectedMonth: item.dados_fluxo.selectedMonth,
        employee: item.dados_fluxo.employee,
        manager: item.dados_fluxo.manager,
        reason: item.dados_fluxo.reason,
        id_atendimento: item.atendimento_id,
        id_solicitacao: item.id,
        id_atendente: item.atendente_id,
      }));
    },

    verifyHeadersAlterados() {
      this.headersAlterados = [
        { text: 'Número do atendimento', value: 'id_atendimento' },
        { text: 'Status da Aprovação', value: 'status_aprovacao' },
        { text: 'Data de alteração', value: 'data' },
        { text: 'Ações', value: 'acoes', sortable: false },
      ];
    },

    verifyHeaders() {
      this.headers = [
        { text: 'Regime de Contratação', value: 'roundCheckBoxType' },
        { text: 'Mês', value: 'selectedMonth' },
        { text: 'Funcionário', value: 'employee' },
        { text: 'Gestor Aprovador', value: 'manager' },
        { text: 'Motivo', value: 'reason' },
        { text: 'Ações', value: 'acoes', sortable: false },
      ];
    },
    dataFormatted(data) {
      return data ? moment(data).format('DD/MM/YYYY') : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 60px;
  margin-top: -50px;
  text-align: center;
  color: #122870;
  font-size: 30px;
}

.ApproveAll{
  display: flex;
  font-size: 18px;
  justify-content: flex-end;
  margin-right: 10px;
}
.btn-dialog {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border: 1px solid #0056a8 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}
.btn-dialog-other {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #175804e1 !important;
  color: #fff !important;
  border: 1px solid #175804e1 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}
.btn-dialog-cancel {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #666262 !important;
  color: #fff !important;
  border: 1px solid #bfbfbf !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}
</style>
