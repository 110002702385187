<template>
  <div id="reajuste">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
      <v-row>
        <v-col>
          <h3 class="titleHeader mb-3">Mensagem</h3>
          <p class="caption">
            Os campos com
            <span class="count-warning">*</span>
            são obrigatórios.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-radio-group
            v-model="roundCheckBoxType"
            row
            label="Regime de Contratação: "
          >
            <v-radio
              label="CLT"
              value="CLT"
            ></v-radio>
            <v-radio
              label="EPS"
              value="EPS"
            ></v-radio>
            <v-radio
              label="Estagiário"
              value="Estagiário"
            ></v-radio>
            <div class="caption count-warning">*</div>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
          <v-select
            v-model="selectedMonth"
            :items="months"
            label="Mês"
            outlined
            dense
          ></v-select>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>
      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="employee"
            label="Funcionário"
            outlined
            dense
            clearable
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>
      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="manager"
            label="Gestor aprovador"
            outlined
            dense
            clearable
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>
      <v-row>
        <v-col cols="5">
          <v-select
            v-model="reason"
            :items="reasonList"
            label="Motivo"
            outlined
            dense
          ></v-select>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>
      <v-row>
        <v-col cols="5">
          <v-text-field
            v-if="reason == 'Mérito' "
            v-model="motivo"
            label="Justificativa de Motivo"
            outlined
            dense
            clearable
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>
      <h3 class="titleHeader mb-3">Cargo/Função</h3>
      <v-row>
        <v-col cols="2">
          <v-text-field
            v-model="occupationPositionFrom"
            label="De"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
          <v-col cols="3">
          <v-text-field
            v-model="occupationPositionTo"
            label="Para"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>
      <h3 class="titleHeader mb-3">Senioridade</h3>
      <v-row>
        <v-col cols="2">
          <v-text-field
            v-model="experienceFrom"
            label="De"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
          <v-col cols="3">
          <v-text-field
            v-model="experienceTo"
            label="Para"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>
      <h3 class="titleHeader mb-3">Remuneração</h3>
      <p class="caption" v-if="roundCheckBoxType === 'CLT'">
            Inserir a remuneração sem o CCT pois será aplicado automaticamente
          </p>
      <v-row v-if="checkTheBoxPayment === true">
        <v-col cols="2">
          <v-text-field
            v-money="money"
            v-model.lazy="paymentFrom"
            label="De"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
          <v-col cols="3">
          <v-text-field
            v-money="money"
            v-model.lazy="paymentTo"
            label="Para"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>
      <v-row>
        <v-col cols="5">
          <v-text-field
            v-if="roundCheckBoxType === 'CLT'"
            v-bind="money"
            v-model.lazy="paymentCalc"
            label="Valor atualizado com CCT"
            outlined
            dense
            clearable
            auto-grow
            :disabled="caixaFalsa ? false : true"
          ></v-text-field>
        </v-col>
      </v-row>
      <div class="div-marcar-desmarcar">
        <v-checkbox
          dense
          v-model="checkTheBoxProgram"
          label="Programa Evoluir (Cursos)"
        >
        </v-checkbox>
        <v-row v-if="checkTheBoxProgram === true">
          <v-col cols="2">
            <v-text-field
              v-money="money"
              v-model.lazy="programFrom"
              label="De"
              outlined
              dense
              clearable
              auto-grow
            ></v-text-field>
          </v-col>
          <div class="caption count-warning">*</div>
            <v-col cols="3">
            <v-text-field
              v-money="money"
              v-model.lazy="programTo"
              label="Para"
              outlined
              dense
              clearable
              auto-grow
            ></v-text-field>
          </v-col>
          <div class="caption count-warning">*</div>
        </v-row>
      </div>
      <div class="div-marcar-desmarcar">
        <v-checkbox
          dense
          v-model="checkTheBoxAllowance"
          label="Ajuda de Custo (Valor que será comprovado com notas, sem VA)"
        >
        </v-checkbox>
        <v-row v-if="checkTheBoxAllowance === true">
          <v-col cols="2">
            <v-text-field
              v-money="money"
              v-model.lazy="allowanceFrom"
              label="De"
              outlined
              dense
              clearable
              auto-grow
            ></v-text-field>
          </v-col>
          <div class="caption count-warning">*</div>
            <v-col cols="3">
            <v-text-field
              v-money="money"
              v-model.lazy="allowanceTo"
              label="Para"
              outlined
              dense
              clearable
              auto-grow
            ></v-text-field>
          </v-col>
          <div class="caption count-warning">*</div>
        </v-row>
      </div>
      <div class="div-marcar-desmarcar">
        <v-checkbox
          dense
          v-model="checkTheBoxMealTicket"
          label="Vale Alimentação
          (Se marcado Ajuda de Custo, necessário informar R$ 500, R$ 1.000 ou R$ 1.500)"
        >
        </v-checkbox>
        <v-row v-if="checkTheBoxMealTicket === true">
          <v-col cols="2">
            <v-text-field
              v-money="money"
              v-model.lazy="mealTicketFrom"
              label="De"
              outlined
              dense
              clearable
              auto-grow
            ></v-text-field>
          </v-col>
          <div class="caption count-warning">*</div>
            <v-col cols="3">
            <v-text-field
              v-money="money"
              v-model.lazy="mealTicketTo"
              label="Para"
              outlined
              dense
              clearable
              auto-grow
            ></v-text-field>
          </v-col>
          <div class="caption count-warning">*</div>
        </v-row>
      </div>
      <v-row>
        <v-col cols="9">
          <v-textarea
            v-model="description"
            label="Descrição"
            outlined
            dense
            clearable
            auto-grow
          ></v-textarea>
          <p class="caption text-right">
            <strong>OBS:</strong>
            Dê a maior quantidade de informações possível.
             ( Mínimo 20 caracteres /
            <span :class="description.length < 20 ? 'count-warning' : 'count-success'">
              {{ countLengthDescription }}
            </span> )
          </p>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import moment from 'moment';
import { VMoney } from 'v-money';
import Snackbar from '../Snackbar';

export default {
  name: 'Reajuste',
  directives: { money: VMoney },
  components: { Snackbar },

  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
      checkTheBoxPayment: true,
      checkTheBoxProgram: false,
      checkTheBoxAllowance: false,
      checkTheBoxMealTicket: false,
      selectedMonth: '',
      roundCheckBoxType: 'CLT',
      employee: '',
      manager: '',
      dadosFluxo: {},
      newDadosFluxo: {},
      reason: '',
      motivo: '',
      occupationPositionFrom: '',
      occupationPositionTo: '',
      experienceFrom: '',
      experienceTo: '',
      paymentFrom: '',
      paymentTo: '',
      caixaFalsa: false,
      programFrom: '',
      programTo: '',
      paymentCalc: '',
      allowanceFrom: '',
      allowanceTo: '',
      description: '',
      reasonList: ['Mérito', 'Promoção', 'Outros'],
      months: ['Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'].slice(this.validateMonths()),
      dialogFluxo: true,
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },

  created() {
    moment.locale('pt-br');
  },

  watch: {
    paymentTo(value) {
      let justNumbers = value.replace(/[.R$ ]/g, '');
      justNumbers = parseFloat(justNumbers);
      let calculation = (justNumbers * 1.05);
      calculation = this.formaNumber(calculation);
      this.paymentCalc = calculation.toString().replace('€', '');
    },
  },

  computed: {
    countLengthDescription() {
      return this.description === '' ? '0' : this.description.length;
    },
    verificaCampos() {
      return (!!this.selectedMonth
      && !!this.roundCheckBoxType
      && !!this.employee
      && !!this.manager
      && !!this.reason
      && !!this.description
      && !!this.occupationPositionFrom
      && !!this.occupationPositionTo
      && !!this.experienceFrom
      && !!this.experienceTo
      && !!this.validatePayment()
      && !!this.validateProgram()
      && !!this.validateAllowance()
      && !!this.validateMealTicket()
      );
    },
  },
  methods: {
    validatePayment() {
      if (this.paymentTo === 'R$ 0,00') return '';
      return true;
    },
    validateProgram() {
      if (this.checkTheBoxProgram) {
        if (this.programTo === 'R$ 0,00') return '';
        return true;
      }
      return true;
    },
    validateAllowance() {
      if (this.checkTheBoxAllowance) {
        if (this.programTo === 'R$ 0,00') return '';
        return true;
      }
      return true;
    },
    validateMealTicket() {
      if (this.checkTheBoxMealTicket) {
        if (this.programTo === 'R$ 0,00') return '';
        return true;
      }
      return true;
    },
    validateMonths() {
      const month = new Date().getMonth();
      const day = new Date().getDate();
      if (day > 5) return (month + 1);
      return month;
    },

    formaNumber(value) {
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency', currency: 'EUR',
      });

      return formatter.format(value);
    },

    trataResposta(dadosFluxo) {
      const respostasModel = {};

      if (dadosFluxo.roundCheckBoxType) {
        respostasModel.column_campo_1 = dadosFluxo.roundCheckBoxType;
      }
      if (dadosFluxo.selectedMonth) respostasModel.column_campo_2 = dadosFluxo.selectedMonth;
      if (dadosFluxo.employee) respostasModel.column_campo_3 = dadosFluxo.employee;
      if (dadosFluxo.manager) respostasModel.column_campo_4 = dadosFluxo.manager;
      if (dadosFluxo.reason) respostasModel.column_campo_5 = dadosFluxo.reason;
      if (dadosFluxo.motivo) respostasModel.column_campo_6 = dadosFluxo.motivo;
      if (dadosFluxo.occupationPositionFrom) {
        respostasModel.column_campo_7 = dadosFluxo.occupationPositionFrom;
      }
      if (dadosFluxo.occupationPositionTo) {
        respostasModel.column_campo_8 = dadosFluxo.occupationPositionTo;
      }
      if (dadosFluxo.experienceFrom) respostasModel.column_campo_9 = dadosFluxo.experienceFrom;
      if (dadosFluxo.experienceTo) respostasModel.column_campo_10 = dadosFluxo.experienceTo;
      if (dadosFluxo.paymentFrom) respostasModel.column_campo_11 = dadosFluxo.paymentFrom;
      if (dadosFluxo.paymentTo) respostasModel.column_campo_12 = dadosFluxo.paymentTo;
      if (dadosFluxo.paymentCalc) respostasModel.column_campo_13 = dadosFluxo.paymentCalc;
      if (dadosFluxo.checkTheBoxProgram) {
        respostasModel.column_campo_14 = dadosFluxo.checkTheBoxProgram;
      }
      if (dadosFluxo.programFrom) respostasModel.column_campo_15 = dadosFluxo.programFrom;
      if (dadosFluxo.programTo) respostasModel.column_campo_16 = dadosFluxo.programTo;
      if (dadosFluxo.checkTheBoxAllowance) {
        respostasModel.column_campo_17 = dadosFluxo.checkTheBoxAllowance;
      }
      if (dadosFluxo.allowanceFrom) respostasModel.column_campo_18 = dadosFluxo.allowanceFrom;
      if (dadosFluxo.allowanceTo) respostasModel.column_campo_19 = dadosFluxo.allowanceTo;
      if (dadosFluxo.checkTheBoxMealTicket) {
        respostasModel.column_campo_20 = dadosFluxo.checkTheBoxMealTicket;
      }
      if (dadosFluxo.mealTicketFrom) respostasModel.column_campo_21 = dadosFluxo.mealTicketFrom;
      if (dadosFluxo.mealTicketTo) respostasModel.column_campo_22 = dadosFluxo.mealTicketTo;
      if (dadosFluxo.description) respostasModel.column_campo_23 = dadosFluxo.description;

      respostasModel.validation = true;
      this.newDadosFluxo = respostasModel;
    },

    enviaJSON() {
      if (!this.verificaCampos) {
        this.dadosFluxo = { validation: false };
        this.alerta = 'error';
        this.msg = 'Todos os campos são obrigatórios.';
        this.snackBarAlertaCampos = true;
      } else if (this.verificaCampos) {
        if (this.description.length >= 20) {
          this.dadosFluxo = {
            validation: true,
            selectedMonth: this.selectedMonth,
            roundCheckBoxType: this.roundCheckBoxType,
            employee: this.employee,
            manager: this.manager,
            reason: this.reason,
            motivo: this.motivo,
            description: this.description,
            occupationPositionFrom: this.occupationPositionFrom,
            occupationPositionTo: this.occupationPositionTo,
            experienceFrom: this.experienceFrom,
            experienceTo: this.experienceTo,
            paymentCalc: this.paymentCalc,
            paymentFrom: this.paymentFrom,
            paymentTo: this.paymentTo,
            checkTheBoxPayment: this.checkTheBoxPayment,

            checkTheBoxProgram: this.checkTheBoxProgram,
            programFrom: this.checkTheBoxProgram ? this.programFrom : '',
            programTo: this.checkTheBoxProgram ? this.programTo : '',

            checkTheBoxAllowance: this.checkTheBoxAllowance,
            allowanceFrom: this.checkTheBoxAllowance ? this.allowanceFrom : '',
            allowanceTo: this.checkTheBoxAllowance ? this.allowanceTo : '',

            checkTheBoxMealTicket: this.checkTheBoxMealTicket,
            mealTicketFrom: this.checkTheBoxMealTicket ? this.mealTicketFrom : '',
            mealTicketTo: this.checkTheBoxMealTicket ? this.mealTicketTo : '',

          };
          this.trataResposta(this.dadosFluxo);
          this.$emit('dadosFluxo', this.newDadosFluxo);
        } else {
          this.dadosFluxo = { validation: false };
          this.alerta = 'error';
          this.msg = 'Descrição precisa ter mais de 20 caracteres.';
          this.snackBarAlertaCampos = true;
          this.$emit('dadosFluxo', this.dadosFluxo);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

.btn-dialog {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border: 1px solid #0056a8 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.v-icon {
  margin-top: 2px;
}

.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.count-warning {
  background-color: transparent;
  color: red;
}

.count-success {
  background-color: transparent;
  color: green;
}

.type-payment {
  margin:0;
}

.card-filtro {
  border: 2px solid black;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;

  .titulo-marcar-desmarcar {
    display: flex;

    .div-marcar-desmarcar {
      display: flex;
      align-items: flex-start;
      padding-left: 20px;
    }
  }
}

</style>
