<template>
  <v-data-table
    :headers="headers"
    :single-expand="true"
    :items="desserts"
    :items-per-page="5"
    :sort-by.sync="sortBy"
    :sort-desc="true"
    class="elevation-4 card-indicador"
    :footer-props="{
        'items-per-page-text': 'Resultados por página',
        'items-per-page-all-text': 'Todos',
        'page-text': '{0}-{1} de {2}'
      }"
  ></v-data-table>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'IndicadorTabelaDesligamento',

  props: {
    dadosDesligamento: VueTypes.array.isRequired,
  },
  watch: {
    dadosDesligamento(val) {
      this.desserts = val;
    },
  },
  data() {
    return {
      sortBy: 'total',
      headers: [
        {
          text: 'Solicitante',
          align: 'start',
          sortable: true,
          value: 'nome',
        },
        { text: 'Total', value: 'total' },
        { text: 'Iniciativa Empresa', value: 'iniciativaEmpresa' },
        { text: 'Iniciativa Profissional', value: 'iniciativaProfissional' },
        { text: 'Estágio', value: 'estagio' },
        { text: 'CLT', value: 'clt' },
        { text: 'EPS', value: 'eps' },
      ],
      desserts: this.dadosDesligamento,
    };
  },
};
</script>
<style lang="scss" scoped>
.card-indicador {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 1233px;
  border-radius: 20px !important;
}
</style>
