<template>
  <div>
      <v-row class="indicadores">
        <h1 class="my-5 ml-6 font-weight-bold body-0">Sumário Executivo da Área:</h1>
        <!-- Quantidade de atendimentos ABERTO(S), ENCERRADO(S) e REABERTO(S) durante o ano -->
        <v-col lg="12">
          <IndicadorAtendimentosPorAno
            v-if="exibeIndicadorAtendimentosPorAno"
            :mesesTratados="mesesTratados"
            :valores="abertosEncerradosReabertos"
            :key="updateAtendimentosPorAno"
            :loaderAtendimentosPorAno="loaderAtendimentosPorAno"
          />
        </v-col>
        <!-- Quantidade de atendimentos ATRIBUIDO(S) por atendente durante o ano -->
        <v-col lg="12">
          <IndicadorAtendimentosPorAtendentePorAno
            v-if="exibeIndicadorAtendimentosAntendentePorAno"
            :valores="atendimentosPorAtendentePorAno"
            :mesesTratados="mesesTratadosAno"
            :key="updateAtendimentosAtendentePorAno"
            :loaderAtendimentosPorAtendentePorAno="loaderAtendimentosPorAtendentePorAno"
          />
        </v-col>
        <!-- Média(%) de SATISTAÇÃO por Atendente durante o ano -->
        <v-col lg="12">
          <IndicadorSatisfacaoAtendentePorAno
            v-if="exibePorcentagemAtendimentosAntendenteAno"
            :mesesTratados="mesesTratadosAno"
            :valores="atendimentosPorcentagemAtendenteAno"
            :key="updateAtendimentosPorcentagemAtendenteAno"
            :loaderAtendimentosPorcentagemAtendenteAno="loaderAtendimentosPorcentagemAtendenteAno"
          />
        </v-col>
        <!-- Quantidade de atendimentos ABERTOS(S) e ENCERRADO(S) por dia  -->
        <v-col lg="12">
          <IndicadorAbertosEncerradosPorDia
            v-if="exibeIndicadorAbertosEncerradosPorDia"
            :valores="atendimentosAbertosEncerradosPorDia"
            :dias="diasTratadosAno"
            :key="updateAbertosEncerradosPorDia"
            :loaderAbertosEncerradosPorDia="loaderAbertosEncerradosPorDia"
          />
        </v-col>
        <!-- Tempo médio de atendimento -->
        <v-row>
          <v-col lg="6">
            <IndicadorTempoMedio
              v-if="exibeIndicadorPorStatusTempoMedio "
              :tempoMedioGeral="tempoMedioAtendimentoGeral"
              :dadosAtendimentoPorStatus="dadosAtendimentoPorStatus"
              :categorias="statusOrdenadosTempoMedio"
              :key="updateAtendimentosPorStatus"
              :loader="loader"
            />
          </v-col>
          <!-- Quantidade de atendimentos ABERTO(S) x ENCERRADO(S) x REABERTO(S) -->
          <v-col lg="6">
            <IndicadorAbertoFechadoReaberto
              v-if="exibeIndicadorAbertoFechadoReaberto"
              :valores="abertoFechadoReaberto"
              :labels="statusLabelAtendimentos"
              :key="updateAtendimentosAbertosFechadosReabertos"
              :loaderAbertoFechadoReaberto="loaderAbertoFechadoReaberto"
            />
          </v-col>
        </v-row>
        <!-- Satisfação de Atendimentos REABERTO(S) -->
        <v-row>
          <v-col lg="6">
            <IndicadorDeSatisfacaoDeAtendimentosReabertos
              v-if="exibeIndicadorPorStatus"
              :valores="satisfacaoAtendimentos"
              :categorias="categoriasSatisfacao"
              :quantidade="quantidadeAvaliacoes"
              :key="updateAtendimentosReabertosSatisfacao"
              :loader="loaderSatisfacaoReabertos"
            />
          </v-col>
          <!-- Indicador de avaliação -->
          <v-col lg="6">
            <IndicadorAvaliacao
              v-if="exibeIndicadorPorAvaliacao"
              :dadosAvaliacao="dadosAvaliacao"
              :loader="loaderAvaliacao"
            />
          </v-col>
        </v-row>
      </v-row>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import IndicadorAtendimentosPorAno from './IndicadorAtendimentosPorAno';
import IndicadorAtendimentosPorAtendentePorAno from './IndicadorAtendimentosPorAtendentePorAno';
import IndicadorSatisfacaoAtendentePorAno from './IndicadorSatisfacaoAtendentePorAno';
import IndicadorAbertosEncerradosPorDia from './IndicadorAbertosEncerradosPorDia';
import IndicadorTempoMedio from './IndicadorTempoMedioAtendimento.vue';
import IndicadorAbertoFechadoReaberto from './IndicadorAbertoFechadoReaberto.vue';
import IndicadorDeSatisfacaoDeAtendimentosReabertos from './IndicadorDeSatisfacaoDeAtendimentosReabertos.vue';
import IndicadorAvaliacao from './IndicadorAvaliacao.vue';

export default {
  name: 'IndicadoresDiretoria',
  components: {
    IndicadorAtendimentosPorAno,
    IndicadorAtendimentosPorAtendentePorAno,
    IndicadorSatisfacaoAtendentePorAno,
    IndicadorAbertosEncerradosPorDia,
    IndicadorTempoMedio,
    IndicadorAbertoFechadoReaberto,
    IndicadorDeSatisfacaoDeAtendimentosReabertos,
    IndicadorAvaliacao,
  },

  props: {
    dadosFiltro: VueTypes.object.isRequired,
  },
  watch: {
    dadosFiltro(val) {
      this.indicadorAtendimentosPorAno(val);
      this.indicadorAtendimentosPorAtendentePorAno(val);
      this.indicadorPorcentagemAtendimentosPorAtendenteAno(val);
      this.indicadorAbertosEncerradosPorDia(val);
      this.indicadorTempoMediaAtendimento(val);
      this.indicadorAbertoFechadoReaberto(val);
      this.indicadorDeSatisfacaoDeAtendimentosReabertos(val);
      this.indicadorPorAvaliacao(val);
    },
  },

  data() {
    return {
      // Quantidade de atendimentos ABERTO(S), ENCERRADO(S) e REABERTO(S) durante o ano
      loaderAtendimentosPorAno: false,
      exibeIndicadorAtendimentosPorAno: false,
      mesesTratados: [],
      abertosEncerradosReabertos: [],
      updateAtendimentosPorAno: 0,

      // Quantidade de atendimentos ATRIBUIDO(S) por atendente durante o ano
      exibeIndicadorAtendimentosAntendentePorAno: false,
      loaderAtendimentosPorAtendentePorAno: false,
      mesesTratadosAno: [],
      updateAtendimentosAtendentePorAno: 0,
      atendimentosPorAtendentePorAno: [],

      // Média(%) de SATISTAÇÃO por Atendente durante o ano
      exibePorcentagemAtendimentosAntendenteAno: false,
      atendimentosPorcentagemAtendenteAno: [],
      updateAtendimentosPorcentagemAtendenteAno: 0,
      loaderAtendimentosPorcentagemAtendenteAno: false,

      // Quantidade de atendimentos ABERTOS(S) e ENCERRADO(S) por dia
      exibeIndicadorAbertosEncerradosPorDia: false,
      atendimentosAbertosEncerradosPorDia: [],
      diasTratadosAno: [],
      updateAbertosEncerradosPorDia: 0,
      loaderAbertosEncerradosPorDia: false,

      // Tempo médio de atendimento
      exibeIndicadorPorStatusTempoMedio: false,
      tempoMedioAtendimentoGeral: '',
      dadosAtendimentoPorStatus: '',
      statusOrdenadosTempoMedio: '',
      updateAtendimentosPorStatus: 0,
      loader: false,

      // Quantidade de atendimentos ABERTO(S) x ENCERRADO(S) x REABERTO(S)
      exibeIndicadorAbertoFechadoReaberto: false,
      abertoFechadoReaberto: [],
      statusLabelAtendimentos: [],
      updateAtendimentosAbertosFechadosReabertos: 0,
      loaderAbertoFechadoReaberto: false,

      // Satisfação de Atendimentos REABERTO(S)
      exibeIndicadorPorStatus: false,
      satisfacaoAtendimentos: '',
      categoriasSatisfacao: [],
      quantidadeAvaliacoes: '',
      updateAtendimentosReabertosSatisfacao: 0,
      loaderSatisfacaoReabertos: false,

      // Indicador de avaliação
      exibeIndicadorPorAvaliacao: false,
      dadosAvaliacao: '',
      loaderAvaliacao: false,
    };
  },

  methods: {
    async indicadorAtendimentosPorAno(dadosFiltro) {
      try {
        this.loaderAtendimentosPorAno = true;

        const { data: response } = await this.$http.post('/indicadores/atendimentos-por-mes', dadosFiltro);

        this.mesesTratados = response.mesesTratados;

        this.abertosEncerradosReabertos = [response.abertos,
          response.encerrados, response.reabertos];

        this.exibeIndicadorAtendimentosPorAno = true;
        this.updateAtendimentosPorAno += 1;
        this.loaderAtendimentosPorAno = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorAtendimentosPorAtendentePorAno(dadosFiltro) {
      try {
        this.loaderAtendimentosPorAtendentePorAno = true;

        const { data: response } = await this.$http.post('/indicadores/atendimentos-atribuidos-por-atendente', dadosFiltro);
        this.atendimentosPorAtendentePorAno = response.valores;

        this.mesesTratadosAno = response.mesesTratados;

        this.exibeIndicadorAtendimentosAntendentePorAno = true;
        this.updateAtendimentosAtendentePorAno += 1;
        this.loaderAtendimentosPorAtendentePorAno = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorPorcentagemAtendimentosPorAtendenteAno(dadosFiltro) {
      try {
        this.loaderAtendimentosPorcentagemAtendenteAno = true;
        const { data: response } = await this.$http.post('/indicadores/satisfacao-por-mes', dadosFiltro);

        this.mesesTratadosAno = response.mesesTratados;
        this.atendimentosPorcentagemAtendenteAno = response.valores;

        this.exibePorcentagemAtendimentosAntendenteAno = true;
        this.updateAtendimentosPorcentagemAtendenteAno += 1;
        this.loaderAtendimentosPorcentagemAtendenteAno = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorAbertosEncerradosPorDia(dadosFiltro) {
      try {
        this.loaderAbertosEncerradosPorDia = true;

        const { data: response } = await this.$http.post('/indicadores/abertos-encerrados-por-dia', dadosFiltro);

        this.atendimentosAbertosEncerradosPorDia = response.valores;
        this.diasTratadosAno = response.days;

        this.exibeIndicadorAbertosEncerradosPorDia = true;
        this.updateAbertosEncerradosPorDia += 1;
        this.loaderAbertosEncerradosPorDia = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorTempoMediaAtendimento(dadosFiltro) {
      try {
        this.loader = true;
        const { data: response } = await this.$http.post('/indicadores/tempo-medio-por-status', dadosFiltro);

        this.statusOrdenadosTempoMedio = [
          'Pendente',
          'Em análise',
          'Em atendimento',
          'Aguardando usuário',
          'Aguardando atendente',
        ];

        this.dadosAtendimentoPorStatus = response.medias_status;
        this.tempoMedioAtendimentoGeral = response.media_geral;

        this.exibeIndicadorPorStatusTempoMedio = true;
        this.updateAtendimentosPorStatus += 1;
        this.loader = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorAbertoFechadoReaberto(dadosFiltro) {
      try {
        this.loaderAbertoFechadoReaberto = true;
        const { data: response } = await this.$http.post('/indicadores/atendimentos-abertos-fechado-reaberto', dadosFiltro);

        this.statusLabelAtendimentos = ['Aberto(s)', 'Fechado(s)', 'Reabertos(s)'];

        this.abertoFechadoReaberto = [response.atendimentosAbertos,
          response.atendimentosFechados, response.atendimentosReabertos];

        this.exibeIndicadorAbertoFechadoReaberto = true;
        this.updateAtendimentosAbertosFechadosReabertos += 1;
        this.loaderAbertoFechadoReaberto = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorDeSatisfacaoDeAtendimentosReabertos(dadosFiltro) {
      try {
        this.loaderSatisfacaoReabertos = true;
        const { data: response } = await this.$http.post('/indicadores/satisfacao-atendimentos-reabertos', dadosFiltro);

        const valoresPrimeiro = [
          response.primeiro[0], response.primeiro[1], response.primeiro[2], response.primeiro[3]];
        const valoresReaberto = [
          response.reaberto[0], response.reaberto[1], response.reaberto[2], response.reaberto[3]];

        const valores = { primeiro: valoresPrimeiro, reaberto: valoresReaberto };
        const labels = response.nomes;

        this.quantidadeAvaliacoes = {
          primeiro: response.primeiro[0] + response.primeiro[1]
            + response.primeiro[2] + response.primeiro[3],
          reaberto: response.reaberto[0] + response.reaberto[1]
            + response.reaberto[2] + response.reaberto[3],
        };
        this.satisfacaoAtendimentos = valores;
        this.categoriasSatisfacao = labels;
        this.exibeIndicadorPorStatus = true;
        this.updateAtendimentosReabertosSatisfacao += 1;
        this.loaderSatisfacaoReabertos = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorPorAvaliacao(dadosFiltro) {
      try {
        this.loaderAvaliacao = true;
        const { data: response } = await this.$http.post('/indicadores/indicador-por-avaliacao', dadosFiltro);
        this.dadosAvaliacao = response;
        this.exibeIndicadorPorAvaliacao = true;
        this.loaderAvaliacao = false;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.indicadores {
  display: flex;
  justify-content: center;
  max-width: 1250px;
}
</style>
