<template>
  <v-app>
    <Loader
      v-if="isLoading"
      :size="80"
    />
    <router-view />
  </v-app>
</template>

<script>
import Loader from './components/Loader.vue';

export default {
  name: 'App',
  components: {
    Loader,
  },
  computed:
  {
    isLoading() {
      return this.$store.getters.isLoader;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
}
.md-menu-content.md-select-menu {
  background: white;
}
.md-field.md-has-password .md-toggle-password {
  margin: 0;
  position: absolute;
  right: 10px !important;
  bottom: 0 !important;
  top: 8px !important;
}
.swal2-container.swal2-center {
  background: rgba($color: #000000, $alpha: 0.5);
}
.swal2-show {
  padding: 50px;
}
html .v-application--wrap {
  width: 100%;
  display: block;
  min-height: unset;
}
</style>
