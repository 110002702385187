<template>
  <div class="page-error">
    <img
      :src="Unsupported"
      alt="Navegador não suportado"
    >
    <p>Navegador não suportado.</p>
    <Botao
      label="Baixar Google Chrome"
      :callback="donwload"
    />
  </div>
</template>

<script>
import Botao from '../components/Botao.vue';
import Unsupported from '../assets/img/unsupported.svg';

export default {
  name: 'UnsupportedBrowser',
  components: {
    Botao,
  },
  data() {
    return {
      Unsupported,
    };
  },
  methods: {
    donwload() {
      window.open('https://www.google.com/intl/pt-BR/chrome/', '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.page-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
}
</style>
