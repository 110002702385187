export default function gestao({ next, store }) {
  const { permissao } = store.getters.isUsuario;

  const permissaoVisualizarAtendimentos = permissao.filter(element => element.id_permissao
    === store.getters.listaPermissoes.gestorgeral
    || element.id_permissao === store.getters.listaPermissoes.gestor
    || element.id_permissao === store.getters.listaPermissoes.businesspartner).length;
  if (permissaoVisualizarAtendimentos) {
    return next();
  }

  const pagina = window.location.pathname;
  return next({
    name: 'login',
    params: { fromPagina: pagina },
  });
}
