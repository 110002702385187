<template>
  <div id="FormModificarCliente">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="empresa"
            label="Empresa"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
      </v-row>

      <v-row>
        <v-col cols="10">
          <v-text-field
            v-model="codigoCliente"
            label="Código Cliente"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="endereco"
            label="Endereço"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="3" v-if="cep || !editar">
          <v-text-field
            v-mask="'#####-###'"
            v-model="cep"
            label="CEP"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="3" v-if="zipCode || !editar">
          <v-text-field
            v-model="zipCode"
            label="ZIPCODE"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="cidade"
            label="Cidade"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="estado"
            label="Estado"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="pais"
            label="País"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="telefone1"
            label="Telefone Principal"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="3" v-if="telefone2 || !editar">
          <v-text-field
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="telefone2"
            label="Telefone Opcional"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="3" v-if="telefone3 || !editar">
          <v-text-field
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="telefone3"
            label="Telefone Opcional"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="emailGeral"
            label="Email Geral"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="emailFaturamento0"
            label="Email para Faturamento"
            outlined
            class="mb-2"
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="emailFaturamento1 || !editar">
        <v-col cols="5">
          <v-text-field
            v-model="emailFaturamento1"
            label="Email para Faturamento"
            outlined
            class="mb-2"
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="emailFaturamento2 || !editar">
        <v-col cols="5">
          <v-text-field
            v-model="emailFaturamento2"
            label="Email para Faturamento"
            outlined
            class="mb-2"
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="emailFaturamento3 || !editar">
        <v-col cols="5">
          <v-text-field
            v-model="emailFaturamento3"
            label="Email para Faturamento"
            outlined
            class="mb-2"
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="emailFaturamento4 || !editar">
        <v-col cols="5">
          <v-text-field
            v-model="emailFaturamento4"
            label="Email para Faturamento"
            outlined
            class="mb-2"
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="7">
          <v-text-field
            v-model="centroLucro0"
            :items="itemsCentrosLucros"
            label="Centro de Lucro"
            class="mb-2"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="centroLucro1 || !editar">
        <v-col cols="7">
          <v-text-field
            v-model="centroLucro1"
            label="Centro de Lucro"
            class="mb-2"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="centroLucro2 || !editar">
        <v-col cols="7">
          <v-text-field
            v-model="centroLucro2"
            label="Centro de Lucro"
            class="mb-2"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-btn
        :class="{ 'btn-primary-disabled ml-0': isStatusEncerrado,
                   'btn-primary ml-0': !isStatusEncerrado }"
        @click="toggleEdit"
        :disabled="isStatusEncerrado">
        <span v-if="editar">
          <v-icon left>mdi-pencil</v-icon>Editar
        </span>
        <span v-else>
          <v-icon left>mdi-close</v-icon>Cancelar
        </span>
      </v-btn>

      <v-btn
        v-if="!editar"
        class="btn-primary ml-2"
        @click="salvarFluxoEditado"
      >
        <v-icon left>mdi-content-save</v-icon>Salvar
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { mask } from 'vue-the-mask';
import Snackbar from '../Snackbar';

export default {
  name: 'FormModificarCliente',
  components: { Snackbar },
  directives: { mask },

  props: {
    camposFluxo: VueTypes.array,
    fluxoId: VueTypes.integer,
    atendimentoId: VueTypes.integer,
    statusAtendimento: VueTypes.string,
  },

  data() {
    return {
      empresa: '',

      codigoCliente: '',
      endereco: '',

      cep: '',

      zipCode: '',

      cidade: '',

      estado: '',

      pais: '',

      telefone1: '',

      telefone2: '',

      telefone3: '',

      emailGeral: '',

      emailFaturamento0: '',
      emailFaturamento1: '',
      emailFaturamento2: '',
      emailFaturamento3: '',
      emailFaturamento4: '',

      centroLucro0: '',
      centroLucro1: '',
      centroLucro2: '',

      editar: true,
      disabled: true,
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },
  created() {
    this.loadInfoCamposFluxo();
  },

  computed: {
    isStatusEncerrado() {
      return this.statusAtendimento === 'Encerrado';
    },

    verificaCampos() {
      return (this.empresa
      && this.codigoCliente);
    },
  },

  methods: {
    loadInfoCamposFluxo() {
      this.empresa = this.camposFluxo[0].resposta;
      this.codigoCliente = this.camposFluxo[1].resposta;
      this.endereco = this.camposFluxo[2].resposta;
      this.cep = this.camposFluxo[3].resposta;
      this.zipCode = this.camposFluxo[4].resposta;
      this.cidade = this.camposFluxo[5].resposta;
      this.estado = this.camposFluxo[6].resposta;
      this.pais = this.camposFluxo[7].resposta;
      this.telefone1 = this.camposFluxo[8].resposta;
      this.telefone2 = this.camposFluxo[9].resposta;
      this.telefone3 = this.camposFluxo[10].resposta;
      this.emailGeral = this.camposFluxo[11].resposta;
      this.emailFaturamento0 = this.camposFluxo[12].resposta;
      this.emailFaturamento1 = this.camposFluxo[13].resposta;
      this.emailFaturamento2 = this.camposFluxo[14].resposta;
      this.emailFaturamento3 = this.camposFluxo[15].resposta;
      this.emailFaturamento4 = this.camposFluxo[16].resposta;
      this.centroLucro0 = this.camposFluxo[17].resposta;
      this.centroLucro1 = this.camposFluxo[18].resposta;
      this.centroLucro2 = this.camposFluxo[19].resposta;
    },

    toggleEdit() {
      this.editar = !this.editar;
      this.disabled = !this.disabled;
    },

    async salvarFluxoEditado() {
      if (!this.verificaCampos) {
        this.dadosFluxo = { validation: false };
        this.alerta = 'error';
        this.msg = 'Todos os campos com * são obrigatórios.';
        this.snackBarAlertaCampos = true;
      } else {
        this.toggleEdit();

        this.alerta = 'success';
        this.msg = 'Formulário editado com sucesso!';
        this.snackBarAlertaCampos = true;

        const body = {
          id: this.atendimentoId,
          campos_fluxo: {
            column_campo_1: this.empresa,
            column_campo_2: this.codigoCliente,
            column_campo_3: this.endereco,
            column_campo_4: this.cep,
            column_campo_5: this.zipCode,
            column_campo_6: this.cidade,
            column_campo_7: this.estado,
            column_campo_8: this.pais,
            column_campo_9: this.telefone1,
            column_campo_10: this.telefone2,
            column_campo_11: this.telefone3,
            column_campo_12: this.emailGeral,
            column_campo_13: this.emailFaturamento0,
            column_campo_14: this.emailFaturamento1,
            column_campo_15: this.emailFaturamento2,
            column_campo_16: this.emailFaturamento3,
            column_campo_17: this.emailFaturamento4,
            column_campo_18: this.centroLucro0,
            column_campo_19: this.centroLucro1,
            column_campo_20: this.centroLucro2,
          },
          responsavel: this.$store.getters.isUsuario.id,
          fluxo_id: this.fluxoId,
        };

        await this.$http.put('/atendimentos/editar/dados-fluxo', body);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

#FormModificarCliente {
  margin-left: 12px;
}

.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.btn-primary {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border: 1px solid #0056a8 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.btn-primary-disabled {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.count-warning {
  background-color: transparent;
  color: red;
}
</style>
