<template>
  <div id="ModificarFornecedor">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
      <h3 class="titleHeader mb-3">Fluxo Modificar Fornecedor</h3>
      <v-row>
        <v-col>
          <h5>Atenção</h5>
          <p class="caption">Os campos com
            <span class="count-warning">*</span>
            são obrigatórios.</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-autocomplete
            v-model="empresa"
            :items="empresas"
            label="Empresa"
            class="alert-fild-required"
            outlined
            dense
          >
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="8">
          <v-autocomplete
            v-model="codigoFornecedor"
            :items="codigosFornecedores"
            label="Código Fornecedor"
            class="alert-fild-required"
            outlined
            dense
          >
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="endereco"
            label="Endereço"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-mask="'#####-###'"
            v-model="cep"
            label="CEP"
            outlined
            dense
            desa
            :disabled="zipCode.length >= 1"
          ></v-text-field>
        </v-col>
        <span style="margin-top: 20px;">OU</span>
        <v-col cols="2">
          <v-text-field
            v-model="zipCode"
            label="ZIPCODE"
            outlined
            dense
            :disabled="cep.length >= 1"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="cidade"
            label="Cidade"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="estado"
            label="Estado"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            v-model="pais"
            label="País"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-text-field
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="telefone1"
            label="Telefone Principal"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="telefone2"
            label="Telefone Opcional"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="telefone3"
            label="Telefone Opcional"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="emailGeral"
            label="Email Geral"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field
            v-model="emailPedido"
            label="Email para envio pedido"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-select
            v-model="formaPagamento"
            :items="formasPagamento"
            label="Forma de pagamento"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <span v-if="formaPagamento === 'Crédito em conta'">Obrigatório ANEXAR
          comprovante de dados bancários
        </span>
      </v-row>

      <v-row>
        <v-col>
          <v-icon>mdi-alert</v-icon>
          <p class="caption"><strong>Atenção: </strong>Informações adicionais,
          utilizar o campo de <strong>mensagem.</strong></p>
        </v-col>
      </v-row>
    </v-form>
  </div>

</template>

<script>
import { mask } from 'vue-the-mask';
import Snackbar from '../Snackbar';

export default {
  name: 'ModificarFornecedor',

  components: { Snackbar },

  directives: { mask },

  data() {
    return {
      empresa: '',
      empresas: [
        'Meta',
        'Netrin',
        'Meta Ventures Part Ltda',
        '91M Cons. Gestão Ltda',
        'MGSA Consultoria Ltda',
        'WORBI Gestão Ltda',
        'WTCPAR Participações Ltda',
      ],

      codigoFornecedor: '',
      codigosFornecedores: ['Carregando...'],

      endereco: '',

      cep: '',

      zipCode: '',

      cidade: '',

      estado: '',

      pais: '',

      telefone1: '',
      telefone2: '',
      telefone3: '',

      emailPedido: '',

      emailGeral: '',

      formaPagamento: '',
      formasPagamento: ['Boleto', 'Crédito em conta', 'Cartão de Crédito'],

      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },

  created() {
    this.getCodigosFornecedores();
  },

  computed: {
    verificaCampos() {
      return (!!this.empresa
      && !!this.codigoFornecedor);
    },
  },

  methods: {
    async getCodigosFornecedores() {
      try {
        const { data: response } = await this.$http.get('/data-from-sap/fornecedor');
        this.codigosFornecedores = response.map(item => item.descricaoItens);
      } catch (error) {
        console.error(error);
      }
    },

    trataResposta(dadosFluxo) {
      const respostasModel = {};

      if (dadosFluxo.empresa) respostasModel.column_campo_1 = dadosFluxo.empresa;
      if (dadosFluxo.codigoFornecedor) respostasModel.column_campo_2 = dadosFluxo.codigoFornecedor;
      if (dadosFluxo.endereco) respostasModel.column_campo_3 = dadosFluxo.endereco;
      if (dadosFluxo.cep) respostasModel.column_campo_4 = dadosFluxo.cep;
      if (dadosFluxo.zipCode) respostasModel.column_campo_5 = dadosFluxo.zipCode;
      if (dadosFluxo.cidade) respostasModel.column_campo_6 = dadosFluxo.cidade;
      if (dadosFluxo.estado) respostasModel.column_campo_7 = dadosFluxo.estado;
      if (dadosFluxo.pais) respostasModel.column_campo_8 = dadosFluxo.pais;
      if (dadosFluxo.telefone1) respostasModel.column_campo_9 = dadosFluxo.telefone1;
      if (dadosFluxo.telefone2) respostasModel.column_campo_10 = dadosFluxo.telefone2;
      if (dadosFluxo.telefone3) respostasModel.column_campo_11 = dadosFluxo.telefone3;
      if (dadosFluxo.emailPedido) respostasModel.column_campo_12 = dadosFluxo.emailPedido;
      if (dadosFluxo.emailGeral) respostasModel.column_campo_13 = dadosFluxo.emailGeral;
      if (dadosFluxo.formaPagamento) respostasModel.column_campo_14 = dadosFluxo.formaPagamento;

      respostasModel.validation = true;
      this.newDadosFluxo = respostasModel;
    },

    enviaJSON() {
      if (!this.verificaCampos) {
        this.dadosFluxo = { validation: false };
        this.alerta = 'error';
        this.msg = 'Todos os campos com * são obrigatórios.';
        this.snackBarAlertaCampos = true;
      } else {
        this.dadosFluxo = {
          validation: true,
          empresa: this.empresa,
          codigoFornecedor: this.codigoFornecedor,
          endereco: this.endereco,
          cep: this.cep,
          zipCode: this.zipCode,
          cidade: this.cidade,
          estado: this.estado,
          pais: this.pais,
          telefone1: this.telefone1,
          telefone2: this.telefone2,
          telefone3: this.telefone3,
          emailPedido: this.emailPedido,
          emailGeral: this.emailGeral,
          formaPagamento: this.formaPagamento,
        };
        this.trataResposta(this.dadosFluxo);
        this.$emit('dadosFluxo', this.newDadosFluxo);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

.alert-fild-required::after {
    content: "*";
    color: red;
    margin-bottom: 10px;
    margin-left: 6px;
    font-size: x-small;
}
.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.count-warning {
  background-color: transparent;
  color: red;
}
</style>
