<template>
  <span>
    <!------- TIPO = TEXT ------->
    <!-- text -->
    <div
    v-if="camposFluxo.id_tipo_campos == tipoText && !camposFluxo.id_tipo_mascara
      && !camposFluxo.min_caracter && camposFluxo.resposta !== null">
      <v-col cols="5">
        <v-text-field
          v-model="camposFluxo.resposta"
          :label="camposFluxo.descricao"
          @change="$emit('emit-answer',
            { resposta: camposFluxo.resposta,
              column_campo: camposFluxo.column_campo
            })"
          outlined
          dense
          auto-grow
          :disabled="isDisabled"
        ></v-text-field>
      </v-col>
    </div>
    <!-- text && mascaras GERAIS-->
    <div
    v-if="camposFluxo.id_tipo_campos == tipoText && camposFluxo.id_tipo_mascara
      && camposFluxo.id_tipo_mascara !== mascaraDinheiroRealID
      && camposFluxo.id_tipo_mascara !== mascaraDinheiroDolarID
      && camposFluxo.id_tipo_mascara !== mascaraDinheiroEuroID
      && camposFluxo.resposta !== null">
      <v-col cols="5">
        <v-text-field
          v-model="camposFluxo.resposta"
          v-mask="populaMascara"
          :label="camposFluxo.descricao"
          @change="$emit('emit-answer',
            { resposta: camposFluxo.resposta,
              column_campo: camposFluxo.column_campo
            })"
          outlined
          dense
          auto-grow
          :disabled="isDisabled"
        ></v-text-field>
      </v-col>
    </div>
    <!-- text && mascara DE DINHEIRO Real -->
    <div
    v-if="camposFluxo.id_tipo_campos == tipoText && camposFluxo.id_tipo_mascara
      && camposFluxo.id_tipo_mascara == mascaraDinheiroRealID && camposFluxo.resposta !== null">
      <v-col cols="5">
        <v-text-field
          v-money="real"
          v-model.lazy="camposFluxo.resposta"
          :label="camposFluxo.descricao"
          @change="$emit('emit-answer',
            { resposta: camposFluxo.resposta,
              column_campo: camposFluxo.column_campo
            })"
          outlined
          dense
          auto-grow
          :disabled="isDisabled"
        ></v-text-field>
      </v-col>
    </div>
    <!-- text && mascara DE DINHEIRO Dolar -->
    <div
    v-if="camposFluxo.id_tipo_campos == tipoText && camposFluxo.id_tipo_mascara
      && camposFluxo.id_tipo_mascara == mascaraDinheiroDolarID && camposFluxo.resposta !== null">
      <v-col cols="5">
        <v-text-field
          v-money="dolar"
          v-model.lazy="camposFluxo.resposta"
          :label="camposFluxo.descricao"
          @change="$emit('emit-answer',
            { resposta: camposFluxo.resposta,
              column_campo: camposFluxo.column_campo
            })"
          outlined
          dense
          auto-grow
          :disabled="isDisabled"
        ></v-text-field>
      </v-col>
    </div>
    <!-- text && mascara DE DINHEIRO Euro -->
    <div
    v-if="camposFluxo.id_tipo_campos == tipoText && camposFluxo.id_tipo_mascara
      && camposFluxo.id_tipo_mascara == mascaraDinheiroEuroID && camposFluxo.resposta !== null">
      <v-col cols="5">
        <v-text-field
          v-money="euro"
          v-model.lazy="camposFluxo.resposta"
          :label="camposFluxo.descricao"
          @change="$emit('emit-answer',
            { resposta: camposFluxo.resposta,
              column_campo: camposFluxo.column_campo
            })"
          outlined
          dense
          auto-grow
          :disabled="isDisabled"
        ></v-text-field>
      </v-col>
    </div>
    <!-- text && minCaracteres -->
    <div
    v-if="camposFluxo.id_tipo_campos == tipoText && camposFluxo.min_caracter
      && camposFluxo.resposta !== null">
      <v-col cols="5">
        <v-text-field
          v-model="camposFluxo.resposta"
          :label="camposFluxo.descricao"
          @change="$emit('emit-answer',
            { resposta: camposFluxo.resposta,
              column_campo: camposFluxo.column_campo
            })"
          outlined
          dense
          auto-grow
          :disabled="isDisabled"
        ></v-text-field>
        <p
          class="caption text-right"
          v-if="camposFluxo.id_tipo_campos == tipoText && camposFluxo.min_caracter
             && camposFluxo.resposta !== null && !isDisabled"
          >
            Mínimo {{ camposFluxo.min_caracter }} caracteres
        </p>
      </v-col>
    </div>


    <!------- TIPO = NUMBER ------->
    <!-- number -->
    <div
    v-if="camposFluxo.id_tipo_campos == tipoNumber && camposFluxo.resposta !== null">
      <v-col cols="5">
        <v-text-field
          v-model="camposFluxo.resposta"
          :label="camposFluxo.descricao"
          @change="$emit('emit-answer',
            { resposta: camposFluxo.resposta,
              column_campo: camposFluxo.column_campo
            })"
          type="number"
          outlined
          dense
          auto-grow
          :disabled="isDisabled"
        ></v-text-field>
      </v-col>
    </div>


    <!------- TIPO = CHECKBOX------->
    <!-- checkbox -->
    <div
    v-if="camposFluxo.id_tipo_campos == tipoCheckBox && camposFluxo.resposta !== null">
      <v-col cols="5">
        <v-checkbox
          v-model="camposFluxo.resposta"
          :label="camposFluxo.descricao"
          :disabled="isDisabled"
          @change="$emit('emit-answer',
            { resposta: camposFluxo.resposta,
              column_campo: camposFluxo.column_campo
            })"
        ></v-checkbox>
      </v-col>
    </div>


    <!------- TIPO = RADIOBUTTON------->
    <!-- radioButton -->
    <div
    v-if="camposFluxo.id_tipo_campos == tipoRadioButton && camposFluxo.resposta !== null">
      <v-col cols="5">
        <v-radio-group
          row
          v-model="camposFluxo.resposta"
          :label="camposFluxo.descricao"
          @change="$emit('emit-answer',
            { resposta: camposFluxo.resposta,
              column_campo: camposFluxo.column_campo
            })"
          :disabled="isDisabled"
        >
          <v-radio
            v-for="(opcao, index) in opcoes" :key="index"
            :label="opcao.text"
            :value="opcao.text"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </div>


    <!------- TIPO = LONGTEXT ------->
    <!-- longText -->
    <div
    v-if="camposFluxo.id_tipo_campos == tipoLongText && !camposFluxo.min_caracter
     && camposFluxo.resposta !== null">
      <v-col cols="5">
        <v-textarea
          v-model="camposFluxo.resposta"
          :label="camposFluxo.descricao"
          @change="$emit('emit-answer',
            { resposta: camposFluxo.resposta,
              column_campo: camposFluxo.column_campo
            })"
          outlined
          dense
          auto-grow
          :disabled="isDisabled"
        ></v-textarea>
      </v-col>
    </div>
    <!-- longText && minCaracteres -->
    <div
    v-if="camposFluxo.id_tipo_campos == tipoLongText && camposFluxo.min_caracter
     && camposFluxo.resposta !== null">
      <v-col cols="5">
        <v-textarea
          v-model="camposFluxo.resposta"
          :label="camposFluxo.descricao"
          @change="$emit('emit-answer',
            { resposta: camposFluxo.resposta,
              column_campo: camposFluxo.column_campo
            })"
          outlined
          dense
          auto-grow
          :disabled="isDisabled"
        ></v-textarea>
        <p
          class="caption text-right"
          v-if="camposFluxo.id_tipo_campos == tipoLongText && camposFluxo.min_caracter
              && camposFluxo.resposta !== null && !isDisabled"
          >
            Mínimo {{ camposFluxo.min_caracter }} caracteres
        </p>
      </v-col>
    </div>


    <!------- TIPO = INPUTSELECT ------->
    <!-- inputSelect -->
    <div
    v-if="camposFluxo.id_tipo_campos == tipoInputSelect
     && camposFluxo.resposta !== null">
      <v-col cols="5">
        <v-select
          v-model="camposFluxo.resposta"
          :label="camposFluxo.descricao"
          @change="$emit('emit-answer',
            { resposta: camposFluxo.resposta,
              column_campo: camposFluxo.column_campo
            })"
          :items="opcoes"
          outlined
          dense
          auto-grow
          :disabled="isDisabled"
        ></v-select>
      </v-col>
    </div>


    <!------- TIPO = DATA ------->
    <!-- data -->
    <div
    v-if="camposFluxo.id_tipo_campos == tipoData && camposFluxo.resposta !== null">
      <v-col cols="5">
        <v-menu
          offset-y
          :close-on-content-click="false"
          :nudge-right="40"
          v-model="menuData"
          transition="scale-transition"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              readonly
              v-model="camposFluxo.resposta"
              :label="camposFluxo.descricao"
              append-icon="event"
              v-bind="attrs"
              v-on="on"
              :disabled="isDisabled"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title
            v-model="dataFormatted"
            @change="$emit('emit-answer',
              { resposta: camposFluxo.resposta,
                column_campo: camposFluxo.column_campo
              })"
            @input="menuData = false"
            locale="pt-br"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </div>


    <!------- TIPO = HORARIO ------->
    <!-- hora -->
    <div
    v-if="camposFluxo.id_tipo_campos == tipoHora
     && camposFluxo.resposta !== null">
      <v-col cols="2">
        <v-text-field
          v-model="camposFluxo.resposta"
          :label="camposFluxo.descricao"
          @change="$emit('emit-answer',
            { resposta: camposFluxo.resposta,
              column_campo: camposFluxo.column_campo
            })"
          type="time"
          outlined
          dense
          auto-grow
          :disabled="isDisabled"
        ></v-text-field>
      </v-col>
    </div>
  </span>
</template>

<script>
import VueTypes from 'vue-types';
import moment from 'moment';
import { VMoney } from 'v-money';
import { mask } from 'vue-the-mask';
import { tipoMascara } from '../utils/utilities';

export default {
  name: 'CamposResposta.vue',

  directives: { mask, money: VMoney },

  props: {
    camposFluxo: VueTypes.object,
    isDisabled: VueTypes.boolean,
  },

  data() {
    return {
      real: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
      dolar: {
        decimal: ',',
        thousands: '.',
        prefix: 'US$ ',
        precision: 2,
      },
      euro: {
        decimal: ',',
        thousands: '.',
        prefix: '€ ',
        precision: 2,
      },
      mascaraDinheiroRealID: 5, // Referencia tabela mascara - banco.
      mascaraDinheiroDolarID: 7,
      mascaraDinheiroEuroID: 8,
      resposta: '',
      opcoes: [],
      menuData: false,

      // Ids salvos no banco
      tipoText: 1,
      tipoNumber: 2,
      tipoCheckBox: 3,
      tipoRadioButton: 4,
      tipoInputSelect: 5,
      tipoLongText: 6,
      tipoData: 7,
      tipoHora: 8,
    };
  },

  computed: {
    populaMascara() {
      return tipoMascara(this.camposFluxo.id_tipo_mascara);
    },
    dataFormatted: {
      get() {
        return this.resposta ? moment(this.resposta).format('L') : '';
      },
      set(newValue) {
        this.camposFluxo.resposta = moment(newValue).format('L');
      },
    },
  },

  created() {
    moment.locale('pt-br');
  },

  mounted() {
    this.populaOpcoes();
  },

  methods: {
    populaOpcoes() {
      if (this.camposFluxo.tipo_opcoes) {
        if (this.camposFluxo.tipo_opcoes.length !== 0) {
          this.opcoes = this.camposFluxo.tipo_opcoes.map(item => ({
            value: item.tipo_opcoes,
            text: item.tipo_opcoes,
          }));
        }
      }
    },
  },
};
</script>
