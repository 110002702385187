<template>
  <div class="page-error">
    <img
      :src="Erro404"
      alt="erro 404"
    >
    <p>Página não encontrada.</p>
    <Botao
      label="Início"
      :callback="voltarInicio"
    />
  </div>
</template>

<script>
import Botao from '../components/Botao.vue';
import Erro404 from '../assets/img/error404.svg';

export default {
  name: 'PageNotFound',
  components: {
    Botao,
  },
  data() {
    return {
      Erro404,
    };
  },
  methods: {
    voltarInicio() {
      this.$router.push({ name: 'home' });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
}
</style>
