<template>
  <div class="principal">
    <Botao
    type="button"
    label="DOWNLOAD RELATÓRIO DESLIGAMENTO DP"
    tipo="primary"
    :callback="getRelatorioDesligamento"
  />
  </div>
</template>

<script>
import moment from 'moment';
import VueTypes from 'vue-types';
import XLSX from 'xlsx';
import Botao from './Botao.vue';
import { getMaxPermission } from '../utils/utilities';
import { setores } from '../Constants.js';
import EventBus from '../plugins/EventBus';

export default {
  name: 'RelatorioDesligamento',
  components: {
    Botao,
  },
  props: {
    dataCheckbox: VueTypes.boolean,
    statusCheckbox: VueTypes.boolean,
    statusSelected: VueTypes,
    dataInitialSelected: VueTypes,
    dataFinalSelected: VueTypes,
  },
  data() {
    return {
      json_fields: {
        setor_nome: 'setor_nome',
        id_atendimento: 'id_atendimento',
        categoria: 'categoria',
        subcategoria: 'subcategoria',
        nome: 'nome',
        descricao: 'descricao',
        nome_fluxo: 'nome_fluxo',
        atendente_nome: 'atendente_nome',
        atendente_usuario: 'atendente_usuario',
        status: 'status',
        sede: 'sede',
        data_criacao: 'data_criacao',
        data_edicao: 'data_edicao',
        avaliacao: 'avaliacao',
        reaberto: 'reaberto',
        data_encerrado: 'data_encerrado',
        dias_em_aberto: 'dias_em_aberto',
        valor_unitario: 'valor_unitario',
      },
      setor: '',
      listaSetores: {
        id: 'id',
        nome: 'nome',
      },
      relatorioDesligamento: [],
      relatorioGeral: [],
      relatorioPorSetor: [],
      dialog: false,
      menu1: false,
      menu2: false,
      dataInicial: '',
      statusEscolhido: '',
      statusList: [],
      dataFinal: '',
      dataHoje: new Date().toISOString().slice(0, 10),
      dataBusInicial: '',
      dataBusFinal: '',
      dialogDelete: false,
      permissaoGestor: getMaxPermission(this.$store),
      dp: setores.departamentoDePessoal,
      gestor: this.$store.getters.listaPermissoes.gestor,
      gestorGeral: this.$store.getters.listaPermissoes.gestorgeral,
    };
  },
  mounted() {
    EventBus.$on('dataInicialFormatedBus', from => this.dataBusInicial.push(from));
    EventBus.$on('dataFinalFormatedBus', from => this.dataBusFinal.push(from));
  },

  methods: {
    closeModal() {
      this.setor = '';
      this.dialog = false;
      this.dialogDelete = true;
    },

    async getRelatorioDesligamento() {
      const body = {
        dataInicio: this.dataCheckbox ? this.dataInitialSelected : null,
        dataFim: this.dataCheckbox ? this.dataFinalSelected : null,
        status: this.statusSelected,
      };

      const { data: response } = await this.$http.post(
        '/download-relatorio-desligamento/', body,
      );

      const data = XLSX.utils.json_to_sheet(response);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'data');
      const nameFile = 'Relatório de Desligamento DP.xlsx';
      XLSX.writeFile(wb, nameFile);
    },

    dataFormatted(data) {
      return data ? moment(data).format('DD/MM/YYYY') : '';
    },
    dataMoment(data) {
      return data ? moment(data, 'DD/MM/YYYY') : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.principal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .btn-2 {
    text-transform: inherit !important;
  background: #0056a8 !important;
  color: #fff;
  border-radius: 5px;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    &:hover {
      color: #bfbfbf;
    }
  }
  .container-row {
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  align-items: flex-start;
  flex-direction: row;
  }
}
</style>
