import vue from 'vue';
// eslint-disable-next-line import/no-cycle
import store from './store/index';

const userTopicSubscriptions = async (id) => {
  if (id) {
    const subscription = vue.ws.socket.getSubscription(`notify:${id}`);
    if (!subscription) {
      await vue.ws.subscribe(`notify:${id}`);
    }
  }
};

export default async () => new Promise(async (resolve) => {
  const WS_URL = process.env.VUE_APP_WS_URL;
  vue.ws.disconnect();
  await vue.ws.connect({
    wsDomain: WS_URL,
    jwtToken: null,
  }, {
    path: 'adonis-ws',
    reconnectionDelay: 50,
  });
  await vue.ws.socket.on('open', async () => {
    await userTopicSubscriptions(store.state.usuario.id);
    resolve();
  });
});
