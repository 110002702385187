<template>
  <layout>
    <div>
      <v-card class="card-filtro mt-0 px-3 py-3">
        <v-expansion-panels
        accordion
        multiple
        flat
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
          <h6 class="font-weight-bold body-0">Filtro: Status</h6>
          </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="container-sedes status-column">
                <v-checkbox
                  dense
                  v-for="(item, index) in status"
                  :key="index"
                  class="flex-item my-0 mr-4 pa-0"
                  v-model="selectedStatus"
                  :label="item.tipo"
                  :value="item.id"
                />
              </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
          <h6 class="font-weight-bold body-0">Filtro: Sedes</h6>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="container-column sedes-column">
              <v-checkbox
                dense
                v-for="(item, index) in sedes"
                :key="index"
                class="my-0 pa-0"
                v-model="selectedSedes"
                :label="item.nome"
                :value="item.id"
              />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row>
        <h6 class="my-6 ml-3 font-weight-bold body-0">Filtro: Intervalo de datas</h6>
      </v-row>
        <div class="container-row">
          <v-menu
            offset-x
            offset-y
            :close-on-content-click="false"
            ref="menu1"
            v-model="menu1"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                readonly
                class="input-date mx-1"
                v-model="dataInicialFormat"
                label="Data inicial"
                append-icon="event"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              :max="dataHoje"
              v-model="dataInicial"
              @input="menu1 = false"
              locale="pt-br"
            ></v-date-picker>
          </v-menu>

          <v-menu
            offset-x
            offset-y
            :close-on-content-click="false"
            ref="menu2"
            v-model="menu2"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                readonly
                class="input-date ml-4"
                v-model="dataFinalFormat"
                label="Data final"
                append-icon="event"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              :max="dataHoje"
              v-model="dataFinal"
              @input="menu2 = false"
              locale="pt-br"
            ></v-date-picker>
          </v-menu>
          <div class="display-button">
            <Botao
              class="botao-filtro"
              type="button"
              label="Filtrar"
              :tipo="showFilterButton"
              :callback="filtrarIndicadores"
            />
          </div>
        </div>
      </v-card>
      <!-- Inicio Indicadores -->
      <v-row class="indicadores">
        <h1 class="my-5 ml-6 font-weight-bold body-0">Performance do Atendente
          {{ atendente[0].nome }}</h1>
        <!-- Card Atendente -->
        <v-row class="indicadores">
          <v-col lg="auto">
            <IndicadorCardAtendimentoUnique
              v-if="exibeIndicadorPorAtendente"
              :valores="atendimentoPorAtendente"
              :key="updateAtendimentosPorAtendente"
              :loader="loaderPorAtendente"
            />
          </v-col>
          <!-- Quantidade de atendimentos ABERTO(S) x ENCERRADO(S) x REABERTO(S) -->
          <v-col lg="auto">
            <IndicadorAbertoFechadoReaberto
              v-if="exibeIndicadorAbertoFechadoReaberto"
              :valores="abertoFechadoReaberto"
              :labels="statusLabelAtendimentos"
              :key="updateAtendimentosAbertosFechadosReabertos"
              :loaderAbertoFechadoReaberto="loaderAbertoFechadoReaberto"
            />
          </v-col>
        </v-row>
        <!-- Média(%) de SATISFAÇÃO por Atendente por dia  -->
        <v-col lg="auto" class="indicadores">
          <IndicadorSatisfacaoAtendentePorMes
            v-if="exibePorcentagemAtendimentosAntendente"
            :diasTratados="diasTratadosAntende"
            :valores="atendimentosPorcentagemAtendente"
            :key="updateAtendimentosPorcentagemAtendente"
            :loaderAtendimentosPorcentagemAtendente="loaderAtendimentosPorcentagemAtendente"
          />
        </v-col>
      </v-row>
      <!-- Término Indicadores -->
    </div>
  </layout>
</template>

<script>
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import layout from '../layouts/Layout.vue';
import Botao from '../components/Botao.vue';
import IndicadorCardAtendimentoUnique from '../components/indicadores/IndicadorCardAtendimentoUnique';
import IndicadorAbertoFechadoReaberto from '../components/indicadores/IndicadorAbertoFechadoReaberto';
import IndicadorSatisfacaoAtendentePorMes from '../components/indicadores/IndicadorSatisfacaoAtendentePorMes';
import { getMaxPermission, orderByAlphabetical } from '../utils/utilities';

export default {
  name: 'IndicadoresDiretoria',
  components: {
    Botao,
    layout,
    IndicadorCardAtendimentoUnique,
    IndicadorAbertoFechadoReaberto,
    IndicadorSatisfacaoAtendentePorMes,
  },

  data() {
    return {
      status: [],
      sedes: [],
      selectedSedes: [],
      selectedStatus: [],
      dataHoje: new Date().toISOString().slice(0, 10),
      dataInicial: '',
      dataFinal: '',
      menu1: false,
      menu2: false,
      usuario: getMaxPermission(this.$store),
      atendenteID: this.$store.getters.isUsuario.id,

      // Cards dos atendente
      atendente: [],
      exibeIndicadorPorAtendente: false,
      atendimentoPorAtendente: [],
      updateAtendimentosPorAtendente: 0,
      loaderPorAtendente: false,

      // Quantidade de atendimentos ABERTO(S) x ENCERRADO(S) x REABERTO(S)
      exibeIndicadorAbertoFechadoReaberto: false,
      abertoFechadoReaberto: [],
      statusLabelAtendimentos: [],
      updateAtendimentosAbertosFechadosReabertos: 0,
      loaderAbertoFechadoReaberto: false,

      // Média(%) de SATISFAÇÃO por Atendente por mês
      exibePorcentagemAtendimentosAntendente: false,
      diasTratadosAntende: [],
      atendimentosPorcentagemAtendente: [],
      updateAtendimentosPorcentagemAtendente: 0,
      loaderAtendimentosPorcentagemAtendente: false,
    };
  },
  computed: {
    dataInicialFormat() {
      return this.dataInicial ? moment(this.dataInicial).format('DD/MM/YYYY') : moment().startOf('month').format('DD/MM/YYYY');
    },
    dataFinalFormat() {
      return this.dataFinal ? moment(this.dataFinal).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY');
    },
    showFilterButton() {
      return (!isEmpty(this.selectedSedes) && this.dataInicial && this.dataFinal) ? 'primary' : 'disabled';
    },
  },

  async created() {
    await this.getAtendentes();
    await this.getSedes();
    await this.getStatus();
    this.filtrarIndicadores();
  },

  methods: {
    loadingPage(status) {
      this.$store.commit('UPDATE_LOADING', status);
    },

    async getSedes() {
      try {
        await this.loadingPage(true);
        const { data: response } = await this.$http.get('/sedes');
        this.sedes = orderByAlphabetical(response, 'sedes');
        this.sedes.forEach((sede) => {
          this.selectedSedes.push(sede.id);
        });
        await this.loadingPage(false);
      } catch (error) {
        console.error(error);
      }
    },

    async getAtendentes() {
      try {
        const { data: response } = await this.$http.get('/atendentes');
        this.atendente = response.filter((item => item.id === this.atendenteID));
        // this.atendente = response;
      } catch (error) {
        console.error(error);
      }
    },

    async getStatus() {
      try {
        await this.loadingPage(true);
        const { data: response } = await this.$http.get('/status');
        this.status = orderByAlphabetical(response, 'status');
        this.status.forEach((status) => {
          this.selectedStatus.push(status.id);
        });
        await this.loadingPage(false);
      } catch (error) {
        console.error(error);
      }
    },

    async filtrarIndicadores() {
      const body = {
        dataInicio: this.dataInicial ? this.dataInicial : moment().startOf('month').format('YYYY-MM-DD'),
        dataFim: this.dataFinal ? this.dataFinal : moment().format('YYYY-MM-DD'),
        setores: [this.usuario.id_setor],
        status: this.selectedStatus,
        sedes: this.selectedSedes,
        atendente_id: this.atendenteID,
      };
      this.IndicadorPorAtendente(body);
      this.indicadorAbertoFechadoReaberto(body);
      this.indicadorPorcentagemAtendimentosPorAtendenteMes(body);
    },

    async IndicadorPorAtendente(dadosFiltro) {
      this.loaderPorAtendente = true;
      const { data: response } = await this.$http.post('/indicadores/atendimentos-por-card-atendente', dadosFiltro);
      const retorno = [];
      this.atendente.forEach(async (atendente) => {
        const dias = response.media.tempoMedia.filter(
          item => item.usuario_atendente_id === atendente.id,
        );
        const avaliacoes = response.avaliacoes.filter(
          item => item.usuario_atendente_id === atendente.id,
        );
        let encerrados = response.encerrados.filter(
          item => item.usuario_atendente_id === atendente.id,
        );
        let reabertos = response.reabertos.filter(
          item => item.usuario_atendente_id === atendente.id,
        );
        let pendentes = response.pendentes.filter(
          item => item.usuario_atendente_id === atendente.id,
        );
        if (encerrados.length) {
          encerrados = encerrados[0].count;
        } else {
          encerrados = '0';
        }
        if (reabertos.length) {
          reabertos = reabertos[0].count;
        } else {
          reabertos = '0';
        }
        if (pendentes.length) {
          pendentes = pendentes[0].count;
        } else {
          pendentes = '0';
        }
        let avatar;
        if (atendente.avatar_id) {
          avatar = await this.$http.get(`/busca-avatar/${atendente.avatar_id}`);
          avatar = avatar.data;
        } else {
          avatar = null;
        }
        retorno.push({
          nome: atendente.nome,
          avatar,
          dias: dias.length ? dias[0].valor : 0,
          avaliacao: avaliacoes.length ? [avaliacoes[0].insatisfeito,
            avaliacoes[0].neutro, avaliacoes[0].satisfeito, avaliacoes[0].naoAvaliar] : ['0', '0', '0', '0'],
          atendimento: [encerrados, reabertos],
          pendente: pendentes,
        });
      });
      this.atendimentoPorAtendente = retorno;

      /* ----------------------------------- */
      this.exibeIndicadorPorAtendente = true;
      this.updateAtendimentosPorAtendente += 1;
      this.loaderPorAtendente = false;
    },

    async indicadorAbertoFechadoReaberto(dadosFiltro) {
      try {
        this.loaderAbertoFechadoReaberto = true;
        const { data: response } = await this.$http.post('/indicadores/atendimentos-abertos-fechado-reaberto', dadosFiltro);

        this.statusLabelAtendimentos = ['Aberto(s)', 'Fechado(s)', 'Reabertos(s)'];

        this.abertoFechadoReaberto = [response.atendimentosAbertos,
          response.atendimentosFechados, response.atendimentosReabertos];

        this.exibeIndicadorAbertoFechadoReaberto = true;
        this.updateAtendimentosAbertosFechadosReabertos += 1;
        this.loaderAbertoFechadoReaberto = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorPorcentagemAtendimentosPorAtendenteMes(dadosFiltro) {
      try {
        this.loaderAtendimentosPorcentagemAtendente = true;
        const { data: response } = await this.$http.post('/indicadores/satisfacao-por-dia', dadosFiltro);

        this.diasTratadosAntende = response.days;
        this.atendimentosPorcentagemAtendente = response.valores;

        this.exibePorcentagemAtendimentosAntendente = true;
        this.updateAtendimentosPorcentagemAtendente += 1;
        this.loaderAtendimentosPorcentagemAtendente = false;
      } catch (error) {
        console.error(error);
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.indicadores {
  display: flex;
  justify-content: center;
}
.container-column {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  max-width: 250px;
}
.status-column {
  max-height: 95px;
}
.sedes-column {
  max-height: 145px;
}
.container-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}
.display-button {
  display: flex;
  margin-left: 10px;
  margin-top: 3px;
  // justify-content: flex-end;
  width: 100%;
}
.card-filtro {
  border: 2px solid black;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}
.input-date {
  max-width: 250px;
}
.container-sedes {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  max-height: 95px;
  max-width: 336px;
}
</style>
