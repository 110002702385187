<template>
  <div class="flex-component">
    <img
      src="../assets/img/empty-states/atendimento-empty-state.svg"
      alt="Ilustração para mostrar que não existem atendimentos para serem exibidos"
    />
    <h2 class="flex-component__text">{{text}}</h2>
    <router-link
      to="/novo"
      class="buttonCentre mb-5"
    >
      <Botao
        label="+ Novo atendimento"
        tipo="atendimento"
        type="submit"
      />
    </router-link>
  </div>
</template>
<style lang="scss" scoped>
.flex-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .flex-component__text {
    font-size: 22px;
    font-weight: 400;
    margin: 30px 0;
  }
}
</style>
<script>
import Botao from './Botao';

export default {
  name: 'AtendimentoEmptyState',
  components: {
    Botao,
  },
  props: {
    text: {
      type: String,
      required: false,
      default: 'Não há atendimentos para serem exibidos',
    },
  },
};
</script>
