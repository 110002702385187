<template>
  <v-card
    class="mb-5"
    outlined
  >
    <v-card-title :[tipoComentario]="true">
      {{ titulo }}
    </v-card-title>
    <v-card-subtitle v-if="subcategoria">
      {{ subcategoria }}
    </v-card-subtitle>
    <v-card-text
      v-html="descricao"
      class="pt-2 pb-1"
    >
    </v-card-text>
    <slot
      v-if="fluxoId"
      :name="getTypeForm()"
    ></slot>
    <slot name="arquivos"></slot>
    <div class="d-flex align-end">
      <v-card-text class="data pt-1">
        {{ data }}
      </v-card-text>
      <v-card-actions
        class="pb-0"
        v-if="usuario"
      >
        <form @submit.prevent="click">
          <Botao
            label="Encerrar"
            :styles="btn"
            :tipo="tipoBotao"
          />
        </form>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import VueTypes from 'vue-types';
import Botao from './Botao.vue';
import { fluxos } from '../Constants.js';

export default {
  name: 'CardComentario',
  components: {
    Botao,
  },
  props: {
    titulo: VueTypes.string.isRequired.def('Titulo'),
    subcategoria: VueTypes.string.def(''),
    descricao: VueTypes.string.def('(Descrição)'),
    dadosFluxo: VueTypes.object,
    fluxoId: VueTypes.integer,
    data: VueTypes.string.def('xx/xx/xxxx'),
    usuario: VueTypes.boolean,
    click: VueTypes.func,
    tipoBotao: VueTypes.oneOf(['secondary', 'disabled']).def('secondary'),
    tipoComentario: VueTypes.oneOf(['publico', 'privado']).def('publico'),
  },
  data() {
    return {
      btn: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingLeft: '10px',
        paddingRight: '10px',
        margin: '10px',
        fontSize: '14px !important',
      },
    };
  },
  methods: {
    getTypeForm() {
      let retorno = '';
      switch (this.fluxoId) {
        case fluxos.recisao:
          retorno = 'formDesligamento';
          break;
        case fluxos.comprasTecnicasNacionais:
          retorno = 'formComprasTecnicasNacionais';
          break;
        case fluxos.comprasTecnicasInternacionais:
          retorno = 'formComprasTecnicasInternacionais';
          break;
        case fluxos.comprasOrdinarias:
          retorno = 'formComprasOrdinarias';
          break;
        case fluxos.comprasFixas:
          retorno = 'formComprasFixas';
          break;
        case fluxos.comprasEPS:
          retorno = 'formComprasEPS';
          break;
        case fluxos.criarFornecedoresDadosMestres:
          retorno = 'formCriarFornecedoresDadosMestres';
          break;
        case fluxos.criarClientesDadosMestres:
          retorno = 'formCriarClientesDadosMestres';
          break;
        case fluxos.modificarFornecedor:
          retorno = 'formModificarFornecedor';
          break;
        case fluxos.criarMaterialServico:
          retorno = 'formCriarMaterialServico';
          break;
        case fluxos.modificarCliente:
          retorno = 'formModificarCliente';
          break;
        case fluxos.criarServicoCliente:
          retorno = 'formCriarServicoCliente';
          break;
        default:
          retorno = 'fluxoLabelsRespostas';
          break;
      }
      return retorno;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application p {
  margin-bottom: 0px;
}

.theme--light.v-card > .v-card__text {
  font-size: 14px;
  color: #303030;
}

.v-card__title[privado] {
  font-size: 14px;
  font-weight: bold;
  color: #303030;
  background-color: #c1c1c1;
  line-height: 1rem;
  padding: 8px;
}

.v-card__title[publico] {
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background-color: #122870;
  line-height: 1rem;
  padding: 8px;
}

.v-card__subtitle {
  margin-top: 0px !important;
  padding: 8px 8px 2px 8px !important;
  color: #303030 !important;
  font-weight: 600;
}

.v-card__text {
  padding: 8px;
}

.data {
  font-size: 12px !important;
  color: rgba(48, 48, 48, 0.7) !important;
}
</style>
