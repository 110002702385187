import Vue from 'vue';
import Vuetify from 'vuetify';
import VueSweetalert2 from 'vue-sweetalert2';
import VueMaterial from 'vue-material';
import Ws from '@adonisjs/websocket-client/index';
import WsPlugin from 'adonis-vue-websocket';
import * as TastyBurgerButton from 'vue-tasty-burgers';
import VueHtml2Canvas from 'vue-html2canvas';
import { VueMaskDirective } from 'v-mask';
import Filters from './filters/filters';
import router from './router';
import AxiosPlugin from './plugins/AxiosPlugin';
import App from './App.vue';
import store from './store';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbvue/lib/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'vue-material/dist/vue-material.min.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import './styles/app.scss';
import 'vue-tasty-burgers/dist/vue-tasty-burgers.css';


const primary = '#122870';

require('typeface-montserrat');

const options = {
  confirmButtonColor: primary,
  cancelButtonColor: primary,
};

Vue.directive('mask', VueMaskDirective);
Vue.use(VueMaterial);
Vue.use(WsPlugin, { adonisWS: Ws });
Vue.use(VueSweetalert2, options);
Vue.use(AxiosPlugin);
Vue.use(Vuetify);
Vue.use(TastyBurgerButton);
Vue.use(VueHtml2Canvas);
Vue.mixin({
  filters: Filters,
});

Vue.config.productionTip = false;

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    // eslint-disable-next-line
    el.event = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.event);
  },
  // eslint-disable-next-line no-unused-vars
  unbind(el) {
    document.body.removeEventListener('click', el.event);
  },
});

new Vue({
  router,
  store,
  vuetify: new Vuetify({
    theme: {
      light: true,
      themes: {
        light: {
          primary,
          secondary: '#b0bec5',
          accent: '#0056a8',
          error: '#b71c1c',
        },
      },
    },
  }),
  // for dark mode configuration:
  // {
  //   theme: {
  //     dark: true,
  //   },
  // }
  // eslint-disable-next-line
  render: (h) => h(App),
}).$mount('#app');
