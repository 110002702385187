<template>
  <div>
    <v-card
      :max-height="500"
      :width="1230"
      :min-width="700"
      :elevation="4"
      class="card-indicador mt-2 px-3 pt-3 pb-0"
    >
      <v-overlay
        :value="loaderAtendimentosPorArea"
        :absolute="true"
        :opacity="0.30"
      >
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
        <div ref="capture">
          <div class="div-titulo-botao">
          <span class="span-titulo">
            Total de atendimentos
            <strong>ABERTO(S), ENCERRADO(S) e REABERTO(S) por Área</strong>
          </span>
          <button
            class="botao-download"
            @click="screenShot()"
          >
            <span class="material-icons">
              get_app
            </span>
          </button>
          </div>
          <apexchart
            type="bar"
            :options="chartOptions"
            :series="series"
            height="420"
            width="1190"
          ></apexchart>
        </div>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import VueTypes from 'vue-types';
import screenShot from '../../mixins/screenShot.js';

export default {
  name: 'IndicadorAtendimentosPorAreaDiretoria',
  components: {
    apexchart: VueApexCharts,
  },
  mixins: [screenShot],
  props: {
    valores: VueTypes.array.isRequired,
    nomeAreas: VueTypes.array.isRequired,
    loaderAtendimentosPorArea: VueTypes.bool.isRequired,
  },
  data() {
    return {
      series: [
        {
          name: 'Aberto(s)',
          data: this.valores[0],
        },
        {
          name: 'Encerrados(s)',
          data: this.valores[1],
        },
        {
          name: 'Reaberto(s)',
          data: this.valores[2],
        },
      ],

      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },

        plotOptions: {
          bar: {
            horizontal: false,
            startingShape: 'flat',
            endingShape: 'flat',
            columnWidth: '80%',
          },
        },
        grid: {
          show: true,
          padding: {
            left: 12,
          },
          width: 2,
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'middle',
          style: {
            fontSize: '13px',
            fontWeight: 'bold',
          },
        },
        xaxis: {
          type: 'category',
          categories: this.nomeAreas,
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
          },
        },
        legend: {
          show: true,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.card-indicador {
  border-radius: 20px !important;
}
.div-titulo-botao {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .span-titulo {
    padding-left: 10px;
    color: #333;
  }

  .botao-download {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;
    border-radius: 5px;
    border: 2px solid #eeeeee;

    &:hover {
      background-color: #e2dede;
    }
    .material-icons {
      color: #0056a8;
      font-size: 25px;
    }
  }
}
</style>
