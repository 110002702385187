<template>
  <div id="preValidacao">
    <Snackbar
      :label="msg"
      v-model="snackbarPutPermissao"
      top
      right
      :timeout="2500"
      :tipo="'error'"
    />
    <h3 class="titleHeader mb-3">{{ nomeFluxo }}</h3>

    <v-row>
      <v-col>
        <h5>Atenção</h5>
        <p>Os campos com
          <span class="caption count-warning alert" style="padding: 3px;">*</span>
          são obrigatórios.</p>
      </v-col>
    </v-row>

    <template>
      <CamposPergunta
        v-for="(campo, index) in campos" :key="index"
        :columnCampo="campo.column_campo"
        :label="campo.descricao"
        :IdTipoCampo="campo.id_tipo_campos"
        :required="campo.required"
        :minCaracteres="campo.min_caracter"
        :IdMascara="campo.id_tipo_mascara"
        :opcoesCampo="campo.tipo_opcoes"
        @emit-value="getValue"
        />
    </template>

  </div>
</template>

<script>
import VueTypes from 'vue-types';
import CamposPergunta from '../CamposPergunta';
import Snackbar from '../Snackbar';

export default {
  name: 'FluxoLabels',
  components: {
    Snackbar,
    CamposPergunta,
  },

  props: {
    fluxoID: VueTypes.integer.required,
  },

  data() {
    return {
      msg: '',
      snackbarPutPermissao: false,
      nomeFluxo: '',
      campos: [],
      formCampos: [],
      IdCheckBox: 3, // Id do Banco de dados.
      dicionarioRequired: {},
      requiredError: false,
      dicionarioMinCaracteres: {},
      minCaracteresError: false,
    };
  },

  mounted() {
    this.getCamposFluxo();
  },

  methods: {

    async getCamposFluxo() {
      const { data: response } = await this.$http.get(`/fluxos/${this.fluxoID}`);
      this.nomeFluxo = response.nome;
      this.campos = response.campos;
      this.criaDicionarioCamposFluxo(this.campos);
    },

    criaDicionarioCamposFluxo(campos) {
      const novoDicionario = {};
      // eslint-disable-next-line array-callback-return
      campos.map((item) => {
        const campo = `column_campo_${item.column_campo}`;
        novoDicionario[campo] = item.id_tipo_campos === this.IdCheckBox ? false : null;
        this.dicionarioRequired[campo] = item.required;
        this.dicionarioMinCaracteres[campo] = item.min_caracter;
      });
      this.formCampos = novoDicionario;
    },

    getValue(value) {
      const campo = `column_campo_${value.column_campo}`;
      this.formCampos[campo] = value.resposta;
    },

    validaCamposRequeried() {
      const listErrors = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < 26; i++) {
        const campo = `column_campo_${i}`;

        if (this.dicionarioRequired[campo] && !this.formCampos[campo]) {
          listErrors.push(true);
        } else listErrors.push(false);
      }

      this.requiredError = !!listErrors.filter(item => item).length;

      if (this.requiredError) {
        this.msg = 'Você precisa preencher todos os campos.';
        this.snackbarPutPermissao = true;
      }
    },

    validaMinCaracteres() {
      const listErrors = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < 26; i++) {
        const campo = `column_campo_${i}`;

        if (this.dicionarioMinCaracteres[campo]) {
          if (!this.formCampos[campo]
            || this.dicionarioMinCaracteres[campo] > this.formCampos[campo].length) {
            listErrors.push(true);
          } else listErrors.push(false);
        } else listErrors.push(false);
      }

      this.minCaracteresError = !!listErrors.filter(item => item).length;

      if (this.minCaracteresError) {
        this.msg = 'Você precisa preencher o minímo de caracteres.';
        this.snackbarPutPermissao = true;
      }
    },

    enviaJSON() {
      this.validaMinCaracteres();
      this.validaCamposRequeried();

      if (this.requiredError || this.minCaracteresError) {
        this.formCampos.validation = false;
      } else this.formCampos.validation = true;

      this.$emit('dadosFluxo', this.formCampos);
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  color: red;
}
</style>
