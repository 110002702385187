<template>
  <Layout>
    <v-card class="card-filtro mt-0 px-3 py-3">
      <v-expansion-panels
      accordion
      multiple
      flat
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h6 class="font-weight-bold body-0">Filtro: Status</h6>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="container-sedes">
              <v-checkbox
                dense
                v-for="(item, index) in statusList"
                :key="index"
                class="flex-item my-0 mr-4 pa-0"
                v-model="statusEscolhido"
                :label="item.tipo"
                :value="item.id"
              />
            </div>
          </v-expansion-panel-content>
      </v-expansion-panel>
      </v-expansion-panels>
      <v-checkbox
        v-model="dataCheckbox"
        label="Filtrar por datas"
        class="checkbox"
      ></v-checkbox>
        <div class="container-row"
        style="display: flex;"
          v-if="dataCheckbox">
          <v-menu
            offset-x
            offset-y
            :close-on-content-click="false"
            ref="menu1"
            v-model="menu1"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                readonly
                class="input-date mx-1"
                v-model="dataInicialFormat"
                label="Data inicial"
                append-icon="event"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              :max="dataHoje"
              v-model="dataInicial"
              @input="menu1 = false"
              locale="pt-br"
            ></v-date-picker>
          </v-menu>
          <v-menu
            offset-x
            offset-y
            :close-on-content-click="false"
            ref="menu2"
            v-model="menu2"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                readonly
                class="input-date ml-4"
                v-model="dataFinalFormat"
                label="Data final"
                append-icon="event"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              :max="dataHoje"
              v-model="dataFinal"
              @input="menu2 = false"
              locale="pt-br"
            ></v-date-picker>
          </v-menu>
        </div>
        <v-icon v-if="dataCheckbox">mdi-alert</v-icon>
        <p class="caption"
        v-if="dataCheckbox"><strong>Atenção: </strong>As datas estão em formato
        americano (Ano - Mês - Dia)</p>
    </v-card>

    <div class="principal">
      <hr  class="linha">
      <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
          <v-btn
            class="btn-2 primary"
            v-if="permissaoGestor.id_permissao === gestorGeral"
            v-on="on"
          >
            DOWNLOAD RELATÓRIO POR SETOR
          </v-btn>
          <v-divider></v-divider>
        </template>
        <v-card class="px-2">
          <v-card-title>Escolha o Setor para gerar o relatório</v-card-title>
          <v-card-subtitle class="mt-1">
            <v-select
              label="Setor"
              v-model="setor"
              :items="listaSetores"
              outlined
              dense
              @click="getSetores"
            ></v-select>
            <v-card-actions class="pb-2">
              <v-spacer></v-spacer>
              <div>
                <Botao
                  class="mr-1"
                  label="Cancelar"
                  tipo="primary"
                  :callback="closeModal" />
                <Botao
                  class="button"
                  label="Gerar relatório"
                  :tipo="setor ? 'primary' : 'disabled'"
                  :callback="getRelatorioPorSetor"
                />
              </div>
            </v-card-actions>
          </v-card-subtitle>
        </v-card>
      </v-dialog>

      <hr>
      <!-- Botão DOWNLOAD RELATÓRIO TODOS ATENDIMENTOS -->
      <Botao
      v-if="(permissaoGestor.id_permissao === gestorGeral)"
      type="button"
      label="DOWNLOAD RELATÓRIO TODOS ATENDIMENTOS"
      tipo="primary"
      :callback="getRelatorioGeral"
      />

      <hr>
      <!-- Botão DOWNLOAD RELATÓRIO -->
      <Botao
        v-if="(permissaoGestor.id_permissao === gestor) || hasRelatorio"
        type="button"
        label="DOWNLOAD RELATÓRIO"
        tipo="primary"
        :callback="getRelatorioPorSetor"
      />

      <hr>
      <!-- Botão DOWNLOAD RELATÓRIO DESLIGAMENTO CLT e Estagio-->
      <RelatorioDesligamento
      v-if="(permissaoGestor.setor_nome === dp && notColaborador )
      || (permissaoGestor.setor_nome === juridico && notColaborador)
      || (permissaoGestor.id_permissao === gestorGeral)"
      :dataCheckbox="dataCheckbox"
      :statusCheckbox="statusCheckbox"
      :statusSelected="statusEscolhido"
      :dataInitialSelected="dataInicialFormat"
      :dataFinalSelected="dataFinalFormat"
    />

    <hr>
    <!-- Botão DOWNLOAD RELATÓRIO DESLIGAMENTO EPS-->
    <RelatorioDesligamentoJuridico
      v-if="(permissaoGestor.setor_nome === dp && notColaborador )
      || (permissaoGestor.setor_nome === juridico && notColaborador)
      || (permissaoGestor.id_permissao === gestorGeral)"
      :dataCheckbox="dataCheckbox"
      :statusCheckbox="statusCheckbox"
      :statusSelected="statusEscolhido"
      :dataInitialSelected="dataInicialFormat"
      :dataFinalSelected="dataFinalFormat"
    />
    </div>
  </Layout>
</template>

<script>
import moment from 'moment';
import XLSX from 'xlsx';
import RelatorioDesligamento from '../components/RelatorioDesligamento.vue';
import RelatorioDesligamentoJuridico from '../components/RelatorioDesligamentoJuridico.vue';
import Botao from '../components/Botao.vue';
import { getMaxPermission, orderByAlphabetical } from '../utils/utilities';
import { setores } from '../Constants.js';
import Layout from '../layouts/Layout.vue';

export default {
  name: 'Relatorios',
  components: {
    Botao,
    Layout,
    RelatorioDesligamento,
    RelatorioDesligamentoJuridico,
  },
  data() {
    return {
      json_fields: {
        setor_nome: 'setor_nome',
        id_atendimento: 'id_atendimento',
        categoria: 'categoria',
        subcategoria: 'subcategoria',
        nome: 'nome',
        descricao: 'descricao',
        nome_fluxo: 'nome_fluxo',
        atendente_nome: 'atendente_nome',
        atendente_usuario: 'atendente_usuario',
        status: 'status',
        dateFormatted: '',
        sede: 'sede',
        data_criacao: 'data_criacao',
        data_edicao: 'data_edicao',
        avaliacao: 'avaliacao',
        reaberto: 'reaberto',
        data_encerrado: 'data_encerrado',
        dias_em_aberto: 'dias_em_aberto',
        valor_unitario: 'valor_unitario',
      },
      setor: '',
      listaSetores: {
        id: 'id',
        nome: 'nome',
      },
      dataCheckbox: false,
      statusCheckbox: false,
      juridico: setores.juridico,
      dialog: false,
      menu1: false,
      menu2: false,
      dataInicial: '',
      statusEscolhido: [],
      statusList: [],
      dataFinal: '',
      dataHoje: new Date().toISOString().slice(0, 10),
      dialogDelete: false,
      permissaoGestor: getMaxPermission(this.$store),
      dp: setores.departamentoDePessoal,
      gestor: this.$store.getters.listaPermissoes.gestor,
      relatorioPermissao: this.$store.getters.listaPermissoes.relatorio,
      colaborador: this.$store.getters.listaPermissoes.colaborador,
      gestorGeral: this.$store.getters.listaPermissoes.gestorgeral,
    };
  },
  created() {
    moment.locale('pt-br');
    this.getSetores();
  },

  mounted() {
    this.getStatus();
    this.dataInicialFormat();
    this.dataFinalFormat();
  },

  computed: {
    hasRelatorio() {
      return !!this.$store.getters.isUsuario.permissao
        .filter(element => element.id_permissao === this.relatorioPermissao).length;
    },
    dataInicialFormat() {
      return this.dataInicial ? moment(this.dataInicial).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD');
    },
    dataFinalFormat() {
      return this.dataFinal ? moment(this.dataFinal).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    },
    showFilterButton() {
      return (this.dataInicial && this.dataFinal) ? 'primary' : 'disabled';
    },
    notColaborador() {
      return this.permissaoGestor.id_permissao !== this.colaborador;
    },
  },

  methods: {
    async getStatus() {
      try {
        const { data: response } = await this.$http.get('/status');
        this.statusList = orderByAlphabetical(response, 'status');
        this.statusList.forEach((status) => {
          this.statusEscolhido.push(status.id);
        });
      } catch (error) {
        console.error(error);
      }
    },

    nomeSetor() {
      const setorNome = this.listaSetores.filter(
        item => item.value === this.setor,
      );
      return `${setorNome[0].text}`;
    },
    closeModal() {
      this.setor = '';
      this.dialog = false;
      this.dialogDelete = true;
    },

    async getSetores() {
      try {
        const { data: response } = await this.$http.get('/setores');

        this.listaSetores = response.map(item => ({
          value: item.id,
          text: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },

    async getRelatorioPorSetor() {
      const listaFiltradaStatus = [];

      this.setor = this.permissaoGestor.id_permissao === this.gestorGeral
        ? this.setor
        : this.permissaoGestor.id_setor;

      const body = {
        dataInicio: this.dataCheckbox ? this.dataInicialFormat : null,
        dataFim: this.dataCheckbox ? this.dataFinalFormat : null,
        setor_id: this.setor,
        status: this.statusEscolhido,
      };
      const { data: response } = await this.$http.post('/download-relatorio-setor', body);

      const listaExcel = this.formatValues(response);

      listaExcel.map(item => listaFiltradaStatus.push(item));

      const data = XLSX.utils.json_to_sheet(listaFiltradaStatus);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'data');
      const nameFile = `Relatório ${this.nomeSetor()}.xlsx`;
      XLSX.writeFile(wb, nameFile);
    },

    async getRelatorioGeral() {
      try {
        const dateInitial = this.dataFormatted(this.dataInicialFormat);
        const dateFinal = this.dataFormatted(this.dataFinalFormat);
        const body = {
          dataInicio: this.dataCheckbox ? dateInitial : null,
          dataFim: this.dataCheckbox ? dateFinal : null,
          status: this.statusEscolhido,
        };
        const { data: response } = await this.$http.post('/download-relatorio', body);
        const listaExcel = this.formatValues(response);

        const data = XLSX.utils.json_to_sheet(listaExcel);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, 'data');
        const nameFile = 'Relatório.xlsx';
        XLSX.writeFile(wb, nameFile);
      } catch (error) {
        console.error(error);
      }
    },

    dataFormatted(data) {
      return data ? moment(data).startOf('day').format('YYYY-MM-DD') : '';
    },

    dataMoment(data) {
      return data ? moment(data, 'DD/MM/YYYY') : '';
    },

    openDays(criacao, encerrado) {
      const datasCcriacao = criacao ? new Date(criacao) : '';
      const dataEncerrado = encerrado ? new Date(encerrado) : Date.now();
      const diffTime = Math.abs(datasCcriacao - dataEncerrado);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },

    formatValues(data) {
      data.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        delete item.area_atuacao;
        // eslint-disable-next-line no-param-reassign
        item.dias_em_aberto = this.openDays(
          item.data_criacao,
          item.data_encerrado,
        );
        // eslint-disable-next-line no-param-reassign
        item.data_criacao = this.dataFormatted(item.data_criacao);
        // eslint-disable-next-line no-param-reassign
        item.data_edicao = this.dataFormatted(item.data_edicao);
        // eslint-disable-next-line no-param-reassign
        item.data_encerrado = this.dataFormatted(item.data_encerrado);
        // eslint-disable-next-line no-param-reassign
        item.valor_unitario = 1;
      });
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.principal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .checkbox {
    display: flex;
    width: 250px;
    margin-left: 90px;
    // background-color: red;
  }

  .linha {
    width: 100%;
    background-color: #122870;
  }

  .btn-2 {
    text-transform: inherit !important;
  background: #0056a8 !important;
  color: #fff;
  border-radius: 5px;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    &:hover {
      color: #bfbfbf;
    }
  }
  .container-row {
    display: flex !important;
    flex-flow: row nowrap !important;
    align-items: flex-start !important;
  }
}

.card-filtro {
  border: 2px solid black;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;

  .titulo-marcar-desmarcar {
    display: flex;

    .div-marcar-desmarcar {
      display: flex;
      align-items: flex-start;
      padding-left: 20px;
    }
  }
}

.container-sedes {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  max-height: 95px;
  max-width: 336px;
}
.container-column {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  max-width: 250px;
}
.status-column {
  max-height: 95px;
}
.sedes-column {
  max-height: 145px;
}
.input-date {
  max-width: 250px;
  padding: 15px;
}
</style>
