<template>
  <div>
    <v-card
      v-if="this.valores[0].length > 0"
      :max-height="500"
      :width="1230"
      :min-width="700"
      :elevation="4"
      class="card-indicador mt-2 px-3 pt-4 pb-0"
    >
      <v-overlay
        :value="this.loader"
        :absolute="true"
        :opacity="0.30"
      >
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <div ref="capture">
        <div class="div-cabecalho-reabertos">
          <span class="span-indicador">
            Atendimentos <strong>ENCERRADO(S) nos últimos 14 dias</strong>
          </span>
          <button
            class="botao-download"
            @click="screenShot()"
          ><span class="
              material-icons">
              get_app
            </span>
          </button>
        </div>
        <apexchart
          type="area"
          :options="chartOptions"
          :series="series"
          width="1190"
          height="338"
        ></apexchart>
      </div>
    </v-card>
    <v-card
      v-else
      :height="450"
      :max-width="500"
      :elevation="4"
      class="card-indicador mt-2 px-3 pt-4 pb-0"
    >
      <div class="div-sem-dados">
        <span class="mensagem-sem-dados">Nenhum dado encontrado de acordo com o filtro!</span>
      </div>
    </v-card>


  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import VueTypes from 'vue-types';
import screenShot from '../../mixins/screenShot.js';


export default {
  name: 'IndicadorEncerradosUltimosDias',
  mixins: [screenShot],
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    valores: VueTypes.array.isRequired,
    loader: VueTypes.bool.isRequired,
  },
  data() {
    return {
      series: [{
        name: 'Atendimento(s)',
        data: this.valores[1],
      }],
      chartOptions: {
        chart: {
          height: 500,
          type: 'line',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        fill: {
          type: 'solid',
          opacity: [0.2],
        },
        grid: {
          show: false,
        },
        labels: this.valores[0],
        yaxis: {
          show: false,
        },
        tooltip: {
          followCursor: true,
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.div-cabecalho-reabertos {
  display: flex;
  justify-content: space-between;

  .span-indicador {
    color: #333;
    font-size: 15px;
  }

  .botao-download {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    border-radius: 5px;
    border: 2px solid #eeeeee;

    &:hover {
      background-color: #e2dede;
    }

    .material-icons {
      color: #0056a8;
      font-size: 25px;
    }
  }
}
.subtitulo-numero {
  font-weight: bold;
}
.card-indicador {
  border-radius: 20px !important;
  padding-bottom:  20px !important;
}
</style>
<style lang="scss">
.card-indicador {
  border-radius: 20px !important;
  padding-bottom:  20px !important;
}
.div-sem-dados {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

  .mensagem-sem-dados {
    font-size: 18px;
  }
}
</style>
