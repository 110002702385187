<template>
  <div class="div-tag-label">
    <span
      class="tag-label"
      :style="corDefinida"
    >{{ tipo }}</span>
  </div>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'TagLabel',
  props: {
    tipo: VueTypes.string,
    cor: VueTypes.string.def('backgroundColor:#006495'),
    idPrioridade: VueTypes,
  },

  watch: {
    idPrioridade(value) {
      this.defineCor(value);
    },
  },

  created() {
    this.corDefinida = this.cor;
    if (this.idPrioridade) this.defineCor(this.idPrioridade);
  },

  data: () => ({
    urgenteCorVermelha: 'backgroundColor:#CF1717',
    prioritarioCorAmarela: 'backgroundColor:#DAC81C',
    normalCorVerde: 'backgroundColor:#2BAE3A',
    corDefinida: '',
  }),

  methods: {
    defineCor(idPrioridade) {
      const background = {
        1: this.urgenteCorVermelha,
        2: this.prioritarioCorAmarela,
        3: this.normalCorVerde,
      };
      this.corDefinida = background[idPrioridade] || this.cor;
    },
  },
};
</script>
<style scoped lang="scss">
.tag-label {
  border-radius: 24px;
  color: #ffffff;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500 !important;
  letter-spacing: normal;
  height: 25px;
}
.div-tag-label {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
