<template>
  <div>
  <Snackbar
      :label="msg"
      v-model="snackbarOpen"
      top
      right
      :tipo="tipoSnackbar"
      :timeout="2500"
    />
  <v-card-title class="headline principal">Criador de Fluxos</v-card-title>
  <div class="principal">
    <v-col cols="5">
      <v-select
        v-model="setorEscolhido"
        :items="setores"
        label="Escolha o setor"
        outlined
        dense
        @change="getCategoria"
      ></v-select>
    </v-col>
    <v-col cols="5">
      <v-select
        v-model="categoriaEscolhida"
        :items="categorias"
        label="Escolha o Categoria"
        outlined
        dense
        @change="getSubcategoria"
      ></v-select>
    </v-col>
    <v-col cols="5">
      <v-select
        v-model="subcategoriaEscolhida"
        :items="subcategorias"
        label="Escolha a Subcategoria"
        outlined
        dense
        @change="getSubcategoria"
      ></v-select>
    </v-col>
    <v-col cols="5">
      <v-text-field
        v-model="nomeFluxo"
        label="Insira o nome do novo Fluxo"
        outlined
        dense
        clearable
      ></v-text-field>
    </v-col>
  </div>
    <v-card-title class="headline principal">Adicionar campos</v-card-title>
    <div class="principal-esquerda">
    <v-col cols="4">
      <v-select
          v-model="tipoEscolhido"
          :items="tipoOpcoes"
          label="Escolha o tipo do campo"
          outlined
          dense
          @change="getSubcategoria"
        ></v-select>
    </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="labelEscolhida"
          label="Insira o nome do novo campo"
          outlined
          dense
          clearable
        ></v-text-field>
      </v-col>
    <v-col cols="4"
    v-if="tipoEscolhido === 1">
      <v-select
        v-model="mascaraEscolhida"
        :items="mascaraOptions"
        label="Escolha a mascara do campo"
        outlined
        dense
        @change="getSubcategoria"
      ></v-select>
    </v-col>
    <v-col cols="4"
    v-if="tipoEscolhido === 5">
      <v-text-field
        v-model="opcaoSelect"
        label="Insira uma opção por vez no Botão Selecionável"
        outlined
        dense
        clearable
      ></v-text-field>
      <v-btn
        class="v-btn_add"
        @click="addOpcaoSelect"
      >Adicionar Opção no Botão Selecionável</v-btn>
      <v-select
        class="campo-padding"
        v-model="select"
        :items="opcoesSelect"
        label="Opções já adicionadas na lista"
        outlined
        dense
      ></v-select>
    </v-col>
    <v-col cols="4"
    v-if="tipoEscolhido === 4">
      <v-text-field
        v-model="opcaoRadio"
        label="Insira uma opção por vez no Botão de Opções"
        outlined
        dense
        clearable
      ></v-text-field>
      <v-btn
        class="v-btn_add"
        @click="addOpcaoRadio"
      >Adicionar Opção no Botão de Opções</v-btn>
      <br>
      <v-select
        class="campo-padding"
        v-model="radio"
        :items="opcoesRadio"
        label="Opções já adicionadas na lista"
        outlined
        dense
      ></v-select>
    </v-col>
  </div>
  <div class="principal">
    <v-col cols="4">
      <v-checkbox
        v-if="tipoEscolhido !== 3"
        v-model="requirido"
        label="Campo obrigatório?"
      ></v-checkbox>
    </v-col>
    <v-col cols="4">
      <v-checkbox
        v-if="(tipoEscolhido === 1 && !mascaraEscolhida
        || tipoEscolhido === 1 && mascaraEscolhida === 1)
    || (tipoEscolhido === 6)"
        dense
        v-model="minCaracteres"
        label="Mínimo de caracteres no campo?"
      ></v-checkbox>
    </v-col>
    <v-col cols="4">
      <v-text-field
        v-if="minCaracteres"
        v-model="numeroCaracteres"
        label="Insira o mínimo de caracteres desse campo"
        type="number"
        outlined
        dense
        max="50"
        min="5"
        clearable
        auto-grow
      ></v-text-field>
    </v-col>
    </div>
    <div class="principal-buttons">
      <v-btn
        class="v-btn_clear"
        @click="clearCampo"
      >Limpar Campos</v-btn>
      <v-btn
        class="v-btn_clear"
        @click="fechaModal"
      >cancelar</v-btn>
      <v-btn
        class="v-btn_add"
        @click="putCampo"
      >Adicionar campo</v-btn>
    </div>
    <div v-if="campoOptions.length > 0">
      <div
        v-for="(item, index) in campoOptions"
        :key="index"
      >
      <v-divider></v-divider>
        <div class="camposFluxo">
          <Campo
          :label="item.descricao"
          :tipoCampo="item.id_tipo_campo"
          :mascara="item.id_tipo_mascara ? item.id_tipo_mascara : false"
          :required="item.required"
          :opcoesSelect="item.tipo_opcoes"
          :opcoesRadio="item.tipo_opcoes"
          :minCaracteres="item.min_caracter"
          />
          <v-btn
            class="v-btn_add style-btn"
            @click="deleteCampo(index)"
          >Remove Campo</v-btn>
        </div>
      </div>
    </div>
    <div class="principal"
    v-if="campoOptions.length > 0">
      <v-btn
        class="v-btn_confirm"
        @click="postFluxo"
      >Criar Fluxo</v-btn>
    </div>
  </div>
</template>

<script>
import { getMaxPermission, orderByAlphabetical } from '../utils/utilities';
import Campo from './Campo.vue';
import Snackbar from './Snackbar.vue';

export default {
  name: 'CriadorFluxos',
  components: {
    // Botao,
    Snackbar,
    Campo,
  },
  data() {
    return {
      tipoSnackbar: 'info',
      snackbarOpen: false,
      msg: '',
      nomeFluxo: '',
      setores: [],
      campoOptions: [],
      setorEscolhido: '',
      subcategorias: [],
      subcategoriaEscolhida: '',
      categorias: [],
      categoriaEscolhida: '',
      tipoOpcoes: [],
      tipoEscolhido: '',
      mascaraOptions: [],
      mascaraEscolhida: '',
      opcoesSelect: [],
      opcaoSelect: '',
      opcoesRadio: [],
      opcaoRadio: '',
      requirido: false,
      minCaracteres: false,
      numeroCaracteres: '',
      labelEscolhida: '',
      gestor: this.$store.getters.listaPermissoes.gestor,
      gestorGeral: this.$store.getters.listaPermissoes.gestorgeral,
      permissaoGestor: getMaxPermission(this.$store),
      dialog: false,
    };
  },
  created() {
    this.getSetores();
    this.getTipos();
    this.getMascaras();
  },

  mounted() {
    this.getSetores();
  },

  methods: {

    fechaModal() {
      this.clearCampo();
      this.$emit('dialogChanged', false);
    },

    async getSetores() {
      try {
        const { data: response } = await this.$http.get('/setores');
        let responseTratado = orderByAlphabetical(response, 'setores');
        if (this.permissaoGestor.id_permissao === this.gestorGeral) {
          this.setores = responseTratado.map(item => ({
            value: item.id,
            text: item.nome,
          }));
        } else {
          responseTratado = responseTratado.filter(item => item.id
            === this.permissaoGestor.id_setor);
          this.setores = responseTratado.map(item => ({
            value: item.id,
            text: item.nome,
          }));
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getCategoria() {
      try {
        const { data: response } = await this.$http.get(`/categorias/${this.setorEscolhido}`);
        const responseTratado = orderByAlphabetical(response, 'categorias');
        this.categorias = responseTratado.map(item => ({
          value: item.id,
          text: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },

    async getSubcategoria() {
      try {
        this.subcategoria = '';
        const { data: response } = await this.$http.get(`/subcategorias/${this.categoriaEscolhida}`);
        const responseTratado = orderByAlphabetical(response, 'subcategorias');
        this.subcategorias = responseTratado.map(item => ({
          value: item.id,
          text: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },

    async getTipos() {
      try {
        const { data: response } = await this.$http.get('/tipo-campos/');
        this.tipoOpcoes = response.map(item => ({
          value: item.id,
          text: item.tipo_campo,
        }));
      } catch (error) {
        console.error(error);
      }
    },

    async getMascaras() {
      try {
        const { data: response } = await this.$http.get('/mascara/');
        this.mascaraOptions = response.map(item => ({
          value: item.id,
          text: item.tipo_mascara,
        }));
      } catch (error) {
        console.error(error);
      }
    },

    async putCampo() {
      try {
        if (this.campoOptions.length > 25) {
          this.snackbarOpen = true;
          this.tipoSnackbar = 'error';
          this.msg = 'O Fluxo já contém 25 campos';
        } else {
          let tipoOpcoes = [];
          if (this.opcoesRadio.length > 0) {
            tipoOpcoes = this.opcoesRadio;
          } else if (this.opcoesSelect.length > 0) {
            tipoOpcoes = this.opcoesSelect;
          }
          this.campoOptions.push({
            descricao: this.labelEscolhida,
            id_tipo_campo: this.tipoEscolhido,
            id_tipo_mascara: this.mascaraEscolhida ? this.mascaraEscolhida : null,
            required: this.requirido,
            min_caracter: this.minCaracteres ? this.numeroCaracteres : null,
            tipo_opcoes: tipoOpcoes || [],
          });
          this.labelEscolhida = '';
          this.tipoEscolhido = '';
          this.mascaraEscolhida = '';
          this.opcoesRadio = [];
          this.opcoesSelect = [];
          this.requirido = false;
          this.minCaracteres = false;
          this.numeroCaracteres = '';
          this.snackbarOpen = true;
          this.tipoSnackbar = 'success';
          this.msg = 'Campo criado com sucesso';
        }
      } catch (error) {
        console.error(error);
      }
    },

    async postFluxo() {
      try {
        const body = {
          setor_id: this.setorEscolhido,
          categoria_id: this.categoriaEscolhida,
          subcategoria_id: this.subcategoriaEscolhida,
          nome: this.nomeFluxo,
          campos_fluxo: this.campoOptions,
        };

        if (this.campoOptions.length > 0) {
          const aviso = {
            snackbarOpen: true,
            tipoSnackbar: 'info',
            msg: 'Fluxo Criado com sucesso',
          };
          this.$emit('avisoCriaFluxo', aviso);
          await this.$http.post('/fluxos/', body);
          this.fechaModal();
        } else {
          this.snackbarOpen = true;
          this.tipoSnackbar = 'info';
          this.msg = 'Fluxo não foi criado';
        }
      } catch (error) {
        console.error(error);
      }
    },

    async clearCampo() {
      try {
        this.setorEscolhido = '';
        this.categoriaEscolhida = '';
        this.subcategoriaEscolhida = '';
        this.requirido = false;
        this.minCaracteres = false;
        this.numeroCaracteres = '';
        this.tipoEscolhido = '';
        this.opcoesRadio = [];
        this.opcoesSelect = [];
        this.labelEscolhida = '';
        this.mascaraEscolhida = '';
        this.nomeFluxo = '';
        this.campoOptions = [];
      } catch (error) {
        console.error(error);
      }
    },

    async addOpcaoSelect() {
      try {
        this.opcoesSelect.push(this.opcaoSelect);
        this.snackbarOpen = true;
        this.tipoSnackbar = 'success';
        this.msg = 'Opção adicionada ao Botão selecionável';
        this.opcaoSelect = '';
      } catch (error) {
        console.error(error);
      }
    },

    async addOpcaoRadio() {
      try {
        this.opcoesRadio.push(this.opcaoRadio);
        this.snackbarOpen = true;
        this.tipoSnackbar = 'success';
        this.msg = 'Opção adicionada ao Botão de Opções';
        this.opcaoRadio = '';
      } catch (error) {
        console.error(error);
      }
    },

    async deleteCampo(index) {
      try {
        this.campoOptions.splice(index, 1);
        this.snackbarOpen = true;
        this.tipoSnackbar = 'success';
        this.msg = 'Campo deletado com sucesso';
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

.principal {
  display: flex;
  font-size: 18px;
  padding: 5px;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: 10px;
}

.principal-buttons {
  display: flex;
  font-size: 18px;
  padding: 15px;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: 10px;
}

.principal-esquerda {
  display: flex;
  font-size: 18px;
  padding: 5px;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-right: 10px;
}

.camposFluxo {
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
}

.v-btn_add {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #ffffff !important;
  border-radius: 5px;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.v-btn_confirm {
  width: 150px;
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #ffffff !important;
  border-radius: 5px;
  font-size: 19px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.style-btn {
  margin: 11px !important;
}

.campo-padding {
  padding: 4px 0 0 0 !important;
}

.v-btn_clear {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #bfbfbf !important;
  color: #ffffff !important;
  border-radius: 5px;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}
</style>
