var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Snackbar',{attrs:{"label":_vm.msg,"top":"","right":"","tipo":_vm.tipoSnackbar,"timeout":2500},model:{value:(_vm.snackbarOpen),callback:function ($$v) {_vm.snackbarOpen=$$v},expression:"snackbarOpen"}}),_vm._m(0),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.fluxos,"items-per-page":5,"search":_vm.searchFluxo,"no-data-text":'Não há fluxos cadastrados.',"no-results-text":'Fluxo não encontrado.',"footer-props":{
  'items-per-page-text': 'Resultados por página',
  'items-per-page-all-text': 'Todos',
  'page-text': '{0}-{1} de {2}',
  }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"label":"Pesquise um Fluxo","outlined":"","clearable":""},model:{value:(_vm.searchFluxo),callback:function ($$v) {_vm.searchFluxo=$$v},expression:"searchFluxo"}},[(!_vm.searchFluxo)?_c('v-icon',{attrs:{"slot":"append"},slot:"append"},[_vm._v("mdi-account-search")]):_vm._e()],1),_c('v-btn',{staticClass:"v-btn_add",on:{"click":function($event){$event.stopPropagation();_vm.dialogCreator = true}}},[_vm._v("Adicionar novo")]),_c('v-dialog',{staticClass:"dialogProperty",attrs:{"retain-focus":false},model:{value:(_vm.dialogCreator),callback:function ($$v) {_vm.dialogCreator=$$v},expression:"dialogCreator"}},[_c('v-card',{staticClass:"px-2"},[_c('CriadorFluxos',{on:{"dialogChanged":_vm.atualizaDialog,"avisoCriaFluxo":_vm.emiteAvisoFluxo}})],1)],1)]},proxy:true},{key:"item.acoes",fn:function(ref){
  var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","dark":""},on:{"click":function($event){return _vm.editAtivados(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-dialog',{attrs:{"retain-focus":false,"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"px-2"},[_c('v-card-title',{staticClass:"headline"},[_vm._v("Atualizar Fluxo")]),_c('v-divider'),_c('v-card-actions',{staticClass:"pb-4"},[_c('v-spacer'),_c('Botao',{staticClass:"mr-4",attrs:{"type":"button","label":"Cancelar","tipo":"secondary","callback":_vm.closeModal}}),_c('v-btn',{staticClass:"btn primary",on:{"click":function($event){return _vm.desactivateFluxo(_vm.itemAtivado)}}},[_vm._v("Desativar Fluxo")])],1)],1)],1)]}}])}),_c('hr'),_vm._m(1),_c('v-data-table',{attrs:{"headers":_vm.headersDesativados,"items":_vm.fluxosDesativados,"items-per-page":5,"sort-by":['nome'],"no-data-text":'Não há fluxos desativados.',"footer-props":{
  'items-per-page-text': 'Resultados por página',
  'items-per-page-all-text': 'Todos',
  'page-text': '{0}-{1} de {2}',
  }},scopedSlots:_vm._u([{key:"item.funcoes",fn:function(ref){
  var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","dark":""},on:{"click":function($event){return _vm.editDesativados(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-dialog',{attrs:{"retain-focus":false,"max-width":"500px"},model:{value:(_vm.dialogDesativado),callback:function ($$v) {_vm.dialogDesativado=$$v},expression:"dialogDesativado"}},[_c('v-card',{staticClass:"px-2"},[_c('v-card-title',{staticClass:"headline"},[_vm._v("Atualizar Fluxo")]),_c('v-divider'),_c('v-card-actions',{staticClass:"pb-4"},[_c('v-spacer'),_c('Botao',{staticClass:"mr-4",attrs:{"type":"button","label":"Cancelar","tipo":"secondary","callback":_vm.closeModal}}),_c('v-btn',{staticClass:"btn primary",on:{"click":function($event){return _vm.activateFluxo(_vm.itemDesativado)}}},[_vm._v("Ativar Fluxo")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"titleFluxo"},[_c('h2',[_vm._v("Fluxos Ativados")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"titleFluxo"},[_c('h2',[_vm._v("Fluxos Desativados")])])}]

export { render, staticRenderFns }