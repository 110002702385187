<template>
  <div class="principal">
    <v-sheet
      class="mx-auto"
      max-width="2000"
      min-width="1070"
    >
      <div class="slider">
        <button
          class="botao-prev-next prev"
          @click="anterior"
        ><span class="material-icons button-prev-next">
            keyboard_arrow_left
          </span></button>
        <v-slide-item
          v-for="(item, index) in valoresMostrar"
          :key="index"
        >
          <v-card
            class="ma-2"
            height="350"
            width="400"
          >
            <!-- Alterar o valor do loader -->
            <v-overlay
              :value="loader"
              :absolute="true"
              :opacity="0.30"
            >
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
            <div ref="capture">
              <div class="cabecalho">
                <div class="span-nome">
                  <span>{{ item.nome }}</span>
                </div>
                <div class="div-botao-download">
                  <button
                    class="botao-download"
                    @click="screenShot(index)"
                  ><span class="
              material-icons">
                      get_app
                    </span>
                  </button>
                </div>
              </div>
              <div class="tempo-medio-indicador">
                <div class="div-tempo-medio">
                  <div class="titulo-tempo-medio">
                    <span>
                      Tempo médio de Atendimento
                    </span>
                  </div>
                  <div class="div-relogio">
                    <span :class="{'material-icons verde' : corVerde(item.dias),
                    'material-icons azul' : corAzul(item.dias),
                    'material-icons vermelho' : corVermelho(item.dias)}">
                      watch_later
                    </span>
                    <span class="span-tempo-medio">
                      {{ item.dias }} dia(s)
                    </span>
                  </div>
                </div>
                <div class="div-avaliacao">
                  <div class="span-avaliacao">
                    <span>Avaliação</span>
                  </div>
                  <apexchart
                    type="bar"
                    :options="chartOptionsAvaliacao"
                    :series="seriesAvaliacao(item)"
                    width="180"
                    height="120"
                  ></apexchart>
                </div>
              </div>
              <div class="tempo-medio-indicador">
                <div class="div-tempo-medio">
                  <div class="titulo-tempo-medio">
                    <span>
                      Atendimentos pendentes
                    </span>
                  </div>
                  <div class="div-atendimentos">
                    <span class="material-icons">
                      person
                    </span>
                    <span class="span-atendimentos">
                      {{ item.pendente }}
                    </span>
                  </div>
                </div>
                <div class="div-avaliacao">
                  <div class="span-avaliacao">
                    <span>Atendimentos (no periodo)</span>
                  </div>
                  <apexchart
                    type="bar"
                    :options="chartOptionsAtendimentos"
                    :series="seriesAtendimento(item)"
                    width="180"
                    height="85"
                  ></apexchart>
                </div>
              </div>
            </div>
          </v-card>
        </v-slide-item>
        <button
          class="botao-prev-next next"
          @click="proximo"
        ><span class="material-icons button-prev-next">
            keyboard_arrow_right
          </span></button>
      </div>
      <!-- </v-slide-group> -->
    </v-sheet>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import VueTypes from 'vue-types';
import screenShot from '../../mixins/screenShot.js';
import nomeIniciais from '../../mixins/nomeIniciais.js';

export default {
  name: 'IndicadorCardAreas',

  components: {
    apexchart: VueApexCharts,
  },
  mixins: [screenShot, nomeIniciais],
  props: {
    valores: VueTypes.array.isRequired,
    loader: VueTypes.bool.isRequired,
  },
  data() {
    return {
      chartOptionsAvaliacao: {
        chart: {
          type: 'bar',

          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
            startingShape: 'flat',
            endingShape: 'rounded',
            barHeight: '60%',
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'middle',
          style: {
            fontSize: '13px',
            fontWeight: 'bold',
          },
          formatter: function formatador(val) {
            return `${val}%`;
          },
        },
        grid: {
          show: false,
        },
        xaxis: {
          type: 'category',
          categories: ['Insatisfeito', 'Neutro', 'Satisfeito', 'Não avaliado'],
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            align: 'left',
            style: {
              fontSize: '12px',
              fontFamily: 'Open Sans',
              fontWeight: 'bold',
            },
          },
        },
        tooltip: {
          y: {
            title: {
              formatter: () => 'Avaliações(s)',
            },
          },
        },
        legend: {
          show: false,
        },
        colors: ['#eb453d', '#ef7f3c', '#97c93d', '#0000FF'],
      },
      chartOptionsAtendimentos: {
        chart: {
          type: 'bar',

          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
            startingShape: 'flat',
            endingShape: 'rounded',
            barHeight: '60%',
            barWidth: 10,
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'middle',
          style: {
            fontSize: '13px',
            fontWeight: 'bold',
          },
        },
        grid: {
          show: false,
        },
        xaxis: {
          type: 'category',
          categories: ['Encerrados', 'Reabertos'],
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            align: 'left',
            style: {
              fontSize: '12px',
              fontFamily: 'Open Sans',
              fontWeight: 'bold',
            },
          },
        },
        tooltip: {
          y: {
            title: {
              formatter: () => 'Atendimento(s)',
            },
          },
        },
        legend: {
          show: false,
        },
        colors: ['#0056a8'],
      },
      valoresMostrar: [],
      valoresIndex: 0,
      numCardsPassar: 3,
    };
  },

  mounted() {
    this.getValoresMostrar();
  },

  watch: {
    valores() {
      this.getValoresMostrar();
    },
  },

  methods: {
    corVerde(dias) {
      return (dias <= 2);
    },
    corAzul(dias) {
      return (dias >= 3);
    },
    corVermelho(dias) {
      return (dias >= 5);
    },
    seriesAvaliacao(item) {
      return [
        {
          data: item.avaliacao,
        },
      ];
    },

    seriesAtendimento(item) {
      return [
        {
          data: item.atendimento,
        },
      ];
    },

    proximo() {
      for (let i = 0; i < this.numCardsPassar; i += 1) {
        if (this.valoresIndex < (this.valores.length - 1)) {
          this.valoresIndex += 1;
        } else {
          this.valoresIndex = 0;
        }
      }
      this.getValoresMostrar();
    },

    anterior() {
      for (let i = 0; i < this.numCardsPassar; i += 1) {
        if (this.valoresIndex > 0) {
          this.valoresIndex -= 1;
        } else {
          this.valoresIndex = this.valores.length - 1;
        }
      }
      this.getValoresMostrar();
    },

    getValoresMostrar() {
      let segundoIndex;
      let terceiroIndex;
      if (this.valoresIndex >= (this.valores.length - 1)) {
        segundoIndex = 0;
        terceiroIndex = 1;
      } else if (this.valoresIndex === (this.valores.length - 2)) {
        segundoIndex = this.valoresIndex + 1;
        terceiroIndex = 0;
      } else {
        segundoIndex = this.valoresIndex + 1;
        terceiroIndex = this.valoresIndex + 2;
      }

      this.valoresMostrar = [this.valores[this.valoresIndex],
        this.valores[segundoIndex],
        this.valores[terceiroIndex]];
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application .grey.lighten-1 {
  background-color: #fff !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.v-application .grey.lighten-1[data-v-a0b322dc] {
  border-radius: 15px;
}

.principal {
  .slider {
    display: flex;
    width: 95%;

    .botao-prev-next {
      display: flex;
      margin-left: 70px;
      align-items: center;
      justify-content: center;
      background-color: #0056a8;
      border-radius: 20px;
      height: 40px;
      z-index: 1;

      &:hover {
        transition: all 0.3s;
        background-color: #5091ce;
      }

      &.next {
        margin-left: -30px;
      }

      &.prev {
        margin-right: -30px;
      }
    }

    .material-icons {
      color: #fff;

      &.button-prev-next {
        font-size: 40px;
      }
    }
  }
  .cabecalho {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    margin-top: 25px;
    margin-left: 20px;
    max-width: 80%;

    .div-botao-download {
      margin-left: 15px;
      .botao-download {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        border: 2px solid #eeeeee;

        &:hover {
          background-color: #e2dede;
        }

        .material-icons {
          color: #0056a8;
          font-size: 25px;
        }
      }
    }

    .avatar {
      .img-avatar {
        height: 45px;
      }
    }

    .span-nome {
      font-size: 21px;
      font-weight: bold;
    }
  }

  .tempo-medio-indicador {
    display: flex;
    width: 100%;
    margin-left: 20px;

    .div-tempo-medio {
      width: 40%;
      font-weight: 700;

      .titulo-tempo-medio {
        margin-bottom: 10px;
      }
    }

    .div-avaliacao {
      .span-avaliacao {
        margin-left: 10px;
        font-weight: 700;
      }
    }
  }

  .div-relogio {
    display: flex;
    align-items: center;

    .material-icons {
      margin-right: 10px;

      &.verde {
        color: #97c93d;
      }

      &.azul {
        color: #017cee;
      }

      &.vermelho {
        color: #eb453d;
      }
    }

    .span-tempo-medio {
      font-size: 15px;
    }
  }

  .div-atendimentos {
    display: flex;
    align-items: center;

    .material-icons {
      color: #0056a8;
    }

    .span-atendimentos {
      margin-left: 5px;
      font-size: 15px;
    }
  }

  .avatar-iniciais {
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.12);
  color: white;
  font-size: 22px;
  font-weight: 500;
  background: #0056a8;
  z-index: 31;
  top: 0px;
  border-radius: 50%;
  transition: all 0.3s;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
}
</style>
