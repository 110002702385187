<template>
  <div>
    <v-row class="indicadores">
      <h1 class="my-5 ml-6 font-weight-bold body-0">Performance de Atendentes da Área</h1>
      <!-- Quantidade de atendimentos ATRIBUIDO(S), ENCERRADO(S), REABERTO(S) por Atendente -->
      <v-col lg="12">
        <IndicadorAtendimentosPorAtendenteFiltro
          v-if="exibeIndicadorAtendimentosPorAtendenteFiltro"
          :nomeAtendente="nomeAtendenteFiltro"
          :valores="abertosEncerradosReabertosFiltro"
          :key="updateAtendimentosPorAtendenteFiltro"
          :loaderAtendimentosPorAtendenteFiltro="loaderAtendimentosPorAtendenteFiltro"
        />
      </v-col>
      <!-- Média(%) de SATISFAÇÃO por Atendente por dia  -->
      <v-col lg="12">
        <IndicadorSatisfacaoAtendentePorMes
          v-if="exibePorcentagemAtendimentosAntendente"
          :diasTratados="diasTratadosAntende"
          :valores="atendimentosPorcentagemAtendente"
          :key="updateAtendimentosPorcentagemAtendente"
          :loaderAtendimentosPorcentagemAtendente="loaderAtendimentosPorcentagemAtendente"
        />
      </v-col>
      <!-- Quantidade de atendimentos ATRIBUIDO(S) por Atendente por dia  -->
      <v-col lg="12">
        <IndicadorAtendimentosPorAtendente
          v-if="exibeIndicadorAtendimentosAntendente"
          :valores="atendimentosPorAtendente"
          :dias="diasTratadosAno"
          :key="updateAtendimentosAtendente"
          :loaderAtendimentosPorAtendente="loaderAtendimentosPorAtendente"
        />
      </v-col>
      <!-- Cards dos atendentes -->
      <v-row>
        <v-col lg="12">
          <IndicadorAtendimentoPorAtendente
            v-if="exibeIndicadorPorAtendente"
            :valores="atendimentoPorAtendente"
            :key="updateAtendimentosPorAtendente"
            :loader="loaderPorAtendente"
          />
        </v-col>
      </v-row>
    </v-row>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import IndicadorAtendimentosPorAtendenteFiltro from './IndicadorAtendimentosPorAtendenteFiltro';
import IndicadorSatisfacaoAtendentePorMes from './IndicadorSatisfacaoAtendentePorMes';
import IndicadorAtendimentosPorAtendente from './IndicadorAtendimentosPorAtendente';
import IndicadorAtendimentoPorAtendente from './IndicadorAtendimentoPorAtendente';

export default {
  name: 'IndicadorPerformanceAtendentes',
  components: {
    IndicadorAtendimentosPorAtendenteFiltro,
    IndicadorSatisfacaoAtendentePorMes,
    IndicadorAtendimentosPorAtendente,
    IndicadorAtendimentoPorAtendente,
  },

  props: {
    dadosFiltro: VueTypes.object.isRequired,
  },
  watch: {
    dadosFiltro(val) {
      this.populaDados(val);
    },
  },

  mounted() {
    this.populaDados(this.dadosFiltro);
  },

  data() {
    return {
      // Quantidade de atendimentos ATRIBUIDO(S), ENCERRADO(S), REABERTO(S) por Atendente
      exibeIndicadorAtendimentosPorAtendenteFiltro: false,
      nomeAtendenteFiltro: [],
      abertosEncerradosReabertosFiltro: [],
      updateAtendimentosPorAtendenteFiltro: 0,
      loaderAtendimentosPorAtendenteFiltro: false,

      // Média(%) de SATISFAÇÃO por Atendente por mês
      exibePorcentagemAtendimentosAntendente: false,
      diasTratadosAntende: [],
      atendimentosPorcentagemAtendente: [],
      updateAtendimentosPorcentagemAtendente: 0,
      loaderAtendimentosPorcentagemAtendente: false,

      // Quantidade de atendimentos ATRIBUIDO(S) por Atendente por dia
      exibeIndicadorAtendimentosAntendente: false,
      atendimentosPorAtendente: [],
      diasTratadosAno: [],
      updateAtendimentosAtendente: 0,
      loaderAtendimentosPorAtendente: false,

      // Cards dos atendentes
      atendentes: [],
      selectedSetores: [],
      exibeIndicadorPorAtendente: false,
      atendimentoPorAtendente: [],
      updateAtendimentosPorAtendente: 0,
      loaderPorAtendente: false,
    };
  },
  created() {
    this.getAtendentes();
  },

  methods: {
    populaDados(dados) {
      this.selectedSetores = dados.setores;

      this.indicadorAtendimentosPorAtendenteFiltro(dados);
      this.indicadorPorcentagemAtendimentosPorAtendenteMes(dados);
      this.indicadorAtendimentosPorAtendente(dados);
      this.IndicadorPorAtendente(dados);
    },

    listCreator(listaKeyValue) {
      const listaKey = [];
      const listaValue = [];

      listaKeyValue.forEach((item) => {
        listaKey.push(item.nome);
        listaValue.push(item.count);
      });

      return [listaKey, listaValue];
    },

    async getAtendentes() {
      try {
        const { data: response } = await this.$http.get('/atendentes');
        this.atendentes = response;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorAtendimentosPorAtendenteFiltro(dadosFiltro) {
      try {
        this.loaderAtendimentosPorAtendenteFiltro = true;
        const { data: response } = await this.$http.post('/indicadores/atendente-aberto-reaberto-fechado', dadosFiltro);

        this.abertosEncerradosReabertosFiltro = [
          response.atribuidos, response.encerrados, response.reabertos];
        this.nomeAtendenteFiltro = response.nomes;

        this.exibeIndicadorAtendimentosPorAtendenteFiltro = true;
        this.updateAtendimentosPorAtendenteFiltro += 1;
        this.loaderAtendimentosPorAtendenteFiltro = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorPorcentagemAtendimentosPorAtendenteMes(dadosFiltro) {
      try {
        this.loaderAtendimentosPorcentagemAtendente = true;
        const { data: response } = await this.$http.post('/indicadores/satisfacao-por-dia', dadosFiltro);

        this.diasTratadosAntende = response.days;
        this.atendimentosPorcentagemAtendente = response.valores;

        this.exibePorcentagemAtendimentosAntendente = true;
        this.updateAtendimentosPorcentagemAtendente += 1;
        this.loaderAtendimentosPorcentagemAtendente = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorAtendimentosPorAtendente(dadosFiltro) {
      try {
        this.loaderAtendimentosPorAtendente = true;

        const { data: response } = await this.$http.post('/indicadores/atendimentos-atribuidos-por-atendente-dia', dadosFiltro);

        this.atendimentosPorAtendente = response.valores;
        this.diasTratadosAno = response.days;

        this.exibeIndicadorAtendimentosAntendente = true;
        this.updateAtendimentosAtendente += 1;
        this.loaderAtendimentosPorAtendente = false;
      } catch (error) {
        console.error(error);
      }
    },

    async IndicadorPorAtendente(dadosFiltro) {
      this.loaderPorAtendente = true;
      const { data: response } = await this.$http.post('/indicadores/atendimentos-por-card-atendente', dadosFiltro);

      const atendentes = this.atendentes.filter(
        atendente => this.selectedSetores.includes(atendente.setor_id),
      );
      const retorno = [];
      atendentes.forEach(async (atendente) => {
        const dias = response.media.tempoMedia.filter(
          item => item.usuario_atendente_id === atendente.id,
        );
        const avaliacoes = response.avaliacoes.filter(
          item => item.usuario_atendente_id === atendente.id,
        );
        let encerrados = response.encerrados.filter(
          item => item.usuario_atendente_id === atendente.id,
        );
        let reabertos = response.reabertos.filter(
          item => item.usuario_atendente_id === atendente.id,
        );
        let pendentes = response.pendentes.filter(
          item => item.usuario_atendente_id === atendente.id,
        );
        if (encerrados.length) {
          encerrados = encerrados[0].count;
        } else {
          encerrados = '0';
        }
        if (reabertos.length) {
          reabertos = reabertos[0].count;
        } else {
          reabertos = '0';
        }
        if (pendentes.length) {
          pendentes = pendentes[0].count;
        } else {
          pendentes = '0';
        }
        let avatar;
        if (atendente.avatar_id) {
          avatar = await this.$http.get(`/busca-avatar/${atendente.avatar_id}`);
          avatar = avatar.data;
        } else {
          avatar = null;
        }
        retorno.push({
          nome: atendente.nome,
          avatar,
          dias: dias.length ? dias[0].valor : 0,
          avaliacao: avaliacoes.length ? [avaliacoes[0].insatisfeito,
            avaliacoes[0].neutro, avaliacoes[0].satisfeito, avaliacoes[0].naoAvaliar] : ['0', '0', '0', '0'],
          atendimento: [encerrados, reabertos],
          pendente: pendentes,
        });
      });
      this.atendimentoPorAtendente = retorno;

      /* ----------------------------------- */
      this.exibeIndicadorPorAtendente = true;
      this.updateAtendimentosPorAtendente += 1;
      this.loaderPorAtendente = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.indicadores {
  display: flex;
  justify-content: center;
  max-width: 1250px;
}
</style>
