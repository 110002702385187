import momentTz from 'moment-timezone';
import moment from 'moment';

export default {
  formatDate(value) {
    return momentTz(value)
      .utc('America/Sao_Paulo')
      .format('DD/MM/YYYY [às] HH:mm');
  },
  formatAdonisDate(value) {
    return moment(value, 'YYYY-MM-DD HH:mm:ss')
      .utc('America/Sao_Paulo')
      .tz('America/Sao_Paulo')
      .format('DD/MM/YYYY [às] HH:mm');
  },
};
