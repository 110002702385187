<template>
  <div>
    <div>
      <editor-menu-bar
        :editor="editor"
        v-slot="{ commands, isActive }"
      >
        <div class="menu-bar">
          <v-btn
            icon
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
          >
            <v-icon>mdi-format-bold</v-icon>
          </v-btn>
          <v-btn
            icon
            :class="{ 'is-active': isActive.underline() }"
            @click="commands.underline"
          >
            <v-icon>mdi-format-underline</v-icon>
          </v-btn>
          <v-btn
            icon
            :class="{
            'is-active': isActive.table(),
            'displayOff' : tableFlag == 1,
            'displayOff' : table
            }"
            @click="() => createTable()"
          >
            <v-icon>mdi-table</v-icon>
          </v-btn>
          <span v-if="isActive.table()">
            <v-btn
              icon
              @click="() => deleteTable()"
            >
              <v-icon>mdi-table-remove</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="() => limitColumn('before')"
              :class="{'displayOff' : colsMax >= 6}"
            >
              <v-icon>mdi-table-column-plus-before</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="() => limitColumn('after')"
              :class="{'displayOff' : colsMax >= 6}"
            >
              <v-icon>mdi-table-column-plus-after</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="() => limitColumn('delete')"
              :class="{'displayOff' : colsMax == 0}"
            >
              <v-icon>mdi-table-column-remove</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="() => limitRow('before')"
              :class="{'displayOff' : rowsMax >= 9}"
            >
              <v-icon>mdi-table-row-plus-before</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="() => limitRow('after')"
              :class="{'displayOff' : rowsMax >= 9}"
            >
              <v-icon>mdi-table-row-plus-after</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="() => limitRow('delete')"
              :class="{'displayOff' : rowsMax == 0}"
            >
              <v-icon>mdi-table-row-remove</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="commands.toggleCellMerge"
            >
              <v-icon>mdi-table-merge-cells</v-icon>
            </v-btn>
          </span>
        </div>
      </editor-menu-bar>
      <editor-content
        class="editor__content"
        :editor="editor"
      />
    </div>
    <div
      v-if="isAtendente"
      class="suggestion-list"
      v-show="showSuggestions"
      ref="suggestions"
    >
      <template v-if="hasResults">
        <div
          v-for="(user, index) in filteredUsers"
          :key="user.id"
          class="suggestion-list__item"
          :class="{ 'is-selected': navigatedUserIndex === index }"
          @click="query ? selectUser(user.item) : selectUser(user)"
        >
          {{ user.name || user.item.name }}
        </div>
      </template>
      <div
        v-else
        class="suggestion-list__item is-empty"
      >
        Nenhuma atendente encontrada
      </div>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js';
import tippy, { sticky } from 'tippy.js';
import '@voerro/vue-tagsinput/dist/style.css';
import 'tippy.js/dist/tippy.css';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Bold,
  Underline,
  Placeholder,
  Mention,
} from 'tiptap-extensions';
import splitNome from '../mixins/splitNome.js';
import EventBus from '../plugins/EventBus';
import { getMaxPermission } from '../utils/utilities';

export default {
  name: 'EditorTexto',
  components: {
    EditorContent,
    EditorMenuBar,
  },
  mixins: [splitNome],
  props: {
    table: {
      required: false,
      type: Boolean,
      default: false,
    },
    mensagem: {
      type: String,
      required: false,
    },
    edited: {
      type: String,
      required: false,
    },
    tipoComentario: {
      required: true,
      type: Boolean,
      default: true,
    },
    setorConvidadoAtendimento: {
      required: true,
    },
  },
  data() {
    return {
      comentario: '',
      editor: new Editor({
        extensions: [
          new Table({
            resizable: true,
          }),
          new TableCell(),
          new TableHeader(),
          new TableRow(),
          new Bold(),
          new Underline(),
          new Mention({
            items: () => this.atendentes,
            onEnter: ({
              items, query, range, command, virtualNode,
            }) => {
              this.query = query;
              this.filteredUsers = items;
              this.suggestionRange = range;
              this.renderPopup(virtualNode);
              this.insertMention = command;
            },
            onChange: ({
              items, query, range, virtualNode,
            }) => {
              this.query = query;
              this.filteredUsers = items;
              this.suggestionRange = range;
              this.navigatedUserIndex = 0;
              this.renderPopup(virtualNode);
            },
            onExit: () => {
              this.query = null;
              this.filteredUsers = [];
              this.suggestionRange = null;
              this.navigatedUserIndex = 0;
              this.destroyPopup();
            },
            onKeyDown: ({ event }) => {
              if (event.key === 'ArrowUp') {
                this.upHandler();
                return true;
              }

              if (event.key === 'ArrowDown') {
                this.downHandler();
                return true;
              }

              if (event.key === 'Enter') {
                this.enterHandler();
                return true;
              }
              return false;
            },
            onFilter: (items, query) => {
              if (!query) {
                return items;
              }
              const fuse = new Fuse(items, {
                threshold: 0.2,
                keys: ['name'],
              });
              return fuse.search(query);
            },
          }),
          new Placeholder({
            emptyEditorClass: 'is-editor-empty',
            emptyNodeClass: 'is-empty',
            emptyNodeText: 'Digite aqui...',
            showOnlyWhenEditable: true,
            showOnlyCurrent: true,
          }),
        ],
        content: this.verificaDescricao(),
        onInit: () => {
          this.$store.commit('UPDATE_COMENTARIO', this.edited ? this.edited : '');
        },
        onUpdate: ({ getHTML }) => {
          this.comentario = getHTML();
          this.$store.commit('UPDATE_COMENTARIO', this.comentario);
        },
      }),
      tableFlag: 0,
      colsMax: 3,
      rowsMax: 3,
      atendentes: [],
      query: null,
      suggestionRange: null,
      filteredUsers: [],
      navigatedUserIndex: 0,
      insertMention: () => { },
    };
  },
  watch: {
    tipoComentario(value) {
      if (value) {
        document.querySelector('.ProseMirror').style.borderColor = '#bfbfbf';
      } else {
        document.querySelector('.ProseMirror').style.borderColor = '#006479';
      }
    },
    setorConvidadoAtendimento() {
      this.getUsuarioAtendente();
    },
  },
  mounted() {
    EventBus.$on('limparComentarioBus', (status) => {
      this.editor.clearContent(status);
      this.editor.focus();
      this.tableFlag = 0;
    });
    if (this.isAtendente) {
      this.getUsuarioAtendente();
    }
  },

  computed: {
    isAtendente() {
      return getMaxPermission(this.$store).id_permissao
        > this.$store.getters.listaPermissoes.colaborador;
    },
    hasResults() {
      return this.filteredUsers.length;
    },
    showSuggestions() {
      return this.query || this.hasResults;
    },
  },

  methods: {
    async getUsuarioAtendente() {
      try {
        const { data: response } = await this.$http.get('/atendentes');
        const { data: setorConvidado } = this.setorConvidadoAtendimento.id_atendimento ? await this.$http.get(`/convidados/${this.setorConvidadoAtendimento.id_atendimento}`) : [];

        const setores = [];
        if (setorConvidado) {
          setorConvidado.forEach(item => (
            setores.push(item.setor_id)
          ));
        }

        const retorno = response.filter(item => this.$store.getters.isUsuario.setor_id
          === item.setor_id || setores.includes(item.setor_id));

        this.atendentes = retorno.map(item => ({

          id: item.id,
          name: this.splitNome(item.nome),

        }));
      } catch (error) {
        console.error(error);
      }
    },
    upHandler() {
      this.navigatedUserIndex = ((this.navigatedUserIndex
        + this.filteredUsers.length) - 1) % this.filteredUsers.length;
    },
    downHandler() {
      this.navigatedUserIndex = (this.navigatedUserIndex + 1) % this.filteredUsers.length;
    },
    enterHandler() {
      const user = this.filteredUsers[this.navigatedUserIndex];
      if (user.item) {
        this.selectUser(user.item);
      } else {
        this.selectUser(user);
      }
    },
    selectUser(user) {
      this.insertMention({
        range: this.suggestionRange,
        attrs: {
          id: user.id,
          label: user.name,
        },
      });
      this.editor.focus();
    },
    renderPopup(node) {
      if (this.popup) {
        return;
      }
      this.popup = tippy('.page', {
        getReferenceClientRect: node.getBoundingClientRect,
        appendTo: () => document.body,
        interactive: true,
        sticky: true,
        plugins: [sticky],
        content: this.$refs.suggestions,
        trigger: 'mouseenter',
        showOnCreate: true,
        theme: 'dark',
        placement: 'top-start',
        inertia: true,
        duration: [400, 200],
      });
    },
    destroyPopup() {
      if (this.popup) {
        this.popup = null;
      }
    },

    createTable() {
      this.tableFlag = 1;
      this.colsMax = 3;
      this.rowsMax = 3;
      this.editor.commands.createTable({
        rowsCount: 3,
        colsCount: 3,
        withHeaderRow: false,
      });
    },
    deleteTable() {
      this.tableFlag = 0;
      this.editor.commands.deleteTable();
    },
    limitColumn(where) {
      if (where !== 'delete') {
        this.colsMax += 1;
      } else {
        this.colsMax -= 1;
        this.editor.commands.deleteColumn();
      }
      if (this.colsMax <= 6) {
        if (where === 'before') {
          this.editor.commands.addColumnBefore();
        }
        if (where === 'after') {
          this.editor.commands.addColumnAfter();
        }
      }
    },
    limitRow(where) {
      if (where !== 'delete') {
        this.rowsMax += 1;
      } else {
        this.rowsMax -= 1;
        this.editor.commands.deleteRow();
      }
      if (this.rowsMax <= 9) {
        if (where === 'before') {
          this.editor.commands.addRowBefore();
        }
        if (where === 'after') {
          this.editor.commands.addRowAfter();
        }
      }
    },
    verificaDescricao() {
      if (this.mensagem) {
        return this.mensagem;
      }
      return '';
    },
  },

  beforeDestroy() {
    this.destroyPopup();
    this.editor.destroy();
  },

};
</script>

<style lang="scss" scoped>
.is-active {
  background-color: #b1b1b157;
}
.menu-bar {
  width: 100%;
  padding: 3px;
  border: 1px solid #bfbfbf;
  border-radius: 9px 9px 0px 0px;
  box-shadow: 0 0 4.5px 0 rgba(0, 0, 0, 0.2);
}
.collorPrivate {
  border: 1px solid #f30606;
}
</style>

<style lang="scss">
.mention {
  background: rgba(black, 0.1);
  color: rgba(black, 0.6);
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
}
.mention-suggestion {
  color: rgba(black, 0.6);
}
.suggestion-list {
  top: 77px;
  left: 9px;
  position: absolute;
  min-width: 140px;
  max-width: 250px;
  max-height: 88px;
  overflow-y: auto !important;
  padding: 0.2rem;
  font-size: 0.8rem;
  font-weight: bold;
  border: 1px solid #bfbfbf;
  box-shadow: 0 0 4.5px 0 rgba(0, 0, 0, 0.2);
  &__no-results {
    padding: 0.2rem 0.5rem;
  }
  &__item {
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &.is-selected,
    &:hover {
      background-color: rgba(#303030, 0.2) !important;
    }
    &.is-empty {
      opacity: 0.5;
    }
  }
}

.displayOff {
  display: none !important;
}

div.v-timeline-item__body > div > div > div.v-card__text.pt-2.pb-1 {
  overflow-x: auto;
  table {
    width: 100%;
    overflow-x: auto;
    td,
    th {
      border: 1px solid #999;
      padding: 0 0.5rem;
      text-align: center;
    }
    strong {
      font-weight: bold;
    }
  }
}

.ProseMirror{
  width: 100%;
  min-height: 125px;
  border-width: 1px;
  border-style: solid;
  border-color: #006479;
  border-radius: 0px 0px 0px 0px;
  box-shadow: 0 0 4.5px 0 rgba(0, 0, 0, 0.2);
  padding: 5px 8px 0px 8px;
}
.ProseMirror-focused {
  outline-width: 1px;
  outline-style: solid;
  outline-color: #ffffff;
  box-shadow: 0 0 4.5px 0 rgba(0, 0, 0, 0.25);
}
.editor {
  &__content {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      margin: 0;
      overflow: hidden;
      td,
      th {
        min-width: 1em;
        border: 2px solid grey;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;
        > * {
          margin-bottom: 0;
        }
      }

      th {
        text-align: left;
      }

      strong {
        font-weight: bold;
      }

      .selectedCell:after {
        z-index: 2;
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(255, 200, 203, 0.4);
        pointer-events: none;
      }

      .column-resize-handle {
        position: absolute;
        right: -2px;
        top: 0;
        bottom: 0;
        width: 4px;
        z-index: 20;
        background-color: #adf;
        pointer-events: none;
      }
    }

    .tableWrapper {
      margin: 1em 0;
      overflow-x: auto;
    }

    .resize-cursor {
      cursor: ew-resize;
      cursor: col-resize;
    }

    *.is-empty:first-child::before {
      font-size: 16px;
      color: #574949;
      float: left;
      height: 0;
      pointer-events: none;
      content: attr(data-empty-text);
    }
  }
}
</style>
