<template>
  <div id="realocacao">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
        <h3 class="titleHeader mb-3">Fluxo de Realocação</h3>
      <v-row>
          <v-col>
            <h5>Atenção</h5>
            <p class="caption">Os campos com
              <span class="count-warning">*</span>
              são obrigatórios.</p>
          </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="nomeProfissional"
            label="Nome do profissional"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-select
            v-model="modalidadeContratacao"
            :items="modalidades"
            label="Modalidade de Contratação"
            outlined
            dense
          ></v-select>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-if="modalidadeContratacao === 'EPS'"
            v-model="nomeEmpresa"
            label="Nome da Empresa (Se EPS)"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <div class="caption count-warning"
        v-if="modalidadeContratacao === 'EPS'">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-select
            v-model="competenciaSelecionada"
            :items="competencias"
            label="Competência"
            outlined
            dense
          ></v-select>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="novoGerente"
            label="Novo Gerente"
            outlined
            dense
          >
          </v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="novoCentroCusto"
            label="Novo Centro de Custos"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="alocacao"
            :items="alocacoes"
            label="Alocação"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-mask="'###.###.###-##'"
            v-model="cpf"
            label="CPF do profissional"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

    </v-form>
  </div>
</template>

<script>
import moment from 'moment';
import { mask } from 'vue-the-mask';
import Snackbar from '../Snackbar';

export default {
  name: 'Realocacao.vue',
  components: { Snackbar },
  directives: { mask },
  data() {
    return {
      nomeProfissional: '',
      modalidadeContratacao: '',
      modalidades: ['EPS', 'CLT'],
      nomeEmpresa: '',
      competenciaSelecionada: '',
      competencias: ['competencia1', 'competencia2'],
      novoGerente: '',
      novoCentroCusto: '',
      alocacao: '',
      cpf: '',
      alocacoes: ['cliente', 'interno'],
      dadosFluxo: {},
      newDadosFluxo: {},
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },
  created() {
    moment.locale('pt-br');
    this.getCentrosCustos();
  },

  computed: {
    verificaCampos() {
      return (!!this.nomeProfissional
      && !!this.modalidadeContratacao
      && !!this.competenciaSelecionada
      && !!this.novoGerente
      && !!this.novoCentroCusto
      && !!this.alocacao
      && !!this.cpf);
    },
  },
  methods: {
    trataResposta(dadosFluxo) {
      const respostasModel = {};

      if (dadosFluxo.nomeProfissional) respostasModel.column_campo_1 = dadosFluxo.nomeProfissional;
      if (dadosFluxo.modalidadeContratacao) {
        respostasModel.column_campo_2 = dadosFluxo.modalidadeContratacao;
      }
      if (dadosFluxo.nomeEmpresa) respostasModel.column_campo_3 = dadosFluxo.nomeEmpresa;
      if (dadosFluxo.competenciaSelecionada) {
        respostasModel.column_campo_4 = dadosFluxo.competenciaSelecionada;
      }
      if (dadosFluxo.novoGerente) respostasModel.column_campo_5 = dadosFluxo.novoGerente;
      if (dadosFluxo.novoCentroCusto) respostasModel.column_campo_6 = dadosFluxo.novoCentroCusto;
      if (dadosFluxo.alocacao) respostasModel.column_campo_7 = dadosFluxo.alocacao;
      if (dadosFluxo.cpf) respostasModel.column_campo_8 = dadosFluxo.cpf;

      respostasModel.validation = true;
      this.newDadosFluxo = respostasModel;
    },

    enviaJSON() {
      if (!this.verificaCampos) {
        this.dadosFluxo = { validation: false };
        this.alerta = 'error';
        this.msg = 'Todos os campos com * são obrigatórios.';
        this.snackBarAlertaCampos = true;
      } else {
        this.dadosFluxo = {
          validation: true,
          nomeProfissional: this.nomeProfissional,
          modalidadeContratacao: this.modalidadeContratacao,
          nomeEmpresa: this.nomeEmpresa,
          competenciaSelecionada: this.competenciaSelecionada,
          novoGerente: this.novoGerente,
          novoCentroCusto: this.novoCentroCusto,
          alocacao: this.alocacao,
          cpf: this.cpf,
        };
        this.trataResposta(this.dadosFluxo);
        this.$emit('dadosFluxo', this.newDadosFluxo);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.count-warning {
  background-color: transparent;
  color: red;
}
</style>
