<template>
  <div id="direcionamentos">
    <Snackbar
      :label="msg"
      v-model="snackbarOpen"
      top
      right
      :tipo="tipoSnackbar"
      :timeout="2500"
    />
    <v-data-table
      :headers="headers"
      :items="redirecionamentos"
      :search="searchUser"
      :sort-by="['atendente_nome', 'sede_nome', 'categoria_nome', 'subcategoria_nome']"
      :items-per-page="5"
      :no-data-text="'Não há direcionamentos cadastrados.'"
      :no-results-text="'Usuário não encontrado.'"
      :footer-props="{
        'items-per-page-text': 'Resultados por página',
        'items-per-page-all-text': 'Todos',
        'page-text': '{0}-{1} de {2}',
    }"
    >
      <template v-slot:top>
        <v-text-field
          v-model="searchUser"
          label="Pesquise um direcionamento"
          outlined
          clearable
        >
          <v-icon
            v-if="!searchUser"
            slot="append"
          >mdi-account-search</v-icon>
        </v-text-field>
        <v-toolbar
          flat
          color="white"
        >
          <v-dialog
            v-model="dialog"
            :retain-focus="false"
            max-width="500px"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                class="v-btn_add"
                v-on="on"
              >Adicionar novo</v-btn>
            </template>
            <v-card class="px-2">
              <v-card-title class="headline">{{ formTitle }}</v-card-title>
              <v-divider></v-divider>
              <v-card-subtitle>
                <p v-if="permissaoGestor.id_permissao === gestorGeral" class="subtitle-1"
                >Selecione o setor que será criado o direcionamento</p>
                  <div v-if="permissaoGestor.id_permissao === gestorGeral">
                  <v-card-subtitle class="mt-1">
                <v-select
                  v-if="permissaoGestor.id_permissao === gestorGeral"
                  label="Setor"
                  v-model="setor"
                  :items="setores"
                  outlined
                  dense
                  @change="listaAtendentesInSetor"
                ></v-select>
              </v-card-subtitle>
                  </div>
              </v-card-subtitle>
              <v-card-subtitle class="mt-1" v-if="setor">
                <InputSelect
                  label="Responsável"
                  v-model="atendente"
                  :options="atendentesSetor"
                  :disabled="editedIndex === -1 ? false : true"
                />
              </v-card-subtitle>
              <v-card-subtitle class="subtitle-1" v-if="atendente">
                Responsável em férias?
                <v-radio-group
                  v-model="ferias"
                  row
                >
                  <v-radio
                    label="Sim"
                    :value="false"
                  ></v-radio>
                  <v-radio
                    label="Não"
                    :value="true"
                  ></v-radio>
                </v-radio-group>
              </v-card-subtitle>
              <v-card-subtitle class="subtitle-1" v-if="atendente">Selecione o tipo:
                <v-radio-group
                  v-model="tipo"
                  row
                  @change="resetTipo(tipo)"
                >
                <v-radio
                    label="Sede"
                    value="sede"
                  ></v-radio>
                  <v-radio
                    label="Categoria"
                    value="categoria"
                    @change="getCategorias"
                  ></v-radio>
                  <v-radio
                    label="Subcategoria"
                    value="subcategoria"
                    @change="getCategorias"
                  ></v-radio>
                </v-radio-group>
              </v-card-subtitle>
              <v-card-subtitle
                v-if="tipo === 'sede'"
                class="subtitle-1"
              >Selecione uma sede:
                <div
                  v-for="(item, index) in sedes"
                  :key="index"
                >
                  <v-checkbox
                    v-model="sede"
                    :label="item.nome"
                    :value="item.id"
                  ></v-checkbox>
                </div>
              </v-card-subtitle>
                <v-card-subtitle
                class="mt-1"
                v-if="tipo === 'categoria' || tipo === 'subcategoria'"
              >
                <v-select
                  label="Categoria"
                  v-model="categoria"
                  :items="categorias"
                  outlined
                  dense
                  @change="getSubcategoria"
                ></v-select>
                <v-card-subtitle
                v-if="categoria && tipo === 'subcategoria'"
                class="mt-1"
                >
                <v-select
                  v-if="categoria"
                  label="Subcategoria"
                  v-model="subcategoria"
                  :items="subcategorias"
                  outlined
                  dense
                ></v-select>
              </v-card-subtitle>
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-card-actions class="pb-4" v-if="atendente">
                <Botao
                  type="button"
                  label="Adicionar Sede"
                  :tipo="showSedeButton()"
                  :callback="isSedeExtra"
                />
              </v-card-actions>

                <v-card-subtitle
                v-if="sedeExtra"
                class="subtitle-1"
                 >Selecione uma sede:

                <div
                  v-for="(item, index) in sedes"
                  :key="index"
                >
                  <v-checkbox
                    v-model="sede"
                    :label="item.nome"
                    :value="item.id"
                  ></v-checkbox>
                </div>
            </v-card-subtitle>
              <v-card-actions class="pb-4">

                <v-spacer></v-spacer>
                <Botao
                  class="mr-4"
                  type="button"
                  label="Cancelar"
                  tipo="secondary"
                  :callback="closeModal"
                />
                <Botao
                  type="button"
                  label="Salvar"
                  :tipo="showSaveButton()"
                  :callback="saveDirecionamento"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogDelete"
            :retain-focus="false"
            scrollable
            max-width="500"
          >
            <v-card class="px-2">
              <v-card-title class="headline">Remover direcionamento</v-card-title>
              <v-card-subtitle class="mt-1">
                Deseja realmente remover o direcionamento?
              </v-card-subtitle>
              <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <Botao
                  class="mr-4"
                  type="button"
                  label="Cancelar"
                  tipo="secondary"
                  :callback="closeModal"
                />
                <v-btn
                  class="btn primary"
                  @click="deleteRedirecionamento(atendente, editedId, editedTipo)">Confirmar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.atendente_nome="{item}">
        {{ item.atendente_nome}}
      </template>
      <template v-slot:item.ativo="{item}">
        {{ item.ativo ? 'Não' : 'Sim' }}
      </template>
      <template v-slot:item.sede_nome="{item}">
        <span v-if="item.sede_nome">{{ item.sede_nome }} </span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.setor_nome="{item}">
        <span v-if="item.setor_nome">{{ item.setor_nome }} </span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.categoria_nome="{item}">
        <span v-if="item.categoria_nome">{{ item.categoria_nome }} </span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.subcategoria_nome="{item}">
        <span v-if="item.subcategoria_nome">{{ item.subcategoria_nome }} </span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.acoes="{item}">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              dark
              class="mr-2"
              v-on="on"
              @click.stop="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              dark
              v-on="on"
              @click.stop="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Remover</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Botao from './Botao.vue';
import InputSelect from './InputSelect.vue';
import Snackbar from './Snackbar.vue';
import { getMaxPermission } from '../utils/utilities';

export default {
  name: 'Direcionamentos',
  components: {
    Botao,
    InputSelect,
    Snackbar,
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedId: '',
      editedTipo: '',
      nomeCat: '',
      nomeSub: '',
      nomeSet: '',
      searchUser: '',
      atendente: '',
      sede: '',
      sedeExtra: false,
      tipo: null,
      ferias: true,
      msg: '',
      setor: '',
      categoria: '',
      subcategoria: '',
      snackbarOpen: false,
      tipoSnackbar: 'info',
      atendentesSetor: [],
      sedes: [],
      setores: [],
      categorias: [],
      subcategorias: [],
      redirecionamentos: [],
      gestor: this.$store.getters.listaPermissoes.gestor,
      gestorGeral: this.$store.getters.listaPermissoes.gestorgeral,
      permissaoGestor: getMaxPermission(this.$store),
      headers: [],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Adicionar direcionamento' : 'Atualizar direcionamento';
    },
    typeTitle() {
      return this.tipo === 'categoria' ? 'Selecione a categoria:' : 'Selecione a subcategoria:';
    },
    isSubcategoria() {
      return (this.tipo === 'subcategoria' && this.categoria && this.subcategoria);
    },
    isCategoria() {
      return (this.tipo === 'categoria' && this.categoria);
    },
    isSede() {
      return (this.tipo === 'sede' && this.sede);
    },
    isSetor() {
      return (this.setor);
    },
  },
  watch: {
    dialog(val) {
      if (this.permissaoGestor.id_permissao === this.$store.getters.listaPermissoes.gestor) {
        this.setor = this.permissaoGestor.id_setor;
        this.listaAtendentesInSetor();
      }
      return val || this.closeModal();
    },
  },
  mounted() {
    this.listaRedirecionamentos();
    this.verifyHeaders();
    this.listaAtendentesInSetor();
    this.listaSedes();
    this.listaSetores();
  },
  methods: {
    async listaAtendentesInSetor() {
      try {
        const { data: response } = await this.$http.get('/lista-por-permissao/2');
        this.atendentesSetor = response.map(item => ({
          label: this.splitNome(item.nome),
          value: item.id_usuario,
          setor_id: item.setor_id,
        }));
        if (this.permissaoGestor.id_permissao === this.$store.getters.listaPermissoes.gestorgeral) {
          this.atendentesSetor = this.atendentesSetor.filter(item => item.setor_id
          === this.setor);
        } else if (this.permissaoGestor.id_permissao
          === this.$store.getters.listaPermissoes.gestor) {
          this.atendentesSetor = this.atendentesSetor.filter(item => item.setor_id
          === this.permissaoGestor.id_setor);
        }
      } catch (error) {
        console.error(error);
      }
    },

    verifyHeaders() {
      if (this.permissaoGestor.id_permissao === this.$store.getters.listaPermissoes.gestorgeral) {
        this.headers = [
          { text: 'Responsável', value: 'atendente_nome' },
          { text: 'Em férias', value: 'ativo' },
          { text: 'Sede', value: 'sede_nome' },
          { text: 'Setor', value: 'setor_nome' },
          { text: 'Categoria', value: 'categoria_nome' },
          { text: 'Subcategoria', value: 'subcategoria_nome' },
          { text: 'Ações', value: 'acoes', sortable: false },
        ];
      } else if (this.permissaoGestor.id_permissao === this.$store.getters.listaPermissoes.gestor) {
        this.headers = [
          { text: 'Responsável', value: 'atendente_nome' },
          { text: 'Em férias', value: 'ativo' },
          { text: 'Sede', value: 'sede_nome' },
          { text: 'Categoria', value: 'categoria_nome' },
          { text: 'Subcategoria', value: 'subcategoria_nome' },
          { text: 'Ações', value: 'acoes', sortable: false },
        ];
      }
    },
    showSaveButton() {
      if (this.atendente) {
        if (this.isSubcategoria || this.isCategoria || this.isSede || this.isSetor) {
          return 'primary';
        }
      }
      return 'disabled';
    },
    showSedeButton() {
      if (this.atendente && this.tipo) {
        if (this.isSubcategoria || this.isCategoria || this.isSetor) {
          return 'primary';
        }
      }
      return 'disabled';
    },
    isSedeExtra() {
      this.sedeExtra = true;
    },
    resetTipo(value) {
      if (value === 'subcategoria') {
        this.categoria = '';
        this.sede = '';
        this.activeCat = null;
        this.inactiveCat = false;
        this.inactiveSub = false;
      } else {
        this.sede = '';
        this.categoria = '';
        this.subcategoria = '';
        this.activeCat = null;
        this.activeSub = null;
        this.inactiveCat = false;
        this.inactiveSub = false;
      }
    },
    closeModal() {
      this.atendente = '';
      this.tipo = null;
      this.sede = '';
      this.categoria = '';
      this.subcategoria = '';
      this.ferias = true;
      this.editedIndex = -1;
      this.dialog = false;
      this.dialogDelete = false;
      this.sedeExtra = false;
    },
    editItem(item) {
      this.editedIndex += 1;
      this.atendente = item.atendente_id;
      this.listaAtendentesInSetor();
      this.ferias = item.ativo;
      if (item.sede_id) {
        this.tipo = 'sede';
        this.editedId = item.sede_id;
        this.editedTipo = 'sede';
        this.sede = item.sede_id;
      }
      if (item.setor_id) {
        this.editedId = item.setor_id;
        this.editedTipo = 'setor';
        this.setor = item.setor_id;
      }
      if (item.categoria_id) {
        this.getCategorias();
        this.tipo = 'categoria';
        this.editedId = item.categoria_id;
        this.editedTipo = 'categoria';
        this.categoria = item.categoria_id;
      }
      if (item.subcategoria_id) {
        this.getSubcategoria();
        this.tipo = 'subcategoria';
        this.editedId = item.subcategoria_id;
        this.editedTipo = 'subcategoria';
        this.nomeSub = item.subcategoria_nome;
        this.subcategoria = item.subcategoria_id;
      }
      this.dialog = true;
    },
    deleteItem(item) {
      this.atendente = item.atendente_id;
      if (item.setor_id) {
        this.editedId = item.setor_id;
        this.editedTipo = 'setor';
      }
      if (item.sede_id) {
        this.editedId = item.sede_id;
        this.editedTipo = 'sede';
      }
      if (item.categoria_id) {
        this.editedId = item.categoria_id;
        this.editedTipo = 'categoria';
      }
      if (item.subcategoria_id) {
        this.editedId = item.subcategoria_id;
        this.editedTipo = 'subcategoria';
      }
      this.dialogDelete = true;
    },
    splitNome(nome) {
      let nomeFormatado = '';
      if (nome) {
        const arrayNome = nome.split(' ');
        nomeFormatado = `${arrayNome[0]} ${arrayNome[arrayNome.length - 1]}`;
      }
      return nomeFormatado;
    },
    snackbarType(tipo) {
      this.tipoSnackbar = tipo;
      this.snackbarOpen = true;
    },
    async listaRedirecionamentos() {
      try {
        const { data: response } = await this.$http.get('/regras-redirecionamento');
        const listaTratada = this.setSetorInItem(response);
        this.redirecionamentos = listaTratada.map(item => ({
          atendente_id: item.atendente_id,
          atendente_nome: this.splitNome(item.atendente[0].nome),
          setor_nome: item.setor_nome,
          setor_id: item.setor_id,
          categoria_id: item.categoria.length < 1 ? '' : item.categoria[0].id,
          categoria_nome: item.categoria.length < 1 ? '' : item.categoria[0].nome,
          subcategoria_id: item.subcategoria.length < 1 ? '' : item.subcategoria[0].id,
          subcategoria_nome: item.subcategoria.length < 1 ? '' : item.subcategoria[0].nome,
          sede_id: item.sede.length < 1 ? '' : item.sede[0].id,
          sede_nome: item.sede.length < 1 ? '' : item.sede[0].nome,
          ativo: item.ativo,
        }));
        if (this.permissaoGestor.id_permissao
          === this.$store.getters.listaPermissoes.gestor) {
          this.redirecionamentos = this.redirecionamentos.filter(value => value.setor_id
          === this.permissaoGestor.id_setor);
        }
      } catch (error) {
        console.error(error);
      }
    },
    setSetorInItem(element) {
      return element.map((item) => {
        let setorNome;
        this.setores.forEach((setor) => {
          if (setor.value === item.setor_id) setorNome = setor.text;
        });
        return { ...item, setor_nome: setorNome };
      });
    },
    filtraCategorias(categorias) {
      const categoriasSetor = categorias.filter(
        categoria => categoria.setor_id === this.setor && categoria.desativado === false,
      );
      return categoriasSetor;
    },
    async listaSedes() {
      try {
        const { data: response } = await this.$http.get('/sedes');
        this.sedes = response;
      } catch (error) {
        console.error(error);
      }
    },
    async listaSetores() {
      try {
        const { data: response } = await this.$http.get('/setores');
        this.setores = response.map(item => ({
          value: item.id,
          text: item.nome,
        }));
        if (this.permissaoGestor.id_permissao
          === this.$store.getters.listaPermissoes.gestor) {
          this.setores = response.filter(item => item.value === this.permissaoGestor.id_setor);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getCategorias() {
      try {
        const { data: response } = await this.$http.get('/categorias');
        this.categorias = this.filtraCategorias(response);
        this.categorias = this.categorias.map(item => ({
          value: item.id,
          text: item.nome,
          setor_id: item.setor_id,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async getSubcategoria() {
      try {
        this.subcategoria = '';
        const { data: response } = await this.$http.get(`/subcategorias/${this.categoria}`);
        this.subcategorias = response.map(item => ({
          value: item.id,
          text: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async saveDirecionamento() {
      if (this.editedIndex > -1) {
        try {
          const body = {
            atendente_id: this.atendente,
            sede_id: this.sede,
            categoria_id: this.categoria,
            subcategoria_id: this.subcategoria,
            ativo: this.ferias,
            tipo: this.tipo,
            setor_id: this.setor,
          };
          await this.$http.put(`/regras-redirecionamento/${this.atendente}/${this.editedId}/${this.editedTipo}`, body);
          this.closeModal();
          this.msg = 'Direcionamento atualizado com sucesso!';
          this.snackbarType('success');
          this.listaRedirecionamentos();
        } catch (error) {
          this.msg = 'Favor preencher todos os campos!';
          this.snackbarType('error');
          console.error(error);
        }
      } else {
        try {
          const body = {
            atendente_id: this.atendente,
            sede_id: this.sede,
            categoria_id: this.categoria,
            subcategoria_id: this.subcategoria,
            ativo: this.ferias,
            setor_id: this.setor,
          };
          const { data: response } = await this.$http.post(`/regras-redirecionamento/${this.tipo}`, body);
          if (response.error) {
            this.msg = 'Responsável já alocado na sede.';
            this.snackbarType('error');
          } else {
            this.closeModal();
            this.msg = 'Direcionamento atribuído com sucesso!';
            this.snackbarType('success');
            this.listaRedirecionamentos();
          }
        } catch (error) {
          this.msg = 'Favor preencher todos os campos!';
          this.snackbarType('error');
          console.error(error);
        }
      }
    },
    async deleteRedirecionamento(atendente, editedId, editedTipo) {
      try {
        await this.$http.delete(`/regras-redirecionamento/${atendente}/${editedId}/${editedTipo}`);
        this.closeModal();
        this.msg = 'Direcionamento removido com sucesso!';
        this.snackbarType('success');
        this.listaRedirecionamentos();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn_add {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #ffffff !important;
  border-radius: 5px;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.v-btn_cat {
  color: #303030;
  letter-spacing: 0;
  text-transform: none;
  border: 1px solid rgba(86, 197, 201, 0.5);
  box-shadow: none;
  transition: all 0.5s ease;
  &:hover {
    color: #ffffff !important;
    background-color: #0056a8 !important;
    border-color: #0056a8;
  }
  &.active {
    color: #fff !important;
    background-color: #0056a8 !important;
    border-color: #0056a8 !important;
  }
  &.inactive {
    display: none;
  }
}

.v-icon {
  margin-top: 2px;
}
.v-input--selection-controls {
  margin-top: 4px;
  padding-top: 4px;
  height: 30px;
}

.v-dialog > .v-card > .v-card__title {
  padding: 16px 24px 0px;
}
</style>
