<template>
  <div id="formRealocacao">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
        <h3 class="titleHeader mb-3">Fluxo de Formularizar pedido</h3>

      <v-row>
        <v-col cols="4">
          <v-autocomplete
            v-model="nomeProfissional"
            label="Nome do profissional"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-select
            v-model="modalidadeContratacao"
            :items="modalidades"
            label="Modalidade de Contração"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-select>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-if="modalidadeContratacao === 'EPS'"
            v-model="nomeEmpresa"
            label="Nome da Empresa (Se EPS)"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-select
            v-model="competenciaSelecionada"
            :items="competencias"
            label="Competência"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-select>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="novoGerente"
            label="Novo Gerente"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="novoCentroCusto"
            label="Novo Centro de Custos"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="alocacao"
            :items="alocacoes"
            label="Alocação"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-mask="'###.###.###-##'"
            v-model="cpf"
            label="CPF do profissional"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-btn
        :class="{ 'btn-primary-disabled ml-3': isStatusEncerrado,
                   'btn-primary ml-3': !isStatusEncerrado }"
        @click="toggleEdit"
        :disabled="isStatusEncerrado">
        <span v-if="editar">
          <v-icon left>mdi-pencil</v-icon>Editar
        </span>
        <span v-else>
          <v-icon left>mdi-close</v-icon>Cancelar
        </span>
      </v-btn>

      <v-btn
        v-if="!editar"
        class="btn-primary ml-2"
        @click="salvarFluxoEditado"
      >
        <v-icon left>mdi-content-save</v-icon>Salvar
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import moment from 'moment';
import { VMoney } from 'v-money';
import { mask } from 'vue-the-mask';
import VueTypes from 'vue-types';
import { getMaxPermission } from '../../utils/utilities';
import Snackbar from '../Snackbar';

export default {
  name: 'FormFormularizarPedido.vue',
  components: { Snackbar },
  directives: { money: VMoney, mask },
  props: {
    camposFluxo: VueTypes.array,
    fluxoId: VueTypes.integer,
    atendimentoId: VueTypes.integer,
    statusAtendimento: VueTypes.string,
  },
  data() {
    return {
      nomeProfissional: '',
      modalidadeContratacao: '',
      modalidades: ['EPS', 'CLT'],
      nomeEmpresa: '',
      competenciaSelecionada: '',
      competencias: ['competencia1', 'competencia2'],
      novoGerente: '',
      novoCentroCusto: '',
      alocacao: '',
      cpf: '',
      editar: true,
      permissaoMax: getMaxPermission(this.$store),
      usuarioPerm: this.$store.getters.listaPermissoes.colaborador,
      newDadosFluxo: {},
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
      caixaFalsa: false,
    };
  },
  created() {
    moment.locale('pt-br');
  },

  mounted() {
    this.loadInfoCamposFluxo();
  },

  computed: {
    isStatusEncerrado() {
      return this.statusAtendimento === 'Encerrado';
    },
  },
  methods: {
    loadInfoCamposFluxo() {
      this.nomeProfissional = this.camposFluxo[0].resposta;
      this.modalidadeContratacao = this.camposFluxo[1].resposta;
      this.nomeEmpresa = this.camposFluxo[2] ? this.camposFluxo[2].resposta : '';
      this.competenciaSelecionada = this.camposFluxo[3].resposta;
      this.novoGerente = this.camposFluxo[4].resposta;
      this.novoCentroCusto = this.camposFluxo[5].resposta;
      this.alocacao = this.camposFluxo[6].resposta;
      this.cpf = this.camposFluxo[7].resposta;
    },

    async salvarFluxoEditado() {
      this.toggleEdit();

      this.alerta = 'success';
      this.msg = 'Formulário editado com sucesso!';
      this.snackBarAlertaCampos = true;
      const body = {
        id: this.atendimentoId,
        campos_fluxo: {
          column_campo_1: this.nomeProfissional,
          column_campo_2: this.pmodalidadeContratacao,
          column_campo_3: this.nomeEmpresa,
          column_campo_4: this.competenciaSelecionada,
          column_campo_5: this.novoGerente,
          column_campo_6: this.novoCentroCusto,
          column_campo_7: this.alocacao,
          column_campo_8: this.cpf,
        },
        responsavel: this.$store.getters.isUsuario.id,
        fluxo_id: this.fluxoId,
      };
      await this.$http.put('/atendimentos/editar/dados-fluxo', body);
    },

    toggleEdit() {
      this.editar = !this.editar;
      this.disabled = !this.disabled;
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

.btn-primary {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border: 1px solid #0056a8 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.btn-primary-disabled {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.count-warning {
  background-color: transparent;
  color: red;
}
</style>
