<template>
  <v-autocomplete
    :items="options"
    v-model="selected"
    :label="label"
    item-text="label"
    outlined
    dense
    color="primary"
    :no-data-text="noDataText"
    :disabled="disabled"
  >
    <template
      slot="selection"
      slot-scope="options"
    >
      <div class="circular-wrapper">
        <CircularStatus
          v-if="options.item.status"
          :status="options.item.status"
          :square="options.item.square"
          :size="options.item.size"
        />
      </div>
      {{options.item.label}}
    </template>
    <template
      slot="item"
      slot-scope="options"
    >
      <div class="circular-wrapper pl-3">
        <CircularStatus
          v-if="options.item.status"
          :status="options.item.status"
          :square="options.item.square"
          :size="options.item.size"
        />
      </div>
      {{options.item.label}}
    </template>
  </v-autocomplete>
</template>

<script>
import isArray from 'lodash/isArray';
import CircularStatus from './CircularStatus.vue';

export default {
  name: 'InputSelect',
  components: {
    CircularStatus,
  },
  data() {
    return {
      selected: isArray(this.value) ? [] : '',
    };
  },
  props: {
    /** v-model used on component element */
    value: {
      required: true,
    },
    /** message to display when component doesn't have options */
    noDataText: {
      required: false,
      default: 'Nenhuma opção encontrada',
    },
    //* * set true if input is disabled */
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    /** Label to be displayed on select */
    label: {
      type: String,
      required: false,
    },
    /** Array with options */
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  watch: {
    selected(selected) {
      this.$emit('input', selected);
    },
    value(value) {
      this.selected = value;
    },
  },
  mounted() {
    this.selected = this.value;
  },
};
</script>
<style lang="scss" scoped>
.circular-wrapper {
  margin-right: 10px;
}
</style>

<style lang="scss">
html .v-input {
  width: 100%;
}
html .v-select > .v-input__control > .v-input__slot {
  margin-bottom: 0;
}
html .v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}
html .v-select__selections {
  font-size: 14px;
}
</style>
