<template>
  <div class="bg">
    <Snackbar
      :label="msg"
      v-model="snackbarOpen"
      top
      right
      :timeout="2500"
      tipo="error"
    />
    <Snackbar
      :label="msg"
      v-model="snackbarAviso"
      top
      right
      :timeout="7000"
      tipo="warn"
    />
    <v-row>
      <v-col
        xs="12"
        sm="12"
        md="6"
        class="logo"
      >
        <img
          src="../assets/img/Meta-Conecta-2.png"
          alt="Meta"
        />
        <p class="meta-labs">Feito com <v-icon
            small
            color="pink"
          >mdi-heart</v-icon> por Meta Labs.</p>
      </v-col>
      <v-col
        xs="12"
        sm="12"
        md="6"
        class="login"
      >
        <v-card class="mx-auto">
          <v-card-title class="justify-center flex-column">
            <img
              src="../assets/img/logoMeta3.svg"
              alt="logo"
            />
            <p class="mt-5">
              <strong>Bem-vindo!</strong>
              <br />
              Faça seu login.
            </p>
          </v-card-title>
          <v-card-actions>
            <v-form @submit.prevent="checkForm">
              <v-text-field
                v-model="login.usuario"
                label="Digite seu login"
                required
                outlined
              ></v-text-field>
              <v-text-field
                class="mt-3"
                v-model="login.senha"
                :append-icon="mostrarSenha ? 'mdi-eye' : 'mdi-eye-off'"
                :type="mostrarSenha ? 'text' : 'password'"
                label="Digite sua senha"
                outlined
                @click:append="mostrarSenha = !mostrarSenha"
              >
              </v-text-field>
              <v-card-text>
                <Botao
                  :styles="btnFullWidth"
                  label="Entrar"
                  type="submit"
                  tipo="azulMeta"
                />
              </v-card-text>
            </v-form>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line
import Botao from '../components/Botao.vue';
import Snackbar from '../components/Snackbar';
import { getPermissionsList } from '../utils/utilities';

export default {
  name: 'login',
  components: {
    Botao,
    Snackbar,
  },
  data() {
    return {
      msg: '',
      snackbarOpen: false,
      mostrarSenha: false,
      validaLogin: false,
      login: {
        usuario: null,
        senha: null,
      },
      usuario: {
        id: '',
        nome: '',
        usuario: '',
        sede_id: '',
        sede_nome: '',
        permissao: [],
        avatar_id: '',
        avatar: '',
      },
      btnFullWidth: {
        width: '100%',
      },
      contadorTentativas: 0,
      snackbarAviso: false,
      imgUrl: '',
    };
  },
  mounted() {
    this.$store.commit('UPDATE_LOADING', false);
  },

  methods: {
    async logar() {
      try {
        this.$store.commit('UPDATE_USUARIO', this.usuario);
        this.$store.commit('UPDATE_LOGIN', true);
        if (this.usuario.avatar_id) {
          await this.buscaAvatarBanco();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async checkForm(e) {
      if (this.login.usuario && this.login.senha) {
        this.$store.commit('UPDATE_LOADING', true);
        await this.entrar();
      }

      if (!this.login.usuario && !this.login.senha) {
        this.msg = 'Todos os campos são obrigatórios.';
        this.snackbarOpen = true;
      } else if (!this.login.usuario) {
        this.msg = 'O usuário é obrigatório.';
        this.snackbarOpen = true;
      } else if (!this.login.senha) {
        this.msg = 'A senha é obrigatória.';
        this.snackbarOpen = true;
      }

      e.preventDefault();
    },
    trataUsuario(usuario) {
      const arrayUser = usuario.split('@');
      if (arrayUser[1] === 'meta.com.br') {
        return arrayUser[0];
      }
      return usuario;
    },
    async entrar() {
      try {
        const body = {
          username: await this.trataUsuario(this.login.usuario),
          password: this.login.senha,
        };
        const { data: response } = await this.$http.post('/login', body);
        localStorage.setItem('usuario', JSON.stringify(response));
        this.contadorTentativas = 0;
        this.snackbarAviso = false;
        if (response) {
          this.usuario = response.usuario;
          const { data: res } = await this.$http.get(`/usuariohaspermissoes/${this.usuario.id}`);
          this.usuario.permissao = getPermissionsList(res);
          await this.logar();
          if (this.$route.params && this.$route.params.fromPagina) {
            this.$router.push(this.$route.params.fromPagina);
          } else {
            this.$router.push('/lista');
          }
        }
      } catch (e) {
        console.error(e);
        this.validaLogin = true;
        this.msg = 'Usuário ou senha inválidos.';
        this.snackbarOpen = true;
        this.$store.commit('UPDATE_LOADING', false);
        this.validarTentativas();
      }
    },
    async buscaAvatarBanco() {
      try {
        const { data: response } = await this.$http.get(`/busca-avatar/${this.$store.getters.isUsuario.avatar_id}`);
        this.$store.commit('UPDATE_USUARIO_AVATAR', response);
      } catch (error) {
        console.error(error);
      }
    },

    validarTentativas() {
      this.contadorTentativas += 1;

      if (this.contadorTentativas === 1) {
        this.msg = 'Caso você seja um usuário Netrin ou MetaIT insira @netrin.com.br ou @metait.ca após o seu nome de usuário. Exemplo: UserNetrin@netrin.com.br ou UserTI@metait.ca';
        this.snackbarAviso = true;
      }

      if (this.contadorTentativas >= 3) {
        this.msg = 'Foi detectado 3 ou mais tentativas de login. Caso sua senha tenha expirado Copie e cole em seu navegador https://sts.meta.com.br/adfs/portal/updatepassword.    Se o problema persistir contate a TI através do e-mail infra@meta.com.br. ';
        this.snackbarAviso = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
html,
body {
  height: 100%;
  margin: 0;
}

html .v-snack {
  top: 1px;
  right: 1px;
}

.bg {
  background-image: url("../assets/img/loginDP.png") !important;

  /* Full height */
  height: 100vh;

  /* Center and scale the image nicely */
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.col-md-6 {
  padding: 16px 16px 0px 16px;
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.logo > img {
  height: auto;
  width: 850px;
}

.meta-labs {
  color: #ffffff;
}

.meta-labs > .v-icon {
  color: #ffffff;
}

.v-card {
  width: 500px;
  height: 575px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 20px 20px 0px 0px;
}

.v-card__title {
  padding: 0px 8px 8px 8px;
}

.v-card__title > p {
  font-size: 24px;
  text-align: center !important;
}

.v-card__title > img {
  height: auto;
  width: 230px;
}

.v-card__actions {
  justify-content: center;
}

.v-form {
  width: 400px;
}

.v-card__text {
  padding: 16px 0px 0px 0px;
}


.aviso-login{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
  border: solid #122870 1px;
  border-radius: 5px;
  padding: 5px;
  margin-top: 20px;
  margin-bottom: -75px;
}

@media (min-width: 321px) and (max-width: 767px) {
  .bg {
    height: 75vh;
  }
  .logo {
    padding-top: 16px;
  }
  .logo > img {
    height: auto;
    width: 175px;
  }
  .v-card {
    width: auto;
    height: 450px;
  }
  .v-card__title > img {
    height: auto;
    width: 175px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .bg {
    height: 100vh;
  }
  .logo {
    padding-top: 16px;
  }
  .logo > img {
    height: auto;
    width: 250px;
  }
  .v-card {
    width: auto;
    height: 475px;
  }
}
</style>
