<template>
  <v-card
    class="mb-5"
    outlined
  >
    <v-card-title>Atendimento iniciado</v-card-title>
    <v-card-text>
      {{reatribuido[0].atendimento.descricao}}
    </v-card-text>
    <div class="footer-card">
      <p>{{reatribuido[0].created_at}}</p>
      <v-card-actions class="pa-4">
        <Botao
          class="mr-4 mt-4"
          type="button"
          label="Recusar"
          tipo="secondary"
          :callback="cancelaReatribuicao"
        />
        <Botao
          class="mt-4"
          type="button"
          label="Aceitar"
          :callback="aceitaReatribuicao"
        />
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import VueTypes from 'vue-types';
import Botao from './Botao.vue';
import { status } from '../Constants.js';
import Notify from '../mixins/Notify';

export default {
  name: 'CardComentario',
  components: {
    Botao,
  },
  props: {
    reatribuido: VueTypes.array.isRequired.def(['reatribuido']),
    idSolicitante: VueTypes.number.isRequired,
  },
  mixins: [Notify],
  data() {
    return {
      btn: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingLeft: '10px',
        paddingRight: '10px',
        margin: '10px',
        fontSize: '14px !important',
      },
    };
  },

  methods: {
    async cancelaReatribuicao() {
      try {
        await this.$http.put(
          `/redistribui/${this.reatribuido[0].id}`,
          {
            id_atendente_origem: this.reatribuido[0].id_atendente_destino,
            id_atendente_destino: this.reatribuido[0].id_atendente_origem,
            id_atendimento: this.reatribuido[0].id_atendimento,
            status: 'recusado',
          },
        );
        this.notificaSolicitante('recusado');
        this.$router.go();
      } catch (error) {
        console.error(error);
      }
    },

    async aceitaReatribuicao() {
      try {
        await this.$http.put(
          `/redistribui/${this.reatribuido[0].id}`,
          {
            id_atendente_origem: this.reatribuido[0].id_atendente_destino,
            id_atendente_destino: this.reatribuido[0].id_atendente_origem,
            id_atendimento: this.reatribuido[0].id_atendimento,
            status: 'aceito',
          },
        );

        const mudaStatus = {
          status_id: status.emAtendimento,
          id_usuario: this.reatribuido[0].atendimento.usuario_id,
          id_atendimento: this.reatribuido[0].atendimento.id,
          tipo_alteracao: 'STATUS',
          valor_antigo: this.reatribuido[0].atendimento.status_id,
          valor_novo: status.emAtendimento,
          flagRedistribuido: true,
        };
        await this.$http.put(`/atendimentos/${this.reatribuido[0].id_atendimento}`, mudaStatus);

        const body = {
          usuario_atendente_id: this.reatribuido[0].id_atendente_destino,
          id_usuario: this.reatribuido[0].atendimento.usuario_id,
          id_atendimento: this.reatribuido[0].id_atendimento,
          tipo_alteracao: 'ATRIBUICAO',
          valor_antigo: this.reatribuido[0].id_atendente_origem,
          valor_novo: this.reatribuido[0].id_atendente_destino,
        };
        await this.$http.put(`/atendimentos/${this.reatribuido[0].id_atendimento}`, body);
        this.notificaSolicitante('aceito');
        this.$router.go();
      } catch (error) {
        console.error(error);
      }
    },
    notificaSolicitante(valor) {
      const dados = {
        id_usuario: this.reatribuido[0].id_atendente_origem,
        id_usuario_responsavel: this.$store.getters.isUsuario.id,
        id_atendimento: this.$route.params.id,
        resposta: valor,
      };
      this.emiteNotificacao('respostaSolicitacao', dados);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application p {
  margin-bottom: 0px;
}

.theme--light.v-card > .v-card__text {
  color: #303030;
}

.v-card__title {
  font-size: 14px;
  font-weight: bold;
  color: #303030;
  background-color: #c1c1c1;
  line-height: 1rem;
  padding: 8px;
}

.v-card__text {
  padding: 8px;
}

.footer-card {
  display: flex;
  justify-content: space-between;
  color: rgba(48, 48, 48, 0.5);
  font-size: 12px;
  text-align: start;
  & p {
    padding: 8px;
    margin: 0;
  }
}

.v-card__subtitle {
  margin-top: 0px !important;
  padding: 8px 8px 2px 8px !important;
  color: #303030 !important;
  font-weight: 600;
}
</style>
