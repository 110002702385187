<template>
  <div id="comprasTecnicasInternacionais">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
        <h3 class="titleHeader mb-3">Fluxo de Compras Técnicas - Internacionais</h3>
      <v-row>
          <v-col>
            <h5>Atenção</h5>
            <p class="caption">Os campos com
              <span class="count-warning">*</span>
              são obrigatórios.</p>
          </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-autocomplete
            class="mb-1 alert-fild-required"
            v-model="empresa"
            :items="empresas"
            label="Empresa"
            outlined
            dense
          ></v-autocomplete>
        </v-col>

        <v-col cols="4">
          <v-autocomplete
            class="mb-1 alert-fild-required"
            v-model="unidade"
            :items="unidades"
            label="Centro / Unidade"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-autocomplete
            class="mb-1 alert-fild-required"
            v-model="fornecedorSelecionado"
            :items="fornecedores"
            label="Escolha um Fornecedor"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2">
          <v-autocomplete
            class="mb-2 alert-fild-required"
            v-model="material"
            :items="materiais"
            label="Material/Serviços"
            outlined
            dense
          ></v-autocomplete>
        </v-col>

        <v-col cols="2">
          <v-autocomplete
            class="mb-2 alert-fild-required"
            v-model="cc"
            :items="centrosCustos"
            label="Centro de custo"
            outlined
            dense
          >
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-textarea
            class="mb-1 alert-fild-required"
            v-model="endereco"
            label="País do Fornecedor"
            outlined
            dense
            auto-grow
            rows="2"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-textarea
            class="mb-1 alert-fild-required"
            v-model="descricaoServico"
            label="Descrever o serviço realizado pelo fornecedor do exterior"
            outlined
            dense
            auto-grow
            rows="2"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
         <v-radio-group
            v-model="servicoClienteOUEmpresa"
            row
            label="O serviço será repassado ao cliente ou utilizado internamente na empresa?"
          >
            <v-radio
              label="Sim"
              :value="'Sim'"
            ></v-radio>
            <v-radio
              label="Não"
              :value="'Não'"
            ></v-radio>
            <div class="caption count-warning">*</div>
          </v-radio-group>
        </v-col>


        <v-col cols="5" v-if="checkIfIsRepassClientOrInterCompany">
          <v-textarea
            class="mb-1 alert-fild-required"
            v-model="empresaFaturadaPedido"
            label="Em qual empresa será faturado o pedido?"
            outlined
            dense
            auto-grow
            rows="2"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-menu
            v-model="menuDataVencimento"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mb-1 alert-fild-required"
                v-model="dataFormatadaVencimento"
                label="Qual o vencimento?"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dataVencimento"
              @input="menuDataVencimento = false"
              :first-day-of-week="0"
              locale="pt-br"
              :min="minDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="7">
          <v-textarea
            class="mb-1 alert-fild-required"
            v-model="compraRecorenteQtMesesFrequenciaTotal"
            label="Indicar se será uma compra recorrente e quantos meses (frequência total):"
            outlined
            dense
            auto-grow
            rows="2"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-radio-group
            v-model="formaPagamento"
            row
            label="Qual será a forma de pagamento:"
          >
            <v-radio
              label="Boleto"
              :value="'Boleto'"
            ></v-radio>
            <v-radio
              label="Cartão de Crédito"
              :value="'Cartão de Crédito'"
            ></v-radio>
            <v-radio
              label="Câmbio pagamento na conta do fornecedor no exterior"
              :value="'Câmbio pagamento na conta do fornecedor no exterior'"
            ></v-radio>
            <div class="caption count-warning">*</div>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-autocomplete
            class="mb-1 alert-fild-required"
            v-model="tipoMoeda"
            :items="moedas"
            label="Qual a moeda do pedido?"
            outlined
            dense
          ></v-autocomplete>
        </v-col>

        <v-col cols="3">
          <v-text-field
            class="mb-1 alert-fild-required"
            v-money="decimal"
            v-model="valorCompraMoedaEstrangeira"
            label="Valor da Compra em MOEDA ESTRANGEIRA?"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            class="mb-1 alert-fild-required"
            v-money="money"
            v-model="valorCompraMoedaReal"
            label="Valor da Compra em MOEDA NACIONAL (Reais)?"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="8">
          <v-text-field
            v-model="urlNF"
            label="Adicionar o URL (link) da NF.
              Caso possua apenas o anexo, adicionar no campo de mensagens."
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-icon>mdi-alert</v-icon>
          <p class="caption"><strong>Atenção: </strong>Informações adicionais,
          utilizar o campo de <strong>mensagem.</strong></p>
        </v-col>
      </v-row>

    </v-form>
  </div>
</template>

<script>
import moment from 'moment';
import { VMoney } from 'v-money';
import { mask } from 'vue-the-mask';
import Snackbar from '../Snackbar';

export default {
  name: 'comprasTecnicasInternacionais.vue',
  components: { Snackbar },
  directives: { money: VMoney, mask },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
      decimal: {
        decimal: ',',
        precision: 2,
      },
      empresa: '',
      empresas: [
        'Meta Sales SC',
        'Meta Consulting Services MC',
        'Meta',
        'Netrin',
        'Meta Ventures Part Ltda',
        '91M Cons. Gestão Ltda',
        'MGSA Consultoria Ltda',
        'WORBI Gestão Ltda',
        'WTCPAR Participações Ltda',
      ],

      unidade: '',
      unidades: ['Carregando...'],

      fornecedorSelecionado: '',
      fornecedores: ['Carregando...'],

      material: '',
      materiais: ['Carregando...'],

      cc: '',
      centrosCustos: ['Carregando...'],

      objetoCentroCusto: [],
      aprovadoresList: [],

      endereco: '',

      descricaoServico: '',

      servicoClienteOUEmpresa: '',
      empresaFaturadaPedido: '',

      dataVencimento: '',
      minDate: new Date().toISOString().substr(0, 10),
      menuDataVencimento: false,

      compraRecorenteQtMesesFrequenciaTotal: '',

      formaPagamento: '',

      tipoMoeda: '',
      valorCompraMoedaEstrangeira: '',
      valorCompraMoedaReal: '',
      moedas: ['USD|DOLAR DOS EUA', 'EUR|EURO', 'GBP|LIBRA ESTERLINA', 'CAD|DOLAR CANADENSE', 'AED|DIRHAM/EMIR.ARABE',
        'AFN|AFEGANE AFEGANIST', 'ALL|LEK ALBANIA REP', 'AMD|DRAM ARMENIA REP', 'ANG|GUILDER ANTILHAS HOLANDESAS',
        'AOA|KWANZA/ANGOLA', 'ARS|PESO ARGENTINO', 'AUD|DOLAR AUSTRALIANO', 'AWG|FLORIM/ARUBA', 'AZN|MANAT ARZEBAIJAO',
        'BAM|MARCO CONV BOSNIA', 'BBD|DOLAR/BARBADOS', 'BDT|TACA/BANGLADESH  ', 'BGN|LEV/BULGARIA, REP', 'BHD|DINAR/BAHREIN',
        'BIF|FRANCO/BURUNDI', 'BMD|DOLAR/BERMUDAS', 'BND|DOLAR BRUNEI', 'BOB|BOLIVIANO/BOLIVIA', 'BSD|DOLAR/BAHAMAS', 'BTN|NGULTRUM/BUTAO',
        'BWP|PULA/BOTSWANA', 'BYN|RUBLO BELARUS', 'BZD|DOLAR/BELIZE', 'CDF|FRANCO CONGOLES', 'CHF|FRANCO SUICO', 'CLF|UNIDADE DE FOMENTO DO CHILE',
        'CLP|PESO CHILE', 'CNH|RENMINBI HONG KONG', 'CNY|RENMINBI CHINES', 'COP|PESO/COLOMBIA', 'COU|UNIDADE DE VALOR REAL',
        'CRC|COLON/COSTA RICA ', 'CUP|PESO/CUBA', 'CVE|ESCUDO CABO VERDE', 'CZK|COROA TCHECA', 'DJF|FRANCO/DJIBUTI', 'DKK|COROA DINAMARQUESA',
        'DOP|PESO/REP. DOMINIC', 'DZD|DINAR ARGELINO', 'EGP|LIBRA/EGITO', 'ERN|NAKFA DA ERITREIA', 'ETB|BIRR/ETIOPIA', 'FJD|DOLAR FIJI',
        'FKP|LIBRA/FALKLAND', 'GEL|LARI GEORGIA', 'GHS|CEDI GANA', 'GIP|LIBRA DE GIBRALTAR', 'GMD|DALASI/GAMBIA', 'GNF|FRANCO/GUINE',
        'GTQ|QUETZAL/GUATEMALA', 'GYD|DOLAR DA GUIANA', 'HKD|DOLAR HONG KONG', 'HNL|LEMPIRA/HONDURAS', 'HRK|KUNA DA CROACIA',
        'HTG|GOURDE HAITI', 'HUF|FORINT/HUNGRIA', 'IDR|RUPIA/INDONESIA', 'ILS|SHEKEL/ISRAEL', 'INR|RUPIA/INDIA', 'IQD|DINAR/IRAQUE',
        'IRR|RIAL/IRAN, REP', 'ISK|COROA ISLND/ISLAN', 'JMD|DOLAR/JAMAICA', 'JOD|DINAR/JORDANIA', 'JPY|IENE', 'KES|XELIM/QUENIA',
        'KGS|SOM DO QUIRGUISTAO', 'KHR|RIEL/CAMBOJA', 'KMF|FRANCO COMORES', 'KPW|WON/COREIA NORTE', 'KRW|WON COREIA SUL', 'KWD|DINAR/KWAIT',
        'KYD|DOLAR CAYMAN', 'KZT|TENGE CAZAQUISTAO', 'LAK|QUIPE/LAOS, REP', 'LBP|LIBRA/LIBANO', 'LKR|RUPIA/SRI LANKA', 'LRD|DOLAR LIBERIA',
        'LSL|LOTI/LESOTO', 'LYD|DINAR/LIBIA', 'MAD|DIRHAM/MARROCOS', 'MDL|LEU/MOLDAVIA, REP', 'MGA|ARIARY MADAGASCAR', 'MKD|DINAR/MACEDONIA',
        'MMK|QUIATE/BIRMANIA', 'MNT|TUGRIK/MONGOLIA', 'MOP|PATACA/MACAU', 'MRO|UGUIA MAURITANIA', 'MRU|UGUIA MAURITANIA',
        'MUR|RUPIA/MAURICIO', 'MVR|RUFIA/MALDIVAS', 'MWK|QUACHA/MALAVI', 'MXN|PESO/MEXICO', 'MYR|RINGGIT/MALASIA', 'MZN|NOVA METICAL/MOCA',
        'NAD|DOLAR DA NAMIBIA', 'NGN|NAIRA NIGERIA', 'NIO|CORDOBA OURO', 'NOK|COROA NORUEGUESA', 'NPR|RUPIA/NEPAL', 'NZD|DOLAR NOVA ZELANDIA',
        'OMR|RIAL/OMA', 'PAB|BALBOA/PANAMA', 'PEN|NOVO SOL/PERU', 'PGK|KINA DE PAPUA NOVA GUINE', 'PHP|PESO/FILIPINAS', 'PKR|RUPIA/PAQUISTAO',
        'PLN|ZLOTY/POLONIA', 'PYG|GUARANI/PARAGUAI', 'QAR|RIAL/CATAR', 'RON|NOVO LEU DA ROMENIA', 'RSD|DINAR SERVIO SERVIA', 'RUB|RUBLO/RUSSIA',
        'RWF|FRANCO/RUANDA', 'SAR|RIAL/ARAB SAUDITA', 'SBD|DOLAR DAS ILHAS SALOMAO', 'SCR|RUPIA SEYCHELES', 'SDG|NOVA LIBRA SUDANESA',
        'SDR|DIREITO ESPECIAL', 'SEK|COROA SUECA', 'SGD|DOLAR CINGAPURA', 'SHP|LIBRA DE SANTA HELENA', 'SLL|LEONE/SERRA LEOA ', 'SOS|XELIM/SOMALIA',
        'SRD|DOLAR DO SURINAME', 'SSP|LIBRA SUL SUDANESA', 'STN|DOBRA SÃO TOMÉ E PRÍNCIPE', 'SVC|COLON/EL SALVADOR', 'SYP|LIBRA/SIRIA, REP', 'SZL|LILANGENI/SUAZIL',
        'THB|BATH/TAILANDIA', 'TJS|SOMONI TADJIQUISTAO', 'TMT|NOVO MANAT TURCOMANO', 'TND|DINAR/TUNISIA', 'TOP|PAANGA/TONGA', 'TRY|LIRA TURCA',
        'TTD|DOLAR/TRIN. TOBAG', 'TWD|NOVO DOLAR/TAIWAN', 'TZS|XELIM DA TANZANIA', 'UAH|HRYVNIA UCRANIA', 'UGX|XELIM/UGANDA', 'UYU|PESO/URUGUAIO',
        'UZS|SOM UZBEQUISTAO', 'VES|BOLIVAR SOBERANO VENEZUELANO', 'VND|DONGUE/VIETNAN', 'VUV|VATU VANUATU', 'WST|TALA SAMOA',
        'XAF|FRANCO CFA BEAC', 'XCD|DOLAR CARIBE ORIENTAL', 'XEU|UNID.MONET.EUROP.', 'XOF|FRANCO CFA BCEAO', 'XPF|FRANCO CFP', 'YER|RIAL/IEMEN',
        'ZAR|RANDE/AFRICA SUL ', 'ZMW|QUACHA ZAMBIA', 'ZWL|DOLAR ZIMBABUE'],

      urlNF: '',

      dadosFluxo: {},
      newDadosFluxo: {},
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },
  async created() {
    await this.getCentrosUnidade();
    await this.getFornecedores();
    await this.getMateriais();
    await this.getCentrosCustos();
  },

  computed: {
    verificaCampos() {
      return (!!this.empresa
      && !!this.unidade
      && !!this.fornecedorSelecionado
      && !!this.material
      && !!this.endereco
      && !!this.descricaoServico
      && !!this.servicoClienteOUEmpresa
      && this.checkEmpresaFaturado
      && this.dataFormatadaVencimento
      && !!this.compraRecorenteQtMesesFrequenciaTotal
      && !!this.formaPagamento
      && !!this.tipoMoeda
      && this.checkValue(this.valorCompraMoedaEstrangeira)
      && this.checkValue(this.valorCompraMoedaReal)
      && this.checkURL);
    },

    checkIfIsRepassClientOrInterCompany() {
      return this.servicoClienteOUEmpresa === 'Sim';
    },

    dataFormatadaVencimento() {
      return this.dataVencimento ? moment(this.dataVencimento).format('DD/MM/YYYY') : '';
    },

    checkURL() {
      if (!this.urlNF) {
        return true;
      }

      if (this.urlNF) {
        return this.isURLValid(this.urlNF);
      }

      return false;
    },

    checkEmpresaFaturado() {
      return !this.checkIfIsRepassClientOrInterCompany ? true : !!this.empresaFaturadaPedido;
    },

  },
  methods: {
    isURLValid(string) {
      try {
        const isURLValid = new URL(string);
        return !!isURLValid;
      } catch (_) {
        return false;
      }
    },

    checkValue(valorTotal) {
      const result = parseFloat(valorTotal.replace('R$ ', '').replace('.', '').replace(',', '.'));
      return result > 0;
    },

    async getCentrosUnidade() {
      try {
        const { data: response } = await this.$http.get('/data-from-sap/centro-filiais');
        this.unidades = response.map(item => item.descricaoItens);
      } catch (error) {
        console.error(error);
      }
    },

    async getFornecedores() {
      try {
        const { data: response } = await this.$http.get('/data-from-sap/fornecedor');
        this.fornecedores = response.map(item => item.descricaoItens);
      } catch (error) {
        console.error(error);
      }
    },

    async getMateriais() {
      try {
        const { data: response } = await this.$http.get('/data-from-sap/material');
        this.materiais = response.map(item => item.descricaoItens);
      } catch (error) {
        console.error(error);
      }
    },

    async getCentrosCustos() {
      try {
        const { data: response } = await this.$http.get('/data-from-sap/centro-custo');
        this.centrosCustos = response.map(item => item.descricaoItens);
        this.objetoCentroCusto = response;
      } catch (error) {
        console.error(error);
      }
    },

    getDadosCentroCusto(centroCustoSelecionado) {
      try {
        this.objetoCentroCusto.filter((element) => {
          if (centroCustoSelecionado.includes(element.descricaoItens)) {
            this.aprovadoresList.push(element);
          }

          return this.aprovadoresList;
        });
      } catch (e) {
        console.error(e);
      }
    },

    inputValueInAprovadores(dadosFluxo) {
      const aprovadoresWithValue = dadosFluxo.aprovadoresList.map(item => ({
        ...item,
        valor: dadosFluxo.valorCompraMoedaReal,
      }));

      return aprovadoresWithValue;
    },

    trataResposta(dadosFluxo) {
      const respostasModel = {};

      if (dadosFluxo.empresa) respostasModel.column_campo_1 = dadosFluxo.empresa;
      if (dadosFluxo.unidade) respostasModel.column_campo_2 = dadosFluxo.unidade;
      if (dadosFluxo.fornecedor) respostasModel.column_campo_3 = dadosFluxo.fornecedor;
      if (dadosFluxo.material) respostasModel.column_campo_4 = dadosFluxo.material;
      if (dadosFluxo.cc) respostasModel.column_campo_5 = dadosFluxo.cc;
      if (dadosFluxo.endereco) respostasModel.column_campo_6 = dadosFluxo.endereco;
      if (dadosFluxo.descricaoServico) respostasModel.column_campo_7 = dadosFluxo.descricaoServico;
      if (dadosFluxo.servicoClienteOUEmpresa) {
        respostasModel.column_campo_8 = dadosFluxo.servicoClienteOUEmpresa;
      }
      if (dadosFluxo.empresaFaturadaPedido) {
        respostasModel.column_campo_9 = dadosFluxo.empresaFaturadaPedido;
      }
      if (dadosFluxo.dataFormatadaVencimento) {
        respostasModel.column_campo_10 = dadosFluxo.dataFormatadaVencimento;
      }
      if (dadosFluxo.compraRecorenteQtMesesFrequenciaTotal) {
        respostasModel.column_campo_11 = dadosFluxo.compraRecorenteQtMesesFrequenciaTotal;
      }
      if (dadosFluxo.formaPagamento) respostasModel.column_campo_12 = dadosFluxo.formaPagamento;
      if (dadosFluxo.tipoMoeda) respostasModel.column_campo_13 = dadosFluxo.tipoMoeda;
      if (dadosFluxo.valorCompraMoedaEstrangeira) {
        respostasModel.column_campo_14 = dadosFluxo.valorCompraMoedaEstrangeira;
      }
      if (dadosFluxo.valorCompraMoedaReal) {
        respostasModel.column_campo_15 = dadosFluxo.valorCompraMoedaReal;
      }
      if (dadosFluxo.urlNF) respostasModel.column_campo_16 = dadosFluxo.urlNF;


      respostasModel.validation = true;

      const aprovadoresWithValue = this.inputValueInAprovadores(dadosFluxo);
      respostasModel.aprovadoresList = aprovadoresWithValue;
      respostasModel.usuarioSolicitante = this.$store.getters.isUsuario.nome;

      this.newDadosFluxo = respostasModel;
    },

    enviaJSON() {
      if (!this.verificaCampos) {
        this.dadosFluxo = { validation: false };
        this.alerta = 'error';
        this.msg = 'Todos os campos com * são obrigatórios.';
        this.snackBarAlertaCampos = true;
      } else {
        this.getDadosCentroCusto(this.cc);
        this.dadosFluxo = {
          empresa: this.empresa,
          unidade: this.unidade,
          fornecedor: this.fornecedorSelecionado,
          material: this.material,
          cc: this.cc,
          endereco: this.endereco,
          descricaoServico: this.descricaoServico,
          servicoClienteOUEmpresa: this.servicoClienteOUEmpresa,
          empresaFaturadaPedido: this.empresaFaturadaPedido,
          dataFormatadaVencimento: this.dataFormatadaVencimento,
          compraRecorenteQtMesesFrequenciaTotal: this.compraRecorenteQtMesesFrequenciaTotal,
          formaPagamento: this.formaPagamento,
          tipoMoeda: this.tipoMoeda,
          valorCompraMoedaEstrangeira: this.valorCompraMoedaEstrangeira,
          valorCompraMoedaReal: this.valorCompraMoedaReal,
          urlNF: this.urlNF,

          aprovadoresList: this.aprovadoresList,
        };
        this.trataResposta(this.dadosFluxo);
        this.$emit('dadosFluxo', this.newDadosFluxo);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.alert-fild-required::after {
    content: "*";
    color: red;
    margin-bottom: 10px;
    margin-left: 6px;
    font-size: x-small;
}

.count-warning {
  background-color: transparent;
  color: red;
}
</style>
