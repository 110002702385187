<template>
  <div
    class="aprovacoes-sap"
  >
    <div>
      <h5>Solicitação:</h5>
    </div>

    <div>
      Centro de Custo: <strong>{{ centroCusto }}</strong>
      <br>
      <br>
      Primeiro Aprovador: <strong>{{ nomePrimeiroAprovador }}</strong><br>
      Primeiro Aprovador Status:
        <v-chip
          :color="statusMapColor[statusPrimeiroAprovador].color"
          :text-color="statusMapColor[statusPrimeiroAprovador].textColor"
          small>{{ statusPrimeiroAprovador }}
        </v-chip><br>
      <br>

      <div v-if="valueMoreThanFive()">
        Segundo Aprovador: <strong>{{ nomeSegundoAprovador }}</strong><br>
        Segundo Aprovador Status:
        <v-chip
          :color="statusMapColor[statusSegundoAprovador].color"
          :text-color="statusMapColor[statusSegundoAprovador].textColor"
          small>{{ statusSegundoAprovador }}
        </v-chip><br>
      </div>
    </div>

    <hr>

  </div>
</template>

<script>
import VueTypes from 'vue-types';
import currency from 'currency.js';


export default {
  name: 'ComprasEPS',

  props: {
    centroCusto: VueTypes.string.def('Não Identificado.'),
    valorCentroCusto: VueTypes.string.def('Não Identificado.'),
    nomePrimeiroAprovador: VueTypes.string.def('Nome não encontrado'),
    statusPrimeiroAprovador: VueTypes.string.def('pendente'),
    nomeSegundoAprovador: VueTypes.string.def('Nome não encontrado'),
    statusSegundoAprovador: VueTypes.string.def('pendente'),
  },


  data() {
    return {
      valor: this.valorCentroCusto,

      statusMapColor: {
        pendente: {
          color: null,
          textColor: null,
        },
        aprovada: {
          color: 'green',
          textColor: 'white',
        },
        recusada: {
          color: 'red',
          textColor: 'white',
        },
      },
    };
  },

  methods: {
    valueMoreThanFive() {
      const valorFormatado = currency(this.valor, { separator: '.', decimal: ',' });
      return valorFormatado > 5000;
    },

  },
};

</script>

<style>
</style>
