/* eslint-disable */
import Vue from 'vue';
import axios from 'axios';
import router from '../router/index';
import EventBus from './EventBus';
import store from '../store/index'

Vue.use(EventBus);
const vue = new Vue({});

const API_URL = process.env.VUE_APP_API_URL;
// const API_URL = 'http://localhost:3333';

// Add base URL to axios api communication
axios.interceptors.request.use(config => {

  const isGitlabCall = config.url.includes('https://gitlab.meta');
  if(!isGitlabCall){
    config.url = API_URL + config.url;
    let usuario = localStorage.getItem('usuario');
    if (usuario) {
      usuario = JSON.parse(usuario);
      config.headers.common['Authorization'] = `Bearer ${usuario.token}`;
    }
  }
  return config;
});

axios.interceptors.response.use(function (response) {


  return response;
}, function (error) {
  const { response: { status } } = error

  const usuario = JSON.parse(localStorage.getItem('usuario'));
  // const data = new Date();

  if (!usuario) {
    localStorage.removeItem('usuario');
    store.commit('UPDATE_USUARIO', null);
    store.commit('UPDATE_LOGIN', false);
    return router.push({ name: 'login' });
  }

  // if (status === 401 && !store.getters.callRefreshToken) {
  //   return refreshToken(usuario, data)
  // }
  if (status === 401) {
    localStorage.removeItem('usuario');
    store.commit('UPDATE_REFRESH_TOKEN', false);
    store.commit('UPDATE_USUARIO', null);
    store.commit('UPDATE_LOGIN', false);
    return router.push({ name: 'login' });
  }
  return Promise.reject(error)
});

async function refreshToken(usuario, data) {
  store.commit('UPDATE_REFRESH_TOKEN', true);

  const headers = { Authorization: `Bearer ${usuario.token}` };
  const body = new FormData();
  body.append('token', usuario.token);

  await fetch(`${process.env.VUE_APP_API_URL}/refresh-token`,
    { method: 'POST', headers, body: body })
    .then(newToken => newToken.json())
    .then((newTokenJson) => {
      if (newTokenJson.token) {
        usuario.refresh_expired_at = new Date
        (data.setHours(data.getHours() + 3)).toLocaleString();
        usuario.token = newTokenJson.token;
        localStorage.setItem('usuario', JSON.stringify(usuario));
        store.commit('UPDATE_REFRESH_TOKEN', false);
        router.go();
      }
    })
    .catch(function(error) {
      console.error(error)
      return new Promise(() => {
        localStorage.removeItem('usuario');
        store.commit('UPDATE_REFRESH_TOKEN', false);
        store.commit('UPDATE_USUARIO', null);
        store.commit('UPDATE_LOGIN', false);
        router.push({ name: 'login' });
      });
    });;
  }

export default V => {
  Object.defineProperties(V.prototype, {
    $http: {
      get() {
        return axios;
      },
      post() {
        return axios;
      },
      put() {
        return axios;
      },
    },
  });
};
