<template>
  <layout>
    <Snackbar
      label="Atendimento indisponível."
      v-model="snackbarAtendimentoOutroUsuario"
      top
      right
      tipo="error"
    />
    <Breadcrumb :breadcrumbs="breadcrumbs" :indicadores="true" />
    <hr />
    <div>
      <div class="buttonCentre">
        <router-link
          to="/novo"
          v-if="hasAtendimentosAbertos"
        >
          <Botao
            label="+ Novo atendimento"
            tipo="atendimento"
            type="submit"
          />
        </router-link>
      </div>
    <div v-if="hasAtendimentosAbertos">
      <v-col cols="3">
         <h5>Escolha um Setor para filtrar seus atendimentos</h5>
        <v-card-subtitle class="mt-1">
          <v-select
            label="Setor"
            v-model="setor"
            :items="setores"
            outlined
            dense
            @change="getAtendimentos"
          ></v-select>
        </v-card-subtitle>
        <h5 v-if="setor">Setor {{setorNome}}</h5>
      </v-col>
    </div>
      <AtendimentoEmptyState v-if="!hasAtendimentosAbertos && !hasAtendimentosEncerrados" />
      <TituloCategoriaLista
        label="Atendimentos abertos"
        class="mx-3 pl-3"
        v-if="hasAtendimentosAbertos"
      />
      <div
        class="mx-3 mb-5 row"
        v-if="hasAtendimentosAbertos"
      >
        <template v-for="(item, index) in atendimentos_abertos">
          <div
            class="col-3"
            :key="index"
          >
            <CardAtendimento
              :key="index"
              :link="`/atendimento/${item.id_atendimento}`"
              :subcategoria="item.subcategoria"
              :data="item.data_edicao | formatDate"
              :status="item.status | statusToColor"
              :statusLabel="item.status"
              :tipo="item.categoria"
              :avatar="getAvatar(item)"
              alt="avatar"
              :atendenteAtribuido="!!item.id_atendente"
              :nome="item.atendente_nome"
              :id="item.id_atendimento"
              :descricao="item.descricao"
            />
          </div>
        </template>
      </div>
      <AtendimentoEmptyState
        v-if="!hasAtendimentosAbertos && hasAtendimentosEncerrados"
        text="Não há atendimentos abertos"
      />
      <TituloCategoriaLista
        label="Atendimentos encerrados"
        class="mx-3 pl-3"
        v-if="hasAtendimentosEncerrados"
      />
      <div>
        <Botao
          v-if="encerrado"
          class="ml-3"
          type="button"
          :label="'Carregar encerrados'"
          tipo="primary"
          :callback="chamaEncerrados"
        />
      </div>
      <div
        class="mx-3 mb-5 row"
        v-if="hasAtendimentosEncerrados && !encerrado"
      >
        <template v-for="(item, index) in atendimentos_encerrados">
          <div
            class="col-3"
            :key="index"
          >
            <CardAtendimento
              :key="index"
              :link="`/atendimento/${item.id_atendimento}`"
              :subcategoria="item.subcategoria"
              :data="item.data_edicao | formatDate"
              status="#ee0202"
              :statusLabel="item.status"
              :tipo="item.categoria"
              :avatar="getAvatar(item)"
              alt="avatar"
              :atendenteAtribuido="!!item.id_atendente"
              :nome="item.atendente_nome"
              :id="item.id_atendimento"
              :descricao="item.descricao"
            />
          </div>
        </template>
      </div>
    </div>
  </layout>
</template>

<script>
import layout from '../layouts/Layout.vue';
import TituloCategoriaLista from '../components/TituloCategoriaLista';
import CardAtendimento from '../components/CardAtendimento';
import Breadcrumb from '../components/Breadcrumb';
import AtendimentoEmptyState from '../components/AtendimentoEmptyState';
import Botao from '../components/Botao';
import Snackbar from '../components/Snackbar';
import nomeIniciais from '../mixins/nomeIniciais.js';
import getAvatar from '../mixins/getAvatar.js';
import { getMaxPermission } from '../utils/utilities';

export default {
  name: 'listaAtendimentoUsuario',
  components: {
    layout,
    TituloCategoriaLista,
    CardAtendimento,
    Breadcrumb,
    AtendimentoEmptyState,
    Botao,
    Snackbar,
  },
  mixins: [nomeIniciais, getAvatar],
  data() {
    return {
      permissaoGestor: this.$store.getters.listaPermissoes.gestor,
      permissaoGestorGeral: this.$store.getters.listaPermissoes.gestorgeral,
      atendenteTipo: getMaxPermission(this.$store),
      atendente_novo: null,
      setores: [],
      setor: '',
      setorNome: '',
      atendimentos_abertos: [],
      atendimentos_encerrados: [],
      atendente: null,
      encerrado: true,
      breadcrumbs: [],
      snackbarAtendimentoOutroUsuario: false,
      url: process.env.VUE_APP_API_URL,
    };
  },
  mounted() {
    this.listaSetores();
    this.$store.commit('UPDATE_LOADING', false);
    this.getPermissoes();
    this.breadcrumbs = [
      {
        route: {
          name: 'lista-usuario',
        },
        label: 'Atendimentos',
      },
    ];
  },
  computed: {
    hasAtendimentosAbertos() {
      return this.atendimentos_abertos.length > 0;
    },
    hasAtendimentosEncerrados() {
      return this.atendimentos_encerrados.length > 0;
    },
  },
  methods: {
    async getPermissoes() {
      const { data: response } = await this.$http.get('/permissoes');

      const retorno = {};
      // eslint-disable-next-line no-return-assign
      response.map(item => retorno[item.permissao.toLowerCase().replace(' ', '')] = item.id);
      this.$store.commit('UPDATE_LISTA_PERMISSOES', retorno);
    },

    async chamaEncerrados() {
      this.encerrado = false;
    },

    async listaSetores() {
      try {
        const { data: response } = await this.$http.get('/setores');
        if (this.atendenteTipo.id_permissao === this.permissaoGestor
            || this.atendenteTipo.id_permissao === this.permissaoGestorGeral) {
          this.setores = response.map(item => ({
            value: item.id,
            text: item.nome,
          }));
        } else {
          const responseTratado = response.map(item => ({
            value: item.id,
            text: item.nome,
          }));
          this.setores = responseTratado.filter(item => item.text !== 'Desenvolvimento Conecta');
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getAtendimentos() {
      try {
        const { id } = this.$store.getters.isUsuario;
        const { data: response } = await this.$http.get(
          `/status-por-usuario/${id}`,
        );
        const [abertos, encerrados] = response;
        if (this.setor) {
          this.atendimentos_abertos = abertos.filter(
            item => item.setor_id === this.setor,
          );
          this.atendimentos_encerrados = encerrados.filter(
            item => item.setor_id === this.setor,
          );
          const nameSetor = this.setores.filter(item => item.value === this.setor);
          this.setorNome = nameSetor[0].text;
        } else {
          this.atendimentos_abertos = abertos;
          this.atendimentos_encerrados = encerrados;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  filters: {
    statusToColor(value) {
      switch (value) {
        case 'Pendente':
          return '#ff6b00';
        case 'Em atendimento':
          return '#97c93d';
        case 'Aguardando usuário':
          return '#ffe500';
        case 'Encerrado':
          return '#ee0202';
        case 'Aguardando atendente':
          return '#027cee';
        default:
          return '#000000';
      }
    },
  },
  created() {
    try {
      if (this.$route.params.toastAtendimentoOutroUsuario) {
        this.snackbarAtendimentoOutroUsuario = true;
      }
      this.getAtendimentos();
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none !important;
}
.title {
  font-weight: 500 !important;
  color: #0157a8 !important;
}
.btn {
  background-color: #0157a8;
  border-radius: 50px;
  padding: 7px 15px;
  font-weight: bold;
}
.buttonCentre {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  margin-bottom: 45px;
}
</style>
