<template>
  <button
    class="btn-menu-lateral"
    :class="rotaAtual === rota ? 'active' : ''"
    @click="pushRota"
    :disabled='rotaAtual === rota'
  >
    <v-icon
      color="#122870"
      :class="rotaAtual === rota ? 'activeIcon' : ''"
    >{{ icone }}</v-icon>
    <span class="conteudo-botao">{{ label }}</span>
  </button>
</template>

<script>
import VueTypes from 'vue-types';
import EventBus from '../plugins/EventBus';

export default {
  name: 'BotaoMenuLateral',
  props: {
    icone: VueTypes.string.isRequired.def('home'),
    label: VueTypes.string.isRequired.def('Início'),
    rota: VueTypes.string.isRequired.def('/'),
  },
  data() {
    return {
      rotaAtual: window.location.pathname,
    };
  },
  computed: {
    styleFundo() {
      return `background-color: ${this.corFundo};`;
    },
  },
  methods: {
    async pushRota() {
      await this.$router.push(this.rota);
      EventBus.$emit('toggleMenuDrawer');
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-menu-lateral {
  display: inline-flex;
  padding: 8px 20px 15px 20px;
  width: 100%;
  height: 40px;
  box-shadow: none;
  background-color: #ffffff;
  border: none;
  font-weight: bold;
  font-size: 16px;
  color: #122870;
  &.active {
    background-color: #122870;
    color: #ffffff;
  }
}
.activeIcon {
  color: #ffffff !important;
}
.conteudo-botao {
  margin-left: 5px;
}
</style>
