<template>
  <span>
    <!------- TIPO = TEXT ------->
    <!-- text -->
      <div
      v-if="IdTipoCampo == tipoText && !IdMascara && !minCaracteres">
        <v-col cols="5" class="col-ajust-padding">
        <div v-if="IdTipoCampo == tipoText && !IdMascara && !minCaracteres && required"
        class="ajust-padding caption count-warning alert">*</div>
          <v-text-field
            v-model="resposta"
            :label="label"
            @input="$emit('emit-value', {
                resposta: $event,
                column_campo: columnCampo
              })"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
        </v-col>
      </div>
    <!-- text && mascaras GERAIS-->
      <div
      v-if="IdTipoCampo == tipoText && IdMascara
        && IdMascara !== mascaraDinheiroRealID
        && IdMascara !== mascaraDinheiroDolarID
        && IdMascara !== mascaraDinheiroEuroID">
        <v-col cols="5" class="col-ajust-padding">
        <div v-if="IdTipoCampo == tipoText && IdMascara
          && IdMascara !== mascaraDinheiroRealID
          && IdMascara !== mascaraDinheiroDolarID
          && IdMascara !== mascaraDinheiroEuroID
          && required"
        class="ajust-padding caption count-warning alert">*</div>
          <v-text-field
            v-model="resposta"
            v-mask="populaMascara"
            :label="label"
            @input="$emit('emit-value', {
                resposta: $event,
                column_campo: columnCampo
              })"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
        </v-col>
      </div>
    <!-- text && mascara DE DINHEIRO Real -->
      <div
      v-if="IdTipoCampo == tipoText && IdMascara == mascaraDinheiroRealID">
        <v-col cols="5" class="col-ajust-padding">
        <div v-if="IdTipoCampo == tipoText && IdMascara == mascaraDinheiroRealID && required"
        class="ajust-padding caption count-warning alert">*</div>
          <v-text-field
            v-money="real"
            v-model.lazy="resposta"
            :label="label"
            @input="$emit('emit-value', {
                resposta: $event,
                column_campo: columnCampo
              })"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
        </v-col>
      </div>
    <!-- text && mascara DE DINHEIRO Dolar -->
      <div
      v-if="IdTipoCampo == tipoText && IdMascara == mascaraDinheiroDolarID">
        <v-col cols="5" class="col-ajust-padding">
        <div v-if="IdTipoCampo == tipoText && IdMascara == mascaraDinheiroDolarID && required"
        class="ajust-padding caption count-warning alert">*</div>
          <v-text-field
            v-money="dolar"
            v-model.lazy="resposta"
            :label="label"
            @input="$emit('emit-value', {
                resposta: $event,
                column_campo: columnCampo
              })"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
        </v-col>
      </div>
    <!-- text && mascara DE DINHEIRO Euro -->
      <div
      v-if="IdTipoCampo == tipoText && IdMascara == mascaraDinheiroEuroID">
        <v-col cols="5" class="col-ajust-padding">
        <div v-if="IdTipoCampo == tipoText && IdMascara == mascaraDinheiroEuroID && required"
        class="ajust-padding caption count-warning alert">*</div>
          <v-text-field
            v-money="euro"
            v-model.lazy="resposta"
            :label="label"
            @input="$emit('emit-value', {
                resposta: $event,
                column_campo: columnCampo
              })"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
        </v-col>
      </div>
    <!-- text && minCaracteres -->
      <div
      v-if="IdTipoCampo == tipoText && minCaracteres">
        <v-col cols="5" class="col-ajust-padding">
        <div v-if="IdTipoCampo == tipoText && minCaracteres && required"
        class="ajust-padding caption count-warning alert">*</div>
          <v-text-field
            v-model="resposta"
            :label="label"
            @input="$emit('emit-value', {
                resposta: $event,
                column_campo: columnCampo
              })"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
          <p
          class="caption text-right"
          v-if="IdTipoCampo == tipoText && minCaracteres"
          >
            Mínimo {{ minCaracteres }} caracteres /
            <span :class="resposta.length < minCaracteres ? 'count-warning' : 'count-success'">
              {{ countLengt }}
            </span>
          </p>
        </v-col>
      </div>


    <!------- TIPO = NUMBER ------->
    <!-- number -->
    <div
    v-if="IdTipoCampo == tipoNumber">
      <v-col cols="5" class="col-ajust-padding">
      <div v-if="IdTipoCampo == tipoNumber && required"
      class="ajust-padding caption count-warning alert">*</div>
        <v-text-field
          v-model="resposta"
          :label="label"
          @input="$emit('emit-value', {
                resposta: $event,
                column_campo: columnCampo
              })"
          type="number"
          outlined
          dense
          clearable
          auto-grow
        ></v-text-field>
      </v-col>
    </div>


    <!------- TIPO = CHECKBOX------->
    <!-- checkbox -->
    <div
    v-if="IdTipoCampo == tipoCheckBox">
      <v-col cols="5" class="col-ajust-padding">
        <v-checkbox
          v-model="resposta"
          :label="label"
          @change="$emit('emit-value', {
                resposta: $event,
                column_campo: columnCampo
              })"
        ></v-checkbox>
      </v-col>
    </div>


    <!------- TIPO = RADIOBUTTON------->
    <!-- radioButton -->
    <div
    v-if="IdTipoCampo == tipoRadioButton">
        <v-col cols="5" class="col-ajust-padding">
          <v-radio-group
            v-model="resposta"
            row
            :label="label"
            @change="$emit('emit-value', {
                resposta: $event,
                column_campo: columnCampo
              })"
          >
            <v-radio
              v-for="(opcao, index) in opcoes" :key="index"
              :label="opcao.text"
              :value="opcao.text"
            ></v-radio>
          <div class="caption count-warning">*</div>
          </v-radio-group>
        </v-col>
    </div>


    <!------- TIPO = LONGTEXT ------->
    <!-- longText -->
    <div
    v-if="IdTipoCampo == tipoLongText && !minCaracteres">
      <v-col cols="5" class="col-ajust-padding">
      <div v-if="IdTipoCampo == tipoLongText && !minCaracteres && required"
      class="ajust-padding caption count-warning alert">*</div>
        <v-textarea
          v-model="resposta"
          :label="label"
          @input="$emit('emit-value', {
                resposta: $event,
                column_campo: columnCampo
              })"
          outlined
          dense
          clearable
          auto-grow
        ></v-textarea>
      </v-col>
    </div>
    <!-- longText && minCaracteres -->
    <div
    v-if="IdTipoCampo == tipoLongText && minCaracteres">
      <v-col cols="5" class="col-ajust-padding">
      <div v-if="IdTipoCampo == tipoLongText && minCaracteres && required"
      class="ajust-padding caption count-warning alert">*</div>
        <v-textarea
          v-model="resposta"
          :label="label"
          @input="$emit('emit-value', {
                resposta: $event,
                column_campo: columnCampo
              })"
          outlined
          dense
          clearable
          auto-grow
        ></v-textarea>
        <p
        class="caption text-right"
        v-if="IdTipoCampo == tipoLongText && minCaracteres"
        >
          Mínimo {{ minCaracteres }} caracteres /
          <span :class="resposta.length < minCaracteres ? 'count-warning' : 'count-success'">
            {{ countLengt }}
          </span>
        </p>
      </v-col>
    </div>


    <!------- TIPO = INPUTSELECT ------->
    <!-- inputSelect -->
    <div
    v-if="IdTipoCampo == tipoInputSelect">
      <v-col cols="5" class="col-ajust-padding">
      <div v-if="IdTipoCampo == tipoInputSelect && required"
      class="ajust-padding caption count-warning alert">*</div>
        <v-select
          v-model="resposta"
          :label="label"
          :items="opcoes"
          @change="$emit('emit-value', {
                resposta: $event,
                column_campo: columnCampo
            })"
          outlined
          dense
          clearable
          auto-grow
        ></v-select>
      </v-col>
    </div>


    <!------- TIPO = DATA ------->
    <!-- data -->
    <div
    v-if="IdTipoCampo == tipoData">
      <v-col cols="5" class="col-ajust-padding">
      <div v-if="IdTipoCampo == tipoData && required"
      class="ajust-padding caption count-warning alert">*</div>
        <v-menu
          offset-y
          :close-on-content-click="false"
          :nudge-right="40"
          v-model="menuData"
          transition="scale-transition"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              readonly
              v-model="dataFormatted"
              :label="label"
              append-icon="event"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title
            v-model="resposta"
            @change="$emit('emit-value',
              { resposta: dataFormatted,
                column_campo: columnCampo
              })"
            @input="menuData = false"
            locale="pt-br"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </div>


    <!------- TIPO = HORARIO ------->
    <!-- hora -->
    <div
    v-if="IdTipoCampo == tipoHora">
      <v-col cols="1" class="col-ajust-padding">
        <div v-if="IdTipoCampo == tipoHora && required"
        class="ajust-padding caption count-warning alert">*</div>
        <v-text-field
          v-model="resposta"
          :label="label"
          type="time"
          outlined
          dense
          clearable
          auto-grow
          @change="$emit('emit-value',
            { resposta: $event,
              column_campo: columnCampo
            })"
        ></v-text-field>
      </v-col>
    </div>
  </span>
</template>

<script>
import VueTypes from 'vue-types';
import moment from 'moment';
import { VMoney } from 'v-money';
import { mask } from 'vue-the-mask';
import { tipoMascara } from '../utils/utilities';

export default {
  name: 'CampoPergunta',
  directives: { mask, money: VMoney },
  props: {
    columnCampo: VueTypes.number,
    label: VueTypes.string.def(''),
    IdTipoCampo: VueTypes.number,
    required: VueTypes.boolean,
    minCaracteres: VueTypes.number,
    IdMascara: VueTypes.text,
    opcoesCampo: VueTypes.array,
  },

  data() {
    return {
      real: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
      dolar: {
        decimal: ',',
        thousands: '.',
        prefix: 'US$ ',
        precision: 2,
      },
      euro: {
        decimal: ',',
        thousands: '.',
        prefix: '€ ',
        precision: 2,
      },
      mascaraDinheiroRealID: 5, // Referencia tabela mascara - banco.
      mascaraDinheiroDolarID: 7,
      mascaraDinheiroEuroID: 8,
      resposta: '',
      opcoes: [],
      menuData: false,

      // Ids salvos no banco
      tipoText: 1,
      tipoNumber: 2,
      tipoCheckBox: 3,
      tipoRadioButton: 4,
      tipoInputSelect: 5,
      tipoLongText: 6,
      tipoData: 7,
      tipoHora: 8,
    };
  },

  created() {
    moment.locale('pt-br');
  },

  mounted() {
    this.populaOpcoes();
  },

  computed: {
    populaMascara() {
      return tipoMascara(this.IdMascara);
    },
    countLengt() {
      return this.resposta === '' ? '0' : this.resposta.length;
    },
    dataFormatted() {
      return this.resposta ? moment(this.resposta).format('L') : '';
    },
  },

  methods: {
    populaOpcoes() {
      if (this.opcoesCampo) {
        if (this.opcoesCampo.length !== 0) {
          this.opcoes = this.opcoesCampo.map(item => ({
            value: item.tipo_opcoes,
            text: item.tipo_opcoes,
          }));
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  z-index: 1;
  top: 30px;
  left: 20px;
  display: flex;
  // background-color:green;
  flex-direction: row-reverse;
}

.ajust-padding {
  padding: 0px;
}

.col-ajust-padding {
  padding: 5px;
}

.count-warning {
  background-color: transparent;
  color: red;
}

.count-success {
  background-color: transparent;
  color: green;
}
</style>
