<template>
  <div id="criarFornecedoresDadosMestres">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
        <h3 class="titleHeader mb-3">Fluxo de Criar Fornecedores Dados Mestres</h3>
      <v-row>
          <v-col>
            <h5>Atenção</h5>
            <p class="caption">Os campos com
              <span class="count-warning">*</span>
              são obrigatórios.</p>
          </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-autocomplete
            v-model="empresa"
            :items="empresas"
            label="Empresa"
            class="alert-fild-required"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="razaoFornecedor"
            label="Razão social do fornecedor"
            class="alert-fild-required"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-mask="'##.###.###/####-##'"
            v-model="cnpj"
            label="CNPJ"
            class="alert-fild-required"
            outlined
            dense
            :disabled="(cpf.length || indentificacaoExterior.length) >= 1"
          ></v-text-field>
        </v-col>
        <span style="margin-top: 20px;">OU</span>
        <v-col cols="4">
          <v-text-field
            v-mask="'###.###.###-##'"
            v-model="cpf"
            label="CPF"
            class="alert-fild-required"
            outlined
            dense
            :disabled="(cnpj.length || indentificacaoExterior.length) >= 1"
          ></v-text-field>
        </v-col>
        <span style="margin-top: 20px;">OU</span>
        <v-col cols="3">
          <v-text-field
            v-model="indentificacaoExterior"
            label="Identificação Exterior"
            class="alert-fild-required"
            outlined
            dense
            :disabled="(cnpj.length || cpf.length) >= 1"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="endereco"
            label="Endereço"
            class="alert-fild-required"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field
            v-mask="'#####-###'"
            v-model="cep"
            label="CEP"
            class="alert-fild-required"
            outlined
            dense
            :disabled="zipCode.length >= 1"
          ></v-text-field>
        </v-col>
        <span style="margin-top: 20px;">OU</span>
        <v-col cols="3">
          <v-text-field
            v-model="zipCode"
            label="ZIPCODE"
            class="alert-fild-required"
            outlined
            dense
            :disabled="cep.length >= 1"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="cidade"
            label="Cidade"
            class="alert-fild-required"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="estado"
            label="Estado"
            class="alert-fild-required"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            v-model="pais"
            label="País"
            class="alert-fild-required"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-text-field
            v-mask="'(##)#####-####'"
            v-model="telefone1"
            label="Telefone Obrigatório"
            class="alert-fild-required"
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-mask="'(##)#####-####'"
            v-model="telefone2"
            label="Telefone opcional"
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            v-mask="'(##)#####-####'"
            v-model="telefone3"
            label="Telefone opcional"
            outlined
            dense
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="emailGeral"
            label="E-mail geral"
            class="alert-fild-required"
            :rules="emailRules"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field
            v-model="emailPedido"
            label="E-mail para envio de pedido"
            :class="checkRequiredCSSAlert()"
            :rules="emailRules"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-select
            v-model="formaPagamento"
            :items="formasPagamento"
            label="Forma de pagamento"
            class="alert-fild-required"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <span v-if="formaPagamento === 'Crédito em conta'">Obrigatório ANEXAR
          comprovante de dados bancários
        </span>
      </v-row>

      <v-row>
        <v-col>
          <v-icon>mdi-alert</v-icon>
          <p class="caption"><strong>Atenção: </strong>Informações adicionais,
          utilizar o campo de <strong>mensagem.</strong></p>
        </v-col>
      </v-row>

    </v-form>
  </div>
</template>

<script>
import moment from 'moment';
import { mask } from 'vue-the-mask';
import Snackbar from '../Snackbar';

export default {
  name: 'CriarFornecedoresDadosMestres.vue',
  components: { Snackbar },
  directives: { mask },
  data() {
    return {
      empresa: '',
      empresas: [
        'Meta',
        'Netrin',
        'Meta Ventures Part Ltda',
        '91M Cons. Gestão Ltda',
        'MGSA Consultoria Ltda',
        'WORBI Gestão Ltda',
        'WTCPAR Participações Ltda',
      ],
      razaoFornecedor: '',
      cnpj: '',
      cpf: '',
      indentificacaoExterior: '',
      endereco: '',
      cep: '',
      zipCode: '',
      cidade: '',
      estado: '',
      emailRules: [
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
      ],
      pais: '',
      telefone1: '',
      telefone2: '',
      telefone3: '',
      emailGeral: '',
      emailPedido: '',
      formaPagamento: '',
      formasPagamento: ['Boleto', 'Crédito em conta'],
      dadosFluxo: {},
      newDadosFluxo: {},
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },
  created() {
    moment.locale('pt-br');
  },

  computed: {
    verificaCampos() {
      return (!!this.empresa
      && !!this.razaoFornecedor
      && (!!this.cnpj || !!this.cpf || !!this.indentificacaoExterior)
      && !!this.endereco
      && (!!this.cep || !!this.zipCode)
      && !!this.cidade
      && !!this.estado
      && !!this.pais
      && !!this.telefone1
      && !!this.emailGeral
      && this.chechIfneedEmaiPedido()
      && !!this.formaPagamento);
    },
  },
  methods: {
    checkRequiredCSSAlert() {
      return this.zipCode ? 'alert-fild' : 'alert-fild-required';
    },

    chechIfneedEmaiPedido() {
      return this.zipCode ? true : !!this.emailPedido;
    },

    trataResposta(dadosFluxo) {
      const respostasModel = {};

      if (dadosFluxo.empresa) respostasModel.column_campo_1 = dadosFluxo.empresa;
      if (dadosFluxo.razaoFornecedor) respostasModel.column_campo_2 = dadosFluxo.razaoFornecedor;
      if (dadosFluxo.cnpj) respostasModel.column_campo_3 = dadosFluxo.cnpj;
      if (dadosFluxo.cpf) respostasModel.column_campo_4 = dadosFluxo.cpf;
      if (dadosFluxo.indentificacaoExterior) {
        respostasModel.column_campo_5 = dadosFluxo.indentificacaoExterior;
      }
      if (dadosFluxo.endereco) respostasModel.column_campo_6 = dadosFluxo.endereco;
      if (dadosFluxo.cep) respostasModel.column_campo_7 = dadosFluxo.cep;
      if (dadosFluxo.zipCode) respostasModel.column_campo_8 = dadosFluxo.zipCode;
      if (dadosFluxo.cidade) respostasModel.column_campo_9 = dadosFluxo.cidade;
      if (dadosFluxo.estado) respostasModel.column_campo_10 = dadosFluxo.estado;
      if (dadosFluxo.pais) respostasModel.column_campo_11 = dadosFluxo.pais;
      if (dadosFluxo.telefone1) respostasModel.column_campo_12 = dadosFluxo.telefone1;
      if (dadosFluxo.telefone2) respostasModel.column_campo_13 = dadosFluxo.telefone2;
      if (dadosFluxo.telefone3) respostasModel.column_campo_14 = dadosFluxo.telefone3;
      if (dadosFluxo.emailGeral) respostasModel.column_campo_15 = dadosFluxo.emailGeral;
      if (dadosFluxo.emailPedido) respostasModel.column_campo_16 = dadosFluxo.emailPedido;
      if (dadosFluxo.formaPagamento) respostasModel.column_campo_17 = dadosFluxo.formaPagamento;

      respostasModel.validation = true;
      this.newDadosFluxo = respostasModel;
    },

    enviaJSON() {
      if (!this.verificaCampos) {
        this.dadosFluxo = { validation: false };
        this.alerta = 'error';
        this.msg = 'Todos os campos com * são obrigatórios.';
        this.snackBarAlertaCampos = true;
      } else {
        this.dadosFluxo = {
          validation: true,
          empresa: this.empresa,
          razaoFornecedor: this.razaoFornecedor,
          cnpj: this.cnpj,
          cpf: this.cpf,
          indentificacaoExterior: this.indentificacaoExterior,
          endereco: this.endereco,
          cep: this.cep,
          zipCode: this.zipCode,
          cidade: this.cidade,
          estado: this.estado,
          pais: this.pais,
          telefone1: this.telefone1,
          telefone2: this.telefone2 ? this.telefone2 : '',
          telefone3: this.telefone3 ? this.telefone3 : '',
          emailGeral: this.emailGeral,
          emailPedido: this.emailPedido,
          formaPagamento: this.formaPagamento,

        };
        this.trataResposta(this.dadosFluxo);
        this.$emit('dadosFluxo', this.newDadosFluxo);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

.alert-fild-required::after {
    content: "*";
    color: red;
    margin-bottom: 10px;
    margin-left: 6px;
    font-size: x-small;
}

.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.count-warning {
  background-color: transparent;
  color: red;
}
</style>
