<template>
  <div>
    <Snackbar
      :label="msg"
      v-model="snackbarPutPermissao"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-text-field
      v-model="searchUser"
      label="Pesquise um usuário"
      outlined
      clearable
    >
      <v-icon v-if="!searchUser" slot="append">mdi-account-search</v-icon>
    </v-text-field>
    <v-dialog v-model="dialog" max-width="600px" >
      <template v-slot:activator="{ on }">
        <v-btn class="v-btn_add" v-on="on">
          Adicionar novo
        </v-btn>
      </template>
      <v-card class="px-2" style="min-height: 270px;">
        <v-card-title v-if="permissaoGestor.id_permissao
        === gestorGeral" class="headline">Adicionar Permissão</v-card-title>
        <v-card-title v-if="permissaoGestor.id_permissao
        === gestor" class="headline">Adicionar Permissão de responsável</v-card-title>
        <v-card-subtitle class="mt-1">
        <FiltroAtendimento
        tipoFiltro="searchUsuarios"
        :quantidadeTags="1"
        class="search-filter px-4"
        @changeFiltro="showResults($event)"
        />
          </v-card-subtitle>
         <v-card-subtitle v-if="usuario
         && permissaoGestor.id_permissao === gestorGeral" class="mt-1" >
            <v-select
              v-if="usuario"
              label="Setor"
              v-model="setor"
              :items="setores"
              outlined
              dense
            ></v-select>
          </v-card-subtitle>
        <v-card-subtitle v-if="(permissaoGestor.id_permissao === gestor && usuario)
        || (permissaoGestor.id_permissao === gestorGeral && setor)" class="mt-1">
          <v-select
            v-if="usuario"
            label="Permissão"
            v-model="permissao"
            :items="permissoes"
            outlined
            dense
          ></v-select>
        </v-card-subtitle>
        <v-card-subtitle v-if="(permissao && permissaoGestor.id_permissao === gestorGeral)
        || (permissao && permissaoGestor.id_permissao === gestor)">
          <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <Botao
            class="mr-4"
            type="button"
            label="Cancelar"
            tipo="secondary"
            :callback="closeModal"
          />
          <Botao
            type="submit"
            label="Salvar"
            :callback="salvaPermissao"
          />
        </v-card-actions>
        </v-card-subtitle>
      </v-card>
    </v-dialog>
      <v-dialog
    v-model="dialogDelete"
    scrollable
    max-width="500"
  >
    <v-card class="px-2">
      <v-card-title class="headline">Remover Permissão</v-card-title>
      <v-card-subtitle class="mt-1">
        Deseja realmente remover a permissão?
      </v-card-subtitle>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <Botao
          class="mr-4"
          type="button"
          label="Cancelar"
          tipo="secondary"
          :callback="closeModal"
        />
        <Botao
          type="button"
          label="Confirmar"
          :callback="deletePermissao"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
    <v-data-table
      :headers="headers"
      :items="usuarios"
      :search="searchUser"
      :sort-by="'permissao_nome'"
      :items-per-page="5"
      :no-results-text="'Usuário não encontrado.'"
      :footer-props="{
        'items-per-page-text': 'Resultados por página',
        'items-per-page-all-text': 'Todos',
        'page-text': '{0}-{1} de {2}'
      }"
    >
      <template v-slot:item.avatar="{ item }">
        <img
          v-if="item.avatar"
          :src="`${url}/${item.avatar}`"
          alt="Avatar"
          height="20"
          width="20"
        />
        <img
          v-else
          src="../assets/img/user-placeholder.png"
          alt="Avatar"
          height="20"
          width="20"
        />
      </template>
      <template v-slot:item.sede_nome="{ item }">
        <span v-if="item.sede_nome">{{ item.sede_nome }} </span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.setor_nome="{item}">
        <span v-if="item.setor_nome">{{ item.setor_nome }} </span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.permissao_id="{ item }">
        <v-icon small dark v-on="on" @click.stop="deleteModal(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Snackbar from './Snackbar';
import { getMaxPermission } from '../utils/utilities';
import { setores } from '../Constants';
import Botao from './Botao.vue';
import FiltroAtendimento from './FiltroAtendimento.vue';
import EventBus from '../plugins/EventBus';

export default {
  name: 'BuscaUsuarios',
  components: {
    Snackbar,
    Botao,
    FiltroAtendimento,
  },
  data() {
    return {
      permissaoGestor: getMaxPermission(this.$store),
      seletorSetores: setores,
      msg: '',
      dialog: false,
      dialogDelete: false,
      deleteItem: {},
      alerta: 'info',
      snackbarPutPermissao: false,
      searchUser: '',
      permissoes: [],
      usuarios: [],
      usuariosFiltrados: [],
      setores: [],
      setor: '',
      responsavel: '',
      usuario: '',
      permissao: '',
      headers: [],
      colaborador: this.$store.getters.listaPermissoes.colaborador,
      gestor: this.$store.getters.listaPermissoes.gestor,
      gestorGeral: this.$store.getters.listaPermissoes.gestorgeral,
      automationCenter: this.$store.getters.listaPermissoes.automationcenter,
      atendente: this.$store.getters.listaPermissoes.atendente,
      relatorio: this.$store.getters.listaPermissoes.relatorio,
      atribuidor: this.$store.getters.listaPermissoes.atribuidor,
      setorAtendente: [],
      url: process.env.VUE_APP_API_URL,
    };
  },
  mounted() {
    this.verifyPermissionHeaders();
  },
  created() {
    this.getSetores();
    this.getUsuarios();
    this.getPermissoes();
  },
  watch: {
    dialog(val) {
      return val || this.closeModal();
    },
  },
  methods: {
    async verifyPermissionHeaders() {
      if (this.permissaoGestor.id_permissao === this.$store.getters.listaPermissoes.gestorgeral) {
        this.headers = [
          { text: '', value: 'avatar', sortable: false },
          { text: 'Nome', value: 'usuario_nome' },
          { text: 'Sede', value: 'sede_nome' },
          { text: 'Setor', value: 'setor_nome' },
          { text: 'Permissão', value: 'permissao_nome' },
          { text: 'Remover', value: 'permissao_id', sortable: false },
        ];
      } else if (this.permissaoGestor.id_permissao === this.$store.getters.listaPermissoes.gestor) {
        this.headers = [
          { text: '', value: 'avatar', sortable: false },
          { text: 'Nome', value: 'usuario_nome' },
          { text: 'Sede', value: 'sede_nome' },
          { text: 'Permissão', value: 'permissao_nome' },
          { text: 'Remover', value: 'permissao_id', sortable: false },
        ];
      }
    },
    async getUsuarios() {
      try {
        const { data: response } = await this.$http.get('/listar-permissao');
        const usuariosTratados = this.setSetorInUsuario(response);
        if (this.permissaoGestor.id_permissao === this.$store.getters.listaPermissoes.gestorgeral) {
          this.usuarios = usuariosTratados.filter(all => all.permissao_id !== this.colaborador);
          Object.entries(this.usuarios).forEach(([key, value]) => {
            if (value.permissao_nome === 'Atendente') {
              this.usuarios[key].permissao_nome = 'Responsável';
            }
          });
        } else if (this.permissaoGestor.id_permissao
          === this.$store.getters.listaPermissoes.gestor) {
          this.usuarios = usuariosTratados.filter(all => all.permissao_id !== this.colaborador
          && all.setor_id === this.permissaoGestor.id_setor);
          Object.entries(this.usuarios).forEach(([key, value]) => {
            if (value.permissao_nome === 'Atendente') {
              this.usuarios[key].permissao_nome = 'Responsável';
            }
          });
        }
        this.usuariosFiltrados = response.map(item => ({
          value: item.usuario_id,
          text: item.usuario_nome,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    async getSetores() {
      try {
        const { data: response } = await this.$http.get('/setores');
        this.setores = response.map(item => ({
          value: item.id,
          text: item.nome,
        }));
        this.setorAtendente = response.filter(item => item.id
        === this.permissaoGestor.id_setor);
        this.setorAtendente = this.setorAtendente.map(item => ({
          value: item.id,
          text: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async getPermissoes() {
      try {
        const { data: response } = await this.$http.get('/permissoes');
        const setorAutomationCenter = this.setores.filter(item => item.text === 'Meta Automation Center')[0];
        const responseTratado = response.map(item => ({
          value: item.id,
          text: item.permissao,
        }));
        this.permissoes = responseTratado.filter(item => item.value !== 1);
        Object.entries(this.permissoes).forEach(([key, value]) => {
          if (value.text === 'Atendente') {
            this.permissoes[key].text = 'Responsável';
          }
        });
        if (this.permissaoGestor.id_permissao === this.gestor) {
          if (this.permissaoGestor.id_setor === setorAutomationCenter.value) {
            this.permissoes = this.permissoes.filter(item => item.value === this.atendente
            || item.value === this.automationCenter || item.value === this.atribuidor
            || item.value === this.relatorio || item.value === this.gestor);
          } else {
            this.permissoes = this.permissoes.filter(item => item.value === this.atendente
            || item.value === this.atribuidor || item.value === this.relatorio
            || item.value === this.gestor);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    showResults(e) {
      if (e[0]) this.usuario = e[0].value;
    },
    setSetorInUsuario(usuarios) {
      return usuarios.map((item) => {
        let setorNome;
        this.setores.forEach((setor) => {
          if (setor.value === item.setor_id) setorNome = setor.text;
        });
        return { ...item, setor_nome: setorNome };
      });
    },
    closeModal() {
      this.searchUser = '';
      this.permissoes = [];
      this.usuariosFiltrados = [];
      this.setores = [];
      this.setor = '';
      this.usuario = '';
      this.permissao = '';
      this.dialog = false;
      this.dialogDelete = false;
      this.getUsuarios();
      this.getSetores();
      this.getPermissoes();
      EventBus.$emit('limpaFiltroUsuarios');
    },
    deleteModal(item) {
      this.dialogDelete = true;
      this.deleteItem = item;
    },
    async deletePermissao() {
      const item = this.deleteItem;
      const body = {
        id_usuario: item.usuario_id,
        id_permissao: 1,
        setor_id: item.setor_id,
      };
      try {
        await this.$http.delete(`/usuariohaspermissoes/${item.usuario_id}/setor/${item.setor_id}`, body).then(() => {
          this.getUsuarios().then(() => {
            this.alerta = 'success';
            this.msg = 'Permissão Deletada com sucesso.';
            this.snackbarPutPermissao = true;
          });
        });
      } catch (e) {
        this.alerta = 'error';
        this.msg = 'Não foi possível alterar a permissão.';
        this.snackbarPutPermissao = true;
        console.error(e);
      }
      this.dialogDelete = false;
    },
    getUsuarioId(usuario) {
      let usuarioId;
      this.usuariosFiltrados.forEach((item) => {
        if (item.text === usuario) usuarioId = item.value;
      });
      return usuarioId;
    },
    async permissaoResponsavel() {
      if (this.permissaoGestor.id_permissao === this.gestor) {
        this.setor = this.permissaoGestor.id_setor;
      }
    },
    async salvaPermissao() {
      if (this.permissaoGestor.id_permissao === this.gestor) {
        await this.permissaoResponsavel();
      }
      const body = {
        id_usuario: this.getUsuarioId(this.usuario),
        id_permissao: this.permissao,
        setor_id: this.setor,
      };
      this.closeModal();
      try {
        await this.$http.post('/usuariohaspermissoes/', body).then(() => {
          this.getUsuarios().then(() => {
            this.alerta = 'success';
            this.msg = 'Permissão Adicionada com sucesso.';
            this.snackbarPutPermissao = true;
          });
        });
      } catch (e) {
        this.alerta = 'error';
        this.msg = 'Não foi possível alterar a permissão.';
        this.snackbarPutPermissao = true;
        console.error(e);
      }
    },
    definePermissao(item) {
      return item.permissao_nome === 'Colaborador'
        ? this.$store.getters.listaPermissoes.atendente
        : this.$store.getters.listaPermissoes.colaborador;
    },
  },
};
</script>

<style lang="scss">
.v-list-item__title {
  font-size: 12px !important;
  text-align: center;
}
.v-dialog > .v-card > .v-card__title {
  padding: 16px 24px 0px;
}
.v-btn_add {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #ffffff !important;
  border-radius: 5px;
  font-size: 16px !important;
  margin: 0px 40%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}
.button-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
}
</style>
