const switchStatus = {
  methods: {
    switchStatus(newVal) {
      const statusBanco = ['', 'Pendente', 'Em Atendimento', 'Aguardando usuário', 'Encerrado', 'Aguardando atendente', 'Em análise'];
      const statusArrays = ['', 'pendente', 'andamento', 'aguardando', 'encerrado', 'aguardandoAtendente', 'analise'];
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(newVal)) {
        return statusBanco[newVal];
      }

      let index = statusArrays.findIndex(item => item === newVal);

      if (index === -1) {
        index = statusBanco.findIndex(item => item === newVal);
      }
      return index;
    },
  },
};

export default switchStatus;
