<template>
  <div id="formComprasTecnicasNacionais">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
        <h3 class="titleHeader mb-3">Fluxo de Compras Técnicas - Nacionais</h3>

      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="empresa"
            label="Empresa"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="unidade"
            label="Centro / Unidade"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="fornecedorSelecionado"
            label="Escolha um Fornecedor"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-text-field
            class="mb-1"
            v-for="(item, index) in pedidos"
            :key="index"
            v-model="item.material"
            label="Material/Serviços"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            class="mb-1"
            v-for="(item, index) in pedidos"
            :key="index"
            v-model="item.qtTotal"
            label="Quantidade total"
            type="number"
            min=0
            outlined
            dense
            @change="calculaPreco()"
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            class="mb-1"
            v-for="(item, index) in pedidos"
            :key="index"
            v-money="money"
            v-model.lazy="item.orcamento"
            label="Valor unitário"
            outlined
            dense
            @change="calculaPreco()"
            :disabled="editar"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            class="mb-1"
            v-for="(item, index) in pedidos"
            :key="index"
            v-model="item.centroCustoSelecionado"
            label="Centro de custo"
            outlined
            dense
            :disabled="editar"
          >
          </v-text-field>
        </v-col>


        <v-col cols="2">
          <v-text-field
            class="mb-1"
            v-for="(item, index) in pedidos"
            :key="index"
            v-model="item.orcamentoTotal"
            label="Valor total do item"
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="orcamentoTotal"
            label="Valor total do pedido"
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="condicaoPagamentoSelecionado"
            label="Condição de pagamento"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-textarea
            v-model="endereco"
            label="Local de entrega"
            outlined
            dense
            auto-grow
            rows="2"
            :disabled="editar"
          ></v-textarea>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="confirmacaoRecebimento"
            label="Confirmação de Recebimento"
            @change="registraUsuarioMarcacao"
            :disabled="editar"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-text-field
            v-if="permissaoMax.id_permissao !== usuarioPerm || isStatusEncerrado"
            v-model="numeroAtendimento"
            label="Número do pedido SAP"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn
        :class="{ 'btn-primary-disabled ml-3': isStatusEncerrado,
                   'btn-primary ml-3': !isStatusEncerrado }"
        @click="toggleEdit"
        :disabled="isStatusEncerrado">
        <span v-if="editar">
          <v-icon left>mdi-pencil</v-icon>Editar
        </span>
        <span v-else>
          <v-icon left>mdi-close</v-icon>Cancelar
        </span>
      </v-btn>

      <v-btn
        v-if="!editar"
        class="btn-primary ml-2"
        @click="salvarFluxoEditado"
      >
        <v-icon left>mdi-content-save</v-icon>Salvar
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import moment from 'moment';
import { VMoney } from 'v-money';
import { mask } from 'vue-the-mask';
import VueTypes from 'vue-types';
import { getMaxPermission } from '../../utils/utilities';
import Snackbar from '../Snackbar';

export default {
  name: 'FormFormalizarPedidoNacional.vue',
  components: { Snackbar },
  directives: { money: VMoney, mask },
  props: {
    camposFluxo: VueTypes.array,
    fluxoId: VueTypes.integer,
    atendimentoId: VueTypes.integer,
    statusAtendimento: VueTypes.string,
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
      empresa: '',
      unidade: '',
      fornecedorSelecionado: '',
      pedidos: [{
        material: '', qtTotal: '', orcamento: '', orcamentoTotal: '', centroCustoSelecionado: '',
      }, {
        material: '', qtTotal: '', orcamento: '', orcamentoTotal: '', centroCustoSelecionado: '',
      }, {
        material: '', qtTotal: '', orcamento: '', orcamentoTotal: '', centroCustoSelecionado: '',
      }],
      condicaoPagamentoSelecionado: '',
      orcamentoTotal: '',
      endereco: '',
      numeroAtendimento: '',
      confirmacaoRecebimento: '',
      editar: true,
      permissaoMax: getMaxPermission(this.$store),
      usuarioPerm: this.$store.getters.listaPermissoes.colaborador,
      observacoes: '',
      newDadosFluxo: {},
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },
  created() {
    moment.locale('pt-br');
    this.loadInfoCamposFluxo();
  },

  computed: {
    verificaCampos() {
      return (!!this.empresa
      && !!this.unidade
      && !!this.fornecedorSelecionado
      && !!this.pedidos[0]
      && !!this.orcamentoTotal
      && !!this.condicaoPagamentoSelecionado);
    },

    isStatusEncerrado() {
      return this.statusAtendimento === 'Encerrado';
    },
  },
  methods: {
    registraUsuarioMarcacao() {
      this.$emit('confirmacaoRecebimento', this.confirmacaoRecebimento);
    },

    loadInfoCamposFluxo() {
      this.empresa = this.camposFluxo[0].resposta;
      this.unidade = this.camposFluxo[1].resposta;
      this.fornecedorSelecionado = this.camposFluxo[2].resposta;
      this.pedidos[0].material = this.camposFluxo[3].resposta;
      this.pedidos[0].qtTotal = this.camposFluxo[4].resposta;
      this.pedidos[0].orcamento = this.camposFluxo[5].resposta;
      this.pedidos[0].orcamentoTotal = this.camposFluxo[6].resposta;
      this.pedidos[0].centroCustoSelecionado = this.camposFluxo[7].resposta;
      if (this.pedidos[1]) {
        this.pedidos[1].material = this.camposFluxo[8].resposta;
        this.pedidos[1].qtTotal = this.camposFluxo[9].resposta;
        this.pedidos[1].orcamento = this.camposFluxo[10].resposta;
        this.pedidos[1].orcamentoTotal = this.camposFluxo[11].resposta;
        this.pedidos[1].centroCustoSelecionado = this.camposFluxo[12].resposta;
      }
      if (this.pedidos[2]) {
        this.pedidos[2].material = this.camposFluxo[13].resposta;
        this.pedidos[2].qtTotal = this.camposFluxo[14].resposta;
        this.pedidos[2].orcamento = this.camposFluxo[15].resposta;
        this.pedidos[2].orcamentoTotal = this.camposFluxo[16].resposta;
        this.pedidos[2].centroCustoSelecionado = this.camposFluxo[17].resposta;
      }
      this.orcamentoTotal = this.camposFluxo[18].resposta;
      this.condicaoPagamentoSelecionado = this.camposFluxo[19].resposta;
      this.endereco = this.camposFluxo[20].resposta;
      this.confirmacaoRecebimento = this.camposFluxo[21].resposta;
      if (this.camposFluxo[20].resposta) {
        this.numeroAtendimento = this.camposFluxo[22].resposta;
      }
    },

    async salvarFluxoEditado() {
      this.toggleEdit();
      this.alerta = 'success';
      this.msg = 'Formulário editado com sucesso!';
      this.snackBarAlertaCampos = true;
      const body = {
        id: this.atendimentoId,
        campos_fluxo: {
          column_campo_1: this.empresa,
          column_campo_2: this.unidade,
          column_campo_3: this.fornecedorSelecionado,
          column_campo_4: this.pedidos[0].material,
          column_campo_5: this.pedidos[0].qtTotal,
          column_campo_6: this.pedidos[0].orcamento,
          column_campo_7: this.pedidos[0].orcamentoTotal,
          column_campo_8: this.pedidos[0].centroCustoSelecionado,
          column_campo_9: this.pedidos[1] ? this.pedidos[1].material : '',
          column_campo_10: this.pedidos[1] ? this.pedidos[1].qtTotal : '',
          column_campo_11: this.pedidos[1] ? this.pedidos[1].orcamento : '',
          column_campo_12: this.pedidos[1] ? this.pedidos[1].orcamentoTotal : '',
          column_campo_13: this.pedidos[1] ? this.pedidos[1].centroCustoSelecionado : '',
          column_campo_14: this.pedidos[2] ? this.pedidos[2].material : '',
          column_campo_15: this.pedidos[2] ? this.pedidos[2].qtTotal : '',
          column_campo_16: this.pedidos[2] ? this.pedidos[2].orcamento : '',
          column_campo_17: this.pedidos[2] ? this.pedidos[2].orcamentoTotal : '',
          column_campo_18: this.pedidos[2] ? this.pedidos[2].centroCustoSelecionado : '',
          column_campo_19: this.orcamentoTotal,
          column_campo_20: this.condicaoPagamento,
          column_campo_21: this.endereco,
          column_campo_22: this.confirmacaoRecebimento,
          column_campo_23: this.numeroAtendimento,
        },
        responsavel: this.$store.getters.isUsuario.id,
        fluxo_id: this.fluxoId,
      };
      await this.$http.put('/atendimentos/editar/dados-fluxo', body);
    },

    calculaPreco() {
      let total = 0;
      this.pedidos.forEach((item, index) => {
        if (item.orcamento !== null) {
          let justNumbers = item.orcamento.replace('R$ ', '').replace('.', '').replace(',', '.');
          justNumbers = parseFloat(justNumbers);
          const calculation = justNumbers * item.qtTotal;
          total += calculation;
          this.pedidos[index].orcamentoTotal = this.formatNumber(calculation);
        }
      });
      this.orcamentoTotal = this.formatNumber(total);
    },

    formatNumber(value) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency', currency: 'BRL',
      });

      return formatter.format(value);
    },

    toggleEdit() {
      this.editar = !this.editar;
      this.disabled = !this.disabled;
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

#formFormalizarPedidoNacional {
    margin-left: 8px;
    margin-right: 12px;
}

.btn-primary {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border: 1px solid #0056a8 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.btn-primary-disabled {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.count-warning {
  background-color: transparent;
  color: red;
}
</style>
