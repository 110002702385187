<template>
  <v-overlay
    :opacity="opacity"
    :color="colorOverlay"
  >
    <v-progress-circular
      indeterminate
      :size="size"
      :color="colorCircular"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'loader',
  props: {
    size: VueTypes.oneOf([40, 50, 60, 70, 80]).def(50),
    colorCircular: VueTypes.string.def('#56C5C9'),
    colorOverlay: VueTypes.string.def('#122870'),
    opacity: VueTypes.number.def(0.4),
  },
};
</script>

<style>
</style>
