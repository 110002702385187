
const Notify = {
  data() {
    return {
      idStatusEncerrado: 4,
    };
  },
  methods: {
    emiteNotificacao(tipoNotificacao, dados) {
      const topicName = `notify:${dados.id_usuario}`;
      try {
        // o segundo parametro do emitToServer é o nome da função a ser chamada no backend
        this.$ws.$emitToServer(topicName, tipoNotificacao, dados);
      } catch (error) {
        console.error('erro:', error);
      }
    },
    notificaConfirmacao(event, idUsuario, idAtendente, idAtendimento) {
      const dados = {
        id_usuario: idUsuario,
        id_usuario_responsavel: idAtendente,
        id_atendimento: idAtendimento,
        resposta: event,
      };
      this.emiteNotificacao('confirmaRecebimento', dados);
    },

    notificaNovoStatus(status, idUsuario, idAtendente, idAtendimento) {
      if (this.isStatusEncerrado(status)) {
        this.notificaStatusEncerrado(idUsuario, idAtendente, idAtendimento);
        return;
      }
      const dados = {
        id_usuario: idUsuario,
        id_usuario_responsavel: idAtendente,
        id_atendimento: idAtendimento,
      };
      this.emiteNotificacao('status', dados);
    },
    notificaStatusEncerrado(idUsuario, idAtendente, idAtendimento) {
      const dados = {
        id_usuario: idUsuario,
        id_usuario_responsavel: idAtendente,
        id_atendimento: idAtendimento,
      };
      this.emiteNotificacao('encerramento', dados);
    },
    isStatusEncerrado(status) {
      return status === this.idStatusEncerrado;
    },
  },
};


export default Notify;
