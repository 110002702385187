function getPermissionsList(listPermissoes) {
  const lista = [];
  listPermissoes.forEach((permissao) => {
    lista.push({
      id_permissao: permissao.id_permissao,
      id_setor: permissao.setor_id,
      setor_nome: permissao.nome,
    });
  });
  return lista;
}
function validaAtendente(store) {
  return store.getters.isUsuario.permissao.filter(element => element.id_permissao
    === store.getters.listaPermissoes.gestorgeral
    || element.id_permissao === store.getters.listaPermissoes.gestor
    || element.id_permissao === store.getters.listaPermissoes.atendente).length;
}

function getMaxPermission(store) {
  let permissaoMax = 0;
  let permissaoSetor = 0;
  let permissaoNomeSetor;
  const permissoesAtendimentos = [store.getters.listaPermissoes.gestor,
    store.getters.listaPermissoes.atendente,
    store.getters.listaPermissoes.colaborador,
    store.getters.listaPermissoes.gestorgeral,
    store.getters.listaPermissoes.businesspartner];
  store.getters.isUsuario.permissao.forEach((permissao) => {
    if (permissoesAtendimentos.includes(permissao.id_permissao)) {
      if (permissao.id_permissao > permissaoMax) {
        permissaoMax = permissao.id_permissao;
        permissaoSetor = permissao.id_setor;
        permissaoNomeSetor = permissao.setor_nome;
      }
    }
  });
  return { id_permissao: permissaoMax, id_setor: permissaoSetor, setor_nome: permissaoNomeSetor };
}

function validaAprovador(store, setores) {
  const permissoes = store.getters.listaPermissoes;
  const setoresPermitidos = [setores.departamentoDePessoal, setores.juridico];
  const maxPermissionSetor = getMaxPermission(store);

  const validation = store.getters.isUsuario.permissao.filter(element => (element.id_permissao
    === permissoes.gestorgeral && setoresPermitidos.includes(maxPermissionSetor.setor_nome))
    || (element.id_permissao
      === permissoes.gestor && setoresPermitidos.includes(maxPermissionSetor.setor_nome))
    || (element.id_permissao
      === permissoes.aprovador && setoresPermitidos.includes(maxPermissionSetor.setor_nome)));

  return !!validation.length;
}

function validaAprovadorCeo(store, permissaoValida) {
  const validation = store.getters.isUsuario.permissao.filter(element => (element.id_permissao
      === permissaoValida));

  return !!validation.length;
}

function validaPermissao(permissaoList, permissoes, permissaoValida) {
  const validation = permissaoList.filter(element => element.id_permissao
    === permissoes.gestorgeral || element.id_permissao === permissaoValida);

  return !!validation.length;
}

function getSetoresPorAtendente(store, listSetores) {
  const setoresPermitidos = [];

  const permissaoMax = getMaxPermission(store).id_permissao;

  if (permissaoMax === store.getters.listaPermissoes.gestorgeral) {
    return listSetores.map(item => item.id);
  }
  store.getters.isUsuario.permissao.forEach((permissao) => {
    if (permissao.id_permissao > 1) {
      setoresPermitidos.push(permissao.id_setor);
    }
  });

  return setoresPermitidos;
}
function orderByAlphabetical(item, tipo) {
  let ordenado = [];
  if (tipo === 'status') {
    ordenado = item.sort((a, b) => {
      if (a.tipo > b.tipo) {
        return 1;
      }
      if (a.tipo < b.tipo) {
        return -1;
      }
      return 0;
    });
  } else {
    ordenado = item.sort((a, b) => {
      if (a.nome > b.nome) {
        return 1;
      }
      if (a.nome < b.nome) {
        return -1;
      }
      return 0;
    });
  }
  return ordenado;
}

function tipoMascara(mascara) {
  switch (mascara) {
    case 2:
      return '(##) #####-####';
    case 3:
      return '##.###.###/####-##';
    case 4:
      return '#.##.##.###';
    case 6:
      return '#####-###';
    case 9:
      return '###.###.###-##';
    default:
      return null;
  }
}

module.exports = {
  getPermissionsList,
  getMaxPermission,
  validaAtendente,
  getSetoresPorAtendente,
  validaAprovador,
  validaPermissao,
  validaAprovadorCeo,
  orderByAlphabetical,
  tipoMascara,
};
