<template>
  <div>
    <at-ta
      v-if="isAtendente"
      :members="
      members"
      name-key="name"
      @at="getMembers"
      v-model="texto"
    >
      <template
        slot="item"
        slot-scope="s"
      >
        <img
          class="img-size"
          v-if="s.item.avatar"
          :src="`${url}/${s.item.avatar}`"
          alt="avatar"
        >
        <img
          class="img-size"
          v-else
          src="../assets/img/user-placeholder.png"
          alt="avatar"
        >
        <span v-text="s.item.name"></span>
      </template>
      <textarea
        :id="id"
        @input="$emit('input', $event.target.value)"
        class="form-control"
        :placeholder="placeholder"
        :value="value"
      ></textarea>
    </at-ta>
    <template v-else>
      <textarea
        :id="id"
        @input="$emit('input', $event.target.value)"
        class="form-control"
        :placeholder="placeholder"
        :value="value"
      ></textarea>
    </template>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import AtTa from 'vue-at/dist/vue-at-textarea';
import { validaAtendente } from '../utils/utilities';

export default {
  name: 'TextArea',
  components: {
    AtTa,
  },
  props: {
    value: VueTypes.string.isRequired.def(''),
    id: VueTypes.string.def('custom-textarea'),
    placeholder: VueTypes.string.def('Digite aqui...'),
  },
  data() {
    return {
      members: [],
      tag: [],
      texto: '',
      url: process.env.VUE_APP_API_URL,
    };
  },
  created() {
    try {
      this.getUsuarioAtendente();
    } catch (error) {
      console.error(error);
    }
  },
  computed: {
    isAtendente() {
      return validaAtendente(this.$store);
    },
  },
  methods: {
    getMembers(memberName) {
      if (!memberName) {
        return;
      }
      this.members.map((memberItem) => {
        if (memberItem.name.trim() === memberName.trim()) {
          this.tag.push(memberItem);
        }
        return memberItem;
      });
      this.tag = [...new Set(this.tag)];
      this.verifyDeleted();
      this.$emit('usersTag', this.tag);
    },
    verifyDeleted() {
      this.tag = this.tag.filter(tagItem => this.texto.includes(`@${tagItem.name.trim()}`));
    },

    splitNome(nome) {
      const arrayNome = nome.split(' ');
      const nomeFormatado = `${arrayNome[0]} ${arrayNome[arrayNome.length - 1]}`;
      return nomeFormatado;
    },
    async getUsuarioAtendente() {
      try {
        const { data: response } = await this.$http.get(
          '/atendentes',
        );
        this.members = response.map(item => ({
          value: item.id,
          avatar: item.caminho,
          name: this.splitNome(item.nome),
        }));
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  padding: 10px;
  margin: 0;
  width: 100%;
  min-height: 125px;
  height: 125px;
  border-top: 1px solid #bfbfbf;
  border-left: 1px solid #bfbfbf;
  border-right: 1px solid #bfbfbf;
  border-bottom: none;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 0 4.5px 0 rgba(0, 0, 0, 0.25);
  &:focus {
    border: solid 2px #006479;
    box-shadow: 0 0 4.5px 0 rgba(0, 0, 0, 0.25);
  }
}

.img-size {
  width: 20px;
  height: 20px;
}
</style>

<style lang="scss">
.atwho-ul {
  padding-left: 0px !important;
}

.atwho-li span {
  padding-left: 5px;
}
</style>
