<template>
  <div id="FormComprasEPS">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="empresa"
            label="Empresa"
            outlined
            dense
            :disabled="editar"
          >
          </v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>

        <v-col cols="5">
          <v-text-field
            v-model="unidade"
            label="Centro / Unidade"
            outlined
            dense
            :disabled="editar"
          >
          </v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
      </v-row>

      <v-row>
        <v-col cols="11">
          <v-text-field
            v-model="fornecedorSelecionado"
            label="Fornecedor"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="servico"
            label="Serviço"
            desable
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
        <v-col cols="5">
          <v-text-field
            v-model="remoneracaoHora"
            label="Remuneração por Hora"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="centroCustoSelecionado0"
            label="Centro de custo"
            outlined
            dense
            :disabled="editar"
          >
          </v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
        <v-col cols="3">
          <v-text-field
            v-model="quantidadeHoras0"
            label="Quantidade de Horas"
            outlined
            dense
            min="0"
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
        <v-col cols="3">
          <v-text-field
            v-model.lazy="valorTotal0"
            label="Valor Total"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
      </v-row>

      <v-row v-if="centroCustoSelecionado1 || !editar">
        <v-col cols="5">
          <v-text-field
            v-model="centroCustoSelecionado1"
            label="Centro de custo"
            outlined
            dense
            :disabled="editar"
          >
          </v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
        <v-col cols="3">
          <v-text-field
            v-model="quantidadeHoras1"
            label="Quantidade de Horas"
            outlined
            dense
            min="0"
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
        <v-col cols="3">
          <v-text-field
            v-model.lazy="valorTotal1"
            label="Valor Total"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
      </v-row>

      <v-row v-if="centroCustoSelecionado2 || !editar">
        <v-col cols="5">
          <v-text-field
            v-model="centroCustoSelecionado2"
            label="Centro de custo"
            outlined
            dense
            :disabled="editar"
          >
          </v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
        <v-col cols="3">
          <v-text-field
            v-model="quantidadeHoras2"
            label="Quantidade de Horas"
            outlined
            dense
            min="0"
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
        <v-col cols="3">
          <v-text-field
            v-model.lazy="valorTotal2"
            label="Valor Total"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
      </v-row>

      <v-row v-if="centroCustoSelecionado3 || !editar">
        <v-col cols="5">
          <v-text-field
            v-model="centroCustoSelecionado3"
            label="Centro de custo"
            outlined
            dense
            :disabled="editar"
          >
          </v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
        <v-col cols="3">
          <v-text-field
            v-model="quantidadeHoras3"
            label="Quantidade de Horas"
            outlined
            dense
            min="0"
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
        <v-col cols="3">
          <v-text-field
            v-model.lazy="valorTotal3"
            label="Valor Total"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
      </v-row>

      <v-row v-if="centroCustoSelecionado4 || !editar">
        <v-col cols="5">
          <v-text-field
            v-model="centroCustoSelecionado4"
            label="Centro de custo"
            outlined
            dense
            :disabled="editar"
          >
          </v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
        <v-col cols="3">
          <v-text-field
            v-model="quantidadeHoras4"
            label="Quantidade de Horas"
            outlined
            dense
            min="0"
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
        <v-col cols="3">
          <v-text-field
            v-model.lazy="valorTotal4"
            label="Valor Total"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
      </v-row>


      <v-btn
        :class="{ 'btn-primary-disabled ml-0 mt-2': isStatusEncerrado,
                   'btn-primary ml-0 mt-2': !isStatusEncerrado }"
        @click="toggleEdit"
        :disabled="isStatusEncerrado">
        <span v-if="editar">
          <v-icon left>mdi-pencil</v-icon>Editar
        </span>
        <span v-else>
          <v-icon left>mdi-close</v-icon>Cancelar
        </span>
      </v-btn>

      <v-btn
        v-if="!editar"
        class="btn-primary ml-2 mt-2"
        @click="salvarFluxoEditado"
      >
        <v-icon left>mdi-content-save</v-icon>Salvar
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import Snackbar from '../Snackbar';

export default {
  name: 'FormComprasEPS',
  components: { Snackbar },

  props: {
    camposFluxo: VueTypes.array,
    fluxoId: VueTypes.integer,
    atendimentoId: VueTypes.integer,
    statusAtendimento: VueTypes.string,
  },

  data() {
    return {

      empresa: '',

      unidade: '',

      fornecedorSelecionado: '',

      servico: '',
      remoneracaoHora: '',

      centroCustoSelecionado0: '',
      quantidadeHoras0: '',
      valorTotal0: '',
      centroCustoSelecionado1: '',
      quantidadeHoras1: '',
      valorTotal1: '',
      centroCustoSelecionado2: '',
      quantidadeHoras2: '',
      valorTotal2: '',
      centroCustoSelecionado3: '',
      quantidadeHoras3: '',
      valorTotal3: '',
      centroCustoSelecionado4: '',
      quantidadeHoras4: '',
      valorTotal4: '',

      editar: true,
      disabled: true,
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },
  created() {
    this.loadInfoCamposFluxo();
  },

  computed: {
    isStatusEncerrado() {
      return this.statusAtendimento === 'Encerrado';
    },

    verificaCampos() {
      return (!!this.empresa
      && !!this.unidade
      && !!this.remoneracaoHora
      && !!this.fornecedorSelecionado
      && !!this.centroCustoSelecionado0
      && !!this.quantidadeHoras0
      && !!this.valorTotal0);
    },
  },
  methods: {
    loadInfoCamposFluxo() {
      this.empresa = this.camposFluxo[0].resposta;
      this.unidade = this.camposFluxo[1].resposta;
      this.fornecedorSelecionado = this.camposFluxo[2].resposta;
      this.servico = this.camposFluxo[3].resposta;
      this.remoneracaoHora = this.camposFluxo[4].resposta;
      this.centroCustoSelecionado0 = this.camposFluxo[5].resposta;
      this.quantidadeHoras0 = this.camposFluxo[6].resposta;
      this.valorTotal0 = this.camposFluxo[7].resposta;
      this.centroCustoSelecionado1 = this.camposFluxo[8].resposta;
      this.quantidadeHoras1 = this.camposFluxo[9].resposta;
      this.valorTotal1 = this.camposFluxo[10].resposta;
      this.centroCustoSelecionado2 = this.camposFluxo[11].resposta;
      this.quantidadeHoras2 = this.camposFluxo[12].resposta;
      this.valorTotal2 = this.camposFluxo[13].resposta;
      this.centroCustoSelecionado3 = this.camposFluxo[14].resposta;
      this.quantidadeHoras3 = this.camposFluxo[15].resposta;
      this.valorTotal3 = this.camposFluxo[16].resposta;
      this.centroCustoSelecionado4 = this.camposFluxo[17].resposta;
      this.quantidadeHoras4 = this.camposFluxo[18].resposta;
      this.valorTotal4 = this.camposFluxo[19].resposta;
    },

    toggleEdit() {
      this.editar = !this.editar;
      this.disabled = !this.disabled;
    },

    async salvarFluxoEditado() {
      if (!this.verificaCampos) {
        this.alerta = 'error';
        this.msg = 'Existem campos obrigatórios que não foram preenchidos';
        this.snackBarAlertaCampos = true;
      }
      if (this.verificaCampos) {
        this.toggleEdit();

        this.alerta = 'success';
        this.msg = 'Formulário editado com sucesso!';
        this.snackBarAlertaCampos = true;

        const body = {
          id: this.atendimentoId,
          campos_fluxo: {
            column_campo_1: this.empresa,
            column_campo_2: this.centro,
            column_campo_3: this.fornecedorSelecionado,
            column_campo_4: this.servico,
            column_campo_5: this.remoneracaoHora,
            column_campo_6: this.centroCustoSelecionado0,
            column_campo_7: this.quantidadeHoras0,
            column_campo_8: this.valorTotal0,
            column_campo_9: this.centroCustoSelecionado1,
            column_campo_10: this.quantidadeHoras1,
            column_campo_11: this.valorTotal1,
            column_campo_12: this.centroCustoSelecionado2,
            column_campo_13: this.quantidadeHoras2,
            column_campo_14: this.valorTotal2,
            column_campo_15: this.centroCustoSelecionado3,
            column_campo_16: this.quantidadeHoras3,
            column_campo_17: this.valorTotal3,
            column_campo_18: this.centroCustoSelecionado4,
            column_campo_19: this.quantidadeHoras4,
            column_campo_20: this.valorTotal4,

          },
          responsavel: this.$store.getters.isUsuario.id,
          fluxo_id: this.fluxoId,
        };

        await this.$http.put('/atendimentos/editar/dados-fluxo', body);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

#FormComprasEPS {
  margin-left: 12px;
}

.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.btn-primary {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border: 1px solid #0056a8 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.btn-primary-disabled {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.count-warning {
  background-color: transparent;
  color: red;
}
</style>
