var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('Breadcrumb',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_c('hr'),_c('div',{staticClass:"header"},[_c('h1',[_vm._v("Solicitação de Aprovação")])]),_c('div',[_c('Snackbar',{attrs:{"label":_vm.msg,"top":"","right":"","tipo":_vm.tipoSnackbar,"timeout":2500},model:{value:(_vm.snackbarOpen),callback:function ($$v) {_vm.snackbarOpen=$$v},expression:"snackbarOpen"}})],1),_c('div',{staticClass:"ApproveAll"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"btn-dialog",attrs:{"rounded":""},on:{"click":function($event){$event.preventDefault();_vm.dialogFluxoVisualizarAlterados = true}}},[_vm._v(" Visualizar todas Aprovações ")])]}}])},[_c('span',[_vm._v("Visualizar Todas")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(_vm.validaAprovadorFunc)?{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.validaAprovadorFunc)?_c('v-btn',{staticClass:"btn-dialog",attrs:{"rounded":""},on:{"click":function($event){$event.preventDefault();_vm.dialogFluxoAprovarTodos = true}}},[_vm._v(" Aprovar Todas ")]):_vm._e()]}}:null],null,true)},[_c('span',[_vm._v("Aprovar t")])])],1),_c('v-dialog',{attrs:{"max-width":"450"},model:{value:(_vm.dialogFluxoVisualizarAlterados),callback:function ($$v) {_vm.dialogFluxoVisualizarAlterados=$$v},expression:"dialogFluxoVisualizarAlterados"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Atenção!")]),_c('v-card-text',[_c('strong',[_vm._v("Essas são as solicitações aprovadas e recusadas:")])]),_c('RelatorioSolicitacoes'),_c('v-data-table',{attrs:{"headers":_vm.headersAlterados,"items":_vm.fluxosAlterados,"items-per-page":5,"no-data-text":'Não há solicitações para serem aceitas',"footer-props":{
            'items-per-page-text': 'Resultados por página',
            'items-per-page-all-text': 'Todos',
            'page-text': '{0}-{1} de {2}',
          }},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('a',{attrs:{"href":("/atendimento/" + (item.id_atendimento)),"target":"_blank","rel":"noopener"}},[_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","dark":""}},on),[_vm._v(" remove_red_eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver fluxo completo")])])]}}])}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"btn-dialog-cancel mr-2 mb-2",attrs:{"text":""},on:{"click":function($event){_vm.dialogFluxoVisualizarAlterados = false}}},[_vm._v(" Sair ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"350"},model:{value:(_vm.dialogFluxoAprovarTodos),callback:function ($$v) {_vm.dialogFluxoAprovarTodos=$$v},expression:"dialogFluxoAprovarTodos"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Atenção!")]),_c('v-card-text',[_c('strong',[_vm._v("Você tem certeza que deseja aprovar todas as solicitações?")]),_vm._v(". ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"btn-dialog mr-2 mb-2",attrs:{"text":""},on:{"click":function($event){return _vm.aprovarTodos()}}},[_vm._v(" Aprovar Todas ")]),_c('v-btn',{staticClass:"btn-dialog-cancel mr-2 mb-2",attrs:{"text":""},on:{"click":function($event){_vm.dialogFluxoAprovarTodos = false}}},[_vm._v(" Cancelar ")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.fluxos,"items-per-page":5,"no-data-text":'Não há solicitações para serem aceitas',"footer-props":{
      'items-per-page-text': 'Resultados por página',
      'items-per-page-all-text': 'Todos',
      'page-text': '{0}-{1} de {2}',
  }},scopedSlots:_vm._u([{key:"item.roundCheckBoxType",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.roundCheckBoxType)+" ")]}},{key:"item.selectedMonth",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.selectedMonth)+" ")]}},{key:"item.employee",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.employee)+" ")])]}},{key:"item.manager",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.manager)+" ")])]}},{key:"item.reason",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.reason)+" ")])]}},(_vm.validaAprovadorFunc
    || _vm.validaAprovadorCeoFunc)?{key:"item.acoes",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","dark":""},on:{"click":function($event){$event.stopPropagation();_vm.dialogFluxoAprovar = true,
            _vm.comentario = ''}}},on),[_vm._v(" check_circle ")])]}}],null,true)},[_c('span',[_vm._v("Aprovar")])]),_c('v-dialog',{attrs:{"max-width":"auto"},model:{value:(_vm.dialogFluxoAprovar),callback:function ($$v) {_vm.dialogFluxoAprovar=$$v},expression:"dialogFluxoAprovar"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Atenção!")]),_c('v-card-text',[_c('strong',[_vm._v("Você tem certeza que deseja aprovar essa solicitação?")]),_c('br'),(!_vm.validaAprovadorCeoFunc())?_c('strong',[_vm._v(" Caso seja necessário uma aprovação de um superior clique em \"Solicitar Aprovação\".")]):_vm._e()]),_c('v-card-title',{staticClass:"title-atendimento text-center"},[_vm._v("Motivo para aprovar:")]),_c('v-card-text',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-textarea',{attrs:{"label":"Insira o motivo para aprovar essa solicitação","outlined":"","dense":"","clearable":"","auto-grow":""},model:{value:(_vm.comentario),callback:function ($$v) {_vm.comentario=$$v},expression:"comentario"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),(!_vm.validaAprovadorCeoFunc())?_c('v-btn',{staticClass:"btn-dialog-other mr-2 mb-2",attrs:{"text":""},on:{"click":function($event){return _vm.enviaTerceiroAprovador(item)}}},[_vm._v(" Solicitar aprovação ")]):_vm._e(),(_vm.comentario)?_c('v-btn',{staticClass:"btn-dialog mr-2 mb-2",attrs:{"text":""},on:{"click":function($event){return _vm.aprovarItem(item)}}},[_vm._v(" Aprovar ")]):_vm._e(),_c('v-btn',{staticClass:"btn-dialog-cancel mr-2 mb-2",attrs:{"text":""},on:{"click":function($event){_vm.dialogFluxoAprovar = false}}},[_vm._v(" Cancelar ")])],1)],1)],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","dark":""},on:{"click":function($event){$event.stopPropagation();_vm.dialogFluxoRecusar = true,
            _vm.comentario = ''}}},on),[_vm._v(" disabled_by_default ")])]}}],null,true)},[_c('span',[_vm._v("Reprovar")])]),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialogFluxoRecusar),callback:function ($$v) {_vm.dialogFluxoRecusar=$$v},expression:"dialogFluxoRecusar"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Atenção!")]),_c('v-card-text',[_c('strong',[_vm._v("Você tem certeza que deseja recusar essa solicitação?")]),_vm._v(". ")]),_c('v-card-title',{staticClass:"title-atendimento text-center"},[_vm._v("Motivo para recusar:")]),_c('v-card-text',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-textarea',{attrs:{"label":"Insira o motivo para recusar essa solicitação","outlined":"","dense":"","clearable":"","auto-grow":""},model:{value:(_vm.comentario),callback:function ($$v) {_vm.comentario=$$v},expression:"comentario"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),(_vm.comentario)?_c('v-btn',{staticClass:"btn-dialog mr-2 mb-2",attrs:{"text":""},on:{"click":function($event){return _vm.reprovarItem(item)}}},[_vm._v(" Recusar ")]):_vm._e(),_c('v-btn',{staticClass:"btn-dialog-cancel mr-2 mb-2",attrs:{"text":""},on:{"click":function($event){_vm.dialogFluxoRecusar = false}}},[_vm._v(" Cancelar ")])],1)],1)],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('a',{attrs:{"href":("/atendimento/" + (item.id_atendimento)),"target":"_blank","rel":"noopener"}},[_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","dark":""}},on),[_vm._v(" remove_red_eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver fluxo completo")])])]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }