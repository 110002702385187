
export default function auth({ to, next, store }) {
  if (!store.getters.logado) {
    const pagina = window.location.pathname;
    return next({
      name: 'login',
      params: { fromPagina: pagina },
    });
  }
  if (!store.getters.isUsuario.permissao.length) {
    try {
      store.commit('UPDATE_USUARIO', null);
      store.commit('UPDATE_LOGIN', false);
      store.commit('UPDATE_INSCRICAO', null);
      store.commit('UPDATE_USUARIO_AVATAR', null);
      store.commit('UPDATE_MENULATERAL', false);
      localStorage.removeItem('usuario');
      return next({
        name: 'login',
      });
    } catch (error) {
      console.error(error);
    }
  }
  const { permissao } = store.getters.isUsuario;

  const permissaoVisualizarAtendimentos = permissao.filter(element => element.id_permissao
    === store.getters.listaPermissoes.gestorgeral
    || element.id_permissao === store.getters.listaPermissoes.gestor
    || element.id_permissao === store.getters.listaPermissoes.atendente).length;

  if (to.path === '/lista' && !permissaoVisualizarAtendimentos) {
    return next({
      name: 'lista-usuario',
    });
  }

  if (to.path === '/lista-usuario' && permissaoVisualizarAtendimentos) {
    return next({
      name: 'lista',
    });
  }
  return next();
}
