const splitNome = {
  methods: {
    splitNome(nome) {
      if (!nome) return '';
      const arrayNome = nome.split(' ');
      const primeiroUltimoNome = `${arrayNome[0]} ${arrayNome[arrayNome.length - 1]}`;
      return primeiroUltimoNome;
    },
    splitNomeUsuario(nome) {
      if (!nome) return '';
      const arrayNome = nome.split(' ');
      const primeiroUltimoNome = `${arrayNome[0]}.${arrayNome[arrayNome.length - 1]}`;
      return primeiroUltimoNome.toLowerCase();
    },
  },
};

export default splitNome;
