const screenShot = {
  methods: {
    async screenShot(index) {
      const referencia = this.$refs.capture;
      const options = {
        type: 'dataURL',
      };

      if (referencia.length > 1) {
        this.salvarScreenShot(await this.$html2canvas(referencia[index], options), 'screenshot.png');
      } else {
        this.salvarScreenShot(await this.$html2canvas(referencia, options), 'screenshot.png');
      }
    },
    salvarScreenShot(url, filename) {
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
    },
  },
};
export default screenShot;
