<template>
  <div id="formCriarClientesDadosMestres">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
        <h3 class="titleHeader mb-3">Fluxo de Criar Clientes Dados Mestres</h3>
      <v-row>
          <v-col>
            <h5>Atenção</h5>
            <p class="caption">Os campos com
              <span class="count-warning">*</span>
              são obrigatórios.</p>
          </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="empresa"
            label="Empresa"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="razaoCliente"
            label="Razão social do cliente"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div v-if="!editar" class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="4" v-if="cnpj || !editar">
          <v-text-field
            v-model="cnpj"
            label="CNPJ"
            outlined
            dense
            :disabled="editar || hasCpfOrExteriorIndentificator"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
        <span
          style="margin-top: 20px;"
          :disabled="editar" v-if="!editar">OU</span>
        <v-col cols="3" v-if="cpf || !editar">
          <v-text-field
            v-model="cpf"
            label="CPF"
            outlined
            dense
            :disabled="editar || hasCNPJOrExteriorIndentificator"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
        <span
          style="margin-top: 20px;"
          :disabled="editar" v-if="!editar">OU</span>
        <v-col cols="3" v-if="indentificacaoExterior || !editar">
          <v-text-field
            v-model="indentificacaoExterior"
            label="Identificação Exterior"
            outlined
            dense
            :disabled="editar || hasCNPJOrCPF"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="endereco"
            label="Endereço"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div v-if="!editar" class="caption count-warning">*</div>
        <v-col cols="3" v-if="cep || !editar">
          <v-text-field
            v-mask="'#####-###'"
            v-model="cep"
            label="CEP"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div v-if="!editar" class="caption count-warning">*</div>
        <v-col cols="3" v-if="zipCode || !editar">
          <v-text-field
            v-model="zipCode"
            label="ZIPCODE"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div v-if="!editar" class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="cidade"
            label="Cidade"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div v-if="!editar" class="caption count-warning">*</div>

        <v-col cols="3">
          <v-text-field
            v-model="estado"
            label="Estado"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div v-if="!editar" class="caption count-warning">*</div>

        <v-col cols="3">
          <v-text-field
            v-model="pais"
            label="País"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div v-if="!editar" class="caption count-warning">*</div>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-mask="'(##)#####-####'"
            v-model="telefone1"
            label="Telefone Obrigatório"
            outlined
            dense
            :disabled="editar"
          >
          </v-text-field>
        </v-col>
        <div v-if="!editar" class="caption count-warning">*</div>

        <v-col cols="3" v-if=" telefone2 || !editar">
          <v-text-field
            v-mask="'(##)#####-####'"
            v-model="telefone2"
            label="Telefone opcional"
            outlined
            dense
            :disabled="editar"
          >
          </v-text-field>
        </v-col>

        <v-col cols="3" v-if="telefone3 || !editar">
          <v-text-field
            v-mask="'(##)#####-####'"
            v-model="telefone3"
            label="Telefone opcional"
            outlined
            dense
            :disabled="editar"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="emailGeral"
            label="E-mail geral"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
        <div v-if="!editar" class="caption count-warning">*</div>
      </v-row>

      <v-row v-if="emailFaturamento0 || !editar">
        <v-col cols="5">
          <v-text-field
            v-model="emailFaturamento0"
            label="E-mail para faturamento"
            type="text"
            outlined
            dense
            clearable
            auto-grow
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="emailFaturamento1 || !editar">
        <v-col cols="5">
          <v-text-field
            v-model="emailFaturamento1"
            label="E-mail para faturamento"
            type="text"
            outlined
            dense
            clearable
            auto-grow
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="emailFaturamento2 || !editar">
        <v-col cols="5">
          <v-text-field
            v-model="emailFaturamento2"
            label="E-mail para faturamento"
            type="text"
            outlined
            dense
            clearable
            auto-grow
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="emailFaturamento3 || !editar">
        <v-col cols="5">
          <v-text-field
            v-model="emailFaturamento3"
            label="E-mail para faturamento"
            type="text"
            outlined
            dense
            clearable
            auto-grow
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="emailFaturamento4 || !editar">
        <v-col cols="5">
          <v-text-field
            v-model="emailFaturamento4"
            label="E-mail para faturamento"
            type="text"
            outlined
            dense
            clearable
            auto-grow
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="centroLucro0 || !editar">
        <v-col cols="5">
          <v-text-field
            v-model="centroLucro0"
            label="Centro de Lucro"
            class="mb-2"
            outlined
            dense
            :disabled="editar"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="centroLucro1 || !editar">
        <v-col cols="5">
          <v-text-field
            v-model="centroLucro1"
            label="Centro de Lucro"
            class="mb-2"
            outlined
            dense
            :disabled="editar"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="centroLucro2 || !editar">
        <v-col cols="5">
          <v-text-field
            v-model="centroLucro2"
            label="Centro de Lucro"
            class="mb-2"
            outlined
            dense
            :disabled="editar"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="permissaoMax !== colaborador">
        <v-col cols="5">
          <v-text-field
            v-model="infoSAP"
            label="Informação dados SAP"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn
        :class="{ 'btn-primary-disabled ml-1': isStatusEncerrado,
                   'btn-primary ml-1': !isStatusEncerrado }"
        @click="toggleEdit"
        :disabled="isStatusEncerrado">
        <span v-if="editar">
          <v-icon left>mdi-pencil</v-icon>Editar
        </span>
        <span v-else>
          <v-icon left>mdi-close</v-icon>Cancelar
        </span>
      </v-btn>

      <v-btn
        v-if="!editar"
        class="btn-primary ml-2"
        @click="salvarFluxoEditado"
      >
        <v-icon left>mdi-content-save</v-icon>Salvar
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { mask } from 'vue-the-mask';
import Snackbar from '../Snackbar';
import { getMaxPermission } from '../../utils/utilities';

export default {
  name: 'formCriarClientesDadosMestres.vue',
  components: { Snackbar },
  directives: { mask },

  props: {
    camposFluxo: VueTypes.array,
    fluxoId: VueTypes.integer,
    atendimentoId: VueTypes.integer,
    statusAtendimento: VueTypes.string,
  },

  data() {
    return {
      empresa: '',
      razaoCliente: '',
      cnpj: '',
      cpf: '',
      indentificacaoExterior: '',
      endereco: '',
      cep: '',
      zipCode: '',
      cidade: '',
      estado: '',
      pais: '',
      telefone1: '',
      telefone2: '',
      telefone3: '',
      emailGeral: '',
      emailFaturamento0: '',
      emailFaturamento1: '',
      emailFaturamento2: '',
      emailFaturamento3: '',
      emailFaturamento4: '',
      emailsTotal: '',
      centroLucro0: '',
      centroLucro1: '',
      centroLucro2: '',
      colaborador: this.$store.getters.listaPermissoes.colaborador,
      permissaoMax: getMaxPermission(this.$store).id_permissao,

      editar: true,
      disabled: true,
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },
  created() {
    this.loadInfoCamposFluxo();
  },

  computed: {
    hasCpfOrExteriorIndentificator() {
      return !!this.cpf || !!this.indentificacaoExterior;
    },

    hasCNPJOrExteriorIndentificator() {
      return !!this.cnpj || !!this.indentificacaoExterior;
    },

    hasCNPJOrCPF() {
      return !!this.cnpj || !!this.cpf;
    },
    isStatusEncerrado() {
      return this.statusAtendimento === 'Encerrado';
    },

    verificaCampos() {
      return (!!this.empresa
      && !!this.razaoCliente
      && (!!this.cnpj || !!this.cpf || !!this.indentificacaoExterior)
      && !!this.endereco
      && (!!this.cep || !!this.zipCode)
      && !!this.cidade
      && !!this.estado
      && !!this.pais
      && !!this.telefone1
      && !!this.emailGeral
      && this.chechIfneedEmailFaturamento);
    },

    chechIfneedEmailFaturamento() {
      return this.zipCode ? true : !!this.emailFaturamento0;
    },
  },
  methods: {
    loadInfoCamposFluxo() {
      this.empresa = this.camposFluxo[0].resposta;
      this.razaoCliente = this.camposFluxo[1].resposta;
      this.cnpj = this.camposFluxo[2].resposta;
      this.cpf = this.camposFluxo[3].resposta;
      this.indentificacaoExterior = this.camposFluxo[4].resposta;
      this.endereco = this.camposFluxo[5].resposta;
      this.cep = this.camposFluxo[6].resposta;
      this.zipCode = this.camposFluxo[7].resposta;
      this.cidade = this.camposFluxo[8].resposta;
      this.estado = this.camposFluxo[9].resposta;
      this.pais = this.camposFluxo[10].resposta;
      this.telefone1 = this.camposFluxo[11].resposta;
      this.telefone2 = this.camposFluxo[12].resposta;
      this.telefone3 = this.camposFluxo[13].resposta;
      this.emailGeral = this.camposFluxo[14].resposta;
      this.emailFaturamento0 = this.camposFluxo[15].resposta;
      if (this.camposFluxo[16].resposta) {
        this.emailFaturamento1 = this.camposFluxo[16].resposta;
      }
      if (this.camposFluxo[17].resposta) {
        this.emailFaturamento2 = this.camposFluxo[17].resposta;
      }
      if (this.camposFluxo[18].resposta) {
        this.emailFaturamento3 = this.camposFluxo[18].resposta;
      }
      if (this.camposFluxo[19].resposta) {
        this.emailFaturamento4 = this.camposFluxo[19].resposta;
      }
      this.centroLucro0 = this.camposFluxo[20].resposta;
      this.centroLucro1 = this.camposFluxo[21].resposta;
      this.centroLucro2 = this.camposFluxo[22].resposta;
      this.infoSAP = this.camposFluxo[23].resposta;
    },

    toggleEdit() {
      this.editar = !this.editar;
      this.disabled = !this.disabled;
    },

    async salvarFluxoEditado() {
      if (!this.verificaCampos) {
        this.dadosFluxo = { validation: false };
        this.alerta = 'error';
        this.msg = 'Todos os campos com * são obrigatórios.';
        this.snackBarAlertaCampos = true;
      } else {
        this.toggleEdit();

        this.alerta = 'success';
        this.msg = 'Formulário editado com sucesso!';
        this.snackBarAlertaCampos = true;

        const body = {
          id: this.atendimentoId,
          campos_fluxo: {
            column_campo_1: this.empresa,
            column_campo_2: this.razaoCliente,
            column_campo_3: this.cnpj,
            column_campo_4: this.cpf,
            calumn_campo_5: this.indentificacaoExterior,
            column_campo_6: this.endereco,
            column_campo_7: this.cep,
            column_campo_8: this.zipCode,
            column_campo_9: this.cidade,
            column_campo_10: this.estado,
            column_campo_11: this.pais,
            column_campo_12: this.telefone1,
            column_campo_13: this.telefone2 ? this.telefone2 : '',
            column_campo_14: this.telefone3 ? this.telefone3 : '',
            column_campo_15: this.emailGeral,
            column_campo_16: this.emailFaturamento0,
            column_campo_17: this.emailFaturamento1 ? this.emailFaturamento1 : '',
            column_campo_18: this.emailFaturamento2 ? this.emailFaturamento2 : '',
            column_campo_19: this.emailFaturamento3 ? this.emailFaturamento3 : '',
            column_campo_20: this.emailFaturamento4 ? this.emailFaturamento4 : '',
            column_campo_21: this.centroLucro0,
            column_campo_22: this.centroLucro1,
            column_campo_23: this.centroLucro2,
            column_campo_24: this.infoSAP,
          },
          responsavel: this.$store.getters.isUsuario.id,
          fluxo_id: this.fluxoId,
        };
        await this.$http.put('/atendimentos/editar/dados-fluxo', body);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.count-warning {
  background-color: transparent;
  color: red;
}

.btn-primary {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border: 1px solid #0056a8 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

#formCriarClientesDadosMestres {
  margin-left: 8px;
  margin-right: 12px;
}

.btn-primary-disabled {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.v-icon {
  margin-top: 2px;
}

.theme--light.v-input--is-disabled,
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgba(4, 4, 4, 0.75) !important;
}

.checkbox {
  margin-top: 6px;
  padding-left: 20px;
  margin-bottom: -16px;
}

</style>
