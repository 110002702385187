<template>
  <layout @sedeChanged="atualizaSede">
    <Snackbar
      :label="msgSnack"
      v-model="snackbarOpen"
      top
      right
      :timeout="2500"
      tipo="error"
    />
    <Breadcrumb :breadcrumbs="breadcrumbs" />
    <ModalAvaliacaoPendente
      v-if="exibePendentes"
      :pendentes="atendimentosPendentes"
      v-on:atualiza-pendentes="atualizaPendentes"
    />
    <hr />
    <form
      @submit.prevent="checkForm"
      class="form-group text-left"
    >
      <v-row>
        <v-col>
          <div>
            <h3 class="titleHeader mb-3">Setores</h3>
            <div class="assunto">
              <v-btn
                rounded
                class="mr-2"
                :class="{ 'active': index === activeSet,
                'inactive': inactiveSet && index !== activeSet }"
                v-for="(setor, index) in setores"
                :key="index"
                @click.prevent="setActiveSetor(index, setor)"
              >
                {{ setor.nome }}
                <v-icon
                  right
                  small
                  v-if="activeSet === index"
                >
                  mdi-close
                </v-icon>
              </v-btn>
              </div>
            </div>
          <div v-if="setor">

            <ModalPrioridade
            :modalOpen="true"
            v-once
            @prioridadeChanged="adicionaPrioridade"/>

            <div v-if="setorNome === setorAdm || setorNome === setorTI">
              <v-col cols="2">
                <p>Solicitação para sede de:</p>
                  <v-select
                    v-model="sede"
                    :items="sedes"
                    outlined
                    dense
                  ></v-select>
              </v-col>
            </div>
            <h3 class="titleHeader mb-3">Categoria</h3>
            <div class="assunto">
              <v-btn
                rounded
                class="mr-2"
                :class="{ 'active': index === activeCat,
                'inactive': inactiveCat && index !== activeCat }"
                v-for="(cat, index) in catSubExist"
                :key="index"
                @click.prevent="setActive(index, cat)"
              >
                {{ cat.nome }}
                <v-icon
                  right
                  small
                  v-if="activeCat === index"
                >
                  mdi-close
                </v-icon>
              </v-btn>
            </div>
          </div>
          <div v-if="categoria">
            <div v-if="subDiversos === 'Diversos'">
              <h3 class="titleSubcategoria mb-3">Qual o assunto?</h3>
              <input
                name="assunto"
                type="text"
                v-model="sugestao"
                class="inputOutros"
                maxlength="45"
                placeholder="Digite aqui"
              />
            </div>
            <div v-else>
              <h3 v-if="subcat.length !== 0" class="titleSubcategoria mb-3">Subcategoria</h3>
              <v-btn
                rounded
                class="mr-2 mb-2"
                :class="{ 'active': index === activeSubcat,
                'inactive': inactiveSubcat && index !== activeSubcat }"
                v-for="(sub, index) in subcat"
                :key="index"
                @click.prevent="setSubcategoria(index, sub)"
              >
                {{ sub.label }}
                <v-icon
                  right
                  small
                  v-if="activeSubcat === index"
                >
                  mdi-close
                </v-icon>
              </v-btn>
              <v-tooltip
                color="#C1C1C1"
                right
                v-if="tituloFAQ && urlFAQ"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="grey"
                    class="mr-2 mb-2 faq-button"
                    :href="urlFAQ"
                    target="_blank"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      small
                      color="white"
                    >
                      mdi-help
                    </v-icon>
                  </v-btn>
                </template>
                <div class="textFAQ">
                  <p class="font-weight-black mb-0">FAQ - {{ tituloFAQ }}</p>
                  <p class="mb-0">{{ textoFAQ }}</p>
                </div>
              </v-tooltip>
            </div>
          </div>
              <v-dialog v-if="hasAviso === true"
                v-model="hasAviso"
                max-width="350">
              <v-card>
                <v-card-title class="headline">{{tituloAviso[0].value}}</v-card-title>
                <v-card-text>
                  <p class='textObservacoes' v-html="msgObservacoesAviso"></p>
                </v-card-text>
              <v-card-actions>
                <v-btn v-if="tituloAviso[0].id === 3"
                  class="btn-dialog mr-2 mb-2"
                  text
                  @click="closeModal">
                  Retornar
                </v-btn>
                <v-btn v-else-if="tituloAviso[0].id"
                  class="btn-dialog mr-2 mb-2"
                  text
                  @click="hasAviso=false">
                  Estou ciente
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <div v-if="setor && categoria && subcategoria && msgObservacoes !== ''">
            <h3 class="titleSubcategoria font-weight-bold mb-3">Observações</h3>
            <p class='textObservacoes' v-html="msgObservacoes"></p>
            <div
              v-for="(item, index) in outrosAnexos"
              :key="index"
            >
              <v-btn
                rounded
                :class="anexoObservacoes.length > 1 ? 'mr-2' : 'mr-0'"
                @click="downloadAnexo(item)"
              >
                <v-icon
                  color="#0056a8"
                  left
                  small
                >attachment</v-icon>
                {{ item.nome }}
              </v-btn>
            </div>
            <div
              v-for="(item, index) in imageSources"
              :key="'imagem' + index"
            >
              <img
                :src="imageSources[index]"
                alt="imagem"
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <InputSelect
            v-if="showSedeSelect"
            :class="!categoria || 'mb-5'"
            label="Sede"
            v-model="sede"
            :options="sedes"
            :disabled="sedeDefinida"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!-- A Label de todos os Fluxos -->
          <div v-if="isFluxoDesenvolvido">
            <FluxoLabels
              :fluxoID="fluxoID"
              @dadosFluxo="recebeDadosFluxo($event)"
              ref="recebeFluxo"
            />
          </div>

          <div v-else-if="loadFluxo && fluxoID === fluxoRecisao">
            <Desligamento
              @dadosFluxo="recebeDadosFluxo($event)"
              ref="recebeFluxo"
            />
          </div>
          <div v-else-if="loadFluxo && fluxoID === fluxoSolicitarMotoboy">
            <SolicitarMotoboy
              @dadosFluxo="recebeDadosFluxo($event)"
              ref="recebeFluxo"
            />
          </div>
          <div v-else-if="loadFluxo && fluxoID === fluxoSolicitarCoffeeBreak">
            <SolicitarCoffeeBreak
              @dadosFluxo="recebeDadosFluxo($event)"
              ref="recebeFluxo"
            />
          </div>
          <div v-else-if="loadFluxo && fluxoID === fluxoReajusteSalario">
            <Reajuste
              @dadosFluxo="recebeDadosFluxo($event)"
              ref="recebeFluxo"
            />
          </div>
          <div v-else-if="loadFluxo && fluxoID === fluxoComprasOrdinarias">
            <ComprasOrdinarias
              @dadosFluxo="recebeDadosFluxo($event)"
              ref="recebeFluxo"
            />
          </div>
          <div v-else-if="loadFluxo && fluxoID === fluxoComprasTecnicasNacionais">
            <ComprasTecnicasNacionais
              @dadosFluxo="recebeDadosFluxo($event)"
              ref="recebeFluxo"
            />
          </div>
          <div v-else-if="loadFluxo && fluxoID === fluxoComprasTecnicasInternacionais">
            <ComprasTecnicasInternacionais
              @dadosFluxo="recebeDadosFluxo($event)"
              ref="recebeFluxo"
            />
          </div>
          <div v-else-if="loadFluxo && fluxoID === fluxoComprasFixas">
            <ComprasFixas
              @dadosFluxo="recebeDadosFluxo($event)"
              ref="recebeFluxo"
            />
          </div>
          <div v-else-if="loadFluxo && fluxoID === fluxoComprasEPS">
            <ComprasEPS
              @dadosFluxo="recebeDadosFluxo($event)"
              ref="recebeFluxo"
            />
          </div>
          <div v-else-if="loadFluxo && fluxoID === fluxoRealocacao">
            <Realocacao
              @dadosFluxo="recebeDadosFluxo($event)"
              ref="recebeFluxo"
            />
          </div>
          <div v-else-if="loadFluxo && fluxoID === fluxoCriarFornecedores">
            <CriarFornecedoresDadosMestres
              @dadosFluxo="recebeDadosFluxo($event)"
              ref="recebeFluxo"
            />
          </div>
          <div v-else-if="loadFluxo && fluxoID === fluxoModificarFornecedor">
            <ModificarFornecedor
              @dadosFluxo="recebeDadosFluxo($event)"
              ref="recebeFluxo"
            />
          </div>
          <div v-else-if="loadFluxo && fluxoID === fluxoCriarMaterialServico">
            <CriarMaterialServico
              @dadosFluxo="recebeDadosFluxo($event)"
              ref="recebeFluxo"
            />
          </div>
          <div v-else-if="loadFluxo && fluxoID === fluxoCriarClientesDadosMestres">
            <CriarClientesDadosMestres
               @dadosFluxo="recebeDadosFluxo($event)"
              ref="recebeFluxo"
            />
          </div>
          <div v-else-if="loadFluxo && fluxoID === fluxoModificarCliente">
            <ModificarCliente
              @dadosFluxo="recebeDadosFluxo($event)"
              ref="recebeFluxo"
            />
          </div>
          <div v-else-if="loadFluxo && fluxoID === fluxoCriarServicoCliente">
            <CriarServicoCliente
              @dadosFluxo="recebeDadosFluxo($event)"
              ref="recebeFluxo"
            />
          </div>
          <div>
            <h3 class="titleHeader mb-3">Mensagem</h3>
            <TextArea
              class="txt-margin"
              v-model="descricao"
              placeholder="Descrição..."
              custom_id="textarea01"
            />
            <InputFile
              class="txt-margin"
              @hasError="updateFileError"
              ref="inputfile"
            />
            </div>
            <div class="button-right">
              <Botao type="submit" label="Enviar" class="mr-3"/>
            </div>
        </v-col>
      </v-row>
    </form>
  </layout>
</template>

<script>
import Swal from 'sweetalert2';
import isEmpty from 'lodash/isEmpty';
import Snackbar from '../components/Snackbar.vue';
import layout from '../layouts/Layout.vue';
import TextArea from '../components/TextArea.vue';
import Botao from '../components/Botao.vue';
import Breadcrumb from '../components/Breadcrumb';
import InputSelect from '../components/InputSelect.vue';
import InputFile from '../components/InputFile.vue';
import ModalAvaliacaoPendente from '../components/ModalAvaliacaoPendente.vue';
import FluxoLabels from '../components/fluxos/FluxoLabels.vue';
import Desligamento from '../components/fluxos/Desligamento.vue';
import SolicitarMotoboy from '../components/fluxos/SolicitarMotoboy';
import SolicitarCoffeeBreak from '../components/fluxos/SolicitarCoffeeBreak';
import ComprasOrdinarias from '../components/fluxos/ComprasOrdinarias.vue';
import ComprasTecnicasNacionais from '../components/fluxos/ComprasTecnicasNacionais.vue';
import ComprasTecnicasInternacionais from '../components/fluxos/ComprasTecnicasInternacionais.vue';
import ComprasFixas from '../components/fluxos/ComprasFixas.vue';
import ComprasEPS from '../components/fluxos/ComprasEPS.vue';
import Realocacao from '../components/fluxos/Realocacao.vue';
import CriarFornecedoresDadosMestres from '../components/fluxos/CriarFornecedoresDadosMestres.vue';
import CriarClientesDadosMestres from '../components/fluxos/CriarClientesDadosMestres.vue';
import Reajuste from '../components/fluxos/Reajuste';
import ModificarFornecedor from '../components/fluxos/ModificarFornecedor.vue';
import CriarMaterialServico from '../components/fluxos/CriarMaterialServico.vue';
import ModificarCliente from '../components/fluxos/ModificarCliente.vue';
import CriarServicoCliente from '../components/fluxos/CriarServicoCliente.vue';
import { getMaxPermission, orderByAlphabetical, validaPermissao } from '../utils/utilities';
import { setores, fluxos, status } from '../Constants.js';
import ModalPrioridade from '../components/ModalPrioridade.vue';

export default {
  name: 'atendimento',
  components: {
    layout,
    Snackbar,
    TextArea,
    Breadcrumb,
    Botao,
    InputSelect,
    InputFile,
    ModalAvaliacaoPendente,
    FluxoLabels,
    Desligamento,
    SolicitarMotoboy,
    SolicitarCoffeeBreak,
    Reajuste,
    ComprasOrdinarias,
    ComprasTecnicasNacionais,
    ComprasTecnicasInternacionais,
    ComprasFixas,
    ComprasEPS,
    Realocacao,
    CriarFornecedoresDadosMestres,
    ModificarFornecedor,
    CriarClientesDadosMestres,
    CriarMaterialServico,
    ModificarCliente,
    CriarServicoCliente,
    ModalPrioridade,
  },
  data() {
    return {
      idCategoria: [],
      tipoDesligamento: null,
      activeCat: null,
      inactiveCat: false,
      activeSubcat: null,
      inactiveSubcat: false,
      activeSet: null,
      inactiveSet: false,
      msg: '',
      msgSnack: '',
      buttonText: 'OK',
      icon: '',
      sede: null,
      atendente: '',
      descricao: '',
      permissaoGestor: this.$store.getters.listaPermissoes.gestor,
      permissaoGestorGeral: this.$store.getters.listaPermissoes.gestorgeral,
      permissaoAtendente: this.$store.getters.listaPermissoes.atendente,
      permissaoAutomationcenter: this.$store.getters.listaPermissoes.automationcenter,
      listaPermissoesUsuario: this.$store.getters.isUsuario.permissao,
      setorAdm: setores.adminstrativo,
      setorJuridico: setores.juridico,
      setorTI: setores.TI,
      setorDev: setores.desenvolvimento,
      setorDp: setores.departamentoDePessoal,
      setorAutomationCenter: setores.automationcenter,
      atendenteTipo: getMaxPermission(this.$store),
      setor: '',
      setorNome: '',
      categoria: '',
      setor_id: '',
      subcategoria: '',
      snackbarOpen: false,
      showSedeSelect: true,
      sedeDefinida: false,
      setores: [],
      catSubExist: [],
      subcat: [],
      subDiversos: '',
      sedes: [],
      fileError: null,
      breadcrumbs: [],
      nome: this.$store.getters.isUsuario.nome,
      msgObservacoes: '',
      msgObservacoesAviso: '',
      anexoObservacoes: [],
      atendimentosPendentes: [],
      exibePendentes: false,
      sugestao: '',
      tituloFAQ: '',
      textoFAQ: 'Clique no botão para mais informações.',
      urlFAQ: '',
      dadosFluxo: [],
      fluxos: [],
      fluxoSubcatID: '',
      tituloAviso: '',
      fluxoID: '',
      loadFluxo: false,
      msgSnackFluxo: '',
      imageTypes: ['jpeg', 'jpg', 'png', 'gif', 'svg'],
      imageSources: [],
      outrosAnexos: [],
      hasAviso: false,
      fluxoRecisao: fluxos.recisao,
      fluxoSolicitarMotoboy: fluxos.solicitarMotoboy,
      fluxoSolicitarCoffeeBreak: fluxos.solicitarCoffeeBreak,
      fluxoReajusteSalario: fluxos.reajuste,
      fluxoComprasOrdinarias: fluxos.comprasOrdinarias,
      fluxoModificarFornecedor: fluxos.modificarFornecedor,
      fluxoComprasTecnicasNacionais: fluxos.comprasTecnicasNacionais,
      fluxoComprasTecnicasInternacionais: fluxos.comprasTecnicasInternacionais,
      fluxoComprasFixas: fluxos.comprasFixas,
      fluxoComprasEPS: fluxos.comprasEPS,
      fluxoRealocacao: fluxos.realocacao,
      fluxoCriarFornecedores: fluxos.criarFornecedoresDadosMestres,
      fluxoCriarClientesDadosMestres: fluxos.criarClientesDadosMestres,
      fluxoCriarMaterialServico: fluxos.criarMaterialServico,
      fluxoModificarCliente: fluxos.modificarCliente,
      fluxoCriarServicoCliente: fluxos.criarServicoCliente,
      prioridade: '',
    };
  },
  mounted() {
    this.breadcrumbs = [
      {
        route: {
          name: 'lista',
        },
        label: 'Atendimentos',
      },
      {
        route: {
          name: 'novo',
        },
        label: 'Novo atendimento',
      },
    ];
  },

  created() {
    try {
      this.getFluxos();
      this.getSetores();
      this.verificaAtendimentosPendentes();
      this.getSedes();
      this.getSedeAtual();
    } catch (error) {
      console.error(error);
    }
  },

  computed: {
    isFluxoDesenvolvido() {
      return (this.loadFluxo
            && this.fluxoID !== this.fluxoRecisao
            && this.fluxoID !== this.fluxoSolicitarMotoboy
            && this.fluxoID !== this.fluxoReajusteSalario
            && this.fluxoID !== this.fluxoSolicitarCoffeeBreak
            && this.fluxoID !== this.fluxoComprasOrdinarias
            && this.fluxoID !== this.fluxoComprasTecnicasNacionais
            && this.fluxoID !== this.fluxoComprasTecnicasInternacionais
            && this.fluxoID !== this.fluxoComprasFixas
            && this.fluxoID !== this.fluxoComprasEPS
            && this.fluxoID !== this.fluxoRealocacao
            && this.fluxoID !== this.fluxoCriarFornecedores
            && this.fluxoID !== this.fluxoModificarFornecedor
            && this.fluxoID !== this.fluxoCriarMaterialServico
            && this.fluxoID !== this.fluxoCriarClientesDadosMestres
            && this.fluxoID !== this.fluxoModificarCliente
            && this.fluxoID !== this.fluxoCriarServicoCliente);
    },
  },

  methods: {

    adicionaPrioridade(valor) {
      this.prioridade = valor;
    },

    validaSetorDev() {
      const permissao = getMaxPermission(this.$store).id_permissao;
      return (permissao === this.permissaoGestor
            || permissao === this.permissaoGestorGeral
            || (permissao === this.permissaoAtendente
            && this.atendenteTipo.setor_nome === this.setorDev));
    },

    async getFluxos() {
      try {
        const { data: response } = await this.$http.get('/fluxos/');
        this.fluxos = response;
      } catch (error) {
        console.error(error);
      }
    },
    recebeDadosFluxo(e) {
      this.dadosFluxo = e;
    },

    async atualizaPendentes() {
      await this.verificaAtendimentosPendentes();
    },

    async verificaAtendimentosPendentes() {
      try {
        const idUsuario = this.$store.getters.isUsuario.id;
        const { data: response } = await this.$http.get(`/atendimentos/avaliacao-pendente/${idUsuario}`);
        if (!isEmpty(response)) {
          let atendimentos = {};
          this.atendimentosPendentes = [];
          response.forEach((element) => {
            atendimentos = {
              url: `/atendimento/${element.id}`,
              titulo: `[${element.id}] - ${element.nome}`,
              data: element.updated_at,
            };
            this.atendimentosPendentes.push(atendimentos);
          });
          this.exibePendentes = true;
        } else {
          this.exibePendentes = false;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async checkForm(e) {
      if (!this.setor || !this.categoria
        || (!this.subcategoria && this.subcat.length > 0 && !this.sugestao.trim())) {
        this.snackbarOpen = true;
        this.msgSnack = 'Todos os campos são obrigatórios.';
        return;
      }

      if (this.subDiversos) {
        await this.getSubcategoriaPorNome(this.categoria, 'Diversos');
      }

      if (!this.descricao && this.dadosFluxo) {
        this.fluxos.forEach((fluxo) => {
          if (fluxo.id === this.fluxoID) this.descricao = fluxo.nome;
        });
      }

      if ((this.sede)
        && this.descricao && this.categoria && this.subcategoria && !this.loadFluxo) {
        this.setAtendimento(this.$refs.inputfile.getFiles());
        this.$refs.inputfile.removeFiles();
      }

      if (this.fluxoSubcatID === this.subcategoria) {
        this.$refs.recebeFluxo.enviaJSON();

        if (!this.dadosFluxo.validation) this.msgSnackFluxo = this.dadosFluxo.errorMsg;
        else {
          this.setAtendimento(this.$refs.inputfile.getFiles());
          this.$refs.inputfile.removeFiles();
        }
      }

      if (!this.sede) {
        this.snackbarOpen = true;
        this.msgSnack = 'A sede é obrigatória.';
      }
      this.$forceUpdate();
      e.preventDefault();
    },
    setActiveSetor(index, setor) {
      if (index === this.activeSet) {
        this.activeSet = null;
        this.activeCat = null;
        this.activeSubcat = null;
        this.tituloFAQ = '';
        this.urlFAQ = '';
        this.setor = '';
        this.categoria = '';
        this.setor_id = '';
        this.subcategoria = '';
        this.anexoObservacoes = [];
        this.imageSources = [];
        this.outrosAnexos = [];
        this.inactiveSet = false;
        this.inactiveCat = false;
        this.inactiveSubcat = false;
        this.loadFluxo = false;
      } else {
        this.activeSet = index;
        this.setor = setor.id;
        this.setorNome = setor.nome;
        this.inactiveSet = true;
        this.subcategoria = '';
        this.subcat = [];
        this.getCategoria();
      }
    },
    setActive(index, cat) {
      if (index === this.activeCat) {
        this.activeCat = null;
        this.activeSubcat = null;
        this.tituloFAQ = '';
        this.urlFAQ = '';
        this.categoria = '';
        this.setor_id = '';
        this.subcategoria = '';
        this.anexoObservacoes = [];
        this.imageSources = [];
        this.outrosAnexos = [];
        this.inactiveCat = false;
        this.inactiveSubcat = false;
        this.loadFluxo = false;
      } else {
        this.activeCat = index;
        this.categoria = cat.id;
        this.setor_id = cat.setor_id;
        this.inactiveCat = true;
        this.subcategoria = '';
        this.subcat = [];
        this.getSubcategoria();
      }
    },
    setSubcategoria(index, subcategoria) {
      if (index === this.activeSubcat) {
        this.activeSubcat = null;
        this.subcategoria = '';
        this.tituloFAQ = '';
        this.urlFAQ = '';
        this.msgObservacoes = '';
        this.anexoObservacoes = [];
        this.imageSources = [];
        this.outrosAnexos = [];
        this.inactiveSubcat = false;
        this.loadFluxo = false;
      } else {
        this.activeSubcat = index;
        this.subcategoria = subcategoria.value;
        this.tituloFAQ = subcategoria.label;
        this.inactiveSubcat = true;
        this.fluxos.forEach((fluxo) => {
          if (!fluxo.desativado && (fluxo.subcategoria_id === this.subcategoria)) {
            this.fluxoID = fluxo.id;
            this.fluxoSubcatID = fluxo.subcategoria_id;
          }
        });
        if (this.fluxoSubcatID === this.subcategoria) {
          this.loadFluxo = true;
        }
        this.carregaObservacoes();
        this.carregaFAQ();
        this.carregaAviso();
      }
    },
    async getSubcategoriaPorNome(idCategoria, nome) {
      try {
        const { data: response } = await this.$http.get(`/subcategoria-nome/${idCategoria}/${nome}`);
        this.subcategoria = response[0].id;
        this.subcategoria = this.subcategoria.filter(all => ['Venda de férias', 'Solicitação de Pagamento RPA', 'Ticket Combustível'].indexOf(all.nome !== -1));
      } catch (error) {
        console.error(error);
      }
    },
    async getSetores() {
      try {
        const { data: response } = await this.$http.get('/setores');
        let responseTratado = orderByAlphabetical(response, 'setores');
        if (!validaPermissao(this.$store.getters.isUsuario.permissao,
          this.$store.getters.listaPermissoes, this.permissaoAutomationcenter)) {
          responseTratado = responseTratado.filter(item => item.nome
            !== this.setorAutomationCenter);
        }
        if (!this.validaSetorDev()) {
          responseTratado = responseTratado.filter(element => element.nome !== this.setorDev);
        }

        this.setores = responseTratado;
      } catch (error) {
        console.error(error);
      }
    },
    async getCategoria() {
      this.$store.commit('UPDATE_LOADING', true);
      try {
        const { data: response } = await this.$http.get(`/categorias/${this.setor}`);
        this.catSubExist = orderByAlphabetical(response, 'categorias');
        this.$store.commit('UPDATE_LOADING', false);
      } catch (error) {
        this.$store.commit('UPDATE_LOADING', false);
        console.error(error);
      }
    },
    async getSubcategoria() {
      this.$store.commit('UPDATE_LOADING', true);
      try {
        const { data: response } = await this.$http.get(`/subcategorias/${this.categoria}`);
        this.subcat = orderByAlphabetical(response, 'subcategorias').map(item => ({
          value: item.id,
          label: item.nome,
        }));
        this.subDiversos = this.subcat[0].label;
        this.$store.commit('UPDATE_LOADING', false);
      } catch (error) {
        this.$store.commit('UPDATE_LOADING', false);
        console.error(error);
      }
    },
    async carregaObservacoes() {
      this.carregaMensagem();
      this.carregaAnexos();
    },
    async carregaFAQ() {
      try {
        const { data: response } = await this.$http.get(`/mostrar-mensagem-faq/${this.categoria}/${this.subcategoria}`);
        if (!isEmpty(response)) {
          this.urlFAQ = response[0].url_socialwork;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async carregaMensagem() {
      try {
        const { data: response } = await this.$http.get(`/mensagens-sugestoes/subcategoria/${this.subcategoria}`);
        if (!isEmpty(response)) {
          this.msgObservacoes = response[0].mensagem;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async carregaAviso() {
      try {
        const { data: response } = await this.$http.get(`/aviso/subcategoria/${this.subcategoria}`);
        const { data: responseTipoAviso } = await this.$http.get('/tipo-aviso');
        const tiposAvisos = responseTipoAviso.map(item => ({
          id: item.id,
          value: item.descricao,
        }));
        if (response) {
          this.hasAviso = true;
          this.msgObservacoesAviso = response.descricao;
          this.tipoAviso = response.tipo_aviso_id;
          this.tituloAviso = tiposAvisos.filter(item => item.id === this.tipoAviso);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async carregaAnexos() {
      try {
        const { data: response } = await this.$http.get(`/arquivos-sugestoes/${this.categoria}/${this.subcategoria}`);
        if (!isEmpty(response)) {
          let anexo = {};
          let nomeAnexo = [];
          response.forEach((element) => {
            nomeAnexo = element.nome;
            anexo = {
              nome: nomeAnexo,
              url: `/arquivos-sugestoes-download/${element.id}`,
              imageUrl: '/arquivos-sugestoes-imagem/',
            };
            this.anexoObservacoes.push(anexo);
          });
          this.separaImagens();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async closeModal() {
      this.hasAviso = false;
      this.setSubcategoria(this.activeSubcat, 0);
    },
    async downloadAnexo(anexo) {
      try {
        this.$http({
          url: anexo.url,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', anexo.nome);
          document.body.appendChild(fileLink);

          fileLink.click();
        });
      } catch (error) {
        console.error(error);
      }
    },

    separaImagens() {
      this.imageSources = [];
      this.outrosAnexos = [];
      const imagens = [];
      this.anexoObservacoes.forEach((element) => {
        if (this.isImage(element)) {
          imagens.push(element);
        } else {
          this.outrosAnexos.push(element);
        }
      });
      this.getImages(imagens);
    },

    isImage(anexo) {
      const type = anexo.nome.split('.');
      if (this.imageTypes.includes(type[type.length - 1])) {
        return true;
      }
      return false;
    },

    async getImages(imagens) {
      const imagemNomes = imagens.map(element => element.nome);

      const { data: response } = await this.$http.post('/arquivos-sugestoes-imagem', {
        imagens: imagemNomes,
      });

      this.imageSources = response;
    },

    async getSedes() {
      try {
        const { data: response } = await this.$http.get('/sedes/');
        this.sedes = orderByAlphabetical(response, 'sedes').map(item => ({
          value: item.id,
          text: item.nome,
          label: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    showAlert() {
      Swal.fire({
        text: this.msg,
        icon: this.icon,
        confirmButtonText: this.buttonText,
      });
    },

    getSedeAtual() {
      this.sede = this.$store.getters.isUsuario.sede_id || '';
      this.showSedeSelect = !this.sede;
    },
    atualizaSede() {
      this.sede = this.$store.getters.isUsuario.sede_id || '';
      this.sedeDefinida = !!this.sede;
    },
    async setAtendimento(files) {
      try {
        if (this.showSedeSelect && !this.sedeDefinida) {
          await this.salvaSede();
        }
        const formData = new FormData();
        for (let index = 0; index < files.length; index += 1) {
          formData.append('envios', files[index]);
        }
        formData.append('descricao', this.descricao);
        formData.append('usuario_id', this.$store.getters.isUsuario.id);
        formData.append('sede_id', this.sede);
        formData.append('subcategoria_id', this.subcategoria);
        formData.append('setor_id', this.setor_id);
        formData.append('status_id', status.pendente);
        formData.append('sugestao', this.sugestao);
        formData.append('id_prioridade', this.prioridade);
        if (this.dadosFluxo.tipoDesligamento === 'EPS') {
          formData.append('emails_alternativos', ['financeiro@meta.com.br', 'juridico@meta.com.br', 'desligamentos@meta.com.br', 'gestaodepessoas@meta.com.br', 'GD.CSC.FORNECEDORES@meta.com.br']);
        }
        if (this.fluxoSubcatID === this.subcategoria) {
          const body = {
            fluxo_id: this.fluxoID,
            fluxo: this.dadosFluxo,
          };
          await this.$http.post('/atendimentos/criar/dados-fluxo', body).then(({ data }) => {
            formData.append('id_fluxo_respostas', data.id_fluxo_respostas);
            formData.append('info_fluxo', JSON.stringify(data.info_fluxo));
            formData.append('fluxo_id', this.fluxoID);
            this.$http.post('/atendimentos/', formData);
          });
        } else {
          this.$http.post('/atendimentos/', formData);
        }
        this.msg = 'Atendimento aberto!';
        this.icon = 'success';
        this.showAlert();
        this.$router.push('/lista-usuario');
      } catch (e) {
        console.error(e);
        this.msg = 'Erro ao abrir atendimento!';
        this.icon = 'error';
        this.showAlert();
      }
    },
    updateFileError(value) {
      this.fileError = value;
    },
    async salvaSede() {
      try {
        const { id } = this.$store.getters.isUsuario;
        await this.$http.put(`/usuarios/${id}`, { sede_id: this.sede });
        await this.updateUsuario();
      } catch (e) {
        console.error(e);
      }
    },
    ByAlphabetical(item, tipo) {
      let ordenado = [];
      if (tipo === 'status') {
        ordenado = item.sort((a, b) => {
          if (a.tipo > b.tipo) {
            return 1;
          }
          if (a.tipo < b.tipo) {
            return -1;
          }
          return 0;
        });
      } else {
        ordenado = item.sort((a, b) => {
          if (a.nome > b.nome) {
            return 1;
          }
          if (a.nome < b.nome) {
            return -1;
          }
          return 0;
        });
      }
      return ordenado;
    },
  },
  async updateUsuario() {
    try {
      const { data: response } = await this.$http.get(`/usuarios/${this.$store.getters.isUsuario.id}`);
      this.usuario = response;
      response.permissao = getMaxPermission(this.$store).id_permissao;
      this.$store.commit('UPDATE_USUARIO', response);
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style lang="scss">
.swal2-styled.swal2-confirm {
  border-radius: 5px !important;
  background-color: #fbfbfb !important;
  color: #122870 !important;
}
</style>

<style scoped lang="scss">
.faq-button {
  border-radius: 36px;
}

.textFAQ {
  color: black;
}
.inputOutros {
  color: white;
  background-color: #c1c1c1;
  height: 36px;
  border-radius: 28px;
  padding: 16px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: white;
  }
}

.titleHeader {
  margin-top: 5px;
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.titleSubcategoria {
  font-size: 18px;
  margin-top: 10px;
  font-weight: 500;
  color: #303030;
}

.textObservacoes {
  font-size: 16px;
}

.assunto {
  display: flex;
  flex-wrap: wrap;
}

.button-right {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  margin-right: -8px;
}

img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 500px;
}

.v-btn {
  color: #303030;
  letter-spacing: 0;
  text-transform: none;
  border: 1px solid rgba(86, 197, 201, 0.5);
  box-shadow: none;
  transition: all 0.5s ease;
  margin-top: 5px;
  &:hover {
    color: #ffffff !important;
    background-color: #0056a8 !important;
    border-color: #0056a8;
  }
  &.active {
    color: #fff !important;
    background-color: #0056a8 !important;
    border-color: #0056a8 !important;
  }
  &.inactive {
    display: none;
  }
}
</style>
