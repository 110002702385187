<template>
  <layout>
    <Breadcrumb :breadcrumbs="breadcrumbs" />
    <hr />
    <v-tabs>
      <v-tab>
        <v-icon left>mdi-lock</v-icon>
        Permissões
      </v-tab>
      <v-tab @click="atualizaDirecionamentos">
        <v-icon left>mdi-earth</v-icon>
        Direcionamentos
      </v-tab>
      <v-tab>
        <v-icon left>mdi-comment-alert</v-icon>
        Feedback
      </v-tab>
      <v-tab>
        <v-icon left>mdi-comment-text</v-icon>
        Cadastro de Sugestões
      </v-tab>
      <v-tab>
        <v-icon left>mdi-alert</v-icon>
        Cadastro de Avisos
      </v-tab>
      <v-tab>
        <v-icon left>mdi-help</v-icon>
        FAQs
      </v-tab>
      <v-tab>
        <v-icon left>mdi-call-split</v-icon>
        Fluxos
      </v-tab>
      <v-tab>
        <v-icon left>mdi-account-supervisor</v-icon>
        Backoffice
      </v-tab>
      <v-tab>
        <v-icon left>mdi-delete</v-icon>
        Opções inativas
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              Gerenciamento de permissões.
            </p>
            <BuscaUsuarios />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              Gerenciamento de direcionamentos.
            </p>
            <Direcionamentos :key="updateDirecionamentos" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p class="mb-0">
              Feedback
            </p>
            <GerenciamentoFeedbacks />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              Cadastro de Sugestões
            </p>
            <CadastroSugestoes />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              Cadastro de Avisos
            </p>
            <CadastroAvisos />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              Cadastro de FAQs
            </p>
            <CadastroFAQs />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              Edição de Fluxos
            </p>
            <Fluxos />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              Criação e edição de setores, categorias e subcategorias.
            </p>
            <GestaoSetoresCategoriasSub :key="updateDirecionamentos" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              Visualização e reativação de setores, categorias e subcategorias inativas.
            </p>
            <OpcoesInativas />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </layout>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import layout from '../layouts/Layout.vue';
import BuscaUsuarios from '../components/BuscaUsuarios.vue';
import Direcionamentos from '../components/Direcionamentos.vue';
import GestaoSetoresCategoriasSub from '../components/GestaoSetoresCategoriasSub.vue';
import GerenciamentoFeedbacks from '../components/GerenciamentoFeedbacks.vue';
import CadastroSugestoes from '../components/CadastroSugestoes.vue';
import CadastroAvisos from '../components/CadastroAvisos.vue';
import CadastroFAQs from '../components/CadastroFAqs.vue';
import Fluxos from '../components/Fluxos.vue';
import OpcoesInativas from '../components/OpcoesInativas.vue';

export default {
  name: 'Admin',
  components: {
    layout,
    Breadcrumb,
    BuscaUsuarios,
    Direcionamentos,
    GestaoSetoresCategoriasSub,
    GerenciamentoFeedbacks,
    CadastroSugestoes,
    CadastroAvisos,
    CadastroFAQs,
    Fluxos,
    OpcoesInativas,
  },
  data() {
    return {
      breadcrumbs: [],
      tab: null,
      updateDirecionamentos: 0,
    };
  },
  mounted() {
    this.$store.commit('UPDATE_LOADING', false);
    this.breadcrumbs = [
      {
        route: {
          name: 'admin',
        },
        label: 'Painel Administrativo',
      },
    ];
  },
  methods: {
    atualizaDirecionamentos() {
      this.updateDirecionamentos += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tab {
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
  letter-spacing: 0;
}

.v-tab:not(.v-tab--active),
.v-tab:not(.v-tab--active) > .v-icon {
  color: $primary !important;
}

.v-tab--active {
  background-color: $primary;
  color: white !important;
}

.indicadores-manu {
  text-align: center;
}
</style>
