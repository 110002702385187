<template>
  <div>
    <Snackbar
      :label="msg"
      v-model="snackbarOpen"
      top
      right
      :tipo="tipoSnackbar"
      :timeout="2500"
    />
    <v-data-table
      :headers="headers"
      :items="faqs"
      :search="searchFAQ"
      :sort-by="['setor_nome','categoria_nome', 'subcategoria_nome', 'descricao']"
      :items-per-page="5"
      :no-data-text="'Não há FAQs cadastrados.'"
      :no-results-text="'FAQ não encontrado.'"
      :footer-props="{
        'items-per-page-text': 'Resultados por página',
        'items-per-page-all-text': 'Todos',
        'page-text': '{0}-{1} de {2}',
    }"
    >
      <template v-slot:top>
        <v-text-field
          v-model="searchFAQ"
          label="Pesquise uma FAQ"
          outlined
          clearable
        >
          <v-icon
            v-if="!searchFAQ"
            slot="append"
          >mdi-account-search</v-icon>
        </v-text-field>
        <v-toolbar
          flat
          color="white"
        >
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                class="botao-adicionar-novo"
                v-on="on"
              >Cadastrar nova</v-btn>
            </template>
            <v-card class="px-2">
              <v-card-title class="headline">{{ formTitle }}</v-card-title>
              <v-divider></v-divider>
              <v-card-subtitle class="mt-1">
                <v-select
                  v-if="permissaoGestor.id_permissao === gestorGeral"
                  label="Setor"
                  v-model="setor"
                  :items="listaSetores"
                  outlined
                  dense
                  @change="getCategorias"
                ></v-select>
              </v-card-subtitle>
              <v-card-subtitle
                v-if="setor || permissaoGestor.id_permissao === gestor"
                class="mt-1"
              >
                <v-select
                  label="Categoria"
                  v-model="categoria"
                  :items="listaCategorias"
                  outlined
                  dense
                  @change="getSubcategorias"
                ></v-select>
              </v-card-subtitle>
              <v-card-subtitle
                v-if="categoria"
                class="mt-1"
              >
                <v-select
                  label="Subcategoria"
                  v-model="subcategoria"
                  :items="listaSubcategorias"
                  outlined
                  dense
                ></v-select>
              </v-card-subtitle>
              <v-card-subtitle
                class="mt-1"
                v-if="subcategoria"
              >
                <v-text-field
                  label="Link para o SocialWork!"
                  v-model="urlSocialWork"
                  outlined
                  dense
                ></v-text-field>
              </v-card-subtitle>
              <v-card-subtitle
                class="show-message-input"
                v-if="categoria && subcategoria"
              >Mensagem
                <EditorTexto
                  :mensagem="mensagem"
                  table
                />
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <Botao
                  class="mr-4"
                  type="button"
                  label="Cancelar"
                  tipo="secondary"
                  :callback="closeModal"
                />
                <Botao
                  type="button"
                  label="Salvar"
                  :tipo="verificarFormulario"
                  :callback="saveOrUpdate"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogDelete"
            scrollable
            max-width="500"
          >
            <v-card class="px-2">
              <v-card-title class="headline">Remover FAQ</v-card-title>
              <v-card-subtitle class="mt-1">
                Deseja realmente remover a FAQ?
              </v-card-subtitle>
              <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <Botao
                  class="mr-4"
                  type="button"
                  label="Cancelar"
                  tipo="secondary"
                  :callback="closeModal"
                />
                <Botao
                  type="button"
                  label="Confirmar"
                  :callback="deleteFAQ"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.setor_nome="{ item }">
        <span v-if="item.setor_nome">{{ item.setor_nome }} </span>
        <span v-else>Não informado</span>
      </template>
      <template v-slot:item.categoria_nome="{ item }">
        <span v-if="item.categoria_nome">{{ item.categoria_nome }} </span>
        <span v-else>Não informado</span>
      </template>
      <template v-slot:item.subcategoria_nome="{ item }">
        <span v-if="item.subcategoria_nome">{{ item.subcategoria_nome }} </span>
        <span v-else>Não informado</span>
      </template>
      <template v-slot:item.descricao="{ item }">
        <div
          class="div-descricao"
          v-if="item.descricao"
          v-html="item.descricao"
        />
        <span v-else>Não informado</span>
      </template>
      <template v-slot:item.url_socialwork="{ item }">
        <a
          v-if="item.url_socialwork"
          :href="item.url_socialwork"
          target="_blank"
        >{{ item.url_socialwork }}</a>
        <span v-else>Não informado</span>
      </template>
      <template v-slot:item.acoes="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              dark
              class="mr-2"
              v-on="on"
              @click.stop="editFAQ(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              dark
              v-on="on"
              @click.stop="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Remover</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import { getMaxPermission } from '../utils/utilities';
import Snackbar from './Snackbar.vue';
import Botao from './Botao.vue';
import EditorTexto from './EditorTexto.vue';

export default {
  name: 'cadastroFAQs',
  components: {
    Snackbar,
    Botao,
    EditorTexto,
  },
  data() {
    return {
      tipoSnackbar: 'info',
      snackbarOpen: false,
      msg: '',
      faqs: [],
      searchFAQ: '',
      dialog: false,
      dialogDelete: false,
      optionDialogFAQ: -1,
      idFAQ: null,
      setor: '',
      categoria: '',
      subcategoria: '',
      urlSocialWork: '',
      mensagem: '',
      listaCategorias: [],
      listaSubcategorias: [],
      headers: [],
      gestor: this.$store.getters.listaPermissoes.gestor,
      gestorGeral: this.$store.getters.listaPermissoes.gestorgeral,
      permissaoGestor: getMaxPermission(this.$store),
    };
  },

  computed: {
    formTitle() {
      return this.optionDialogFAQ === -1 ? 'Adicionar FAQ' : 'Atualizar FAQ';
    },
    cMensagem() {
      return this.$store.getters.comentario;
    },
    verificarFormulario() {
      return (this.setor
        && this.categoria
        && this.subcategoria
        && this.verificarMensagem()) ? 'primary' : 'disabled';
    },
  },

  watch: {
    dialog(value) {
      return value || this.closeModal();
    },
  },

  mounted() {
    try {
      if (this.permissaoGestor.id_permissao === this.gestor) this.getCategorias();
      this.verifyHeadersPermission();
      this.getSetores();
      this.getFAQs();
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    verificarMensagem() {
      if (this.$store.getters.comentario !== '<p></p>') {
        return true;
      }
      return false;
    },
    verifyHeadersPermission() {
      if (this.permissaoGestor.id_permissao === this.gestor) {
        this.headers = [
          { text: 'Categoria', value: 'categoria_nome' },
          { text: 'Subcategoria', value: 'subcategoria_nome' },
          { text: 'Link para o SocialWork!', value: 'url_socialwork' },
          { text: 'Ações', value: 'acoes', sortable: false },
        ];
      } else if (this.permissaoGestor.id_permissao === this.gestorGeral) {
        this.headers = [
          { text: 'Setor', value: 'setor_nome' },
          { text: 'Categoria', value: 'categoria_nome' },
          { text: 'Subcategoria', value: 'subcategoria_nome' },
          { text: 'Link para o SocialWork!', value: 'url_socialwork' },
          { text: 'Ações', value: 'acoes', sortable: false },
        ];
      }
    },
    closeModal() {
      this.optionDialogFAQ = -1;
      this.setor = '';
      this.categoria = '';
      this.subcategoria = '';
      this.urlSocialWork = '';
      this.mensagem = '';
      this.dialog = false;
      this.dialogDelete = false;
    },
    deleteItem(item) {
      this.idFAQ = item.id;
      this.dialogDelete = true;
    },
    async getFAQs() {
      try {
        const { data: response } = await this.$http.get('/listar-mensagens-faq');
        const listaTratada = this.setSetorInItem(response);
        this.faqs = listaTratada.map(item => ({
          id: item.id,
          setor_id: item.setor_id,
          setor_nome: item.setor_nome,
          categoria_id: item.categoria_id,
          categoria_nome: item.categoria.nome,
          subcategoria_id: item.subcategoria_id,
          subcategoria_nome: item.subcategoria.nome,
          url_socialwork: item.url_socialwork,
          descricao: item.mensagem,
        }));
        if (this.permissaoGestor.id_permissao === this.gestor) {
          this.faqs = this.faqs.filter(item => item.setor_id
          === this.permissaoGestor.id_setor);
        }
      } catch (error) {
        console.error(error);
      }
    },
    limpaSelecoes() {
      this.categoria = '';
      this.subcategoria = '';
      this.urlSocialWork = '';
    },
    setSetorInItem(element) {
      return element.map((item) => {
        let setorNome;
        this.listaSetores.forEach((setor) => {
          if (setor.value === item.categoria.setor_id) setorNome = setor.text;
        });
        return { ...item, setor_nome: setorNome };
      });
    },
    async getSetores() {
      try {
        const { data: response } = await this.$http.get('/setores');
        this.listaSetores = response.map(item => ({
          value: item.id,
          text: item.nome,
        }));
        if (this.permissaoGestor.id_permissao === this.gestor) {
          this.setor = this.permissaoGestor.id_setor;
        }
        this.limpaSelecoes();
      } catch (error) {
        console.error(error);
      }
    },
    async getCategorias(isEdit) {
      try {
        const { data: response } = await this.$http.get('/categorias');
        const result = response.filter(item => item.setor_id === this.setor);
        this.listaCategorias = result.map(item => ({
          value: item.id,
          text: item.nome,
        }));
        if (isEdit !== true) this.limpaSelecoes();
      } catch (error) {
        console.error(error);
      }
    },
    async getSubcategorias() {
      try {
        this.subcategoria = '';
        this.mensagem = '';
        const { data: response } = await this.$http.get(`/subcategorias/${this.categoria}`);
        this.listaSubcategorias = response.map(item => ({
          value: item.id,
          text: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async saveOrUpdate() {
      if (this.optionDialogFAQ === -1) {
        this.saveFAQ();
      } else {
        this.updateFAQ();
      }
    },
    async saveFAQ() {
      try {
        await this.$http.post('/criar-mensagem-faq', {
          setor_id: this.setor,
          categoria_id: this.categoria,
          subcategoria_id: this.subcategoria,
          mensagem: this.$store.getters.comentario,
          url_socialwork: this.urlSocialWork,
        });
        this.closeModal();
        this.msg = 'FAQ criada com sucesso!';
        this.tipoSnackbar = 'success';
        this.snackbarOpen = true;
        this.getFAQs();
      } catch (error) {
        console.error(error);
      }
    },
    async updateFAQ() {
      try {
        await this.$http.put(`/editar-mensagem-faq/${this.idFAQ}`, {
          setor_id: this.setor,
          categoria_id: this.categoria,
          subcategoria_id: this.subcategoria,
          mensagem: this.cMensagem,
          url_socialwork: this.urlSocialWork,
        });
        this.closeModal();
        this.msg = 'FAQ atualizada com sucesso!';
        this.tipoSnackbar = 'success';
        this.snackbarOpen = true;
        this.getFAQs();
      } catch (error) {
        console.error(error);
      }
    },
    async editFAQ(item) {
      try {
        this.getCategorias(true);
        this.getSubcategorias();
        this.idFAQ = item.id;
        this.setor = item.setor_id;
        this.categoria = item.categoria_id;
        this.subcategoria = item.subcategoria_id;
        this.urlSocialWork = item.url_socialwork;
        this.mensagem = item.descricao;
        this.optionDialogFAQ += 1;
        this.dialog = true;
      } catch (error) {
        console.error(error);
      }
    },
    async deleteFAQ() {
      try {
        await this.$http.delete(`/deletar-mensagem-faq/${this.idFAQ}`);
        this.closeModal();
        this.msg = 'FAQ deletado com sucesso!';
        this.tipoSnackbar = 'success';
        this.snackbarOpen = true;
        this.getFAQs();
      } catch (error) {
        console.error(error);
      }
    },
    verificaComentario() {
      const texto = this.$store.getters.comentario;
      const paragrafoVazio = '<p></p>';
      return (texto && texto !== paragrafoVazio);
    },
  },
};
</script>

<style lang="scss" scoped>
.botao-adicionar-novo {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #ffffff !important;
  border-radius: 5px;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.show-message-input {
  display: none;
}
</style>

<style lang="scss">
.div-descricao {
  & p {
    margin: 0px !important;
  }
  strong {
    font-weight: bold;
  }
}
</style>
