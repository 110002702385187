<template>
  <div id="modalAvaliacao">
    <Snackbar
      :label="msg"
      v-model="snackbarOpen"
      top
      right
      :timeout="2500"
      :tipo="tipo"
    />
    <v-row justify="center">
      <v-dialog
        v-model="modal"
        max-width="500"
      >
        <v-card>
          <div class="pt-2 px-5">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-span
                v-show="tooltip"
                class="tooltipMin px-1"
              >Minimizar</v-span>
              <v-btn
                class="minimizar"
                x-small
                @click="closeModal()"
                @mouseover="tooltip = true"
                @mouseleave="tooltip = false"
              >
                <v-icon
                  x-small
                  left
                >mdi-minus</v-icon>
              </v-btn>
            </v-card-actions>
          </div>
          <v-divider class="mt-0 mb-0"></v-divider>
          <div class="pb-3 px-5">
            <h5 class="text-center py-4">Informações do atendimento:</h5>
            <p class="mb-1">Status:
              <strong class="status-encerrado">Encerrado</strong>
            </p>
            <div v-if="infosAtendimento.atendente">
              <p>Responsável: <strong>{{ infosAtendimento.atendente }}</strong></p>
              <p class="font-weight-bold mb-0">Solução apresentada:</p>
              <p
                class="mb-1"
                v-html="infosAtendimento.comentario"
              ></p>
              <p class="data mb-1 pt-1 pb-1">{{ infosAtendimento.data | formatDate }}</p>
            </div>
            <div v-else>
              <p class="font-weight-bold mb-0 pt-2">Solução apresentada:</p>
              <p class="mb-1">{{ infosAtendimento.comentario }}</p>
              <p class="data mb-1 pt-1 pb-1">{{ infosAtendimento.data | formatDate }}</p>
            </div>
          </div>
          <v-divider class="mb-0"></v-divider>
          <v-card-title class="title-atendimento text-center">Avalie o atendimento:</v-card-title>
          <v-card-text>
          <v-col cols="14">
            <v-textarea
              v-model="suggestion"
              label="Deixe um elogio ou uma sugestão de melhoria do atendimento"
              outlined
              dense
              clearable
              auto-grow
            ></v-textarea>
          </v-col>
            <div class="d-flex justify-space-around margin-buttons">
              <a
                class="icon-sad"
                @click="avaliarAtendimento('Insatisfeito')"
              >
              </a>
              <a
                class="icon-neutral"
                @click="avaliarAtendimento('Neutro')"
              >
              </a>
              <a
                class="icon-excited"
                @click="avaliarAtendimento('Satisfeito')"
              >
              </a>
            </div>
            <div class="d-flex justify-space-around margin-buttons">
              <a
                class="btn-sem-avaliacao mt-2 text-decoration-underline"
                @click="avaliarAtendimento('Sem Avaliação')"
              >
                Não avaliar
              </a>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-btn
        v-show="modalMin"
        fab
        bottom
        right
        fixed
        class="btn-fab"
        :class="{ 'animate': !modal }"
        @click="toggleModal()"
        @mouseover="hover = true"
        @mouseleave="hover = false"
      >
        <span class="reveal"></span>
        <v-icon large>mdi-star-face</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import Snackbar from './Snackbar.vue';

export default {
  name: 'AutostartModal',
  components: {
    Snackbar,
  },
  props: {
    idAtendimento: VueTypes.integer.isRequired,
    idUsuario: VueTypes.integer.isRequired,
    modalMin: VueTypes.bool,
  },
  data() {
    return {
      modal: true,
      hover: false,
      tooltip: false,
      msg: '',
      text: '',
      tipo: '',
      suggestion: '',
      snackbarOpen: false,
      infosAtendimento: {},
    };
  },
  mounted() {
    this.carregaInformacoes();
  },

  methods: {
    toggleModal() {
      this.modal = !this.modal;
    },
    closeModal() {
      this.modal = false;
    },
    async carregaInformacoes() {
      try {
        const { id } = this.$route.params;
        const { data: response } = await this.$http.get(
          `/comentarios-atendimento/${id}`,
        );
        const { nome } = response;
        if (nome) {
          this.infosAtendimento = {
            atendente: response.nome,
            comentario: response.comentario,
            data: response.created_at,
          };
        } else {
          this.infosAtendimento = {
            comentario: response.comentario,
            data: response.created_at,
          };
        }
      } catch (error) {
        console.error(error);
      }
    },
    async avaliarAtendimento(avaliacao) {
      try {
        const { data: response } = await this.$http.post('/avaliar-atendimento', {
          atendimento_id: this.idAtendimento,
          avaliacao,
          sugestao: this.suggestion,
        });

        this.modal = false;
        this.modalMin = false;
        if (response.status === 200) {
          this.tipo = 'error';
          this.msg = response.message;
          this.snackbarOpen = true;
        } else if (avaliacao !== 'Sem Avaliação') {
          this.tipo = 'success';
          this.msg = 'Avaliação enviada com sucesso!';
          this.snackbarOpen = true;
        }
      } catch (error) {
        console.error(error);
        this.modal = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.minimizar {
  text-transform: none;
  letter-spacing: 0;
  color: #ffffff !important;
  background-color: rgb(18, 40, 112) !important;
  border-radius: 50%;
  border: 1px solid rgb(18, 40, 112);
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  height: 25px !important;
  width: 25px !important;
  min-width: 25px !important;
}
.tooltipMin {
  margin-right: 2px;
  color: rgb(18, 40, 112);
}
.v-application--is-ltr .v-btn__content .v-icon--left {
  margin-top: 1px !important;
  margin-left: 0px !important;
  margin-right: 1px !important;
}
.btn-fab {
  text-transform: none !important;
  letter-spacing: 0px !important;
  font-size: 16px !important;
  color: #ffffff !important;
  background-color: rgb(18, 40, 112) !important;
  z-index: 999 !important;
  height: 60px !important;
  width: 60px !important;
  &.animate {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
  }
  &:hover {
    border-radius: 50px !important;
    width: 220px !important;
    transition: all 0.4s ease 0s;
  }
}
.btn-fab .reveal {
  opacity: 0;
}
button:hover .reveal {
  opacity: 1;
  transition: all 0.3s ease 0s;
}
button:hover .reveal:after {
  margin-right: 6px;
  content: "Avaliar atendimento";
}
.status-encerrado {
  color: #ee0202;
}
.title-atendimento {
  justify-content: center;
  margin-bottom: 10px;
}
.margin-buttons {
  margin-top: 10px;
  margin-bottom: 10px;
}
.data {
  font-size: 12px !important;
  color: rgba(48, 48, 48, 0.7) !important;
}
.shakeButton-enter-active {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-2px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(4px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-6px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(6px, 0, 0);
  }
}
.icon-sad {
  width: 85px;
  height: 85px;
  background: round;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/icons/emoticon-sad-outline.svg");
}
.icon-sad:hover {
  width: 85px;
  height: 85px;
  background: round;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/icons/emoticon-sad.svg");
}
.icon-neutral {
  width: 85px;
  height: 85px;
  background: round;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/icons/emoticon-neutral-outline.svg");
}
.icon-neutral:hover {
  width: 85px;
  height: 85px;
  background: round;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/icons/emoticon-neutral.svg");
}
.icon-excited {
  width: 85px;
  height: 85px;
  background: round;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/icons/emoticon-excited-outline.svg");
}
.icon-excited:hover {
  width: 85px;
  height: 85px;
  background: round;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/icons/emoticon-excited.svg");
}
.btn-sem-avaliacao {
  font-size: 12pt;
  text-decoration: underline !important;
  color: rgb(18, 40, 112) !important;
}
</style>

<style lang="scss">
div.v-dialog__content.v-dialog__content--active
  > div
  > div
  > div.py-3.px-5
  > div
  > p.mb-1 {
  table {
    width: 100%;
  }
  td,
  th {
    border: 1px solid #999;
    padding: 0 0.5rem;
    text-align: center;
  }
  strong {
    font-weight: bold;
  }
}
</style>
