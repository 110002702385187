<template>
  <div>
    <v-row class="indicadores">
        <h1 class="my-5 ml-6 font-weight-bold body-0">Desligamentos</h1>
        <v-col lg="12" class="indicadores">
          <IndicadorDesligamentoPorAno
            v-if="exibeIndicadorDesligamentoPorAno"
            :mesesTratados="mesesTratados"
            :nome="nomeDesligamentoPorAno"
            :valores="totalDesligamentoPorAno"
            :key="updateDesligamentoPorAno"
            :loaderDesligamentoPorAno="loaderDesligamentoPorAno"
          />
        </v-col>
        <v-col lg="12" class="indicadores">
          <IndicadorDesligamentoIniciativaPorAno
            v-if="exibeIndicadorDesligamentoIniciativaPorAno"
            :mesesTratados="mesesTratados"
            :tipoIniciativaPorAno="tipoIniciativaPorAno"
            :valores="totalDesligamentoIniciativaPorAno"
            :key="updateDesligamentoIniciativaPorAno"
            :loaderDesligamentoIniciativaPorAno="loaderDesligamentoIniciativaPorAno"
          />
        </v-col>
        <v-col lg="12" class="indicadores">
          <IndicadorDesligamentoContrato
            v-if="exibeIndicadorDesligamentoContrato"
            :diasTratados="diasTratados"
            :tipoContrato="tipoContrato"
            :valores="totalDesligamentoContrato"
            :key="updateDesligamentoContrato"
            :loaderDesligamentoContrato="loaderDesligamentoContrato"
          />
        </v-col>
        <!-- Término Indicadores -->
        <!-- Inicio Tábela -->
          <IndicadorTabelaDesligamento
            :dadosDesligamento="dadosDesligamento"
          />
        <!-- Término Tábela -->
      </v-row>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import IndicadorDesligamentoPorAno from './IndicadorDesligamentoPorAno.vue';
import IndicadorDesligamentoIniciativaPorAno from './IndicadorDesligamentoIniciativaPorAno.vue';
import IndicadorDesligamentoContrato from './IndicadorDesligamentoContrato.vue';
import IndicadorTabelaDesligamento from './IndicadorTabelaDesligamento.vue';

export default {
  name: 'IndicadorDesligamentos',
  components: {
    IndicadorDesligamentoPorAno,
    IndicadorDesligamentoIniciativaPorAno,
    IndicadorDesligamentoContrato,
    IndicadorTabelaDesligamento,
  },

  props: {
    dadosFiltro: VueTypes,
  },
  watch: {
    dadosFiltro(val) {
      this.populaDados(val);
    },
  },

  mounted() {
    this.populaDados(this.dadosFiltro);
  },

  data() {
    return {
      // IndicadorDesligamentoPorAno
      mesesTratados: [],
      exibeIndicadorDesligamentoPorAno: false,
      nomeDesligamentoPorAno: [],
      totalDesligamentoPorAno: [],
      updateDesligamentoPorAno: 0,
      loaderDesligamentoPorAno: false,

      // IndicadorDesligamentoIniciativaPorAno
      exibeIndicadorDesligamentoIniciativaPorAno: false,
      tipoIniciativaPorAno: [],
      totalDesligamentoIniciativaPorAno: [],
      updateDesligamentoIniciativaPorAno: 0,
      loaderDesligamentoIniciativaPorAno: false,

      // IndicadorDesligamentoContrato
      diasTratados: [],
      exibeIndicadorDesligamentoContrato: false,
      tipoContrato: [],
      totalDesligamentoContrato: [],
      updateDesligamentoContrato: 0,
      loaderDesligamentoContrato: false,

      // IndicadorTabelaDesligamento
      dadosDesligamento: [],
    };
  },

  methods: {
    populaDados(dados) {
      this.indicadorDesligamentoPorAno(dados);
      this.indicadorDesligamentoIniciativaPorAno(dados);
      this.indicadorDesligamentoContrato(dados);
      this.IndicadorTabelaDesligamento(dados);
    },

    listCreator(listaKeyValue) {
      const listaKey = [];
      const listaValue = [];

      listaKeyValue.forEach((item) => {
        listaKey.push(item.nome);
        listaValue.push(item.count);
      });

      return [listaKey, listaValue];
    },

    async indicadorDesligamentoPorAno(body) {
      try {
        this.loaderDesligamentoPorAno = true;
        const { data: response } = await this.$http.post('/indicadores/desligamento-mes', body);
        this.mesesTratados = response.mesesTratados;

        const [nome, count] = this.listCreator(response.valores);

        this.nomeDesligamentoPorAno = nome;
        this.totalDesligamentoPorAno = count;

        this.exibeIndicadorDesligamentoPorAno = true;
        this.updateDesligamentoPorAno += 1;
        this.loaderDesligamentoPorAno = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorDesligamentoIniciativaPorAno(body) {
      try {
        this.loaderDesligamentoIniciativaPorAno = true;
        const { data: response } = await this.$http.post('/indicadores/desligamento-iniciativa-mes', body);

        this.mesesTratados = response.mesesTratados;
        const [nome, count] = this.listCreator(response.valores);

        this.tipoIniciativaPorAno = nome;
        this.totalDesligamentoIniciativaPorAno = count;

        this.exibeIndicadorDesligamentoIniciativaPorAno = true;
        this.updateDesligamentoIniciativaPorAno += 1;
        this.loaderDesligamentoIniciativaPorAno = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorDesligamentoContrato(body) {
      try {
        this.loaderDesligamentoContrato = true;
        const { data: response } = await this.$http.post('/indicadores/desligamento-tipo-mes', body);

        this.diasTratados = response.days;

        const [nome, count] = this.listCreator(response.valores);

        this.tipoContrato = nome;
        this.totalDesligamentoContrato = count;

        this.exibeIndicadorDesligamentoContrato = true;
        this.updateDesligamentoContrato += 1;
        this.loaderDesligamentoContrato = false;
      } catch (error) {
        console.error(error);
      }
    },

    async IndicadorTabelaDesligamento(body) {
      try {
        this.loaderDesligamentoContrato = true;
        const { data: response } = await this.$http.post('/indicadores/desligamento-tabela', body);

        this.dadosDesligamento = response;

        this.exibeIndicadorDesligamentoContrato = true;
        this.updateDesligamentoContrato += 1;
        this.loaderDesligamentoContrato = false;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.indicadores {
  display: flex;
  justify-content: center;
  max-width: 1250px;
}
</style>
