<template>
  <div>
  <Snackbar
      :label="msg"
      v-model="snackbarOpen"
      top
      right
      :tipo="tipoSnackbar"
      :timeout="2500"
    />
  <div class="titleFluxo">
    <h2>Fluxos Ativados</h2>
  </div>
  <v-data-table
    :headers="headers"
    :items="fluxos"
    :items-per-page="5"
    :search="searchFluxo"
    :no-data-text="'Não há fluxos cadastrados.'"
    :no-results-text="'Fluxo não encontrado.'"
    :footer-props="{
    'items-per-page-text': 'Resultados por página',
    'items-per-page-all-text': 'Todos',
    'page-text': '{0}-{1} de {2}',
    }"
  >
    <template v-slot:top>
      <v-text-field
        v-model="searchFluxo"
        label="Pesquise um Fluxo"
        outlined
        clearable
      >
      <v-icon
        v-if="!searchFluxo"
        slot="append"
      >mdi-account-search</v-icon>
      </v-text-field>
        <v-btn
          class="v-btn_add"
          @click.stop="dialogCreator = true"
        >Adicionar novo</v-btn>
        <v-dialog
          :retain-focus="false"
          class="dialogProperty"
          v-model="dialogCreator"
        >
        <v-card class="px-2">
          <CriadorFluxos
          @dialogChanged="atualizaDialog"
          @avisoCriaFluxo="emiteAvisoFluxo"
          />
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:item.acoes="{ item }">
        <v-icon
          small
          dark
          class="mr-2"
          @click="editAtivados(item)"
        >
        mdi-pencil
        </v-icon>
        <v-dialog
      :retain-focus="false"
      v-model="dialog"
      max-width="500px"
    >
      <v-card class="px-2">
        <v-card-title class="headline">Atualizar Fluxo</v-card-title>
        <v-divider></v-divider>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <Botao
            class="mr-4"
            type="button"
            label="Cancelar"
            tipo="secondary"
            :callback="closeModal"
          />
          <v-btn
          class="btn primary"
          @click="desactivateFluxo(itemAtivado)">Desativar Fluxo</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </template>
  </v-data-table>
  <hr>
  <div class="titleFluxo">
    <h2>Fluxos Desativados</h2>
  </div>
  <v-data-table
    :headers="headersDesativados"
    :items="fluxosDesativados"
    :items-per-page="5"
    :sort-by="['nome']"
    :no-data-text="'Não há fluxos desativados.'"
    :footer-props="{
    'items-per-page-text': 'Resultados por página',
    'items-per-page-all-text': 'Todos',
    'page-text': '{0}-{1} de {2}',
    }"
  >
  <template v-slot:item.funcoes="{ item }">
    <v-icon
      small
      dark
      class="mr-2"
      @click="editDesativados(item)"
    >
    mdi-pencil
    </v-icon>
    <v-dialog
      :retain-focus="false"
      v-model="dialogDesativado"
      max-width="500px"
    >
    <v-card class="px-2">
      <v-card-title class="headline">Atualizar Fluxo</v-card-title>
      <v-divider></v-divider>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <Botao
          class="mr-4"
          type="button"
          label="Cancelar"
          tipo="secondary"
          :callback="closeModal"
        />
        <v-btn
        class="btn primary"
        @click="activateFluxo(itemDesativado)">Ativar Fluxo</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
  </template>
  </v-data-table>
  </div>
</template>

<script>
import { getMaxPermission } from '../utils/utilities';
import Botao from './Botao.vue';
import Snackbar from './Snackbar.vue';
import CriadorFluxos from './CriadorFluxos';

export default {
  name: 'Fluxos',
  components: {
    Botao,
    Snackbar,
    CriadorFluxos,
  },
  data() {
    return {
      tipoSnackbar: 'info',
      dialogCreator: false,
      snackbarOpen: false,
      msg: '',
      fluxos: [],
      fluxosDesativados: [],
      fluxo_id: null,
      setor: '',
      categoria: '',
      subcategoria: '',
      setor_item: '',
      editedIndex: -1,
      categoria_item: '',
      subcategoria_item: '',
      nome: '',
      emails: '',
      itemAtivado: '',
      itemDesativado: '',
      listaSetores: [],
      categorias: [],
      categoriasFiltradas: [],
      subcategorias: [],
      searchFluxo: '',
      dialog: false,
      dialogDesativado: false,
      gestor: this.$store.getters.listaPermissoes.gestor,
      gestorGeral: this.$store.getters.listaPermissoes.gestorgeral,
      permissaoGestor: getMaxPermission(this.$store),
      headers: [],
      headersDesativados: [],
    };
  },
  mounted() {
    this.getFluxos();
    this.getFluxosDesativados();
    this.verifyHeadersPermission();
    this.verifyHeadersPermissionDesativados();
    this.getSetores();
    if (this.permissaoGestor.id_permissao === this.gestor) this.getCategorias();
    this.getSubcategorias();
  },
  computed: {
    verificarFormulario() {
      return (this.categoria
        && this.subcategoria) ? 'primary' : 'disabled';
    },
  },
  methods: {
    atualizaDialog(value) {
      this.dialogCreator = value;
      this.getFluxos();
      this.getFluxosDesativados();
    },

    editAtivados(item) {
      this.itemAtivado = item;
      this.dialog = true;
    },
    editDesativados(item) {
      this.itemDesativado = item;
      this.dialogDesativado = true;
    },
    verifyHeadersPermission() {
      if (this.permissaoGestor.id_permissao === this.gestor) {
        this.headers = [
          { text: 'Nome', value: 'nome' },
          { text: 'Categoria', value: 'nome_categoria' },
          { text: 'Subcategoria', value: 'nome_subcategoria' },
          { text: 'Ações', value: 'acoes', sortable: false },
        ];
      } else if (this.permissaoGestor.id_permissao === this.gestorGeral) {
        this.headers = [
          { text: 'Nome', value: 'nome' },
          { text: 'Setor', value: 'nome_setor' },
          { text: 'Categoria', value: 'nome_categoria' },
          { text: 'Subcategoria', value: 'nome_subcategoria' },
          { text: 'Ações', value: 'acoes', sortable: false },
        ];
      }
    },
    verifyHeadersPermissionDesativados() {
      if (this.permissaoGestor.id_permissao === this.gestor) {
        this.headersDesativados = [
          { text: 'Nome', value: 'nome' },
          { text: 'Categoria', value: 'nome_categoria' },
          { text: 'Subcategoria', value: 'nome_subcategoria' },
          { text: 'Ações', value: 'funcoes', sortable: false },
        ];
      } else if (this.permissaoGestor.id_permissao === this.gestorGeral) {
        this.headersDesativados = [
          { text: 'Nome', value: 'nome' },
          { text: 'Setor', value: 'nome_setor' },
          { text: 'Categoria', value: 'nome_categoria' },
          { text: 'Subcategoria', value: 'nome_subcategoria' },
          { text: 'Ações', value: 'funcoes', sortable: false },
        ];
      }
    },
    closeModal() {
      this.dialog = false;
      this.dialogDesativado = false;
    },
    limpaSelecoes() {
      this.categoria = '';
      this.subcategoria = '';
    },
    async getFluxos() {
      try {
        const { data: response } = await this.$http.get('/fluxos-categorias');
        if (this.permissaoGestor.id_permissao === this.gestor) {
          this.fluxos = response.filter(item => item.setor_id
          === this.permissaoGestor.id_setor);
        } else this.fluxos = response;
      } catch (error) {
        console.error(error);
      }
    },

    async getFluxosDesativados() {
      try {
        const { data: response } = await this.$http.get('/fluxos-categorias-desativados');
        if (this.permissaoGestor.id_permissao === this.gestor) {
          this.fluxosDesativados = response.filter(item => item.setor_id
          === this.permissaoGestor.id_setor);
        } else this.fluxosDesativados = response;
      } catch (error) {
        console.error(error);
      }
    },

    async getSetores() {
      try {
        const { data: response } = await this.$http.get('/setores');
        this.listaSetores = response.map(item => ({
          value: item.id,
          text: item.nome,
        }));
        if (this.permissaoGestor.id_permissao === this.gestor) {
          this.setor = this.permissaoGestor.id_setor;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getCategorias() {
      try {
        this.limpaSelecoes();
        const { data: response } = await this.$http.get('/categorias');
        this.categorias = response.map(item => ({
          value: item.id,
          text: item.nome,
          setor_id: item.setor_id,
        }));
        this.categoriasFiltradas = this.categorias.filter(
          categoria => categoria.setor_id === this.setor,
        );
      } catch (error) {
        console.error(error);
      }
    },
    async getSubcategorias() {
      try {
        this.subcategoria = '';
        const { data: response } = await this.$http.get(`/subcategorias/${this.categoria}`);
        this.subcategorias = response.map(item => ({
          value: item.id,
          text: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },

    async desactivateFluxo(item) {
      try {
        const dados = this.desativaFluxo();
        await this.$http.put(`/fluxos/${item.id}`, dados);

        this.openSnackbar(true);
        this.closeModal();

        this.getFluxos();
        this.getFluxosDesativados();
      } catch (error) {
        this.openSnackbar(false);
        console.error(error);
      }
    },

    emiteAvisoFluxo(value) {
      this.snackbarOpen = value.snackbarOpen;
      this.tipoSnackbar = value.tipoSnackbar;
      this.msg = value.msg;
    },

    async activateFluxo(item) {
      try {
        const dados = this.ativaFluxo();
        await this.$http.put(`/fluxos/${item.id}`, dados);

        this.openSnackbar(true);
        this.closeModal();

        this.getFluxos();
        this.getFluxosDesativados();
      } catch (error) {
        this.openSnackbar(false);
        console.error(error);
      }
    },
    editFluxo() {
      this.dialog = true;
    },
    editFluxoDesativado(item) {
      this.editedIndex = this.fluxos.indexOf(item);
      this.dialogDesativado = true;
    },
    openSnackbar(type) {
      if (type) {
        this.msg = 'Fluxo atualizado com sucesso!';
        this.tipoSnackbar = 'success';
        this.snackbarOpen = true;
        return;
      }
      this.msg = 'Erro ao atualizar fluxo!';
      this.tipoSnackbar = 'error';
      this.snackbarOpen = true;
    },

    desativaFluxo() {
      return {
        desativado: true,
      };
    },

    ativaFluxo() {
      return {
        desativado: false,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogProperty {
  max-height: 100% !important;
  overflow:scroll;
}
.titleFluxo {
  display: flex;
  justify-content: center;
}

.v-btn_add {
  display: flex;
  justify-content: center;
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #ffffff !important;
  border-radius: 5px;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}
</style>
