<template>
  <span>
    <!-- Tipos TEXT -->
    <!-- text -->
    <div
    v-if="tipoCampo === 1 && !mascara">
      <v-col cols="auto">
        <span v-if="required"
          class="caption count-warning alerta">*</span>
        <v-text-field
          v-model="resposta1"
          :label="label"
          :required="required"
          outlined
          dense
          clearable
        ></v-text-field>
        <p
          class="caption text-right"
          v-if="minCaracteres"
        >
          Mínimo {{ minCaracteres }} caracteres
        </p>
      </v-col>
    </div>

    <!-- text && mascara-->
    <div
    v-if="tipoCampo === 1 && mascara
    && (mascara !== 5
    && mascara !== 7
    && mascara !== 8)">
      <v-col cols="auto">
        <span v-if="required"
          class="caption count-warning alerta">*</span>
        <v-text-field
          v-model="resposta2"
          v-mask="populaMascara"
          :label="label"
          :required="required"
          outlined
          dense
          clearable
        ></v-text-field>
        <p
          class="caption text-right"
          v-if="minCaracteres"
        >
          Mínimo {{ minCaracteres }} caracteres
        </p>
      </v-col>
    </div>


    <!-- text && mascara de dinheiro real -->
    <div
    v-if="tipoCampo === 1 && mascara === 5">
      <v-col cols="auto">
        <span v-if="required"
          class="caption count-warning alerta">*</span>
        <v-text-field
          v-money="money"
          v-model.lazy="resposta9"
          :label="label"
          :required="required"
          outlined
          dense
          clearable
        ></v-text-field>
        <p
          class="caption text-right"
          v-if="minCaracteres"
        >
          Mínimo {{ minCaracteres }} caracteres
        </p>
      </v-col>
    </div>


    <!-- text && mascara de dinheiro dolar -->
    <div
    v-if="tipoCampo === 1 && mascara === 7">
      <v-col cols="auto">
        <span v-if="required"
          class="caption count-warning alerta">*</span>
        <v-text-field
          v-money="dolar"
          v-model.lazy="resposta9"
          :label="label"
          :required="required"
          outlined
          dense
          clearable
        ></v-text-field>
        <p
          class="caption text-right"
          v-if="minCaracteres"
        >
          Mínimo {{ minCaracteres }} caracteres
        </p>
      </v-col>
    </div>


<!-- text && mascara de dinheiro euro -->
    <div
    v-if="tipoCampo === 1 && mascara === 8">
      <v-col cols="auto">
        <span v-if="required"
          class="caption count-warning alerta">*</span>
        <v-text-field
          v-money="euro"
          v-model.lazy="resposta9"
          :label="label"
          :required="required"
          outlined
          dense
          clearable
        ></v-text-field>
        <p
          class="caption text-right"
          v-if="minCaracteres"
        >
          Mínimo {{ minCaracteres }} caracteres
        </p>
      </v-col>
    </div>


    <!------- TIPO = NUMBER ------->
    <!-- number -->
    <div
    v-if="tipoCampo === 2">
      <v-col cols="auto">
        <span v-if="required"
          class="caption count-warning alerta">*</span>
        <v-text-field
          v-model="resposta3"
          :label="label"
          :required="required"
          type="number"
          outlined
          dense
          clearable
        ></v-text-field>
      </v-col>
    </div>


    <!-- TIPOS InputSelect -->
    <div
    v-if="tipoCampo === 5">
      <v-col cols="auto">
        <span v-if="required"
          class="caption count-warning alerta">*</span>
        <v-select
          v-model="resposta4"
          :label="label"
          :items="opcoesSelect"
          :required="required"
          outlined
          dense
          clearable
          auto-grow
        ></v-select>
      </v-col>
    </div>


    <!-- TIPOS RADIOBUTTON -->
    <div
    v-if="tipoCampo === 4">
      <span v-if="required"
        class="caption count-warning alerta">*</span>
      <v-card-subtitle class="subtitle-1"> {{ label }}
        <v-radio-group
          v-for="(item, index) in opcoesRadio"
          :key="index"
          v-model="resposta5"
          row
        >
        <v-radio
          :label="item"
          :value="item"
        ></v-radio>
        </v-radio-group>
      </v-card-subtitle>
    </div>


    <!-- TIPOS longText -->
    <div
    v-if="tipoCampo === 6">
      <v-col cols="auto">
        <span v-if="required"
          class="caption count-warning alerta">*</span>
        <v-textarea
          v-model="resposta6"
          :label="label"
          outlined
          dense
          clearable
          auto-grow
        ></v-textarea>
        <p
          class="caption text-right"
          v-if="minCaracteres"
        >
          Mínimo {{ minCaracteres }} caracteres
        </p>
      </v-col>
    </div>

    <!-- TIPOS Data -->
    <div
    v-if="tipoCampo === 7">
      <v-col cols="auto">
        <span v-if="required"
          class="caption count-warning alerta">*</span>
        <v-menu
          v-model="dataResposta"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dataFormatted"
              :label="label"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="resposta7"
            @input="dataResposta = false"
            :first-day-of-week="0"
            locale="pt-br"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </div>


    <!-- TIPOS checkbox -->
    <div
    v-if="tipoCampo === 3">
      <v-col cols="auto">
        <span v-if="required"
          class="caption count-warning alerta">*</span>
        <v-checkbox
          dense
          v-model="resposta8"
          :label="label"
        ></v-checkbox>
      </v-col>
    </div>


    <!------- TIPO = HORARIO ------->
    <!-- hora -->
    <div
    v-if="tipoCampo == 8">
      <v-col cols="auto">
        <div v-if="tipoCampo == 8 && required"
        class="caption count-warning alert">*</div>
        <v-text-field
          v-model="resposta9"
          :label="label"
          type="time"
          outlined
          dense
          clearable
          auto-grow
        ></v-text-field>
      </v-col>
    </div>
  </span>
</template>

<script>
import VueTypes from 'vue-types';
import moment from 'moment';
import { VMoney } from 'v-money';
import { mask } from 'vue-the-mask';
import { tipoMascara } from '../utils/utilities';

export default {
  name: 'Campo',
  directives: { mask, money: VMoney },
  props: {
    label: VueTypes.string.def(''),
    tipoCampo: VueTypes.number,
    required: VueTypes.boolean,
    minCaracteres: VueTypes.number,
    mascara: VueTypes.text,
    opcoesSelect: VueTypes.array,
    opcoesRadio: VueTypes.array,
  },

  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
      dolar: {
        decimal: ',',
        thousands: '.',
        prefix: 'US$ ',
        precision: 2,
      },
      euro: {
        decimal: ',',
        thousands: '.',
        prefix: '€ ',
        precision: 2,
      },
      dataResposta: false,
      date: new Date().toISOString().substr(0, 10),
      resposta1: '',
      resposta2: '',
      resposta3: '',
      resposta4: '',
      resposta5: '',
      resposta6: '',
      resposta7: '',
      resposta8: false,
      resposta9: '',
      resposta10: '',
      resposta11: '',
      resposta12: '',
      resposta13: '',
      resposta14: '',
      resposta15: '',
      resposta16: '',
      resposta17: '',
      resposta18: '',
      resposta19: '',
      resposta20: '',
      resposta21: '',
      resposta22: '',
      resposta23: '',
      resposta24: '',
      resposta25: '',

    };
  },
  computed: {
    populaMascara() {
      return tipoMascara(this.mascara);
    },
    dataFormatted() {
      if (this.resposta7) return moment(this.resposta7).format('L');
      return this.date;
    },
  },

  methods: {
  },
};
</script>

<style lang="scss" scoped>
.btn[primary] {
  text-transform: inherit !important;
  background: #0056a8 !important;
  color: #fff;
  border-radius: 5px;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}
.btn[secondary] {
  text-transform: inherit !important;
  background: #fff;
  color: #122870;
  border-radius: 5px;
  border: 1px solid #bfbfbf;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}
.btn[disabled] {
  text-transform: inherit !important;
  background: #fff;
  color: black;
  border-radius: 5px;
  font-size: 16px !important;
  border: 1px solid #bfbfbf;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  opacity: 0.4;
  &:hover {
    color: #bfbfbf;
    opacity: 0.4;
  }
}
.btn[atendimento] {
  text-transform: inherit !important;
  background: #0056a8;
  color: white;
  border-radius: 5px;
  font-size: 16px !important;
  border: 1px solid #0056a8;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}
.btn[azulMeta] {
  text-transform: inherit !important;
  background: #122870;
  color: white;
  border-radius: 5px;
  font-size: 16px !important;
  border: 1px solid #122870;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.alerta {
  display: flex;
  flex-direction: row-reverse;
}

.count-warning {
  background-color: transparent;
  color: red;
}

.count-success {
  background-color: transparent;
  color: green;
}
</style>
