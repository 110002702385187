<template>
  <div>
    <v-card
      :max-height="600"
      :width="600"
      :min-width="200"
      :elevation="4"
      class="card-indicador mt-2 px-3 pt-3 pb-0"
    >
      <v-overlay
        :value="loaderAbertoFechadoReaberto"
        :absolute="true"
        :opacity="0.30"
      >
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <div ref="capture">
        <div class="div-titulo-botao">
          <span class="span-titulo">
            Quantidade de atendimentos <strong>ABERTO(S) x ENCERRADO(S) x REABERTO(S)</strong>
          </span>
          <button
            class="botao-download"
            @click="screenShot()"
          >
            <span class="material-icons">
              get_app
            </span>
          </button>
        </div>
        <apexchart
          type="pie"
          :options="chartOptions"
          :series="series"
          height="380"
          width="600"
        ></apexchart>
      </div>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import VueTypes from 'vue-types';
import screenShot from '../../mixins/screenShot.js';


export default {
  name: 'IndicadorAbertoFechadoReaberto',
  components: {
    apexchart: VueApexCharts,
  },
  mixins: [screenShot],
  props: {
    valores: VueTypes.array.isRequired,
    labels: VueTypes.array.isRequired,
    loaderAbertoFechadoReaberto: VueTypes.bool.isRequired,
  },

  data() {
    return {

      series: [
        this.valores[0],
        this.valores[1],
        this.valores[2],
      ],

      chartOptions: {
        chart: {
          width: '100%',
          type: 'pie',
        },
        labels: this.labels,
        theme: {
          monochrome: {
            enabled: true,
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5,
            },
          },
        },
        dataLabels: {
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex];
            return [name, `${val.toFixed(1)}%`];
          },
        },
        legend: {
          show: false,
        },
      },
    };
  },

};
</script>

<style lang="scss" scoped>
.card-indicador {
  border-radius: 20px !important;
}
.div-titulo-botao {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .span-titulo {
    padding-left: 10px;
    color: #333;
  }

  .botao-download {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;
    border-radius: 5px;
    border: 2px solid #eeeeee;

    &:hover {
      background-color: #e2dede;
    }
    .material-icons {
      color: #0056a8;
      font-size: 25px;
    }
  }
}
</style>
