<template>
  <v-card
    class="mb-5"
    outlined
  >
    <v-card-text
      class="pt-1 pb-1"
    >
      <span> Confirmação do recebimento editado por <strong>{{usuario}}</strong></span>
    </v-card-text>
    <v-card-text class="data pt-1 pb-1">
      {{ data }}
    </v-card-text>
  </v-card>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'CardEdicaoFluxoConfirmacao',
  props: {
    usuario: VueTypes.string.def(''),
    data: VueTypes.string.def('xx/xx/xxxx'),
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-card > .v-card__text {
  font-size: 14px;
  color: #303030;
  line-height: 0.75rem;
}

.theme--light.v-card.v-card--outlined {
  border: none;
}

.data {
  font-size: 12px !important;
  color: rgba(48, 48, 48, 0.7) !important;
}
</style>
