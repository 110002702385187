<template>
  <div id="ComprasOrdinarias">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
        <h3 class="titleHeader mb-3">Fluxo de Compras Ordinarias</h3>
      <v-row>
          <v-col>
            <h5>Atenção</h5>
            <p class="caption">Os campos com
              <span class="count-warning">*</span>
              são obrigatórios.</p>
          </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-autocomplete
            v-model="empresa"
            :items="empresas"
            class="mb-2 alert-fild-required"
            label="Empresa"
            outlined
            dense
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="5">
          <v-autocomplete
            v-model="unidade"
            :items="unidades"
            class="mb-2 alert-fild-required"
            label="Centro / Unidade"
            outlined
            dense
          >
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="fornecedor"
            label="Indicação de Fornecedor"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-text-field
            v-for="(item, index) in pedidos" :key="index"
            v-model="item.material"
            class="mb-2 alert-fild-required"
            label="Material/Serviços"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-for="(item, index) in pedidos" :key="index"
            v-model="item.qtTotal"
            class="mb-2 alert-fild-required"
            label="Quantidade total"
            type="number"
            min=0
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-for="(item, index) in pedidos" :key="index"
            v-money="money"
            v-model="item.orcamento"
            class="mb-2 alert-fild-required"
            label="Valor aproximado do Orçamento"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-for="(item, index) in pedidos"
            :key="index"
            v-model="item.cc"
            :items="centrosCustos"
            class="mb-3 alert-fild-required"
            label="Centro de Custo"
            outlined
            dense
          >
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                color="white"
                x-small
                dark
                left
                v-bind="attrs"
                v-on="on"
                @click.stop="addNewMaterial()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Adicione mais um material ou serviço.</span>
          </v-tooltip>
          <v-tooltip v-if="pedidos.length > 1" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                color="white"
                x-small
                dark
                left
                v-bind="attrs"
                v-on="on"
                @click.stop="removeOldMaterial()"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </template>
            <span>Remova um material ou serviço.</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-textarea
            v-model="endereco"
            class="mb-2 alert-fild-required"
            label="Endereço de Entrega"
            outlined
            dense
            auto-grow
            rows="2"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="responsavel"
            class="mb-2 alert-fild-required"
            label="Responsável pelo recebimento"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="confirmacaoRecebimento"
            label="Confirmação de Recebimento"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-icon>mdi-alert</v-icon>
          <p class="caption"><strong>Atenção: </strong>Informações adicionais,
          utilizar o campo de <strong>mensagem.</strong></p>
        </v-col>
      </v-row>

    </v-form>
  </div>
</template>

<script>
import { VMoney } from 'v-money';
import Snackbar from '../Snackbar';

export default {
  name: 'ComprasOrdinarias',
  components: { Snackbar },
  directives: { money: VMoney },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },

      fornecedor: '',

      pedidos: [{
        material: '',
        qtTotal: '',
        orcamento: '',
        cc: '',
      }],

      empresa: '',
      empresas: [
        'Meta Sales SC',
        'Meta Consulting Services MC',
        'Meta',
        'Netrin',
        'Meta Ventures Part Ltda',
        '91M Cons. Gestão Ltda',
        'MGSA Consultoria Ltda',
        'WORBI Gestão Ltda',
        'WTCPAR Participações Ltda',
      ],

      unidade: '',
      unidades: ['Carregando...'],

      centrosCustos: ['Carregando...'],
      objetoCentroCusto: [],
      aprovadoresList: [],

      endereco: '',

      responsavel: '',

      confirmacaoRecebimento: '',

      dadosFluxo: {},
      newDadosFluxo: {},
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },
  async created() {
    await this.getCentrosUnidade();
    await this.getCentrosCustos();
  },

  computed: {
    verificaCampos() {
      return (!!this.empresa
      && !!this.unidade
      && this.checkPedidos()
      && !!this.endereco
      && !!this.responsavel);
    },
  },
  methods: {
    checkMoney(valorTotal) {
      return valorTotal !== 'R$ 0,00';
    },

    checkPedidos() {
      if (this.pedidos.length === 1) {
        return (!!this.pedidos[0].material
              && !!this.pedidos[0].qtTotal
              && this.checkMoney(this.pedidos[0].orcamento)
              && !!this.pedidos[0].cc);
      }
      if (this.pedidos.length === 2) {
        return (!!this.pedidos[0].material
              && !!this.pedidos[0].qtTotal
              && this.checkMoney(this.pedidos[0].orcamento)
              && !!this.pedidos[0].cc
              && !!this.pedidos[1].material
              && !!this.pedidos[1].qtTotal
              && this.checkMoney(this.pedidos[1].orcamento)
              && !!this.pedidos[1].cc);
      }
      if (this.pedidos.length === 3) {
        return (!!this.pedidos[0].material
              && !!this.pedidos[0].qtTotal
              && this.checkMoney(this.pedidos[0].orcamento)
              && !!this.pedidos[0].cc
              && !!this.pedidos[1].material
              && !!this.pedidos[1].qtTotal
              && this.checkMoney(this.pedidos[1].orcamento)
              && !!this.pedidos[1].cc
              && !!this.pedidos[2].material
              && !!this.pedidos[2].qtTotal
              && this.checkMoney(this.pedidos[2].orcamento)
              && !!this.pedidos[2].cc);
      }
      if (this.pedidos.length === 4) {
        return (!!this.pedidos[0].material
              && !!this.pedidos[0].qtTotal
              && this.checkMoney(this.pedidos[0].orcamento)
              && !!this.pedidos[0].cc
              && !!this.pedidos[1].material
              && !!this.pedidos[1].qtTotal
              && this.checkMoney(this.pedidos[1].orcamento)
              && !!this.pedidos[1].cc
              && !!this.pedidos[2].material
              && !!this.pedidos[2].qtTotal
              && this.checkMoney(this.pedidos[2].orcamento)
              && !!this.pedidos[2].cc
              && !!this.pedidos[3].material
              && !!this.pedidos[3].qtTotal
              && this.checkMoney(this.pedidos[3].orcamento)
              && !!this.pedidos[3].cc);
      }

      return false;
    },
    async getCentrosUnidade() {
      try {
        const { data: response } = await this.$http.get('/data-from-sap/centro-filiais');
        this.unidades = response.map(item => item.descricaoItens);
      } catch (error) {
        console.error(error);
      }
    },

    async getCentrosCustos() {
      try {
        const { data: response } = await this.$http.get('/data-from-sap/centro-custo');
        this.centrosCustos = response.map(item => item.descricaoItens);
        this.objetoCentroCusto = response;
      } catch (error) {
        console.error(error);
      }
    },

    getDadosCentroCusto(centrosCusto) {
      try {
        centrosCusto.forEach((item) => {
          this.objetoCentroCusto.filter((element) => {
            if (item.cc.includes(element.descricaoItens)) {
              this.aprovadoresList.push(element);
            }

            return this.aprovadoresList;
          });
        });
      } catch (e) {
        console.error(e);
      }
    },

    addNewMaterial() {
      if (this.pedidos.length < 4) {
        this.pedidos.push({
          material: '',
          qtTotal: '',
          orcamento: '',
          cc: '',
        });
      } else {
        this.alerta = 'error';
        this.msg = 'Limite de 4 Materiais/Serviços por Atendimento';
        this.snackBarAlertaCampos = true;
      }
    },

    removeOldMaterial() {
      this.pedidos.pop();
    },

    inputValueInAprovadores(dadosFluxo) {
      const aprovadoresWithValue = dadosFluxo.aprovadoresList.map((item, index) => ({
        ...item,
        valor: dadosFluxo.pedidos[index].orcamento,
      }));

      return aprovadoresWithValue;
    },

    trataResposta(dadosFluxo) {
      const respostasModel = {};

      if (dadosFluxo.empresa) respostasModel.column_campo_1 = dadosFluxo.empresa;
      if (dadosFluxo.unidade) respostasModel.column_campo_2 = dadosFluxo.unidade;
      if (dadosFluxo.fornecedor) respostasModel.column_campo_3 = dadosFluxo.fornecedor;

      if (dadosFluxo.pedidos[0]) {
        respostasModel.column_campo_4 = dadosFluxo.pedidos[0].material;
        respostasModel.column_campo_5 = dadosFluxo.pedidos[0].qtTotal;
        respostasModel.column_campo_6 = dadosFluxo.pedidos[0].orcamento;
        respostasModel.column_campo_7 = dadosFluxo.pedidos[0].cc;
      }
      if (dadosFluxo.pedidos[1]) {
        respostasModel.column_campo_8 = dadosFluxo.pedidos[1].material;
        respostasModel.column_campo_9 = dadosFluxo.pedidos[1].qtTotal;
        respostasModel.column_campo_10 = dadosFluxo.pedidos[1].orcamento;
        respostasModel.column_campo_11 = dadosFluxo.pedidos[1].cc;
      }
      if (dadosFluxo.pedidos[2]) {
        respostasModel.column_campo_12 = dadosFluxo.pedidos[2].material;
        respostasModel.column_campo_13 = dadosFluxo.pedidos[2].qtTotal;
        respostasModel.column_campo_14 = dadosFluxo.pedidos[2].orcamento;
        respostasModel.column_campo_15 = dadosFluxo.pedidos[2].cc;
      }
      if (dadosFluxo.pedidos[3]) {
        respostasModel.column_campo_16 = dadosFluxo.pedidos[3].material;
        respostasModel.column_campo_17 = dadosFluxo.pedidos[3].qtTotal;
        respostasModel.column_campo_18 = dadosFluxo.pedidos[3].orcamento;
        respostasModel.column_campo_19 = dadosFluxo.pedidos[3].cc;
      }
      if (dadosFluxo.endereco) respostasModel.column_campo_20 = dadosFluxo.endereco;
      if (dadosFluxo.responsavel) respostasModel.column_campo_21 = dadosFluxo.responsavel;
      if (dadosFluxo.confirmacaoRecebimento) {
        respostasModel.column_campo_22 = dadosFluxo.confirmacaoRecebimento;
      }
      respostasModel.validation = true;

      const aprovadoresWithValue = this.inputValueInAprovadores(dadosFluxo);
      respostasModel.aprovadoresList = aprovadoresWithValue;
      respostasModel.usuarioSolicitante = this.$store.getters.isUsuario.nome;

      this.newDadosFluxo = respostasModel;
    },

    enviaJSON() {
      if (!this.verificaCampos) {
        this.dadosFluxo = { validation: false };
        this.alerta = 'error';
        this.msg = 'Todos os campos com * são obrigatórios.';
        this.snackBarAlertaCampos = true;
      } else {
        this.getDadosCentroCusto(this.pedidos);
        this.dadosFluxo = {
          validation: true,
          empresa: this.empresa,
          unidade: this.unidade,
          fornecedor: this.fornecedor,
          pedidos: this.pedidos,
          endereco: this.endereco,
          responsavel: this.responsavel,
          confirmacaoRecebimento: this.confirmacaoRecebimento,
          aprovadoresList: this.aprovadoresList,
        };
        this.trataResposta(this.dadosFluxo);
        this.$emit('dadosFluxo', this.newDadosFluxo);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

.alert-fild-required::after {
    content: "*";
    color: red;
    margin-bottom: 10px;
    margin-left: 6px;
    font-size: x-small;
}

.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.count-warning {
  background-color: transparent;
  color: red;
}
</style>
