<template>
  <div id="formComprasTecnicasInternacionais">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
        <h3 class="titleHeader mb-3">Fluxo de Compras Técnicas - Internacionais</h3>

      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="empresa"
            label="Empresa"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="unidade"
            label="Centro / Unidade"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="fornecedorSelecionado"
            label="Escolha um Fornecedor"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-text-field
            class="mb-1"
            v-model="material"
            label="Material/Serviços"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            class="mb-1"
            v-model="centroCustoSelecionado"
            label="Centro de custo"
            outlined
            dense
            :disabled="editar"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-textarea
            v-model="endereco"
            label="País do Fornecedor"
            outlined
            dense
            auto-grow
            rows="2"
            :disabled="editar"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="8">
          <v-textarea
            v-model="descricaoServico"
            label="Descrever o serviço realizado pelo fornecedor do exterior"
            outlined
            dense
            auto-grow
            rows="2"
            :disabled="editar"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
         <v-radio-group
            v-model="servicoClienteOUEmpresa"
            row
            label="O serviço será repassado ao cliente ou utilizado internamente na empresa?"
            :disabled="editar"
          >
            <v-radio
              label="Sim"
              :value="'Sim'"
            ></v-radio>
            <v-radio
              label="Não"
              :value="'Não'"
            ></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="6" v-if="empresaFaturadaPedido || servicoClienteOUEmpresa === 'Sim'">
          <v-textarea
            v-model="empresaFaturadaPedido"
            label="Em qual empresa será faturado o pedido?"
            outlined
            dense
            auto-grow
            rows="2"
            :disabled="editar"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-menu
            v-model="menuDataVencimento"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dataFormatadaVencimento"
                label="Qual o vencimento?"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                :disabled="editar"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dataVencimento"
              @input="menuDataVencimento = false"
              :first-day-of-week="0"
              locale="pt-br"
              :min="minDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="8">
          <v-textarea
            v-model="compraRecorenteQtMesesFrequenciaTotal"
            label="Indicar se será uma compra recorrente e quantos meses (frequência total):"
            outlined
            dense
            auto-grow
            rows="2"
            :disabled="editar"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-radio-group
            v-model="formaPagamento"
            row
            label="Qual será a forma de pagamento:"
            :disabled="editar"
          >
            <v-radio
              label="Boleto"
              :value="'Boleto'"
            ></v-radio>
            <v-radio
              label="Cartão de Crédito"
              :value="'Cartão de Crédito'"
            ></v-radio>
            <v-radio
              label="Câmbio pagamento na conta do fornecedor no exterior"
              :value="'Câmbio pagamento na conta do fornecedor no exterior'"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-autocomplete
            v-model="tipoMoeda"
            :items="moedas"
            label="Qual a moeda do pedido?"
            outlined
            dense
            :disabled="editar"
          ></v-autocomplete>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-money="decimal"
            v-model.lazy="valorCompraMoedaEstrangeira"
            label="Valor da Compra em MOEDA ESTRANGEIRA?"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-money="money"
            v-model.lazy="valorCompraMoedaReal"
            label="Valor da Compra em MOEDA NACIONAL (Reais)?"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="urlNF || !editar">
        <v-col cols="8">
          <v-text-field
            v-model="urlNF"
            label="Adicionar o URL (link) da NF.
              Caso possua apenas o anexo, adicionar no campo de mensagens."
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="6">
          <v-text-field
            v-if="permissaoMax.id_permissao !== usuarioPerm || isStatusEncerrado"
            v-model="numeroAtendimento"
            label="Número do pedido SAP"
            outlined
            dense
            :disabled="editar"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn
        :class="{ 'btn-primary-disabled ml-3': isStatusEncerrado,
                   'btn-primary ml-3': !isStatusEncerrado }"
        @click="toggleEdit"
        :disabled="isStatusEncerrado">
        <span v-if="editar">
          <v-icon left>mdi-pencil</v-icon>Editar
        </span>
        <span v-else>
          <v-icon left>mdi-close</v-icon>Cancelar
        </span>
      </v-btn>

      <v-btn
        v-if="!editar"
        class="btn-primary ml-2"
        @click="salvarFluxoEditado"
      >
        <v-icon left>mdi-content-save</v-icon>Salvar
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import moment from 'moment';
import { VMoney } from 'v-money';
import { mask } from 'vue-the-mask';
import VueTypes from 'vue-types';
import { getMaxPermission } from '../../utils/utilities';
import Snackbar from '../Snackbar';

export default {
  name: 'FormComprasTecnicasNacionais.vue',
  components: { Snackbar },
  directives: { money: VMoney, mask },
  props: {
    camposFluxo: VueTypes.array,
    fluxoId: VueTypes.integer,
    atendimentoId: VueTypes.integer,
    statusAtendimento: VueTypes.string,
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
      decimal: {
        decimal: ',',
        precision: 2,
      },
      empresa: '',

      unidade: '',

      fornecedorSelecionado: '',

      material: '',

      centroCustoSelecionado: '',

      endereco: '',

      descricaoServico: '',

      servicoClienteOUEmpresa: '',
      empresaFaturadaPedido: '',

      dataVencimento: '',
      minDate: new Date().toISOString().substr(0, 10),
      newDataVencimento: '',
      menuDataVencimento: false,

      compraRecorenteQtMesesFrequenciaTotal: '',

      formaPagamento: '',

      tipoMoeda: '',
      valorCompraMoedaEstrangeira: '',
      valorCompraMoedaReal: '',
      moedas: ['USD|DOLAR DOS EUA', 'EUR|EURO', 'GBP|LIBRA ESTERLINA', 'CAD|DOLAR CANADENSE', 'AED|DIRHAM/EMIR.ARABE',
        'AFN|AFEGANE AFEGANIST', 'ALL|LEK ALBANIA REP', 'AMD|DRAM ARMENIA REP', 'ANG|GUILDER ANTILHAS HOLANDESAS',
        'AOA|KWANZA/ANGOLA', 'ARS|PESO ARGENTINO', 'AUD|DOLAR AUSTRALIANO', 'AWG|FLORIM/ARUBA', 'AZN|MANAT ARZEBAIJAO',
        'BAM|MARCO CONV BOSNIA', 'BBD|DOLAR/BARBADOS', 'BDT|TACA/BANGLADESH  ', 'BGN|LEV/BULGARIA, REP', 'BHD|DINAR/BAHREIN',
        'BIF|FRANCO/BURUNDI', 'BMD|DOLAR/BERMUDAS', 'BND|DOLAR BRUNEI', 'BOB|BOLIVIANO/BOLIVIA', 'BSD|DOLAR/BAHAMAS', 'BTN|NGULTRUM/BUTAO',
        'BWP|PULA/BOTSWANA', 'BYN|RUBLO BELARUS', 'BZD|DOLAR/BELIZE', 'CDF|FRANCO CONGOLES', 'CHF|FRANCO SUICO', 'CLF|UNIDADE DE FOMENTO DO CHILE',
        'CLP|PESO CHILE', 'CNH|RENMINBI HONG KONG', 'CNY|RENMINBI CHINES', 'COP|PESO/COLOMBIA', 'COU|UNIDADE DE VALOR REAL',
        'CRC|COLON/COSTA RICA ', 'CUP|PESO/CUBA', 'CVE|ESCUDO CABO VERDE', 'CZK|COROA TCHECA', 'DJF|FRANCO/DJIBUTI', 'DKK|COROA DINAMARQUESA',
        'DOP|PESO/REP. DOMINIC', 'DZD|DINAR ARGELINO', 'EGP|LIBRA/EGITO', 'ERN|NAKFA DA ERITREIA', 'ETB|BIRR/ETIOPIA', 'FJD|DOLAR FIJI',
        'FKP|LIBRA/FALKLAND', 'GEL|LARI GEORGIA', 'GHS|CEDI GANA', 'GIP|LIBRA DE GIBRALTAR', 'GMD|DALASI/GAMBIA', 'GNF|FRANCO/GUINE',
        'GTQ|QUETZAL/GUATEMALA', 'GYD|DOLAR DA GUIANA', 'HKD|DOLAR HONG KONG', 'HNL|LEMPIRA/HONDURAS', 'HRK|KUNA DA CROACIA',
        'HTG|GOURDE HAITI', 'HUF|FORINT/HUNGRIA', 'IDR|RUPIA/INDONESIA', 'ILS|SHEKEL/ISRAEL', 'INR|RUPIA/INDIA', 'IQD|DINAR/IRAQUE',
        'IRR|RIAL/IRAN, REP', 'ISK|COROA ISLND/ISLAN', 'JMD|DOLAR/JAMAICA', 'JOD|DINAR/JORDANIA', 'JPY|IENE', 'KES|XELIM/QUENIA',
        'KGS|SOM DO QUIRGUISTAO', 'KHR|RIEL/CAMBOJA', 'KMF|FRANCO COMORES', 'KPW|WON/COREIA NORTE', 'KRW|WON COREIA SUL', 'KWD|DINAR/KWAIT',
        'KYD|DOLAR CAYMAN', 'KZT|TENGE CAZAQUISTAO', 'LAK|QUIPE/LAOS, REP', 'LBP|LIBRA/LIBANO', 'LKR|RUPIA/SRI LANKA', 'LRD|DOLAR LIBERIA',
        'LSL|LOTI/LESOTO', 'LYD|DINAR/LIBIA', 'MAD|DIRHAM/MARROCOS', 'MDL|LEU/MOLDAVIA, REP', 'MGA|ARIARY MADAGASCAR', 'MKD|DINAR/MACEDONIA',
        'MMK|QUIATE/BIRMANIA', 'MNT|TUGRIK/MONGOLIA', 'MOP|PATACA/MACAU', 'MRO|UGUIA MAURITANIA', 'MRU|UGUIA MAURITANIA',
        'MUR|RUPIA/MAURICIO', 'MVR|RUFIA/MALDIVAS', 'MWK|QUACHA/MALAVI', 'MXN|PESO/MEXICO', 'MYR|RINGGIT/MALASIA', 'MZN|NOVA METICAL/MOCA',
        'NAD|DOLAR DA NAMIBIA', 'NGN|NAIRA NIGERIA', 'NIO|CORDOBA OURO', 'NOK|COROA NORUEGUESA', 'NPR|RUPIA/NEPAL', 'NZD|DOLAR NOVA ZELANDIA',
        'OMR|RIAL/OMA', 'PAB|BALBOA/PANAMA', 'PEN|NOVO SOL/PERU', 'PGK|KINA DE PAPUA NOVA GUINE', 'PHP|PESO/FILIPINAS', 'PKR|RUPIA/PAQUISTAO',
        'PLN|ZLOTY/POLONIA', 'PYG|GUARANI/PARAGUAI', 'QAR|RIAL/CATAR', 'RON|NOVO LEU DA ROMENIA', 'RSD|DINAR SERVIO SERVIA', 'RUB|RUBLO/RUSSIA',
        'RWF|FRANCO/RUANDA', 'SAR|RIAL/ARAB SAUDITA', 'SBD|DOLAR DAS ILHAS SALOMAO', 'SCR|RUPIA SEYCHELES', 'SDG|NOVA LIBRA SUDANESA',
        'SDR|DIREITO ESPECIAL', 'SEK|COROA SUECA', 'SGD|DOLAR CINGAPURA', 'SHP|LIBRA DE SANTA HELENA', 'SLL|LEONE/SERRA LEOA ', 'SOS|XELIM/SOMALIA',
        'SRD|DOLAR DO SURINAME', 'SSP|LIBRA SUL SUDANESA', 'STN|DOBRA SÃO TOMÉ E PRÍNCIPE', 'SVC|COLON/EL SALVADOR', 'SYP|LIBRA/SIRIA, REP', 'SZL|LILANGENI/SUAZIL',
        'THB|BATH/TAILANDIA', 'TJS|SOMONI TADJIQUISTAO', 'TMT|NOVO MANAT TURCOMANO', 'TND|DINAR/TUNISIA', 'TOP|PAANGA/TONGA', 'TRY|LIRA TURCA',
        'TTD|DOLAR/TRIN. TOBAG', 'TWD|NOVO DOLAR/TAIWAN', 'TZS|XELIM DA TANZANIA', 'UAH|HRYVNIA UCRANIA', 'UGX|XELIM/UGANDA', 'UYU|PESO/URUGUAIO',
        'UZS|SOM UZBEQUISTAO', 'VES|BOLIVAR SOBERANO VENEZUELANO', 'VND|DONGUE/VIETNAN', 'VUV|VATU VANUATU', 'WST|TALA SAMOA',
        'XAF|FRANCO CFA BEAC', 'XCD|DOLAR CARIBE ORIENTAL', 'XEU|UNID.MONET.EUROP.', 'XOF|FRANCO CFA BCEAO', 'XPF|FRANCO CFP', 'YER|RIAL/IEMEN',
        'ZAR|RANDE/AFRICA SUL ', 'ZMW|QUACHA ZAMBIA', 'ZWL|DOLAR ZIMBABUE'],

      urlNF: '',

      numeroAtendimento: '',

      editar: true,

      permissaoMax: getMaxPermission(this.$store),
      usuarioPerm: this.$store.getters.listaPermissoes.colaborador,

      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },
  created() {
    moment.locale('pt-br');
    this.loadInfoCamposFluxo();
  },

  watch: {
    servicoClienteOUEmpresa(value) {
      if (value === 'Não') {
        this.empresaFaturadaPedido = '';
      }
    },
  },

  computed: {
    verificaCampos() {
      return (!!this.empresa
      && !!this.unidade
      && !!this.fornecedorSelecionado
      && !!this.material
      && !!this.centroCustoSelecionado
      && !!this.descricaoServico
      && this.checkEmpresaFaturado()
      && !!this.dataVencimento
      && !!this.compraRecorenteQtMesesFrequenciaTotal
      && !!this.formaPagamento
      && !!this.tipoMoeda
      && this.checkDecimalValue(this.valorCompraMoedaEstrangeira)
      && this.checkMoney(this.valorCompraMoedaReal));
    },

    checkIfIsRepassClientOrInterCompany() {
      return this.servicoClienteOUEmpresa === 'Sim';
    },

    dataFormatadaVencimento() {
      return this.dataVencimento ? moment(this.dataVencimento).format('DD/MM/YYYY') : this.newDataVencimento;
    },

    isStatusEncerrado() {
      return this.statusAtendimento === 'Encerrado';
    },
  },
  methods: {
    checkEmpresaFaturado() {
      return !this.checkIfIsRepassClientOrInterCompany ? true : !!this.empresaFaturadaPedido;
    },

    checkMoney(valorTotal) {
      return valorTotal !== 'R$ 0,00';
    },

    checkDecimalValue(valorTotal) {
      return valorTotal !== '0,00';
    },

    loadInfoCamposFluxo() {
      const formataMoedaReal = this.camposFluxo[14].resposta.replace('R$ ', '').replace('.', '').replace(',', '.');

      this.empresa = this.camposFluxo[0].resposta;
      this.unidade = this.camposFluxo[1].resposta;
      this.fornecedorSelecionado = this.camposFluxo[2].resposta;
      this.material = this.camposFluxo[3].resposta;
      this.centroCustoSelecionado = this.camposFluxo[4].resposta;
      this.endereco = this.camposFluxo[5].resposta;
      this.descricaoServico = this.camposFluxo[6].resposta;
      this.servicoClienteOUEmpresa = this.camposFluxo[7].resposta;
      this.empresaFaturadaPedido = this.camposFluxo[8].resposta;
      this.newDataVencimento = this.camposFluxo[9].resposta;
      this.compraRecorenteQtMesesFrequenciaTotal = this.camposFluxo[10].resposta;
      this.formaPagamento = this.camposFluxo[11].resposta;
      this.tipoMoeda = this.camposFluxo[12].resposta;
      this.valorCompraMoedaEstrangeira = this.camposFluxo[13].resposta;
      this.valorCompraMoedaReal = formataMoedaReal;
      this.urlNF = this.camposFluxo[15].resposta;
      this.numeroAtendimento = this.camposFluxo[16].resposta;
    },

    async salvarFluxoEditado() {
      this.toggleEdit();

      this.alerta = 'success';
      this.msg = 'Formulário editado com sucesso!';
      this.snackBarAlertaCampos = true;
      const body = {
        id: this.atendimentoId,
        campos_fluxo: {
          column_campo_1: this.empresa,
          column_campo_2: this.unidade,
          column_campo_3: this.fornecedorSelecionado,
          column_campo_4: this.material,
          column_campo_5: this.centroCustoSelecionado,
          column_campo_6: this.endereco,
          column_campo_7: this.descricaoServico,
          column_campo_8: this.servicoClienteOUEmpresa,
          column_campo_9: this.empresaFaturadaPedido,
          column_campo_10: this.dataFormatadaVencimento,
          column_campo_11: this.compraRecorenteQtMesesFrequenciaTotal,
          column_campo_12: this.formaPagamento,
          column_campo_13: this.tipoMoeda,
          column_campo_14: this.valorCompraMoedaEstrangeira,
          column_campo_15: this.valorCompraMoedaReal,
          column_campo_16: this.urlNF,
          column_campo_17: this.numeroAtendimento,
        },
        responsavel: this.$store.getters.isUsuario.id,
        fluxo_id: this.fluxoId,
      };
      await this.$http.put('/atendimentos/editar/dados-fluxo', body);
    },

    toggleEdit() {
      this.editar = !this.editar;
      this.disabled = !this.disabled;
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

#formComprasTecnicasInternacionais {
    margin-left: 8px;
    margin-right: 12px;
}

.btn-primary {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border: 1px solid #0056a8 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.btn-primary-disabled {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.count-warning {
  background-color: transparent;
  color: red;
}
</style>
