const primeiroNome = {
  methods: {
    primeiroNome(nome) {
      if (!nome) {
        return '';
      }
      const arrayNome = nome.split(' ');
      return `${arrayNome[0]}`;
    },
  },
};

export default primeiroNome;
