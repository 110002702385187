<template>
  <v-card
    class="mb-5"
    outlined
  >
    <v-card-text
      :[tipo]="true"
      class="pt-1 pb-1"
    >
      {{ titulo }}
      <span v-if="subcategoria && titulo
        != 'Solicitação de reajuste de salário'"
        >de <strong>{{ subcategoria  === 'Aguardando atendente' ?
         'Aguardando responsável' : subcategoria }}</strong>
      </span>

      <span v-if="titulo !== 'Solicitação de Aprovação enviada'
      && titulo != 'Solicitação de reajuste de salário'">
      {{titulo === 'Convidado usuario' || titulo === 'Convidado setor' ? '': 'para'}}
      </span>

      <strong v-if="titulo !== 'Solicitação de Aprovação enviada'">
      {{ descricao === 'Aguardando atendente' ?
        'Aguardando responsável' : descricao}}</strong>

      <span v-if="responsavel && titulo == 'Status atualizado' ||
        (titulo == 'Solicitação de Aprovação enviada')"> por {{responsavel}}</span>

      <span v-if="titulo == 'Solicitação de reajuste de salário' && !responsavel">
        pelo {{ email }}</span>

      <span v-if="titulo == 'Solicitação de reajuste de salário' && responsavel">
        por {{responsavel}}</span>

    </v-card-text>
    <v-card-text class="data pt-1 pb-1">
      {{ data }}
    </v-card-text>
  </v-card>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'CardStatus',
  props: {
    responsavel: VueTypes.string.def(''),
    titulo: VueTypes.string.isRequired.def('Titulo'),
    subcategoria: VueTypes.string.def(''),
    descricao: VueTypes.string.def('(Descrição)'),
    data: VueTypes.string.def('xx/xx/xxxx'),
    tipo: VueTypes.oneOf(['usuario', 'atendente']).def('usuario'),
    email: VueTypes.string,
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-card > .v-card__text {
  font-size: 14px;
  color: #303030;
  line-height: 0.75rem;
}

.theme--light.v-card.v-card--outlined {
  border: none;
}

.data {
  font-size: 12px !important;
  color: rgba(48, 48, 48, 0.7) !important;
}
</style>
