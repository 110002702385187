<template>
  <div id="ComprasEPS">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
        <h3 class="titleHeader mb-3">Fluxo Compras EPS</h3>
      <v-row>
          <v-col>
            <h5>Atenção</h5>
            <p class="caption">Os campos com
              <span class="count-warning">*</span>
              são obrigatórios.</p>
          </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-autocomplete
            v-model="empresa"
            :items="empresas"
            label="Empresa"
            outlined
            class="alert-fild-required"
            dense
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="5">
          <v-autocomplete
            v-model="unidade"
            :items="unidades"
            label="Centro / Unidade"
            class="alert-fild-required"
            outlined
            dense
          >
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10">
          <v-autocomplete
            v-model="fornecedorSelecionado"
            :items="fornecedores"
            label="Fornecedor"
            outlined
            dense
            class="alert-fild-required"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="servico"
            label="Serviço"
            class="alert-fild-required"
            desable
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-text-field
            v-money="money"
            v-model="remoneracaoHora"
            class="alert-fild-required"
            label="Remuneração por Hora"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-autocomplete
            v-for="(item, index) in centrosCusto"
            :key="index"
            v-model="item.cc"
            :items="centrosCustos"
            label="Centro de custo"
            class="mb-2 alert-fild-required"
            outlined
            dense
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-for="(item, index) in centrosCusto"
            :key="index"
            v-model="item.quantidadeHoras"
            label="Quantidade de Horas"
            v-mask="['##:##', '###:##', '####:##']"
            class="mb-1 alert-fild-required"
            outlined
            dense
            min="0"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            v-for="(item, index) in centrosCusto"
            :key="index"
            v-money="money"
            v-model="item.valorTotal"
            class="mb-1 alert-fild-required"
            label="Valor Total"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                color="white"
                x-small
                dark
                left
                v-bind="attrs"
                v-on="on"
                @click.stop="addNewCC()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Adicione mais um Centro de custo.</span>
          </v-tooltip>

          <v-tooltip v-if="centrosCusto.length > 1" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                color="white"
                x-small
                dark
                left
                v-bind="attrs"
                v-on="on"
                @click.stop="removeOldCC()"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </template>
            <span>Remova um Centro de Custo.</span>
          </v-tooltip>

        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-icon>mdi-alert</v-icon>
          <p class="caption"><strong>Atenção: </strong>Informações adicionais,
          utilizar o campo de <strong>mensagem.</strong></p>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import { VMoney } from 'v-money';
import Snackbar from '../Snackbar';

export default {
  name: 'ComprasEPS',
  components: { Snackbar },
  directives: { mask, money: VMoney },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },

      empresa: '',
      empresas: [
        'Meta Sales SC',
        'Meta Consulting Services MC',
        'Meta',
        'Netrin',
        'Meta Ventures Part Ltda',
        '91M Cons. Gestão Ltda',
        'MGSA Consultoria Ltda',
        'WORBI Gestão Ltda',
        'WTCPAR Participações Ltda',
      ],

      unidade: '',
      unidades: ['Carregando...'],

      fornecedorSelecionado: '',
      fornecedores: ['Carregando...'],

      servico: 'EPS Manual',
      remoneracaoHora: '',

      centrosCustos: ['Carregando....'],
      centrosCusto: [{ cc: '', quantidadeHoras: 0, valorTotal: '' }],

      objetoCentroCusto: [],
      aprovadoresList: [],

      dadosFluxo: {},
      newDadosFluxo: {},
      snackBarAlertaCampos: false,
      alerta: 'info',
      mensagemDinamica: 'Todos os campos com * são obrigatórios.',
      msg: '',
    };
  },
  async created() {
    await this.getCentrosUnidade();
    await this.getFornecedores();
    await this.getCentroCustos();
  },

  computed: {
    verificaCampos() {
      return (!!this.empresa
      && !!this.unidade
      && !!this.fornecedorSelecionado
      && this.checkMoney(this.remoneracaoHora)
      && this.checkCentrosCusto());
    },
  },
  methods: {
    checkMoney(valorTotal) {
      return valorTotal !== 'R$ 0,00';
    },

    checkCentrosCusto() {
      if (this.centrosCusto.length === 1) {
        return (!!this.centrosCusto[0].cc
              && this.checkHours(this.centrosCusto[0].quantidadeHoras)
              && this.checkMoney(this.centrosCusto[0].valorTotal));
      }
      if (this.centrosCusto.length === 2) {
        return (!!this.centrosCusto[0].cc
              && this.checkHours(this.centrosCusto[0].quantidadeHoras)
              && this.checkMoney(this.centrosCusto[0].valorTotal)
              && !!this.centrosCusto[1].cc
              && this.checkHours(this.centrosCusto[1].quantidadeHoras)
              && this.checkMoney(this.centrosCusto[1].valorTotal));
      }
      if (this.centrosCusto.length === 3) {
        return (!!this.centrosCusto[0].cc
              && this.checkHours(this.centrosCusto[0].quantidadeHoras)
              && this.checkMoney(this.centrosCusto[0].valorTotal)
              && !!this.centrosCusto[1].cc
              && this.checkHours(this.centrosCusto[1].quantidadeHoras)
              && this.checkMoney(this.centrosCusto[1].valorTotal)
              && !!this.centrosCusto[2].cc
              && this.checkHours(this.centrosCusto[2].quantidadeHoras)
              && this.checkMoney(this.centrosCusto[2].valorTotal));
      }
      if (this.centrosCusto.length === 4) {
        return (!!this.centrosCusto[0].cc
              && this.checkHours(this.centrosCusto[0].quantidadeHoras)
              && this.checkMoney(this.centrosCusto[0].valorTotal)
              && !!this.centrosCusto[1].cc
              && this.checkHours(this.centrosCusto[1].quantidadeHoras)
              && this.checkMoney(this.centrosCusto[1].valorTotal)
              && !!this.centrosCusto[2].cc
              && this.checkHours(this.centrosCusto[2].quantidadeHoras)
              && this.checkMoney(this.centrosCusto[2].valorTotal)
              && !!this.centrosCusto[3].cc
              && this.checkHours(this.centrosCusto[3].quantidadeHoras)
              && this.checkMoney(this.centrosCusto[3].valorTotal));
      }
      if (this.centrosCusto.length === 5) {
        return (!!this.centrosCusto[0].cc
              && this.checkHours(this.centrosCusto[0].quantidadeHoras)
              && this.checkMoney(this.centrosCusto[0].valorTotal)
              && !!this.centrosCusto[1].cc
              && this.checkHours(this.centrosCusto[1].quantidadeHoras)
              && this.checkMoney(this.centrosCusto[1].valorTotal)
              && !!this.centrosCusto[2].cc
              && this.checkHours(this.centrosCusto[2].quantidadeHoras)
              && this.checkMoney(this.centrosCusto[2].valorTotal)
              && !!this.centrosCusto[3].cc
              && this.checkHours(this.centrosCusto[3].quantidadeHoras)
              && this.checkMoney(this.centrosCusto[3].valorTotal)
              && !!this.centrosCusto[4].cc
              && this.checkHours(this.centrosCusto[4].quantidadeHoras)
              && this.checkMoney(this.centrosCusto[4].valorTotal));
      }
      return false;
    },

    checkHours(hours) {
      if (hours.includes(':')) {
        const [horas, minutos] = hours.split(':');

        if (horas > 0 && minutos < 60) {
          return true;
        }
        this.mensagemDinamica = 'Você deve digitar um horário valido.';
        return false;
      }

      if (hours > 0) {
        return true;
      }
      this.mensagemDinamica = 'Você deve digitar um horário valido.';
      return false;
    },

    async getCentrosUnidade() {
      try {
        const { data: response } = await this.$http.get('/data-from-sap/centro-filiais');
        this.unidades = response.map(item => item.descricaoItens);
      } catch (error) {
        console.error(error);
      }
    },

    async getCentroCustos() {
      try {
        const { data: response } = await this.$http.get('/data-from-sap/centro-custo');
        this.centrosCustos = response.map(item => item.descricaoItens);
        this.objetoCentroCusto = response;
      } catch (error) {
        console.error(error);
      }
    },

    getDadosCentroCusto(centrosCusto) {
      try {
        centrosCusto.forEach((item) => {
          this.objetoCentroCusto.filter((element) => {
            if (item.cc.includes(element.descricaoItens)) {
              this.aprovadoresList.push(element);
            }

            return this.aprovadoresList;
          });
        });
      } catch (e) {
        console.error(e);
      }
    },

    async getFornecedores() {
      try {
        const { data: response } = await this.$http.get('/data-from-sap/fornecedor');
        this.fornecedores = response.map(item => item.descricaoItens);
      } catch (error) {
        console.error(error);
      }
    },

    addNewCC() {
      if (this.centrosCusto.length < 5) {
        this.centrosCusto.push({ cc: '', quantidadeHoras: 0, valorTotal: '' });
      } else {
        this.alerta = 'error';
        this.msg = 'Limite de 5 centro de custo por Atendimento';
        this.snackBarAlertaCampos = true;
      }
    },

    removeOldCC() {
      this.centrosCusto.pop();
    },

    inputValueInAprovadores(dadosFluxo) {
      const aprovadoresWithValue = dadosFluxo.aprovadoresList.map((item, index) => ({
        ...item,
        valor: dadosFluxo.centrosCusto[index].valorTotal,
      }));
      return aprovadoresWithValue;
    },

    trataResposta(dadosFluxo) {
      const respostasModel = {};

      if (dadosFluxo.empresa) respostasModel.column_campo_1 = dadosFluxo.empresa;
      if (dadosFluxo.unidade) respostasModel.column_campo_2 = dadosFluxo.unidade;
      if (dadosFluxo.fornecedor) respostasModel.column_campo_3 = dadosFluxo.fornecedor;
      if (dadosFluxo.servico) respostasModel.column_campo_4 = dadosFluxo.servico;
      if (dadosFluxo.remoneracaoHora) respostasModel.column_campo_5 = dadosFluxo.remoneracaoHora;
      if (dadosFluxo.centrosCusto[0]) {
        respostasModel.column_campo_6 = dadosFluxo.centrosCusto[0].cc;
        respostasModel.column_campo_7 = dadosFluxo.centrosCusto[0].quantidadeHoras;
        respostasModel.column_campo_8 = dadosFluxo.centrosCusto[0].valorTotal;
      }
      if (dadosFluxo.centrosCusto[1]) {
        respostasModel.column_campo_9 = dadosFluxo.centrosCusto[1].cc;
        respostasModel.column_campo_10 = dadosFluxo.centrosCusto[1].quantidadeHoras;
        respostasModel.column_campo_11 = dadosFluxo.centrosCusto[1].valorTotal;
      }
      if (dadosFluxo.centrosCusto[2]) {
        respostasModel.column_campo_12 = dadosFluxo.centrosCusto[2].cc;
        respostasModel.column_campo_13 = dadosFluxo.centrosCusto[2].quantidadeHoras;
        respostasModel.column_campo_14 = dadosFluxo.centrosCusto[2].valorTotal;
      }
      if (dadosFluxo.centrosCusto[3]) {
        respostasModel.column_campo_15 = dadosFluxo.centrosCusto[3].cc;
        respostasModel.column_campo_16 = dadosFluxo.centrosCusto[3].quantidadeHoras;
        respostasModel.column_campo_17 = dadosFluxo.centrosCusto[3].valorTotal;
      }
      if (dadosFluxo.centrosCusto[4]) {
        respostasModel.column_campo_18 = dadosFluxo.centrosCusto[4].cc;
        respostasModel.column_campo_19 = dadosFluxo.centrosCusto[4].quantidadeHoras;
        respostasModel.column_campo_20 = dadosFluxo.centrosCusto[4].valorTotal;
      }
      respostasModel.validation = true;

      const aprovadoresWithValue = this.inputValueInAprovadores(dadosFluxo);
      respostasModel.aprovadoresList = aprovadoresWithValue;
      respostasModel.usuarioSolicitante = this.$store.getters.isUsuario.nome;

      this.newDadosFluxo = respostasModel;
    },

    enviaJSON() {
      if (!this.verificaCampos) {
        this.dadosFluxo = { validation: false };
        this.alerta = 'error';
        this.msg = this.mensagemDinamica;
        this.snackBarAlertaCampos = true;
      } else {
        this.getDadosCentroCusto(this.centrosCusto);
        this.dadosFluxo = {
          validation: true,
          empresa: this.empresa,
          unidade: this.unidade,
          fornecedor: this.fornecedorSelecionado,
          servico: this.servico,
          remoneracaoHora: this.remoneracaoHora,
          centrosCusto: this.centrosCusto,
          aprovadoresList: this.aprovadoresList,
        };
        this.trataResposta(this.dadosFluxo);
        this.$emit('dadosFluxo', this.newDadosFluxo);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}
.alert-fild-required::after {
    content: "*";
    color: red;
    margin-bottom: 10px;
    margin-left: 6px;
    font-size: x-small;
}
.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.count-warning {
  background-color: transparent;
  color: red;
}
</style>
