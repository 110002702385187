<template>
  <div id="preValidacao">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />

    <template>
      <CamposResposta
        v-for="(fluxo, index) in camposFluxo" :key="index"
        :camposFluxo="fluxo"
        :isDisabled="disabled"
        @emit-answer="getValue"
        />
    </template>

    <template>
      <v-btn
        v-if="fluxoId !== IdFluxoMotoboy"
        :class="{ 'btn-primary-disabled ml-3': isStatusEncerrado,
                   'btn-primary ml-3': !isStatusEncerrado }"
        @click="toggleEdit"
        :disabled="isStatusEncerrado">
        <span v-if="!editar">
          <v-icon left>mdi-pencil</v-icon>Editar
        </span>
        <span v-else>
          <v-icon left>mdi-close</v-icon>Cancelar
        </span>
      </v-btn>

      <v-btn
        v-if="editar"
        class="btn-primary ml-2"
        @click="salvarFluxoEditado"
      >
        <v-icon left>mdi-content-save</v-icon>Salvar
      </v-btn>
    </template>

  </div>
</template>

<script>
import VueTypes from 'vue-types';
import CamposResposta from '../CamposResposta.vue';
import Snackbar from '../Snackbar';

export default {
  name: 'FluxoLabelsRespostas',

  components: {
    CamposResposta,
    Snackbar,
  },

  props: {
    camposFluxo: VueTypes.array,
    fluxoId: VueTypes.integer,
    atendimentoId: VueTypes.integer,
    statusAtendimento: VueTypes.string,
  },

  computed: {
    isStatusEncerrado() {
      return this.statusAtendimento === 'Encerrado';
    },
  },

  data() {
    return {
      editar: false,
      disabled: true,
      formCampos: {},
      IdCheckBox: 3, // Id do Banco de dados.
      IdFluxoMotoboy: 4,

      alerta: 'info',
      msg: '',
      snackBarAlertaCampos: false,
    };
  },

  mounted() {
    this.criaDicionarioCamposFluxoRespostas(this.camposFluxo);
  },

  methods: {
    criaDicionarioCamposFluxoRespostas(campos) {
      const novoDicionario = {};
      // eslint-disable-next-line array-callback-return
      campos.map((item) => {
        const campo = `column_campo_${item.column_campo}`;
        novoDicionario[campo] = item.resposta;
      });
      this.formCampos = novoDicionario;
    },

    getValue(value) {
      const campo = `column_campo_${value.column_campo}`;
      this.formCampos[campo] = value.resposta;
    },

    toggleEdit() {
      this.editar = !this.editar;
      this.disabled = !this.disabled;
    },

    async salvarFluxoEditado() {
      this.toggleEdit();

      this.alerta = 'success';
      this.msg = 'Formulário editado com sucesso!';
      this.snackBarAlertaCampos = true;

      const body = {
        id: this.atendimentoId,
        campos_fluxo: this.formCampos,
        responsavel: this.$store.getters.isUsuario.id,
        fluxo_id: this.fluxoId,
      };

      await this.$http.put('/atendimentos/editar/dados-fluxo', body);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-primary {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border: 1px solid #0056a8 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.btn-primary-disabled {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.v-icon {
  margin-top: 2px;
}

</style>
