var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"opcoesInativas"}},[_c('Snackbar',{attrs:{"label":_vm.msg,"top":"","right":"","tipo":'success',"timeout":2500},model:{value:(_vm.snackbarOpen),callback:function ($$v) {_vm.snackbarOpen=$$v},expression:"snackbarOpen"}}),_c('v-data-table',{attrs:{"item-key":"descricao","headers":_vm.headers,"items":_vm.inativos,"search":_vm.searchUser,"sort-by":['categoria_nome', 'subcategoria_nome'],"items-per-page":5,"no-data-text":'Não há setores/categorias/subcategorias inativos.',"no-results-text":'Setor/categoria/subcategoria não encontrada.',"footer-props":{
      'items-per-page-text': 'Resultados por página',
      'items-per-page-all-text': 'Todos',
      'page-text': '{0}-{1} de {2}',
  }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"label":"Pesquise um setor/categoria/subcategoria","outlined":"","clearable":""},model:{value:(_vm.searchUser),callback:function ($$v) {_vm.searchUser=$$v},expression:"searchUser"}},[(!_vm.searchUser)?_c('v-icon',{attrs:{"slot":"append"},slot:"append"},[_vm._v("mdi-account-search")]):_vm._e()],1),_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-dialog',{attrs:{"scrollable":"","max-width":"500"},model:{value:(_vm.dialogReativar),callback:function ($$v) {_vm.dialogReativar=$$v},expression:"dialogReativar"}},[_c('v-card',{staticClass:"px-2"},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Reativar "+_vm._s(_vm.tipoSelecionado)+"? ")]),_c('v-card-actions',{staticClass:"pb-4"},[_c('v-spacer'),_c('Botao',{staticClass:"mr-4",attrs:{"type":"button","label":"Cancelar","tipo":"secondary","callback":_vm.closeModal}}),_c('Botao',{attrs:{"type":"button","label":"Confirmar","callback":_vm.reativarTipo}})],1)],1)],1)],1)]},proxy:true},{key:"item.setor_nome",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.setor_nome)+" ")]}},{key:"item.categoria_nome",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.categoria_nome)+" ")]}},{key:"item.subcategoria_nome",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.subcategoria_nome)+" ")]}},{key:"item.acoes",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.reativarItem(item)}}},on),[_vm._v(" mdi-recycle ")])]}}],null,true)},[_c('span',[_vm._v("Reativar")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }