<template>
  <div class="page-error">
    <h4>Whoops...</h4>
    <p> {{error|| erroPadrao}} </p>


    <Botao
      label="Início"
      :callback="voltarInicio"
    />
  </div>
</template>

<script>
import Botao from '../components/Botao.vue';

export default {
  name: 'PageNotFoundService',
  components: {
    Botao,
  },
  data() {
    return {
      error: this.$route.params.error,
      erroPadrao: 'Você não tem acesso a este Atendimento.',
    };
  },
  methods: {
    voltarInicio() {
      this.$router.push({ name: 'home' });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
}
</style>
