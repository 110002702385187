var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.camposFluxo.id_tipo_campos == _vm.tipoText && !_vm.camposFluxo.id_tipo_mascara
    && !_vm.camposFluxo.min_caracter && _vm.camposFluxo.resposta !== null)?_c('div',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"label":_vm.camposFluxo.descricao,"outlined":"","dense":"","auto-grow":"","disabled":_vm.isDisabled},on:{"change":function($event){return _vm.$emit('emit-answer',
          { resposta: _vm.camposFluxo.resposta,
            column_campo: _vm.camposFluxo.column_campo
          })}},model:{value:(_vm.camposFluxo.resposta),callback:function ($$v) {_vm.$set(_vm.camposFluxo, "resposta", $$v)},expression:"camposFluxo.resposta"}})],1)],1):_vm._e(),(_vm.camposFluxo.id_tipo_campos == _vm.tipoText && _vm.camposFluxo.id_tipo_mascara
    && _vm.camposFluxo.id_tipo_mascara !== _vm.mascaraDinheiroRealID
    && _vm.camposFluxo.id_tipo_mascara !== _vm.mascaraDinheiroDolarID
    && _vm.camposFluxo.id_tipo_mascara !== _vm.mascaraDinheiroEuroID
    && _vm.camposFluxo.resposta !== null)?_c('div',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.populaMascara),expression:"populaMascara"}],attrs:{"label":_vm.camposFluxo.descricao,"outlined":"","dense":"","auto-grow":"","disabled":_vm.isDisabled},on:{"change":function($event){return _vm.$emit('emit-answer',
          { resposta: _vm.camposFluxo.resposta,
            column_campo: _vm.camposFluxo.column_campo
          })}},model:{value:(_vm.camposFluxo.resposta),callback:function ($$v) {_vm.$set(_vm.camposFluxo, "resposta", $$v)},expression:"camposFluxo.resposta"}})],1)],1):_vm._e(),(_vm.camposFluxo.id_tipo_campos == _vm.tipoText && _vm.camposFluxo.id_tipo_mascara
    && _vm.camposFluxo.id_tipo_mascara == _vm.mascaraDinheiroRealID && _vm.camposFluxo.resposta !== null)?_c('div',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.real),expression:"real"}],attrs:{"label":_vm.camposFluxo.descricao,"outlined":"","dense":"","auto-grow":"","disabled":_vm.isDisabled},on:{"change":function($event){return _vm.$emit('emit-answer',
          { resposta: _vm.camposFluxo.resposta,
            column_campo: _vm.camposFluxo.column_campo
          })}},model:{value:(_vm.camposFluxo.resposta),callback:function ($$v) {_vm.$set(_vm.camposFluxo, "resposta", $$v)},expression:"camposFluxo.resposta"}})],1)],1):_vm._e(),(_vm.camposFluxo.id_tipo_campos == _vm.tipoText && _vm.camposFluxo.id_tipo_mascara
    && _vm.camposFluxo.id_tipo_mascara == _vm.mascaraDinheiroDolarID && _vm.camposFluxo.resposta !== null)?_c('div',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.dolar),expression:"dolar"}],attrs:{"label":_vm.camposFluxo.descricao,"outlined":"","dense":"","auto-grow":"","disabled":_vm.isDisabled},on:{"change":function($event){return _vm.$emit('emit-answer',
          { resposta: _vm.camposFluxo.resposta,
            column_campo: _vm.camposFluxo.column_campo
          })}},model:{value:(_vm.camposFluxo.resposta),callback:function ($$v) {_vm.$set(_vm.camposFluxo, "resposta", $$v)},expression:"camposFluxo.resposta"}})],1)],1):_vm._e(),(_vm.camposFluxo.id_tipo_campos == _vm.tipoText && _vm.camposFluxo.id_tipo_mascara
    && _vm.camposFluxo.id_tipo_mascara == _vm.mascaraDinheiroEuroID && _vm.camposFluxo.resposta !== null)?_c('div',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.euro),expression:"euro"}],attrs:{"label":_vm.camposFluxo.descricao,"outlined":"","dense":"","auto-grow":"","disabled":_vm.isDisabled},on:{"change":function($event){return _vm.$emit('emit-answer',
          { resposta: _vm.camposFluxo.resposta,
            column_campo: _vm.camposFluxo.column_campo
          })}},model:{value:(_vm.camposFluxo.resposta),callback:function ($$v) {_vm.$set(_vm.camposFluxo, "resposta", $$v)},expression:"camposFluxo.resposta"}})],1)],1):_vm._e(),(_vm.camposFluxo.id_tipo_campos == _vm.tipoText && _vm.camposFluxo.min_caracter
    && _vm.camposFluxo.resposta !== null)?_c('div',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"label":_vm.camposFluxo.descricao,"outlined":"","dense":"","auto-grow":"","disabled":_vm.isDisabled},on:{"change":function($event){return _vm.$emit('emit-answer',
          { resposta: _vm.camposFluxo.resposta,
            column_campo: _vm.camposFluxo.column_campo
          })}},model:{value:(_vm.camposFluxo.resposta),callback:function ($$v) {_vm.$set(_vm.camposFluxo, "resposta", $$v)},expression:"camposFluxo.resposta"}}),(_vm.camposFluxo.id_tipo_campos == _vm.tipoText && _vm.camposFluxo.min_caracter
           && _vm.camposFluxo.resposta !== null && !_vm.isDisabled)?_c('p',{staticClass:"caption text-right"},[_vm._v(" Mínimo "+_vm._s(_vm.camposFluxo.min_caracter)+" caracteres ")]):_vm._e()],1)],1):_vm._e(),(_vm.camposFluxo.id_tipo_campos == _vm.tipoNumber && _vm.camposFluxo.resposta !== null)?_c('div',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"label":_vm.camposFluxo.descricao,"type":"number","outlined":"","dense":"","auto-grow":"","disabled":_vm.isDisabled},on:{"change":function($event){return _vm.$emit('emit-answer',
          { resposta: _vm.camposFluxo.resposta,
            column_campo: _vm.camposFluxo.column_campo
          })}},model:{value:(_vm.camposFluxo.resposta),callback:function ($$v) {_vm.$set(_vm.camposFluxo, "resposta", $$v)},expression:"camposFluxo.resposta"}})],1)],1):_vm._e(),(_vm.camposFluxo.id_tipo_campos == _vm.tipoCheckBox && _vm.camposFluxo.resposta !== null)?_c('div',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-checkbox',{attrs:{"label":_vm.camposFluxo.descricao,"disabled":_vm.isDisabled},on:{"change":function($event){return _vm.$emit('emit-answer',
          { resposta: _vm.camposFluxo.resposta,
            column_campo: _vm.camposFluxo.column_campo
          })}},model:{value:(_vm.camposFluxo.resposta),callback:function ($$v) {_vm.$set(_vm.camposFluxo, "resposta", $$v)},expression:"camposFluxo.resposta"}})],1)],1):_vm._e(),(_vm.camposFluxo.id_tipo_campos == _vm.tipoRadioButton && _vm.camposFluxo.resposta !== null)?_c('div',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-radio-group',{attrs:{"row":"","label":_vm.camposFluxo.descricao,"disabled":_vm.isDisabled},on:{"change":function($event){return _vm.$emit('emit-answer',
          { resposta: _vm.camposFluxo.resposta,
            column_campo: _vm.camposFluxo.column_campo
          })}},model:{value:(_vm.camposFluxo.resposta),callback:function ($$v) {_vm.$set(_vm.camposFluxo, "resposta", $$v)},expression:"camposFluxo.resposta"}},_vm._l((_vm.opcoes),function(opcao,index){return _c('v-radio',{key:index,attrs:{"label":opcao.text,"value":opcao.text}})}),1)],1)],1):_vm._e(),(_vm.camposFluxo.id_tipo_campos == _vm.tipoLongText && !_vm.camposFluxo.min_caracter
   && _vm.camposFluxo.resposta !== null)?_c('div',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-textarea',{attrs:{"label":_vm.camposFluxo.descricao,"outlined":"","dense":"","auto-grow":"","disabled":_vm.isDisabled},on:{"change":function($event){return _vm.$emit('emit-answer',
          { resposta: _vm.camposFluxo.resposta,
            column_campo: _vm.camposFluxo.column_campo
          })}},model:{value:(_vm.camposFluxo.resposta),callback:function ($$v) {_vm.$set(_vm.camposFluxo, "resposta", $$v)},expression:"camposFluxo.resposta"}})],1)],1):_vm._e(),(_vm.camposFluxo.id_tipo_campos == _vm.tipoLongText && _vm.camposFluxo.min_caracter
   && _vm.camposFluxo.resposta !== null)?_c('div',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-textarea',{attrs:{"label":_vm.camposFluxo.descricao,"outlined":"","dense":"","auto-grow":"","disabled":_vm.isDisabled},on:{"change":function($event){return _vm.$emit('emit-answer',
          { resposta: _vm.camposFluxo.resposta,
            column_campo: _vm.camposFluxo.column_campo
          })}},model:{value:(_vm.camposFluxo.resposta),callback:function ($$v) {_vm.$set(_vm.camposFluxo, "resposta", $$v)},expression:"camposFluxo.resposta"}}),(_vm.camposFluxo.id_tipo_campos == _vm.tipoLongText && _vm.camposFluxo.min_caracter
            && _vm.camposFluxo.resposta !== null && !_vm.isDisabled)?_c('p',{staticClass:"caption text-right"},[_vm._v(" Mínimo "+_vm._s(_vm.camposFluxo.min_caracter)+" caracteres ")]):_vm._e()],1)],1):_vm._e(),(_vm.camposFluxo.id_tipo_campos == _vm.tipoInputSelect
   && _vm.camposFluxo.resposta !== null)?_c('div',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-select',{attrs:{"label":_vm.camposFluxo.descricao,"items":_vm.opcoes,"outlined":"","dense":"","auto-grow":"","disabled":_vm.isDisabled},on:{"change":function($event){return _vm.$emit('emit-answer',
          { resposta: _vm.camposFluxo.resposta,
            column_campo: _vm.camposFluxo.column_campo
          })}},model:{value:(_vm.camposFluxo.resposta),callback:function ($$v) {_vm.$set(_vm.camposFluxo, "resposta", $$v)},expression:"camposFluxo.resposta"}})],1)],1):_vm._e(),(_vm.camposFluxo.id_tipo_campos == _vm.tipoData && _vm.camposFluxo.resposta !== null)?_c('div',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","readonly":"","label":_vm.camposFluxo.descricao,"append-icon":"event","disabled":_vm.isDisabled},model:{value:(_vm.camposFluxo.resposta),callback:function ($$v) {_vm.$set(_vm.camposFluxo, "resposta", $$v)},expression:"camposFluxo.resposta"}},'v-text-field',attrs,false),on))]}}],null,false,2345628364),model:{value:(_vm.menuData),callback:function ($$v) {_vm.menuData=$$v},expression:"menuData"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"pt-br"},on:{"change":function($event){return _vm.$emit('emit-answer',
            { resposta: _vm.camposFluxo.resposta,
              column_campo: _vm.camposFluxo.column_campo
            })},"input":function($event){_vm.menuData = false}},model:{value:(_vm.dataFormatted),callback:function ($$v) {_vm.dataFormatted=$$v},expression:"dataFormatted"}})],1)],1)],1):_vm._e(),(_vm.camposFluxo.id_tipo_campos == _vm.tipoHora
   && _vm.camposFluxo.resposta !== null)?_c('div',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":_vm.camposFluxo.descricao,"type":"time","outlined":"","dense":"","auto-grow":"","disabled":_vm.isDisabled},on:{"change":function($event){return _vm.$emit('emit-answer',
          { resposta: _vm.camposFluxo.resposta,
            column_campo: _vm.camposFluxo.column_campo
          })}},model:{value:(_vm.camposFluxo.resposta),callback:function ($$v) {_vm.$set(_vm.camposFluxo, "resposta", $$v)},expression:"camposFluxo.resposta"}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }