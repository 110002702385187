import { render, staticRenderFns } from "./GestaoSetoresCategoriasSub.vue?vue&type=template&id=4f32f1ca&scoped=true&"
import script from "./GestaoSetoresCategoriasSub.vue?vue&type=script&lang=js&"
export * from "./GestaoSetoresCategoriasSub.vue?vue&type=script&lang=js&"
import style0 from "./GestaoSetoresCategoriasSub.vue?vue&type=style&index=0&id=4f32f1ca&lang=scss&scoped=true&"
import style1 from "./GestaoSetoresCategoriasSub.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f32f1ca",
  null
  
)

export default component.exports