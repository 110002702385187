import { render, staticRenderFns } from "./FormComprasOrdinarias.vue?vue&type=template&id=4946a177&scoped=true&"
import script from "./FormComprasOrdinarias.vue?vue&type=script&lang=js&"
export * from "./FormComprasOrdinarias.vue?vue&type=script&lang=js&"
import style0 from "./FormComprasOrdinarias.vue?vue&type=style&index=0&id=4946a177&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4946a177",
  null
  
)

export default component.exports