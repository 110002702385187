<template>
  <div class="principal">
    <v-card
      v-if="this.dadosAtendimentoPorStatus.status_desc.length !== 0"
      :max-height="480"
      :max-width="600"
      :elevation="4"
      class="card-indicador mt-2 px-3 pt-3 pb-0"
    >
      <v-overlay
        :value="loaderAtivo"
        :absolute="true"
        :opacity="0.30"
      >
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <div ref="capture">
        <div class="div-titulo-dias">
          <div class="div-titulo-botao">
            <span class="span-tempo-medio">
              Tempo médio de Atendimento por dia <br> (Ag. usuário não faz parte da média)
            </span>
            <button
              class="botao-download"
              @click="screenShot()"
            ><span class="
              material-icons">
                get_app
              </span>
            </button>
          </div>
          <br>
          <div class="div-relogio">
            <span class="material-icons">
              watch_later
            </span>
            <span class="span-tempo-medio">
              {{ tempoMedioGeral }} dia(s)
            </span>
          </div>
        </div>
        <apexchart
          type="bar"
          :options="chartOptions"
          :series="series"
          width="540"
          height="254"
        ></apexchart>
      </div>
    </v-card>
    <v-card
      v-else
      :height="300"
      :max-width="480"
      :elevation="4"
      class="card-indicador mt-2 px-3 pt-3 pb-0"
    >
      <div class="div-sem-dados">
        <span class="mensagem-sem-dados">Nenhum dado encontrado de acordo com o filtro!</span>
      </div>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import VueTypes from 'vue-types';
import screenShot from '../../mixins/screenShot.js';

export default {
  name: 'IndicadorTempoMedio',
  components: {
    apexchart: VueApexCharts,
  },
  mixins: [screenShot],
  props: {
    categorias: VueTypes.array.isRequired,
    tempoMedioGeral: VueTypes.string.isRequired,
    dadosAtendimentoPorStatus: VueTypes.object.isRequired,
    loader: VueTypes.bool.isRequired,
  },

  data() {
    return {
      loaderAtivo: this.loader,
      series: [
        {
          data: this.dadosAtendimentoPorStatus.status_media,
        },
      ],

      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,

          toolbar: {
            show: false,
          },
        },

        plotOptions: {
          bar: {
            horizontal: true,
            startingShape: 'flat',
            endingShape: 'rounded',
            barHeight: '30%',
          },
        },

        title: {
          text: 'Tempo médio de Atendimento por status',
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#333',
          },
        },

        dataLabels: {
          enabled: true,
          textAnchor: 'middle',
          style: {
            fontSize: '13px',
            fontWeight: 'bold',
            colors: ['#fff'],
          },
          offsetX: 0,
        },

        grid: {
          show: false,

          yaxis: {
            lines: {
              show: false,
            },
          },

          xaxis: {
            lines: {
              show: false,
            },
          },
        },

        xaxis: {
          type: 'category',
          categories: this.dadosAtendimentoPorStatus.status_desc,
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },

        yaxis: {
          show: true,
          labels: {
            show: true,
            align: 'right',
            style: {
              fontSize: '12px',
              fontFamily: 'Open Sans',
              fontWeight: 'bold',
            },
          },
        },

        tooltip: {
          followCursor: true,
          y: {
            formatter: function formatador(value) {
              return `${value} dia(s)`;
            },
            title: {
              formatter: () => 'Tempo por status',
            },
          },
        },

        legend: {
          show: false,
        },

        colors: ['#0056a8'],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.card-indicador {
  border-radius: 20px !important;
}

.principal {
  .div-titulo-dias {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-bottom: 50px;

    .div-titulo-botao {
      display: flex;
      justify-content: space-between;

      .span-tempo-medio {
        font-weight: bold;
        color: #333;
        font-size: 13px;
      }

      .botao-download {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 8%;
        border-radius: 5px;
        border: 2px solid #eeeeee;

        &:hover {
          background-color: #e2dede;
        }

        .material-icons {
          color: #0056a8;
          font-size: 25px;
        }
      }
    }

    .div-relogio {
      display: flex;
      align-items: center;

      .material-icons {
        color: #0056a8;
        font-size: 40px;
      }

      .span-tempo-medio {
        padding-left: 10px;
        font-size: 25px;
        font-weight: bold;
        color: #000;
      }
    }
  }

  .div-sem-dados {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .mensagem-sem-dados {
      font-size: 18px;
    }
  }
}
</style>
