<template>
  <div>
    <h6 class="show-list-categorias">{{ categoria }}</h6>
    <ul
      v-for="(subcategoria, index) in subcategorias"
      :key="index"
      class="show-list-subcategorias"
    >
      <li>
        <a
          :href="subcategoria.mensagensFaqDuvida.url_socialwork"
          target="_blank"
        >{{ subcategoria.nome }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'FAQList',
  props: {
    categoria: VueTypes.string.isRequired,
    subcategorias: VueTypes.array.isRequired,
  },
};
</script>

<style lang="scss" scoped>
.show-list-subcategorias {
  list-style-type: none;
  padding: 0;
  color: blue;
  text-align: center;
  font-weight: bold;
}

.show-list-categorias{
  font-weight: bold;
  text-align: center;
  font-size: 1,2vw;
}
</style>
