import { render, staticRenderFns } from "./ComprasFixas.vue?vue&type=template&id=942b3976&scoped=true&"
import script from "./ComprasFixas.vue?vue&type=script&lang=js&"
export * from "./ComprasFixas.vue?vue&type=script&lang=js&"
import style0 from "./ComprasFixas.vue?vue&type=style&index=0&id=942b3976&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "942b3976",
  null
  
)

export default component.exports