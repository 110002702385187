import { getMaxPermission } from '../../utils/utilities';
import { setores } from '../../Constants.js';

export default function diretoria({ next, store }) {
  const permissaoGestor = store.getters.listaPermissoes.gestor;
  const atendenteTipo = getMaxPermission(store);

  const validaDiretoria = store.getters.isUsuario.permissao.filter(element => element.id_permissao
    === store.getters.listaPermissoes.gestorgeral
    || element.id_permissao === store.getters.listaPermissoes.businesspartner
    || (element.id_permissao === permissaoGestor && atendenteTipo.setor_nome
      === setores.departamentoDePessoal)
    || (element.id_permissao === permissaoGestor && atendenteTipo.setor_nome
      === setores.juridico)).length;

  if (validaDiretoria) {
    return next();
  }
  const pagina = window.location.pathname;
  return next({
    name: 'login',
    params: { fromPagina: pagina },
  });
}
