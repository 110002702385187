<template>
  <div id="formDesligamento">
    <Snackbar
      :label=msg
      v-model="snackbarOpen"
      top
      right
      :tipo="'success'"
      :timeout="2500"
    />
    <v-form>
      <v-divider></v-divider>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="funcionario"
            label="Email profissional"
            outlined
            dense
            :disabled="editar ? false : true"
          ></v-text-field>
        </v-col>

        <v-col cols="5">
          <v-text-field
            v-model="nomeUsuario"
            label="Nome do Profissional"
            outlined
            dense
            clearable
            auto-grow
            :disabled="editar ? false : true"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-radio-group
            v-model="vinculo"
            row
            label="Vinculo: "
            :disabled="editar ? false : true"
          >
            <v-radio
              label="CLT"
              value="CLT"
            ></v-radio>
            <v-radio
              label="Estágio"
              value="Estágio"
            ></v-radio>
            <v-radio
              label="Sócio"
              value="Sócio"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-select
            v-model="solicitante"
            :items="itensSolicitante"
            label="Solicitante"
            outlined
            dense
            :disabled="editar ? false : true"
          ></v-select>
        </v-col>

        <v-col
          cols="5"
          v-if="exibeTipoDesligamento"
        >
          <v-select
            v-model="tipoDesligamento"
            :items="itensDesligamento"
            label="Tipo de Desligamento"
            outlined
            dense
            :disabled="editar ? false : true"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="5"
          v-if="exibeTipoAviso"
        >
          <v-select
            v-model="tipoAviso"
            :items="itensAviso"
            label="Tipo de aviso"
            outlined
            dense
            :disabled="editar ? false : true"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="5"
          v-if="exibeCausas"
        >
          <v-select
            v-model="causa"
            :items="causas"
            label="Causas"
            outlined
            dense
            :disabled="editar ? false : true"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="5"
          v-if="exibeDataDesligamento"
        >
          <v-menu
            v-model="menuDataDesligamento"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dataFormatadaDesligamento"
                label="Data do Desligamento"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                :disabled="editar ? false : true"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="newDateDesligamento"
              @input="menuDataDesligamento = false"
              :first-day-of-week="0"
              locale="pt-br"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="5"
          v-if="exibeDataInicioAvisoEdataFim"
        >
          <v-menu
            v-model="menuDataInicioAviso"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dataFormatadaInicioAviso"
                label="Data de Inicio do Aviso"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                :disabled="editar ? false : true"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="newDateInicioAviso"
              @input="menuDataInicioAviso = false"
              :first-day-of-week="0"
              locale="pt-br"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="5"
          v-if="exibeDataInicioAvisoEdataFim"
        >
          <v-text-field
            v-model="quantidadeDias"
            label="Quantidade de Dias"
            type="number"
            outlined
            dense
            max="30"
            min="0"
            clearable
            auto-grow
            :disabled="editar ? false : true"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="5"
            v-if="exibeDataInicioAvisoEdataFim"
          >
          <v-menu
            v-model="menuDataFimAviso"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dataFormatadaFim"
                label="Data fim do aviso"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                :disabled="editar ? false : true"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="newDateFimAviso"
              @input="menuDataFimAviso = false"
              :first-day-of-week="0"
              locale="pt-br"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="5"
          v-if="exibeDesconto"
        >
          <v-radio-group
            v-model="descontoAviso"
            row
            label="Desconto de aviso"
            :disabled="editar ? false : true"
          >
            <v-radio
              label="Sim (Descontar)"
              :value="'Sim (Descontar)'"
            ></v-radio>
            <v-radio
              label="Não (Dispensado)"
              :value="'Não (Dispensado)'"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="5"
          v-if="exibeDescontoAvisoMulta"
        >
          <v-radio-group
            v-model="descontoAvisoMulta"
            row
            label="Desconto - Multa quebra de contrato"
            :disabled="editar ? false : true"
          >
            <v-radio
              label="Sim (Descontar)"
              :value="'Sim (Descontar)'"
            ></v-radio>
            <v-radio
              label="Não (Dispensado)"
              :value="'Não (Dispensado)'"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="dataAdmissao"
            label="Data de contratação do Profissional"
            outlined
            dense
            clearable
            :disabled="editar ? false : true"
          ></v-text-field>
        </v-col>

        <v-col cols="5">
          <v-text-field
            v-model="emailGestor"
            label="Email do Gestor"
            outlined
            dense
            :disabled="editar ? false : true"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="cc"
            label="CC que vai o custo de rescisão"
            outlined
            dense
            clearable
            :disabled="editar ? false : true"
          ></v-text-field>
        </v-col>

        <v-col cols="5">
          <v-select
            v-model="unidade"
            :items="sedes"
            label="Unidade"
            outlined
            dense
            :disabled="editar ? false : true"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="telefone"
            label="Telefone do profissional"
            outlined
            dense
            clearable
            auto-grow
            :disabled="editar ? false : true"
          ></v-text-field>
        </v-col>

        <v-col cols="5">
          <v-text-field
            v-model="emailPessoal"
            label="E-mail pessoal do profissional"
            outlined
            dense
            clearable
            :disabled="editar ? false : true"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-radio-group
            v-model="absorvido"
            row
            label="Profissional absorvido pelo cliente? "
            :disabled="editar ? false : true"
          >
            <v-radio
              label="Sim"
              :value="'Sim'"
            ></v-radio>
            <v-radio
              label="Não"
              :value="'Não'"
            ></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="5">
          <v-radio-group
            v-model="alocado"
            row
            label="Profissional alocado no cliente? "
            :disabled="editar ? false : true"
          >
            <v-radio
              label="Sim"
              :value="'Sim'"
            ></v-radio>
            <v-radio
              label="Não"
              :value="'Não'"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="5">
          <v-select
            v-model="motivo"
            :items="itensMotivo"
            label="Motivo"
            outlined
            dense
            :disabled="editar ? false : true"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-select
            v-model="futuraContratacao"
            :items="contratacao"
            label="Futura Contratação?"
            outlined
            dense
            :disabled="editar ? false : true"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10">
          <v-textarea
            v-model="justifique"
            label="Justifique"
            outlined
            dense
            clearable
            auto-grow
            :disabled="editar ? false : true"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-btn
        :class="{ 'btn-primary-disabled ml-0': isStatusEncerrado,
        'btn-primary ml-0': !isStatusEncerrado }"
        @click="toggleEdit"
        :disabled="isStatusEncerrado"
      >
        <span v-if="!editar">
          <v-icon left>mdi-pencil</v-icon>Editar
        </span>
        <span v-else>
          <v-icon left>mdi-close</v-icon>Cancelar
        </span>
      </v-btn>

      <v-btn
        v-if="editar"
        class="btn-primary ml-2"
        @click="salvarFluxoEditado"
      >
        <v-icon left>mdi-content-save</v-icon>Salvar
      </v-btn>

    </v-form>
  </div>
</template>

<script>
import moment from 'moment';
import VueTypes from 'vue-types';
import { mask } from 'vue-the-mask';
import Snackbar from '../Snackbar';

export default {
  name: 'formDesligamento',

  props: {
    camposFluxo: VueTypes.array,
    dadosFluxo: VueTypes.object,
    fluxoId: VueTypes.integer,
    atendimentoId: VueTypes.integer,
    statusAtendimento: VueTypes.string,
  },

  components: {
    Snackbar,
  },

  directives: { mask },

  data: () => ({
    funcionario: '',
    nomeUsuario: '',
    vinculo: '',
    solicitante: '',
    tipoDesligamento: '',
    tipoAviso: '',
    causa: '',
    causas: ['Abandono de Emprego', 'Outros (Orientação Prévia do Jurídico)'],

    menuDataDesligamento: false,
    menuDataInicioAviso: false,
    menuDataFimAviso: false,
    dataDesligamento: new Date().toISOString().substr(0, 10),
    dataInicioAviso: new Date().toISOString().substr(0, 10),
    dataFimAviso: '',

    newDateDesligamento: '',
    newDateInicioAviso: '',
    newDateFimAviso: '',

    quantidadeDias: '',

    descontoAviso: '',
    descontoAvisoMulta: '',
    emailGestor: '',
    dataAdmissao: '',
    cc: '',

    unidade: '',
    sedes: [],
    telefone: '',
    emailPessoal: '',
    absorvido: '',
    alocado: '',
    motivo: '',

    futuraContratacao: '',
    contratacao: ['Sim', 'Não'],

    justifique: '',

    editar: false,
    disabled: false,

    snackbarOpen: false,
    msg: '',
  }),

  computed: {
    itensSolicitante() {
      if (this.vinculo === 'Estágio') {
        return ['Empresa', 'Estagiário'];
      }
      return ['Empresa', 'Colaborador'];
    },

    itensDesligamento() {
      if (this.vinculo === 'CLT' && this.solicitante === 'Empresa') {
        return ['Aviso prévio', 'Término de contrato', 'Término de contrato antecipado', 'Justa causa'];
      }

      if (this.vinculo === 'CLT' && this.solicitante === 'Colaborador') {
        return ['Pedido de demissão', 'Término de contrato', 'Término de contrato antecipado'];
      }

      if (this.vinculo === 'Estágio' && this.solicitante === 'Empresa') {
        return ['Efetivação', 'Término de contrato', 'Término de contrato antecipado'];
      }

      if (this.vinculo === 'Estágio' && this.solicitante === 'Estagiário') {
        return ['Término de contrato', 'Pedido de demissão'];
      }

      return ['Selecionar campos anteriores'];
    },

    itensAviso() {
      if (this.solicitante === 'Empresa' && this.tipoDesligamento === 'Aviso prévio') {
        return ['Indenizado', 'Trabalhado'];
      }

      if (this.solicitante === 'Colaborador' && this.tipoDesligamento === 'Pedido de demissão') {
        return ['Ausência de Aviso', 'Trabalhado'];
      }

      return ['Selecionar campos anteriores'];
    },

    dataFormatadaDesligamento() {
      if (this.newDateDesligamento) return moment(this.newDateDesligamento).format('DD/MM/YYYY');
      return this.dataDesligamento;
    },

    dataFormatadaInicioAviso() {
      if (this.newDateInicioAviso) return moment(this.newDateInicioAviso).format('DD/MM/YYYY');
      return this.dataInicioAviso;
    },

    dataFormatadaFim() {
      if (this.newDateFimAviso) return moment(this.newDateFimAviso).format('DD/MM/YYYY');
      return this.dataFimAviso;
    },

    exibeTipoDesligamento() {
      return this.vinculo !== 'Sócio';
    },

    exibeTipoAviso() {
      return (this.solicitante === 'Empresa' && this.tipoDesligamento === 'Aviso prévio')
        || (this.solicitante === 'Colaborador' && this.tipoDesligamento === 'Pedido de demissão');
    },

    exibeCausas() {
      return this.tipoDesligamento === 'Justa causa';
    },

    exibeDataDesligamento() {
      return this.tipoAviso !== 'Trabalhado';
    },

    exibeDataInicioAvisoEdataFim() {
      return this.tipoAviso === 'Trabalhado';
    },

    exibeDescontoAvisoMulta() {
      return this.solicitante === 'Colaborador'
        && this.tipoDesligamento === 'Término de contrato antecipado';
    },

    exibeDesconto() {
      return ((this.tipoDesligamento === 'Pedido de demissão' && this.tipoAviso === 'Trabalhado') && (this.quantidadeDias < 30 && this.quantidadeDias !== 30))
       || (this.tipoDesligamento === 'Pedido de demissão' && this.tipoAviso === 'Ausência de Aviso');
    },

    isStatusEncerrado() {
      return this.statusAtendimento === 'Encerrado';
    },

    itensMotivo() {
      if (this.solicitante === 'Colaborador' || this.solicitante === 'Estagiário') {
        return [
          'Absorção cliente',
          'Absorção Meta',
          'Atividades desenvolvidas atualmente',
          'Cultura Organizacional Cliente',
          'Cultura Organizacional Meta',
          'Modalidade de trabalho (presencial / remoto)',
          'Mudança de carreira',
          'Mudança de localidade',
          'Novos desafios (cargo / projeto / empresa)',
          'Ociosidade',
          'Problemas pessoais',
          'Proposta internacional',
          'Relacionamento',
          'Remuneração e Benefícios',
        ];
      }

      if (this.solicitante === 'Empresa' || this.solicitante === 'Estagiário') {
        return [
          'Abandono',
          'Absorção cliente',
          'Absorção Meta',
          'Cultura organizacional Cliente',
          'Cultura organizacional Meta',
          'Desempenho',
          'Efetivação',
          'Falecimento',
          'Faltas/Atraso',
          'Ociosidade',
          'Redução projeto',
          'Relacionamento',
          'Término projeto',
        ];
      }

      return ['Selecionar campos anteriores'];
    },
  },

  mounted() {
    try {
      moment.locale('pt-br');
      this.listaSedes();
      this.loadInfoCamposFluxo();
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    loadInfoCamposFluxo() {
      this.funcionario = this.camposFluxo[0].resposta;
      this.nomeUsuario = this.camposFluxo[1].resposta;
      this.vinculo = this.camposFluxo[2].resposta;
      this.solicitante = this.camposFluxo[3].resposta;
      this.tipoDesligamento = this.camposFluxo[4].resposta;
      this.tipoAviso = this.camposFluxo[5].resposta;
      this.causa = this.camposFluxo[6].resposta;
      this.dataDesligamento = this.camposFluxo[7].resposta;
      this.dataInicioAviso = this.camposFluxo[8].resposta;
      this.quantidadeDias = this.camposFluxo[9].resposta;
      this.dataFimAviso = this.camposFluxo[10].resposta;
      this.descontoAviso = this.camposFluxo[11].resposta;
      this.descontoAvisoMulta = this.camposFluxo[12].resposta;
      this.dataAdmissao = this.camposFluxo[13].resposta;
      this.emailGestor = this.camposFluxo[14].resposta;
      this.cc = this.camposFluxo[15].resposta;
      this.unidade = this.camposFluxo[16].resposta;
      this.telefone = this.camposFluxo[17].resposta;
      this.emailPessoal = this.camposFluxo[18].resposta;
      this.absorvido = this.camposFluxo[19].resposta;
      this.alocado = this.camposFluxo[20].resposta;
      this.motivo = this.camposFluxo[21].resposta;
      this.futuraContratacao = this.camposFluxo[22].resposta;
      this.justifique = this.camposFluxo[23].resposta;
    },

    async listaSedes() {
      try {
        const { data: response } = await this.$http.get('/sedes/');
        this.sedes = response.map(item => ({
          value: item.nome,
          text: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },

    toggleEdit() {
      this.editar = !this.editar;
      this.disabled = !this.disabled;
    },

    async salvarFluxoEditado() {
      this.toggleEdit();

      this.snackbarOpen = true;
      this.msg = 'Atendimento editado com sucesso';

      const body = {
        id: this.atendimentoId,
        campos_fluxo: {
          column_campo_1: this.funcionario,
          column_campo_2: this.nomeUsuario,
          column_campo_3: this.vinculo,
          column_campo_4: this.solicitante,
          column_campo_5: this.tipoDesligamento,
          column_campo_6: this.tipoAviso,
          column_campo_7: this.causa,
          column_campo_8: this.dataFormatadaDesligamento,
          column_campo_9: this.dataFormatadaInicioAviso,
          column_campo_10: this.quantidadeDias,
          column_campo_11: this.dataFormatadaFim,
          column_campo_12: this.descontoAviso,
          column_campo_13: this.descontoAvisoMulta,
          column_campo_14: this.dataAdmissao,
          column_campo_15: this.emailGestor,
          column_campo_16: this.cc,
          column_campo_17: this.unidade,
          column_campo_18: this.telefone,
          column_campo_19: this.emailPessoal,
          column_campo_20: this.absorvido,
          column_campo_21: this.alocado,
          column_campo_22: this.motivo,
          column_campo_23: this.futuraContratacao,
          column_campo_24: this.justifique,
        },
        responsavel: this.$store.getters.isUsuario.id,
        fluxo_id: this.fluxoId,
      };

      await this.$http.put('/atendimentos/editar/dados-fluxo', body);
    },
  },
};
</script>

<style lang="scss" scoped>

#formDesligamento {
  display: flex;
  margin-left: 30px;
}
.btn-primary {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border: 1px solid #0056a8 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.btn-primary-disabled {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.v-icon {
  margin-top: 2px;
}

.theme--light.v-input--is-disabled,
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgba(4, 4, 4, 0.75) !important;
}

.checkbox {
  margin-top: 6px;
  padding-left: 20px;
  margin-bottom: -16px;
}
</style>
