<template>
  <div>
    <Snackbar
      :label="msg"
      v-model="snackbarOpen"
      top
      right
      :tipo="tipoSnackbar"
      :timeout="2500"
    />
    <v-row class="justify-content-center">
      <div class="topbar">
        <div class="mr-2">Filtrar por:</div>
        <div class="input-select">
          <v-select
            v-model="filtro"
            :items="opcoes"
            outlined
            dense
            @change="listaFeedback"
          ></v-select>
          <v-select
            v-if="atendenteTipo.id_permissao === permissaoGestorGeral"
            v-model="setor_id"
            label="Setor"
            :items="setores"
            outlined
            dense
            @change="listaFeedback"
          ></v-select>
        </div>
      </div>
      <v-col
        cols="10"
        class="pt-0"
      >
        <div v-if="feedbacks.length < 1">
          <p class="notFound text-center mt-5">Não foram encontrados feedbacks.</p>
        </div>
        <template>
          <v-expansion-panels v-model="panel">
            <v-expansion-panel
              v-for="(feed, index) in visiblePages"
              :key="index"
            >
              <v-card
                outlined
                class="mx-auto mt-5"
              >
                <v-card-text class="pb-0 mb-1">
                  {{ feed.texto }}
                </v-card-text>
                <v-card-subtitle class="subtitulo py-0">
                  Status:
                  <v-chip
                    class="ma-2"
                    :class="{
                      'active': item.value === feed.status_id,
                      'disable-events': feed.status_id === index + 1,
                    }"
                    v-for="(item, index) in opcoes.slice(1,5)"
                    :key="index"
                    @click="storageIdEValue(feed.feed_id, item.value, index, true)"
                    :disabled="(feed.status_id === implementado && index !== valIndex.implementado)
                      || (feed.status_id === naoImplementado && index !== valIndex.naoImplementado)"
                    label
                    small
                  >
                    {{ item.text }}
                  </v-chip>
                  <v-btn
                    v-if="feed.status_id !== 1"
                    class="btn-expansion"
                    :class="{'disable-events disableBtn' : feed.comentarios[2]}"
                    @click="storageIdEValue(feed.feed_id, feed.status_id, index, false)"
                  >
                    Adicionar Resposta
                  </v-btn>
                </v-card-subtitle>
                <v-card-subtitle
                  v-if="feed.comentarios[0]"
                  class="comentario-subtitle"
                >
                  <strong>Respostas:</strong>
                  <template>
                    <div
                      v-for="(item, index) in feed.comentarios"
                      :key="index"
                    >
                      <div v-html="item.comentario">
                      </div>
                      <span class="coment-title">
                        Comentado por <strong>{{item.gestor_nome}}</strong>
                        em <strong>{{item.created_at | formatDate}}</strong>.
                      </span>
                    </div>
                  </template>
                </v-card-subtitle>
              </v-card>
              <v-expansion-panel-header
                v-if="feed.status_id !== 1 && feed.status_id !== 2"
                class="display-none"
              >
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <EditorTexto :table="true" />
                <div class="btn-stilo">
                  <v-btn
                    class="mt-2 btn btn-light"
                    @click="apagaComentario"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    class="mt-2"
                    :class="verificaComentario() ? 'btn btn-primary' : 'disableBtn'"
                    @click="enviarFormulario()"
                  >
                    Responder
                  </v-btn>
                </div>
              </v-expansion-panel-content>
              <v-card-subtitle class="subtitulo pt-2">
                Feedback enviado por: <strong>{{ splitNome(feed.usuario_nome) }}</strong>
                em <strong>{{ feed.data | formatDate }}</strong>
              </v-card-subtitle>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
        <div class="text-center">
          <v-pagination
            v-model="pagination.current"
            :length="Math.ceil(feedbacks.length/pagination.perPage)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Snackbar from './Snackbar.vue';
import splitNome from '../mixins/splitNome.js';
import EditorTexto from './EditorTexto.vue';
import EventBus from '../plugins/EventBus';
import { setores } from '../Constants.js';
import { getMaxPermission } from '../utils/utilities';

export default {
  name: 'gerenciamento-feedbacks',
  components: {
    Snackbar,
    EditorTexto,
  },
  mixins: [splitNome],
  data() {
    return {
      setores: [],
      setor_id: '',
      permissaoGestor: this.$store.getters.listaPermissoes.gestor,
      permissaoGestorGeral: this.$store.getters.listaPermissoes.gestorgeral,
      setorNome: '',
      setorTi: setores.TI,
      atendenteTipo: getMaxPermission(this.$store),
      msg: '',
      snackbarOpen: false,
      tipoSnackbar: 'info',
      todos: 0,
      implementado: 3,
      naoImplementado: 4,
      emAberto: 1,
      emAnalise: 2,
      valIndex: {
        emAberto: 0,
        emAnalise: 1,
        implementado: 2,
        naoImplementado: 3,
      },
      filtro: 0,
      pagination: {
        current: 1,
        perPage: 4,
      },
      feedbacks: [],
      opcoes: [
        { value: 0, text: 'Todos' },
        { value: 1, text: 'Em aberto' },
        { value: 2, text: 'Em análise' },
        { value: 3, text: 'Implementado' },
        { value: 4, text: 'Não implementado' },
      ],
      statusFeed: null,
      valueFeed: null,
      indexFeed: null,
      idCard: null,
      panel: [],
      flagcComentario: false,
    };
  },
  async mounted() {
    try {
      await this.listaFeedback();
      await this.listaSetores();
    } catch (error) {
      console.error(error);
    }
  },
  computed: {
    verificarStatus() {
      return this.feed.status_id === this.emAberto
        || this.feed.status_id === this.emAnalise
        || this.feed.status_id === this.implementado
        || this.feed.status_id === this.naoImplementado;
    },
    visiblePages() {
      return this.feedbacks.slice((this.pagination.current - 1)
        * this.pagination.perPage, this.pagination.current * this.pagination.perPage);
    },
    cComentario() {
      const texto = this.$store.getters.comentario;
      return texto;
    },
  },
  methods: {
    async listaFeedback() {
      this.$store.commit('UPDATE_LOADING', true);
      try {
        const { data: response } = this.filtro === this.todos
          ? await this.$http.get('/lista-feedback') : await this.$http.get(`/filtro-feedback/${this.filtro}`);
        this.feedbacks = response.map(item => ({
          feed_id: item.id,
          usuario_id: item.usuario[0].id,
          usuario_nome: item.usuario[0].nome,
          status_id: item.status.id,
          status_nome: item.status.nome,
          texto: item.texto,
          comentarios: item.comentarios,
          data: item.created_at,
          setor_id: item.setor_id,
        }));
        if (this.atendenteTipo.id_permissao === this.permissaoGestor) {
          this.feedbacks = this.feedbacks.filter(item => item.setor_id
            === this.atendenteTipo.id_setor);
        } else if (this.atendenteTipo.id_permissao === this.permissaoGestorGeral && this.setor_id) {
          this.feedbacks = this.feedbacks.filter(item => item.setor_id
            === this.setor_id);
        }
        this.$store.commit('UPDATE_LOADING', false);
      } catch (error) {
        this.$store.commit('UPDATE_LOADING', false);
        console.error(error);
      }
    },
    async listaSetores() {
      try {
        const { data: response } = await this.$http.get('/setores');
        this.setores = response.map(item => ({
          value: item.id,
          text: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    verificaComentario() {
      const texto = this.cComentario;
      const paragrafoVazio = '<p></p>';
      const tabelaVazia = '<table><tbody><tr><td><p></p></td><td><p></p></td><td><p></p></td></tr><tr><td><p></p></td><td><p></p></td><td><p></p></td></tr><tr><td><p></p></td><td><p></p></td><td><p></p></td></tr></tbody></table>';
      return (texto && texto !== paragrafoVazio) && (texto !== tabelaVazia);
    },
    storageIdEValue(value, status, index, enviar) {
      this.statusFeed = status;
      this.valueFeed = value;
      this.indexFeed = index;
      if (enviar) {
        this.updateFeedback(this.valueFeed, this.statusFeed);
      } else {
        this.panel = index;
      }
    },
    async updateFeedback(value, status) {
      try {
        if (!this.flagcComentario) {
          this.apagaComentario();
        }
        const body = {
          status_id: status,
          comentario: this.cComentario,
          gestor_id: this.$store.getters.isUsuario.id,
          gestor_nome: this.$store.getters.isUsuario.nome,
        };
        const { data: response } = await this.$http.put(`/novo-feedback/${value}`, body);
        this.snackbarOpen = true;
        if (response.status !== 200) {
          this.tipoSnackbar = 'success';
          this.msg = 'Feedback atualizado com sucesso.';
        } else {
          this.tipoSnackbar = 'error';
          this.msg = response.message;
        }
        this.filtro = this.todos;
        this.listaFeedback();
      } catch (error) {
        this.snackbarOpen = true;
        this.tipoSnackbar = 'error';
        this.msg = 'Ops, ocorreu um erro!';
        console.error(error);
      }
    },
    apagaComentario() {
      this.panel = [];
      EventBus.$emit('limparComentarioBus', true);
    },
    enviarFormulario() {
      if (this.verificaComentario()) {
        this.flagcComentario = true;
        this.updateFeedback(this.valueFeed, this.statusFeed);
        this.panel = [];
        this.valueFeed = null;
        this.indexFeed = null;
        this.flagcComentario = false;
        this.apagaComentario();
      } else {
        this.snackbarOpen = true;
        this.tipoSnackbar = 'error';
        this.msg = 'Digite algo para enviar!';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-chip:not(.v-chip--active) {
  border: 1px solid rgba(86, 197, 201, 0.5);
  background-color: #fbfbfb;
  transition: all 0.5s ease;
  &:hover {
    color: #fbfbfb;
    background-color: #0056a8 !important;
    border-color: #0056a8;
  }
  &.active {
    color: #fbfbfb;
    background-color: #0056a8 !important;
    border-color: #0056a8;
  }
  &.active2 {
    color: #fbfbfb;
    background-color: #0056a8 !important;
    border-color: #0056a8;
  }
}

.notFound,
.theme--light.v-card > .v-card__text {
  font-size: 14px;
  color: #303030;
}

.subtitulo {
  font-size: 13px;
  color: rgba(48, 48, 48, 0.7);
}

.topbar {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btn-stilo {
  display: flex;
  justify-content: flex-end;
}
.display-none {
  display: none;
}
.btn-expansion {
  float: right;
  letter-spacing: 0;
  text-transform: inherit !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
}
.btn {
  text-transform: inherit !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
}
.btn-primary {
  background: #0056a8 !important;
  color: #fff !important;
  &:hover {
    color: #bfbfbf !important;
  }
}
.disableBtn {
  pointer-events: none;
  letter-spacing: 0;
  text-transform: inherit !important;
  background: #fff;
  color: black;
  border-radius: 5px;
  font-size: 16px !important;
  border: 1px solid #bfbfbf;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  opacity: 0.4;
  &:hover {
    color: #bfbfbf;
    opacity: 0.4;
  }
}
.coment-title {
  font-size: 12px;
}
.comentario-subtitle {
  padding-top: 0;
  padding-bottom: 0;
}
.disable-events {
  pointer-events: none;
}
.theme--light.v-card.v-card--outlined {
  border: 0;
}
.v-card__text.pb-0.mb-1 {
  padding-top: 0;
}
</style>

<style lang="scss">
div.v-card__subtitle.comentario-subtitle > div {
  strong {
    font-weight: bold;
  }
  p {
    margin-top: 1.2rem;
    margin-bottom: 0;
    font-size: 1rem;
  }
}

.v-list-item__title {
  text-align: justify !important;
  padding-left: 12px !important;
  font-size: 14px !important;
}
.v-application--is-ltr .v-expansion-panel-header {
  padding: 0;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.v-expansion-panel {
  margin: 5px;
}
</style>
