<template>
  <div id="solicitarMotoboy">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="10000"
      :tipo="alerta"
    />
    <v-form>
      <v-row>
        <v-col cols="6">
          <h3 class="titleHeader mb-3">Fluxo de Solicitar Motoboy</h3>
          <v-col>
            <h5>Atenção</h5>
            <p class="caption">Os campos com
              <span class="count-warning">*</span>
              são obrigatórios.</p>
          </v-col>
          <v-radio-group
            v-model="tipoSolicitacao"
            row
            label="Tipo de Solicitação: "
          >
            <v-radio
              label="Somente Entrega"
              value="Somente Entrega"
            ></v-radio>
            <v-radio
              label="Entrega com Retorno"
              value="Entrega com Retorno"
            ></v-radio>
            <v-radio
              label="Retirada"
              value="Retirada"
            ></v-radio>
            <div class="caption count-warning">*</div>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
          <v-select
            v-model="unidade"
            :items="sedes"
            label="Escritório"
            outlined
            dense
          ></v-select>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>
      <v-row>
        <v-col cols="5">
        <v-text-field
          v-model="destinatario"
          label="Usuário destinatário (nome.sobrenome)"
          outlined
          dense
          :disabled="hideField"
        >
        ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>

        <div>
          <v-btn
            v-if="!hideField"
            :disabled="!this.destinatario.length"
            class="search primary"
            @click="getEndereco"
            >
            Buscar Endereço
          </v-btn>
          <v-btn
            v-else
            class="search primary"
            @click="clearFilds"
            >
            Limpar Campos
          </v-btn>
        </div>

      </v-row>
      <v-row v-if="!hideField">
        <v-col cols="5">
          <v-text-field
            v-model="endereco"
            label="Endereço Completo"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>

        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>
      <v-row v-if="!hideField">
        <v-col cols="5">
          <v-text-field
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="telefone"
            label="Telefone"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>

      <v-col v-if="hideField">
        <v-icon>mdi-check</v-icon>
        <p class="caption"><strong>Salvo!</strong>
          O endereço e telefone do usuário informado {{destinatario}} foi salvo.</p>
      </v-col>

      <v-row>
        <v-col cols="2">
          <v-text-field
            v-model="numeroCC"
            label="Número CC"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
          <v-col cols="3">
          <v-text-field
            v-model="descricaoCC"
            label="Descrição CC"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>
      <v-row>
        <v-col cols="9">
          <v-textarea
            v-model="descricao"
            label="Descrição"
            outlined
            dense
            clearable
            auto-grow
          ></v-textarea>
          <p class="caption text-right">
            <strong>OBS:</strong>
            Dê a maior quantidade de informações para o Motoboy entregar sua solicitação
             ( Mínimo 20 caracteres /
            <span :class="descricao.length < 20 ? 'count-warning' : 'count-success'">
              {{ countLengthDescricao }}
            </span> )
          </p>
        </v-col>
        <div class="caption count-warning">*</div>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import moment from 'moment';
import { mask } from 'vue-the-mask';
import Snackbar from '../Snackbar';

export default {
  name: 'SolicitarMotoboy',
  directives: { mask },
  components: { Snackbar },

  data() {
    return {
      tipoSolicitacao: '',
      unidade: '',
      endereco: '',
      telefone: '',
      destinatario: '',
      numeroCC: '',
      descricaoCC: '',
      descricao: '',
      check: false,
      hideField: false,
      sedes: [],
      dadosFluxo: {},
      newDadosFluxo: {},
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },
  created() {
    moment.locale('pt-br');
    try {
      this.listaSedes();
    } catch (error) {
      console.error(error);
    }
  },
  computed: {
    countLengthDescricao() {
      return this.descricao === '' ? '0' : this.descricao.length;
    },
    verificaCampos() {
      return (!!this.tipoSolicitacao
      && !!this.unidade
      && !!this.endereco
      && !!this.telefone
      && !!this.destinatario
      && !!this.numeroCC
      && !!this.descricaoCC
      && !!this.descricao);
    },
  },
  methods: {
    // disabled() {
    //   if (!this.destinatario.length) return true;

    //   if (this.check) {
    //     this.check = false;
    //     return true;
    //   }
    //   return false;
    // },
    async getEndereco() {
      try {
        const headers = { headers: { user: this.destinatario } };
        const { data: response } = await this.$http.get('/get-endereco', headers);
        this.hideField = true;
        this.endereco = response.adress;
        this.telefone = response.phone;
        this.alerta = 'success';
        this.msg = 'Dados do usuário informado salvos.';
        this.snackBarAlertaCampos = true;
      } catch (error) {
        this.hideField = false;
        this.endereco = '';
        this.telefone = '';
        this.alerta = 'error';
        this.msg = 'Não foram encontrados os dados do usuário informado! Favor preencher o endereço completo para onde deseja o serviço e comunicar ao Setor Administrativo para que solicite o cadastro do endereço deste profissional no banco de dados.';
        this.snackBarAlertaCampos = true;
        console.error(error);
      }
    },

    async listaSedes() {
      try {
        const { data: response } = await this.$http.get('/sedes/');
        this.sedes = response.map(item => ({
          value: item.nome,
          text: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },

    trataResposta(dadosFluxo) {
      const respostasModel = {};

      if (dadosFluxo.tipoSolicitacao) respostasModel.column_campo_1 = dadosFluxo.tipoSolicitacao;
      if (dadosFluxo.unidade) respostasModel.column_campo_2 = dadosFluxo.unidade;
      if (dadosFluxo.endereco) respostasModel.column_campo_3 = dadosFluxo.endereco;
      if (dadosFluxo.telefone) respostasModel.column_campo_4 = dadosFluxo.telefone;
      if (dadosFluxo.destinatario) respostasModel.column_campo_5 = dadosFluxo.destinatario;
      if (dadosFluxo.numeroCC) respostasModel.column_campo_6 = dadosFluxo.numeroCC;
      if (dadosFluxo.descricaoCC) respostasModel.column_campo_7 = dadosFluxo.descricaoCC;
      if (dadosFluxo.descricao) respostasModel.column_campo_8 = dadosFluxo.descricao;

      respostasModel.validation = true;
      this.newDadosFluxo = respostasModel;
    },

    clearFilds() {
      this.hideField = false;
      this.destinatario = '';
      this.endereco = '';
      this.telefone = '';
      this.alerta = 'success';
      this.msg = 'Removido dados do usuário informado';
      this.snackBarAlertaCampos = true;
    },

    enviaJSON() {
      if (!this.verificaCampos) {
        this.dadosFluxo = { validation: false };
        this.alerta = 'error';
        this.msg = 'Todos os campos são obrigatórios.';
        this.snackBarAlertaCampos = true;
      } else if (this.verificaCampos) {
        if (this.descricao.length >= 20) {
          this.dadosFluxo = {
            validation: true,
            tipoSolicitacao: this.tipoSolicitacao,
            unidade: this.unidade,
            endereco: this.endereco,
            telefone: this.telefone,
            destinatario: this.destinatario,
            numeroCC: this.numeroCC,
            descricaoCC: this.descricaoCC,
            descricao: this.descricao,
          };
          this.trataResposta(this.dadosFluxo);
          this.$emit('dadosFluxo', this.newDadosFluxo);
        } else {
          this.dadosFluxo = { validation: false };
          this.alerta = 'error';
          this.msg = 'Descrição precisa ter mais de 20 caracteres.';
          this.snackBarAlertaCampos = true;
          this.$emit('dadosFluxo', this.dadosFluxo);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

.search {
  margin: 15px 15px 20px 0;
  padding-left: 20px;
}

.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.count-warning {
  background-color: transparent;
  color: red;
}

.count-success {
  background-color: transparent;
  color: green;
}
</style>
