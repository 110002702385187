import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
// eslint-disable-next-line import/no-cycle
import WsSubscriptions from '../WsSubscriptions';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    login: false,
    usuario: {
      id: '',
      nome: '',
      usuario: '',
      sede_id: '',
      sede_nome: '',
      permissao: '',
      avatar_id: '',
    },
    inscricao: null,
    loader: false,
    menuLateral: false,
    avatar: '',
    comentario: '',
    anexo: false,
    listaPermissoes: '',
    callRefreshToken: false,
  },
  getters: {
    logado(state) {
      return state.login;
    },
    isUsuario(state) {
      return state.usuario;
    },
    inscricao(state) {
      return state.inscricao;
    },
    isLoader(state) {
      return state.loader;
    },
    menuLateral(state) {
      return state.menuLateral;
    },
    usuarioAvatar(state) {
      return state.avatar;
    },
    comentario(state) {
      return state.comentario;
    },
    anexo(state) {
      return state.anexo;
    },
    callRefreshToken(state) {
      return state.callRefreshToken;
    },
    listaPermissoes(state) {
      return state.listaPermissoes;
    },
  },

  mutations: {
    UPDATE_LOGIN(state, payload) {
      state.login = payload;
    },
    UPDATE_USUARIO(state, payload) {
      state.usuario = payload;
    },
    UPDATE_SEDE(state, payload) {
      state.usuario.sede_id = payload;
    },
    UPDATE_USUARIO_AVATAR(state, payload) {
      state.avatar = payload;
    },
    UPDATE_INSCRICAO(state, payload) {
      state.inscricao = payload;
    },
    UPDATE_LOADING(state, payload) {
      state.loader = payload;
    },
    UPDATE_MENULATERAL(state, payload) {
      state.menuLateral = payload;
    },
    UPDATE_COMENTARIO(state, payload) {
      state.comentario = payload;
    },
    UPDATE_ANEXO(state, payload) {
      state.anexo = payload;
    },
    UPDATE_REFRESH_TOKEN(state, payload) {
      state.callRefreshToken = payload;
    },
    UPDATE_LISTA_PERMISSOES(state, payload) {
      state.listaPermissoes = payload;
    },
  },
  actions: {
    async inscricaoNotificacao({ commit }, usuario) {
      try {
        await WsSubscriptions();
        commit('UPDATE_INSCRICAO', usuario);
      } catch (error) {
        console.error(error);
      }
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});
