var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.IdTipoCampo == _vm.tipoText && !_vm.IdMascara && !_vm.minCaracteres)?_c('div',[_c('v-col',{staticClass:"col-ajust-padding",attrs:{"cols":"5"}},[(_vm.IdTipoCampo == _vm.tipoText && !_vm.IdMascara && !_vm.minCaracteres && _vm.required)?_c('div',{staticClass:"ajust-padding caption count-warning alert"},[_vm._v("*")]):_vm._e(),_c('v-text-field',{attrs:{"label":_vm.label,"outlined":"","dense":"","clearable":"","auto-grow":""},on:{"input":function($event){return _vm.$emit('emit-value', {
              resposta: $event,
              column_campo: _vm.columnCampo
            })}},model:{value:(_vm.resposta),callback:function ($$v) {_vm.resposta=$$v},expression:"resposta"}})],1)],1):_vm._e(),(_vm.IdTipoCampo == _vm.tipoText && _vm.IdMascara
      && _vm.IdMascara !== _vm.mascaraDinheiroRealID
      && _vm.IdMascara !== _vm.mascaraDinheiroDolarID
      && _vm.IdMascara !== _vm.mascaraDinheiroEuroID)?_c('div',[_c('v-col',{staticClass:"col-ajust-padding",attrs:{"cols":"5"}},[(_vm.IdTipoCampo == _vm.tipoText && _vm.IdMascara
        && _vm.IdMascara !== _vm.mascaraDinheiroRealID
        && _vm.IdMascara !== _vm.mascaraDinheiroDolarID
        && _vm.IdMascara !== _vm.mascaraDinheiroEuroID
        && _vm.required)?_c('div',{staticClass:"ajust-padding caption count-warning alert"},[_vm._v("*")]):_vm._e(),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.populaMascara),expression:"populaMascara"}],attrs:{"label":_vm.label,"outlined":"","dense":"","clearable":"","auto-grow":""},on:{"input":function($event){return _vm.$emit('emit-value', {
              resposta: $event,
              column_campo: _vm.columnCampo
            })}},model:{value:(_vm.resposta),callback:function ($$v) {_vm.resposta=$$v},expression:"resposta"}})],1)],1):_vm._e(),(_vm.IdTipoCampo == _vm.tipoText && _vm.IdMascara == _vm.mascaraDinheiroRealID)?_c('div',[_c('v-col',{staticClass:"col-ajust-padding",attrs:{"cols":"5"}},[(_vm.IdTipoCampo == _vm.tipoText && _vm.IdMascara == _vm.mascaraDinheiroRealID && _vm.required)?_c('div',{staticClass:"ajust-padding caption count-warning alert"},[_vm._v("*")]):_vm._e(),_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.real),expression:"real"}],attrs:{"label":_vm.label,"outlined":"","dense":"","clearable":"","auto-grow":""},on:{"input":function($event){return _vm.$emit('emit-value', {
              resposta: $event,
              column_campo: _vm.columnCampo
            })}},model:{value:(_vm.resposta),callback:function ($$v) {_vm.resposta=$$v},expression:"resposta"}})],1)],1):_vm._e(),(_vm.IdTipoCampo == _vm.tipoText && _vm.IdMascara == _vm.mascaraDinheiroDolarID)?_c('div',[_c('v-col',{staticClass:"col-ajust-padding",attrs:{"cols":"5"}},[(_vm.IdTipoCampo == _vm.tipoText && _vm.IdMascara == _vm.mascaraDinheiroDolarID && _vm.required)?_c('div',{staticClass:"ajust-padding caption count-warning alert"},[_vm._v("*")]):_vm._e(),_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.dolar),expression:"dolar"}],attrs:{"label":_vm.label,"outlined":"","dense":"","clearable":"","auto-grow":""},on:{"input":function($event){return _vm.$emit('emit-value', {
              resposta: $event,
              column_campo: _vm.columnCampo
            })}},model:{value:(_vm.resposta),callback:function ($$v) {_vm.resposta=$$v},expression:"resposta"}})],1)],1):_vm._e(),(_vm.IdTipoCampo == _vm.tipoText && _vm.IdMascara == _vm.mascaraDinheiroEuroID)?_c('div',[_c('v-col',{staticClass:"col-ajust-padding",attrs:{"cols":"5"}},[(_vm.IdTipoCampo == _vm.tipoText && _vm.IdMascara == _vm.mascaraDinheiroEuroID && _vm.required)?_c('div',{staticClass:"ajust-padding caption count-warning alert"},[_vm._v("*")]):_vm._e(),_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.euro),expression:"euro"}],attrs:{"label":_vm.label,"outlined":"","dense":"","clearable":"","auto-grow":""},on:{"input":function($event){return _vm.$emit('emit-value', {
              resposta: $event,
              column_campo: _vm.columnCampo
            })}},model:{value:(_vm.resposta),callback:function ($$v) {_vm.resposta=$$v},expression:"resposta"}})],1)],1):_vm._e(),(_vm.IdTipoCampo == _vm.tipoText && _vm.minCaracteres)?_c('div',[_c('v-col',{staticClass:"col-ajust-padding",attrs:{"cols":"5"}},[(_vm.IdTipoCampo == _vm.tipoText && _vm.minCaracteres && _vm.required)?_c('div',{staticClass:"ajust-padding caption count-warning alert"},[_vm._v("*")]):_vm._e(),_c('v-text-field',{attrs:{"label":_vm.label,"outlined":"","dense":"","clearable":"","auto-grow":""},on:{"input":function($event){return _vm.$emit('emit-value', {
              resposta: $event,
              column_campo: _vm.columnCampo
            })}},model:{value:(_vm.resposta),callback:function ($$v) {_vm.resposta=$$v},expression:"resposta"}}),(_vm.IdTipoCampo == _vm.tipoText && _vm.minCaracteres)?_c('p',{staticClass:"caption text-right"},[_vm._v(" Mínimo "+_vm._s(_vm.minCaracteres)+" caracteres / "),_c('span',{class:_vm.resposta.length < _vm.minCaracteres ? 'count-warning' : 'count-success'},[_vm._v(" "+_vm._s(_vm.countLengt)+" ")])]):_vm._e()],1)],1):_vm._e(),(_vm.IdTipoCampo == _vm.tipoNumber)?_c('div',[_c('v-col',{staticClass:"col-ajust-padding",attrs:{"cols":"5"}},[(_vm.IdTipoCampo == _vm.tipoNumber && _vm.required)?_c('div',{staticClass:"ajust-padding caption count-warning alert"},[_vm._v("*")]):_vm._e(),_c('v-text-field',{attrs:{"label":_vm.label,"type":"number","outlined":"","dense":"","clearable":"","auto-grow":""},on:{"input":function($event){return _vm.$emit('emit-value', {
              resposta: $event,
              column_campo: _vm.columnCampo
            })}},model:{value:(_vm.resposta),callback:function ($$v) {_vm.resposta=$$v},expression:"resposta"}})],1)],1):_vm._e(),(_vm.IdTipoCampo == _vm.tipoCheckBox)?_c('div',[_c('v-col',{staticClass:"col-ajust-padding",attrs:{"cols":"5"}},[_c('v-checkbox',{attrs:{"label":_vm.label},on:{"change":function($event){return _vm.$emit('emit-value', {
              resposta: $event,
              column_campo: _vm.columnCampo
            })}},model:{value:(_vm.resposta),callback:function ($$v) {_vm.resposta=$$v},expression:"resposta"}})],1)],1):_vm._e(),(_vm.IdTipoCampo == _vm.tipoRadioButton)?_c('div',[_c('v-col',{staticClass:"col-ajust-padding",attrs:{"cols":"5"}},[_c('v-radio-group',{attrs:{"row":"","label":_vm.label},on:{"change":function($event){return _vm.$emit('emit-value', {
              resposta: $event,
              column_campo: _vm.columnCampo
            })}},model:{value:(_vm.resposta),callback:function ($$v) {_vm.resposta=$$v},expression:"resposta"}},[_vm._l((_vm.opcoes),function(opcao,index){return _c('v-radio',{key:index,attrs:{"label":opcao.text,"value":opcao.text}})}),_c('div',{staticClass:"caption count-warning"},[_vm._v("*")])],2)],1)],1):_vm._e(),(_vm.IdTipoCampo == _vm.tipoLongText && !_vm.minCaracteres)?_c('div',[_c('v-col',{staticClass:"col-ajust-padding",attrs:{"cols":"5"}},[(_vm.IdTipoCampo == _vm.tipoLongText && !_vm.minCaracteres && _vm.required)?_c('div',{staticClass:"ajust-padding caption count-warning alert"},[_vm._v("*")]):_vm._e(),_c('v-textarea',{attrs:{"label":_vm.label,"outlined":"","dense":"","clearable":"","auto-grow":""},on:{"input":function($event){return _vm.$emit('emit-value', {
              resposta: $event,
              column_campo: _vm.columnCampo
            })}},model:{value:(_vm.resposta),callback:function ($$v) {_vm.resposta=$$v},expression:"resposta"}})],1)],1):_vm._e(),(_vm.IdTipoCampo == _vm.tipoLongText && _vm.minCaracteres)?_c('div',[_c('v-col',{staticClass:"col-ajust-padding",attrs:{"cols":"5"}},[(_vm.IdTipoCampo == _vm.tipoLongText && _vm.minCaracteres && _vm.required)?_c('div',{staticClass:"ajust-padding caption count-warning alert"},[_vm._v("*")]):_vm._e(),_c('v-textarea',{attrs:{"label":_vm.label,"outlined":"","dense":"","clearable":"","auto-grow":""},on:{"input":function($event){return _vm.$emit('emit-value', {
              resposta: $event,
              column_campo: _vm.columnCampo
            })}},model:{value:(_vm.resposta),callback:function ($$v) {_vm.resposta=$$v},expression:"resposta"}}),(_vm.IdTipoCampo == _vm.tipoLongText && _vm.minCaracteres)?_c('p',{staticClass:"caption text-right"},[_vm._v(" Mínimo "+_vm._s(_vm.minCaracteres)+" caracteres / "),_c('span',{class:_vm.resposta.length < _vm.minCaracteres ? 'count-warning' : 'count-success'},[_vm._v(" "+_vm._s(_vm.countLengt)+" ")])]):_vm._e()],1)],1):_vm._e(),(_vm.IdTipoCampo == _vm.tipoInputSelect)?_c('div',[_c('v-col',{staticClass:"col-ajust-padding",attrs:{"cols":"5"}},[(_vm.IdTipoCampo == _vm.tipoInputSelect && _vm.required)?_c('div',{staticClass:"ajust-padding caption count-warning alert"},[_vm._v("*")]):_vm._e(),_c('v-select',{attrs:{"label":_vm.label,"items":_vm.opcoes,"outlined":"","dense":"","clearable":"","auto-grow":""},on:{"change":function($event){return _vm.$emit('emit-value', {
              resposta: $event,
              column_campo: _vm.columnCampo
          })}},model:{value:(_vm.resposta),callback:function ($$v) {_vm.resposta=$$v},expression:"resposta"}})],1)],1):_vm._e(),(_vm.IdTipoCampo == _vm.tipoData)?_c('div',[_c('v-col',{staticClass:"col-ajust-padding",attrs:{"cols":"5"}},[(_vm.IdTipoCampo == _vm.tipoData && _vm.required)?_c('div',{staticClass:"ajust-padding caption count-warning alert"},[_vm._v("*")]):_vm._e(),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","readonly":"","label":_vm.label,"append-icon":"event"},model:{value:(_vm.dataFormatted),callback:function ($$v) {_vm.dataFormatted=$$v},expression:"dataFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,3154359374),model:{value:(_vm.menuData),callback:function ($$v) {_vm.menuData=$$v},expression:"menuData"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"pt-br"},on:{"change":function($event){return _vm.$emit('emit-value',
            { resposta: _vm.dataFormatted,
              column_campo: _vm.columnCampo
            })},"input":function($event){_vm.menuData = false}},model:{value:(_vm.resposta),callback:function ($$v) {_vm.resposta=$$v},expression:"resposta"}})],1)],1)],1):_vm._e(),(_vm.IdTipoCampo == _vm.tipoHora)?_c('div',[_c('v-col',{staticClass:"col-ajust-padding",attrs:{"cols":"1"}},[(_vm.IdTipoCampo == _vm.tipoHora && _vm.required)?_c('div',{staticClass:"ajust-padding caption count-warning alert"},[_vm._v("*")]):_vm._e(),_c('v-text-field',{attrs:{"label":_vm.label,"type":"time","outlined":"","dense":"","clearable":"","auto-grow":""},on:{"change":function($event){return _vm.$emit('emit-value',
          { resposta: $event,
            column_campo: _vm.columnCampo
          })}},model:{value:(_vm.resposta),callback:function ($$v) {_vm.resposta=$$v},expression:"resposta"}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }