const nomeIniciais = {
  methods: {
    nomeIniciais(nome) {
      if (!nome) {
        return '';
      }
      const arrayNome = nome.split(' ');
      if (arrayNome.length === 1) return this.letrasIniciais(nome);
      const iniciais = `${arrayNome[0].substring(0, 1)}${arrayNome[arrayNome.length - 1].substring(0, 1)}`;
      return iniciais;
    },
    letrasIniciais(nome) {
      if (!nome) return '';
      const arrayNome = nome.split('');
      const iniciais = `${arrayNome[0]}${arrayNome[1]}`;
      return iniciais.toUpperCase();
    },
  },
};

export default nomeIniciais;
