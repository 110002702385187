<template>
  <div>
    <vue2Dropzone
      ref="dropzone"
      :options="dropOptions"
      v-on:vdropzone-thumbnail="thumbnail"
      @vdropzone-error="fileError"
      @vdropzone-files-added="updateHasFiles"
      @vdropzone-removed-file="updateHasFiles"
      id="customdropzone"
      :class="{'customdropzone': !hasFiles}"
    />
    <Snackbar
      :label="errorMessage"
      v-model="showSnackbar"
      top
      right
      tipo="error"
    />
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import Snackbar from './Snackbar.vue';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

const getTemplate = () => `
              <div class="dz-preview dz-file-preview">
                <div class="dz-image">
                    <div data-dz-thumbnail-bg></div>
                    <div>
                      <a data-dz-remove class="dz-remove"></a>
                    </div>
                </div>
                <div class="dz-details">
                    <div class="dz-size"><span data-dz-size></span></div>
                    <div class="dz-filename"><span data-dz-name></span></div>
                </div>
                <div class="dz-success-mark"><i class="fa fa-check"></i></div>
              </div>`;

export default {
  name: 'InputFile',
  components: {
    vue2Dropzone,
    Snackbar,
  },
  data: () => ({
    dropOptions: {
      url: '#',
      autoProcessQueue: false,
      maxFilesize: 5, // MB
      maxFiles: 4,
      chunking: true,
      thumbnailWidth: 85, // px
      thumbnailHeight: 85,
      dictFileTooBig: '',
      dictMaxFilesExceeded: '',
      dictInvalidFileType: 'Por favor, anexe um arquivo .pdf, .png, .jpeg, .jpg, .xls, .xlsx, .doc, .docx, .gif, .rar ou .zip.',
      acceptedFiles: `image/jpeg,
      image/jpg,
      image/png,
      image/gif,
      application/pdf,
      application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
      application/vnd.ms-excel,
      application/msword,
      application/vnd.openxmlformats-officedocument.wordprocessingml.document,
      application/vnd.rar,
      application/zip, application/x-zip-compressed, multipart/x-zip`,
      previewTemplate: getTemplate(),
      addRemoveLinks: true,
    },
    showSnackbar: false,
    errorMessage: '',
    hasFiles: false,
  }),
  created() {
    this.dropOptions.dictMaxFilesExceeded = `Numero máximo de
      ${this.dropOptions.maxFiles} arquivos atingidos`;
    this.dropOptions.dictFileTooBig = `Tamanho do arquivo excede o limite
      de ${this.dropOptions.maxFilesize}MB`;
  },
  watch: {
    showSnackbar(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.errorMessage = '';
          this.showSnackbar = false;
        }, 6000);
      }
    },
    errorMessage(newVal) {
      if (newVal) {
        this.showSnackbar = true;
      }
      this.$emit('hasError', newVal);
    },
  },

  methods: {
    getFiles() {
      return this.$refs.dropzone.getAcceptedFiles();
    },
    removeFiles() {
      this.$refs.dropzone.removeAllFiles();
      this.hasFiles = false;
    },
    async updateHasFiles() {
      const files = await this.$refs.dropzone.getAcceptedFiles();
      const queue = await this.$refs.dropzone.getQueuedFiles();
      this.hasFiles = files.length > 0 || queue.length > 0;
      this.$store.commit('UPDATE_ANEXO', this.hasFiles);
    },
    fileError(file, errors) {
      this.errorMessage = errors;
      if (errors === this.dropOptions.dictFileTooBig) {
        this.errorMessage = this.dropOptions.dictFileTooBig;
      } else if (!file.type.match(`image/jpeg|
    image/jpg|
    image/png|
    image/gif|
    application/pdf|
    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|
    application/vnd.ms-excel|
    application/msword|
    application/vnd.openxmlformats-officedocument.wordprocessingml.document|
    application/vnd.rar|
    application/zip| application/x-zip-compressed| multipart/x-zip`)) {
        this.errorMessage = this.dropOptions.dictInvalidFileType;
      }
      this.$refs.dropzone.removeFile(file);
    },
    thumbnail(file, dataUrl) {
      if (file.previewElement) {
        this.updateHasFiles();
        file.previewElement.classList.remove('dz-file-preview');
        const ref = file.previewElement.querySelectorAll('[data-dz-thumbnail-bg]');
        for (let j = 0; j < ref.length; j += 1) {
          const thumbnailElement = ref[j];
          thumbnailElement.alt = file.name;
          thumbnailElement.style.backgroundImage = `url("${dataUrl}")`;
        }
        return file.previewElement.classList.add('dz-image-preview');
      }
      return null;
    },
  },

};
</script>

<style scoped lang="scss">
.dropzone {
  box-sizing: border-box;
  height: 115px;
  border: 1px solid #bfbfbf;
  border-radius: 0 0 9px 9px;
  box-shadow: 0 0 4.5px 0 rgba(0, 0, 0, 0.25);
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  background-image: none;
  &:hover {
    border: solid 1px #0056a8;
    box-shadow: 0 0 4.5px 0 rgba(0, 0, 0, 0.25);
  }
}

.customdropzone {
  background: round;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/img/inputfile.svg");
}
</style>

<style lang="scss">
#customdropzone .dz-message {
  text-align: center;
  color: transparent;
  display: none;
}
#customdropzone .dz-preview {
  width: 80px;
  min-height: 80px;
  display: inline-block;
}
#customdropzone .dz-image {
  border: 1px solid #bfbfbf;
}
#customdropzone .dz-preview .dz-image {
  width: 80px;
  height: 80px;
}

#customdropzone .dz-preview .dz-image > div {
  width: inherit;
  height: inherit;
}

#customdropzone .dz-preview .dz-image > img {
  width: 100%;
}

#customdropzone .dz-preview .dz-details {
  color: white;
  transition: opacity 0.2s linear;
  text-align: center;
}

#customdropzone .dz-preview .dz-details span {
  font-size: 10pt;
  padding: 0;
}
#customdropzone .dz-remove {
  margin: 0px;
  background-image: url("../assets/icons/x.svg");
  background-repeat: no-repeat;
  top: 6px;
  font-size: 0px;
  bottom: inherit;
  left: 28px;
  text-rendering: unset;
  border: none;
  width: 24px;
}
</style>
