<template>
  <layout ref="layout">
    <Snackbar
      :label="errorMessage"
      v-model="snackbarOpen"
      top
      right
      :tipo="'info'"
      :timeout="2500"
    />
    <v-row justify="center">
      <v-dialog
        v-model="modalConf"
        persistent
        max-width="500"
      >
        <v-card>
          <v-card-title class="headline mb-2">{{ modalConfTitulo }}</v-card-title>
          <v-card-text>{{ modalConfTexto }}</v-card-text>
          <v-card-actions class="pa-3">
            <v-spacer></v-spacer>
            <Botao
              class="mx-1"
              type="button"
              label="Cancelar"
              tipo="secondary"
              :callback="cancelaAtualizacaoStatus"
            />
            <Botao
              class="mx-1"
              type="button"
              label="Encerrar atendimento"
              :callback="atualizaStatusEncerrado"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <ModalAvaliacao
      v-if="(!statusAvaliacao)"
      :idAtendimento="id_atendimento"
      :idUsuario="atendimento.id_usuario"
      :modalMin="avaliacaoModalMin"
    />
    <div class="row">
      <div class="col-8">
        <div class="row">
          <div class="col-12 mb-5">
            <Breadcrumb :breadcrumbs="breadcrumbs" />
            <hr />
            <AtendimentoTimeline
              :key="updateStatus"
              :camposFluxo="camposFluxo"
              :atendenteTipo="atendenteTipo.id_permissao"
              :atendimento_id="id_atendimento"
              :atendimento="atendimento"
              :statusHistorico="statusHistorico"
              :encerraAtendimento="putEncerraAtendimento"
              :email="emailSolicitacao"
            />
            <FormTimeline
              v-if="isNaoEncerrado"
              :atendimento="atendimento"
              :idAtendimento="id_atendimento"
              @refreshTimeline="getHistoricoStatus"
              @updateStatus="atualizaStatus"
              @getNotificaNovoAtendente="notificaNovoAtendente"
            />
          </div>
        </div>
      </div>
      <div class="col-3 pl-4 pt-4 mt-2 linha">
        <h6 class="mb-1 subtitle-2">Nome do usuário</h6>
        <p>{{atendimento.nome}}</p>
        <hr />
        <h6 class="mb-1 subtitle-2">Número do atendimento</h6>
        <p>{{atendimento.id_atendimento}}</p>
        <hr />


        <!-- prioridade -->
        <div v-if="id_prioridade" class="flex-menu" >
          <h6 class="d-inline-flex mb-1 subtitle-2">Prioridade</h6>
          <img
            v-if="(isPermissao && isNaoEncerrado) || (isGestor && !isNaoEncerrado)"
            class="categoria"
            @click="modalPrioridades = true"
            src="../assets/icons/edit.svg"
            alt="edit"
          >
          <TagLabel
            class="mb-5"
            :tipo="prioridade"
            :cor="corPrioridade"
          />
        </div>
        <v-dialog
          v-model="modalPrioridades"
          max-width="500"
        >
          <v-card class="toggle-categoria">
            <h6 class="editar-categoria subtitle-2 mb-6">
              Editar Prioridade
            </h6>
            <p class="mb-2">
              Escolha a prioridade:
            </p>
            <v-card-subtitle class="mt-1">
              <v-select
                label="Prioridade"
                v-model="prioridade"
                :items="todasPrioridades"
                outlined
                dense
              ></v-select>
            </v-card-subtitle>

            <v-card-actions>
              <v-spacer></v-spacer>
              <Botao
                class="mr-4 mt-4"
                type="button"
                label="Cancelar"
                tipo="secondary"
                :callback="closeModalPrioridades"
              />
              <Botao
                class="mt-4"
                type="button"
                label="Salvar"
                :callback="atualizaPrioridade"
                :tipo="checkForm"
              />
            </v-card-actions>
          </v-card>
        </v-dialog>
        <hr v-if="id_prioridade"/>

        <div class="flex-menu">
          <h6 class="d-inline-flex mb-1 subtitle-2">Setor</h6>
          <img
            v-if="(isPermissao && isNaoEncerrado) || (isGestor && !isNaoEncerrado)"
            class="categoria"
            @click="modalCategorias = true"
            src="../assets/icons/edit.svg"
            alt="edit"
          >
        </div>
        <TagLabel
          class="mb-5"
          :tipo="atendimento.setor_nome"
        />
        <v-dialog
          v-model="modalCategorias"
          max-width="500"
        >
          <v-card class="toggle-categoria">
            <h6 class="editar-categoria subtitle-2 mb-6">
              Editar setor
            </h6>
            <p class="mb-2">
              Escolha o novo setor:
            </p>
            <v-card-subtitle class="mt-1">
              <v-select
                label="Setor"
                v-model="atendimento.setor_id"
                :items="todosSetores"
                outlined
                dense
                @click.stop="edit()"
                @change="getCategorias"
              ></v-select>
            </v-card-subtitle>
            <p class="mt-4">
              Selecione a categoria:
            </p>
            <v-card-subtitle
              v-if="atendimento.setor_id"
              class="mt-1"
            >
              <v-select
                label="Categoria"
                v-model="atendimento.id_categoria"
                :items="categoriasFiltradas"
                outlined
                dense
                @change="getSubcategorias"
              ></v-select>
            </v-card-subtitle>
            <p class="mt-4">
              Selecione a subcategoria:
            </p>
            <v-card-subtitle
              class="mt-1"
            >
              <v-select
                label="Subcategoria"
                v-model="atendimento.id_subcategoria"
                :items="subcategorias"
                outlined
                dense
              ></v-select>
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <Botao
                class="mr-4 mt-4"
                type="button"
                label="Cancelar"
                tipo="secondary"
                :callback="closeModalCategorias"
              />
              <Botao
                class="mt-4"
                type="button"
                label="Salvar"
                :callback="putAtendimentoSubcategoria"
                :tipo="checkForm"
              />
            </v-card-actions>
          </v-card>
        </v-dialog>
        <hr />
        <div class="flex-menu">
          <h6 class="d-inline-flex mb-1 subtitle-2">Categoria</h6>
          <img
            v-if="(isPermissao && isNaoEncerrado) || (isGestor && !isNaoEncerrado)"
            class="categoria"
            @click="modalSubcategorias = true"
            src="../assets/icons/edit.svg"
            alt="edit"
          >
        </div>
        <TagLabel
          class="mb-5"
          :tipo="atendimento.categoria"
        />
        <v-dialog
          v-model="modalSubcategorias"
          max-width="500"
        >
          <v-card class="toggle-categoria">
            <h6 class="editar-categoria subtitle-2 mb-6">
              Editar categoria
            </h6>
            <p class="mb-2">
              Escolha a nova categoria:
            </p>
            <v-card-subtitle
              class="mt-1"
            >
              <v-select
                label="Categoria"
                v-model="atendimento.id_categoria"
                :items="categoriasFiltradas"
                outlined
                dense
                @click.stop="edit()"
                @change="getSubcategorias"
              ></v-select>
            </v-card-subtitle>
            <p class="mt-4">
              Selecione a subcategoria:
            </p>
            <v-card-subtitle
              class="mt-1"
            >
              <v-select
                label="Subcategoria"
                v-model="atendimento.id_subcategoria"
                :items="subcategorias"
                outlined
                dense
              ></v-select>
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <Botao
                class="mr-4 mt-4"
                type="button"
                label="Cancelar"
                tipo="secondary"
                :callback="closeModalSubcategorias"
              />
              <Botao
                class="mt-4"
                type="button"
                label="Salvar"
                :callback="putAtendimentoSubcategoria"
                :tipo="checkForm"
              />
            </v-card-actions>
          </v-card>
        </v-dialog>
        <hr />
        <h6 class="mb-1 subtitle-2">Sede</h6>
        <TagLabel
          class="mb-5"
          :tipo="atendimento.sede"
        />
        <hr />
        <h6 class="mb-1 subtitle-2">Status</h6>
        <InputSelect
          v-if="isPermissao && atendimento.id_status != this.statusEncerradoId"
          :label="atendimento.status === 'Aguardando atendente' ?
            'Aguardando responsável' : atendimento.status "
          v-model="selectStatus"
          :options="novoStatus"
          :key="updateStatus"
        />
        <h6
          v-else
          class="mb-1 subtitle-2"
        >
          <CircularStatus :status="atendimento.status | statusToColor" /> {{atendimento.status}}
        </h6>
        <hr />
        <div class="flex-menu">
          <h6 class="d-inline-flex mb-1 subtitle-2">Responsável</h6>
          <h6
            v-if="(isNaoEncerrado && atendenteTipo.id_permissao > 1
              && atendimento.setor_id === atendenteTipo.id_setor)"
            class="atribuir mb-3"
            @click="atribuir()"
          >Atribuir-se</h6>
        </div>

        <InputSelect
          v-if="isAtribuidor || isGestor"
          :label="atendimento.atendente_nome ? atendimento.atendente_nome
            : 'Não atribuído'"
          v-model="atendente"
          :options="atendentes"
          :disabled="!isNaoEncerrado"
        />
        <p v-else>
          {{atendimento.atendente_nome ? atendimento.atendente_nome
            : 'Não atribuído' }}
        </p>
        <p v-if="alerta">
          <img
            src="../assets/icons/icone-atencao.svg"
            alt="atenção"
            height="20"
            width="20"
          />
          <strong> {{aguardandoResposta.data[0].atendenteDestino.nome}}</strong>
          ainda não aceitou a redistribuição.
        </p>
        <hr />
        <v-dialog
        v-model="convidarSetorDialog"
        persistent
        max-width="500"
        >
          <v-card class="toggle-categoria">
            <InputSelect
              v-model="setorConvidadoSelect"
              label="Setor"
              :options="setores"
              v-if="setores.length"
            />
            <h6 v-else class="d-inline-flex mb-1 subtitle-2">
              <img
              src="../assets/icons/icone-atencao.svg"
              alt="atenção"
              height="20"
              width="20"
            />
             <strong>Não há setores para serem convidados no momento</strong>
            </h6>
            <v-card-actions>
              <v-spacer></v-spacer>
              <Botao
                class="mr-4 mt-4"
                type="button"
                label="Cancelar"
                tipo="secondary"
                :callback="setorConvidadoModal"
              />
              <Botao
                class="mt-4"
                type="button"
                label="Salvar"
                :callback="putAtendimentoSetorConvidado"
              />
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div v-for="(item, index) in setoresConvidados" :key=index class="convidados">
          <h6 class="d-inline-flex mb-1 subtitle-2">Setor convidado - {{item.setor_nome}}</h6>
          <v-dialog
        v-model="convidarParticipanteDialog"
        persistent
        max-width="500"
        >
          <v-card class="toggle-categoria">
          <InputSelect
            label='Não atribuído'
            v-model="participanteConvidadoSelect"
            :options="participantesPorSetor"
            v-if="participantesPorSetor.length"
          />
            <h6 v-else class="d-inline-flex mb-1 subtitle-2">
              <img
              src="../assets/icons/icone-atencao.svg"
              alt="atenção"
              height="20"
              width="20"
            />
             <strong>Não há participantes para serem convidados no momento</strong>
            </h6>
            <v-card-actions>
              <v-spacer></v-spacer>
              <Botao
                class="mr-4 mt-4"
                type="button"
                label="Cancelar"
                tipo="secondary"
                :callback="participanteConvidadoClosenModal"
              />
              <Botao
                class="mt-4"
                type="button"
                label="Salvar"
                :callback="atribuirConvidado"
              />
            </v-card-actions>
          </v-card>
        </v-dialog>
          <div class="flex-menu">
            <h6
             class="d-inline-flex mb-1 subtitle-2">Atendente - {{item.atendente_nome}}</h6>
            <h6
              v-if="(isGestor && isNaoEncerrado) ||
              (isNaoEncerrado && atendenteTipo.id_permissao > 1
              && item.setor_id === atendenteTipo.id_setor)"
              class="atribuir mb-3"
              @click="atribuirSeConvidado(item.id)"
            >Atribuir-se</h6>
          </div>
            <h6
              v-if="(isGestor && isNaoEncerrado)"
              class="atribuir mb-3"
              @click="participanteConvidadoOpenModal(item.setor_id, item.id)"
            >Convidar Participante</h6>
        <hr/>
          </div>
          <Botao
            v-if="(isGestor && isNaoEncerrado)||
                (isNaoEncerrado && atendenteTipo.id_permissao > 1
                && atendimento.setor_id === atendenteTipo.id_setor)"
            class="mr-4 mt-4"
            type="button"
            label="Convidar Setor"
            tipo="primary"
            :callback="setorConvidadoModal"
          />
          <hr v-if="(isGestor && isNaoEncerrado)||
                (isNaoEncerrado && atendenteTipo.id_permissao > 1
                && atendimento.setor_id === atendenteTipo.id_setor)">
          <div v-if="solicitacao" class="flex-menu">
            <h6
             v-for="(item, index) in statusAprovacao" :key=index
             class="mb-1 subtitle-2">Status da {{index+1}}ª tentativa de solicitação :
              <strong>{{item.status}}</strong></h6>
            <h6
             class="d-inline-flex mb-1 subtitle-2">Atendente solicitante :
              {{atendimento.atendente_nome}}</h6>
            <hr v-if="solicitacao">
          </div>
            <!-- BOTAO DE SOLICITAR APROVAÇÃO -->
          <Botao
            v-if="( atendenteTipo.id_permissao > 1 && isNaoEncerrado &&
                    subReajusteSalario == atendimento.subcategoria)"
            class="mr-4 mt-4"
            type="button"
            label="Solicitar Aprovação"
            tipo="primary"
            :callback="emailAprovacaoModal"
          />
          <hr v-if="( atendenteTipo.id_permissao > 1 && isNaoEncerrado &&
                    subReajusteSalario == atendimento.subcategoria)">
          <Botao
            v-if="(solicitacoesReprovadas.length !== 0)"
            class="mr-4 mt-4"
            type="button"
            label="Reenviar Solicitação"
            tipo="primary"
            :callback="reenviaSolicitacao"
          />
          <v-dialog
          v-model="emailAprovacaoDialog"
          persistent
          max-width="500"
          >
            <v-card class="toggle-categoria">
              <v-text-field
                label="Email completo do Aprovador"
                v-model="emails"
                outlined
                dense
              ></v-text-field>
              <v-card-actions>
                <v-spacer></v-spacer>
                <Botao
                  class="mr-4 mt-4"
                  type="button"
                  label="Cancelar"
                  tipo="secondary"
                  :callback="emailAprovacaoModal"
                />
                <Botao
                  class="mt-4"
                  type="button"
                  label="Enviar email"
                  :tipo="emails.includes('@') && emails.includes('com') ? 'primary' : 'disabled'"
                  :callback="atribuirEmailAprovacao"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>
          <hr v-if="(solicitacoesReprovadas.length !== 0)">
          <div v-if="atendimento.area_atuacao" class="flex-menu">
            <h6
             class="d-inline-flex mb-1 subtitle-2">Área de Atuação - {{areaAtuacaoMostrar}}</h6>
            <hr>
          </div>
            <v-select
              v-if="atendenteTipo.id_permissao > 1 && setorTi == atendenteTipo.setor_nome"
              label="Área de Atuação"
              v-model="areaAtuacaoSelect"
              :items="areaAtuacaoOptions"
              outlined
              dense
              @change="putAreaAtuacao"
            ></v-select>
          <hr v-if="atendenteTipo.id_permissao > 1 && setorTi == atendenteTipo.setor_nome">

          <IndentificadorAprovacoesSAP
            v-for="(item, index) in aprovacoesSAP" :key="index"
            :centroCusto="item.centroCusto"
            :valorCentroCusto="item.valor"
            :nomePrimeiroAprovador="item.nomePrimeiroAprovador"
            :statusPrimeiroAprovador="item.statusPrimeiroAprovador"
            :nomeSegundoAprovador="item.nomeSegundoAprovador"
            :statusSegundoAprovador="item.statusSegundoAprovador"
          />
      </div>
    </div>
  </layout>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import AtendimentoTimeline from '../components/AtendimentoTimeline.vue';
import FormTimeline from '../components/FormTimeline.vue';
import TagLabel from '../components/TagLabel.vue';
import InputSelect from '../components/InputSelect.vue';
import Breadcrumb from '../components/Breadcrumb.vue';
import CircularStatus from '../components/CircularStatus.vue';
import layout from '../layouts/Layout.vue';
import IndentificadorAprovacoesSAP from '../components/IdentificadorAprovacoesSAP.vue';
import Snackbar from '../components/Snackbar';
import Botao from '../components/Botao';
import Notify from '../mixins/Notify';
import ModalAvaliacao from '../components/ModalAvaliacao.vue';
import {
  aprovacao, fluxos, status, areaAtuacao, setores, subcategorias,
} from '../Constants.js';
import EventBus from '../plugins/EventBus';
import {
  getMaxPermission, validaAtendente, validaPermissao,
} from '../utils/utilities';

export default {
  name: 'visualizar_atendimento',
  components: {
    layout,
    AtendimentoTimeline,
    FormTimeline,
    TagLabel,
    InputSelect,
    Breadcrumb,
    CircularStatus,
    Snackbar,
    Botao,
    ModalAvaliacao,
    IndentificadorAprovacoesSAP,
  },
  mixins: [Notify],
  data() {
    return {
      isEdited: false,
      updateStatus: false,
      salvaPorPermissao: this.isPermissao ? 'atendente' : 'usuario',
      historico: true,
      atendenteID: this.$store.getters.isUsuario.id,
      atendenteTipo: getMaxPermission(this.$store),
      id_atendimento: parseInt(this.$route.params.id, 10),
      atendimento: {},
      selectStatus: null,
      atendente: '',
      atendentes: [],
      camposFluxo: [],
      novoStatus: [],
      statusHistorico: [],
      body: '',
      isCancel: false,
      breadcrumbs: [],
      snackbarOpen: false,
      categorias: null,
      editCategoria: null,
      subcategorias: null,
      modalSubcategorias: false,
      categoriasFiltradas: [],
      modalCategorias: false,
      modalPrioridades: false,
      active: null,
      statusEncerradoId: 4,
      statusAvaliacao: true,
      avaliacaoModalMin: null,
      modalConf: false,
      seAtribuiu: false,
      errorMessage: '',
      aguardandoResposta: '',
      alerta: false,
      setoresConvidados: [],
      convidarSetorDialog: false,
      areaAtuacaoDialog: false,
      areaAtuacaoSelect: '',
      areaAtuacaoOptions: [],
      areaAtuacaoMostrar: '',
      solicitacao: false,
      emailAprovacaoDialog: false,
      emails: '',
      setorTi: setores.TI,
      convidarParticipanteDialog: false,
      idHasConvidado: null,
      setorConvidadoSelect: null,
      setores: [],
      solicitacoesReprovadas: [],
      todosSetores: [],
      todosStatus: [],
      participantesPorSetor: [],
      alertaSetorConvidado: false,
      todasPrioridades: ['URGENTE', 'PRIORITARIO', 'NORMAL'],
      prioridade: '',
      id_prioridade: '',
      corPrioridade: '',
      prioridadeUrgente: 1, // Dado que está no banco de prioridades
      prioridadePrioritario: 2, // Dado que está no banco de prioridades
      prioridadeNormal: 3, // Dado que está no banco de prioridades
      emailSolicitacao: '',
      participanteConvidadoSelect: null,
      statusAprovacao: '',
      modalConfTitulo: 'Atualização de status',
      modalConfTexto: 'Você digitou uma mensagem e não a enviou, tem certeza que deseja encerrar o atendimento?',
      idGestor: 3,
      idUsuario: 1,
      subReajusteSalario: subcategorias.reajusteSalario,

      aprovacoesSAP: [],
      fluxoComprasTecnicasNacionais: fluxos.comprasTecnicasNacionais,
      fluxoComprasOrdinarias: fluxos.comprasOrdinarias,
    };
  },
  async created() {
    try {
      await this.getDadosAtendimento(this.id_atendimento);
      await this.getStatus();
      // await this.statusEmailAprovacao();
      this.lerTodasNotificacoes(this.id_atendimento, this.$store.getters.isUsuario.id);
      this.getUsuarioAtendente();
      await this.getHistoricoStatus();
      await this.verificaReatribuido();
      this.getSetores();
      this.getCategorias();
      this.getSubcategorias(this.atendimento.id_categoria);
      // await this.getEmailSolicitacao();
      await this.getStatusAtendimento();
      EventBus.$on('cancelarFluxo', () => {
        this.isCancel = true;
        this.selectStatus = status.encerrado;
        const userskill = this.atendentes.find(item => item.label.includes('userskill'));
        if (!this.atendimento.id_atendente && userskill) {
          this.atendente = userskill.value;
        }
        this.atualizaStatusEncerrado();
      });
      EventBus.$on('atualizarTimeline', () => {
        this.getHistoricoStatus();
      });
    } catch (error) {
      console.error(error);
    }
  },

  async mounted() {
    this.avaliacaoModalMin = this.statusAvaliacao;
    this.breadcrumbs = [
      {
        route: {
          name: 'lista',
        },
        label: 'Atendimentos',
      },
      {
        route: {
          name: 'atendimento',
        },
        label: 'Histórico de atendimento',
      },
    ];
    await this.getSetores();
    await this.getCategorias();
    await this.getSubcategorias(this.atendimento.id_categoria);
    await this.getAreaAtuacao();
    await this.getSolicitationSAP();
    // await this.getEmailSolicitacao();
    await this.getConvidadosAtendimento();
    // await this.solicitacaoReprovada();
  },
  computed: {
    checkForm() {
      return (!!this.atendimento.id_categoria
        && !!this.atendimento.id_subcategoria) ? 'primary' : 'disabled';
    },
    isPermissao() {
      return validaAtendente(this.$store);
    },
    isGestor() {
      return this.atendenteTipo.id_permissao === this.$store.getters.listaPermissoes.gestor
      || this.atendenteTipo.id_permissao === this.$store.getters.listaPermissoes.gestorgeral;
    },
    isAtribuidor() {
      return validaPermissao(this.$store.getters.isUsuario.permissao,
        this.$store.getters.listaPermissoes, this.$store.getters.listaPermissoes.atribuidor);
    },
    isAtendenteLogado() {
      return this.atendente === this.$store.getters.isUsuario.id;
    },
    isNaoEncerrado() {
      return this.atendimento.id_status !== status.encerrado;
    },
    isStatusAlteravel() {
      return this.atendimento.id_status === status.pendente
        || this.atendimento.id_status === status.aguardandoAtendente;
    },
  },
  watch: {
    selectStatus(newVal) {
      if (newVal && newVal !== this.atendimento.id_status) {
        if (newVal === status.encerrado && this.verificaEditor()) {
          this.modalConf = true;
        } else {
          this.putAtendimentoStatus(this.atendimento.id_status, newVal);
          this.getStatus();
        }
      }
    },
    atendente(newVal) {
      if (newVal) {
        this.putAtendimentoAtendente();
      }
    },
  },
  filters: {
    statusToColor(value) {
      switch (value) {
        case 'Pendente':
          return '#ff6b00';
        case 'Em análise':
          return '#8E24AA';
        case 'Em atendimento':
          return '#97c93d';
        case 'Aguardando usuário':
          return '#ffe500';
        case 'Encerrado':
          return '#ee0202';
        case 'Aguardando atendente':
          return '#027cee';
        default:
          return '#000000';
      }
    },
  },
  methods: {
    defineCor(idPrioridade) {
      if (idPrioridade === this.prioridadeUrgente) {
        this.corPrioridade = 'backgroundColor:#CF1717';
      } else if (idPrioridade === this.prioridadePrioritario) {
        this.corPrioridade = 'backgroundColor:#DAC81C';
      } else if (idPrioridade === this.prioridadeNormal) {
        this.corPrioridade = 'backgroundColor:#2BAE3A';
      }
    },


    async getSolicitationSAP() {
      const { data: response } = await this.$http.get(`/solicitacao-aprovacao/${this.id_atendimento}`);

      this.aprovacoesSAP = response.map(item => ({
        centroCusto: item.centro_custo,
        nomePrimeiroAprovador: item.nome_primeiro_aprovador,
        statusPrimeiroAprovador: item.status_primeira_solicitacao,
        nomeSegundoAprovador: item.nome_segundo_aprovador,
        statusSegundoAprovador: item.status_segunda_solicitacao,
        valor: item.valor,
      }));
    },

    async getEmailSolicitacao() {
      const { data: response } = await this.$http.get('/all-solicitacoes/');
      const listaEmail = response.filter(item => item.id_atendimento === this.id_atendimento);
      if (listaEmail.length > 0) {
        this.emailSolicitacao = listaEmail[0].email;
      }
    },

    async reenviaSolicitacao() {
      try {
        const body = {
          statusAprovacao: 'Aceita',
          segundaAprovacao: false,
          id_aprovador: this.$store.getters.isUsuario.id,
        };
        await this.$http.put(`/reenvia-solicitacao/${this.solicitacoesReprovadas[0].id}`, body);
        this.errorMessage = 'Solicitação de Aprovação reenviada!';
        this.snackbarOpen = true;
      } catch (e) {
        console.error(e);
      }
    },

    async solicitacaoReprovada() {
      try {
        const { data: response } = await this.$http.get('/reprovados-segunda-instancia/');
        this.solicitacoesReprovadas = response.filter(item => item.id_atendimento
        === this.id_atendimento);
      } catch (e) {
        console.error(e);
      }
    },

    async edit() {
      this.isEdited = true;
    },
    limpaSelecoes() {
      this.atendimento.id_categoria = '';
      this.atendimento.id_subcategoria = '';
    },
    limpaSubcategoria() {
      this.atendimento.id_subcategoria = '';
    },
    async verificaReatribuido() {
      this.aguardandoResposta = await this.$http.get(`/redistribui/${this.id_atendimento}`);
      if (this.aguardandoResposta.data.length > 0) {
        this.alerta = true;
      }
    },
    async atualizaStatus() {
      await this.getDadosAtendimento(this.id_atendimento);
      await this.getHistoricoStatus();
      this.updateStatus = !this.updateStatus;
    },
    verificaEditor() {
      const texto = this.$store.getters.comentario;
      const paragrafoVazio = '<p></p>';
      const tabelaVazia = '<table><tbody><tr><td><p></p></td><td><p></p></td><td><p></p></td></tr><tr><td><p></p></td><td><p></p></td><td><p></p></td></tr><tr><td><p></p></td><td><p></p></td><td><p></p></td></tr></tbody></table>';
      const { anexo } = this.$store.getters;
      return ((texto && texto !== paragrafoVazio) && (texto !== tabelaVazia)) || (anexo);
    },
    cancelaAtualizacaoStatus() {
      this.modalConf = false;
      this.selectStatus = this.atendimento.id_status;
    },
    atualizaStatusEncerrado() {
      this.putAtendimentoStatus(this.atendimento.id_status, status.encerrado);
      this.getStatus();
      this.modalConf = false;
    },
    async atualizaPrioridade() {
      if (this.prioridade === this.todasPrioridades[0]) {
        this.atendimento.id_prioridade = 1;
      } else if (this.prioridade === this.todasPrioridades[1]) {
        this.atendimento.id_prioridade = 2;
      } else {
        this.atendimento.id_prioridade = 3;
      }
      this.defineCor(this.atendimento.id_prioridade);
      const body = {
        id: this.id_atendimento,
        id_prioridade: this.atendimento.id_prioridade,
      };
      await this.$http.put('/edicao-prioridade/', body);
      this.closeModalPrioridades();
    },

    atualizaStatusPendente() {
      this.putAtendimentoStatus(this.atendimento.id_status, status.pendente);
    },
    async getHistoricoStatus() {
      try {
        const { id } = this.$route.params;
        const { data: response } = await this.$http.get(
          `/historicoalteracoes/${id}`,
        );
        if (response.length) {
          const responseTratado = response.map((item) => {
            if (item.tipo_alteracao === 'AREA ATUACAO') {
              if (item.valor_novo === 1) {
                // eslint-disable-next-line no-param-reassign
                item.valor_novo_desc = 'Infraestrutura';
              } else if (item.valor_novo === 2) {
                // eslint-disable-next-line no-param-reassign
                item.valor_novo_desc = 'Sistemas Internos';
              }
            } else if (item.tipo_alteracao === 'SOLICITACAO') {
              if (item.valor_novo === 4) {
                // eslint-disable-next-line no-param-reassign
                item.valor_novo_desc = 'aceita e encaminhada para terceiro aprovador';
              }
              if (item.valor_novo === 2) {
                // eslint-disable-next-line no-param-reassign
                item.valor_novo_desc = 'Aceita';
              }
              if (item.valor_novo === 3) {
                // eslint-disable-next-line no-param-reassign
                item.valor_novo_desc = 'Recusada';
              }
            }
            return item;
          });
          this.statusHistorico = responseTratado;
        } else {
          this.statusHistorico = response;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getDadosAtendimento(id) {
      try {
        const { data: atendimentoCompleto } = await this.$http.get(`/lista/${id}`);

        const { data: atendimentoFluxo } = await this.$http.get(`/atendimentos/${this.id_atendimento}`);
        this.camposFluxo = atendimentoFluxo.campos_fluxo;
        this.id_prioridade = atendimentoFluxo.id_prioridade;
        this.prioridade = atendimentoFluxo.descricao_prioridade;
        this.atendimento = atendimentoCompleto;
        this.defineCor(this.atendimento.id_prioridade);
        this.selectStatus = atendimentoCompleto.id_status;
      } catch (error) {
        console.error(error);
      }
    },
    splitNome(nome) {
      let nomeFormatado = '';
      if (nome) {
        const arrayNome = nome.split(' ');
        nomeFormatado = `${arrayNome[0]} ${arrayNome[arrayNome.length - 1]}`;
      }
      return nomeFormatado;
    },
    async getUsuarioAtendente() {
      try {
        const { data: response } = await this.$http.get(
          '/lista-por-permissao/2',
        );
        let listaSemUser = response.filter(
          item => item.id_usuario !== this.atendimento.id_usuario,
        );

        listaSemUser = listaSemUser.map(item => ({
          label: this.splitNome(item.nome),
          value: item.id_usuario,
          setor_id: item.setor_id,
          id_permissao: item.id_permissao,
        }));

        this.atendentes = listaSemUser.filter(item => item.id_permissao
          === this.$store.getters.listaPermissoes.atendente
          || item.id_permissao === this.$store.getters.listaPermissoes.gestor
          || item.id_permissao === this.$store.getters.listaPermissoes.gestorgeral);
        if (this.atendenteTipo.id_permissao < this.$store.getters.listaPermissoes.gestorgeral) {
          this.atendentes = this.atendentes.filter(item => item.setor_id
        === this.atendenteTipo.id_setor);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getStatus() {
      try {
        const { data: response } = await this.$http.get('/status/');
        this.todosStatus = response.map(item => ({
          nome: item.tipo,
          id: item.id,
        }));
        this.novoStatus = response.map(item => ({
          label: item.tipo === 'Aguardando atendente' ? 'Aguardando responsável' : item.tipo,
          value: item.id,
          status: this.$options.filters.statusToColor(item.tipo),
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async putAtendimentoStatus(idAntigo, idNovo) {
      try {
        if (idAntigo !== idNovo) {
          if (idNovo === status.aguardandoAtendente && !this.atendimento.id_atendente) {
            this.errorMessage = 'É Necessário selecionar um atendente para atualizar este status';
            this.snackbarOpen = true;
            this.atualizaStatus();
            return;
          }

          if (idNovo === status.encerrado
           && this.atendimento.id_fluxo === this.fluxoComprasTecnicasNacionais) {
            const hasConfirmation = await this.hasConfirmationComprasTecnicasNacionais();

            if (!hasConfirmation) {
              this.errorMessage = 'Para encerrar o Atendimento, você precisa Confirmar Recebimento e Salvar.';
              this.snackbarOpen = true;
              this.atualizaStatus();
              return;
            }
          }

          if (idNovo === status.encerrado
           && this.atendimento.id_fluxo === this.fluxoComprasOrdinarias) {
            const hasConfirmation = await this.hasConfirmationFluxoComprasOrdinarias();

            if (!hasConfirmation) {
              this.errorMessage = 'Para encerrar o Atendimento, você precisa Confirmar Recebimento e Salvar.';
              this.snackbarOpen = true;
              this.atualizaStatus();
              return;
            }
          }

          const atendente = this.atendente ? this.atendente : this.atendimento.id_atendente;
          const body = {
            usuario_atendente_id: atendente,
            status_id: idNovo,
            id_usuario: this.atendimento.id_usuario,
            id_atendente: this.atendimento.id_atendente,
            id_atendimento: parseInt(this.$route.params.id, 10),
            tipo_alteracao: 'STATUS',
            valor_antigo: idAntigo,
            valor_novo: idNovo,
            isCancel: this.isCancel,
          };
          await this.$http.put(`/atendimentos/${this.id_atendimento}`, body);
          this.getHistoricoStatus();
          this.getDadosAtendimento(this.id_atendimento);
          this.notificaNovoStatus(
            idNovo,
            this.atendimento.id_usuario,
            this.$store.getters.isUsuario.id,
            this.$route.params.id,
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    async hasConfirmationComprasTecnicasNacionais() {
      const { data: response } = await this.$http.get(`/fluxos-has-confirmation-formularizar-pedido-nacional/${this.id_atendimento}`);
      const hasConfirmation = response;
      return hasConfirmation;
    },
    async hasConfirmationFluxoComprasOrdinarias() {
      const { data: response } = await this.$http.get(`/fluxos-has-confirmation-novas-compras/${this.id_atendimento}`);
      const hasConfirmation = response;
      return hasConfirmation;
    },
    async putAtendimentoAtendente() {
      try {
        if (this.seAtribuiu) {
          const body = {
            usuario_atendente_id: this.atendente,
            id_usuario: this.atendimento.id_usuario,
            id_atendimento: parseInt(this.$route.params.id, 10),
            tipo_alteracao: 'ATRIBUICAO',
            valor_antigo: this.atendimento.id_atendente,
            valor_novo: this.atendente,
            atribuido: true,
          };
          await this.$http.put(`/atendimentos/${this.id_atendimento}`, body);
          this.errorMessage = 'Você se atribuiu ao atendimento!';
          this.snackbarOpen = true;
          this.seAtribuiu = false;
          this.getHistoricoStatus();
          this.getDadosAtendimento(this.id_atendimento);
          this.notificaNovoAtendente();
        } else {
          const { data: response } = await this.$http.get(`/redistribui/${this.id_atendimento}`);
          const { data: usuarioAtribuido } = await this.$http.get(`/atendimento/${this.id_atendimento}`);
          const permissaoMax = getMaxPermission(this.$store).id_permissao;
          if (response[0]) {
            this.errorMessage = `Atendimento já atribuido ao responsavel: ${response[0].atendenteDestino.nome}, Aguardando sua resposta`;
            this.snackbarOpen = true;
            return;
          }

          if (usuarioAtribuido.usuario_atendente_id === this.atendente) {
            this.errorMessage = 'Usuário selecionado já está atribuido!';
            this.snackbarOpen = true;
            return;
          }

          if (permissaoMax === this.$store.getters.listaPermissoes.atendente) {
            const dadosPost = {
              id_atendente_origem: this.atendenteID,
              id_atendente_destino: this.atendente,
              id_atendimento: this.id_atendimento,
              status: 'pendente',
            };

            this.errorMessage = 'Atendimento atribuido aguardando resposta!';
            this.snackbarOpen = true;
            await this.$http.post(
              '/redistribui', dadosPost,
            );
          } else if (permissaoMax === this.$store.getters.listaPermissoes.gestor
            || permissaoMax === this.$store.getters.listaPermissoes.gestorgeral) {
            const dadosPost = {
              id_atendente_origem: this.atendenteID,
              id_atendente_destino: this.atendente,
              id_atendimento: this.id_atendimento,
              status: 'aceito',
            };

            this.errorMessage = 'Atendimento atribuido!';
            this.snackbarOpen = true;
            await this.$http.post(
              '/redistribui', dadosPost,
            );

            const { data: dados } = await this.$http.get(`/ultimoCadastro/${this.id_atendimento}`);
            const body = {
              usuario_atendente_id: dados[0].id_atendente_destino,
              id_usuario: dados[0].atendimento.usuario_id,
              id_atendimento: dados[0].id_atendimento,
              tipo_alteracao: 'ATRIBUICAO',
              valor_antigo: dados[0].id_atendente_origem,
              valor_novo: dados[0].id_atendente_destino,
              atribuido: true,
            };
            await this.$http.put(`/atendimentos/${dados[0].id_atendimento}`, body);
            this.$router.go();
          }

          this.getHistoricoStatus();
          this.getDadosAtendimento(this.id_atendimento);
          this.notificaNovoAtendente();
        }
        this.verificaReatribuido();
      } catch (error) {
        console.error(error);
      }
    },
    async putEncerraAtendimento() {
      try {
        this.putAtendimentoStatus(this.atendimento.id_status, status.encerrado);
      } catch (error) {
        console.error(error);
      }
    },
    atribuir() {
      try {
        this.seAtribuiu = true;
        this.atendente = this.atendenteID;
        this.snackbarOpen = true;
      } catch (error) {
        console.error(error);
      }
    },

    async statusEmailAprovacao() {
      const { data: response } = await this.$http.get('/email-aprovacao');

      this.statusAprovacao = response.map(item => ({
        id: item.id,
        atendimento_id: item.atendimento_id,
        status: item.status_aprovacao,
      }));

      this.statusAprovacao = this.statusAprovacao
        .filter(item => item.atendimento_id === this.id_atendimento);

      if (this.statusAprovacao.length > 0) {
        this.solicitacao = true;
      }
    },

    async atribuirEmailAprovacao() {
      try {
        this.solicitacao = true;

        const body = {
          id_responsavel: this.atendenteID,
          id_usuario: this.atendimento.id_usuario,
          id_atendimento: parseInt(this.$route.params.id, 10),
          tipo_alteracao: 'APROVACAO',
          valor_antigo: null,
          valor_novo: aprovacao.aguardandoAprovacao,
          email: this.emails,
        };

        await this.$http.post('/email-aprovacao', body);
        this.errorMessage = 'Solicitação enviada com sucesso!';
        this.snackbarOpen = true;
        // this.statusEmailAprovacao();
        this.emailAprovacaoModal();
      } catch (e) {
        console.error(e);
        this.errorMessage = 'Já uma existe solicitação para este atendimento !';
        this.snackbarOpen = true;
      }
    },

    async atribuirConvidado() {
      const body = {
        atendente_id: this.participanteConvidadoSelect,
      };
      if (this.idHasConvidado) {
        await this.$http.put(`/convidados/${this.idHasConvidado}`, body);
      }
      this.getConvidadosAtendimento();
      const bodyHist = {
        usuario_atendente_id: this.atendimento.id_atendente,
        id_usuario: this.$store.getters.isUsuario.id,
        id_atendimento: this.id_atendimento,
        tipo_alteracao: 'CONVITE PARTICIPANTE',
        valor_antigo: 0,
        valor_novo: this.$store.getters.isUsuario.id,
      };
      this.participanteConvidadoClosenModal();
      await this.$http.put(`/atendimentos/${this.id_atendimento}`, bodyHist);
      this.errorMessage = 'Atribuído com sucesso!';
      this.snackbarOpen = true;
    },
    async atribuirSeConvidado(conviteId) {
      const body = {
        atendente_id: this.$store.getters.isUsuario.id,
      };
      if (conviteId) {
        await this.$http.put(`/convidados/${conviteId}`, body);
      }
      this.getConvidadosAtendimento();
      const bodyHist = {
        usuario_atendente_id: this.atendimento.id_atendente,
        id_usuario: this.$store.getters.isUsuario.id,
        id_atendimento: this.id_atendimento,
        tipo_alteracao: 'CONVITE PARTICIPANTE',
        valor_antigo: 0,
        valor_novo: this.$store.getters.isUsuario.id,
      };
      await this.$http.put(`/atendimentos/${this.id_atendimento}`, bodyHist);
      this.errorMessage = 'Atribuído com sucesso!';
      this.snackbarOpen = true;
    },
    async atribuirStatus(conviteId, novoStatus, antigoStatus) {
      const body = {
        status_id: novoStatus,
      };
      if (conviteId) {
        await this.$http.put(`/convidados/${conviteId}`, body);
      }
      this.getConvidadosAtendimento();
      const bodyHist = {
        usuario_atendente_id: this.atendimento.id_atendente,
        id_usuario: this.$store.getters.isUsuario.id,
        id_atendimento: this.id_atendimento,
        tipo_alteracao: 'STATUS CONVITE',
        valor_antigo: antigoStatus,
        valor_novo: novoStatus,
      };
      await this.$http.put(`/atendimentos/${this.id_atendimento}`, bodyHist);
      this.$router.go();
    },
    notificaNovoAtendente() {
      if (this.atendente === this.atendenteID) { return; }
      const dados = {
        id_usuario: this.atendente,
        id_usuario_responsavel: this.$store.getters.isUsuario.id,
        id_atendimento: this.$route.params.id,
      };
      this.emiteNotificacao('atribuicao', dados);
    },
    async lerTodasNotificacoes(idAtendimento, idUsuario) {
      await this.$http.put(`/ler-notificacao-atendimento/${idAtendimento}/${idUsuario}`);
      this.$refs.layout.layoutCarregaNotificacoes();
    },
    async getCategorias() {
      if (this.isEdited === true) this.limpaSelecoes();
      try {
        const { data: response } = await this.$http.get('/categorias');
        this.categorias = response.map(item => ({
          value: item.id,
          text: item.nome,
          setor_id: item.setor_id,
        }));
        this.categoriasFiltradas = this.categorias.filter(
          categoria => categoria.setor_id === this.atendimento.setor_id,
        );
      } catch (error) {
        console.error(error);
      }
    },
    async getSubcategorias(id) {
      if (this.isEdited === true) this.limpaSubcategoria();
      try {
        const { data: response } = await this.$http.get(`/subcategorias/${id}`);
        this.subcategorias = response.map(item => ({
          value: item.id,
          text: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async putAtendimentoSubcategoria() {
      try {
        const body = {
          id_atendimento: this.id_atendimento,
          subcategoria_id: this.atendimento.id_subcategoria,
          id_usuario: this.atendimento.id_usuario,
          setor_id: this.atendimento.setor_id,
        };
        this.atualizaStatusPendente();
        await this.$http.put(`/atualiza-atendimento-subcategoria/${this.id_atendimento}`, body);
        this.getDadosAtendimento(this.id_atendimento);
        this.closeModalSubcategorias();
        this.closeModalCategorias();
      } catch (error) {
        console.error(error);
      }
    },
    closeModalPrioridades() {
      this.modalPrioridades = false;
    },
    closeModalSubcategorias() {
      this.modalSubcategorias = false;
    },
    closeModalCategorias() {
      this.modalCategorias = false;
    },
    emailAprovacaoModal() {
      this.emailAprovacaoDialog = !this.emailAprovacaoDialog;
      this.emails = '';
    },
    setorConvidadoModal() {
      this.convidarSetorDialog = !this.convidarSetorDialog;
    },
    participanteConvidadoOpenModal(setorId, conviteId) {
      this.idHasConvidado = conviteId;
      this.getParticipantesSetor(setorId);
      this.convidarParticipanteDialog = !this.convidarParticipanteDialog;
    },
    participanteConvidadoClosenModal() {
      this.convidarParticipanteDialog = !this.convidarParticipanteDialog;
    },
    isEncerrado(Atendimento) {
      const idUsuario = this.$store.getters.isUsuario.id;
      return (idUsuario === Atendimento.usuario_id && Atendimento.status_id === status.encerrado);
    },
    hasAvaliacao(Atendimento) {
      return (
        (!Atendimento.reaberto && isEmpty(Atendimento.avaliacoesAtendimento))
        || (Atendimento.reaberto && isEmpty(Atendimento.avaliacoesAtendimento))
        || (Atendimento.reaberto && Atendimento.avaliacoesAtendimento.length === 1)
      );
    },
    async getParticipantesSetor(setorId) {
      try {
        const { data: response } = await this.$http.get('/listar-permissao');
        let responseTratado = response.map(item => ({
          label: item.usuario_nome,
          value: item.usuario_id,
          permissao_id: item.permissao_id,
          setorId: item.setor_id,
        }));
        responseTratado = responseTratado.filter(item => item.permissao_id >= 2);
        const listaVazia = [];
        // eslint-disable-next-line array-callback-return
        responseTratado.map((item) => {
          if (item.setorId === setorId) listaVazia.push(item);
        });
        this.participantesPorSetor = listaVazia;
      } catch (error) {
        console.error(error);
      }
    },
    async getConvidados() {
      const { data: response } = await this.$http.get('/convidados');
      return response;
    },
    async getConvidadosAtendimento() {
      const convites = await this.getConvidados();
      let retorno = [];
      if (convites.length > 0) {
        retorno = convites.filter(item => item.atendimento_id
          === this.atendimento.id_atendimento);
      }
      retorno = retorno.map((item) => {
        const nomeSetor = this.todosSetores.find(element => element.id === item.setor_id);
        const nomeAtendente = this.atendentes.find(element2 => element2.value
          === item.atendente_id);
        const nomeStatus = this.todosStatus.find(element3 => element3.id === item.status_id);
        return {
          ...item,
          setor_nome: nomeSetor.nome,
          atendente_nome: nomeAtendente ? nomeAtendente.label : 'Não atribuído',
          status_nome: nomeStatus.nome,
        };
      });
      this.setoresConvidados = retorno;
    },
    async putAtendimentoSetorConvidado() {
      try {
        const body = {
          atendimento_id: this.id_atendimento,
          setor_id: this.setorConvidadoSelect,
          atendente_id: null,
          status_id: 1,
          id_atendente_convite: this.atendimento.id_atendente,
        };
        await this.$http.post('/convidados', body);
        const bodyHist = {
          usuario_atendente_id: this.atendimento.id_atendente,
          id_usuario: this.$store.getters.isUsuario.id,
          id_atendimento: this.id_atendimento,
          tipo_alteracao: 'CONVITE SETOR',
          valor_antigo: 0,
          valor_novo: this.setorConvidadoSelect,
        };
        await this.$http.put(`/atendimentos/${this.id_atendimento}`, bodyHist);
        this.getDadosAtendimento(this.id_atendimento);
        this.getSetores();
        this.setorConvidadoModal();
        this.getConvidadosAtendimento();
        this.setorConvidadoSelect = '';
      } catch (error) {
        console.error(error);
      }
    },
    async getAreaAtuacao() {
      this.areaAtuacaoOptions = areaAtuacao.map(item => ({
        value: item.value,
        text: item.label,
      }));
      this.areaAtuacaoMostrar = this.atendimento.area_atuacao === 1 ? 'Infraestrutura' : 'Sistemas Internos';
    },

    async putAreaAtuacao() {
      try {
        const body = {
          status_id: this.statusAguardandoAtendenteId,
          id_usuario: this.atendimento.id_usuario,
          id_atendimento: parseInt(this.$route.params.id, 10),
          tipo_alteracao: 'AREA ATUACAO',
          valor_antigo: this.atendimento.area_atuacao,
          valor_novo: this.areaAtuacaoSelect,
        };
        this.areaAtuacaoMostrar = this.areaAtuacaoSelect === 1 ? 'Infraestrutura' : 'Sistemas Internos';
        await this.$http.put(`/atualiza-atendimento-area-atuacao/${body.id_atendimento}`, body);
        this.getDadosAtendimento(this.id_atendimento);
        this.$router.go();
      } catch (error) {
        console.error(error);
      }
    },
    async getSetores() {
      try {
        const { data: response } = await this.$http.get('/setores');
        this.todosSetores = response.map(item => ({
          value: item.id,
          text: item.nome,
        }));
        this.setores = await this.filtraSetorConvidado(response);
      } catch (error) {
        console.error(error);
      }
    },
    async filtraSetorConvidado(listaSetores) {
      let retorno;
      try {
        const tratamentoSetores = listaSetores.map(item => ({
          value: item.id,
          label: item.nome,
        }));
        const response = await this.getConvidados();
        const listaSetoresIdConvidado = [];
        response.map((item) => {
          if (this.atendimento.id_atendimento === item.atendimento_id) {
            listaSetoresIdConvidado.push(item.setor_id);
          }
          return null;
        });
        const setoresTratados = [];
        tratamentoSetores.forEach((setor) => {
          if (this.atendimento.setor_id === setor.value);
          else if (listaSetoresIdConvidado.includes(setor.value));
          else setoresTratados.push(setor);
        });
        retorno = setoresTratados;
      } catch (error) {
        console.error(error);
      }
      return retorno;
    },
    async getStatusAtendimento() {
      try {
        const idAtendimento = parseInt(this.$route.params.id, 10);
        const { data: response } = await this.$http.get(`/status-atendimento/${idAtendimento}`);

        if (this.isEncerrado(response)) {
          if (this.hasAvaliacao(response)) {
            this.statusAvaliacao = false;
          }
        } else {
          this.statusAvaliacao = true;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
div {
  padding: 0;
  margin: 0;
}
.title {
  font-weight: 500 !important;
  color: #0157a8 !important;
}
.btn {
  margin-top: 10px;
  background-color: #0157a8;
  border-radius: 50px;
  padding: 7px 30px;
}
.linha {
  border-left: #c1c1c1 1px solid;
  position: sticky;
  right: 30px;
  overflow: auto;
  @media screen and (min-width: 1600px) {
    margin-left: 5%;
  }
}
.btn-stilo {
  display: flex;
  justify-content: flex-end;
}
.btn-local {
  margin: 10px 0px 0 10px;
}
.icon {
  width: 50px;
  height: 50px;
  padding: 11px;
  color: #0157a8;
  border-radius: 50px;
  background: white;
  border: 5px solid white;
  z-index: 1;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}
.atribuir {
  float: right;
  width: fit-content;
}
.atribuir:hover {
  text-decoration: underline;
  cursor: pointer;
}
.categoria {
  float: right;
  width: fit-content;
}
.categoria:hover {
  cursor: pointer;
  opacity: 0.5;
}
.toggle-categoria {
  padding: 30px;
  background-color: #fff;
  width: 100%;
}
.editar-categoria {
  display: flex;
  justify-content: center;
  color: #0056a8;
}
.btn {
  text-transform: capitalize !important;
  border-radius: 50px !important;
  padding: 6px 17px !important;
}
.btn-outline-primary {
  color: #707070 !important ;
  background-color: #fff !important;
  border: 1px solid #707070 !important;
  margin: 4px 8px 4px 0px;
  &:hover {
    color: #fff !important;
    background-color: #0056a8 !important;
    border-color: #0056a8 !important;
  }

  &.active {
    outline: none;
    box-shadow: none !important;
    color: #fff !important;
    background-color: #0056a8 !important;
    border-color: #0056a8 !important;
  }
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #0056a8 !important;
  border-color: #0056a8 !important;
}
@media (max-width: 950px) {
  .flex-menu {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
  }
}
.convidados {
  display: flex;
  flex-direction: column;
}
</style>
<style lang="scss">
html .theme--light.v-timeline:before {
  background: $primary;
}
@media screen and (max-height: 768px) {
  .linha {
    position: absolute !important;
    top: 0px;
    right: 30px;
  }
}
</style>
