<template>
  <div>
    <v-row class="indicadores">
      <h1 class="my-5 ml-6 font-weight-bold body-0">Volumetria da Área</h1>
      <!-- Indicador Área de atuação TI -->
        <v-col lg="12">
          <IndicadorAtendimentoPorTI
            v-if="setorTi === atendenteTipo.setor_nome && exibeIndicadorPorTI"
            :valores="atendimentosPorTI"
            :atuacao="TIOrdenadas"
            :key="updateAtendimentosPorTI"
            :loaderTI="loaderTI"
          />
        </v-col>
        <!-- Atendimentos por Sedes -->
        <v-row>
          <v-col lg="6">
            <IndicadorPorSede
              v-if="exibeIndicadorPorSede"
              :valores="atendimentosPorSede"
              :sedes="sedesOrdenadas"
              :key="updateAtendimentosPorSede"
              :loaderSede="loaderSede"
            />
          </v-col>
          <!-- Atendimentos por Status -->
          <v-col lg="5">
            <IndicadorPorStatus
              v-if="exibeIndicadorAtendimentoPorStatus"
              :valores="atendimentosPorStatus"
              :statusLabel="statusOrdenados"
              :totalAbertos="totalAbertos"
              :totalEncerrados="totalEncerrados"
              :key="updateAtendimentosPorStatus"
              :loaderStatus="loaderStatus"
            />
          </v-col>
        </v-row>
        <!-- Atendimentos Encerrados nos ultimos 14 dias -->
        <v-col lg="12">
          <IndicadorEncerradosUltimosDias
            v-if="exibeIndicadorUltimoDia"
            :valores="dadosUltimosDias"
            :key="updateEncerradosUltimosDias"
            :loader="loaderUltimosDias"
          />
        </v-col>
        <!-- Tempo médio de atendimento por categoria -->
        <v-col lg="12">
          <IndicadorTempoCategoria
            v-if="exibeIndicadorTempoCategoria"
            :valores="tempoMedioCategorias"
            :categorias="categoriasOrdenadasTempo"
            :key="updateAtendimentosTempoCategoria"
            :loader="loaderTempoCategoria"
          />
        </v-col>
        <!-- Quantiade de atendimentos abertos por categoria -->
        <v-col lg="12">
          <IndicadorPorCategoria
            v-if="exibeIndicadorPorCategoria"
            :valores="atendimentosPorCategoria"
            :categorias="categoriasOrdenadas"
            :key="updateAtendimentosPorCategoria"
            :loaderCategoria="loaderCategoria"
          />
        </v-col>
        <!-- Quantidade de atendimentos abertos por subcategoria -->
        <v-col lg="12">
          <IndicadorPorSubcategoria
            v-if="exibeIndicadorPorSubcategoria"
            :valores="atendimentosPorSubcategoria"
            :subcategorias="subcategoriasOrdenadas"
            :key="updateAtendimentosPorSubcategoria"
            :loaderSubcategoria="loaderSubcategoria"
          />
        </v-col>
        <!-- Quantidade de atendimentos reabertos por categoria -->
        <v-col lg="12">
          <IndicadorReabertosPorCategoria
            v-if="exibeIndicadorReabertosCategoria"
            :categorias="categoriasOrdenadasReabertas"
            :valores="reabertosPorCategoria"
            :key="updateReabertosPorCategoria"
            :loaderReabertosCategoria="loaderReabertosCategoria"
          />
        </v-col>
        <!-- Atendimentos por Prioridades -->
        <v-col lg="12">
            <IndicadorPorPrioridades
              v-if="exibeIndicadorAtendimentoPorPrioridades"
              :prioridadesLabel="atendimentosPorPrioridades"
              :valores="valoresPorPrioridades"
              :key="updateAtendimentosPorPrioridades"
              :loaderPrioridades="loaderPrioridades"
            />
          </v-col>
    </v-row>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import IndicadorAtendimentoPorTI from './IndicadorAtendimentoPorTI';
import IndicadorPorSede from './IndicadorPorSede';
import IndicadorPorStatus from './IndicadorPorStatus';
import IndicadorEncerradosUltimosDias from './IndicadorEncerradosUltimosDias';
import IndicadorTempoCategoria from './IndicadorTempoCategoria';
import IndicadorPorCategoria from './IndicadorPorCategoria';
import IndicadorPorSubcategoria from './IndicadorPorSubcategoria';
import IndicadorReabertosPorCategoria from './IndicadorReabertosPorCategoria';
import IndicadorPorPrioridades from './IndicadorPorPrioridades.vue';
import { setores } from '../../Constants';
import { getMaxPermission } from '../../utils/utilities';

export default {
  name: 'IndicadoresVolumetria',
  components: {
    IndicadorAtendimentoPorTI,
    IndicadorPorSede,
    IndicadorPorStatus,
    IndicadorEncerradosUltimosDias,
    IndicadorTempoCategoria,
    IndicadorPorCategoria,
    IndicadorPorSubcategoria,
    IndicadorReabertosPorCategoria,
    IndicadorPorPrioridades,
  },

  props: {
    dadosFiltro: VueTypes.object.isRequired,
  },
  watch: {
    dadosFiltro(val) {
      this.populaDados(val);
    },
  },
  mounted() {
    this.populaDados(this.dadosFiltro);
  },

  data() {
    return {
      atendenteTipo: getMaxPermission(this.$store),
      setorTi: setores.TI,
      setorJuridico: setores.juridico,

      // Indicador Area Atuação
      atendimentosPorTI: [],
      TIOrdenadas: [],
      exibeIndicadorPorTI: false,
      updateAtendimentosPorTI: 0,
      loaderTI: false,

      // Atendimentos por Sedes
      exibeIndicadorPorSede: false,
      atendimentosPorSede: [],
      sedesOrdenadas: [],
      updateAtendimentosPorSede: 0,
      loaderSede: false,

      // Atendimentos por Status
      exibeIndicadorAtendimentoPorStatus: false,
      atendimentosPorStatus: [],
      statusOrdenados: [],
      totalAbertos: 0,
      totalEncerrados: 0,
      updateAtendimentosPorStatus: 0,
      loaderStatus: false,

      // Atendimentos Encerrados nos ultimos 14 dias
      exibeIndicadorUltimoDia: false,
      dadosUltimosDias: '',
      updateEncerradosUltimosDias: 0,
      loaderUltimosDias: false,

      // Tempo médio de atendimento por categoria por dia
      exibeIndicadorTempoCategoria: false,
      tempoMedioCategorias: [],
      categoriasOrdenadasTempo: [],
      updateAtendimentosTempoCategoria: 0,
      loaderTempoCategoria: false,


      // Quantiade de atendimentos abertos por categoria
      exibeIndicadorPorCategoria: false,
      atendimentosPorCategoria: [],
      categoriasOrdenadas: [],
      updateAtendimentosPorCategoria: 0,
      loaderCategoria: false,

      // Quantidade de atendimentos abertos por subcategoria
      exibeIndicadorPorSubcategoria: false,
      atendimentosPorSubcategoria: [],
      subcategoriasOrdenadas: [],
      updateAtendimentosPorSubcategoria: 0,
      loaderSubcategoria: false,

      // Quantidade de atendimentos reabertos por categoria
      exibeIndicadorReabertosCategoria: false,
      categoriasOrdenadasReabertas: [],
      reabertosPorCategoria: [],
      updateReabertosPorCategoria: 0,
      loaderReabertosCategoria: false,

      // Atendimentos por Prioridades
      exibeIndicadorAtendimentoPorPrioridades: false,
      atendimentosPorPrioridades: [],
      valoresPorPrioridades: [],
      updateAtendimentosPorPrioridades: 0,
      loaderPrioridades: false,
    };
  },

  methods: {
    populaDados(dados) {
      this.indicadorAtendimentoPorTI(dados);
      this.indicadorAtendimentoPorSede(dados);
      this.indicadorAtendimentoPorStatus(dados);
      this.indicadorEncerradosUltimosDias(dados);
      this.indicadorTempoCategoria(dados);
      this.indicadorAtendimentoPorCategoria(dados);
      this.indicadorAtendimentoPorSubcategoria(dados);
      this.indicadorReabertosPorCategoria(dados);
      this.indicadorAtendimentoPorPrioridades(dados);
    },

    listCreator(listaKeyValue) {
      const listaKey = [];
      const listaValue = [];

      listaKeyValue.forEach((item) => {
        listaKey.push(item.nome);
        listaValue.push(item.count);
      });

      return [listaKey, listaValue];
    },

    async indicadorAtendimentoPorTI(dadosFiltro) {
      try {
        this.loaderTI = true;
        const { data: response } = await this.$http.post('/indicadores/filtro-indicador-ti', dadosFiltro);

        this.atendimentosPorTI = response.count;
        this.TIOrdenadas = response.nome;

        this.exibeIndicadorPorTI = true;
        this.updateAtendimentosPorTI += 1;
        this.loaderTI = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorAtendimentoPorSede(dadosFiltro) {
      try {
        this.loaderSede = true;
        const { data: response } = await this.$http.post('/indicadores/atendimentos-por-sede', dadosFiltro);

        const [nome, count] = this.listCreator(response);

        this.atendimentosPorSede = count;
        this.sedesOrdenadas = nome;

        this.exibeIndicadorPorSede = true;
        this.updateAtendimentosPorSede += 1;
        this.loaderSede = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorAtendimentoPorStatus(dadosFiltro) {
      try {
        this.loaderStatus = true;
        const { data: response } = await this.$http.post('/indicadores/atendimentos-por-status', dadosFiltro);

        const [nome, count] = this.listCreator(response.atendimentos);

        this.atendimentosPorStatus = count;
        this.statusOrdenados = nome;
        this.totalAbertos = response.totalAbertos;
        this.totalEncerrados = response.totalEncerrados;

        this.exibeIndicadorAtendimentoPorStatus = true;
        this.updateAtendimentosPorStatus += 1;
        this.loaderStatus = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorAtendimentoPorPrioridades(dadosFiltro) {
      try {
        this.loaderPrioridades = true;
        const { data: response } = await this.$http.post('/indicadores/atendimentos-por-prioridades', dadosFiltro);

        const [nome, count] = this.listCreator(response);

        this.valoresPorPrioridades = count;
        this.atendimentosPorPrioridades = nome;

        this.exibeIndicadorAtendimentoPorPrioridades = true;
        this.updateAtendimentosPorPrioridades += 1;
        this.loaderPrioridades = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorEncerradosUltimosDias(dadosFiltro) {
      try {
        this.loaderUltimosDias = true;
        const { data: response } = await this.$http.post('/indicadores/indicador-ultimos-dias', dadosFiltro);

        this.dadosUltimosDias = response;
        this.exibeIndicadorUltimoDia = true;
        this.updateEncerradosUltimosDias += 1;
        this.loaderUltimosDias = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorTempoCategoria(dadosFiltro) {
      try {
        this.loaderTempoCategoria = true;
        const { data: response } = await this.$http.post('/indicadores/tempo-medio-categoria', dadosFiltro);
        const [nome, count] = this.listCreator(response);

        this.tempoMedioCategorias = count;
        this.categoriasOrdenadasTempo = nome;

        this.exibeIndicadorTempoCategoria = true;
        this.updateAtendimentosTempoCategoria += 1;
        this.loaderTempoCategoria = false;
      } catch (error) {
        console.error = error;
      }
    },

    async indicadorAtendimentoPorCategoria(dadosFiltro) {
      try {
        this.loaderCategoria = true;
        const { data: response } = await this.$http.post('/indicadores/atendimentos-por-categoria', dadosFiltro);

        const [nome, count] = this.listCreator(response);

        this.atendimentosPorCategoria = count;
        this.categoriasOrdenadas = nome;

        this.exibeIndicadorPorCategoria = true;
        this.updateAtendimentosPorCategoria += 1;
        this.loaderCategoria = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorAtendimentoPorSubcategoria(dadosFiltro) {
      try {
        this.loaderSubcategoria = true;
        const { data: response } = await this.$http.post('/indicadores/atendimentos-por-subcategoria', dadosFiltro);

        const [nome, count] = this.listCreator(response);

        this.atendimentosPorSubcategoria = count;
        this.subcategoriasOrdenadas = nome;
        this.exibeIndicadorPorSubcategoria = true;
        this.updateAtendimentosPorSubcategoria += 1;
        this.loaderSubcategoria = false;
      } catch (error) {
        console.error(error);
      }
    },

    async indicadorReabertosPorCategoria(dadosFiltro) {
      try {
        this.loaderReabertosCategoria = true;
        const { data: response } = await this.$http.post('/indicadores/reabertos-por-categoria', dadosFiltro);

        this.reabertosPorCategoria = [response.listaNaoReabertos, response.listaReabertos];
        this.categoriasOrdenadasReabertas = response.listKeys;

        this.exibeIndicadorReabertosCategoria = true;
        this.updateReabertosPorCategoria += 1;
        this.loaderReabertosCategoria = false;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.indicadores {
  display: flex;
  justify-content: center;
  max-width: 1250px;
}
</style>
