<template>
  <v-timeline
    :align-top="true"
    :dense="true"
  >
    <v-dialog
      v-model="dialogFluxo"
      max-width="350"
    >
      <v-card>
        <v-card-title class="headline">Atenção!</v-card-title>
        <v-card-text>
          <strong>Não é permitido a reabertura do seu atendimento</strong>.
        </v-card-text>
        <v-card-text>
          Seu atendimento foi encerrado há mais de 5 dias,
           por isso não é possível reabri-lo novamente.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="btn-dialog mr-2 mb-2"
            text
            @click="dialogFluxo = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-timeline-item
      right
      icon="mdi-checkbox-marked-outline"
      color="white"
    >
      <CardComentario
        :titulo="atendimento.status === 'Aguardando atendente' ?
          'Aguardando responsável' : atendimento.status"
        :subcategoria="atendimento.subcategoria"
        :descricao="atendimento.descricao | lineBreak"
        :dadosFluxo="atendimento.dados_fluxo || {}"
        :data="atendimento.data_criacao | formatDate"
        :fluxoId="atendimento.id_fluxo"
        :usuario="atendenteTipo === 1 ? true : false"
        :click="encerraAtendimento"
        tipo="atendente"
        :tipoBotao="hasStatusEncerrado ? 'disabled' : 'secondary'"
      >
        <template v-slot:fluxoLabelsRespostas>
          <FluxoLabelsRespostas
            :camposFluxo="camposFluxo"
            :fluxoId="atendimento.id_fluxo"
            :atendimentoId="id_atendimento"
            :statusAtendimento="atendimento.status"
          />
        </template>
        <template v-slot:formDesligamento>
          <FormDesligamento
            :camposFluxo="camposFluxo"
            :dadosFluxo="atendimento.dados_fluxo"
            :fluxoId="atendimento.id_fluxo"
            :atendimentoId="id_atendimento"
            :statusAtendimento="atendimento.status"
          />
        </template>
        <template v-slot:formComprasTecnicasNacionais>
          <FormComprasTecnicasNacionais
            :camposFluxo="camposFluxo"
            :fluxoId="atendimento.id_fluxo"
            :atendimentoId="id_atendimento"
            :statusAtendimento="atendimento.status"
            @confirmacaoRecebimento="sendNotify($event)"
          />
        </template>
        <template v-slot:formComprasTecnicasInternacionais>
          <FormComprasTecnicasInternacionais
            :camposFluxo="camposFluxo"
            :fluxoId="atendimento.id_fluxo"
            :atendimentoId="id_atendimento"
            :statusAtendimento="atendimento.status"
          />
        </template>
        <template v-slot:formComprasOrdinarias>
          <FormComprasOrdinarias
            :camposFluxo="camposFluxo"
            :fluxoId="atendimento.id_fluxo"
            :atendimentoId="id_atendimento"
            :statusAtendimento="atendimento.status"
            @confirmacaoRecebimento="sendNotify($event)"
          />
        </template>
        <template v-slot:formComprasFixas>
          <FormComprasFixas
            :camposFluxo="camposFluxo"
            :fluxoId="atendimento.id_fluxo"
            :atendimentoId="id_atendimento"
            :statusAtendimento="atendimento.status"
          />
        </template>
        <template v-slot:formComprasEPS>
          <FormComprasEPS
            :camposFluxo="camposFluxo"
            :fluxoId="atendimento.id_fluxo"
            :atendimentoId="id_atendimento"
            :statusAtendimento="atendimento.status"
          />
        </template>
        <template v-slot:formRealocacao>
          <FormRealocacao
            :camposFluxo="camposFluxo"
            :fluxoId="atendimento.id_fluxo"
            :atendimentoId="id_atendimento"
            :statusAtendimento="atendimento.status"
          />
        </template>
        <template v-slot:formModificarFornecedor>
          <FormModificarFornecedor
            :camposFluxo="camposFluxo"
            :fluxoId="atendimento.id_fluxo"
            :atendimentoId="id_atendimento"
            :statusAtendimento="atendimento.status"
          />
        </template>
        <template v-slot:formCriarFornecedoresDadosMestres>
          <FormCriarFornecedoresDadosMestres
            :camposFluxo="camposFluxo"
            :fluxoId="atendimento.id_fluxo"
            :atendimentoId="id_atendimento"
            :statusAtendimento="atendimento.status"
          />
        </template>
        <template v-slot:formCriarClientesDadosMestres>
          <FormCriarClientesDadosMestres
            :camposFluxo="camposFluxo"
            :fluxoId="atendimento.id_fluxo"
            :atendimentoId="id_atendimento"
            :statusAtendimento="atendimento.status"
          />
        </template>
        <template v-slot:formCriarMaterialServico>
          <FormCriarMaterialServico
            :camposFluxo="camposFluxo"
            :fluxoId="atendimento.id_fluxo"
            :atendimentoId="id_atendimento"
            :statusAtendimento="atendimento.status"
          />
        </template>
        <template v-slot:formModificarCliente>
          <FormModificarCliente
            :camposFluxo="camposFluxo"
            :fluxoId="atendimento.id_fluxo"
            :atendimentoId="id_atendimento"
            :statusAtendimento="atendimento.status"
          />
        </template>
        <template v-slot:formCriarServicoCliente>
          <FormCriarServicoCliente
            :camposFluxo="camposFluxo"
            :fluxoId="atendimento.id_fluxo"
            :atendimentoId="id_atendimento"
            :statusAtendimento="atendimento.status"
          />
        </template>
        <template v-slot:arquivos>
          <CardArquivo :atendimento_id="getAtendimentoId" />
        </template>
      </CardComentario>
    </v-timeline-item>

    <v-timeline-item
      v-for="(item, index) in statusHistorico"
      :key="index"
      right
      icon="mdi-reload"
      color="white"
    >
      <template v-slot:icon>
        <v-avatar v-if="item.comentario">
          <img
            v-if="item.avatar"
            :src="item.avatar"
            alt="avatar"
          >
          <span
            v-else
            class="avatar-iniciais"
          >
            {{ nomeIniciais(item.nome) }}
          </span>
        </v-avatar>
      </template>

      <div
        class="col-12"
        v-if="!item.comentario && item.tipo_alteracao!=='EDICAO'
          && item.tipo_alteracao!=='CONFIRMACAO-RECEBIMENTO'"
      >
        <CardStatus
          :responsavel="item.responsavel"
          :titulo="alteraTipo(item)"
          :subcategoria="item.valor_antigo_desc ? `${item.valor_antigo_desc}` : ''"
          :descricao="`${item.valor_novo_desc}`"
          :data="item.data_criacao | formatDate"
          :tipo="atendenteTipo === 1 ? 'usuario' : 'atendente'"
          :email="email"
        />
      </div>


      <div
        class="col-12"
        v-if="item.comentario && item.tipo_alteracao!=='EDICAO'"
      >
        <CardComentario
          :titulo="
            atendenteTipo >= 2 ? `Respondido por ${item.nome}` : `Retorno por ${item.nome}`
          "
          :descricao="item.comentario"
          :dadosFluxo={}
          :data="item.data_criacao | formatDate"
          :tipoComentario="item.publico ? 'publico' : 'privado'"
        >
          <template v-slot:arquivos>
            <CardArquivo
              :atendimento_id="getAtendimentoId"
              :comentario_id="item.id"
            />
          </template>
        </CardComentario>
      </div>


      <div
        v-if="item.tipo_alteracao==='EDICAO'"
        class="col-12"
      >
        <CardEdicaoFluxo
          :usuario="item.responsavel"
          :data="item.data_criacao | formatDate"
          :item="item"
        />
      </div>

      <div
        v-if="item.tipo_alteracao==='CONFIRMACAO-RECEBIMENTO'"
        class="col-12"
      >
        <CardEdicaoFluxoConfirmacao
          :usuario="item.responsavel"
          :data="item.data_criacao | formatDate"
          :item="item"
        />
      </div>
    </v-timeline-item>
    <v-timeline-item
      v-if="(hasStatusEncerrado && !reaberto) || (usuario >= 2 && hasStatusEncerrado)"
      right
      icon="mdi-open-in-new"
      color="white"
    >
      <Botao
        label="Reabrir Atendimento"
        tipo="atendimento"
        type="button"
        :callback="toggleModal"
      />
      <v-dialog
        v-model="dialog"
        persistent
        max-width="350"
      >
        <v-card>
          <v-card-title class="headline">Reabertura de atendimento</v-card-title>
          <v-card-text v-if="usuario === 1">
            É possível reabrir um atendimento apenas uma vez.
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <Botao
              class="mr-4 mt-4"
              type="button"
              label="Cancelar"
              tipo="secondary"
              :callback="toggleModal"
            />
            <Botao
              class="mt-4"
              type="button"
              label="Prosseguir"
              :callback="reabreAtendimento"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-timeline-item>
    <v-timeline-item
      v-if="reatribuidoCard"
      right
      icon="mdi-open-in-new"
      color="white"
    >
      <CardReatribuido
        :reatribuido="reatribuido"
        :idSolicitante="idSolicitante"
      />
    </v-timeline-item>
  </v-timeline>
</template>
<script>
import isEmpty from 'lodash/isEmpty';
import VueTypes from 'vue-types';
import CardComentario from './CardComentario.vue';
import CardReatribuido from './CardReatribuido.vue';
import CardStatus from './CardStatus.vue';
import CardArquivo from './CardArquivo.vue';
import CardEdicaoFluxo from './CardEdicaoFluxo.vue';
import CardEdicaoFluxoConfirmacao from './CardEdicaoFluxoConfirmacao.vue';
import Notify from '../mixins/Notify';
import FluxoLabelsRespostas from './fluxos/FluxoLabelsRespostas.vue';
import FormComprasTecnicasNacionais from './fluxos/FormComprasTecnicasNacionais.vue';
import FormComprasTecnicasInternacionais from './fluxos/FormComprasTecnicasInternacionais.vue';
import FormDesligamento from './fluxos/FormDesligamento.vue';
import FormComprasOrdinarias from './fluxos/FormComprasOrdinarias.vue';
import FormComprasFixas from './fluxos/FormComprasFixas.vue';
import FormComprasEPS from './fluxos/FormComprasEPS.vue';
import FormRealocacao from './fluxos/FormRealocacao.vue';
import FormCriarFornecedoresDadosMestres from './fluxos/FormCriarFornecedoresDadosMestres.vue';
import FormCriarClientesDadosMestres from './fluxos/FormCriarClientesDadosMestres.vue';
import FormModificarFornecedor from './fluxos/FormModificarFornecedor.vue';
import FormCriarMaterialServico from './fluxos/FormCriarMaterialServico.vue';
import FormModificarCliente from './fluxos/FormModificarCliente.vue';
import FormCriarServicoCliente from './fluxos/FormCriarServicoCliente.vue';
import nomeIniciais from '../mixins/nomeIniciais.js';
import Botao from './Botao.vue';
import { status } from '../Constants.js';
import { getMaxPermission } from '../utils/utilities';

export default {
  name: 'AtendimentoTimeline',
  components: {
    CardComentario,
    CardReatribuido,
    CardStatus,
    CardArquivo,
    CardEdicaoFluxo,
    CardEdicaoFluxoConfirmacao,
    FluxoLabelsRespostas,
    FormDesligamento,
    FormComprasTecnicasNacionais,
    FormComprasTecnicasInternacionais,
    FormComprasOrdinarias,
    FormComprasFixas,
    FormComprasEPS,
    FormRealocacao,
    FormCriarFornecedoresDadosMestres,
    FormCriarClientesDadosMestres,
    FormModificarFornecedor,
    FormCriarMaterialServico,
    FormModificarCliente,
    FormCriarServicoCliente,
    Botao,
  },
  mixins: [nomeIniciais, Notify],
  data() {
    return {
      usuario: this.$store.getters.isUsuario.id_permissao,
      dialogFluxo: false,
      dialog: false,
      id_atendimento: parseInt(this.$route.params.id, 10),
      reaberto: false,
      avaliacao: true,
      reatribuidoCard: false,
      reatribuido: {},
      statusAguardandoAtendenteId: status.aguardandoAtendente,
      statusEncerradoId: status.encerrado,
      avatarUsuarios: [],
      idSolicitante: null,
    };
  },
  props: {
    camposFluxo: VueTypes.array,
    encerraAtendimento: VueTypes.func,
    atendimento_id: VueTypes.number.isRequired,
    atendimento: VueTypes.object.isRequired,
    statusHistorico: VueTypes.array,
    atendenteTipo: VueTypes.integer.isRequired,
    comentario_id: VueTypes.number,
    email: VueTypes.string,
  },
  computed: {
    getAtendimentoId() {
      return this.atendimento_id;
    },
    isUsuario() {
      return this.atendenteTipo === this.$store.getters.listaPermissoes.colaborador;
    },
    hasStatusEncerrado() {
      return this.atendimento.id_status === status.encerrado;
    },
    hasAtendente() {
      return getMaxPermission(this.$store).id_permissao
        === this.$store.getters.listaPermissoes.atendente;
    },
  },
  filters: {
    lineBreak(value) {
      return value ? value.replace(/\n/g, '<br/>') : null;
    },
  },

  mounted() {
    this.verificaReabertura();
    this.verificaReatribuicao();
  },

  methods: {
    sendNotify(event) {
      this.notificaConfirmacao(
        event,
        this.atendimento.id_usuario,
        this.$store.getters.isUsuario.id,
        this.$route.params.id,
      );
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },
    alteraTipo(item) {
      if (item.tipo_alteracao === 'STATUS') {
        return 'Status atualizado';
      }
      if (item.tipo_alteracao === 'ATRIBUICAO') {
        return 'Atribuído';
      }
      if (item.tipo_alteracao === 'CONVITE SETOR') {
        return 'Convidado setor';
      }
      if (item.tipo_alteracao === 'CONVITE PARTICIPANTE') {
        return 'Convidado usuario';
      }
      if (item.tipo_alteracao === 'STATUS CONVITE') {
        return 'Status convidado atualizado';
      }
      if (item.tipo_alteracao === 'APROVACAO') {
        return 'Solicitação de Aprovação enviada';
      }
      if (item.tipo_alteracao === 'AREA ATUACAO') {
        return 'Área de atuação alterada';
      }
      if (item.tipo_alteracao === 'SOLICITACAO') {
        return 'Solicitação de reajuste de salário';
      }
      return null;
    },
    async verificaAvaliacao() {
      try {
        const { data: response } = await this.$http.get(`/avaliar-atendimento/${this.id_atendimento}`);
        if (isEmpty(response)) {
          this.avaliacao = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async verificaReabertura() {
      try {
        const { data: response } = await this.$http.get(`/atendimento/${this.id_atendimento}`);
        this.reaberto = response.reaberto;
        if (!this.reaberto) {
          await this.verificaAvaliacao();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async verificaReatribuicao() {
      try {
        const { data: response } = await this.$http.get(`/redistribui/${this.id_atendimento}`);
        if (response[0] && response[0].atendenteDestino.id === this.$store.getters.isUsuario.id && response[0].status === 'pendente') {
          this.reatribuido = response;
          this.idSolicitante = this.reatribuido[0].atendenteOrigem.id;
          this.reatribuidoCard = true;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async reabreAtendimento() {
      this.$store.commit('UPDATE_LOADING', true);
      try {
        const reaberto = true;

        if (!this.avaliacao && this.atendenteTipo === 1) this.pularAvaliacaoReabertura();
        const body = {
          reaberto,
          status_id: this.statusAguardandoAtendenteId,
          id_usuario: this.atendimento.id_usuario,
          id_atendimento: parseInt(this.$route.params.id, 10),
          tipo_alteracao: 'STATUS',
          valor_antigo: this.statusEncerradoId,
          valor_novo: this.statusAguardandoAtendenteId,
        };
        await this.$http.put(`/reabrir/atendimento/${this.id_atendimento}`, body);
        this.$store.commit('UPDATE_LOADING', false);
        this.toggleModal();
        this.$router.go();
      } catch (error) {
        this.dialogFluxo = true;
        this.dialog = false;
        this.$store.commit('UPDATE_LOADING', false);
        console.error(error);
      }
    },
    async pularAvaliacaoReabertura() {
      await this.$http.post('/avaliar-atendimento', {
        atendimento_id: parseInt(this.$route.params.id, 10),
        avaliacao: 'Sem Avaliação',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
div {
  padding: 0;
  margin: 0;
}

.v-timeline[noLine]:before {
  width: 0px;
}

.icon {
  width: 50px;
  height: 50px;
  padding: 11px;
  color: #122870 !important;
  border-radius: 50px;
  background: white;
  border: 5px solid white;
  z-index: 1;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}
</style>

<style lang="scss">
.theme--dark.v-icon {
  color: #122870 !important;
}

.v-timeline-item__dot {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25) !important;
}

html .theme--light.v-timeline:before {
  background-color: #122870 !important;
}
.avatar-iniciais {
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.12);
  color: white;
  font-size: 22px;
  font-weight: 500;
  background: #0056a8;
  z-index: 31;
  top: 0px;
  border-radius: 50%;
  transition: all 0.3s;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.colorButton {
  color: #122870;
}
</style>
