<template>
  <div class="card">
    <div
      class="card-header"
      :style="styleFundo"
    >
      <h3 :style="styleTitulo">{{ titulo }}</h3>
    <div>
      <span>
        Total:{{total}}
      </span>
      <span
       @click="reordenar"
      >
        <v-icon
          class="seta"
          :class="{'rotacionar-seta': ordemValue}"
        >
          mdi-arrow-up-bold
        </v-icon>
      </span>
    </div>
    </div>
    <div class="card-content">
      <slot />
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'ColunaStatus',
  props: {
    titulo: VueTypes.string.def('Titulo'),
    corTitulo: VueTypes.string.def('#303030'),
    corFundo: VueTypes.string.def('#ffffff'),
    total: VueTypes.number,
    ordenar: {
      required: false,
      type: Array,
      default: null,
    },
    decrescente: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ordemValue: this.decrescente,
    };
  },
  computed: {
    styleTitulo() {
      return `color: ${this.corTitulo};`;
    },
    styleFundo() {
      return `background-color: ${this.corFundo};`;
    },
  },
  methods: {
    reordenar() {
      this.ordemValue = !this.ordemValue;
      this.ordenar.reverse();
    },
  },
};
</script>

<style lang="scss" scoped>
.seta {
  cursor: pointer;
  &.rotacionar-seta{
    transform: rotate(180deg);
  }
}
.card {
  margin: 10px;
  width: 382px;
  height: 97%;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
}
.card-header {
  height: 33.6px;
  display: flex;
  padding: 10px;
  padding-left: 10px;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  background-color: #ffffff;
}
.card-header h3 {
  margin: 0;
  font-size: 18px;
  color: #303030;
}
.card-content {
  padding: 0 10px;
  overflow-y: auto;
}
</style>
