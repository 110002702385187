<template>
  <div id="FormComprasOrdinarias">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="empresa"
            label="Empresa"
            :class="checkRequiredCSSAlert"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="unidade"
            label="Centro / Unidade"
            :class="checkRequiredCSSAlert"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="fornecedor"
            label="Indicação de Fornecedor"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="material0"
            label="Material/Serviços"
            :class="checkRequiredCSSAlert"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            v-model="qtTotal0"
            label="Quantidade total"
            :class="checkRequiredCSSAlert"
            type="number"
            min=0
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-money="money"
            v-model="orcamento0"
            label="Valor aproximado do Orçamento"
            :class="checkRequiredCSSAlert"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="cc0"
            label="Centro de custo"
            :class="checkRequiredCSSAlert"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="!!material1 || !editar">
        <v-col cols="4">
          <v-text-field
            v-model="material1"
            label="Material/Serviços"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            v-model="qtTotal1"
            label="Quantidade total"
            type="number"
            min=0
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-money="money"
            v-model="orcamento1"
            label="Valor aproximado do Orçamento"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="cc1"
            label="Centro de custo"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="!!material2 || !editar">
        <v-col cols="4">
          <v-text-field
            v-model="material2"
            label="Material/Serviços"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            v-model="qtTotal2"
            label="Quantidade total"
            type="number"
            min=0
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-money="money"
            v-model="orcamento2"
            label="Valor aproximado do Orçamento"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="cc2"
            label="Centro de custo"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="!!material3 || !editar">
        <v-col cols="4">
          <v-text-field
            v-model="material3"
            label="Material/Serviços"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            v-model="qtTotal3"
            label="Quantidade total"
            type="number"
            min=0
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-money="money"
            v-model="orcamento3"
            label="Valor aproximado do Orçamento"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="cc3"
            label="Centro de custo"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-textarea
            v-model="endereco"
            label="Endereço de Entrega"
            outlined
            dense
            auto-grow
            rows="2"
            :disabled="editar ? true : false"
          ></v-textarea>
        </v-col>

      </v-row>

      <v-row>
        <v-col cols="6">
          <v-textarea
            v-model="responsavel"
            label="Responsável pelo recebimento"
            outlined
            :class="checkRequiredCSSAlert"
            dense
            auto-grow
            rows="1"
            :disabled="editar ? true : false"
          ></v-textarea>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="confirmacaoRecebimento"
            label="Confirmação de Recebimento"
            class="confirmacao-recebimento"
            @change="registraUsuarioMarcacao"
            :disabled="editar ? true : false"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-btn
        :class="{ 'btn-primary-disabled ml-0 mt-2': isStatusEncerrado,
                   'btn-primary ml-0 mt-2': !isStatusEncerrado }"
        @click="toggleEdit"
        :disabled="isStatusEncerrado">
        <span v-if="editar">
          <v-icon left>mdi-pencil</v-icon>Editar
        </span>
        <span v-else>
          <v-icon left>mdi-close</v-icon>Cancelar
        </span>
      </v-btn>

      <v-btn
        v-if="!editar"
        class="btn-primary ml-2 mt-2"
        @click="salvarFluxoEditado"
      >
        <v-icon left>mdi-content-save</v-icon>Salvar
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { VMoney } from 'v-money';
import Snackbar from '../Snackbar';

export default {
  name: 'FormComprasOrdinarias',
  components: { Snackbar },
  directives: { money: VMoney },

  props: {
    camposFluxo: VueTypes.array,
    fluxoId: VueTypes.integer,
    atendimentoId: VueTypes.integer,
    statusAtendimento: VueTypes.string,
  },

  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },

      fornecedor: '',

      material0: '',
      material1: '',
      material2: '',
      material3: '',

      qtTotal0: '',
      qtTotal1: '',
      qtTotal2: '',
      qtTotal3: '',

      orcamento0: '',
      orcamento1: '',
      orcamento2: '',
      orcamento3: '',

      cc0: '',
      cc1: '',
      cc2: '',
      cc3: '',

      pedidos: [
        {
          material: '', qtTotal: '', orcamento: '', cc: '',
        },
        {
          material: '', qtTotal: '', orcamento: '', cc: '',
        },
        {
          material: '', qtTotal: '', orcamento: '', cc: '',
        },
        {
          material: '', qtTotal: '', orcamento: '', cc: '',
        },
      ],
      empresa: '',

      unidade: '',

      endereco: '',

      responsavel: '',

      confirmacaoRecebimento: '',

      editar: true,
      disabled: true,
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },
  created() {
    this.loadInfoCamposFluxo();
  },

  computed: {
    isStatusEncerrado() {
      return this.statusAtendimento === 'Encerrado';
    },

    checkRequiredCSSAlert() {
      return this.editar ? '' : 'alert-fild-required';
    },

    verificaCampos() {
      return (!!this.empresa
      && !!this.unidade
      && !!this.material0
      && !!this.qtTotal0
      && !!this.orcamento0
      && !!this.cc0
      && !!this.responsavel);
    },
  },
  methods: {
    registraUsuarioMarcacao() {
      this.$emit('confirmacaoRecebimento', this.confirmacaoRecebimento);
    },
    loadInfoCamposFluxo() {
      this.empresa = this.camposFluxo[0].resposta;
      this.unidade = this.camposFluxo[1].resposta;
      this.fornecedor = this.camposFluxo[2].resposta;
      this.material0 = this.camposFluxo[3].resposta;
      this.qtTotal0 = this.camposFluxo[4].resposta;
      this.orcamento0 = this.camposFluxo[5].resposta;
      this.cc0 = this.camposFluxo[6].resposta;
      this.material1 = this.camposFluxo[7].resposta;
      this.qtTotal1 = this.camposFluxo[8].resposta;
      this.orcamento1 = this.camposFluxo[9].resposta;
      this.cc1 = this.camposFluxo[10].resposta;
      this.material2 = this.camposFluxo[11].resposta;
      this.qtTotal2 = this.camposFluxo[12].resposta;
      this.orcamento2 = this.camposFluxo[13].resposta;
      this.cc2 = this.camposFluxo[14].resposta;
      this.material3 = this.camposFluxo[15].resposta;
      this.qtTotal3 = this.camposFluxo[16].resposta;
      this.orcamento3 = this.camposFluxo[17].resposta;
      this.cc3 = this.camposFluxo[18].resposta;
      this.endereco = this.camposFluxo[19].resposta;
      this.responsavel = this.camposFluxo[20].resposta;
      this.confirmacaoRecebimento = this.camposFluxo[21].resposta;
    },

    toggleEdit() {
      this.editar = !this.editar;
      this.disabled = !this.disabled;
    },

    async salvarFluxoEditado() {
      if (!this.verificaCampos) {
        this.alerta = 'error';
        this.msg = 'Existem campos obrigatórios que não foram preenchidos';
        this.snackBarAlertaCampos = true;
      }
      if (this.verificaCampos) {
        this.toggleEdit();

        this.alerta = 'success';
        this.msg = 'Formulário editado com sucesso!';
        this.snackBarAlertaCampos = true;

        const body = {
          id: this.atendimentoId,
          campos_fluxo: {
            column_campo_1: this.empresa,
            column_campo_2: this.unidade,
            column_campo_3: this.fornecedor,
            column_campo_4: this.material0,
            column_campo_5: this.qtTotal0,
            column_campo_6: this.orcamento0,
            column_campo_7: this.cc0,
            column_campo_8: this.material1,
            column_campo_9: this.qtTotal1,
            column_campo_10: this.orcamento1,
            column_campo_11: this.cc1,
            column_campo_12: this.material2,
            column_campo_13: this.qtTotal2,
            column_campo_14: this.orcamento2,
            column_campo_15: this.cc2,
            column_campo_16: this.material3,
            column_campo_17: this.qtTotal3,
            column_campo_18: this.orcamento3,
            column_campo_19: this.cc3,
            column_campo_20: this.endereco,
            column_campo_21: this.responsavel,
            column_campo_22: this.confirmacaoRecebimento,
          },
          responsavel: this.$store.getters.isUsuario.id,
          fluxo_id: this.fluxoId,
        };

        await this.$http.put('/atendimentos/editar/dados-fluxo', body);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

#FormComprasOrdinarias {
  display: flex;
  margin-left: 8px;
  margin-right: 12px;
}

.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}
.alert-fild-required::after {
    content: "*";
    color: red;
    margin-bottom: 10px;
    margin-left: 6px;
    font-size: x-small;
}

.btn-primary {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border: 1px solid #0056a8 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}
.confirmacao-recebimento {
  padding-top: 0px;
}
.btn-primary-disabled {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.count-warning {
  background-color: transparent;
  color: red;
}
</style>
