<template>
  <v-snackbar
    :timeout="timeout"
    v-model="snackbarValue"
    :color="color"
    :top="top"
    :right="right"
    :left="left"
    :bottom="bottom"
    :multi-line="multiline"
  >
    <div
      class="snackbar-border"
      :[tipo]="true"
      :multiline="multiline"
    >
    </div>
    <v-icon
      :color="corIcone"
      left
    >{{ mdiIcon }}</v-icon>
    {{ label }}
  </v-snackbar>
</template>

<script>
import VueTypes from 'vue-types';

export default {
  name: 'Snackbar',
  data() {
    return {
      corIcone: '',
      mdiIcon: '',
      borderColor: '',
      snackbarValue: this.value,
    };
  },
  props: {
    multiline: VueTypes.bool.def(false),
    value: VueTypes.bool.def(false),
    absolute: VueTypes.bool.def(false),
    timeout: VueTypes.integer.def(6000),
    top: VueTypes.bool.def(false),
    bottom: VueTypes.bool.def(false),
    left: VueTypes.bool.def(false),
    right: VueTypes.bool.def(false),
    label: VueTypes.string.isRequired,
    color: VueTypes.oneOf(['white']).def('white'),
    tipo: VueTypes.oneOf(['info', 'warn', 'error', 'success']).isRequired,
  },
  mounted() {
    this.verifyType();
  },
  updated() {
    this.verifyType();
  },
  methods: {
    verifyType() {
      switch (this.tipo) {
        case 'info':
          this.corIcone = '#00A6DF';
          this.mdiIcon = 'mdi-information';
          break;
        case 'warn':
          this.corIcone = '#FFE500';
          this.mdiIcon = 'mdi-alert';
          break;
        case 'error':
          this.corIcone = '#990000';
          this.mdiIcon = 'mdi-close-circle';
          break;
        case 'success':
          this.corIcone = '#17c301';
          this.mdiIcon = 'mdi-check-circle';
          break;
        default:
          break;
      }
    },
  },
  watch: {
    value(newValue) {
      this.snackbarValue = newValue;
    },
    snackbarValue(newValue) {
      this.$emit('input', newValue);
    },
  },
};
</script>

<style lang="scss">
html .v-snack {
  top: 94px;
}
html .v-snack__content {
  display: flex;
  justify-content: left;
  color: #000;
  border-radius: 2px;
}
html .v-snack__wrapper {
  pointer-events: none;
}
.snackbar-border {
  border-radius: 2px 0 0 2px;
  position: absolute;
  margin: 0 0 0 -16px;
  height: 48px;
}
.snackbar-border[multiline] {
  height: 68px;
}
.snackbar-border[error] {
  margin-top: -10px;
  border-left: 6px solid #990000 !important;
}
.snackbar-border[info] {
  margin-top: -10px;
  border-left: 6px solid #00a6df !important;
}
.snackbar-border[warn] {
  margin-top: -10px;
  border-left: 6px solid #ffe500 !important;
}
.snackbar-border[success] {
  margin-top: -10px;
  border-left: 6px solid #17c301 !important;
}
</style>
