<template>
  <div id="cadastroSugestoes">
    <Snackbar
      :label="msg"
      v-model="snackbarOpen"
      top
      right
      :tipo="tipoSnackbar"
      :timeout="2500"
    />
    <v-data-table
      show-expand
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="descricao"
      :headers="headers"
      :items="sugestoes"
      :search="searchUser"
      :sort-by="['categoria_nome', 'subcategoria_nome']"
      :items-per-page="5"
      :no-data-text="'Não há sugestões cadastradas.'"
      :no-results-text="'Sugestão não encontrada.'"
      :footer-props="{
        'items-per-page-text': 'Resultados por página',
        'items-per-page-all-text': 'Todos',
        'page-text': '{0}-{1} de {2}',
    }"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td
          v-if="item.arquivos.length"
          class="pa-2"
          :colspan="headers.length"
        >
          <v-simple-table>
            <thead>
              <tr>
                <th scope="col">Anexo(s)</th>
                <th scope="col">Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(i, index) in item.arquivos"
                :key="index"
              >
                <td>
                  <v-icon
                    small
                    class="mr-1"
                  >mdi-paperclip</v-icon>{{ i[2] }}
                </td>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        dark
                        v-on="on"
                        @click.stop="deleteArquivo(i[0])"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Remover</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </td>
      </template>
      <template v-slot:top>
        <v-text-field
          v-model="searchUser"
          label="Pesquise uma categoria/subcategoria"
          outlined
          clearable
        >
          <v-icon
            v-if="!searchUser"
            slot="append"
          >mdi-account-search</v-icon>
        </v-text-field>
        <v-toolbar
          flat
          color="white"
        >
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                class="v-btn_add"
                v-on="on"
              >Cadastrar nova</v-btn>
            </template>
            <v-card class="px-2">
              <v-card-title class="headline">{{ formTitle }}</v-card-title>
              <v-divider></v-divider>
              <v-card-subtitle class="mt-1">
                <v-select
                  v-if="permissaoGestor.id_permissao === gestorGeral"
                  label="Setor"
                  v-model="setor"
                  :items="setores"
                  outlined
                  dense
                  @change="listaCategorias"
                ></v-select>
              </v-card-subtitle>
              <v-card-subtitle
                class="mt-1"
                v-if="setor || permissaoGestor.id_permissao === gestor"
              >
                <v-select
                  label="Categoria"
                  v-model="categoria"
                  :items="categoriasFiltradas"
                  outlined
                  dense
                  @change="getSubcategoria"
                ></v-select>
              </v-card-subtitle>
              <v-card-subtitle
                v-if="categoria"
                class="mt-1"
              >
                <v-select
                  v-if="categoria"
                  label="Subcategoria"
                  v-model="subcategoria"
                  :items="subcategorias"
                  outlined
                  dense
                ></v-select>
              </v-card-subtitle>
              <v-card-subtitle v-if="categoria && subcategoria">Mensagem
                <EditorTexto
                  :mensagem="mensagem"
                  :edited="mensagem"
                  table
                />
                <InputFile
                  @hasError="updateFileError"
                  ref="inputfile"
                />
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <Botao
                  class="mr-4"
                  type="button"
                  label="Cancelar"
                  tipo="secondary"
                  :callback="closeModal"
                />
                <Botao
                  type="submit"
                  label="Salvar"
                  :tipo="verificarFormulario"
                  :callback="saveCadastro"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogDelete"
            scrollable
            max-width="500"
          >
            <v-card class="px-2">
              <v-card-title class="headline">{{ deleteTitle }}</v-card-title>
              <v-card-subtitle class="mt-1">
                {{ deleteText }}
              </v-card-subtitle>
              <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <Botao
                  class="mr-4"
                  type="button"
                  label="Cancelar"
                  tipo="secondary"
                  :callback="closeModal"
                />
                <Botao
                  type="button"
                  label="Confirmar"
                  :callback="deleteCadastro"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.categoria_nome="{item}">
        {{ item.setor_nome }}
      </template>
      <template v-slot:item.categoria_nome="{item}">
        {{ item.categoria_nome }}
      </template>
      <template v-slot:item.subcategoria_nome="{item}">
        {{ item.subcategoria_nome }}
      </template>
      <template v-slot:item.descricao="{item}">
        <div
          class="descricao"
          v-if="item.descricao"
          v-html="item.descricao"
        >
        </div>
        <span v-else>-</span>
      </template>
      <template v-slot:item.arquivos="{item}">
        <span v-if="item.arquivos.length > 0">
          <div
            v-for="(i, index) in item.arquivos.slice(0, 1)"
            :key="index"
          >
            <v-icon
              small
              class="mr-1"
            >mdi-paperclip</v-icon>{{ i[2] }}
          </div>
        </span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.acoes="{item}">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              dark
              class="mr-2"
              v-on="on"
              @click.stop="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              dark
              v-on="on"
              @click.stop="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Remover</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getMaxPermission } from '../utils/utilities';
import Botao from './Botao.vue';
import Snackbar from './Snackbar.vue';
import InputFile from './InputFile.vue';
import EditorTexto from './EditorTexto.vue';

export default {
  name: 'cadastroSugestoes',
  components: {
    Botao,
    Snackbar,
    InputFile,
    EditorTexto,
  },
  data() {
    return {
      fileError: null,
      snackbarOpen: false,
      tipoSnackbar: 'info',
      msg: '',
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      deleteArq: false,
      id: '',
      setor: '',
      categoria: '',
      subcategoria: '',
      mensagem: '',
      searchUser: '',
      sugestoes: [],
      setores: [],
      categorias: [],
      categoriasFiltradas: [],
      subcategorias: [],
      expanded: [],
      headers: [],
      gestor: this.$store.getters.listaPermissoes.gestor,
      gestorGeral: this.$store.getters.listaPermissoes.gestorgeral,
      permissaoGestor: getMaxPermission(this.$store),
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Cadastrar sugestão' : 'Editar sugestão';
    },
    deleteTitle() {
      return this.deleteArq === true ? 'Remover arquivo' : 'Remover sugestão';
    },
    deleteText() {
      return this.deleteArq === true ? 'Deseja realmente remover o arquivo?' : 'Deseja realmente remover a sugestão?';
    },
    verificarFormulario() {
      return (this.categoria
        && this.subcategoria
        && this.verificarMensagem()) ? 'primary' : 'disabled';
    },
  },
  watch: {
    dialog(val) {
      return val || this.closeModal();
    },
  },
  async mounted() {
    try {
      this.verifyHeadersPermission();
      await this.getSetores();
      if (this.permissaoGestor.id_permissao === this.gestor) this.listaCategorias();
      await this.listaSugestoes();
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    verifyHeadersPermission() {
      if (this.permissaoGestor.id_permissao === this.gestor) {
        this.headers = [
          { text: 'Categoria', value: 'categoria_nome' },
          { text: 'Subcategoria', value: 'subcategoria_nome' },
          { text: 'Descrição', value: 'descricao' },
          { text: 'Anexos', value: 'arquivos' },
          { text: 'Ações', value: 'acoes', sortable: false },
          { text: '', value: 'data-table-expand' },
        ];
      } else if (this.permissaoGestor.id_permissao === this.gestorGeral) {
        this.headers = [
          { text: 'Setor', value: 'setor_nome' },
          { text: 'Categoria', value: 'categoria_nome' },
          { text: 'Subcategoria', value: 'subcategoria_nome' },
          { text: 'Descrição', value: 'descricao' },
          { text: 'Anexos', value: 'arquivos' },
          { text: 'Ações', value: 'acoes', sortable: false },
          { text: '', value: 'data-table-expand' },
        ];
      }
    },
    closeModal() {
      this.editedIndex = -1;
      this.dialog = false;
      this.dialogDelete = false;
      this.deleteArq = false;
      this.setor = '';
      this.categoria = '';
      this.subcategoria = '';
      this.mensagem = '';
    },
    async editItem(item) {
      this.editedIndex += 1;
      this.id = item.sugestao_id;
      this.setor = item.setor_id;
      await this.listaCategorias();
      this.categoria = item.categoria_id;
      this.getSubcategoria();
      this.subcategoria = item.subcategoria_id;
      this.mensagem = item.descricao;
      this.dialog = true;
    },
    deleteItem(item) {
      this.id = item.sugestao_id;
      this.dialogDelete = true;
    },
    deleteArquivo(item) {
      this.id = item;
      this.deleteArq = true;
      this.dialogDelete = true;
    },
    snackbarType(tipo) {
      this.tipoSnackbar = tipo;
      this.snackbarOpen = true;
    },
    async listaSugestoes() {
      try {
        const { data: response } = await this.$http.get('/mensagens-sugestoes');
        const sugestoesTratadas = this.setSetorInItem(response);
        this.sugestoes = sugestoesTratadas.map((item) => {
          const info = {};
          info.sugestao_id = item.id;
          info.setor_id = item.categoria.setor_id;
          info.setor_nome = item.setor_nome;
          info.categoria_id = item.categoria.id;
          info.categoria_nome = item.categoria.nome;
          info.subcategoria_id = item.subcategoria.id;
          info.subcategoria_nome = item.subcategoria.nome;
          info.descricao = item.mensagem;
          info.arquivos = item.arquivos.map(a => [a.id, a.caminho.split('arquivos/').slice(-1)[0], a.nome]);
          return info;
        });
        if (this.permissaoGestor.id_permissao === this.gestor) {
          this.sugestoes = this.sugestoes.filter(item => item.setor_id
          === this.permissaoGestor.id_setor);
        }
      } catch (error) {
        console.error(error);
      }
    },
    setSetorInItem(element) {
      return element.map((item) => {
        let setorNome;
        this.setores.forEach((setor) => {
          if (setor.value === item.categoria.setor_id) setorNome = setor.text;
        });
        return { ...item, setor_nome: setorNome };
      });
    },
    async getSetores() {
      try {
        const { data: response } = await this.$http.get('/setores');
        this.setores = response.map(item => ({
          value: item.id,
          text: item.nome,
        }));
        if (this.permissaoGestor.id_permissao === this.gestor) {
          this.setor = this.permissaoGestor.id_setor;
        }
      } catch (error) {
        console.error(error);
      }
    },
    limpaSelecoes() {
      this.categoria = '';
      this.subcategoria = '';
    },
    async listaCategorias() {
      try {
        const { data: response } = await this.$http.get('/categorias');
        this.categorias = response.map(item => ({
          value: item.id,
          text: item.nome,
          setor_id: item.setor_id,
        }));
        this.categoriasFiltradas = this.categorias.filter(
          categoria => categoria.setor_id === this.setor,
        );

        this.limpaSelecoes();
      } catch (error) {
        console.error(error);
      }
    },
    async getSubcategoria() {
      try {
        this.subcategoria = '';
        const { data: response } = await this.$http.get(`/subcategorias/${this.categoria}`);
        this.subcategorias = response.map(item => ({
          value: item.id,
          text: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async saveCadastro() {
      try {
        const files = this.$refs.inputfile.getFiles();
        const body = {
          categoria_id: this.categoria,
          subcategoria_id: this.subcategoria,
          mensagem: this.$store.getters.comentario,
        };
        if (this.editedIndex > -1) {
          const response = await this.$http.put(`/mensagens-sugestoes/${this.id}`, body);
          if (files.length) {
            await this.sendFiles(response.data.id, files);
          }
          this.msg = 'Cadastro de sugestão alterado com sucesso!';
          this.snackbarType('success');
          this.$refs.inputfile.removeFiles();
          this.closeModal();
          this.listaSugestoes();
        } else {
          const response = await this.$http.post('/mensagens-sugestoes', body);
          if (files.length) {
            await this.sendFiles(response.data.id, files);
          }
          this.msg = 'Cadastro de sugestão criado com sucesso!';
          this.snackbarType('success');
          this.$refs.inputfile.removeFiles();
          this.closeModal();
          this.listaSugestoes();
        }
      } catch (error) {
        console.error = error;
      }
    },
    updateFileError(value) {
      this.fileError = value;
    },
    async sendFiles(id, files) {
      const formData = new FormData();
      for (let index = 0; index < files.length; index += 1) {
        formData.append('envios', files[index]);
      }
      formData.append('categoria_id', this.categoria);
      formData.append('subcategoria_id', this.subcategoria);
      await this.$http.post('/arquivos-sugestoes', formData);
    },
    async deleteCadastro() {
      try {
        if (this.deleteArq) {
          await this.$http.delete(`/arquivos-sugestoes/${this.id}`);
          this.msg = 'Arquivo deletado com sucesso!';
          this.snackbarType('success');
          this.closeModal();
          this.listaSugestoes();
        } else {
          await this.$http.delete(`/mensagens-sugestoes/${this.id}`);
          this.msg = 'Cadastro de sugestão deletado com sucesso!';
          this.snackbarType('success');
          this.closeModal();
          this.listaSugestoes();
        }
      } catch (error) {
        console.error(error);
      }
    },
    verificarMensagem() {
      return (this.$store.getters.comentario
        && this.$store.getters.comentario !== '<p></p>');
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  padding: 10px;
  margin: 0;
  width: 100%;
  height: 125px;
  border-top: 1px solid #bfbfbf;
  border-left: 1px solid #bfbfbf;
  border-right: 1px solid #bfbfbf;
  border-bottom: none;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 0 4.5px 0 rgba(0, 0, 0, 0.25);
  &:focus {
    border: solid 2px #006479;
    box-shadow: 0 0 4.5px 0 rgba(0, 0, 0, 0.25);
  }
}

.v-btn_add {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #ffffff !important;
  border-radius: 5px;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}
</style>

<style lang="scss">
.v-data-table tbody tr.v-data-table__expanded__content {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.descricao {
  strong {
    font-weight: bold;
  }

  p {
    margin: 0px !important;
  }
}
</style>
