<template>
  <div>
    <v-card
      :max-height="600"
      :height="430"
      :width="800"
      :min-width="200"
      :elevation="4"
      class="card-indicador border-circle mt-2 px-3 pt-3 pb-0"
    >
      <v-overlay
        :value="loader"
        :absolute="true"
        :opacity="0.30"
      >
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <div ref="capture">
        <div class="div-cabecalho">
          <span class="span-indicador-avaliacao">
            Indicador de avaliação
          </span>
          <button
            class="botao-download"
            @click="screenShot()"
          ><span class="
              material-icons">
              get_app
            </span>
          </button>
        </div>
        <div class="div-container-grafico">
          <div class="div-barra">
            <div class="emojis emoji-insatisfeito">
              <img
                class="svg-emojis"
                src="../../assets/icons/sad-red.svg"
                alt="Insatisfeito"
              />
            </div>
            <div class="div-barra insatisfeito"></div>
            <div class="div-porcentagem">
              <span class="span-porcentagem">{{dadosAvaliacao[0]}}%</span>
            </div>
          </div>
          <div class="div-barra">
            <div class="emojis emoji-neutro">
              <img
                class="svg-emojis"
                src="../../assets/icons/neutro-orange.svg"
                alt="Neutro"
              />
            </div>
            <div class="div-barra neutro"></div>
            <div class="div-porcentagem">
              <span class="span-porcentagem">{{dadosAvaliacao[1]}}%</span>
            </div>
          </div>
          <div class="div-barra">
            <div class="emojis emoji-satisfeito">
              <img
                class="svg-emojis"
                src="../../assets/icons/happy-green.svg"
                alt="Satisfeito"
              />
            </div>
            <div class="div-barra satisfeito"></div>
            <div class="div-porcentagem">
              <span class="span-porcentagem">{{dadosAvaliacao[2]}}%</span>
            </div>
          </div>
          <div class="div-barra">
            <div class="emojis emoji-nao-avaliado">
              <span class="material-icons">
                highlight_off
              </span>
            </div>
            <div class="div-barra nao-avaliado"></div>
            <div class="div-porcentagem">
              <span class="span-porcentagem">{{dadosAvaliacao[3]}}%</span>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import screenShot from '../../mixins/screenShot.js';

export default {
  name: 'IndicadorAvaliacao',

  mixins: [screenShot],
  props: {
    loader: VueTypes.bool.required,
    dadosAvaliacao: VueTypes.array.isRequired,
  },

  mounted() {
    this.receberDados();
  },

  updated() {
    this.receberDados();
  },

  methods: {
    receberDados() {
      document.documentElement.style.setProperty('--insatisfeito', `${this.dadosAvaliacao[0]}%`);
      document.documentElement.style.setProperty('--neutro', `${this.dadosAvaliacao[1]}%`);
      document.documentElement.style.setProperty('--satisfeito', `${this.dadosAvaliacao[2]}%`);
      document.documentElement.style.setProperty('--naoAvaliado', `${this.dadosAvaliacao[3]}%`);
    },
  },
};
</script>

<style lang="scss" scoped>
:root {
  --insatisfeito: "";
  --neutro: "";
  --satisfeito: "";
  --naoAvaliado: "";
}

.card-indicador {
  border-radius: 20px !important;
}

.div-cabecalho {
  display: flex;
  justify-content: space-between;
  padding-bottom: 120px;
  padding-left: 10px;

  .span-indicador-avaliacao {
    font-weight: bold;
    color: #333;
    font-size: 15px;
  }

  .botao-download {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    border-radius: 5px;
    border: 2px solid #eeeeee;

    &:hover {
      background-color: #e2dede;
    }

    .material-icons {
      color: #0056a8;
      font-size: 25px;
    }
  }
}

.div-container-grafico {
  display: flex;
  justify-content: space-around;
  height: 241px;
  width: 100%;
  padding-bottom: 15px;

  .div-barra {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    width: 50%;
    position: relative;


    &:hover {
      opacity: 0.7;
    }

    .satisfeito {
      height: var(--satisfeito);
      background-color: #97c93d;
      border-radius: 10px 10px 0 0;
    }

    .neutro {
      height: var(--neutro);
      background-color: #ef7f3c;
      border-radius: 10px 10px 0 0;
    }

    .insatisfeito {
      height: var(--insatisfeito);
      background-color: #eb453d;
      border-radius: 10px 10px 0 0;
    }

    .nao-avaliado {
      height: var(--naoAvaliado);
      background-color: blue;
      border-radius: 10px 10px 0 0;
    }
  }
}

.div-porcentagem {
  display: flex;
  justify-content: center;

  .span-porcentagem {
    font-size: 15px;
    font-weight: bold;
    color: #333;
  }
}

.svg-emojis {
  width: 80%;
}

.emojis {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;

  &.emoji-satisfeito {
    bottom: calc(var(--satisfeito) + 25px);
  }

  &.emoji-neutro {
    bottom: calc(var(--neutro) + 25px);
  }

  &.emoji-insatisfeito {
    bottom: calc(var(--insatisfeito) + 25px);
  }

  &.emoji-nao-avaliado {
    bottom: calc(var(--naoAvaliado) + 25px);

    .material-icons {
      font-size: 4rem;
      color: blue;
    }
  }
}
</style>
