<template>
  <div id="criarClientesDadosMestres">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
        <h3 class="titleHeader mb-3">Fluxo de Criar Clientes Dados Mestres</h3>
      <v-row>
          <v-col>
            <h5>Atenção</h5>
            <p class="caption">Os campos com
              <span class="count-warning">*</span>
              são obrigatórios.</p>
          </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-autocomplete
            v-model="empresa"
            :items="empresas"
            label="Empresa"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="razaoCliente"
            label="Razão social do cliente"
            class="alert-fild-required"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-text-field
            v-mask="'##.###.###/####-##'"
            v-model="cnpj"
            label="CNPJ"
            class="alert-fild-required"
            outlined
            dense
            :disabled="(cpf.length || indentificacaoExterior.length) >= 1"
          ></v-text-field>
        </v-col>
        <span style="margin-top: 20px;">OU</span>
        <v-col cols="3">
          <v-text-field
            v-mask="'###.###.###-##'"
            v-model="cpf"
            label="CPF"
            class="alert-fild-required"
            outlined
            dense
            :disabled="(cnpj.length || indentificacaoExterior.length) >= 1"
          ></v-text-field>
        </v-col>
        <span style="margin-top: 20px;">OU</span>
        <v-col cols="3">
          <v-text-field
            v-model="indentificacaoExterior"
            label="Identificação Exterior"
            class="alert-fild-required"
            outlined
            dense
            :disabled="(cnpj.length || cpf.length) >= 1"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="endereco"
            label="Endereço"
            class="alert-fild-required"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-mask="'#####-###'"
            v-model="cep"
            label="CEP"
            class="alert-fild-required"
            outlined
            dense
            :disabled="zipCode.length >= 1"
          ></v-text-field>
        </v-col>
        <span style="margin-top: 20px;">OU</span>
        <v-col cols="3">
          <v-text-field
            v-model="zipCode"
            label="ZIPCODE"
            outlined
            class="alert-fild-required"
            dense
            :disabled="cep.length >= 1"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="cidade"
            label="Cidade"
            class="alert-fild-required"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="estado"
            label="Estado"
            class="alert-fild-required"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            v-model="pais"
            label="País"
            class="alert-fild-required"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-text-field
            v-mask="'(##)#####-####'"
            v-model="telefone1"
            label="Telefone Obrigatório"
            class="alert-fild-required"
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-mask="'(##)#####-####'"
            v-model="telefone2"
            label="Telefone opcional"
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            v-mask="'(##)#####-####'"
            v-model="telefone3"
            label="Telefone opcional"
            outlined
            dense
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="emailGeral"
            label="E-mail geral"
            class="alert-fild-required"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-for="(item, index) in emailsFaturamento"
            :key="index"
            v-model="item.email"
            label="E-mail para faturamento"
            type="text"
            :class="checkRequiredCSSAlert"
            outlined
            dense
            clearable
            auto-grow
          ></v-text-field>
        </v-col>
        <v-col>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                color="white"
                x-small
                dark
                left
                v-bind="attrs"
                v-on="on"
                @click.stop="addNewEmail()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Adicione mais um e-mail.</span>
          </v-tooltip>
          <v-tooltip v-if="emailsFaturamento.length > 1" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                color="white"
                x-small
                dark
                left
                v-bind="attrs"
                v-on="on"
                @click.stop="removeOldEmail()"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </template>
            <span>Remova um e-mail.</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-autocomplete
            v-for="(item, index) in centrosLucros"
            :key="index"
            v-model="item.centroLucro"
            :items="itemsCentrosLucros"
            label="Centro de Lucro"
            class="mb-2 alert-fild"
            outlined
            dense
          >
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                color="white"
                x-small
                dark
                left
                v-bind="attrs"
                v-on="on"
                @click.stop="addNewCl()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Adicione mais um Centro de Lucro.</span>
          </v-tooltip>

          <v-tooltip v-if="centrosLucros.length > 1" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                color="white"
                x-small
                dark
                left
                v-bind="attrs"
                v-on="on"
                @click.stop="removeOldCL()"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </template>
            <span>Remova um Centro de Lucro.</span>
          </v-tooltip>

        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-icon>mdi-alert</v-icon>
          <p class="caption"><strong>Atenção: </strong>Informações adicionais,
          utilizar o campo de <strong>mensagem.</strong></p>
        </v-col>
      </v-row>

    </v-form>
  </div>
</template>

<script>
import moment from 'moment';
import { mask } from 'vue-the-mask';
import Snackbar from '../Snackbar';

export default {
  name: 'CriarClientesDadosMestres.vue',
  components: { Snackbar },
  directives: { mask },
  data() {
    return {
      empresa: '',
      empresas: [
        'Meta',
        'Netrin',
        'Meta Ventures Part Ltda',
        '91M Cons. Gestão Ltda',
        'MGSA Consultoria Ltda',
        'WORBI Gestão Ltda',
        'WTCPAR Participações Ltda',
      ],
      razaoCliente: '',
      cnpj: '',
      cpf: '',
      indentificacaoExterior: '',
      endereco: '',
      cep: '',
      zipCode: '',
      cidade: '',
      estado: '',
      pais: '',
      telefone1: '',
      telefone2: '',
      telefone3: '',
      emailGeral: '',
      emailsFaturamento: [{ email: '' }],
      centrosLucros: [{ centroLucro: '' }],
      itemsCentrosLucros: [],
      dadosFluxo: {},
      newDadosFluxo: {},
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },
  created() {
    moment.locale('pt-br');
    this.getCentrosLucro();
  },

  computed: {
    verificaCampos() {
      return (!!this.empresa
      && !!this.razaoCliente
      && (!!this.cnpj || !!this.cpf || !!this.indentificacaoExterior)
      && !!this.endereco
      && (!!this.cep || !!this.zipCode)
      && !!this.cidade
      && !!this.estado
      && !!this.pais
      && !!this.telefone1
      && !!this.emailGeral
      && this.chechIfneedEmailFaturamento);
    },

    chechIfneedEmailFaturamento() {
      return this.zipCode ? true : !!this.emailsFaturamento[0].email;
    },

    checkRequiredCSSAlert() {
      return this.zipCode ? 'alert-fild' : 'alert-fild-required';
    },
  },
  methods: {
    async getCentrosLucro() {
      try {
        const { data: response } = await this.$http.get('/data-from-sap/centro-lucro');
        this.itemsCentrosLucros = response.map(item => item.descricaoItens);
      } catch (error) {
        console.error(error);
      }
    },

    addNewEmail() {
      if (this.emailsFaturamento.length < 5) {
        this.emailsFaturamento.push({
          email: '',
        });
      } else {
        this.alerta = 'error';
        this.msg = 'Limite de 5 e-mails por Atendimento';
        this.snackBarAlertaCampos = true;
      }
    },

    removeOldEmail() {
      this.emailsFaturamento.pop();
    },

    addNewCl() {
      if (this.centrosLucros.length < 3) {
        this.centrosLucros.push({
          centroLucro: '',
        });
      } else {
        this.alerta = 'error';
        this.msg = 'Limite de 3 centros de lucro por Atendimento';
        this.snackBarAlertaCampos = true;
      }
    },

    removeOldCL() {
      this.centrosLucros.pop();
    },

    trataResposta(dadosFluxo) {
      const respostasModel = {};

      if (dadosFluxo.empresa) respostasModel.column_campo_1 = dadosFluxo.empresa;
      if (dadosFluxo.razaoCliente) respostasModel.column_campo_2 = dadosFluxo.razaoCliente;
      if (dadosFluxo.cnpj) respostasModel.column_campo_3 = dadosFluxo.cnpj;
      if (dadosFluxo.cpf) respostasModel.column_campo_4 = dadosFluxo.cpf;
      if (dadosFluxo.indentificacaoExterior) {
        respostasModel.column_campo_5 = dadosFluxo.indentificacaoExterior;
      }
      if (dadosFluxo.endereco) respostasModel.column_campo_6 = dadosFluxo.endereco;
      if (dadosFluxo.cep) respostasModel.column_campo_7 = dadosFluxo.cep;
      if (dadosFluxo.zipCode) respostasModel.column_campo_8 = dadosFluxo.zipCode;
      if (dadosFluxo.cidade) respostasModel.column_campo_9 = dadosFluxo.cidade;
      if (dadosFluxo.estado) respostasModel.column_campo_10 = dadosFluxo.estado;
      if (dadosFluxo.pais) respostasModel.column_campo_11 = dadosFluxo.pais;
      if (dadosFluxo.telefone1) respostasModel.column_campo_12 = dadosFluxo.telefone1;
      if (dadosFluxo.telefone2) respostasModel.column_campo_13 = dadosFluxo.telefone2;
      if (dadosFluxo.telefone3) respostasModel.column_campo_14 = dadosFluxo.telefone3;
      if (dadosFluxo.emailGeral) respostasModel.column_campo_15 = dadosFluxo.emailGeral;
      if (dadosFluxo.emailsFaturamento[0]) {
        respostasModel.column_campo_16 = dadosFluxo.emailsFaturamento[0].email;
      }
      if (dadosFluxo.emailsFaturamento[1]) {
        respostasModel.column_campo_17 = dadosFluxo.emailsFaturamento[1].email;
      }
      if (dadosFluxo.emailsFaturamento[2]) {
        respostasModel.column_campo_18 = dadosFluxo.emailsFaturamento[2].email;
      }
      if (dadosFluxo.emailsFaturamento[3]) {
        respostasModel.column_campo_19 = dadosFluxo.emailsFaturamento[3].email;
      }
      if (dadosFluxo.emailsFaturamento[4]) {
        respostasModel.column_campo_20 = dadosFluxo.emailsFaturamento[4].email;
      }
      if (dadosFluxo.centrosLucros[0]) {
        respostasModel.column_campo_21 = dadosFluxo.centrosLucros[0].centroLucro;
      }
      if (dadosFluxo.centrosLucros[1]) {
        respostasModel.column_campo_22 = dadosFluxo.centrosLucros[1].centroLucro;
      }
      if (dadosFluxo.centrosLucros[2]) {
        respostasModel.column_campo_23 = dadosFluxo.centrosLucros[2].centroLucro;
      }
      respostasModel.validation = true;
      this.newDadosFluxo = respostasModel;
    },

    enviaJSON() {
      if (!this.verificaCampos) {
        this.dadosFluxo = { validation: false };
        this.alerta = 'error';
        this.msg = 'Todos os campos com * são obrigatórios.';
        this.snackBarAlertaCampos = true;
      } else {
        this.dadosFluxo = {
          validation: true,
          empresa: this.empresa,
          razaoCliente: this.razaoCliente,
          cnpj: this.cnpj,
          cpf: this.cpf,
          indentificacaoExterior: this.indentificacaoExterior,
          endereco: this.endereco,
          cep: this.cep,
          zipCode: this.zipCode,
          cidade: this.cidade,
          estado: this.estado,
          pais: this.pais,
          telefone1: this.telefone1,
          telefone2: this.telefone2 ? this.telefone2 : '',
          telefone3: this.telefone3 ? this.telefone3 : '',
          emailGeral: this.emailGeral,
          emailsFaturamento: this.emailsFaturamento,
          centrosLucros: this.centrosLucros,

        };
        this.trataResposta(this.dadosFluxo);
        this.$emit('dadosFluxo', this.newDadosFluxo);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

.alert-fild-required::after {
    content: "*";
    color: red;
    margin-bottom: 10px;
    margin-left: 6px;
    font-size: x-small;
}
.alert-fild::after {
    content: "";
    margin-bottom: 10px;
    margin-left: 6px;
    font-size: x-small;
}

.count-warning {
  background-color: transparent;
  color: red;
}
</style>
