import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

const getAvatar = {
  methods: {
    getAvatar(item) {
      if (isArray(item.usuarioHasAvatar) && !isEmpty(item.usuarioHasAvatar)) {
        return item.usuarioHasAvatar[0].caminho
          ? `${this.url}/${item.usuarioHasAvatar[0].caminho}`
          : this.nomeIniciais(item.atendente_nome);
      }
      return item.usuarioHasAvatar.caminho
        ? `${this.url}/${item.usuarioHasAvatar.caminho}`
        : this.nomeIniciais(item.atendente_nome);
    },
  },
};

export default getAvatar;
