<template>
  <div>
    <Navegacao
      ref="Navegacao"
      @sedeChanged="emitNovaSede"
    />
    <tasty-burger-button
      :type="buttonType"
      :active="drawer"
      :size="size"
      :color="activeColor"
      :active-color="activeColor"
      class="tasty-burger"
      v-on:toggle="toggleMenuDrawer"
    />
    <v-row>
      <v-col :cols="drawer ? '2' : '0'">
        <v-navigation-drawer
          class="menu-lateral"
          v-model="drawer"
          :color="color"
          :expand-on-hover="expandOnHover"
          :mini-variant="miniVariant"
          :height="'100%'"
          :right="right"
          hide-overlay
          stateless
        >
          <v-list class="mt-100">
            <v-list-item>
              <BotaoMenuLateral
                icone="mdi-email"
                label="Atendimento"
                :rota="permissaoVisualizarAtendimentos ? '/lista' : '/lista-usuario'"
              />
            </v-list-item>
            <v-list-item>
              <BotaoMenuLateral
                icone="feedback"
                label="Feedback"
                :rota="'/feedback'"
              />
            </v-list-item>
            <v-list-item>
              <BotaoMenuLateral
                icone="help"
                label="FAQ"
                :rota="'/perguntas-frequentes'"
              />
            </v-list-item>
            <v-list-item>
              <BotaoMenuLateral
                icone="mdi-email-plus"
                label="Novo"
                :rota="'/novo'"
              />
            </v-list-item>
            <v-list-item v-if="permissaoGestor">
              <BotaoMenuLateral
                icone="mdi-account-supervisor"
                label="Administrativo"
                :rota="'/admin'"
              />
            </v-list-item>
            <hr>
            <v-list-item v-if="permissaoRelatorio">
              <BotaoMenuLateral
                icone="mdi-file-download"
                label="Relatórios"
                :rota="'/relatorios'"
              />
            </v-list-item>
            <v-list-item v-if="permissaoAtendente || (atendenteTipo.id_permissao == gestor)">
              <BotaoMenuLateral
                icone="mdi-chart-bar"
                label="Analytics Atendente"
                :rota="'/dashboard/atendente'"
              />
            </v-list-item>
            <v-list-item v-if="permissaoGestor">
              <BotaoMenuLateral
                icone="mdi-chart-bar"
                label="Analytics Gestor"
                :rota="'/dashboard/gestor'"
              />
            </v-list-item>
            <v-list-item v-if="permissaoGestorGeral
              || (permissaoBPS)
              || (permissaoGestor && atendenteTipo.setor_nome == setorDP)
              || (permissaoGestor && atendenteTipo.setor_nome == setorJuridico)">
              <BotaoMenuLateral
                icone="mdi-chart-bar"
                label="Analytics Diretoria"
                :rota="'/dashboard/diretoria'"
              />
            </v-list-item>
            <v-list-item
            v-if="permissaoAprovador
            || permissaoAprovadorCeo"
            >
              <BotaoMenuLateral
                icone="settings_accessibility"
                label="Aprovação"
                :rota="'/aprovacao'"
              />
            </v-list-item>
            <hr>
            <v-list-item>
              <BotaoMenuLateral
                icone="system_update_alt"
                label="Atualizações"
                :rota="'/atualizacoes'"
              />
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-col>
      <v-col
        class="container-slot"
        :class="drawer ? 'pl-1 pt-7 pr-12' : 'pl-12 pt-7 pr-12'"
        :cols="drawer ? '10' : '12'"
      >
        <slot />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Navegacao from '../components/Navegacao.vue';
import BotaoMenuLateral from '../components/BotaoMenuLateral.vue';
import { setores } from '../Constants.js';
import EventBus from '../plugins/EventBus';
import {
  getMaxPermission,
  validaAprovador,
  getPermissionsList,
  validaPermissao,
  validaAprovadorCeo,
} from '../utils/utilities';

export default {
  name: 'layout',
  components: {
    Navegacao,
    BotaoMenuLateral,
  },
  data() {
    return {
      drawer: false,
      right: false,
      miniVariant: false,
      expandOnHover: false,
      color: '#fff',
      activeColor: '#122870',
      buttonType: 'collapse',
      size: 'm',
      permissao: getMaxPermission(this.$store).id_permissao,
      listaPermissoesUsuario: getPermissionsList(this.$store.getters.isUsuario.permissao),
      setorDP: setores.departamentoDePessoal,
      setorJuridico: setores.juridico,
      gestor: this.$store.getters.listaPermissoes.gestor,
      gestorGeral: this.$store.getters.listaPermissoes.gestorgeral,
      aprovador: this.$store.getters.listaPermissoes.aprovador,
      atendente: this.$store.getters.listaPermissoes.atendente,
      atendenteTipo: getMaxPermission(this.$store),
    };
  },
  computed: {
    permissaoGestor() {
      return this.listaPermissoesUsuario.filter(element => element.id_permissao
        === this.gestorGeral || element.id_permissao === this.gestor).length;
    },
    permissaoGestorGeral() {
      return this.permissao === this.$store.getters.listaPermissoes.gestorgeral;
    },
    permissaoAtendente() {
      return this.listaPermissoesUsuario.filter(element => element.id_permissao
        === this.atendente).length;
    },
    permissaoBPS() {
      return this.permissao === this.$store.getters.listaPermissoes.businesspartner;
    },
    permissaoAprovador() {
      return (validaAprovador(this.$store, setores));
    },
    permissaoAprovadorCeo() {
      return validaAprovadorCeo(this.$store, this.$store.getters.listaPermissoes.aprovadorceo);
    },
    permissaoRelatorio() {
      return validaPermissao(this.$store.getters.isUsuario.permissao,
        this.$store.getters.listaPermissoes, this.$store.getters.listaPermissoes.relatorio)
        || this.permissaoGestor;
    },
    permissaoVisualizarAtendimentos() {
      return this.permissao === this.$store.getters.listaPermissoes.gestorgeral
        || this.permissao === this.$store.getters.listaPermissoes.atendente
        || this.permissao === this.$store.getters.listaPermissoes.gestor;
    },
  },
  created() {
    this.drawer = this.$store.getters.menuLateral;
    EventBus.$on('toggleMenuDrawer', () => {
      this.toggleMenuDrawer();
    });
  },
  methods: {
    layoutCarregaNotificacoes() {
      this.$refs.Navegacao.carregaNotificacoes();
    },
    emitNovaSede() {
      this.$emit('sedeChanged');
    },
    toggleMenuDrawer(active) {
      this.drawer = active;
      this.$store.commit('UPDATE_MENULATERAL', active);
    },
  },
};
</script>

<style lang="scss">
html .v-list-item {
  padding: 0;
}
html .v-navigation-drawer {
  transition: 0.5s;
  transition-timing-function: ease;
}
html .v-navigation-drawer__content {
  height: 100%;
}
html .theme--light.v-navigation-drawer {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
</style>

<style lang="scss" scoped>
.menu-lateral {
  position: fixed;
  width: auto !important;
}
.container-slot {
  margin-top: 85px;
}
.tasty-burger {
  margin: 50px 0 0 35px;
  position: fixed;
  width: fit-content;
  z-index: 32;
}
.col {
  transition: 0.5s;
  transition-timing-function: ease;
}
.col-0 {
  width: 0;
  padding: 0;
  margin: 0;
}
.mt-100 {
  margin-top: 100px;
}
</style>
