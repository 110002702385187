<template>
  <div id="FormCriarMaterialServico">
    <Snackbar
      :label="msg"
      v-model="snackBarAlertaCampos"
      top
      right
      :timeout="2500"
      :tipo="alerta"
    />
    <v-form>
      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="unidade"
            label="Centro / Unidade"
            outlined
            dense
            :disabled="editar ? true : false"
          >
          </v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-text-field
            v-model="nomeMaterialServico"
            label="Nome do Material ou Serviço"
            outlined
            dense
            :disabled="editar ? true : false"
          ></v-text-field>
        </v-col>
        <div class="caption count-warning" v-if="!editar">*</div>
      </v-row>

      <v-row v-if="isStatusEncerrado || permissaoMax > colaborador">
        <v-col cols="5">
          <v-text-field
            v-model="infoSAP"
            label="Informação dados SAP"
            outlined
            dense
            :disabled="fielAdd ? true : false"
          ></v-text-field>
          <v-btn
            v-if="permissaoMax > colaborador"
            class="btn-primary mt-1 ml-0 mb-2"
            @click="addFieldSAP"
          >
          <span v-if="fielAdd">
            <v-icon left>mdi-content-save</v-icon>Adicionar info SAP
          </span>
          <span v-else>
            <v-icon left>mdi-content-save</v-icon>Salvar
          </span>
          </v-btn>
        </v-col>
      </v-row>

      <v-btn
        :class="{ 'btn-primary-disabled ml-0': isStatusEncerrado,
                   'btn-primary ml-0': !isStatusEncerrado }"
        @click="toggleEdit"
        :disabled="isStatusEncerrado">
        <span v-if="editar">
          <v-icon left>mdi-pencil</v-icon>Editar
        </span>
        <span v-else>
          <v-icon left>mdi-close</v-icon>Cancelar
        </span>
      </v-btn>

      <v-btn
        v-if="!editar"
        class="btn-primary ml-2"
        @click="salvarFluxoEditado"
      >
        <v-icon left>mdi-content-save</v-icon>Salvar
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import Snackbar from '../Snackbar';
import { getMaxPermission } from '../../utils/utilities';

export default {
  name: 'FormCriarMaterialServico',
  components: { Snackbar },

  props: {
    camposFluxo: VueTypes.array,
    fluxoId: VueTypes.integer,
    atendimentoId: VueTypes.integer,
    statusAtendimento: VueTypes.string,
  },

  data() {
    return {

      unidade: '',

      nomeMaterialServico: '',

      colaborador: this.$store.getters.listaPermissoes.colaborador,
      infoSAP: '',
      fielAdd: true,
      permissaoMax: getMaxPermission(this.$store).id_permissao,

      editar: true,
      disabled: true,
      snackBarAlertaCampos: false,
      alerta: 'info',
      msg: '',
    };
  },
  created() {
    this.loadInfoCamposFluxo();
  },

  computed: {
    isStatusEncerrado() {
      return this.statusAtendimento === 'Encerrado';
    },

    verificaCampos() {
      return (!!this.unidade
      && !!this.nomeMaterialServico);
    },
  },

  methods: {
    loadInfoCamposFluxo() {
      this.unidade = this.camposFluxo[0].resposta;
      this.nomeMaterialServico = this.camposFluxo[1].resposta;
      this.infoSAP = this.camposFluxo[2].resposta;
    },

    togglefielAdd() {
      this.fielAdd = !this.fielAdd;
    },

    async addFieldSAP() {
      this.togglefielAdd();

      if (this.fielAdd) {
        this.alerta = 'success';
        this.msg = 'Registro SAP adicionado!';
        this.snackBarAlertaCampos = true;
      }

      const body = {
        id: this.atendimentoId,
        campos_fluxo: {
          column_campo_3: this.infoSAP,
        },
        responsavel: this.$store.getters.isUsuario.id,
        fluxo_id: this.fluxoId,
      };

      await this.$http.put('/atendimentos/editar/dados-fluxo', body);
    },

    toggleEdit() {
      this.editar = !this.editar;
      this.disabled = !this.disabled;
    },

    async salvarFluxoEditado() {
      if (!this.verificaCampos) {
        this.dadosFluxo = { validation: false };
        this.alerta = 'error';
        this.msg = 'Todos os campos com * são obrigatórios.';
        this.snackBarAlertaCampos = true;
      } else {
        this.toggleEdit();

        this.alerta = 'success';
        this.msg = 'Formulário editado com sucesso!';
        this.snackBarAlertaCampos = true;

        const body = {
          id: this.atendimentoId,
          campos_fluxo: {
            column_campo_1: this.unidade,
            column_campo_2: this.nomeMaterialServico,
            column_campo_3: this.infoSAP,
          },
          responsavel: this.$store.getters.isUsuario.id,
          fluxo_id: this.fluxoId,
        };

        await this.$http.put('/atendimentos/editar/dados-fluxo', body);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col,
.col-3 {
  padding: 12px 6px 12px 12px !important;
}

#FormCriarMaterialServico {
  margin-left: 12px;
}

.titleHeader {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.btn-primary {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border: 1px solid #0056a8 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.btn-primary-disabled {
  text-transform: inherit !important;
  letter-spacing: 0;
  background: #0056a8 !important;
  color: #fff !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  margin: 0 auto;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #bfbfbf;
  }
}

.count-warning {
  background-color: transparent;
  color: red;
}
</style>
