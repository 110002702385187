var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('v-card',{staticClass:"card-filtro mt-0 px-3 py-3"},[_c('v-expansion-panels',{attrs:{"accordion":"","multiple":"","flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('h6',{staticClass:"font-weight-bold body-0"},[_vm._v("Filtro: Status")])]),_c('v-expansion-panel-content',[_c('div',{staticClass:"container-sedes"},_vm._l((_vm.statusList),function(item,index){return _c('v-checkbox',{key:index,staticClass:"flex-item my-0 mr-4 pa-0",attrs:{"dense":"","label":item.tipo,"value":item.id},model:{value:(_vm.statusEscolhido),callback:function ($$v) {_vm.statusEscolhido=$$v},expression:"statusEscolhido"}})}),1)])],1)],1),_c('v-checkbox',{staticClass:"checkbox",attrs:{"label":"Filtrar por datas"},model:{value:(_vm.dataCheckbox),callback:function ($$v) {_vm.dataCheckbox=$$v},expression:"dataCheckbox"}}),(_vm.dataCheckbox)?_c('div',{staticClass:"container-row",staticStyle:{"display":"flex"}},[_c('v-menu',{ref:"menu1",attrs:{"offset-x":"","offset-y":"","close-on-content-click":false,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-date mx-1",attrs:{"dense":"","outlined":"","readonly":"","label":"Data inicial","append-icon":"event"},model:{value:(_vm.dataInicialFormat),callback:function ($$v) {_vm.dataInicialFormat=$$v},expression:"dataInicialFormat"}},'v-text-field',attrs,false),on))]}}],null,false,1911382955),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","max":_vm.dataHoje,"locale":"pt-br"},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.dataInicial),callback:function ($$v) {_vm.dataInicial=$$v},expression:"dataInicial"}})],1),_c('v-menu',{ref:"menu2",attrs:{"offset-x":"","offset-y":"","close-on-content-click":false,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-date ml-4",attrs:{"dense":"","outlined":"","readonly":"","label":"Data final","append-icon":"event"},model:{value:(_vm.dataFinalFormat),callback:function ($$v) {_vm.dataFinalFormat=$$v},expression:"dataFinalFormat"}},'v-text-field',attrs,false),on))]}}],null,false,1553711066),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","max":_vm.dataHoje,"locale":"pt-br"},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.dataFinal),callback:function ($$v) {_vm.dataFinal=$$v},expression:"dataFinal"}})],1)],1):_vm._e(),(_vm.dataCheckbox)?_c('v-icon',[_vm._v("mdi-alert")]):_vm._e(),(_vm.dataCheckbox)?_c('p',{staticClass:"caption"},[_c('strong',[_vm._v("Atenção: ")]),_vm._v("As datas estão em formato americano (Ano - Mês - Dia)")]):_vm._e()],1),_c('div',{staticClass:"principal"},[_c('hr',{staticClass:"linha"}),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.permissaoGestor.id_permissao === _vm.gestorGeral)?_c('v-btn',_vm._g({staticClass:"btn-2 primary"},on),[_vm._v(" DOWNLOAD RELATÓRIO POR SETOR ")]):_vm._e(),_c('v-divider')]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"px-2"},[_c('v-card-title',[_vm._v("Escolha o Setor para gerar o relatório")]),_c('v-card-subtitle',{staticClass:"mt-1"},[_c('v-select',{attrs:{"label":"Setor","items":_vm.listaSetores,"outlined":"","dense":""},on:{"click":_vm.getSetores},model:{value:(_vm.setor),callback:function ($$v) {_vm.setor=$$v},expression:"setor"}}),_c('v-card-actions',{staticClass:"pb-2"},[_c('v-spacer'),_c('div',[_c('Botao',{staticClass:"mr-1",attrs:{"label":"Cancelar","tipo":"primary","callback":_vm.closeModal}}),_c('Botao',{staticClass:"button",attrs:{"label":"Gerar relatório","tipo":_vm.setor ? 'primary' : 'disabled',"callback":_vm.getRelatorioPorSetor}})],1)],1)],1)],1)],1),_c('hr'),((_vm.permissaoGestor.id_permissao === _vm.gestorGeral))?_c('Botao',{attrs:{"type":"button","label":"DOWNLOAD RELATÓRIO TODOS ATENDIMENTOS","tipo":"primary","callback":_vm.getRelatorioGeral}}):_vm._e(),_c('hr'),((_vm.permissaoGestor.id_permissao === _vm.gestor) || _vm.hasRelatorio)?_c('Botao',{attrs:{"type":"button","label":"DOWNLOAD RELATÓRIO","tipo":"primary","callback":_vm.getRelatorioPorSetor}}):_vm._e(),_c('hr'),((_vm.permissaoGestor.setor_nome === _vm.dp && _vm.notColaborador )
    || (_vm.permissaoGestor.setor_nome === _vm.juridico && _vm.notColaborador)
    || (_vm.permissaoGestor.id_permissao === _vm.gestorGeral))?_c('RelatorioDesligamento',{attrs:{"dataCheckbox":_vm.dataCheckbox,"statusCheckbox":_vm.statusCheckbox,"statusSelected":_vm.statusEscolhido,"dataInitialSelected":_vm.dataInicialFormat,"dataFinalSelected":_vm.dataFinalFormat}}):_vm._e(),_c('hr'),((_vm.permissaoGestor.setor_nome === _vm.dp && _vm.notColaborador )
    || (_vm.permissaoGestor.setor_nome === _vm.juridico && _vm.notColaborador)
    || (_vm.permissaoGestor.id_permissao === _vm.gestorGeral))?_c('RelatorioDesligamentoJuridico',{attrs:{"dataCheckbox":_vm.dataCheckbox,"statusCheckbox":_vm.statusCheckbox,"statusSelected":_vm.statusEscolhido,"dataInitialSelected":_vm.dataInicialFormat,"dataFinalSelected":_vm.dataFinalFormat}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }