<template>
  <div class="principal">
    <v-card
      v-if="this.valores.length !== 0"
      :max-height="500"
      :width="1230"
      :min-width="700"
      :elevation="4"
      class="card-indicador mt-2 px-3 pt-4 pb-0"
    >
      <v-overlay
        :value="loaderTI"
        :absolute="true"
        :opacity="0.30"
      >
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <div ref="capture">
        <div class="div-titulo-dias">
          <div class="div-titulo-botao">
            <span class="span-atendimento-status">
              Atendimentos por área de atuação
            </span>
            <button
              class="botao-download"
              @click="screenShot()"
            ><span class="
              material-icons">
                get_app
              </span>
            </button>
          </div>
        </div>
        <apexchart
          type="bar"
          :options="chartOptions"
          :series="series"
          width="1190"
          height="338"
        ></apexchart>
      </div>
    </v-card>
    <v-card
      v-else
      :height="450"
      :max-width="500"
      :elevation="4"
      class="card-indicador mt-2 px-3 pt-3 pb-0"
    >
      <div class="div-sem-dados">
        <span class="mensagem-sem-dados">Nenhum dado encontrado de acordo com o filtro!</span>
      </div>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import VueTypes from 'vue-types';
import screenShot from '../../mixins/screenShot.js';

export default {
  name: 'IndicadorAtendimentoPorTI',
  components: {
    apexchart: VueApexCharts,
  },
  mixins: [screenShot],
  props: {
    valores: VueTypes.array.isRequired,
    atuacao: VueTypes.array.isRequired,
    loaderTI: VueTypes.bool.isRequired,
  },

  data() {
    return {
      series: [
        {
          data: this.valores,
        },
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,

          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
            startingShape: 'flat',
            endingShape: 'rounded',
            barHeight: '80%',
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'middle',
          style: {
            fontSize: '13px',
            fontWeight: 'bold',
          },
        },
        grid: {
          show: true,
          padding: {
            top: -20,
            left: 25,
            bottom: -20,
          },
        },
        xaxis: {
          type: 'category',
          categories: this.atuacao,
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            align: 'left',
            style: {
              fontSize: '12px',
              fontFamily: 'Open Sans',
              fontWeight: 'bold',
            },
          },
        },
        tooltip: {
          y: {
            title: {
              formatter: () => 'Atendimento(s)',
            },
          },
        },
        legend: {
          show: false,
        },
        colors: ['#239023', '#040E80'],
      },

    };
  },
};
</script>

<style lang="scss" scoped>
.card-indicador {
  border-radius: 20px !important;
}
.principal {
  .div-titulo-dias {
    display: flex;
    flex-direction: column;
    padding-left: 10px;

    .div-titulo-botao {
      display: flex;
      justify-content: space-between;

      .botao-download {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 8%;
        border-radius: 5px;
        border: 2px solid #eeeeee;

        &:hover {
          background-color: #e2dede;
        }

        .material-icons {
          color: #0056a8;
          font-size: 25px;
        }
      }

      .span-atendimento-status {
        font-weight: bold;
      }
    }
    .div-encerrados {
      font-size: 13px;
      font-weight: bold;
      padding-left: 6px;
    }
  }

  .div-sem-dados {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .mensagem-sem-dados {
      font-size: 18px;
    }
  }
}
</style>
