/* eslint-disable array-callback-return */
<template>
  <layout>
      <p class="totalAttendance">Atendimentos em aberto: {{ getTotalAttendance() }}</p>
    <hr />
    <div class="topbar">
      <FiltroAtendimento
        tipoFiltro="searchAtendimentos"
        :quantidadeTags="2"
        class="input-filtro"
        :permissao="this.permissaoMax"
        @changeFiltro="filtrar($event)"
      />
      <div class="input-select">
        <InputSelect
          v-model="filtro"
          :options="opcoes"
        />
      </div>
    </div>
    <div class="lista-atendente">
      <div>
        <ColunaStatus
          titulo="Pendente"
          :ordenar="pendente"
          :total="pendente.length"
        >
          <draggable
            class="draggable-wrapper"
            v-model="pendente"
            name="pendente"
            :move="onMove"
            @start="isDragging=true"
            @end="isDragging=false"
            @change="onDrop"
            v-bind="dragOptions"
          >
            <div
              v-for="(item, index) in pendente"
              :key="index"
            >
              <CardAtendimento
                v-if="filtro === todosAtend ||
                    (filtro === usuarioAtend && item.id_atendente === usuario) ||
                    (filtro === atend && item.id_usuario === usuario)"
                :key="index"
                :link="`/atendimento/${item.id_atendimento}`"
                :nomeUsuario="item.nome"
                :setorOrigem="item.setor_origem"
                :subcategoria="item.subcategoria"
                :descricao="item.descricao"
                status="#ff6b00"
                :statusLabel="item.status"
                :tipo="item.categoria"
                :data="item.data_criacao | formatDate"
                :avatar="getAvatar(item)"
                alt="avatar"
                :atendenteAtribuido="!!item.id_atendente"
                :nome="item.atendente_nome"
                :id="item.id_atendimento"
                :id_fluxo="item.id_fluxo ? item.id_fluxo : 0"
                :funcionario="item.dados_fluxo ?
                item.dados_fluxo.funcionario : 'Sem funcionario'"
                :unidade="item.dados_fluxo ?
                item.dados_fluxo.unidade : 'Sem unidade'"
                :data_desligamento="item.dados_fluxo ?
                item.dados_fluxo.dataDesligamento : 'Sem data de desligamento'"
                :dataAvisoPrevio="item.dados_fluxo ?
                item.dados_fluxo.dataAvisoPrevio : 'Sem data de aviso prévio'"
                :idPrioridade="item.id_prioridade"
              />
            </div>
          </draggable>
        </ColunaStatus>
      </div>
      <!--  -->
      <div>
        <ColunaStatus
          titulo="Em análise"
          :ordenar="analise"
          :total="analise.length"
        >
          <draggable
            class="draggable-wrapper"
            v-model="analise"
            name="analise"
            :move="onMove"
            @start="isDragging=true"
            @end="isDragging=false"
            @change="onDrop"
            v-bind="dragOptions"
          >
            <div
              v-for="(item, index) in analise"
              :key="index"
            >
              <CardAtendimento
                v-if="filtro === todosAtend ||
                    (filtro === usuarioAtend && item.id_atendente === usuario) ||
                    (filtro === atend && item.id_usuario === usuario)"
                :key="index"
                :link="`/atendimento/${item.id_atendimento}`"
                :nomeUsuario="item.nome"
                :setorOrigem="item.setor_origem"
                :subcategoria="item.subcategoria"
                :descricao="item.descricao"
                status="#8E24AA"
                :statusLabel="item.status"
                :tipo="item.categoria"
                :data="item.data_criacao | formatDate"
                :avatar="getAvatar(item)"
                alt="avatar"
                :atendenteAtribuido="!!item.id_atendente"
                :nome="item.atendente_nome"
                :id="item.id_atendimento"
                :id_fluxo="item.id_fluxo ? item.id_fluxo : 0"
                :funcionario="item.dados_fluxo ?
                item.dados_fluxo.funcionario : 'Sem funcionario'"
                :unidade="item.dados_fluxo ?
                item.dados_fluxo.unidade : 'Sem unidade'"
                :data_desligamento="item.dados_fluxo ?
                item.dados_fluxo.dataDesligamento : 'Sem data de desligamento'"
                :dataAvisoPrevio="item.dados_fluxo ?
                item.dados_fluxo.dataAvisoPrevio : 'Sem data de aviso prévio'"
                :idPrioridade="item.id_prioridade"
              />
            </div>
          </draggable>
        </ColunaStatus>
      </div>
      <!--  -->
      <div>
        <ColunaStatus
          titulo="Aguardando responsável"
          :ordenar="aguardandoAtendente"
          :total="aguardandoAtendente.length"

        >
          <draggable
            class="draggable-wrapper"
            v-model="aguardandoAtendente"
            name="aguardandoAtendente"
            :move="onMove"
            @start="isDragging=true"
            @end="isDragging=false"
            @change="onDrop"
            v-bind="dragOptions"
          >
            <div
              v-for="(item, index) in aguardandoAtendente"
              :key="index"
            >
              <CardAtendimento
                v-if="filtro === todosAtend ||
                    (filtro === usuarioAtend && item.id_atendente === usuario) ||
                    (filtro === atend && item.id_usuario === usuario)"
                :key="index"
                :link="`/atendimento/${item.id_atendimento}`"
                :nomeUsuario="item.nome"
                :setorOrigem="item.setor_origem"
                :subcategoria="item.subcategoria"
                :descricao="item.descricao"
                status="#027cee"
                :statusLabel="item.status"
                :tipo="item.categoria"
                :data="item.data_criacao | formatDate"
                :avatar="getAvatar(item)"
                alt="avatar"
                :atendenteAtribuido="!!item.id_atendente"
                :nome="item.atendente_nome"
                :id="item.id_atendimento"
                :id_fluxo="item.id_fluxo ? item.id_fluxo : 0"
                :funcionario="item.dados_fluxo ?
                item.dados_fluxo.funcionario : 'Sem funcionario'"
                :unidade="item.dados_fluxo ?
                item.dados_fluxo.unidade : 'Sem unidade'"
                :data_desligamento="item.dados_fluxo ?
                item.dados_fluxo.dataDesligamento : 'Sem data de desligamento'"
                :dataAvisoPrevio="item.dados_fluxo ?
                item.dados_fluxo.dataAvisoPrevio : 'Sem data de aviso prévio'"
                :idPrioridade="item.id_prioridade"
              />
            </div>
          </draggable>
        </ColunaStatus>
      </div>
      <div>
        <ColunaStatus
          titulo="Em atendimento"
          :ordenar="andamento"
          :total="andamento.length"
        >
          <draggable
            class="draggable-wrapper"
            v-model="andamento"
            name="andamento"
            :move="onMove"
            @start="isDragging=true"
            @end="isDragging=false"
            @change="onDrop"
            v-bind="dragOptions"
          >
            <div
              v-for="(item, index) in andamento"
              :key="index"
            >
              <CardAtendimento
                v-if="filtro === todosAtend ||
                    (filtro === usuarioAtend && item.id_atendente === usuario) ||
                    (filtro === atend && item.id_usuario === usuario)"
                :key="index"
                :link="`/atendimento/${item.id_atendimento}`"
                :nomeUsuario="item.nome"
                :setorOrigem="item.setor_origem"
                :subcategoria="item.subcategoria"
                :descricao="item.descricao"
                status="#97c93d"
                :statusLabel="item.status"
                :tipo="item.categoria"
                :data="item.data_criacao | formatDate"
                :avatar="getAvatar(item)"
                alt="avatar"
                :atendenteAtribuido="!!item.id_atendente"
                :nome="item.atendente_nome"
                :id="item.id_atendimento"
                :id_fluxo="item.id_fluxo ? item.id_fluxo : 0"
                :funcionario="item.dados_fluxo ?
                item.dados_fluxo.funcionario : 'Sem funcionario'"
                :unidade="item.dados_fluxo ?
                item.dados_fluxo.unidade : 'Sem unidade'"
                :data_desligamento="item.dados_fluxo ?
                item.dados_fluxo.dataDesligamento : 'Sem data de desligamento'"
                :dataAvisoPrevio="item.dados_fluxo ?
                item.dados_fluxo.dataAvisoPrevio : 'Sem data de aviso prévio'"
                :idPrioridade="item.id_prioridade"
              />
            </div>
          </draggable>
        </ColunaStatus>
      </div>
      <div>
        <ColunaStatus
          titulo="Aguardando usuário/gestor"
          :ordenar="aguardando"
          :total="aguardando.length"
        >
          <draggable
            class="draggable-wrapper"
            v-model="aguardando"
            name="aguardando"
            :move="onMove"
            @start="isDragging=true"
            @end="isDragging=false"
            @change="onDrop"
            v-bind="dragOptions"
          >
            <div
              v-for="(item, index) in aguardando"
              :key="index"
            >
              <CardAtendimento
                v-if="filtro === todosAtend ||
                    (filtro === usuarioAtend && item.id_atendente === usuario) ||
                    (filtro === atend && item.id_usuario === usuario)"
                :key="index"
                :link="`/atendimento/${item.id_atendimento}`"
                :nomeUsuario="item.nome"
                :setorOrigem="item.setor_origem"
                :subcategoria="item.subcategoria"
                :descricao="item.descricao"
                status="#ffe500"
                :statusLabel="item.status"
                :tipo="item.categoria"
                :data="item.data_criacao | formatDate"
                :avatar="getAvatar(item)"
                alt="avatar"
                :atendenteAtribuido="!!item.id_atendente"
                :nome="item.atendente_nome"
                :id="item.id_atendimento"
                :id_fluxo="item.id_fluxo ? item.id_fluxo : 0"
                :funcionario="item.dados_fluxo ?
                item.dados_fluxo.funcionario : 'Sem funcionario'"
                :unidade="item.dados_fluxo ?
                item.dados_fluxo.unidade : 'Sem unidade'"
                :data_desligamento="item.dados_fluxo ?
                item.dados_fluxo.dataDesligamento : 'Sem data de desligamento'"
                :dataAvisoPrevio="item.dados_fluxo ?
                item.dados_fluxo.dataAvisoPrevio : 'Sem data de aviso prévio'"
                :idPrioridade="item.id_prioridade"
              />
            </div>
          </draggable>
        </ColunaStatus>
      </div>
      <div>
        <ColunaStatus
          titulo="Encerrado"
          :ordenar="encerrado"
          :total="encerrado.length"
          decrescente
        >
          <draggable
            class="draggable-wrapper"
            v-model="encerrado"
            name="encerrado"
            :move="onMove"
            @start="isDragging=true"
            @end="isDragging=false"
            @change="onDrop"
            v-bind="dragOptions"
          >
            <div class="botao-encerrados-container">
              <Botao
                v-if="encerrado.length === 0"
                class="ml-3"
                type="button"
                :label="'Carregar encerrados'"
                tipo="primary"
                :callback="chamaEncerrados"
              />
            </div>
            <div
              v-for="(item, index) in encerrado"
              :key="index"
            >
              <CardAtendimento
                v-if="filtro === todosAtend ||
                    (filtro === usuarioAtend && item.id_atendente === usuario) ||
                    (filtro === atend && item.id_usuario === usuario)"
                :key="index"
                :link="`/atendimento/${item.id_atendimento}`"
                :nomeUsuario="item.nome"
                :setorOrigem="item.setor_origem"
                :subcategoria="item.subcategoria"
                :descricao="item.descricao"
                status="#ee0202"
                :statusLabel="item.status"
                :tipo="item.categoria"
                :data="item.data_criacao | formatDate"
                :avatar="getAvatar(item)"
                alt="avatar"
                :atendenteAtribuido="!!item.id_atendente"
                :nome="item.atendente_nome"
                :id="item.id_atendimento"
                :id_fluxo="item.id_fluxo ? item.id_fluxo : 0"
                :funcionario="item.dados_fluxo ?
                item.dados_fluxo.funcionario : 'Sem funcionario'"
                :unidade="item.dados_fluxo ?
                item.dados_fluxo.unidade : 'Sem unidade'"
                :data_desligamento="item.dados_fluxo ?
                item.dados_fluxo.dataDesligamento : 'Sem data de desligamento'"
                :dataAvisoPrevio="item.dados_fluxo ?
                item.dados_fluxo.dataAvisoPrevio : 'Sem data de aviso prévio'"
                :idPrioridade="item.id_prioridade"
              />
            </div>
          </draggable>
        </ColunaStatus>
      </div>
    </div>
  </layout>
</template>

<script>
import axios from 'axios';
import draggable from 'vuedraggable';
import isEmpty from 'lodash/isEmpty';
import Botao from '../components/Botao.vue';
import layout from '../layouts/Layout.vue';
import ColunaStatus from '../components/ColunaStatus.vue';
import CardAtendimento from '../components/CardAtendimento.vue';
import InputSelect from '../components/InputSelect.vue';
import FiltroAtendimento from '../components/FiltroAtendimento.vue';
import nomeIniciais from '../mixins/nomeIniciais.js';
import switchStatus from '../mixins/switchStatus.js';
import Notify from '../mixins/Notify';
import getAvatar from '../mixins/getAvatar.js';
import { getSetoresPorAtendente, getMaxPermission } from '../utils/utilities';
import { status } from '../Constants';

export default {
  name: 'listaAtendimento',
  components: {
    draggable,
    Botao,
    InputSelect,
    layout,
    ColunaStatus,
    CardAtendimento,
    FiltroAtendimento,
  },
  mixins: [nomeIniciais, switchStatus, Notify, getAvatar],
  data() {
    return {
      atendente_novo: null,
      atendente: null,
      atendimentos: null,
      pendente: [],
      analise: [],
      andamento: [],
      aguardando: [],
      aguardandoAtendente: [],
      encerrado: [],
      breadcrumbs: [],
      total: 0,
      todosAtend: 1,
      usuarioAtend: 2,
      atend: 3,
      filtro: 1,
      usuario: {},
      opcoes: [
        { value: 1, label: 'Todos os atendimentos' },
        { value: 2, label: 'Meus atendimentos' },
        { value: 3, label: 'Minhas solicitações' },
      ],
      url: process.env.VUE_APP_API_URL,
      isDragging: false,
      raiaAtual: null,
      setoresPermitidos: [],
      permissaoMax: getMaxPermission(this.$store).id_permissao,
      chamadoConvidados: [],
      atendimentosConvidados: [],
      atendenteTipo: getMaxPermission(this.$store),
    };
  },
  async created() {
    await this.getSetoresPermitidos();
    await this.buscarConvidados();
    this.atualizaDados();
  },
  computed: {
    dragOptions() {
      return {
        group: 'dragStatus',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  mounted() {
    this.$store.commit('UPDATE_LOADING', false);
    this.usuario = this.$store.getters.isUsuario.id;
    this.getPermissoes();
    this.breadcrumbs = [
      {
        route: {
          name: 'lista',
        },
        label: 'Atendimento',
      },
    ];
  },
  methods: {
    async getPermissoes() {
      const { data: response } = await this.$http.get('/permissoes');

      const retorno = {};
      // eslint-disable-next-line no-return-assign
      response.map(item => retorno[item.permissao.toLowerCase().replace(' ', '')] = item.id);
      this.$store.commit('UPDATE_LISTA_PERMISSOES', retorno);
    },

    /* eslint-disable */
    filtrar: function name(params) {
      if (!isEmpty(params)) {
        this.pendente = params[0];
        this.analise = params[1];
        this.aguardandoAtendente = params[2];
        this.aguardando = params[4];
        this.andamento = params[3];
        this.encerrado = params[5];
      } else {
        this.atualizaDados();
      }
    },
    /* eslint-enable */

    getTotalAttendance() {
      return this.pendente.length + this.andamento.length
      + this.aguardandoAtendente.length + this.analise.length
      + this.aguardando.length;
    },

    async atualizaDados() {
      this.pendente = [];
      this.aguardando = [];
      try {
        this.pendente = await this.getAtendimentosForStatus(status.pendente);
        this.analise = await this.getAtendimentosForStatus(status.analise);
        this.aguardandoAtendente = await
        this.getAtendimentosForStatus(status.aguardandoAtendente);
        this.aguardando = await this.getAtendimentosForStatus(status.aguardandoUsuario);
        const aguardandoGestor = await this.getAtendimentosForStatus(status.aguardandoGestor);
        this.andamento = await this.getAtendimentosForStatus(status.emAtendimento);
        this.aguardando.push(...aguardandoGestor);
        this.separaConvidadosPorStatus();
      } catch (error) {
        console.error(error);
      }
    },
    separaConvidadosPorStatus() {
      this.atendimentosConvidados.forEach((item) => {
        this.chamadoConvidados.forEach((element) => {
          if (element.atendimento_id === item.id_atendimento) {
            const atendimentoCompleto = {
              ...item,
              setor_origem: item.setor_nome,
              setorId: item.setor_id,
            };
            if (element.status_id === status.pendente
            && !this.pendente.filter(value => value.id_atendimento
            === atendimentoCompleto.id_atendimento).length) {
              this.pendente.push(atendimentoCompleto);
            }
          }
        });
      });
    },
    label() {
      if (this.filtro === 1) {
        return 'Todos os Atendimentos';
      }
      if (this.filtro === 2) {
        return 'Meus Atendimentos';
      }
      return false;
    },
    async onDrop({ added }) {
      if (added) {
        const cardArrastado = added.element;
        const nomeStatusNovo = this.raiaAtual.component.$attrs.name;
        const idStatusNovo = this.switchStatus(nomeStatusNovo);
        try {
          const body = {
            status_id: idStatusNovo,
            id_usuario: cardArrastado.id_usuario,
            id_atendimento: cardArrastado.id_atendimento,
            id_prioridade: cardArrastado.id_prioridade,
            tipo_alteracao: 'STATUS',
            valor_antigo: cardArrastado.id_status,
            valor_novo: idStatusNovo,
          };

          const { data: retornoAtualizacaoStatus } = await this.$http.put(`/atendimentos/${cardArrastado.id_atendimento}`, body);

          cardArrastado.status = this.switchStatus(
            retornoAtualizacaoStatus[0].status_id,
          );
          cardArrastado.id_status = retornoAtualizacaoStatus[0].status_id;
          this.notificaNovoStatus(
            cardArrastado.id_status,
            cardArrastado.id_usuario,
            cardArrastado.id_prioridade,
            cardArrastado.id_atendente,
            cardArrastado.id_atendimento,
          );
          return true;
        } catch (error) {
          console.error(error);
          return false;
        }
      }
      return false;
    },
    onMove({ relatedContext, draggedContext }) {
      if (this.isDragging && draggedContext.element.status === 'Encerrado') {
        return false;
      }
      if (relatedContext) {
        this.raiaAtual = relatedContext;
      }
      return true;
    },
    async getAtendimentosForStatus(id) {
      let retorno;
      try {
        const { data: response } = await axios.get(
          `/atendimento-por-status/${id}`,
        );
        const listaSetores = this.setoresPermitidos;
        retorno = response.filter(item => (listaSetores.includes(item.setor_id)));
        const convites = this.chamadoConvidados.map(item => ({
          atendimento: item.atendimento_id,
          setor: item.setor_id,
        }));
        convites.forEach((item) => {
          response.forEach((element) => {
            if (item.atendimento === element.id_atendimento
              && listaSetores.includes(item.setor)
              && !retorno.includes(element)) {
              this.atendimentosConvidados.push(element);
            }
          });
        });
      } catch (error) {
        console.error(error);
      }
      return retorno;
    },
    async getSetoresPermitidos() {
      const { data: resposta } = await axios.get(
        '/setores',
      );
      const listaSetores = await getSetoresPorAtendente(this.$store, resposta);
      this.setoresPermitidos = listaSetores;
    },

    async chamaEncerrados() {
      const recebeEncerrados = await this.getAtendimentosForStatus(4);

      this.encerrado = recebeEncerrados.filter(item => item.setor_id
       === this.atendenteTipo.id_setor);
    },

    async buscarConvidados() {
      const { data: response } = await axios.get(
        '/convidados/',
      );
      this.chamadoConvidados = response;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 500 !important;
  color: #0157a8 !important;
}
.btn {
  background-color: #0157a8;
  border-radius: 50px;
  padding: 7px 15px;
  font-weight: bold;
}
.lista-atendente {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  height: calc(100vh - 206px);
}
.topbar {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.input-select {
  margin-left: auto;
  width: 30%;
}
.input-filtro {
  margin-left: 10px;
  display: flex;
  width: 55%;
}
.draggable-wrapper {
  min-height: 53vh;
}

.botao-encerrados-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.totalAttendance {
  color: #122870;
  margin: 0;
  width: 100%;
  height: 20px;
  font-weight: bold;
  margin-left: 5px;
}
</style>
